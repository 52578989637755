<template>
	<modal
			@close="$emit('close')"
			class="custom-popup buy-label medium-size"
	>
		<template slot="header">

		</template>
		<template slot="body">
			<div class="buy-label__content">
        <div v-html="schedulePopupText"></div>
			</div>
		</template>
		<template slot="footer">
			<div class="buy-label__btn d-flex align-items-center"
					 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
				<div class="admin-edit" @click="editTranslate(['common_cancel','common_payForAuthorize', 'common_buyLabel'])"></div>
        <span
						class="site-link site-link--alt buy-label__btn-i mr-3"
						@click="$emit('close')"
				>
          {{$t('common_cancel.localization_value.value')}}
        </span>
			</div>
		</template>
	</modal>

</template>

<script>
	import Modal from '../../../commonModals/Modal.vue'

	export default {
		name: "FreshChatSchedulePopup",
		components: {
			Modal,
		},

    props: {
      schedulePopupText: String,
    },

		data() {
			return {

			}
		},

		created() {


		},


		methods: {


		}

	}

</script>

<style lang="scss" scoped>
  .date-time{
    margin-bottom: 20px;
  }
</style>
