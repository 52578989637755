
import {routerPaths} from '../../../router/routerPaths'

const EtsyRankCard = () => import(/* webpackChunkName: "group-etsy-rank" */ './components/EtsyRankCard/EtsyRankCard.vue')
const moduleKey = 'etsy-rank'

export const EtsyRankRoutes = [
  {
    path: routerPaths.statisticsEtsyRank,
    component: EtsyRankCard,
    meta: {
      breadcrumbs: {
        key: [
          'statistics',
          'etsyRank'
        ],
        items: {
          'statistics': {
            name: 'menu_statistics',
            link: routerPaths.mainSettings,
          },
          'etsyRank': {
            name: 'menu_etsyRank',
            link: routerPaths.statisticsEtsyRank,
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'menu_etsyRank'
    },
  },

]
