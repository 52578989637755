
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";


const InvoicesTable = () => import(/* webpackChunkName: "group-invoices" */ './components/InvoicesTable/InvoicesTable.vue')
const InvoicesCreation = () => import(/* webpackChunkName: "group-invoices-create" */ './components/InvoicesCreation/InvoicesCreation')
const InvoicesEditing = () => import(/* webpackChunkName: "group-invoices-edit" */ './components/InvoicesEditing/InvoicesEditing')
const moduleKey = 'invoices'

export const InvoicesRoutes = [
  {
    path: routerPaths.financeInvoices,
    component: InvoicesTable,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'ordersFBM'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_finance',
            link: routerPaths.financeInvoices,
          },
          'ordersFBM': {
            name: 'menu_invoices',
            link: routerPaths.financeInvoices,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.INVOICES_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_invoices'
    },
  },

  {
    path: routerPaths.financeInvoicesCreate,
    component: InvoicesCreation,
    meta: {
      breadcrumbs: {
        key: [
          'ordersFBM',
          'invoicesCreation'
        ],
        items: {
          'ordersFBM': {
            name: 'menu_invoices',
            link: routerPaths.financeInvoices,
          },
          'invoicesCreation': {
            name: 'invoices_createInvoice',
            link: routerPaths.financeInvoicesCreate,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.INVOICES_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'invoices_createInvoice'
    },
  },

  {
    path: routerPaths.financeInvoicesEdit,
    component: InvoicesEditing,
    meta: {
      breadcrumbs: {
        key: [
          'ordersFBM',
          'invoicesEditing'
        ],
        items: {
          'ordersFBM': {
            name: 'menu_invoices',
            link: routerPaths.financeInvoices,
          },
          'invoicesEditing': {
            name: 'invoices_editInvoice',
            link: routerPaths.financeInvoicesEdit,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.INVOICES_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'invoices_editInvoice'
    },
  },
]
