import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const ShippingCompanyTable = () => import(/* webpackChunkName: "group-shipping-company" */ './components/ShippingCompanyTable/ShippingCompanyTable.vue')
const moduleKey = 'shipping-companies'

export const ShippingCompanyRoutes = [
  {
    path: routerPaths.shippingCompany,
    component: ShippingCompanyTable,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettings',
          'shippingCompany'
        ],
        items: {
          'mainSettings': {
            name: 'menu_settings',
            link: routerPaths.shippingCompany,
          },
          'shippingCompany': {
            name: 'breadcrumbs_shippingCompany',
            link: routerPaths.shippingCompany,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.DELIVERY_SERVICE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_shippingCompany'
    },
  },
]
