import {routerPaths} from '../../../router/routerPaths'

const APCDelconTable = () => import(/* webpackChunkName: "group-apc-delcon-table" */ './components/APCDelconTable/APCDelconTable.vue')
const APCDelconCreate = () => import(/* webpackChunkName: "group-apc-delcon-create" */ './components/APCDelconCreate/APCDelconCreate.vue')
const APCDelconEditing = () => import(/* webpackChunkName: "group-apc-delcon-edit" */ './components/APCDelconEdit/APCDelconEdit.vue')
const moduleKey = 'apc-delcon'

export const APCDelconRoutes = [
  {
    path: routerPaths.reportsAPCDelcon,
    component: APCDelconTable,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'reportsAPCDelcon'
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.reportsAPCDelcon,
          },
          'reportsAPCDelcon': {
            name: 'menu_reportsAPCDelcon',
            link: routerPaths.reportsAPCDelcon,
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'menu_reportsAPCDelcon'
    },
  },
  {
    path: routerPaths.reportsAPCDelconCreate,
    component: APCDelconCreate,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'reportsAPCDelcon',
          'reportsAPCDelconCreate'
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.reportsAPCDelcon,
          },
          'reportsAPCDelcon': {
            name: 'menu_reportsAPCDelcon',
            link: routerPaths.reportsAPCDelcon,
          },
          'reportsAPCDelconCreate': {
            name: 'apcDelcon_APCDelconCreate',
            link: routerPaths.reportsAPCDelconCreate,
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'apcDelcon_APCDelconCreate'
    },
  },

  {
    path: routerPaths.reportsAPCDelconEditItem,
    component: APCDelconEditing,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'reportsAPCDelcon',
          'APCDelconEdit'
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.reportsAPCDelcon,
          },
          'reportsAPCDelcon': {
            name: 'menu_reportsAPCDelcon',
            link: routerPaths.reportsAPCDelcon,
          },
          'APCDelconEdit': {
            name: 'apcDelcon_APCDelconEdit',
            link: '/',
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'apcDelcon_APCDelconEdit'
    },
  },

]
