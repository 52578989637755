<template>
  <button class="main-button"
          :type="type"
          :class="{'main-button--tooltip' : tooltip == true}"
  >
    <div class="main-button__ico" v-if="ico">
        <slot name="ico">

        </slot>
    </div>
    {{value}}

    <span
        v-if="tooltip"
        class="main-button__tooltip"
    >
      <v-popover
          class="site-tooltip"
          :disabled="!tooltipActive"
          offset="5"
          placement="top"
          trigger="hover"

      >
        <TooltipBtn
            class="tooltip-target"

        />
          <template slot="popover">
            <slot name="tooltip">

            </slot>
<!--            <a v-close-popover class="tooltip__close"></a>-->
          </template>
      </v-popover>
    </span>
  </button>
</template>

<script>
  import { VPopover  } from 'v-tooltip'
  import TooltipBtn from "../../tooltips/TooltipBtn/TooltipBtn.vue"


  export default {
    name: "MainButton",

    components: {
      VPopover,
      TooltipBtn
    },

    data () {
      return {
        id: null,
        isShowPass: false,
        tooltipActive: true,
      }
    },

    // props: [
    //   'value',
    //   'ico',
    //   'tooltip',
    //   'plus',
    // ],

    props: {
      tooltip: Boolean,
      ico: Boolean,
      value: [String, Object, Number],
      plus: [String, Object, Number],
      type: {
        type: String,
        default: 'button'
      }
    },

    mounted () {

    },

    methods: {

    },
  }
</script>

<style lang="scss">
  @import "../../../../scss/mixins/mixins";
  @import "../../../../scss/colors";

  .main-button{
    display: flex;
    height: 43px;
    max-width: 149px;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: $accent;
    border-radius: 5px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    cursor: pointer;
    outline: 0;
    transition: 0.3s;
    padding: 0 15px;
    border: 1px solid $accent;
    position: relative;

    &.auto-with{
      max-width: initial;
    }

    &.fsz-16{
      font-size: 16px;
      padding: 0 20px;
      height: 40px;
    }
    &.brown,
    &--brown{
      background: $brown;
      border-color: $brown;
    }


    &:hover{
      box-shadow: 0px 0 3px #00000052;
      background: $brown;
      border-color: $brown;
    }

    &.sharp{
      border-radius: 0;
    }


    &.secondary{
      color: $accent;
      border-color: $accent;
      background: transparent;


      .main-button__ico svg path{
        transition: .3s;
      }
      &:hover{
        @include from-1120 {
          border-color: $brown;
          color: $brown;
        }


        .main-button__ico svg path{
          stroke: $brown!important;
        }
      }
    }

    &.secondary-brown{
      border-color: $brown;
      color: $brown;

      &:before{
        content: '';
        background: linear-gradient(90deg, #F8F4EE 39.36%, rgba(41, 41, 41, 0) 108.3%, #F8F4EE 108.3%);
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        transition: .3s;
      }

      &:hover{
        color: white;
        border-color: white;

        &:before{
          opacity: 0.1;
        }
      }
    }


    &__ico{
      /*width: 19px;*/
      /*height: 19px;*/
      max-width: 30px;
      margin-right: 5px;

      svg{
        width: 100%;
      }
    }

    &--tooltip{
      max-width: 100%;
      width: fit-content;
      min-width: auto!important;
      white-space: nowrap;
      padding: 0 24px;

      @include for-550{
        padding: 0 16px;
      }

      &:hover{
        svg circle {
          fill: #8F7A61;
        }
      }

      &:not(.secondary){
        .tooltip-btn__ico svg{
          rect,
          path{
            fill: $accent;
          }

          circle{
            fill: white;
          }
        }
      }
    }

    &__tooltip{
      position: absolute;
      top: 2px;
      right: 2px;
    }
  }
</style>
