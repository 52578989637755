
export const PERMISSIONS = {
  ACCOUNT_BANKS_GENERAL: 'account-banks-general',
  ACCOUNT_BUSINESS: 'account-business',
  ACCOUNT_EMAIL: 'account-email',
  ACCOUNT_GENERAL: 'account-general',
  ACCOUNT_PERSONAL_UKRAINE: 'account-personal-ukraine',
  ACCOUNT_PERSONAL_WORLD: 'account-personal-world',
  AUTH_TOKENS_GENERAL: 'auth-tokens-general',
  BALANCE_OUTPUT_GENERAL: 'balance-output-general',
  CAN_MANAGE_ALL_USER_PERMISSIONS: 'can-manage-all-user-permissions',
  CALCULATOR_SETTING_PERMISSION: 'calculator-setting-permission',
  CLAIM_GENERAL: 'claim-general',
  CONSOLIDATION_GENERAL: 'consolidation-general',
  CONSOLIDATION_UNION_GENERAL: 'consolidation-union-general',
  CONSOLIDATION_UNION_MEEST_GENERAL: 'consolidation-union-meest-general',
  CONSOLIDATION_PERSONS_GENERAL: 'consolidation-persons-general',
  CREATE_NOTIFICATION: 'create-notification',
  CUSTOM_PRODUCT_THERMAL_GENERAL: 'custom-product-thermal-general',
  CUSTOMER_PAYMENT_AUTHORIZE: 'customer-payment-authorize',
  CUSTOMER_PAYMENT_GENERAL: 'customer-payment-general',
  CUSTOMER_PAYMENT_PAYPAL: 'customer-payment-paypal',
  CUSTOMER_PAYMENT_BRAINTREE: 'customer-payment-braintree',
  CUSTOMER_PAYMENTS_OUTPUT: 'customer-payments-output',
  CUSTOMER_PAYMENTS_OTHER: 'customer-payments-other',
  CUSTOMER_PAYMENT_PAYONEER: 'customer-payment-payoneer',
  DELETE_NOTIFICATION: 'delete-notification',
  DELIVERY_COUNTRY_CUSTOMS_GENERAL: 'delivery-country-customs-general',
  DELIVERY_SERVICE_COST_GENERAL: 'delivery-service-cost-general',
  DELIVERY_SERVICE_GENERAL: 'delivery-service-general',
  DELIVERY_STATISTIC_GENERAL: 'delivery-statistic-general',
  EASY_POST_INVOICES_LIST: 'easy-post-invoices-list',
  EXPENSE_GENERAL: 'expense-general',
  EXPRESS_PRICE_GENERAL: 'express-price-general',
  INCOME_GENERAL: 'income-general',
  INSURANCE_GENERAL: 'insurance-general',
  FAST_EXPRESS_ORDER: 'fast-express-order',
  FAST_ORDER_GENERAL: 'fast-order-general',
  FORBIDDEN_PAYMENT_GENERAL: 'forbidden-payment-general',
  FORBIDDEN_WORDS_GENERAL: 'forbidden-words-general',
  FRESH_CHAT_GENERAL: 'fresh-chat-general',
  FBM_SCAN_FORM: 'fbm-scan-form',
  IMPERSONALIZATION_MODE: 'impersonalization-mode',
  INVOICES_CDEK: 'invoices-cdek',
  INVOICES_CONSOLIDATION: 'invoices-consolidation',
  INVOICES_FBA: 'invoices-fba',
  INVOICES_FBA_RETURN: 'invoices-fba-return',
  INVOICES_GENERAL: 'invoices-general',
  INVOICES_NOVAPOSHTA: 'invoices-novaposhta',
  INVOICES_ORDER_EXPRESS: 'invoices-order-express',
  INVOICES_ORDERS: 'invoices-orders',
  INVOICES_OTHER: 'invoices-other',
  INVENTORY_GENERAL: 'inventory-general',
  IOSS_NUMBERS_GENERAL: 'ios-numbers-general',
  LISTING_DOWNLOAD_GENERAL: 'listing-download-general',
  MY_PAYMENT_AUTHORIZE: 'my-payment-authorize',
  MY_PAYMENT_GENERAL: 'my-payment-general',
  MY_PAYMENT_PAYPAL: 'my-payment-paypal',
  MY_PAYMENT_BRAINTREE: 'my-payment-braintree',
  NOTIFICATION_GENERAL: 'notification-general',
  NEGATIVE_BALANCE_GENERAL: 'negative-balance-general',
  ORDER_DISPOSAL_GENERAL: 'order-disposal-general',
  ORDER_EXPRESS_DHL: 'order-express-dhl',
  ORDER_EXPRESS_FEDEX: 'order-express-fedex',
  ORDER_EXPRESS_GENERAL: 'order-express-general',
  ORDER_EXPRESS_TNT: 'order-express-tnt',
  ORDER_EXPRESS_NPG: 'order-express-npg',
  ORDER_EXPRESS_VIEW_BLOCKED_DOCS: 'order-express-view-blocked-docs',
  ORDER_EXTERNAL_AMAZON: 'order-external-amazon',
  ORDER_EXTERNAL_EBAY: 'order-external-ebay',
  ORDER_EXTERNAL_ETSY: 'order-external-etsy',
  ORDER_EXTERNAL_GENERAL: 'order-external-general',
  ORDER_EXTERNAL_OPENCART: 'order-external-opencart',
  ORDER_EXTERNAL_SHOPIFY: 'order-external-shopify',
  ORDER_EXTERNAL_WOOCOMMERCE: 'order-external-woocommerce',
  ORDER_EXTERNAL_EXPRESS_POSHTA: 'order-external-express-poshta',
  ORDER_FBA_GENERAL: 'order-fba-general',
  ORDER_FBM_CONSOLIDATION: 'order-fbm-consolidation',
  ORDER_FBM_GENERAL: 'order-fbm-general',
  ORDER_FBM_LABEL: 'order-fbm-label',
  ORDER_FBM_WAREHOUSE: 'order-fbm-warehouse',
  ORDER_INBOUND_FBA: 'order-inbound-fba',
  ORDER_INBOUND_FBM: 'order-inbound-fbm',
  ORDER_INBOUND_GENERAL: 'order-inbound-general',
  ORDER_INBOUND_RETURNS: 'order-inbound-returns',
  ORDER_INTERNAL_BELPOSHTA: 'order-internal-belposhta',
  ORDER_INTERNAL_CDEK: 'order-internal-cdek',
  ORDER_INTERNAL_GENERAL: 'order-internal-general',
  ORDER_INTERNAL_NOVAPOSHTA: 'order-internal-novaposhta',
  ORDER_INTERNAL_UKRPOSHTA: 'order-internal-ukrposhta',
  ORDER_PRICE_GENERAL: 'order-price-general',
  ORDER_NOTIFICATION_GENERAL: 'order-notification-general',
  ORDER_SIZES_ACCESS: 'order-sizes-access',
  ORDER_MEEST_GENERAL: 'order-meest-general',
  OTHER_PAYMENT_MANAGE_GENERAL: 'other-payment-manage-general',
  PACKING_PRICE_GENERAL: 'packing-price-general',
  PARCELS_GENERAL: 'parcels-general',
  PAYMENT_LISTING_ACTIVE: 'payment-listing-active',
  PAYMENT_LISTING_GENERAL: 'payment-listing-general',
  PAYMENT_LISTING_PAYPAL: 'payment-listing-paypal',
  PAYMENT_LISTING_SOLD: 'payment-listing-sold',
  PAYMENT_HISTORY_GENERAL: 'payment-history-general',
  PAYPAL_CASE_GENERAL: 'paypal-case-general',
  PAYPAL_PAY_DASHBOARD: 'paypal-pay-dashboard',
  PRODUCT_GENERAL: 'product-general',
  PRODUCT_IMPORT_GENERAL: 'product-import-general',
  REFILL_PRODUCTS_GENERAL: 'refill-products-general',
  PROFILE_EXPENSES: 'profile-expenses',
  PROFILE_INCOME: 'profile-income',
  PROFILE_GENERAL: 'profile-general',
  PROFILE_LABEL_PACKAGING: 'profile-label-packaging',
  PROFILE_PACKAGING: 'profile-packaging',
  PROFILE_PRODUCTS: 'profile-products',
  PROFILE_SIMPLE_PRODUCT: 'profile-simple-product',
  PROFORM_GENERAL: 'proform-general',
  PROMOCODE_SETTINGS: 'promocode-settings',
  PROMOCODE_SHOW: 'promocode-show',
  REFUNDS_GENERAL: 'refunds-general',
  CONSOLIDATION_UNION_ORDER_GENERAL: 'consolidation-union-order-general',
  REPORT_ORDERS_IN_CONSOLIDATION_GENERAL: 'report-orders-in-consolidation',
  REPORT_ORDER_FBM_PRODUCTS_GENERAL: 'report-order-fbm-products-general',
  REPORT_ORDER_FBA_PRODUCTS_GENERAL: 'report-order-fba-products-general',
  REPORT_ORDER_FBM_ADMIN_PROCESSED_GENERAL: 'report-fbm-admin-processed-general',
  REPORT_CELLS_GENERAL: 'report-cells-general',
  REPORT_PRODUCTS_GENERAL: 'report-products-general',
  ADMIN_REPORT_PRODUCTS_GENERAL: 'admin-report-products-general',
  REPORT_SEND_TRACKING_NUMBER_GENERAL: 'report-send-tracking-number-general',
  TRACKING_FOR_PAYPAL_GENERAL: 'tracking-for-paypal-general',
  TRANSACTION_EXPORT: 'transaction-export',
  REPORT_CONNECTION_HISTORY_GENERAL: 'report-connection-history-general',
  REPORT_PROFIT: 'report-profit',
  RETURN_PARCEL_GENERAL: 'return-parcel-general',
  SETTING_GENERAL: 'setting-general',
  SHOPS_DECLINED: 'shops-declined',
  SHOPS_GENERAL: 'shops-general',
  SHOPS_UNVERIFIED: 'shops-unverified',
  SHOPS_VERIFIED: 'shops-verified',
  SHOPS_UKRAINIAN_TREASURES_TYPE: 'shops-ukrainian-treasures-type',
  SENT_SMS_GENERAL: 'sent-sms-general',
  SELECT_ALL_SCAN_PRODUCTS: 'select-all-scan-products',
  // SYSTEM_ADMIN_AUTH_GENERAL: 'system-admin-auth-general',
  TRANSLATION_MODE: 'translation-mode',
  TRANSLATION_GENERAL: 'translation-general',
  UNDEFINED_PAYMENT_AUTHORIZE: 'undefined-payment-authorize',
  UNDEFINED_PAYMENT_ARCHIVED: 'undefined-payment-archived',
  UNDEFINED_PAYMENT_GENERAL: 'undefined-payment-general',
  UNDEFINED_PAYMENT_PAYPAL: 'undefined-payment-paypal',
  UNDEFINED_PAYMENT_BRAINTREE: 'undefined-payment-braintree',
  UPDATE_NOTIFICATION: 'update-notification',
  USER_MANAGE_ACCESS_GENERAL: 'user-manage-access-general',
  USER_MANAGE_ACCESS_SUB_ADMIN: 'user-manage-access-sub-admin',
  USER_MANAGE_ACCESS_SUB_USER: 'user-manage-access-sub-user',
  USER_CAN_USE_SCALES: 'user-can-use-scales',
  USERS_GENERAL: 'users-general',
  USERS_EDIT: 'users-edit-general',
  USER_BALANCE_GRAPHIC_STATISTIC: 'user_balance_graphic_statistic',
  UKRAINIAN_TREASURES_ACCESS: 'ukrainian-treasures-general',
  UKRAINIAN_TREASURES_REVIEWS: 'ukrainian-treasures-reviews',
  UKRAINIAN_TREASURES_SETTINGS: 'ukrainian-treasures-settings',
  UKRAINIAN_TREASURES_SHIPPING_RATE: 'ukrainian-treasures-shipping-rate',
  VIEW_ADMIN_ROUTE_PREFIX: 'view-admin-route-prefix',
  WAREHOUSE_GENERAL: 'warehouse-general',
  WAREHOUSE_STORAGE_CELL_GENERAL: 'warehouse-storage-cell-general',
  WAREHOUSE_STORAGE_ORDER_BINS_GENERAL: 'warehouse-storage-order-bins-general',
  WITHOUT_TWO_FACTOR_AUTH: 'without-two-factor-auth',
  SCAN_BARCODE_GENERAL: 'scan-barcode-general',
  SCAN_FBM_LABEL_GENERAL: 'scan-fbm-labels',
}
