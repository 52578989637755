import {API} from "../../axios/axiosMainUrl";

const prefix = 'consolidation-delivery-price'
const prefixDeliveryAPI = 'delivery-api'

export const shippingCost = {
  state: {
    shippingCost: [],
    shippingCostItem: {},
    shippingCostCommonList: [],
    shippingCostBtn: false,
    shippingCostLoading: true,
    nextShippingCostPage: false,
    shippingCostForPage: 25,
    shippingCostFilter: '',
  },

  mutations: {
    setShippingCostFilter: (state, payload) => {
      state.shippingCostFilter = payload
    },

    setShippingCost: (state, payload) => {
      if(state.nextShippingCostPage) {
        state.shippingCost = state.shippingCost.concat(payload.shippingCost)
      } else {
        state.shippingCost = payload.shippingCost
      }
    },

    setShippingCostCommonList: (state, payload) => {
      state.shippingCostCommonList = payload.shippingCostCommonList
    },

    setNextShippingCostPage: (state, payload) => {
      state.nextShippingCostPage = payload
    },

    setShippingCostItem: (state, payload) => {
      state.shippingCostItem = payload.shippingCostItem
    },

    setShippingCostLoadingStart: (state) => {
      state.shippingCostLoading = true
    },

    setShippingCostLoadingEnd: (state) => {
      state.shippingCostLoading = false
    },

    changeShippingCostBtn: (state) => {
      state.shippingCostBtn = true
    },
    successShippingCostBtn: (state) => {
      state.shippingCostBtn = false
    },
  },

  getters: {
    getShippingCostFilter: state => {
      return state.shippingCostFilter
    },

    getShippingCostForPage: state => {
      return state.shippingCostForPage
    },

    getShippingCost: state => {
      return state.shippingCost
    },

    getShippingCostLoading: state => {
      return state.shippingCostLoading
    },


    getNextShippingCostPage: state => {
      return state.nextShippingCostPage
    },

    getShippingCostItem: state => {
      return state.shippingCostItem
    },

    getShippingCostCommonList: state => {
      return state.shippingCostCommonList
    },

    getShippingCostBtn: state => {
      return state.shippingCostBtn
    },
  },

  actions: {

    async fetchShippingCost({commit, getters}, filter = '') {
      if(!getters.getNextShippingCostPage)
        commit('setShippingCostLoadingStart')
      try {
        return await API.get(`/${prefix}${filter}`).then((response) =>{
          commit('setShippingCost',{shippingCost: response.data.data.data})
          commit('setShippingCostCommonList',{shippingCostCommonList: response.data.data})
          commit('setShippingCostLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setShippingCostLoadingEnd');
        return e;
      }
    },

    async getShippingCostItem({commit}, id) {
      try {
        return await API.get(`/${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setShippingCostItem', {shippingCostItem: response.data.data})
          commit('setShippingCostLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setShippingCostLoadingEnd');
        return e;
      }
    },

    async createShippingCost({commit}, data) {
      commit('changeShippingCostBtn');
      try {
        return await API.post(`/${prefix}`, data).then((response) =>{
          commit('successShippingCostBtn');
          return response;
        });
      } catch (e) {
        commit('successShippingCostBtn');
        return e;
      }
    },

    async updateShippingCost({commit}, {id, data}) {
      commit('changeShippingCostBtn');
      try {
        return await API.put(`/${prefix}/${id}`, data).then((response) =>{
          commit('successShippingCostBtn');
          return response;
        });
      } catch (e) {
        commit('successShippingCostBtn');
        return e;
      }
    },

    async deleteShippingCost({commit}, id) {
      try {
        return await API.delete(`/${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successShippingCostBtn');
        return e;
      }
    },

    async getShippingCostCalcCreate({commit}) {
      try {
        return await API.get(`/${prefixDeliveryAPI}/calculator/create`).then((response) =>{
          commit('setShippingCostLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setShippingCostLoadingEnd');
        return e;
      }
    },

    async getShippingCostCalcResult({commit}, data) {
      commit('changeShippingCostBtn');
      try {
        return await API.post(`/${prefixDeliveryAPI}/calculator/get-cost`, data).then((response) =>{
          commit('successShippingCostBtn');
          return response;
        });
      } catch (e) {
        commit('successShippingCostBtn');
        return e;
      }
    },

  }
}
