import {SOCKET_MODE} from "../../socket/socket-config";

export const socketMixin = {
  methods: {
    prepareCreatePrivateSocketChannel(type = false) {
      let commonAuthorized = this.$store.getters.GET_COMMON_AUTHORIZED,
        event,
        channel

      if (SOCKET_MODE === 'OFF') {
        return {active: false}
      }

      if (this._.has(commonAuthorized, 'user') && this._.has(commonAuthorized.user, 'id')) {
        switch (type) {
          case 'RequestReturn':
            event = this.$store.getters.getRequestReturnSocketEvent
            channel = this.$store.getters.getRequestReturnSocketChannel
            break
          case 'ForbiddenGoodsRefund':
            event = this.$store.getters.getForbiddenGoodsSocketEvent
            channel = this.$store.getters.getForbiddenGoodsSocketChannel
            break
          case 'EtsyTransfer':
            event = this.$store.getters.getEtsyTransferSocketEvent
            channel = this.$store.getters.getEtsyTransferSocketChannel
            break
          case 'RequestFunds':
            event = this.$store.getters.getRequestFundsSocketEvent
            channel = this.$store.getters.getRequestFundsSocketChannel
            break
          case 'FastOrders':
            event = this.$store.getters.getFastOrdersSocketEvent
            channel = this.$store.getters.getFastOrdersSocketChannel
            break
          case 'HeartysanChat':
            event = this.$store.getters.getHeartysanChatSocketEvent
            channel = this.$store.getters.getHeartysanChatSocketChannel
            break
          case 'HeartysanProducts':
            event = this.$store.getters.getHeartysanProductsSocketEvent
            channel = this.$store.getters.getHeartysanProductsSocketChannel
            break
          case 'ExportRequests':
            event = this.$store.getters.getExportRequestsSocketEvent
            channel = this.$store.getters.getExportRequestsSocketChannel
            break
          case 'GenerateIE559':
            event = this.$store.getters.getIE559ReportSocketEvent
            channel = this.$store.getters.getIE559ReportSocketChannel
            break
          default:
            alert('no socket type')
            break
        }

        return {
          active: true,
          user: commonAuthorized.user.id,
          event: event,
          channel: channel,
        }
      }

      return {active: false}
    },

    destroySocketChannel(channelName, storeSetter) {
      let prepareSocket = this.prepareCreatePrivateSocketChannel(channelName)
      console.log(`close ${channelName} channel`);
      window.Echo.leave(`${prepareSocket.channel}.${prepareSocket.user}`)
      this.$store.commit(storeSetter, false)
    },

  }
}


export const requestReturnSocketMixin = {
  mixins: [socketMixin],

  methods: {
    createRequestReturnSocket(prepareSocket) {
      window.Echo.private(`${prepareSocket.channel}.${prepareSocket.user}`)
        .listen(`.${prepareSocket.event}`, (response) => {
          let tableData = this.$store.getters.getRequestReturn
          let indexTableData = this._.findKey(tableData, {id: response.data.id})
          console.log('request return socket', response.data);
          tableData[indexTableData].status = response.data.status
          tableData[indexTableData].payment_transaction = response.data
          tableData[indexTableData]['net_amount_from_socket'] = response.data?.net_amount || '0.00'
          tableData[indexTableData].in_draft = 0
        }).error(e => {
        console.log(e);
      })
    },
  }
}

export const forbiddenGoodsSocketMixin = {
  mixins: [socketMixin],

  methods: {
    createForbiddenGoodsSocket(prepareSocket, success, error) {
      this.$store.commit('setForbiddenGoodsSocket', true)

      window.Echo.private(`${prepareSocket.channel}.${prepareSocket.user}`)
        .listen(`.${prepareSocket.event}`, (response) => {

          if (response.data.status === success.status) {
            success.successFunc()
          }

          if (response.data.status === error.status) {
            error.errorFunc()
          }
        }).error(e => {
        console.log(e);
      })
    }
  }
}

export const etsyTransferSocketMixin = {
  mixins: [socketMixin],

  methods: {
    createEtsyTransferSocket(prepareSocket, functionEvent) {
      this.$store.commit('setEtsyTransferSocket', true)

      window.Echo.private(`${prepareSocket.channel}.${prepareSocket.user}`)
        .listen(`.${prepareSocket.event}`, (response) => {
          functionEvent(response)
        }).error(e => {
        console.log(e);
      })
    }
  }
}

export const heartysanChatSocketMixin = {
  mixins: [socketMixin],

  methods: {
    createHeartysanChatSocket(prepareSocket, functionEvent) {
      this.$store.commit('setHeartysanChatSocket', true)
      window.Echo.private(`${prepareSocket.channel}.${prepareSocket.user}`)
        .listen(`.${prepareSocket.event}`, (response) => {
          console.log(7777);
          console.log(response);
          functionEvent(response)
        }).error(e => {
        console.log(e);
      })
    }
  }
}

export const heartysanProductsSocketMixin = {
  mixins: [socketMixin],

  methods: {
    createHeartysanProductsSocket(prepareSocket, functionEvent) {
      this.$store.commit('setHeartysanProductsSocket', true)
      window.Echo.listen(`${prepareSocket.channel}`, `.${prepareSocket.event}`, (response) => {
          console.log(8888);
          console.log(response);
          functionEvent(response)
        }).error(e => {
        console.log(e);
      })
    }
  }
}

export const exportRequestsSocketMixin = {
  mixins: [socketMixin],

  methods: {
    createExportRequestsSocket(prepareSocket, functionEvent) {
      this.$store.commit('setExportRequestsSocket', true)

      window.Echo.private(`${prepareSocket.channel}.${prepareSocket.user}`)
        .listen(`.${prepareSocket.event}`, (response) => {
          functionEvent(response)
        }).error(e => {
        console.log(e);
      })
    }
  }
}

export const IE559ReportSocketMixin = {
  mixins: [socketMixin],

  methods: {
    createIE559ReportSocket(prepareSocket, functionEvent) {
      this.$store.commit('setIE559ReportSocket', true)
      window.Echo.listen(`${prepareSocket.channel}`, `.${prepareSocket.event}`, (response) => {
          functionEvent(response)
        }).error(e => {
        console.log(e);
      })
    }
  }
}