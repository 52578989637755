var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header__inner"},[(_vm.$route.meta && _vm.$route.meta.moduleKey === 'dashboard')?_c('div',{staticClass:"header__support"},[_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
            'header_whatsappSupport',
            'header_whatsappSupportTel',
            'header_whatsappSupportHref',
            ])}}}),_c('a',{staticClass:"header__support-item",attrs:{"href":_vm.$t('header_whatsappSupportHref.localization_value.value'),"target":"_blank"}},[_c('span',[_vm._v(_vm._s(_vm.$t('header_whatsappSupport.localization_value.value')))]),_vm._v(" "),_c('b',[_vm._v(_vm._s(_vm.$t('header_whatsappSupportTel.localization_value.value')))])])]),_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
            'header_supportService',
            'header_supportServiceTel',
            'header_supportServiceHref',
            ])}}}),_c('a',{staticClass:"header__support-item",attrs:{"href":_vm.$t('header_supportServiceHref.localization_value.value')}},[_c('span',[_vm._v(_vm._s(_vm.$t('header_supportService.localization_value.value')))]),_vm._v(" "),_c('b',[_vm._v(_vm._s(_vm.$t('header_supportServiceTel.localization_value.value')))])])])]):_vm._e(),_c('GuideBlock'),_c('MultiSearch',{on:{"multiSearch":(e, checkPressedKey, searchString) => { _vm.$emit('multiSearch', e, checkPressedKey, searchString) }}}),_c('div',{staticClass:"header__right-btn"},[_c('div',{staticClass:"header__settings"},[_c('HeaderSettings',{on:{"logout":function($event){return _vm.$emit('logout')}}})],1),_c('div',{staticClass:"header__menu"},[_c('div',{staticClass:"header__menu-btn",on:{"click":function($event){return _vm.$store.commit('SET_MOBILE_MENU', !_vm.$store.getters.GET_MOBILE_MENU)}}},[(_vm.$store.getters.GET_MOBILE_MENU == false)?_c('BurgerIco'):_c('BurgerCloseIco')],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }