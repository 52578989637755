import {API} from "../../axios/axiosMainUrl";

const prefix = '/delivery-tracking-log'

export const trackingLog = {
  state: {
    trackingLog: [],
    trackingLogItem: {},
    trackingLogCommonList: [],
    trackingLogBtn: false,
    trackingLogLoading: true,
    nextTrackingLogPage: false,
    trackingLogForPage: 25,
    trackingLogFilter: '',
  },

  mutations: {
    setTrackingLogFilter: (state, payload) => {
      state.trackingLogFilter = payload
    },

    setTrackingLog: (state, payload) => {
      if(state.nextTrackingLogPage) {
        state.trackingLog = state.trackingLog.concat(payload.trackingLog)
      } else {
        state.trackingLog = payload.trackingLog
      }
    },

    setTrackingLogCommonList: (state, payload) => {
      state.trackingLogCommonList = payload.trackingLogCommonList
    },

    setNextTrackingLogPage: (state, payload) => {
      state.nextTrackingLogPage = payload
    },

    setTrackingLogItem: (state, payload) => {
      state.trackingLogItem = payload.trackingLogItem
    },

    setTrackingLogLoadingStart: (state) => {
      state.trackingLogLoading = true
    },

    setTrackingLogLoadingEnd: (state) => {
      state.trackingLogLoading = false
    },

    changeTrackingLogBtn: (state) => {
      state.trackingLogBtn = true
    },
    successTrackingLogBtn: (state) => {
      state.trackingLogBtn = false
    },
  },

  getters: {
    getTrackingLogFilter: state => {
      return state.trackingLogFilter
    },

    getTrackingLogForPage: state => {
      return state.trackingLogForPage
    },

    getTrackingLog: state => {
      return state.trackingLog
    },

    getTrackingLogLoading: state => {
      return state.trackingLogLoading
    },


    getNextTrackingLogPage: state => {
      return state.nextTrackingLogPage
    },

    getTrackingLogItem: state => {
      return state.trackingLogItem
    },

    getTrackingLogCommonList: state => {
      return state.trackingLogCommonList
    },

    getTrackingLogBtn: state => {
      return state.trackingLogBtn
    },
  },

  actions: {

    async fetchTrackingLog({commit, getters}, filter = '') {
      if(!getters.getNextTrackingLogPage)
        commit('setTrackingLogLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setTrackingLog',{trackingLog: response.data.data.data})
          commit('setTrackingLogCommonList',{trackingLogCommonList: {...response.data.data.meta, ...{
              next_page_url: response.data.data.links.next,
              first_page_url: response.data.data.links.first,
              last_page_url: response.data.data.links.last,
              prev_page_url: response.data.data.links.prev
            }}})
          commit('setTrackingLogLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setTrackingLogLoadingEnd');
        return e;
      }
    },

    async fetchParcelsTwoWeek({commit, getters}, filter = '') {
      if(!getters.getNextTrackingLogPage)
        commit('setTrackingLogLoadingStart')
      try {
        return await API.get(`${prefix}/no-delivery${filter}`).then((response) =>{
          commit('setTrackingLog',{trackingLog: response.data.data.data})
          commit('setTrackingLogCommonList',{trackingLogCommonList: {...response.data.data.meta, ...{
              next_page_url: response.data.data.links.next,
              first_page_url: response.data.data.links.first,
              last_page_url: response.data.data.links.last,
              prev_page_url: response.data.data.links.prev
            }}})
          commit('setTrackingLogLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setTrackingLogLoadingEnd');
        return e;
      }
    },

    // async fetchParcelsTwoWeek({commit, getters}, filter = '') {
    //   if(!getters.getNextTrackingLogPage)
    //     commit('setTrackingLogLoadingStart')
    //   try {
    //     return await API.get(`${prefix}/get-info${filter}`).then((response) =>{
    //       commit('setTrackingLog',{trackingLog: response.data.data.data})
    //       commit('setTrackingLogCommonList',{trackingLogCommonList: {...response.data.data.meta, ...{
    //           next_page_url: response.data.data.links.next,
    //           first_page_url: response.data.data.links.first,
    //           last_page_url: response.data.data.links.last,
    //           prev_page_url: response.data.data.links.prev
    //         }}})
    //       commit('setTrackingLogLoadingEnd');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('setTrackingLogLoadingEnd');
    //     return e;
    //   }
    // },

    async getTrackingLog({commit}, id) {
      // commit('setTrackingLogLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setTrackingLogItem', {trackingLogItem: response.data.data})
          // commit('setTrackingLogLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setTrackingLogLoadingEnd');
        return e;
      }
    },

    async getTrackingLogDeliveredStatistics({commit}, data = '') {
      // commit('setTrackingLogLoadingStart');
      try {
        return await API.get(`${prefix}/get-delivered-statistics${data}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setTrackingLogLoadingEnd');
        return e;
      }
    },

    async createTrackingLog({commit}, data) {
      commit('changeTrackingLogBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successTrackingLogBtn');
          return response;
        });
      } catch (e) {
        commit('successTrackingLogBtn');
        return e;
      }
    },

    async updateTrackingLog({commit}, {id, data}) {
      commit('changeTrackingLogBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successTrackingLogBtn');
          return response;
        });
      } catch (e) {
        commit('successTrackingLogBtn');
        return e;
      }
    },

    async deleteTrackingLog({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successTrackingLogBtn');
        return e;
      }
    },

  }
}
