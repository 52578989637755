
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const ProformCategoryTable = () => import(/* webpackChunkName: "group-request-return" */ './components/ProformCategoryTable/ProformCategoryTable.vue')
const moduleKey = 'proform-category'


export const ProformCategoryRoutes = [
  {
    path: routerPaths.mainSettingsProformCategory,
    component: ProformCategoryTable,
    meta: {
      breadcrumbs: {
        key: [
          'dashboard',
          'ProformCategory'
        ],
        items: {
          'dashboard': {
            name: 'menu_proform',
            link: routerPaths.mainSettingsProformCategory,
          },
          'ProformCategory': {
            name: 'menu_proformCategory',
            link: routerPaths.mainSettingsProformCategory,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.PROFORM_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_proformCategory'
    },
  },

]
