import {API} from "../../axios/axiosMainUrl";

const prefix = 'api-etsy-listing';
const downloaded = 'products/listing-download';
const shopApi = 'shop-api/listing-transfer';

export const etsyTransfer = {
  state: {
    etsyTransfer: [],
    etsyTransferItem: {},
    etsyTransferCommonList: [],
    etsyTransferBtn: false,
    etsyTransferLoading: true,
    nextEtsyTransferPage: false,
    etsyTransferForPage: 25,
    etsyTransferFilter: '',


    etsyTransferShopItems: {},

    etsyTransferSocket: false,
    etsyTransferSocketChannel: 'listing-transfer-channel',
    etsyTransferSocketEvent: 'listing.transfer',
  },

  mutations: {
    setEtsyTransferFilter: (state, payload) => {
      state.etsyTransferFilter = payload
    },

    setEtsyTransfer: (state, payload) => {
      if(state.nextEtsyTransferPage) {
        state.etsyTransfer = state.etsyTransfer.concat(payload.etsyTransfer)
      } else {
        state.etsyTransfer = payload.etsyTransfer
      }
    },

    addEtsyTransferShopItems: (state, payload) => {
      let items = state.etsyTransferShopItems
      state.etsyTransferShopItems = {}
      items[payload.index] = payload.data
      state.etsyTransferShopItems = items
    },

    setEtsyTransferCommonList: (state, payload) => {
      state.etsyTransferCommonList = payload.etsyTransferCommonList
    },

    setNextEtsyTransferPage: (state, payload) => {
      state.nextEtsyTransferPage = payload
    },

    setEtsyTransferItem: (state, payload) => {
      state.etsyTransferItem = payload.etsyTransferItem
    },

    setEtsyTransferLoadingStart: (state) => {
      state.etsyTransferLoading = true
    },

    setEtsyTransferLoadingEnd: (state) => {
      state.etsyTransferLoading = false
    },

    changeEtsyTransferBtn: (state) => {
      state.etsyTransferBtn = true
    },
    successEtsyTransferBtn: (state) => {
      state.etsyTransferBtn = false
    },

    setEtsyTransferSocket: (state, payload) => {
      state.etsyTransferSocket = payload
    },
  },

  getters: {
    getEtsyTransferFilter: state => {
      return state.etsyTransferFilter
    },

    getEtsyTransferForPage: state => {
      return state.etsyTransferForPage
    },

    getEtsyTransfer: state => {
      return state.etsyTransfer
    },

    getEtsyTransferShopItems: state => {
      return state.etsyTransferShopItems
    },

    getEtsyTransferLoading: state => {
      return state.etsyTransferLoading
    },


    getNextEtsyTransferPage: state => {
      return state.nextEtsyTransferPage
    },

    getEtsyTransferItem: state => {
      return state.etsyTransferItem
    },

    getEtsyTransferCommonList: state => {
      return state.etsyTransferCommonList
    },

    getEtsyTransferBtn: state => {
      return state.etsyTransferBtn
    },

    getEtsyTransferSocket: state => {
      return state.etsyTransferSocket
    },

    getEtsyTransferSocketChannel: state => {
      return state.etsyTransferSocketChannel
    },

    getEtsyTransferSocketEvent: state => {
      return state.etsyTransferSocketEvent
    },
  },

  actions: {

    async fetchEtsyTransfer({commit, getters}, filter = '') {
      if(!getters.getNextEtsyTransferPage)
        commit('setEtsyTransferLoadingStart')
      try {
        return await API.get(`/${downloaded}${filter}`).then((response) =>{
          commit('setEtsyTransfer',{etsyTransfer: response.data.data.data})
          commit('setEtsyTransferCommonList',{etsyTransferCommonList: response.data.data})
          commit('setEtsyTransferLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setEtsyTransferLoadingEnd');
        return e;
      }
    },

    async getEtsyTransfer({commit}, id) {
      commit('setEtsyTransferLoadingStart');
      try {
        return await API.get(`/etsyTransfer/${id}`).then((response) =>{
          console.log(response)
          commit('setEtsyTransferItem', {etsyTransferItem: response.data.data})
          commit('setEtsyTransferLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setEtsyTransferLoadingEnd');
        return e;
      }
    },

    async createEtsyTransfer({commit}, data) {
      commit('changeEtsyTransferBtn');
      try {
        return await API.post(`/etsyTransfer`, data).then((response) =>{
          commit('successEtsyTransferBtn');
          return response;
        });
      } catch (e) {
        commit('successEtsyTransferBtn');
        return e;
      }
    },

    async updateEtsyTransfer({commit}, {id, data}) {
      commit('changeEtsyTransferBtn');
      try {
        return await API.put(`/etsyTransfer/${id}`, data).then((response) =>{
          commit('successEtsyTransferBtn');
          return response;
        });
      } catch (e) {
        commit('successEtsyTransferBtn');
        return e;
      }
    },

    async deleteEtsyTransfer({commit}, id) {
      try {
        return await API.delete(`/${downloaded}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successEtsyTransferBtn');
        return e;
      }
    },


    async getListingsForDownload({commit}, data) {
      commit('changeEtsyTransferBtn');
      try {
        return await API.post(`/${prefix}/get-listings-for-download`, data).then((response) =>{
          commit('successEtsyTransferBtn');
          return response;
        });
      } catch (e) {
        commit('successEtsyTransferBtn');
        return e;
      }
    },


    async getShopListingsInfo({commit}, data) {
      commit('changeEtsyTransferBtn');
      try {
        return await API.post(`/${prefix}/get-shop-info`, data).then((response) =>{
          commit('successEtsyTransferBtn');
          return response;
        });
      } catch (e) {
        commit('successEtsyTransferBtn');
        return e;
      }
    },

    async listingsTransferToShop({commit}, data) {
      commit('changeEtsyTransferBtn');
      try {
        return await API.post(`/${shopApi}`, data).then((response) =>{
          commit('successEtsyTransferBtn');
          return response;
        });
      } catch (e) {
        commit('successEtsyTransferBtn');
        return e;
      }
    },
  }
}
