import {API} from "../../axios/axiosMainUrl";

const heartysanUserTypes = '/heartysan/user-type'
const setHeartysanUser = '/heartysan/user'

export const heartysan = {
  state: {
    heartysanUsers: [],
    heartysanItem: {},
    heartysanUsersCommonList: [],
    heartysanBtn: false,
    heartysanUsersLoading: true,
    nextHeartysanUsersPage: false,
    heartysanUsersForPage: 25,
    heartysanFilter: '',
  },

  mutations: {
    setHeartysanFilter: (state, payload) => {
      state.heartysanFilter = payload
    },

    setHeartysanUsers: (state, payload) => {
      if(state.nextHeartysanUsersPage) {
        state.heartysanUsers = state.heartysanUsers.concat(payload.heartysanUsers)
      } else {
        state.heartysanUsers = payload.heartysanUsers
      }
    },

    setHeartysanUsersCommonList: (state, payload) => {
      state.heartysanUsersCommonList = payload.heartysanUsersCommonList
    },

    setNextHeartysanUsersPage: (state, payload) => {
      state.nextHeartysanUsersPage = payload
    },

    setHeartysanItem: (state, payload) => {
      state.heartysanItem = payload.heartysanItem
    },

    setHeartysanUsersLoadingStart: (state) => {
      state.heartysanUsersLoading = true
    },

    setHeartysanUsersLoadingEnd: (state) => {
      state.heartysanUsersLoading = false
    },

    changeHeartysanBtn: (state) => {
      state.heartysanBtn = true
    },
    successHeartysanBtn: (state) => {
      state.heartysanBtn = false
    },
  },

  getters: {
    getHeartysanFilter: state => {
      return state.heartysanFilter
    },

    getHeartysanUsersForPage: state => {
      return state.heartysanUsersForPage
    },

    getHeartysanUsers: state => {
      return state.heartysanUsers
    },

    getHeartysanUsersLoading: state => {
      return state.heartysanUsersLoading
    },


    getNextHeartysanUsersPage: state => {
      return state.nextHeartysanUsersPage
    },

    getHeartysanItem: state => {
      return state.heartysanItem
    },

    getHeartysanUsersCommonList: state => {
      return state.heartysanUsersCommonList
    },

    getHeartysanBtn: state => {
      return state.heartysanBtn
    },
  },

  actions: {

    async fetchHeartysanUsers({commit, getters}, filter = '') {
      if(!getters.getNextHeartysanUsersPage)
        commit('setHeartysanUsersLoadingStart')
      try {
        return await API.get(`${setHeartysanUser}${filter}`).then((response) =>{
          commit('setHeartysanUsers',{heartysanUsers: response.data.data.data})
          commit('setHeartysanUsersCommonList',{heartysanUsersCommonList: {...response.data.data.meta, ...{
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev
              }}})
          commit('setHeartysanUsersLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setHeartysanUsersLoadingEnd');
        return e;
      }
    },

    async getHeartysan({commit}, id) {
      commit('setHeartysanUsersLoadingStart');
      try {
        return await API.get(`${heartysanUserTypes}/${id}`).then((response) =>{
          console.log(response)
          commit('setHeartysanItem', {heartysanItem: response.data.data})
          commit('setHeartysanUsersLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setHeartysanUsersLoadingEnd');
        return e;
      }
    },

    async createHeartysan({commit}, data) {
      commit('changeHeartysanBtn');
      try {
        return await API.post(`${heartysanUserTypes}`, data).then((response) =>{
          commit('successHeartysanBtn');
          return response;
        });
      } catch (e) {
        commit('successHeartysanBtn');
        return e;
      }
    },

    async updateHeartysan({commit}, {id, data}) {
      commit('changeHeartysanBtn');
      try {
        return await API.put(`${heartysanUserTypes}/${id}`, data).then((response) =>{
          commit('successHeartysanBtn');
          return response;
        });
      } catch (e) {
        commit('successHeartysanBtn');
        return e;
      }
    },

    async deleteHeartysan({commit}, id) {
      try {
        return await API.delete(`${heartysanUserTypes}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successHeartysanBtn');
        return e;
      }
    },


    async getHeartysanTypes({commit}) {
      commit('setHeartysanUsersLoadingStart');
      try {
        return await API.get(`${heartysanUserTypes}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setHeartysanUsersLoadingEnd');
        return e;
      }
    },

    async setHeartysanTypes({commit}, data) {
      commit('changeHeartysanBtn');
      try {
        return await API.post(`${setHeartysanUser}`, data).then((response) =>{
          commit('successHeartysanBtn');
          return response;
        });
      } catch (e) {
        commit('successHeartysanBtn');
        return e;
      }
    },
  }
}
