import {API} from "../../axios/axiosMainUrl";

const prefix = '/error-logs/backend'

export const errorLogsBackend = {
  state: {
    errorLogsBackend: [],
    errorLogsBackendItem: {},
    errorLogsBackendCommonList: [],
    errorLogsBackendBtn: false,
    errorLogsBackendLoading: true,
    nextErrorLogsBackendPage: false,
    errorLogsBackendForPage: 25,
    errorLogsBackendFilter: '',
  },

  mutations: {
    setErrorLogsBackendFilter: (state, payload) => {
      state.errorLogsBackendFilter = payload
    },

    setErrorLogsBackend: (state, payload) => {
      if(state.nextErrorLogsBackendPage) {
        state.errorLogsBackend = state.errorLogsBackend.concat(payload.errorLogsBackend)
      } else {
        state.errorLogsBackend = payload.errorLogsBackend
      }
    },

    setErrorLogsBackendCommonList: (state, payload) => {
      state.errorLogsBackendCommonList = payload.errorLogsBackendCommonList
    },

    setNextErrorLogsBackendPage: (state, payload) => {
      state.nextErrorLogsBackendPage = payload
    },

    setErrorLogsBackendItem: (state, payload) => {
      state.errorLogsBackendItem = payload.errorLogsBackendItem
    },

    setErrorLogsBackendLoadingStart: (state) => {
      state.errorLogsBackendLoading = true
    },

    setErrorLogsBackendLoadingEnd: (state) => {
      state.errorLogsBackendLoading = false
    },

    changeErrorLogsBackendBtn: (state) => {
      state.errorLogsBackendBtn = true
    },
    successErrorLogsBackendBtn: (state) => {
      state.errorLogsBackendBtn = false
    },
  },

  getters: {
    getErrorLogsBackendFilter: state => {
      return state.errorLogsBackendFilter
    },

    getErrorLogsBackendForPage: state => {
      return state.errorLogsBackendForPage
    },

    getErrorLogsBackend: state => {
      return state.errorLogsBackend
    },

    getErrorLogsBackendLoading: state => {
      return state.errorLogsBackendLoading
    },


    getNextErrorLogsBackendPage: state => {
      return state.nextErrorLogsBackendPage
    },

    getErrorLogsBackendItem: state => {
      return state.errorLogsBackendItem
    },

    getErrorLogsBackendCommonList: state => {
      return state.errorLogsBackendCommonList
    },

    getErrorLogsBackendBtn: state => {
      return state.errorLogsBackendBtn
    },
  },

  actions: {

    async fetchErrorLogsBackend({commit, getters}, filter = '') {
      if(!getters.getNextErrorLogsBackendPage)
        commit('setErrorLogsBackendLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setErrorLogsBackend',{errorLogsBackend: response.data.data.data})
          commit('setErrorLogsBackendCommonList',{errorLogsBackendCommonList: response.data.data})
          commit('setErrorLogsBackendLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setErrorLogsBackendLoadingEnd');
        return e;
      }
    },

    async getErrorLogsBackend({commit}, id) {
      commit('setErrorLogsBackendLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setErrorLogsBackendItem', {errorLogsBackendItem: response.data.data})
          commit('setErrorLogsBackendLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setErrorLogsBackendLoadingEnd');
        return e;
      }
    },

    async createErrorLogsBackend({commit}, data) {
      commit('changeErrorLogsBackendBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successErrorLogsBackendBtn');
          return response;
        });
      } catch (e) {
        commit('successErrorLogsBackendBtn');
        return e;
      }
    },

    async updateErrorLogsBackend({commit}, {id, data}) {
      commit('changeErrorLogsBackendBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successErrorLogsBackendBtn');
          return response;
        });
      } catch (e) {
        commit('successErrorLogsBackendBtn');
        return e;
      }
    },

    async deleteErrorLogsBackend({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successErrorLogsBackendBtn');
        return e;
      }
    },

    async deleteCheckedErrorLogsBackend({commit}, data) {
      try {
        return await API.post(`${prefix}/delete-selectively`, data).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successErrorLogsBackendBtn');
        return e;
      }
    },

  }
}
