
import {API} from '../../../axios/axiosMainUrl'

export const roles = {
  state: {
    roles: [],
    roleItem: {},
    loadingRoles: false,
    loadingRoleItem: false,
    roleBtn: false,
  },

  mutations: {
    setRoles: (state, payload) => {
      state.roles = payload.roles
    },
    setRoleItem: (state, payload) => {
      state.roleItem = payload.roleItem
    },

    setLoadingStart: (state) => {
      state.loadingRoles = true
    },
    setLoadingRoleItemStart: (state) => {
      state.loadingRoles = true
    },

    setLoadingEnd: (state) => {
      state.loadingRoles = false
    },
    setLoadingRoleItemEnd: (state) => {
      state.loadingRoles = false
    },


    changeRole: (state) => {
      state.roleBtn = true
    },
    successRole: (state) => {
      state.roleBtn = false
    },
  },

  getters: {
    getRoles: state => {
      return state.roles
    },

    getRoleItem: state => {
      return state.roleItem
    },

    getRoleBtn: state => {
      return state.roleBtn
    }
  },

  actions: {
    async fetchRoleItem({commit}, roleId) {
      commit('setLoadingRoleItemStart');
      try {
        return await API.get(`access-controls/roles/${roleId}`).then((response) =>{
          commit('setRoleItem', {roleItem: response.data.data});
          commit('setLoadingRoleItemEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingRoleItemEnd');
        return e;
      }
    },

    async fetchRoles({commit}) {
      commit('setLoadingStart');
      try {
        return await API.get('access-controls/roles').then((response) =>{
          commit('setRoles', {roles: response.data.data.data});
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async updateRoles({commit}, {id, data}) {
      commit('setLoadingStart');
      try {
        return await API.put(`access-controls/roles/${id}`, data).then((response) =>{
          commit('setRoles', {roles: response.data.data});
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async createRoles({commit}, data) {
      commit('setLoadingStart');
      try {
        return await API.post('access-controls/roles', data).then((response) =>{
          // commit('setRoles', {roles: response.data.roles});
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },


    async deleteRoles({commit}, roleId) {
      commit('setLoadingStart');
      try {
        return await API.delete(`access-controls/roles/${roleId}`).then((response) =>{
          // commit('setRoles', {roles: response.data.roles});
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

  }
}
