

export const globalHelperMixin = {

  methods: {
    notifyErrorHelper(errors, withOut = []){

      if(Array.isArray(errors) && errors.length > 0){
        if(typeof errors[0][this._.first(Object.keys(errors[0]))] === 'object'){
          this.openNotify('error', {txtServer: errors[0][this._.first(Object.keys(errors[0]))] })
          return;
        }

        this.openNotify('error', {txtServer: errors[0]})
        return
      }

      let firstItemError = errors[this._.first(Object.keys(errors))]

      if(Array.isArray(firstItemError)){
        this.openNotify('error', {txtServer: this._.first(firstItemError)})
        return
      }

      if(errors.length > 0 && typeof errors === 'string'){
        this.openNotify('error', {txtServer: errors })
        return;
      }

      if(Object.keys(errors).length > 0){
        this.openNotify('error', {txtServer: errors[this._.first(Object.keys(errors))] })
      }

      console.log(withOut); // TODO withOut array
    },

    copyLink(link) {
      const el = document.createElement('textarea')
      el.value = link
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },

    hasHistory () {
      return window.history.length > 2
    },

    goRouterBack(link = false) {
      if(this.hasHistory()){
        this.$router.back()
        return
      }

      if(link){
        this.$router.push(link)
        return
      }

      this.$router.push(this.$store.getters.GET_PATHS.dashboard)
    },


    setPriceValue(value) {
      if(('' + value).indexOf('.') > -1){
        return value
      }
      return value + '.00'
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },

    downloadBlobFile(response, type, filename = false) {

      if(filename === false && response.headers['content-disposition']){
        let headerLine = response.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('=') + 1
        filename = headerLine.substring(startFileNameIndex)
      }

      const blob = new Blob([response.data], { type: `application/${type}` })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = filename ? filename : 'Default name'
      link.click()
      URL.revokeObjectURL(link.href)
    },


    capitalize(word) {
      return word[0].toUpperCase() + word.slice(1).toLowerCase();
    },

    setCookie(c_name, value, timestamp = 0) {
      let exdate = new Date(timestamp);
      let c_value = encodeURIComponent(value) + ((timestamp === 0) ? "; path=/;" : "; path=/; expires=" + exdate.toUTCString());
      console.log(c_name + "=" + c_value);
      //localStorage.setItem(c_name, c_name + "=" + c_value)
      document.cookie = c_name + "=" + c_value;
    },

    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text)
    },

    isObject(item) {
      return typeof item === 'object' && item !== null
    },

    getBetweenDates(startDate, stopDate) {
      let dateArray = [];
      let currentDate = this.$moment(startDate);
      let endDate = this.$moment(stopDate);

      while (currentDate.unix() <= endDate.unix()) {
        dateArray.push( this.$moment(currentDate).format('YYYY-MM-DD') )
        currentDate = this.$moment(currentDate).add(1, 'days');
      }
      return dateArray;
    }
  },


}
