import {API} from "../../axios/axiosMainUrl";

const prefix = 'order-inbound'

export const ordersInbound = {
  state: {
    ordersInbound: [],
    ordersInboundItem: {},
    ordersInboundCommonList: [],
    ordersInboundBtn: false,
    ordersInboundLoading: true,
    nextOrdersInboundPage: false,
    ordersInboundForPage: 25,
    ordersInboundFilter: '',

    ordersInboundLoadingPrice: false,
  },


  mutations: {
    setOrdersInboundFilter: (state, payload) => {
      state.ordersInboundFilter = payload
    },

    setOrdersInbound: (state, payload) => {
      if(state.nextOrdersInboundPage) {
        state.ordersInbound = state.ordersInbound.concat(payload.ordersInbound)
      } else {
        state.ordersInbound = payload.ordersInbound
      }
    },

    setOrdersInboundCommonList: (state, payload) => {
      state.ordersInboundCommonList = payload.ordersInboundCommonList
    },

    setNextOrdersInboundPage: (state, payload) => {
      state.nextOrdersInboundPage = payload
    },

    setOrdersInboundItem: (state, payload) => {
      state.ordersInboundItem = payload.ordersInboundItem
    },

    setOrdersInboundLoadingStart: (state) => {
      state.ordersInboundLoading = true
    },

    setOrdersInboundLoadingEnd: (state) => {
      state.ordersInboundLoading = false
    },

    changeOrdersInboundBtn: (state) => {
      state.ordersInboundBtn = true
    },
    successOrdersInboundBtn: (state) => {
      state.ordersInboundBtn = false
    },

    setOrdersInboundLoadingPriceStart: (state) => {
      state.ordersInboundLoadingPrice = true
    },
    setOrdersInboundLoadingPriceEnd: (state) => {
      state.ordersInboundLoadingPrice = false
    },
  },

  getters: {
    getOrdersInboundFilter: state => {
      return state.ordersInboundFilter
    },

    getOrdersInboundForPage: state => {
      return state.ordersInboundForPage
    },

    getOrdersInbound: state => {
      return state.ordersInbound
    },

    getOrdersInboundLoading: state => {
      return state.ordersInboundLoading
    },


    getNextOrdersInboundPage: state => {
      return state.nextOrdersInboundPage
    },

    getOrdersInboundItem: state => {
      return state.ordersInboundItem
    },

    getOrdersInboundCommonList: state => {
      return state.ordersInboundCommonList
    },

    getOrdersInboundBtn: state => {
      return state.ordersInboundBtn
    },

    getOrdersInboundLoadingPrice: state => {
      return state.ordersInboundLoadingPrice
    },
  },

  actions: {

    async fetchOrdersInbound({commit, getters}, {filter = '', noCommit = false}) {
      if(!getters.getNextOrdersInboundPage && !noCommit)
        commit('setOrdersInboundLoadingStart')
      try {
        return await API.get(`/${prefix}${filter}`).then((response) =>{
          if (!noCommit) {
            commit('setOrdersInbound',{ordersInbound: response.data.data.data})
            commit('setOrdersInboundCommonList',{ordersInboundCommonList: response.data.data})
            commit('setOrdersInboundLoadingEnd');
          }

          return response;
        });
      } catch (e) {
        commit('setOrdersInboundLoadingEnd');
        return e;
      }
    },

    async getOrderInboundEdit({commit}, id) {
      try {
        return await API.get(`/${prefix}/${id}/edit`).then((response) =>{
          commit('setOrdersInboundItem', {ordersInboundItem: response.data.data})
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async getOrderInbound({commit}, id) {
      try {
        return await API.get(`/${prefix}/${id}`).then((response) =>{
          commit('setOrdersInboundItem', {ordersInboundItem: response.data.data})
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async createOrderInbound({commit}, data) {
      commit('changeOrdersInboundBtn');
      try {
        return await API.post(`/${prefix}`, data).then((response) =>{
          commit('successOrdersInboundBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersInboundBtn');
        return e;
      }
    },

    async updateOrderInbound({commit}, {id, data}) {
      commit('changeOrdersInboundBtn');
      try {
        return await API.put(`/${prefix}/${id}`, data).then((response) =>{
          commit('successOrdersInboundBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersInboundBtn');
        return e;
      }
    },

    async updateInboundTrackingNumber({commit}, {id, data}) {
      commit('changeOrdersInboundBtn');
      try {
        return await API.put(`/${prefix}/${id}/set-tracking-number`, data).then((response) =>{
          commit('successOrdersInboundBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersInboundBtn');
        return e;
      }
    },

    async deleteOrderInbound({commit}, id) {
      try {
        return await API.delete(`/${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successOrdersInboundBtn');
        return e;
      }
    },

    async getOrderInboundType({commit}, id = false) {
      let typeId = ''
      if(id){
        typeId = `/${id}`
      }
      try {
        return await API.get(`/${prefix}/type${typeId}`).then((response) =>{
          return response
        });
      } catch (e) {
        commit('setOrdersInboundLoadingEnd');
        return e;
      }
    },


    async uploadFileOrderInbound({commit}, {data, id}) {
      commit('changeOrdersInboundBtn');
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${prefix}/${id}/upload-file`, data).then((response) =>{
          commit('successOrdersInboundBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successOrdersInboundBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },


    async getOrderInboundExport({commit}, {filter , exportType}) {
      commit('setExportBtnLoadingStart')
      try {
        return await API.get(`${prefix}/export/${exportType}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setLoadingEnd')
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

    async getOrderProcessFee({commit}, filter) {
      commit('changeOrdersInboundBtn')
      try {
        return await API.get(`/orders/order-process-fee/${filter}`).then((response) =>{
          commit('successOrdersFBMBtn')
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn')
        return e;
      }
    },

    async getInboundLabelRate({commit}, data) {
      commit('changeOrdersInboundBtn')
      commit('setOrdersInboundLoadingPriceStart')
      try {
        return await API.post(`${prefix}/rate`, data).then((response) =>{
          commit('successOrdersInboundBtn')
          commit('setOrdersInboundLoadingPriceEnd')
          return response;
        });
      } catch (e) {
        commit('setOrdersInboundLoadingPriceEnd')
        return e;
      }
    },

    async createInboundLabel({commit}, data) {
      commit('changeOrdersInboundBtn')
      try {
        return await API.post(`${prefix}/create-label`, data).then((response) =>{
          commit('successOrdersInboundBtn')
          return response;
        });
      } catch (e) {
        commit('successOrdersInboundBtn')
        return e;
      }
    },

    async getInboundOrderFileBlob({commit}, data) {
      commit('changeOrdersInboundBtn')
      let config = {
        headers: {'Access-Control-Expose-Headers' : 'Content-Disposition'},
        responseType: 'blob'
      }
      try {
        return await API.get(`${prefix}/${data.id}/marking/${data.type}`, config).then((response) =>{
          commit('successOrdersInboundBtn')
          return response
        })
      } catch (e) {
        commit('successOrdersInboundBtn')
        commit('setLoadingEnd')
        return e;
      }
    },

    async getInboundOrderFile({commit}, data) {
      commit('changeOrdersInboundBtn')
      try {
        return await API.get(`${prefix}/${data.id}/marking/${data.type}`).then((response) =>{
          commit('successOrdersInboundBtn')
          return response
        })
      } catch (e) {
        commit('successOrdersInboundBtn')
        commit('setLoadingEnd')
        return e;
      }
    },

    async updateInboundProductInCells({commit}, {id, data}) {
      commit('changeOrdersInboundBtn')
      try {
        return await API.patch(`${prefix}/${id}`, data).then((response) =>{
          commit('successOrdersInboundBtn')
          return response;
        });
      } catch (e) {
        commit('successOrdersInboundBtn')
        return e;
      }
    },
  }
}
