<template>
  <div class="side-bar__body side-bar-admin"
       :class="{'iphone' : iphoneDetect()}"
  >

<!--    'side-bar-admin' : $store.getters.getIsAdminRights === 'admin'-->

    <!--<div class="side-bar__content">-->


    <!--<vue-custom-scrollbar class="side-bar__content-scrollbar" :swicher="showScroll" :settings="settings">-->


    <div class="side-bar__head">
      <router-link :to="'/#'" class="side-bar__head-ico">
        <SkladUSALogo/>
      </router-link>
    </div>

    <div class="side-bar__user admin">
      <span @click="goToProfile" class="side-bar__user-ico cursor-pointer">
            <img v-if="$store.getters.getAvatarSmall.length > 0" :src="$store.getters.getAvatarSmall" alt="user-ico">
            <img v-else src="/img/profile-group/profile-photo-default.svg" alt="user-ico">
            <div class="side-bar__user-indicator side-bar__user-indicator--online"></div>
          </span>
<!--      <router-link :to="$store.getters.GET_PATHS.mainSettingsMyProfile" class="side-bar__user-ico">-->
<!--        <img v-if="$store.getters.getAvatarSmall.length > 0" :src="$store.getters.getAvatarSmall" alt="user-ico">-->
<!--        <img v-else src="/img/profile-group/profile-photo-default.svg" alt="user-ico">-->
<!--        <div class="side-bar__user-indicator side-bar__user-indicator&#45;&#45;online"></div>-->
<!--      </router-link>-->
      <div class="side-bar__user-info">
        <template v-if="$store.getters.getUserProfile.user_personal_contact">
          <span class="side-bar__user-name btn-style"
                @click="goToProfile"
          >
            {{ $store.getters.getUserProfile.user_personal_contact.user_full_name }}
          </span>
          <div class="side-bar__user-email">
            {{$store.getters.getUserProfile.user_personal_contact.email}}
          </div>
        </template>
      </div>
    </div>


    <!--<div class="side-bar__login-as">-->
      <!--<div style="color: white">-->
        <!--<DefaultSelectInside-->
            <!--class="black"-->
            <!--:options="$store.getters.getImpersonateUsers"-->
            <!--:optionsLabel="'email'"-->
            <!--:label="'Users'"-->
            <!--@change="changeUser"-->
            <!--@onBlur="onBlurSelect"-->
        <!--/>-->
      <!--</div>-->
    <!--</div>-->
    <SidebarSearch v-if="isAdmin" />

    <div class="side-bar__content-scrollbar-inner">
      <MenuScrollContainer>
        <template slot="content">
          <div class="side-bar__menu">

            <div class="side-bar__menu-item admin-edit-item"
                 v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.dashboard )}"
            >
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <DashboardIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.dashboard"
                >
                  {{ $t('menu_dashboard.localization_value.value') }}
                </router-link>
              </div>
              <span class="admin-edit" style="top: 2px;" v-if="!isMobileFunc()" @click="editTranslate(dashboardTranslates)"></span>
            </div>

            <!--NEED TRANSLATE-->
            <div class="side-bar__menu-item admin-edit-item"
                 v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.mainSettingsUsers )}"
                 v-if="_.has(this.currentPermissions, PERMISSIONS.USERS_GENERAL)"
            >
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <UsersIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.mainSettingsUsers"
                             v-bind:class="{open : checkActiveRoute($store.getters.GET_PATHS.mainSettingsUsers)}"
                >
                  {{ $t('menu_users.localization_value.value') }}
                </router-link>
              </div>
              <span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(['menu_users'])"></span>
            </div>

            <div class="side-bar__menu-item"
                 v-if="checkVisibleGroupMenu(ordersAndDispatchesList.links)"
                 v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.ordersAndDispatches ),
                   'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate}"
            >
              <span class="admin-edit" @click="editTranslate(ordersAndDispatchesTranslates)"
                    v-if="!isMobileFunc()"></span>

              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <OrdersDispatchesIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <div class="accordItemTitlesJs side-bar__menu-item-name"
                     v-bind:class="{open : checkActiveRoute($store.getters.GET_PATHS.ordersAndDispatches + '/')}"
                >
                  {{ $t('menu_ordersDispatches.localization_value.value') }}
                </div>
                <div class="side-bar__menu-submenu">
                  <MenuItem
                      v-for="(item, index) in ordersAndDispatchesList.links" :key="index"
                      :item="item"
                  />
                </div>
              </div>


            </div>


            <div class="side-bar__menu-item"
                 v-if="checkVisibleGroupMenu(financeList.links)"
                 v-bind:class="{active : checkActiveRoute('/custom') || checkActiveRoute('finance'),
             'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate}"
            >
              <span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(finance)"></span>
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <FinanceIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <div class="accordItemTitlesJs side-bar__menu-item-name"
                     v-bind:class="{open : checkActiveRoute('/custom') || checkActiveRoute('finance')}"
                >
                  {{ $t('menu_finance.localization_value.value') }}
                </div>
                <div class="side-bar__menu-submenu">
                  <MenuItem
                      v-for="(item, index) in financeList.links" :key="index"
                      :item="item"
                  />
                </div>
              </div>
            </div>

            <div class="side-bar__menu-item admin-edit-item"
                 v-if="checkVisibleGroupMenu(warehousesList.links)"
                 v-bind:class="{active : checkActiveRoute('warehouse'),
             'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate}">
              <span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(warehouseTranslates)"></span>
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <WarehouseIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <div class="accordItemTitlesJs side-bar__menu-item-name"
                     v-bind:class="{open : checkActiveRoute('warehouse')}">
                  {{ $t('menu_warehouse.localization_value.value') }}
                </div>

                <div class="side-bar__menu-submenu">

                  <MenuItem
                      v-for="(item, index) in warehousesList.links" :key="index"
                      :item="item"
                  />

                </div>
              </div>
            </div>


            <div class="side-bar__menu-item"
                 v-if="checkVisibleGroupMenu(problemsList.links)"
                 v-bind:class="{
                   'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate,
                    active : checkActiveRoute('problems')
                 }">
              <span class="admin-edit" @click="editTranslate(problemsTranslates)" v-if="!isMobileFunc()"></span>
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <ProblemsIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <div class="accordItemTitlesJs side-bar__menu-item-name"
                     v-bind:class="{open : checkActiveRoute('problems')}">
                  {{ $t('menu_problems.localization_value.value') }}
                </div>
                <div class="side-bar__menu-submenu">
                  <MenuItem
                      v-for="(item, index) in problemsList.links" :key="index"
                      :item="item"
                  />
                </div>
              </div>
            </div>


            <div class="side-bar__menu-item"
                 v-if="checkVisibleGroupMenu(incomeExpressList.links)"
                 v-bind:class="{
                  active : checkActiveRoute('income-and-expenses'),
                  'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate
                }">
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <IncomeExpensesIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <div class="side-bar__menu-item-name accordItemTitlesJs"
                     v-bind:class="{open : checkActiveRoute('income-and-expenses')}">
                  {{ $t('menu_incomeExpenses.localization_value.value') }}
                </div>
                <div class="side-bar__menu-submenu">

                  <MenuItem
                      v-for="(item, index) in incomeExpressList.links" :key="index"
                      :item="item"
                  />
                </div>
              </div>

              <span class="admin-edit" @click="editTranslate(incomeTranslates)" v-if="!isMobileFunc()"></span>
            </div>

<!--                 v-if="checkVisibleGroupMenu(reportsList.links)"-->
            <div class="side-bar__menu-item"
                 v-if="checkVisibleGroupMenu(reportsList.links)"
                 v-bind:class="{
                  active : checkActiveRoute('/reports/'),
                'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate
              }">

              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <ReportIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <div class="side-bar__menu-item-name accordItemTitlesJs"
                     v-bind:class="{open : checkActiveRoute('/reports/')}">
                  {{ $t('menu_reports.localization_value.value') }}
                </div>

                <div class="side-bar__menu-submenu">

                  <MenuItem
                      v-for="(item, index) in reportsList.links" :key="index"
                      :item="item"
                  />
                </div>
              </div>
              <span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(reportsTranslates)"></span>
            </div>


            <!--NEED TRANSLATE-->
            <!--<div class="side-bar__menu-item admin-edit-item"-->
                 <!--v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.www )}"-->
            <!--&gt;-->
              <!--<div class="side-bar__menu-item-ico">-->
                <!--<div class="side-bar__menu-item-ico-i">-->
                  <!--<OrdersDispatchesIco/>-->
                <!--</div>-->
              <!--</div>-->
              <!--<div class="side-bar__menu-item-content">-->
                <!--<router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.dashboard"-->
                             <!--v-bind:class="{open : checkActiveRoute($store.getters.GET_PATHS.dashboard + '/')}"-->
                <!--&gt;-->
                  <!--Payment System-->
                <!--</router-link>-->
              <!--</div>-->
              <!--<span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(dashboardTranslates)"></span>-->
            <!--</div>-->

            <!--NEED TRANSLATE-->
            <!--<div class="side-bar__menu-item admin-edit-item"-->
                 <!--v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.www )}"-->
            <!--&gt;-->
              <!--<div class="side-bar__menu-item-ico">-->
                <!--<div class="side-bar__menu-item-ico-i">-->
                  <!--<OrdersDispatchesIco/>-->
                <!--</div>-->
              <!--</div>-->
              <!--<div class="side-bar__menu-item-content">-->
                <!--<router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.dashboard"-->
                             <!--v-bind:class="{open : checkActiveRoute($store.getters.GET_PATHS.dashboard + '/')}"-->
                <!--&gt;-->
                  <!--Orders ExpressPoshta-->
                <!--</router-link>-->
              <!--</div>-->
              <!--<span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(dashboardTranslates)"></span>-->
            <!--</div>-->


            <!--NEED TRANSLATE-->
            <!--<div class="side-bar__menu-item admin-edit-item"-->
                 <!--v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.www )}"-->
            <!--&gt;-->
              <!--<div class="side-bar__menu-item-ico">-->
                <!--<div class="side-bar__menu-item-ico-i">-->
                  <!--<OrdersDispatchesIco/>-->
                <!--</div>-->
              <!--</div>-->
              <!--<div class="side-bar__menu-item-content">-->
                <!--<router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.dashboard"-->
                             <!--v-bind:class="{open : checkActiveRoute($store.getters.GET_PATHS.dashboard + '/')}"-->
                <!--&gt;-->
                  <!--Invoices-->
                <!--</router-link>-->
              <!--</div>-->
              <!--<span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(dashboardTranslates)"></span>-->
            <!--</div>-->


            <!--NEED TRANSLATE-->
            <!--<div class="side-bar__menu-item admin-edit-item"-->
                 <!--v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.www )}"-->
            <!--&gt;-->
              <!--<div class="side-bar__menu-item-ico">-->
                <!--<div class="side-bar__menu-item-ico-i">-->
                  <!--<OrdersDispatchesIco/>-->
                <!--</div>-->
              <!--</div>-->
              <!--<div class="side-bar__menu-item-content">-->
                <!--<router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.dashboard"-->
                             <!--v-bind:class="{open : checkActiveRoute($store.getters.GET_PATHS.dashboard + '/')}"-->
                <!--&gt;-->
                  <!--Expenses and income-->
                <!--</router-link>-->
              <!--</div>-->
              <!--<span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(dashboardTranslates)"></span>-->
            <!--</div>-->

            <!--NEED TRANSLATE-->


            <!--NEED TRANSLATE-->
            <!--<div class="side-bar__menu-item admin-edit-item"-->
                 <!--v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.www )}"-->
            <!--&gt;-->
              <!--<div class="side-bar__menu-item-ico">-->
                <!--<span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(dashboardTranslates)"></span>-->
                <!--<div class="side-bar__menu-item-ico-i">-->
                  <!--<OrdersDispatchesIco/>-->
                <!--</div>-->
              <!--</div>-->
              <!--<div class="side-bar__menu-item-content">-->
                <!--<router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.dashboard"-->
                             <!--v-bind:class="{open : checkActiveRoute($store.getters.GET_PATHS.dashboard + '/')}"-->
                <!--&gt;-->
                  <!--Etsy-->
                <!--</router-link>-->
              <!--</div>-->
              <!--<span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(dashboardTranslates)"></span>-->
            <!--</div>-->



            <div class="side-bar__menu-item admin-edit-item"
                 v-if="checkVisibleGroupMenu(settingsList.links)"
                 v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.mainSettings )}">
              <div class="side-bar__menu-item-ico">
                <span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(settingsTranslates)"></span>
                <div class="side-bar__menu-item-ico-i">
                  <SettingsIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <div class="accordItemTitlesJs side-bar__menu-item-name"
                     v-bind:class="{open : checkActiveRoute($store.getters.GET_PATHS.mainSettings + '/')}">
                  {{ $t('menu_settings.localization_value.value') }}
                </div>
                <div class="side-bar__menu-submenu">

                  <MenuItem
                      v-for="(item, index) in settingsList.links" :key="index"
                      :item="item"
                  />

                </div>
              </div>

              <span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(settingsTranslates)"></span>

            </div>

            <div class="side-bar__menu-item admin-edit-item"
                 v-if="checkVisibleGroupMenu(systemList.links)"
                 v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.settings )}">
              <span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(systemTranslates)"></span>
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <SettingsIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <div class="accordItemTitlesJs side-bar__menu-item-name"
                     v-bind:class="{open : checkActiveRoute($store.getters.GET_PATHS.settings + '/')}">
                  {{ $t('menu_system.localization_value.value') }}
                </div>
                <div class="side-bar__menu-submenu">

                  <MenuItem
                      v-for="(item, index) in systemList.links" :key="index"
                      :item="item"
                  />

                  <!--<template v-if="-->
              <!--Object.prototype.hasOwnProperty.call($store.getters.getCurrentUserRights, 'role') &&-->
              <!--$store.getters.getCurrentUserRights.role.name === $store.getters.GET_DEVELOPER_ROLE">-->

                    <!--<div class="side-bar__menu-submenu-item">-->
                      <!--<router-link class="side-bar__menu-submenu-item-name"-->
                                   <!--v-bind:class="{'router-link-active' : checkActiveRoute($store.getters.GET_PATHS.settingsRoles)}"-->
                                   <!--:to="$store.getters.GET_PATHS.settingsRoles"-->
                      <!--&gt;-->
                        <!--{{ $t('menu_roles.localization_value.value') }}-->
                      <!--</router-link>-->
                    <!--</div>-->
                    <!--<div class="side-bar__menu-submenu-item">-->
                      <!--<router-link class="side-bar__menu-submenu-item-name"-->
                                   <!--v-bind:class="{'router-link-active' : checkActiveRoute($store.getters.GET_PATHS.settingsPermissions)}"-->
                                   <!--:to="$store.getters.GET_PATHS.settingsPermissions"-->
                      <!--&gt;-->
                        <!--{{ $t('menu_permissions.localization_value.value') }}-->
                      <!--</router-link>-->
                    <!--</div>-->

                    <!--<div class="side-bar__menu-submenu-item">-->
                      <!--<router-link class="side-bar__menu-submenu-item-name"-->
                                   <!--v-bind:class="{'router-link-active' : checkActiveRoute($store.getters.GET_PATHS.settingsPermissionsGroup)}"-->
                                   <!--:to="$store.getters.GET_PATHS.settingsPermissionsGroup"-->
                      <!--&gt;-->
                        <!--{{ $t('menu_permissionsGroup.localization_value.value') }}-->
                      <!--</router-link>-->
                    <!--</div>-->
                    <!--<div class="side-bar__menu-submenu-item">-->
                      <!--<router-link class="side-bar__menu-submenu-item-name"-->
                                   <!--v-bind:class="{'router-link-active' : checkActiveRoute($store.getters.GET_PATHS.settingsVueComponents)}"-->
                                   <!--:to="$store.getters.GET_PATHS.settingsVueComponents"-->
                      <!--&gt;-->
                        <!--{{ $t('menu_vueComponents.localization_value.value') }}-->
                      <!--</router-link>-->
                    <!--</div>-->


                  <!--</template>-->


                  <!--<template-->
                      <!--v-if="$store.getters.getCurrentUserRights.additionalPermissions &&-->
                      <!--$store.getters.getCurrentUserRights.additionalPermissions[PERMISSIONS.translationMode]"-->
                  <!--&gt;-->
                    <!--<div class="side-bar__menu-submenu-item">-->
                      <!--<router-link class="side-bar__menu-submenu-item-name"-->
                                   <!--v-bind:class="{'router-link-active' : checkActiveRoute($store.getters.GET_PATHS.settingsTranslations)}"-->
                                   <!--:to="$store.getters.GET_PATHS.settingsTranslations"-->
                      <!--&gt;-->
                        <!--{{ $t('menu_translations.localization_value.value') }}-->
                      <!--</router-link>-->
                    <!--</div>-->

                    <!--<div class="side-bar__menu-submenu-item">-->
                      <!--<router-link class="side-bar__menu-submenu-item-name"-->
                                   <!--v-bind:class="{'router-link-active' : checkActiveRoute($store.getters.GET_PATHS.settingsTranslationsGroup)}"-->
                                   <!--:to="$store.getters.GET_PATHS.settingsTranslationsGroup"-->
                      <!--&gt;-->
                        <!--{{ $t('menu_translationsGroups.localization_value.value') }}-->
                      <!--</router-link>-->
                    <!--</div>-->
                  <!--</template>-->

                </div>
              </div>

            </div>


            <div class="side-bar__menu-item admin-edit-item"
                 v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.notificationsList )}"
            >
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <ReportIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.notificationsList"
                >
                  {{ $t('menu_notifications.localization_value.value') }}
                </router-link>


              </div>
              <span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(dashboardTranslates)"></span>
            </div>

            <div class="side-bar__menu-item"
                 v-if="checkVisibleGroupMenu(statisticsList.links)"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate}">
              <span class="admin-edit" @click="editTranslate(statisticTranslates)" v-if="!isMobileFunc()"></span>
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <StatisticsIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <div class="accordItemTitlesJs side-bar__menu-item-name">
                  {{ $t('menu_statistics.localization_value.value') }}
                </div>
                <div class="side-bar__menu-submenu">
                  <MenuItem
                      v-for="(item, index) in statisticsList.links" :key="index"
                      :item="item"
                  />
                </div>
              </div>
            </div>

            <!--NEED TRANSLATE-->
            <!--<div class="side-bar__menu-item admin-edit-item"-->
                 <!--v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.www )}"-->
            <!--&gt;-->
              <!--<div class="side-bar__menu-item-ico">-->
                <!--<div class="side-bar__menu-item-ico-i">-->
                  <!--<OrdersDispatchesIco/>-->
                <!--</div>-->
              <!--</div>-->
              <!--<div class="side-bar__menu-item-content">-->
                <!--<router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.dashboard"-->
                             <!--v-bind:class="{open : checkActiveRoute($store.getters.GET_PATHS.dashboard + '/')}"-->
                <!--&gt;-->
                  <!--API-->
                <!--</router-link>-->
              <!--</div>-->
              <!--<span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(dashboardTranslates)"></span>-->
            <!--</div>-->

            <!--NEED TRANSLATE-->
            <!--<div class="side-bar__menu-item admin-edit-item"-->
                 <!--v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.www )}"-->
            <!--&gt;-->
              <!--<div class="side-bar__menu-item-ico">-->
                <!--<div class="side-bar__menu-item-ico-i">-->
                  <!--<OrdersDispatchesIco/>-->
                <!--</div>-->
              <!--</div>-->
              <!--<div class="side-bar__menu-item-content">-->
                <!--<router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.dashboard"-->
                             <!--v-bind:class="{open : checkActiveRoute($store.getters.GET_PATHS.dashboard + '/')}"-->
                <!--&gt;-->
                  <!--Translations-->
                <!--</router-link>-->
              <!--</div>-->
              <!--<span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(dashboardTranslates)"></span>-->
            <!--</div>-->

            <!--NEED TRANSLATE-->
            <!--<div class="side-bar__menu-item admin-edit-item"-->
                 <!--v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.www )}"-->
            <!--&gt;-->
              <!--<div class="side-bar__menu-item-ico">-->
                <!--<div class="side-bar__menu-item-ico-i">-->
                  <!--<OrdersDispatchesIco/>-->
                <!--</div>-->
              <!--</div>-->
              <!--<div class="side-bar__menu-item-content">-->
                <!--<router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.dashboard"-->
                             <!--v-bind:class="{open : checkActiveRoute($store.getters.GET_PATHS.dashboard + '/')}"-->
                <!--&gt;-->
                  <!--Carriers-->
                <!--</router-link>-->
              <!--</div>-->
              <!--<span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(dashboardTranslates)"></span>-->
            <!--</div>-->

            <!--NEED TRANSLATE-->
            <!--<div class="side-bar__menu-item admin-edit-item"-->
                 <!--v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.www )}"-->
            <!--&gt;-->
              <!--<div class="side-bar__menu-item-ico">-->
                <!--<div class="side-bar__menu-item-ico-i">-->
                  <!--<OrdersDispatchesIco/>-->
                <!--</div>-->
              <!--</div>-->
              <!--<div class="side-bar__menu-item-content">-->
                <!--<router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.dashboard"-->
                             <!--v-bind:class="{open : checkActiveRoute($store.getters.GET_PATHS.dashboard + '/')}"-->
                <!--&gt;-->
                  <!--Delivery statistics-->
                <!--</router-link>-->
              <!--</div>-->
              <!--<span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(dashboardTranslates)"></span>-->
            <!--</div>-->

            <!--NEED TRANSLATE-->
            <div class="side-bar__menu-item admin-edit-item"
                 v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.scanBarcode )}"
                 v-if="_.has(this.currentPermissions, PERMISSIONS.SCAN_BARCODE_GENERAL)"
            >
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <BarcodeIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.scanBarcode"
                             v-bind:class="{open : checkActiveRoute($store.getters.GET_PATHS.scanBarcode + '/')}"
                >
                  {{ $t('menu_scanBarcode.localization_value.value') }}
                </router-link>
              </div>
              <span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(scanBarcodeTranslates)"></span>
            </div>

            <div class="side-bar__menu-item"
                 v-bind:class="{
                  active : checkActiveRoute('/heartysan/'),
                'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate
              }">

              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <ReportIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <div class="side-bar__menu-item-name accordItemTitlesJs"
                     v-bind:class="{open : checkActiveRoute('/heartysan/')}">
                  {{ $t('menu_heartysan.localization_value.value') }}
                </div>

                <div class="side-bar__menu-submenu">

                  <MenuItem
                      v-for="(item, index) in heartysan.links" :key="index"
                      :item="item"
                  />
                </div>
              </div>
              <span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(heartysanTranslates)"></span>
            </div>

            <div class="side-bar__menu-item"
                 v-if="checkVisibleGroupMenu(marketplace.links)"
                 v-bind:class="{
                  active : checkActiveRoute('/marketplace/'),
                'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate
              }">

              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <ReportIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <div class="side-bar__menu-item-name accordItemTitlesJs"
                     v-bind:class="{open : checkActiveRoute('/marketplace/')}">
                  {{ $t('menu_marketplace.localization_value.value') }}
                </div>

                <div class="side-bar__menu-submenu">

                  <MenuItem
                      v-for="(item, index) in marketplace.links" :key="index"
                      :item="item"
                  />
                </div>
              </div>
              <span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(marketplaceTranslates)"></span>
            </div>



            <!--NEED TRANSLATE-->
            <!--<div class="side-bar__menu-item admin-edit-item"-->
                 <!--v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.www )}"-->
            <!--&gt;-->
              <!--<div class="side-bar__menu-item-ico">-->
                <!--<div class="side-bar__menu-item-ico-i">-->
                  <!--&lt;!&ndash;<OrdersDispatchesIco/>&ndash;&gt;-->
                <!--</div>-->
              <!--</div>-->
              <!--<div class="side-bar__menu-item-content">-->
                <!--<router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.dashboard"-->
                             <!--v-bind:class="{open : checkActiveRoute($store.getters.GET_PATHS.dashboard + '/')}"-->
                <!--&gt;-->
                  <!--Support Ticket-->
                <!--</router-link>-->
              <!--</div>-->
              <!--<span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(dashboardTranslates)"></span>-->
            <!--</div>-->

            <!--NEED TRANSLATE-->
            <!--<div class="side-bar__menu-item admin-edit-item"-->
            <!--v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.www )}"-->
            <!--&gt;-->
            <!--<div class="side-bar__menu-item-ico">-->
            <!--<div class="side-bar__menu-item-ico-i">-->
            <!--&lt;!&ndash;<OrdersDispatchesIco/>&ndash;&gt;-->
            <!--</div>-->
            <!--</div>-->
            <!--<div class="side-bar__menu-item-content">-->
            <!--<router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.dashboard"-->
            <!--v-bind:class="{open : checkActiveRoute($store.getters.GET_PATHS.dashboard + '/')}"-->
            <!--&gt;-->
            <!--Help and Support-->
            <!--</router-link>-->
            <!--</div>-->
            <!--<span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(dashboardTranslates)"></span>-->
            <!--</div>-->

          </div>


        </template>
      </MenuScrollContainer>

    </div>

    <!--</vue-custom-scrollbar>-->

    <div class="side-bar__login-as" v-if="isDeveloper || isDashboard">
      <div style="color: white"
           v-if="_.has(currentPermissions, PERMISSIONS.IMPERSONALIZATION_MODE) && !checkImpersonate"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate}">
        <span class="admin-edit" @click="editTranslate(['menu_impersonateUsersField'])"></span>
        <DefaultSelectInside
            class="black"
            :otherValue="'usersSelect'"
            :options="impersonateUsersList"
            :label="$t('menu_impersonateUsersField.localization_value.value')"
            @change="changeUser"
            @onBlur="onBlurSelect"
        />
<!--            :optionsLabel="'email'"-->
      </div>

      <div v-if="checkImpersonate">
        <MainButton
                class="secondary secondary-brown wfc"
                :value="'Back to Admin'"
                v-bind:class="{'disabled-btn' : $store.getters.getImpersonateButton}"
                @click.native="resetImpersonate"
        />
      </div>
    </div>

    <div class="side-bar__bottom"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate}"
    >
      <span class="admin-edit" v-if="!isMobileFunc()"
            @click="editTranslate(['menu_contactAndWarehouses', 'menu_helpAndSupport',])"></span>

      <div
          @click="showAddressPopupHandler"
          class="side-bar__bottom-btn site-link">
        <div class="side-bar__bottom-btn-ico">
          <AddressIco style="width: 18px;"/>
        </div>
        <div class="side-bar__bottom-btn-txt">
          {{ $t('menu_contactAndWarehouses.localization_value.value') }}
        </div>
      </div>
      <a :href="supportLink"
         target="_blank" rel="nofollow" class="side-bar__bottom-btn">
        <div class="side-bar__bottom-btn-ico">
          <HelpIco/>
        </div>
        <div class="side-bar__bottom-btn-txt">
          {{ $t('menu_helpAndSupport.localization_value.value') }}
        </div>
      </a>
      <div class="side-bar__bottom-link"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate}"
      >
            <span class="side-bar__bottom-link--big" style="color: #BBAD9C;">
              <span class="admin-edit" v-if="!isMobileFunc()"
                    @click="editTranslate(['menu_confidencial', 'menu_generalTerms', 'menu_and'])"></span>
              <a :href="`https://skladusa.com/${$store.getters.GET_LANG}/privacy-policy/`" class="site-link" target="_blank">
                {{ $t('menu_confidencial.localization_value.value') }}&nbsp;&nbsp;
              </a>
              {{ $t('menu_and.localization_value.value') }}
              <a :href="`https://skladusa.com/${$store.getters.GET_LANG}/user-agreement/`" class="site-link" target="_blank">
                {{ $t('menu_generalTerms.localization_value.value') }}
              </a>
              <!--{{ $t('menu_showOfficesAddresses.localization_value.value') }}-->
            </span>
        <span class="side-bar__bottom-link--small" style="color: #BBAD9C; transform: translateY(-18px);">
              <span class="admin-edit" v-if="!isMobileFunc()"
                    @click="editTranslate(['menu_confidencial', 'menu_generalTerms', 'menu_and'])"></span>
              <a :href="`https://skladusa.com/${$store.getters.GET_LANG}/privacy-policy/`" class="site-link" target="_blank">
                {{ $t('menu_confidencial.localization_value.value') }}&nbsp;&nbsp;
              </a>
              {{ $t('menu_and.localization_value.value') }}
              <a :href="`https://skladusa.com/${$store.getters.GET_LANG}/user-agreement/`" class="site-link" target="_blank">
                {{ $t('menu_generalTerms.localization_value.value') }}
              </a>
          <!--{{ $t('menu_addresses.localization_value.value') }}-->
            </span>
      </div>
      <div style="margin-top: 3px;" class="side-bar__bottom-link"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate}"
      >
            <span class="side-bar__bottom-link--big site-link">
              <span class="admin-edit" v-if="!isMobileFunc()"
                    @click="editTranslate(['common_byAsabix'])"></span>
              <a href="https://asabix.com/" target="_blank" class="site-link">
                {{ $t('common_byAsabix.localization_value.value') }} &nbsp;
              </a>&nbsp;
            </span>
        <span class="side-bar__bottom-link--small site-link">
              <span class="admin-edit" v-if="!isMobileFunc()"
                    @click="editTranslate(['common_byAsabix'])"></span>
              <a href="https://asabix.com/" target="_blank" class="site-link">
                {{ $t('common_byAsabix.localization_value.value') }} &nbsp;
              </a>&nbsp;
        </span>
      </div>

    </div>

    <!--<AddressPopup-->
    <!--@close="closeAddressPopupHandler"-->
    <!--v-if="showAddress"-->
    <!--/>-->

    <!--</div>-->


  </div>

</template>

<script>
  import SkladUSALogo from '../../../../../public/img/sidebar-group/SkladUSALogo.svg?inline'
  import OrdersDispatchesIco from '../../../../../public/img/sidebar-group/OrdersDispatchesIco.svg?inline'
  import FinanceIco from '../../../../../public/img/sidebar-group/FinanceIco.svg?inline'
  import WarehouseIco from '../../../../../public/img/sidebar-group/WarehouseIco.svg?inline'
  import ProblemsIco from '../../../../../public/img/sidebar-group/ProblemsIco.svg?inline'
  import IncomeExpensesIco from '../../../../../public/img/sidebar-group/IncomeExpensesIco.svg?inline'
  import SettingsIco from '../../../../../public/img/sidebar-group/SettingsIco.svg?inline'
  import StatisticsIco from '../../../../../public/img/sidebar-group/StatisticsIco.svg?inline'
  import HelpIco from '../../../../../public/img/sidebar-group/HelpIco.svg?inline'
  import AddressIco from '../../../../../public/img/sidebar-group/addressIco.svg?inline'
  import UsersIco from '../../../../../public/img/sidebar-group/UsersIco.svg?inline'
  import DashboardIco from '../../../../../public/img/sidebar-group/DashboardIco.svg?inline'
  import BarcodeIco from '../../../../../public/img/sidebar-group/BarcodeIco.svg?inline'
  import ReportIco from '../../../../../public/img/sidebar-group/ReportIco.svg?inline'
  import DefaultSelectInside from "../../../UI/selectiones/DefaultSelectInside/DefaultSelectInside";
  import {ADMIN_ROUTE_STORAGE, EN_LANG, RU_LANG, SYSTEM_ROLES, UA_LANG} from "../../../../staticData/staticVariables";
  import {mixinDetictingMobile} from "../../../../mixins/mobileFunctions";
  import {GetAdminMenuTranslationsList} from "./adminMenuTranslationsList";
  import {GetAdminMenuList} from "./adminMenuCounters";
  import MenuItem from "../components/MenuItem";
  import {munuFunc} from "../../../../mixins/menuMixins/menuFunc";
  import MenuScrollContainer from "../../../coreComponents/MenuScrollContainer/MenuScrollContainer";
  import {checkAccess} from "../../../../mixins/userCredentionalMixins/userCredentional";
  import MainButton from "../../../UI/buttons/MainButton/MainButton";
  import {routerPathsMain} from "@/router/routerPaths";
  import SidebarSearch from "../../../coreComponents/SidebarSearch/SidebarSearch";


  export default {
    name: "SideBarAdmin",

    components: {
      SidebarSearch,
      MainButton,
      SkladUSALogo,
      OrdersDispatchesIco,
      FinanceIco,
      WarehouseIco,
      ProblemsIco,
      IncomeExpensesIco,
      SettingsIco,
      StatisticsIco,
      HelpIco,
      AddressIco,
      DefaultSelectInside,
      MenuItem,
      UsersIco,
      DashboardIco,
      BarcodeIco,
      ReportIco,

      // vueCustomScrollbar,
      MenuScrollContainer,
      // SearchInput,
    },

    mixins: [mixinDetictingMobile, munuFunc, checkAccess],

    props: [],

    computed: {

      checkImpersonate() {
        return this.localStorage?.parentUserId
      },

      lang: function() {
        return this.$store.getters.GET_LANG
      },

      forbiddenGoodsCount(){
        return this.$store.getters.GET_COMMON_AUTHORIZED?.forbidden_payment_transaction_count
      },

      balanceOutputCount(){
        return this.$store.getters.GET_COMMON_AUTHORIZED?.balance_output_count
      },

      isDashboard(){
        return this.$route.fullPath === routerPathsMain.dashboardAdmin
      },

      impersonateUsersList() {
        let forbiddenRoles = []

        if (!this.isDeveloper) {
          forbiddenRoles.push(SYSTEM_ROLES.SYSTEM_ROLE_SUPER_ADMIN.id)
        }

        if (!this.isDeveloper && !this.isSupperAdmin) {
          forbiddenRoles.push(SYSTEM_ROLES.SYSTEM_ROLE_ADMIN.id)
        }

        return this.$store.getters.getImpersonateUsers.filter(item => {
          return item.roles.find(roleItem => {
            return !forbiddenRoles.includes(roleItem.id)
          })
        })
      }

    },

    watch: {
      lang: function(newCount) {
        this.checkLang(newCount)
      },

      forbiddenGoodsCount(newVal){
        this.setMenuForbiddenGoodsCounts(newVal)
      },

      balanceOutputCount(newVal){
        this.setMenuBalanceOutputCounts(newVal)
      },

      isDeveloper() {
        if((this.isDeveloper || this.isDashboard) && this.$store.getters.getImpersonateUsers.length === 0){
          this.$store.dispatch('fetchImpersonateUsers')
        }
      },

      isDashboard() {
        if((this.isDeveloper || this.isDashboard) && this.$store.getters.getImpersonateUsers.length === 0){
          this.$store.dispatch('fetchImpersonateUsers')
        }
      },


    },

    data() {
      return {
        localStorage: localStorage,

        settings: {
          suppressScrollY: false,
          suppressScrollX: false,
          wheelPropagation: false,
          wheelSpeed: 0.5,
        },
        showScroll: true,
        sideBarSearch: '',
        supportLink: '',
        EN_LANG: EN_LANG,
        RU_LANG: RU_LANG,
        UA_LANG: UA_LANG,

        ordersAndDispatchesList: new GetAdminMenuList()
          .ORDER_DISPATCHES(this),
        financeList: new GetAdminMenuList()
          .FINANCE(this),
        warehousesList: new GetAdminMenuList()
          .WAREHOUSE(this),
        problemsList: new GetAdminMenuList()
          .PROBLEMS(this),
        incomeExpressList: new GetAdminMenuList()
          .INCOME_EXPRESS(this),
        reportsList: new GetAdminMenuList()
          .REPORTS(this),
        heartysan: new GetAdminMenuList()
            .HEARTYSAN(this),
        marketplace: new GetAdminMenuList()
            .MARKETPLACE(this),
        settingsList: new GetAdminMenuList()
          .SETTINGS(this),
        systemList: new GetAdminMenuList()
          .SYSTEM(this),
        statisticsList: new GetAdminMenuList()
          .STATISTICS(this),



        dashboardTranslates: new GetAdminMenuTranslationsList()
          .DASHBOARD(),
        ordersAndDispatchesTranslates: new GetAdminMenuTranslationsList()
          .ORDER_AND_DISPATHES(),
        settingsTranslates: new GetAdminMenuTranslationsList()
          .SETTINGS(),
        systemTranslates: new GetAdminMenuTranslationsList()
          .SYSTEM(),
        warehouseTranslates: new GetAdminMenuTranslationsList()
          .WAREHOUSE(),
        problemsTranslates: new GetAdminMenuTranslationsList()
          .PROBLEMS(),
        finance: new GetAdminMenuTranslationsList()
          .FINANCE(),
        reportsTranslates: new GetAdminMenuTranslationsList()
          .REPORTS(),
        scanBarcodeTranslates: new GetAdminMenuTranslationsList()
            .SCAN_BARCODE(),

        incomeTranslates: new GetAdminMenuTranslationsList()
            .INCOME_EXPRESS(),
        heartysanTranslates: new GetAdminMenuTranslationsList()
            .HEARTYSAN(),
        marketplaceTranslates: new GetAdminMenuTranslationsList()
            .MARKETPLACE(),


        statisticTranslates: [
          'menu_statistics',
          'menu_deliveryStatistics',
          'menu_etsyRank',
          'menu_googleAnalytics',
        ],


        windowWidth: '',
        showAddress: false,

      }
    },

    mounted() {
      this.checkLang(this.$store.getters.GET_LANG)

      if(this.isDeveloper || this.isDashboard){
        this.$store.dispatch('fetchImpersonateUsers')
      }

      this.initMenu()

      this.setMenuForbiddenGoodsCounts(this.$store.getters.GET_COMMON_AUTHORIZED?.forbidden_payment_transaction_count)
    },


    methods: {


      setMenuForbiddenGoodsCounts(value) {
        let item = this._.find(this.financeList.links, {translation: 'menu_forbiddenGoods'})
        item.count = value
      },

      setMenuBalanceOutputCounts(value) {
        let item = this._.find(this.financeList.links, {translation: 'menu_requestFunds'})
        item.count = value
      },

      checkLang(val){
        switch (val) {
          case EN_LANG:
            this.supportLink = 'https://skladusa.freshdesk.com/en/support/home'
            break
          case RU_LANG:
            this.supportLink = 'https://skladusa.freshdesk.com/ru-RU/support/home'
            break
          case UA_LANG:
            this.supportLink = 'https://skladusa.freshdesk.com/uk/support/home'
            break
          default:
            break
        }
      },

      onBlurSelect() {
        this.showScroll = !this.showScroll
      },

      changeUser(val) {
        let data = {
          user_id: val.id
        }
        this.$store.dispatch('impersonate', data).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            // Add to localstorage variable for checking admin routes
            if(val.roles[0]?.id === SYSTEM_ROLES.SYSTEM_ROLE_USER.id || val.roles[0]?.id === SYSTEM_ROLES.SYSTEM_ROLE_SUB_USER.id){
              localStorage.removeItem(ADMIN_ROUTE_STORAGE)
            }
            location.href = this.$store.getters.GET_PATHS.dashboardUser
            // End add to localstorage variable for checking admin routes
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }

        })
      },

      checkActiveRoute(path) {
        let routeMatchedPath = this._.last(this.$route.matched)?.path
        if(routeMatchedPath) {
          return routeMatchedPath.indexOf(path) > -1 ? true : false
        }
        return false

        // return this.$route.path.indexOf(path) > -1 ? true : false;
      },

      resetImpersonate() {
        let data = {
          impersonateId: localStorage.getItem('parentUserId')
        }
        this.$store.dispatch('resetImpersonate', data).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            // Add to localstorage variable for checking admin routes
            localStorage.setItem(ADMIN_ROUTE_STORAGE, 'Y')
            location.href = this.$store.getters.GET_PATHS.dashboardAdmin
            // End add to localstorage variable for checking admin routes
          } else {
            this.$store.dispatch('logOut')
            // location.reload()
            // console.log(23423);
          }
        })
      },

      subIsActive(input) {
        const paths = Array.isArray(input) ? input : [input]
        return paths.some(path => {
          return this.$route.path.indexOf(path) === 0 // current path starts with this path string
        })
      },

      showAddressPopupHandler() {
        this.$emit('openAddressPopup')
      },

      // resizeWindowEvent(){
      //   this.$forceUpdate();
      // },

    },

    // created() {
    //   window.addEventListener("resize", this.resizeWindowEvent);
    // },
    //
    // destroyed() {
    //   window.removeEventListener("resize", this.resizeWindowEvent);
    // },


  }

</script>

<style lang="scss">
  @import "../SideBar/SideBar";
</style>
