import {API} from "../../axios/axiosMainUrl";

const prefix = '/report/cells'

export const cellsReport = {
  state: {
    cellsReport: [],
    cellsReportItem: {},
    cellsReportCommonList: [],
    cellsReportBtn: false,
    cellsReportLoading: true,
    nextCellsReportPage: false,
    cellsReportForPage: 10,
    cellsReportFilter: '',
  },

  mutations: {
    setCellsReportFilter: (state, payload) => {
      state.cellsReportFilter = payload
    },

    setCellsReport: (state, payload) => {
      if(state.nextCellsReportPage) {
        state.cellsReport = state.cellsReport.concat(payload.cellsReport)
      } else {
        state.cellsReport = payload.cellsReport
      }
    },

    setCellsReportCommonList: (state, payload) => {
      state.cellsReportCommonList = payload.cellsReportCommonList
    },

    setNextCellsReportPage: (state, payload) => {
      state.nextCellsReportPage = payload
    },

    setCellsReportItem: (state, payload) => {
      state.cellsReportItem = payload.cellsReportItem
    },

    setCellsReportLoadingStart: (state) => {
      state.cellsReportLoading = true
    },

    setCellsReportLoadingEnd: (state) => {
      state.cellsReportLoading = false
    },

    changeCellsReportBtn: (state) => {
      state.cellsReportBtn = true
    },
    successCellsReportBtn: (state) => {
      state.cellsReportBtn = false
    },
  },

  getters: {
    getCellsReportFilter: state => {
      return state.cellsReportFilter
    },

    getCellsReportForPage: state => {
      return state.cellsReportForPage
    },

    getCellsReport: state => {
      return state.cellsReport
    },

    getCellsReportLoading: state => {
      return state.cellsReportLoading
    },


    getNextCellsReportPage: state => {
      return state.nextCellsReportPage
    },

    getCellsReportItem: state => {
      return state.cellsReportItem
    },

    getCellsReportCommonList: state => {
      return state.cellsReportCommonList
    },

    getCellsReportBtn: state => {
      return state.cellsReportBtn
    },
  },

  actions: {

    async fetchCellsReport({commit, getters}, filter = '') {
      if(!getters.getNextCellsReportPage)
        commit('setCellsReportLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setCellsReport',{cellsReport: response.data.data.data})
          // commit('setCellsReportCommonList',{cellsReportCommonList: response.data.data})
          commit('setCellsReportCommonList',{cellsReportCommonList: {...response.data.data.meta, ...{
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev
              }}})
          commit('setCellsReportLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setCellsReportLoadingEnd');
        return e;
      }
    },

    async fetchCellsReportWithoutCommit({commit}, filter = '') {
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setCellsReportLoadingEnd');
        return e;
      }
    },

    async getCellsReport({commit}, id) {
      commit('setCellsReportLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setCellsReportItem', {cellsReportItem: response.data.data})
          commit('setCellsReportLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setCellsReportLoadingEnd');
        return e;
      }
    },

    async createCellsReport({commit}, data) {
      commit('changeCellsReportBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successCellsReportBtn');
          return response;
        });
      } catch (e) {
        commit('successCellsReportBtn');
        return e;
      }
    },

    async updateCellsReport({commit}, {id, data}) {
      commit('changeCellsReportBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successCellsReportBtn');
          return response;
        });
      } catch (e) {
        commit('successCellsReportBtn');
        return e;
      }
    },

    async deleteCellsReport({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successCellsReportBtn');
        return e;
      }
    },

  }
}
