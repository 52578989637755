import {API} from "../../axios/axiosMainUrl";

const prefix = '/users/ip-location'

export const connectionsHistory = {
  state: {
    connectionsHistory: [],
    connectionsHistoryItem: {},
    connectionsHistoryByUserId: [],
    connectionsHistoryCommonList: [],
    connectionsHistoryBtn: false,
    connectionsHistoryLoading: true,
    nextConnectionsHistoryPage: false,
    connectionsHistoryForPage: 25,
    connectionsHistoryFilter: '',
  },

  mutations: {
    setConnectionsHistoryFilter: (state, payload) => {
      state.connectionsHistoryFilter = payload
    },

    setConnectionsHistory: (state, payload) => {
      if(state.nextConnectionsHistoryPage) {
        state.connectionsHistory = state.connectionsHistory.concat(payload.connectionsHistory)
      } else {
        state.connectionsHistory = payload.connectionsHistory
      }
    },

    setConnectionsHistoryCommonList: (state, payload) => {
      state.connectionsHistoryCommonList = payload.connectionsHistoryCommonList
    },

    setNextConnectionsHistoryPage: (state, payload) => {
      state.nextConnectionsHistoryPage = payload
    },

    setConnectionsHistoryItem: (state, payload) => {
      state.connectionsHistoryItem = payload.connectionsHistoryItem
    },

    setConnectionsHistoryByUserId: (state, payload) => {
      state.connectionsHistoryByUserId = payload.connectionsHistoryByUserId
    },

    setConnectionsHistoryLoadingStart: (state) => {
      state.connectionsHistoryLoading = true
    },

    setConnectionsHistoryLoadingEnd: (state) => {
      state.connectionsHistoryLoading = false
    },

    changeConnectionsHistoryBtn: (state) => {
      state.connectionsHistoryBtn = true
    },
    successConnectionsHistoryBtn: (state) => {
      state.connectionsHistoryBtn = false
    },
  },

  getters: {
    getConnectionsHistoryFilter: state => {
      return state.connectionsHistoryFilter
    },

    getConnectionsHistoryForPage: state => {
      return state.connectionsHistoryForPage
    },

    getConnectionsHistory: state => {
      return state.connectionsHistory
    },

    getConnectionsHistoryLoading: state => {
      return state.connectionsHistoryLoading
    },


    getNextConnectionsHistoryPage: state => {
      return state.nextConnectionsHistoryPage
    },

    getConnectionsHistoryItem: state => {
      return state.connectionsHistoryItem
    },

    getConnectionsHistoryByUserId: state => {
      return state.connectionsHistoryByUserId
    },

    getConnectionsHistoryCommonList: state => {
      return state.connectionsHistoryCommonList
    },

    getConnectionsHistoryBtn: state => {
      return state.connectionsHistoryBtn
    },
  },

  actions: {

    async fetchConnectionsHistory({commit, getters}, filter = '') {
      if(!getters.getNextConnectionsHistoryPage)
        commit('setConnectionsHistoryLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setConnectionsHistory',{connectionsHistory: response.data.data.data})
          commit('setConnectionsHistoryCommonList',{connectionsHistoryCommonList: response.data.data})
          commit('setConnectionsHistoryLoadingEnd');
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('setConnectionsHistoryLoadingEnd');
        return e;
      }
    },

  }
}
