
export const siteSettingsMixin = {

  computed: {
    /**
     * Return site user negative balance Limit
     * @returns {boolean}
     */

    getSiteUserNegativeBalanceLimit() {
      let config = this.$store.getters.GET_COMMON_AUTHORIZED
      return config?.settings?.user_negative_balance?.user_negative_balance_min_limit?.value
    },

    /**
     * Return site user negative balance
     * @returns {boolean}
     */

    getSiteUserNegativeBalanceDays() {
      let config = this.$store.getters.GET_COMMON_AUTHORIZED
      return config?.settings?.user_negative_balance?.user_negative_balance_max_days?.value
    },

    /**
     * Return site user negative balance
     * @returns {boolean}
     */

    getPercentOrderOutboundInsuranceCost() {
      let config = this.$store.getters.GET_COMMON_AUTHORIZED
      return config?.settings?.order?.percent_order_outbound_insurance_cost?.value
    },

  },

}
