import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const TransactionExportPage = () => import(/* webpackChunkName: "group-transaction-export" */ './components/TransactionExportPage/TransactionExportPage.vue')
const moduleKey = 'new-payments'

export const TransactionExportRoutes = [
  {
    path: routerPaths.transactionExport,
    component: TransactionExportPage,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'reportsTransactionExport'
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.transactionExport,
          },
          'reportsTransactionExport': {
            name: 'menu_TransactionExport',
            link: routerPaths.transactionExport,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.TRANSACTION_EXPORT],

      moduleKey: moduleKey,
      pageTitle: 'menu_TransactionExport'
    },
  },
]
