
import {routerPaths} from '../../../router/routerPaths'

const EtsyListingsTable = () => import(/* webpackChunkName: "group-heartysan-etsy-listings" */ './components/EtsyListingsTable/EtsyListingsTable.vue')
const EtsyListingsShop = () => import(/* webpackChunkName: "group-heartysan-etsy-listings" */ './components/EtsyListingsShop/EtsyListingsShop.vue')
const EtsyListingsEdit = () => import(/* webpackChunkName: "group-heartysan-etsy-listings" */ './components/EtsyListingsEdit/EtsyListingsEdit.vue')
const EtsyListingsCreate = () => import(/* webpackChunkName: "group-heartysan-etsy-listings" */ './components/EtsyListingsCreate/EtsyListingsCreate.vue')
const moduleKey = 'etsy-listings'

export const HeartysanEtsyRoutes = [
  {
    path: routerPaths.heartysanEtsyListings,
    component: EtsyListingsTable,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettings',
          'mainSettingsEtsyListings'
        ],
        items: {
          'mainSettings': {
            name: 'menu_settings',
            link: routerPaths.mainSettings,
          },
          'mainSettingsEtsyListings': {
            name: 'breadcrumbs_MyShopsEtsy',
            link: routerPaths.mainSettingsEtsyListings,
          },
        },
      },


      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_MyShopsEtsy'
    },
  },
  {
    path: routerPaths.heartysanEtsyListingsShop,
    component: EtsyListingsShop,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettings',
          'mainSettingsEtsyListings',
          'breadcrumbs_etsyListingsShop',
        ],
        items: {
          'mainSettings': {
            name: 'menu_settings',
            link: routerPaths.heartysan,
          },
          'mainSettingsEtsyListings': {
            name: 'menu_etsyListings',
            link: routerPaths.heartysanEtsyListings,
          },
          'breadcrumbs_etsyListingsShop': {
            name: 'breadcrumbs_etsyListingsShop',
            link: routerPaths.heartysanEtsyListingsShop,
          },
        },
      },


      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_etsyListingsShop'
    },
  },
  {
    path: routerPaths.heartysanEtsyListingsCreateListing,
    component: EtsyListingsCreate,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettings',
          'mainSettingsEtsyListings',
          'mainSettingsEtsyListingsCreateListing',
        ],
        items: {
          'mainSettings': {
            name: 'menu_settings',
            link: routerPaths.heartysan,
          },
          'mainSettingsEtsyListings': {
            name: 'menu_etsyListings',
            link: routerPaths.heartysanEtsyListings,
          },
          'mainSettingsEtsyListingsCreateListing': {
            name: 'breadcrumbs_NewEtsyListing',
            link: routerPaths.heartysanEtsyListings,
          },
        },
      },


      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewEtsyListing'
    },
  },
  {
    path: routerPaths.heartysanEtsyListingsEditListing,
    component: EtsyListingsEdit,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettings',
          'mainSettingsEtsyListings',
          'mainSettingsEtsyListingsEditListing',
        ],
        items: {
          'mainSettings': {
            name: 'menu_settings',
            link: routerPaths.heartysan,
          },
          'mainSettingsEtsyListings': {
            name: 'menu_etsyListings',
            link: routerPaths.heartysanEtsyListings,
          },
          'mainSettingsEtsyListingsEditListing': {
            name: 'breadcrumbs_EditEtsyListing',
            link: routerPaths.heartysanEtsyListings,
          },
        },
      },


      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_EditEtsyListing'
    },
  },
]
