export const munuFunc = {
  methods: {

    checkVisibleGroupMenu(links) {
      let flag = false
      links.map(item => {
        if(this._.has(item, 'permissionLinkName')){
          if(this._.has(this.currentPermissions, item.permissionLinkName)){
            flag = true
          }
        }
      })
      return flag
    },

    initMenu() {
      // console.log(this.subIsActive('/dashboard'));
      /***  Custom accordion (slideToggle analog) for menu  ***/

      let accordionItemTitles = document.querySelectorAll(".accordItemTitlesJs");

      let alternativeAccordionItemTitles = []

      if (window.innerWidth < 1550 && window.innerWidth > 680) {

        let onlyMainTitle = document.querySelectorAll(".side-bar__menu-item");


        for (let i = 0; i < onlyMainTitle.length; i++) {
          onlyMainTitle[i].addEventListener("click",function () {
            console.log(onlyMainTitle[i].closest('section'))
            onlyMainTitle[i].closest('.side-bar__menu').scrollIntoView()
          });
        }



        for (let i = 0; i < accordionItemTitles.length - 1; i++) {
          if(!accordionItemTitles[i].classList.contains('side-bar__menu-item-name')) {
            alternativeAccordionItemTitles.push(accordionItemTitles[i])
          }
        }
        accordionItemTitles = alternativeAccordionItemTitles.slice()
      }

      for (let i = 0; i < accordionItemTitles.length; i++) {
        accordionItemTitles[i].addEventListener("click",
          function (event) {
            event.preventDefault();
            let menuItem = event.target
            if(!menuItem.classList.contains('accordItemTitlesJs')) {
              menuItem = menuItem.closest('.accordItemTitlesJs')
            }
            menuItem.classList.toggle("open");
            let accordionItemContent = menuItem.nextElementSibling;

            if (!accordionItemContent.classList.contains('open')) {
              accordionItemContent.classList.add('open');
              accordionItemContent.style.height = 'auto';

              let height = accordionItemContent.clientHeight + 'px';

              accordionItemContent.style.height = '0px';

              setTimeout(function () {
                accordionItemContent.style.height = height;
              }, 0);
              setTimeout(function () {
                accordionItemContent.style.height = 'auto';
              }, 300);
            } else {

              let heightClose = accordionItemContent.clientHeight + 'px';
              accordionItemContent.style.height = heightClose;

              setTimeout(function () {
                accordionItemContent.style.height = '0px';
                accordionItemContent.addEventListener('transitionend',
                  function () {
                    accordionItemContent.classList.remove('open');
                  }, {
                    once: true
                  });
              }, 100);

            }
          });
      }
      setTimeout(function () {

        for (let i = 0; i < accordionItemTitles.length; i++) {
          if (accordionItemTitles[i].classList.contains('open')) {
            let nextItem = accordionItemTitles[i].nextElementSibling;
            nextItem.classList.add('open');
            nextItem.style.height = 'auto';
          }
        }
      }, 1);
      /***  END  ***/


      /***  When the screen is less than 1550 pixels, opening and closing the menu  ***/


      let sideBar = document.getElementById('side-bar');
      let sideBarMenuItems = document.querySelectorAll(".side-bar__menu-item");
      let sideBarMenuItemsIco = document.querySelectorAll(".side-bar__menu-item-ico");

      if (window.innerWidth < 1550) {

        for (let i = 0; i < sideBarMenuItemsIco.length; i++) {
          sideBarMenuItemsIco[i].addEventListener("click",
            function (event) {

              if (
                sideBarMenuItemsIco[i].parentElement.classList.contains('active')
                && sideBar.classList.contains('open')
              ) {
                sideBar.classList.remove('open');
              } else {
                sideBar.classList.add('open');
              }

              for (let y = 0; y < sideBarMenuItems.length; y++) {
                sideBarMenuItems[y].classList.remove('active');

                sideBarMenuItems[y].children[1].children[0].classList.remove('open'); /*fix 1400 pixel open item resize*/

                if (sideBarMenuItems[y].children[1].children[1])
                  sideBarMenuItems[y].children[1].children[1].classList.remove('open'); /*fix 1400 pixel open item resize*/
              }

              event.preventDefault();
              sideBarMenuItemsIco[i].parentElement.classList.add('active');

              let sideBarMenuItemContent = sideBarMenuItemsIco[i].nextElementSibling;
              sideBarMenuItemContent.children[0].classList.add('open');
              if (sideBarMenuItemContent.children[1])
                sideBarMenuItemContent.children[1].classList.add('open');
            }
          );
        }

      }

      document.addEventListener('click', (e) => {
        if (document.querySelector('.side-bar.open') !== null) {
          if (e.target.closest('.side-bar') === null) {
            sideBar.classList.remove('open');
          }
        }
      })
      /***  END  ***/

    }

  }
}
