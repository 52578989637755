import {API} from "../../axios/axiosMainUrl";

const prefixOrderSize = '/orders/order-size'

export const orderSize = {
  state: {
    orderSize: [],
    orderSizeItem: {},
    orderSizeCommonList: [],
    orderSizeBtn: false,
    orderSizeLoading: true,
    nextOrderSizePage: false,
    orderSizeForPage: 25,
    orderSizeFilter: '',
  },

  mutations: {
    setOrderSizeFilter: (state, payload) => {
      state.orderSizeFilter = payload
    },

    setOrderSize: (state, payload) => {
      if(state.nextOrderSizePage) {
        state.orderSize = state.orderSize.concat(payload.orderSize)
      } else {
        state.orderSize = payload.orderSize
      }
    },

    setOrderSizeCommonList: (state, payload) => {
      state.orderSizeCommonList = payload.orderSizeCommonList
    },

    setNextOrderSizePage: (state, payload) => {
      state.nextOrderSizePage = payload
    },

    setOrderSizeItem: (state, payload) => {
      state.orderSizeItem = payload.orderSizeItem
    },

    setOrderSizeLoadingStart: (state) => {
      state.orderSizeLoading = true
    },

    setOrderSizeLoadingEnd: (state) => {
      state.orderSizeLoading = false
    },

    changeOrderSizeBtn: (state) => {
      state.orderSizeBtn = true
    },
    successOrderSizeBtn: (state) => {
      state.orderSizeBtn = false
    },
  },

  getters: {
    getOrderSizeFilter: state => {
      return state.orderSizeFilter
    },

    getOrderSizeForPage: state => {
      return state.orderSizeForPage
    },

    getOrderSize: state => {
      return state.orderSize
    },

    getOrderSizeLoading: state => {
      return state.orderSizeLoading
    },


    getNextOrderSizePage: state => {
      return state.nextOrderSizePage
    },

    getOrderSizeItem: state => {
      return state.orderSizeItem
    },

    getOrderSizeCommonList: state => {
      return state.orderSizeCommonList
    },

    getOrderSizeBtn: state => {
      return state.orderSizeBtn
    },
  },

  actions: {

    async fetchOrderSize({commit, getters}, filter = '') {
      if(!getters.getNextOrderSizePage)
        commit('setOrderSizeLoadingStart')
      try {
        return await API.get(`${prefixOrderSize}/public-list${filter}`).then((response) =>{
          commit('setOrderSize',{orderSize: response.data.data.data})
          commit('setOrderSizeCommonList',{orderSizeCommonList: response.data.data})
          commit('setOrderSizeLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrderSizeLoadingEnd');
        return e;
      }
    },

    async fetchOrderSizeWithPermissions({commit, getters}, filter = '') {
      if(!getters.getNextOrderSizePage)
        commit('setOrderSizeLoadingStart')
      try {
        return await API.get(`${prefixOrderSize}${filter}`).then((response) =>{
          commit('setOrderSize',{orderSize: response.data.data.data})
          commit('setOrderSizeCommonList',{orderSizeCommonList: response.data.data})
          commit('setOrderSizeLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrderSizeLoadingEnd');
        return e;
      }
    },

    async getOrderSize({commit}, id) {
      // commit('setOrderSizeLoadingStart');
      try {
        return await API.get(`${prefixOrderSize}/${id}`).then((response) =>{
          commit('setOrderSizeItem', {orderSizeItem: response.data.data})
          commit('setOrderSizeLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrderSizeLoadingEnd');
        return e;
      }
    },

    async createOrderSize({commit}, data) {
      commit('changeOrderSizeBtn');
      try {
        return await API.post(`${prefixOrderSize}`, data).then((response) =>{
          commit('successOrderSizeBtn');
          return response;
        });
      } catch (e) {
        commit('successOrderSizeBtn');
        return e;
      }
    },

    async updateOrderSize({commit}, {id, data}) {
      commit('changeOrderSizeBtn');
      try {
        return await API.put(`${prefixOrderSize}/${id}`, data).then((response) =>{
          commit('successOrderSizeBtn');
          return response;
        });
      } catch (e) {
        commit('successOrderSizeBtn');
        return e;
      }
    },

    async deleteOrderSize({commit}, id) {
      try {
        return await API.delete(`${prefixOrderSize}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successOrderSizeBtn');
        return e;
      }
    },

    async getOrderSizePrint({commit}, {type, sizeType}) {
      try {
        return await API.get(`${prefixOrderSize}/print/${type}?filter[bySizeType]=${sizeType}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successOrderSizeBtn');
        return e;
      }
    },



    async getOrderSizeFile({commit}, {type, sizeType}) {
      try {
        return await API.get(`${prefixOrderSize}/print/${type}?filter[bySizeType]=${sizeType}`).then((response) =>{
          return response
        })
      } catch (e) {
        commit('successOrderSizeBtn')
        return e;
      }
    },

  }
}
