<template>
  <div class="datepicker-default">
    <label class="datepicker-default__label"
           :class="{ active: activeInput || value != null || placeholder != null || label != null, disabled : disabled === true}"
           @click="activeInput = true"
           :id="'datepicker-default-' + id"
    >
      <span class="datepicker__txt">
        {{label}}
      </span>
      <slot name="body">

      </slot>
      <span class="default-select__error error-field" v-if="error && errorTxt">{{errorTxt}}</span>
    </label>
  </div>
</template>

<script>
  export default {
    name: "DatePickerDefault",

    props: [
      'label',
      'value',
      'placeholder',
      'disabled',
      'error',
      'errorTxt',
    ],

    data () {
      return {
        activeInput: false,
        id: null,
      }
    },

    mounted(){
      this.id = this._uid

      document
        .querySelectorAll(".mx-datepicker .mx-input")
        .forEach(e => (e.readOnly = true))
    },

    methods: {
      go(){
        alert(345)
      }
    }
  }
</script>

<style lang="scss">
  @import '~vue2-datepicker/scss/index.scss';
  @import '../../../../scss/colors';

  .datepicker-default{
    display: block;
    position: relative;

    &.white input[name='date']{
      background: white;
      border: 1px solid #F4ECE1;
      border-block: none;
    }

    &.white.active span.datepicker__txt {
      background: linear-gradient(0deg, #FFFFFF -0.91%, #F8F4EE 100%);
    }

    &.ui-no-valid input[name='date']{
      border: 2px solid $orange;
    }

    input[name='date']{
      font-size: 14px;
      line-height: 16px;
      width: 100%;

      padding-left: 16px;
      height: 42px;
      background: $mainBg;
      border: 1px solid $borderBrown;
      box-sizing: border-box;
      border-radius: 5px;

      &::placeholder{
        font-size: 14px;
        line-height: 16px;
        color: $borderBrown;
        line-height: revert;
      }
    }

    &__label{

      span.datepicker__txt {
        position: absolute;
        left: 16px;
        top: 13px;
        font-size: 14px;
        line-height: 16px;
        color: $borderBrown;
        transition: 0.15s all;
        z-index: 3;
      }

      &.active span.datepicker__txt{
        top: -5px;
        background: white;
        background: linear-gradient(0deg, #F8F4EE 0%, #FFFFFF 105.56%);
        padding: 0 2px;
        font-size: 10px;
        line-height: 12px;
      }

      &.disabled {
        > span.datepicker__txt {
          background: #e9e2da !important;
        }

        .mx-input{
          border-color: #e9e2da;
        }
      }

    }




    .mx-datepicker{
      width: 100%;
      /*max-width: 150px;*/
    }

    /*&.hideDate .mx-datepicker{*/
      /*width: 100% !important;*/
    /*}*/

    .mx-input[disabled="disabled"]{
      background: $brownOpacity02;
    }

  }
</style>
