
import {routerPaths} from '../../../router/routerPaths'

const HSCodesTable = () => import(/* webpackChunkName: "group-hs-codes" */ './components/HSCodesTable/HSCodesTable.vue')
const moduleKey = 'hs-codes'


export const HSCodesRoutes = [
  {
    path: routerPaths.mainSettingsHSCodes,
    component: HSCodesTable,
    meta: {
      breadcrumbs: {
        key: [
          'dashboard',
          'HSCodes'
        ],
        items: {
          'dashboard': {
            name: 'menu_proform',
            link: routerPaths.mainSettingsHSCodes,
          },
          'HSCodes': {
            name: 'menu_hSCodes',
            link: routerPaths.mainSettingsHSCodes,
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'menu_hSCodes'
    },
  },

]
