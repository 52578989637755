import {API} from "../../axios/axiosMainUrl";

const prefix = 'heartysan/etsy'

export const heartysanEtsy = {
  state: {
    heartysanEtsy: [],
    heartysanEtsyItem: {},
    heartysanEtsyCommonList: [],
    heartysanEtsyBtn: false,
    heartysanEtsyLoading: true,
    nextHeartysanEtsyPage: false,
    heartysanEtsyForPage: 25,
    heartysanEtsyFilter: '',
  },

  mutations: {
    setHeartysanEtsyFilter: (state, payload) => {
      state.heartysanEtsyFilter = payload
    },

    setHeartysanEtsy: (state, payload) => {
      if(state.nextHeartysanEtsyPage) {
        state.heartysanEtsy = state.heartysanEtsy.concat(payload.heartysanEtsy)
      } else {
        state.heartysanEtsy = payload.heartysanEtsy
      }
    },

    setHeartysanEtsyCommonList: (state, payload) => {
      state.heartysanEtsyCommonList = payload.heartysanEtsyCommonList
    },

    setNextHeartysanEtsyPage: (state, payload) => {
      state.nextHeartysanEtsyPage = payload
    },

    setHeartysanEtsyItem: (state, payload) => {
      state.heartysanEtsyItem = payload.heartysanEtsyItem
    },

    setHeartysanEtsyLoadingStart: (state) => {
      state.heartysanEtsyLoading = true
    },

    setHeartysanEtsyLoadingEnd: (state) => {
      state.heartysanEtsyLoading = false
    },

    changeHeartysanEtsyBtn: (state) => {
      state.heartysanEtsyBtn = true
    },
    successHeartysanEtsyBtn: (state) => {
      state.heartysanEtsyBtn = false
    },
  },

  getters: {
    getHeartysanEtsyFilter: state => {
      return state.heartysanEtsyFilter
    },

    getHeartysanEtsyForPage: state => {
      return state.heartysanEtsyForPage
    },

    getHeartysanEtsy: state => {
      return state.heartysanEtsy
    },

    getHeartysanEtsyLoading: state => {
      return state.heartysanEtsyLoading
    },


    getNextHeartysanEtsyPage: state => {
      return state.nextHeartysanEtsyPage
    },

    getHeartysanEtsyItem: state => {
      return state.heartysanEtsyItem
    },

    getHeartysanEtsyCommonList: state => {
      return state.heartysanEtsyCommonList
    },

    getHeartysanEtsyBtn: state => {
      return state.heartysanEtsyBtn
    },
  },

  actions: {

    async fetchHeartysanEtsy({commit, getters}, filter = '') {
      if(!getters.getNextHeartysanEtsyPage)
        commit('setHeartysanEtsyLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setHeartysanEtsy',{heartysanEtsy: response.data.data.data})
          commit('setHeartysanEtsyCommonList',{heartysanEtsyCommonList: response.data.data})
          commit('setHeartysanEtsyLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setHeartysanEtsyLoadingEnd');
        return e;
      }
    },

    async getHeartysanEtsy({commit}, id) {
      commit('setHeartysanEtsyLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setHeartysanEtsyItem', {heartysanEtsyItem: response.data.data})
          commit('setHeartysanEtsyLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setHeartysanEtsyLoadingEnd');
        return e;
      }
    },

    async getHeartysanEtsyShops({commit}) {
      commit('setHeartysanEtsyLoadingStart');
      try {
        return await API.get(`${prefix}/shops`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setHeartysanEtsyLoadingEnd');
        return e;
      }
    },

    async createHeartysanEtsy({commit}, data) {
      commit('changeHeartysanEtsyBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successHeartysanEtsyBtn');
          return response;
        });
      } catch (e) {
        commit('successHeartysanEtsyBtn');
        return e;
      }
    },

    async updateHeartysanEtsy({commit}, {id, data}) {
      commit('changeHeartysanEtsyBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successHeartysanEtsyBtn');
          return response;
        });
      } catch (e) {
        commit('successHeartysanEtsyBtn');
        return e;
      }
    },

    async deleteHeartysanEtsy({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successHeartysanEtsyBtn');
        return e;
      }
    },

  }
}
