
import {routerPaths} from '../../../router/routerPaths'

const ChatPage = () => import(/* webpackChunkName: "group-chat" */ './components/ChatPage/ChatPage.vue')


export const ChatRoutes = [
  {
    path: routerPaths.chat,
    component: ChatPage,
    meta: {
      breadcrumbs: {
        key: [
          'chat'
        ],
        items: {
          'chat': {
            name: 'menu_Chat',
            link: routerPaths.chat,
          },
        },
      },
      pageTitle: 'menu_Chat'
    },
  },

]
