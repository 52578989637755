<template>
  <div class="fragment">
<!--    <div class="soft-text mb-5"-->
<!--         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--      <div class="admin-edit" @click="editTranslate(['common_toTheProduct',])"></div>-->
<!--      {{$t('common_toTheProduct.localization_value.value')}}-->
<!--    </div>-->
    <div class="site-table-wrap table-small">
      <div class="history-table__wrap">
        <div class="history-table">
          <div class="history-table__head">
            <div class="history-table__row">
              <div class="history-table__col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" style="top: 3px" @click="editTranslate(['common_historyDate',])"></div>
                {{$t('common_historyDate.localization_value.value')}}
              </div>
              <div class="history-table__col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" style="top: 3px" @click="editTranslate(['common_historyUsers',])"></div>
                {{$t('common_historyUsers.localization_value.value')}}
              </div>
              <div class="history-table__col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" style="top: 3px" @click="editTranslate(['common_historyAction',])"></div>
                {{$t('common_historyAction.localization_value.value')}}
              </div>
              <div class="history-table__col"></div>
            </div>
          </div>
          <div class="history-table__body">
            <div class="fragment"
                 v-for="(item, i) in historyChanges"
                 :key="i">
              <HistoryOfChangesTableItem
                  :pevItem="i === 0 ? null : historyChanges[i-1]"
                  :item="item"
                  :type="type"
                  :index="i"
              />
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import HistoryOfChangesTableItem
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/components/HistoryOfChangesTable/HistoryOfChangesTableItem/HistoryOfChangesTableItem";
export default {
  name: "HistoryOfChangesTable",
  components: {
    HistoryOfChangesTableItem
  },

  props: {
    historyChanges: Array,
    type: String,
  },

}
</script>

<style scoped>

</style>