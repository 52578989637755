import {routerPaths} from '../../../router/routerPaths'

const ProcessFBMReportTable = () => import(/* webpackChunkName: "group-process-fbm-report" */ './components/ProcessFBMReportTable/ProcessFBMReportTable.vue')
const moduleKey = 'process-fbm-report'

export const ProcessFBMReportRoutes = [
  {
    path: routerPaths.reportsProcessFBMReport,
    component: ProcessFBMReportTable,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'reportsProcessFBMReport'
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.reportsProcessFBMReport,
          },
          'reportsProcessFBMReport': {
            name: 'menu_financeProcessFBMReport',
            link: routerPaths.reportsProcessFBMReport,
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'menu_financeProcessFBMReport'
    },
  }
]
