import {API} from "../../axios/axiosMainUrl";

export const warehouseStorage = {
  state: {
    warehouseStorage: [],
    warehouseStorageItem: {},
    warehouseStorageCommonList: [],
    warehouseStorageBtn: false,
    warehouseStorageLoading: true,
    nextWarehouseStoragePage: false,
    warehouseStorageForPage: 25,
    warehouseStorageFilter: '',
  },

  mutations: {
    setWarehouseStorageFilter: (state, payload) => {
      state.warehouseStorageFilter = payload
    },

    setWarehouseStorage: (state, payload) => {
      if(state.nextWarehouseStoragePage) {
        state.warehouseStorage = state.warehouseStorage.concat(payload.warehouseStorage)
      } else {
        state.warehouseStorage = payload.warehouseStorage
      }
    },

    setWarehouseStorageCommonList: (state, payload) => {
      state.warehouseStorageCommonList = payload.warehouseStorageCommonList
    },

    setNextWarehouseStoragePage: (state, payload) => {
      state.nextWarehouseStoragePage = payload
    },

    setWarehouseStorageItem: (state, payload) => {
      state.warehouseStorageItem = payload.warehouseStorageItem
    },

    setWarehouseStorageLoadingStart: (state) => {
      state.warehouseStorageLoading = true
    },

    setWarehouseStorageLoadingEnd: (state) => {
      state.warehouseStorageLoading = false
    },

    changeWarehouseStorageBtn: (state) => {
      state.warehouseStorageBtn = true
    },
    successWarehouseStorageBtn: (state) => {
      state.warehouseStorageBtn = false
    },
  },

  getters: {
    getWarehouseStorageFilter: state => {
      return state.warehouseStorageFilter
    },

    getWarehouseStorageForPage: state => {
      return state.warehouseStorageForPage
    },

    getWarehouseStorage: state => {
      return state.warehouseStorage
    },

    getWarehouseStorageLoading: state => {
      return state.warehouseStorageLoading
    },


    getNextWarehouseStoragePage: state => {
      return state.nextWarehouseStoragePage
    },

    getWarehouseStorageItem: state => {
      return state.warehouseStorageItem
    },

    getWarehouseStorageCommonList: state => {
      return state.warehouseStorageCommonList
    },

    getWarehouseStorageBtn: state => {
      return state.warehouseStorageBtn
    },
  },

  actions: {

    async fetchWarehouseStorage({commit, getters}, filter = '') {
      if(!getters.getNextWarehouseStoragePage)
        commit('setWarehouseStorageLoadingStart')
      try {
        return await API.get(`/warehouse/storage${filter}`).then((response) =>{
          commit('setWarehouseStorage',{warehouseStorage: response.data.data.data})
          commit('setWarehouseStorageCommonList',{warehouseStorageCommonList: response.data.data})
          commit('setWarehouseStorageLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setWarehouseStorageLoadingEnd');
        return e;
      }
    },

    async fetchWarehouseStorageByCountry({commit, getters}, data = '') {
      if(!getters.getNextWarehouseStoragePage)
        commit('setWarehouseStorageLoadingStart')
      try {
        return await API.post(`/warehouse/storage/storage-list`, data).then((response) =>{
          // commit('setWarehouseStorage',{warehouseStorage: response.data.data.data})
          // commit('setWarehouseStorageCommonList',{warehouseStorageCommonList: response.data.data})
          commit('setWarehouseStorageLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setWarehouseStorageLoadingEnd');
        return e;
      }
    },

    async getWarehouseStorage({commit}, id) {
      commit('setWarehouseStorageLoadingStart');
      try {
        return await API.get(`/warehouse/storage/${id}`).then((response) =>{
          // console.log(response)
          commit('setWarehouseStorageItem', {warehouseStorageItem: response.data.data})
          commit('setWarehouseStorageLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setWarehouseStorageLoadingEnd');
        return e;
      }
    },

    async createWarehouseStorage({commit}, data) {
      commit('changeWarehouseStorageBtn');
      try {
        return await API.post(`/warehouse/storage`, data).then((response) =>{
          commit('successWarehouseStorageBtn');
          return response;
        });
      } catch (e) {
        commit('successWarehouseStorageBtn');
        return e;
      }
    },

    async updateWarehouseStorage({commit}, {id, data}) {
      commit('changeWarehouseStorageBtn');
      try {
        return await API.put(`/warehouse/storage/${id}`, data).then((response) =>{
          commit('successWarehouseStorageBtn');
          return response;
        });
      } catch (e) {
        commit('successWarehouseStorageBtn');
        return e;
      }
    },

    async deleteWarehouseStorage({commit}, id) {
      try {
        return await API.delete(`/warehouse/storage/${id}`).then((response) =>{
          // console.log(response);
          return response;
        });
      } catch (e) {
        commit('successWarehouseStorageBtn');
        return e;
      }
    },


    /** ROW **/
    async getWarehouseStorageRow({commit}, id) {
      commit('setWarehouseStorageLoadingStart');
      try {
        return await API.get(`/warehouse/storage/row/${id}`).then((response) =>{
          // console.log(response)
          commit('setWarehouseStorageLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setWarehouseStorageLoadingEnd');
        return e;
      }
    },

    async createWarehouseStorageRow({commit}, data) {
      commit('changeWarehouseStorageBtn');
      try {
        return await API.post(`/warehouse/storage/row`, data).then((response) =>{
          commit('successWarehouseStorageBtn');
          return response;
        });
      } catch (e) {
        commit('successWarehouseStorageBtn');
        return e;
      }
    },

    async updateWarehouseStorageRow({commit}, {id, data}) {
      commit('changeWarehouseStorageBtn');
      try {
        return await API.put(`/warehouse/storage/row/${id}`, data).then((response) =>{
          commit('successWarehouseStorageBtn');
          return response;
        });
      } catch (e) {
        commit('successWarehouseStorageBtn');
        return e;
      }
    },

    async deleteWarehouseStorageRow({commit}, id) {
      try {
        return await API.delete(`/warehouse/storage/row/${id}`).then((response) =>{
          // console.log(response);
          return response;
        });
      } catch (e) {
        commit('successWarehouseStorageBtn');
        return e;
      }
    },

    /** SECTION **/
    async getWarehouseStorageSection({commit}, id) {
      commit('setWarehouseStorageLoadingStart');
      try {
        return await API.get(`/warehouse/storage/section/${id}`).then((response) =>{
          // console.log(response)
          commit('setWarehouseStorageLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setWarehouseStorageLoadingEnd');
        return e;
      }
    },

    async createWarehouseStorageSection({commit}, data) {
      commit('changeWarehouseStorageBtn');
      try {
        return await API.post(`/warehouse/storage/section`, data).then((response) =>{
          commit('successWarehouseStorageBtn');
          return response;
        });
      } catch (e) {
        commit('successWarehouseStorageBtn');
        return e;
      }
    },

    async updateWarehouseStorageSection({commit}, {id, data}) {
      commit('changeWarehouseStorageBtn');
      try {
        return await API.put(`/warehouse/storage/section/${id}`, data).then((response) =>{
          commit('successWarehouseStorageBtn');
          return response;
        });
      } catch (e) {
        commit('successWarehouseStorageBtn');
        return e;
      }
    },

    async deleteWarehouseStorageSection({commit}, id) {
      try {
        return await API.delete(`/warehouse/storage/section/${id}`).then((response) =>{
          // console.log(response);
          return response;
        });
      } catch (e) {
        commit('successWarehouseStorageBtn');
        return e;
      }
    },

    /** SHELF **/
    async getWarehouseStorageShelf({commit}, id) {
      commit('setWarehouseStorageLoadingStart');
      try {
        return await API.get(`/warehouse/storage/shelf/${id}`).then((response) =>{
          // console.log(response)
          commit('setWarehouseStorageLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setWarehouseStorageLoadingEnd');
        return e;
      }
    },

    async createWarehouseStorageShelf({commit}, data) {
      commit('changeWarehouseStorageBtn');
      try {
        return await API.post(`/warehouse/storage/shelf`, data).then((response) =>{
          commit('successWarehouseStorageBtn');
          return response;
        });
      } catch (e) {
        commit('successWarehouseStorageBtn');
        return e;
      }
    },

    async updateWarehouseStorageShelf({commit}, {id, data}) {
      commit('changeWarehouseStorageBtn');
      try {
        return await API.put(`/warehouse/storage/shelf/${id}`, data).then((response) =>{
          commit('successWarehouseStorageBtn');
          return response;
        });
      } catch (e) {
        commit('successWarehouseStorageBtn');
        return e;
      }
    },

    async deleteWarehouseStorageShelf({commit}, id) {
      try {
        return await API.delete(`/warehouse/storage/shelf/${id}`).then((response) =>{
          // console.log(response);
          return response;
        });
      } catch (e) {
        commit('successWarehouseStorageBtn');
        return e;
      }
    },

    /** CELL **/
    async getWarehouseStorageCell({commit}, id) {
      commit('setWarehouseStorageLoadingStart');
      try {
        return await API.get(`/warehouse/storage/cell/${id}`).then((response) =>{
          // console.log(response)
          commit('setWarehouseStorageLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setWarehouseStorageLoadingEnd');
        return e;
      }
    },

    async createWarehouseStorageCell({commit}, data) {
      commit('changeWarehouseStorageBtn');
      try {
        return await API.post(`/warehouse/storage/cell`, data).then((response) =>{
          commit('successWarehouseStorageBtn');
          return response;
        });
      } catch (e) {
        commit('successWarehouseStorageBtn');
        return e;
      }
    },

    async updateWarehouseStorageCell({commit}, {id, data}) {
      commit('changeWarehouseStorageBtn');
      try {
        return await API.put(`/warehouse/storage/cell/${id}`, data).then((response) =>{
          commit('successWarehouseStorageBtn');
          return response;
        });
      } catch (e) {
        commit('successWarehouseStorageBtn');
        return e;
      }
    },

    async deleteWarehouseStorageCell({commit}, id) {
      try {
        return await API.delete(`/warehouse/storage/cell/${id}`).then((response) =>{
          // console.log(response);
          return response;
        });
      } catch (e) {
        commit('successWarehouseStorageBtn');
        return e;
      }
    },

    async getWarehouseStorageCellWithFilter({commit}, filter = '') {
      commit('setWarehouseStorageLoadingStart');
      try {
        return await API.get(`/warehouse/storage/cell${filter}`).then((response) =>{
          // console.log(response)
          commit('setWarehouseStorageLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setWarehouseStorageLoadingEnd');
        return e;
      }
    },

    async getWarehouseStorageCellWithFilterPublic({commit}, filter = '') {
      commit('setWarehouseStorageLoadingStart');
      try {
        return await API.get(`/warehouse/storage/cell/public-list${filter}`).then((response) =>{
          // console.log(response)
          commit('setWarehouseStorageLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setWarehouseStorageLoadingEnd');
        return e;
      }
    },


    /** PRINT **/
    async getWarehouseStoragePrint({commit}, id) {
      commit('changeWarehouseStorageBtn');
      try {
        return await API.get(`/warehouse/storage/cell/${id}/pdf-marking`).then((response) =>{
          // console.log(response);
          commit('successWarehouseStorageBtn');
          return response;
        });
      } catch (e) {
        commit('successWarehouseStorageBtn');
        return e;
      }
    },

    async relocateWarehouseStorageCell({commit}, data) {
      commit('changeWarehouseStorageBtn');
      try {
        return await API.post(`/warehouse/storage/relocate`, data).then((response) =>{
          commit('successWarehouseStorageBtn');
          return response;
        });
      } catch (e) {
        commit('successWarehouseStorageBtn');
        return e;
      }
    },

  }
}
