import {API} from "../../axios/axiosMainUrl";

const prefix = '/delivery/zone-price'

export const zonePrice = {
  state: {
    zonePrice: [],
    zonePriceItem: {},
    zonePriceCommonList: [],
    zonePriceBtn: false,
    zonePriceLoading: true,
    nextZonePricePage: false,
    zonePriceForPage: 25,
    zonePriceFilter: '',
  },

  mutations: {
    setZonePriceFilter: (state, payload) => {
      state.zonePriceFilter = payload
    },

    setZonePrice: (state, payload) => {
      if(state.nextZonePricePage) {
        state.zonePrice = state.zonePrice.concat(payload.zonePrice)
      } else {
        state.zonePrice = payload.zonePrice
      }
    },

    setZonePriceCommonList: (state, payload) => {
      state.zonePriceCommonList = payload.zonePriceCommonList
    },

    setNextZonePricePage: (state, payload) => {
      state.nextZonePricePage = payload
    },

    setZonePriceItem: (state, payload) => {
      state.zonePriceItem = payload.zonePriceItem
    },

    setZonePriceLoadingStart: (state) => {
      state.zonePriceLoading = true
    },

    setZonePriceLoadingEnd: (state) => {
      state.zonePriceLoading = false
    },

    changeZonePriceBtn: (state) => {
      state.zonePriceBtn = true
    },
    successZonePriceBtn: (state) => {
      state.zonePriceBtn = false
    },
  },

  getters: {
    getZonePriceFilter: state => {
      return state.zonePriceFilter
    },

    getZonePriceForPage: state => {
      return state.zonePriceForPage
    },

    getZonePrice: state => {
      return state.zonePrice
    },

    getZonePriceLoading: state => {
      return state.zonePriceLoading
    },


    getNextZonePricePage: state => {
      return state.nextZonePricePage
    },

    getZonePriceItem: state => {
      return state.zonePriceItem
    },

    getZonePriceCommonList: state => {
      return state.zonePriceCommonList
    },

    getZonePriceBtn: state => {
      return state.zonePriceBtn
    },
  },

  actions: {

    async fetchZonePrice({commit, getters}, filter = '') {
      if(!getters.getNextZonePricePage)
        commit('setZonePriceLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setZonePrice',{zonePrice: response.data.data.data})
          commit('setZonePriceCommonList',{zonePriceCommonList: response.data.data})
          commit('setZonePriceLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setZonePriceLoadingEnd');
        return e;
      }
    },

    async getZonePrice({commit}, id) {
      commit('setZonePriceLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setZonePriceItem', {zonePriceItem: response.data.data})
          commit('setZonePriceLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setZonePriceLoadingEnd');
        return e;
      }
    },

    async getCreateZonePrice({commit}) {
      // commit('setZonePriceLoadingStart');
      try {
        return await API.get(`${prefix}/create`).then((response) =>{
          console.log(response)
          commit('setZonePriceItem', {zonePriceItem: response.data.data})
          // commit('setZonePriceLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setZonePriceLoadingEnd');
        return e;
      }
    },

    async createZonePrice({commit}, data) {
      commit('changeZonePriceBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successZonePriceBtn');
          return response;
        });
      } catch (e) {
        commit('successZonePriceBtn');
        return e;
      }
    },

    async updateZonePrice({commit}, {id, data}) {
      commit('changeZonePriceBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successZonePriceBtn');
          return response;
        });
      } catch (e) {
        commit('successZonePriceBtn');
        return e;
      }
    },

    async deleteZonePrice({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successZonePriceBtn');
        return e;
      }
    },


    async importZonePrice({commit}, data) {
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${prefix}/import`, data).then((response) =>{
          commit('successProductsBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successProductsBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

  }
}
