
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const MainSettingsEdit = () => import(/* webpackChunkName: "group-main-settings" */ './components/MainSettingsEdit/MainSettingsEdit.vue')
const moduleKey = 'main-settings'

export const MainSettingsRoutes = [
  {
    path: routerPaths.mainSettingsEdit,
    component: MainSettingsEdit,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettingsEdit',
        ],
        items: {
          'mainSettingsEdit': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsEdit,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.SETTING_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_mainSettingsEdit'
    },
  },
]
