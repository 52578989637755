import {API} from "../../axios/axiosMainUrl";

const ordersPrefix = '/orders'
const orderFBAPrefix = 'order-fba'

export const ordersFBA = {
  state: {
    ordersFBA: [],
    ordersFBAItem: {},
    ordersFBACommonList: [],
    ordersFBABtn: false,
    ordersFBALoading: true,
    nextOrdersFBAPage: false,
    ordersFBAForPage: 25,
    ordersFBAFilter: '',

    orderFBAItemId: -1
  },

  mutations: {
    setOrdersFBAFilter: (state, payload) => {
      state.ordersFBAFilter = payload
    },

    setOrdersFBA: (state, payload) => {
      if(state.nextOrdersFBAPage) {
        state.ordersFBA = state.ordersFBA.concat(payload.ordersFBA)
      } else {
        state.ordersFBA = payload.ordersFBA
      }
    },

    setOrdersFBACommonList: (state, payload) => {
      state.ordersFBACommonList = payload.ordersFBACommonList
    },

    setNextOrdersFBAPage: (state, payload) => {
      state.nextOrdersFBAPage = payload
    },

    setOrdersFBAItem: (state, payload) => {
      state.ordersFBAItem = payload.ordersFBAItem
    },

    setOrdersFBALoadingStart: (state) => {
      state.ordersFBALoading = true
    },

    setOrdersFBALoadingEnd: (state) => {
      state.ordersFBALoading = false
    },

    changeOrdersFBABtn: (state) => {
      state.ordersFBABtn = true
    },
    successOrdersFBABtn: (state) => {
      state.ordersFBABtn = false
    },


    setOrderFBAItemId: (state, payload) => {
      state.orderFBAItemId = payload

      setTimeout(() => {
        state.orderFBAItemId = -1
      }, 3000)
    },

  },

  getters: {
    getOrdersFBAFilter: state => {
      return state.ordersFBAFilter
    },

    getOrdersFBAForPage: state => {
      return state.ordersFBAForPage
    },

    getOrdersFBA: state => {
      return state.ordersFBA
    },

    getOrdersFBALoading: state => {
      return state.ordersFBALoading
    },


    getNextOrdersFBAPage: state => {
      return state.nextOrdersFBAPage
    },

    getOrdersFBAItem: state => {
      return state.ordersFBAItem
    },


    getOrdersFBACommonList: state => {
      return state.ordersFBACommonList
    },

    getOrdersFBABtn: state => {
      return state.ordersFBABtn
    },


    getOrderFBAItemId: state => {
      return state.orderFBAItemId
    },
  },

  actions: {

    async fetchOrdersFBA({commit, getters}, filter = '') {
      if(!getters.getNextOrdersFBAPage)
        commit('setOrdersFBALoadingStart')
      try {

        return await API.get(`${ordersPrefix}/${orderFBAPrefix}${filter}`).then((response) =>{
          commit('setOrdersFBA',{ordersFBA: response.data.data.data})
          commit('setOrdersFBACommonList',{ordersFBACommonList: response.data.data})
          commit('setOrdersFBALoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrdersFBALoadingEnd');
        return e;
      }
    },

    async getOrderFBAEdit({commit}, id) {
      commit('setOrdersFBALoadingStart');
      try {
        return await API.get(`${ordersPrefix}/${orderFBAPrefix}/${id}/edit`).then((response) =>{
          commit('setOrdersFBAItem', {ordersFBAItem: response.data.data})
          commit('setOrdersFBALoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrdersFBALoadingEnd');
        return e;
      }
    },

    async getOrderFBA({commit}, id) {
      commit('setOrdersFBALoadingStart');
      try {
        return await API.get(`${ordersPrefix}/${orderFBAPrefix}/${id}`).then((response) =>{
          commit('setOrdersFBAItem', {ordersFBAItem: response.data.data})
          commit('setOrdersFBALoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrdersFBALoadingEnd');
        return e;
      }
    },

    async createOrderFBA({commit}, data) {
      commit('changeOrdersFBABtn');
      try {
        return await API.post(`${ordersPrefix}/${orderFBAPrefix}`, data).then((response) =>{
          commit('successOrdersFBABtn');
          commit('setOrderFBAItemId', response.data.data.id)
          return response;
        });
      } catch (e) {
        commit('successOrdersFBABtn');
        return e;
      }
    },

    async updateOrderFBA({commit}, {id, data}) {
      commit('changeOrdersFBABtn');
      try {
        return await API.put(`${ordersPrefix}/${orderFBAPrefix}/${id}`, data).then((response) =>{
          commit('successOrdersFBABtn');
          commit('setOrderFBAItemId', id)
          return response;
        });
      } catch (e) {
        commit('successOrdersFBABtn');
        commit('setOrderFBAItemId', id)
        return e;
      }
    },

    async deleteOrderFBA({commit}, id) {
      try {
        return await API.delete(`${ordersPrefix}/${orderFBAPrefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successOrdersFBABtn');
        return e;
      }
    },

    async getFeeOrderFBA({commit}) {
      commit('changeOrdersFBABtn')
      try {
        return await API.get(`/orders/order-process-fee/fee_order_fba/list?page=1&limit=100`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successOrdersFBABtn')
        return e;
      }
    },

    async getOrderFBAExport({commit}, {filter , exportType}) {
      commit('setExportBtnLoadingStart')
      try {
        return await API.get(`${ordersPrefix}/${orderFBAPrefix}/export/${exportType}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setLoadingEnd')
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

    async buyLabelFBA({commit}, id) {
      commit('changeOrdersFBABtn');
      try {
        return await API.post(`/${orderFBAPrefix}/create-label`, {order_id: id}).then((response) => {
          commit('successOrdersFBABtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBABtn');
        return e;
      }
    },

  }
}
