<template>
  <div class="fragment">

    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('consolidations_shipping.localization_value.value')}}</b></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('consolidations_shippingPartner.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.consolidation_destination_id.currentTranslate.name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.consolidation_destination_id.currentTranslate.name'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_city.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.warehouse.currentTranslate.name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.warehouse.currentTranslate.name'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('consolidations_shippingDate.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.shipped_at'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.shipped_at'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('consolidations_received.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.received_at'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.received_at'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('consolidations_deliveryService.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.delivery_service.name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.delivery_service.name'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('consolidations_trackNumIncCons.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.tracking_number'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.tracking_number'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('consolidations_expFromWarehouse.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.old_delivery_service.name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.old_delivery_service.name'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('consolidations_trackingNumber.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_tracking_number'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_tracking_number'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('consolidations_consolidation.localization_value.value')}}</div>
      <div class="history-table__sub-col">
        <span v-if="pevItem && (pevItem.history.consolidation_union || pevItem.history.old_delivery_service)">
          <ValueHelper v-if="pevItem.history.old_delivery_service" :value="pevItem" :deep="'history.old_delivery_service.name'"/>
          <template v-else>
            <ValueHelper :value="item" :deep="'history.consolidation_union.delivery_service.name'"/>:
            <ValueHelper :value="item" :deep="'history.consolidation_union.tracking_number'"/>
          </template>
        </span>
        <template v-else>—</template>
      </div>
      <div class="history-table__sub-col">
        <span v-if="item && item.history.consolidation_union">
          <ValueHelper :value="item" :deep="'history.consolidation_union.delivery_service.name'"/>:
          <ValueHelper :value="item" :deep="'history.consolidation_union.tracking_number'"/>
        </span>
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('consolidations_status.localization_value.value')}}</div>
      <div class="history-table__sub-col"><span v-if="pevItem">{{$t(getStatusItemPrev + '.localization_value.value')}}</span> <span v-else>{{getStatusItemPrev}}</span></div>
      <div class="history-table__sub-col">{{$t(getStatusItem + '.localization_value.value')}}</div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('consolidations_TimeGap.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.available_courier_time'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.available_courier_time'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('consolidations_lunchPeriod.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.not_available_courier_time'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.not_available_courier_time'"/></div>
    </div>


    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('consolidations_prices.localization_value.value')}}</b></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('consolidations_shippingCostByAdmin.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.admin_order_amount'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.admin_order_amount'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('consolidations_shippingCost.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.payed_invoice.net_cost_amount'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.payed_invoice.net_cost_amount'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('consolidations_packaging.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.packaging_amount'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.packaging_amount'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('consolidations_orderProcessingFee.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.order_process_fee'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.order_process_fee'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('consolidations_vatCost.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.vat_amount'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.vat_amount'"/></div>
    </div>

    <!--    /////////////-->
    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('express_Packaging.localization_value.value')}}</b></div>
    </div>

    <PackagingTable
        :name="false"
        :pevItem="pevItem ? {packaging: pevItem.history.proform_packaging} : pevItem"
        :item="{packaging: item.history.proform_packaging}"
    />

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('consolidations_comment.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.comment'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.comment'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col align-items-start">{{$t('consolidations_ordersFBMInc.localization_value.value')}}</div>
      <div class="history-table__sub-col align-items-start d-block">
        <template v-if="pevItem && pevItem.history.consolidation_orders.length > 0">
          <template v-for="(fbm, index) in pevItem.history.consolidation_orders">
            <div class="mb-1" :key="index + 'fbm'">#<ValueHelper :value="fbm" :deep="'orderInstance.id'" /></div>
          </template>
        </template>
        <template v-else>—</template>
      </div>
      <div class="history-table__sub-col align-items-start d-block">
        <template v-if="item.history.consolidation_orders.length > 0">
          <template v-for="(fbm, index) in item.history.consolidation_orders" >
            <div class="mb-1" :key="index + 'fbm2'">#<ValueHelper :value="fbm" :deep="'orderInstance.id'"/></div>
          </template>
        </template>
        <template v-else>—</template>
      </div>
    </div>

<!--    <div class="history-table__sub-row" v-for="(fbm, index) in item.history.consolidation_orders" :key="index">-->
<!--      <div class="history-table__sub-col">{{$t('consolidations_ordersFBMInc.localization_value.value')}}</div>-->
<!--      <div :key="index" class="history-table__sub-col"><template v-if="pevItem && _.has(pevItem.history.consolidation_orders, index)">#<ValueHelper :value="pevItem" :deep="'history.consolidation_orders.' + index + '.order_id'"/></template><template v-else>—</template></div>-->
<!--      <div class="history-table__sub-col">#<ValueHelper :value="fbm" :deep="'orderInstance.id'"/></div>-->
<!--    </div>-->

<!--    <template v-if="pevItem && pevItem.history.consolidation_orders.length > item.history.consolidation_orders.length">-->
<!--      <template v-for="(fbm, index) in pevItem.history.consolidation_orders">-->
<!--        <div class="history-table__sub-row" v-if="index > item.history.consolidation_orders.length - 1" :key="index + 'fbm'">-->
<!--          <div class="history-table__sub-col">{{$t('consolidations_ordersFBMInc.localization_value.value')}}</div>-->
<!--          <div class="history-table__sub-col"><ValueHelper :value="fbm" :deep="'orderInstance.id'"/></div>-->
<!--          <div class="history-table__sub-col">—</div>-->
<!--        </div>-->
<!--      </template>-->

<!--    </template>-->

<!--    <div class="history-table__sub-row">-->
<!--      <div class="history-table__sub-col">{{$t('consolidations_documents.localization_value.value')}}</div>-->
<!--      &lt;!&ndash;      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.comment'"/></div>&ndash;&gt;-->
<!--      &lt;!&ndash;      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.comment'"/></div>&ndash;&gt;-->
<!--    </div>-->

    <!--    /////////////-->
    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('consolidations_productsForConsolidation.localization_value.value')}}</b></div>
    </div>

    <ProformTable
        v-for="(proform, index) in item.history.proform_entities"
        :key="index + 'proform'"
        :pevItem="pevItem && _.has(getActualOrderProform, index) ? {history: getActualOrderProform[index]} : null"
        :item="{history: proform}"
    />
    <template v-if="pevItem && getDeletedOrderProform">
      <template v-for="(proform, index) in getDeletedOrderProform">
        <ProformTable
            :key="index + 'proform0'"
            :pevItem="{history: proform}"
            :item="null"
        />
      </template>
    </template>

    <!--    /////////////-->
    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('consolidations_addFromWarehouse.localization_value.value')}}</b></div>
    </div>

    <template v-for="(proform, index) in item.history.productProformEntities">
<!--      <template v-if="hasOldProform(index)"></template>-->
      <div class="history-table__sub-row" :key="index + 'proform1'">
        <div class="history-table__sub-col">{{$t('product_ProductName.localization_value.value')}}</div>
<!--        _.has(pevItem.history.productProformEntities, index)-->
        <div class="history-table__sub-col"><template v-if="pevItem && _.has(getActualProductProform, index)"><ValueHelper :value="getActualProductProform[index]" :deep="'product.name_for_fba'"/></template><template v-else>—</template></div>
        <div class="history-table__sub-col"><ValueHelper :value="proform" :deep="'product.name_for_fba'"/></div>
      </div>
      <CellsTable
          :key="index + 'cells'"
          :itemCells="getProductProformCells(proform)"
          :pevCells="getPrevProductProformCells(index)"
      />

      <ProformTable
          :key="index + 'proform2'"
          :pevItem="pevItem && _.has(getActualProductProform, index) ? {history: getProductProform(getActualProductProform[index])} : null"
          :item="{history: getProductProform(proform)}"
      />
    </template>


<!--    <template v-if="_.has(item.history.proform_deleted_ids, 'product_entities') && item.history.proform_deleted_ids.product_entities.length > item.history.productProformEntities.length">-->
<!--      <template v-for="(id, index) in item.history.proform_deleted_ids.product_entities">-->
<!--        <template-->
<!--            v-if="index > item.history.productProformEntities.length && _.find(pevItem.history.productProformEntities, {id: parseInt(id)}) && getA(index, id)">-->
<!--&lt;!&ndash;          v-if="isDeletedOldProductProform(proform.id)"&ndash;&gt;-->
<!--          <div class="history-table__sub-row" :key="index + 'productProformEntities1'">-->
<!--            <div class="history-table__sub-col">{{$t('product_ProductName.localization_value.value')}}</div>-->
<!--            <div class="history-table__sub-col"><ValueHelper :value="_.find(pevItem.history.productProformEntities, {id: parseInt(id)})" :deep="'product.name_for_fba'"/></div>-->
<!--            <div class="history-table__sub-col">—</div>-->
<!--          </div>-->
<!--          <CellsTable-->
<!--              v-if="getB(id)"-->
<!--              :key="index + 'cellsPrev'"-->
<!--              :pevCells="_.find(pevItem.history.productEntities, {product_id: _.find(pevItem.history.productProformEntities, {id: parseInt(id)}).product_id}) ? _.find(pevItem.history.productEntities, {product_id: _.find(pevItem.history.productProformEntities, {id: parseInt(id)}).product_id}).cells : []"-->
<!--              :itemCells="[]"-->
<!--          />-->
<!--          <ProformTable-->
<!--              :key="index + 'productProformEntities'"-->
<!--              :pevItem="{history: getProductProform(_.find(pevItem.history.productProformEntities, {id: parseInt(id)}))}"-->
<!--              :item="null"-->
<!--          />-->
<!--        </template>-->
<!--      </template>-->
<!--    </template>-->

    <template v-if="getDeletedProductProform">
      <template v-for="(proform, index) in getDeletedProductProform">
          <div class="history-table__sub-row" :key="index + 'productProformEntities1'">
            <div class="history-table__sub-col">{{$t('product_ProductName.localization_value.value')}}</div>
            <div class="history-table__sub-col"><ValueHelper :value="proform" :deep="'product.name_for_fba'"/></div>
            <div class="history-table__sub-col">—</div>
          </div>
          <CellsTable
              :key="index + 'cellsPrev'"
              :pevCells="getPrevDeletedProductProformCells(proform)"
              :itemCells="[]"
          />
          <ProformTable
              :key="index + 'productProformEntities'"
              :pevItem="{history: getProductProform(proform)}"
              :item="null"
          />
      </template>
    </template>

    <!--    /////////////-->
    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('consolidations_senderInfo.localization_value.value')}}</b></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('consolidations_customerName.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.sender_first_name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.sender_first_name'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('consolidations_phoneNum.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.sender_phone_number'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.sender_phone_number'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('consolidations_address.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.sender_address'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.sender_address'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_city.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.sender_city'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.sender_city'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_region.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.sender_country_region.name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.sender_country_region.name'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_country.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.sender_country.name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.sender_country.name'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_zipCode.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.sender_zip'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.sender_zip'"/></div>
    </div>

    <!--    /////////////-->
    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>Consolidation union</b></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_userAdmin.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.consolidation_union.admin.email'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.consolidation_union.admin.email'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('consolidationUnion_shippingCompany.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.consolidation_union.delivery_service.name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.consolidation_union.delivery_service.name'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('consolidationUnion_trackingNumber.localization_value.value')}}</div>
      <div class="history-table__sub-col">
        <ValueHelper :value="pevItem" :deep="'history.consolidation_union.tracking_number'"/>
      </div>
      <div class="history-table__sub-col">
        <ValueHelper :value="item" :deep="'history.consolidation_union.tracking_number'"/>
      </div>

    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('consolidationUnion_sendDate.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.consolidation_union.send_at'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.consolidation_union.send_at'"/></div>
    </div>


    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('fbm_Paid.localization_value.value') }}</div>
      <div class="history-table__sub-col">
        <StatusIcoBtn :type="'active'" class="mr-1" v-if="pevItem && pevItem.history.prepay"/>
        <StatusIcoBtn :type="'cancel-red'" class="mr-1" v-else/>
      </div>
      <div class="history-table__sub-col">
        <StatusIcoBtn :type="'active'" class="mr-1" v-if="item && item.history.prepay"/>
        <StatusIcoBtn :type="'cancel-red'" class="mr-1" v-else/>
      </div>
    </div>

  </div>
</template>

<script>

import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import PackagingTable
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/chunks/PackagingTable/PackagingTable";
import ProformTable from "@/components/coreComponents/Popups/HistoryOfChangesPopup/chunks/ProformTable/ProformTable";
import {CONSOLIDATION_STATUSES} from "@/staticData/staticVariables";
import CellsTable from "@/components/coreComponents/Popups/HistoryOfChangesPopup/chunks/CellsTable/CellsTable";
import StatusIcoBtn from "@/components/UI/status/StatusIcoBtn/StatusIcoBtn";

export default {
  name: "ConsolidationType",

  components: {StatusIcoBtn, CellsTable, ProformTable, PackagingTable, ValueHelper},

  props: {
    pevItem: Object,
    item: Object,
  },
  computed: {
    getStatusItem() {
      return this._.find(CONSOLIDATION_STATUSES, {value: this.item.history.mark})?.translation
    },
    getStatusItemPrev() {
      if(!this.pevItem) return '—'
      return this._.find(CONSOLIDATION_STATUSES, {value: this.pevItem.history.mark})?.translation
    },


    getActualProductProform() {
      if(!this.pevItem) return false
      if(!this.item.history?.deleted_proform_ids?.product_data) return this.pevItem?.history?.productProformEntities

      let actualProform = []
      let deletedIds = this.item.history?.deleted_proform_ids?.product_data

      this.pevItem?.history?.productProformEntities.map(prevProductItem => {
        let foundDeleted = false
        deletedIds.map(deletedId => {
          if(parseInt(prevProductItem.id) === parseInt(deletedId)) foundDeleted = true
        })
        if(!foundDeleted) actualProform.push(prevProductItem)
      })
      if(actualProform.length > 0) return actualProform

      return false
    },

    getDeletedProductProform() {
      if(!this.pevItem || !this.item.history?.deleted_proform_ids?.product_data) return false

      let deletedProform = []
      let deletedIds = this.item.history?.deleted_proform_ids?.product_data

      deletedIds.map(deletedId => {
        let product = this._.find(this.pevItem?.history?.productProformEntities, {id: parseInt(deletedId)})
        if(product) deletedProform.push(product)
      })

      if(deletedProform.length > 0) return deletedProform

      return false
    },

    getActualOrderProform() {
      if(!this.pevItem) return false
      if(!this.item.history?.deleted_proform_ids?.proform_data) return this.pevItem?.history?.proform_entities

      let actualProform = []
      let deletedIds = this.item.history?.deleted_proform_ids?.proform_data

      this.pevItem?.history?.proform_entities.map(prevProformItem => {
        let foundDeleted = false
        deletedIds.map(deletedId => {
          if(parseInt(prevProformItem.id) === parseInt(deletedId)) foundDeleted = true
        })
        if(!foundDeleted) actualProform.push(prevProformItem)
      })
      if(actualProform.length > 0) return actualProform

      return false
    },

    getDeletedOrderProform() {
      if(!this.pevItem || !this.item.history?.deleted_proform_ids?.proform_data) return false

      let deletedProform = []
      let deletedIds = this.item.history?.deleted_proform_ids?.proform_data

      deletedIds.map(deletedId => {
        let product = this._.find(this.pevItem?.history?.proform_entities, {id: parseInt(deletedId)})
        if(product) deletedProform.push(product)
        // deletedProform.push(this._.find(this.pevItem?.history?.proform_entities, {id: parseInt(deletedId)}))
      })

      if(deletedProform.length > 0) return deletedProform

      return false
    },

  },

  mounted() {
  },

  methods: {

    getProductProformCells(proform) {
      let product = this._.find(this.item.history.productEntities, {product_id: proform.product_id, item_price: proform.item_price, item_quantity: proform.item_quantity})
      if(!product || !product.cells) return []
      return product.cells
    },
    getPrevProductProformCells(index) {
      if(!this.pevItem || !this._.has(this.getActualProductProform, index)) return []
      let product = this._.find(this.pevItem.history.productEntities, {product_id: this.getActualProductProform[index].product_id,
        item_price: this.getActualProductProform[index].item_price, item_quantity: this.getActualProductProform[index].item_quantity})
      if(!product || !product.cells) return []
      return product.cells
    },
    getPrevDeletedProductProformCells(proform) {
      let product = this._.find(this.pevItem.history.productEntities, {product_id: proform.product_id, item_price: proform.item_price, item_quantity: proform.item_quantity})
      if(!product || !product.cells) return []
      return product.cells
    },


    getProductProform(productEntity) {
      let proform = this._.clone(productEntity.proform_entities)
      proform.hs_code = productEntity.hs_code
      proform.item_price = productEntity.item_price
      proform.item_quantity = productEntity.item_quantity
      proform.handmade = productEntity.handmade
      return proform
    },

    // getProductProformPrev(productEntity) {
    //   let proform = this._.clone(productEntity.proform_entities)
    //   proform.hs_code = productEntity.hs_code
    //   proform.item_price = productEntity.item_price
    //   proform.item_quantity = productEntity.item_quantity
    //   return proform
    // },
  },

}
</script>

<style scoped>

</style>