import {API} from "../../axios/axiosMainUrl";

export const accountBanks = {
  state: {
    accountBanks: [],
    accountBanksItem: {},
    accountBanksCommonList: [],
    accountBanksBtn: false,
    accountBanksLoading: true,
    nextAccountBanksPage: false,
    accountBanksForPage: 10000,
    accountBanksFilter: '',
    accountBankType: [],
  },

  mutations: {
    setAccountBanksFilter: (state, payload) => {
      state.accountBanksFilter = payload
    },

    setAccountBanks: (state, payload) => {
      if(state.nextAccountBanksPage) {
        state.accountBanks = state.accountBanks.concat(payload.accountBanks)
      } else {
        state.accountBanks = payload.accountBanks
      }
    },

    setAccountBanksCommonList: (state, payload) => {
      state.accountBanksCommonList = payload.accountBanksCommonList
    },

    setNextAccountBanksPage: (state, payload) => {
      state.nextAccountBanksPage = payload
    },

    setAccountBanksItem: (state, payload) => {
      state.accountBanksItem = payload.accountBanksItem
    },

    setAccountBankType: (state, payload) => {
      state.accountBankType = payload.accountBankType
    },

    setAccountBanksLoadingStart: (state) => {
      state.accountBanksLoading = true
    },

    setAccountBanksLoadingEnd: (state) => {
      state.accountBanksLoading = false
    },

    changeAccountBanksBtn: (state) => {
      state.accountBanksBtn = true
    },
    successAccountBanksBtn: (state) => {
      state.accountBanksBtn = false
    },
  },

  getters: {
    getAccountBanksFilter: state => {
      return state.accountBanksFilter
    },

    getAccountBanksForPage: state => {
      return state.accountBanksForPage
    },

    getAccountBanks: state => {
      return state.accountBanks
    },

    getAccountBanksLoading: state => {
      return state.accountBanksLoading
    },


    getNextAccountBanksPage: state => {
      return state.nextAccountBanksPage
    },

    getAccountBanksItem: state => {
      return state.accountBanksItem
    },

    getAccountBanksCommonList: state => {
      return state.accountBanksCommonList
    },

    getAccountBanksBtn: state => {
      return state.accountBanksBtn
    },

    getAccountBankType: state => {
      return state.accountBankType
    },
  },

  actions: {

    async fetchAccountBanks({commit, getters}, filter = '') {
      if(!getters.getNextAccountBanksPage)
        commit('setAccountBanksLoadingStart')
      try {
        return await API.get(`/account-bank${filter}`).then((response) =>{
          commit('setAccountBanks',{accountBanks: response.data.data.data})
          commit('setAccountBanksCommonList',{accountBanksCommonList: response.data.data})
          commit('setAccountBanksLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setAccountBanksLoadingEnd');
        return e;
      }
    },

    async getAccountBanks({commit}, id) {
      commit('setAccountBanksLoadingStart');
      try {
        return await API.get(`/account-bank/${id}`).then((response) =>{
          console.log(response)
          commit('setAccountBanksItem', {accountBanksItem: response.data.data})
          commit('setAccountBanksLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setAccountBanksLoadingEnd');
        return e;
      }
    },
    // async getAccountBanksEdit({commit}, id) {
    //   console.log(666, id);
    //   commit('setAccountBanksLoadingStart');
    //   try {
    //     return await API.get(`/account-bank/${id}/edit`).then((response) =>{
    //       console.log(response)
    //       commit('setAccountBanksItem', {accountBanksItem: response.data.data})
    //       commit('setAccountBanksLoadingEnd');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('setAccountBanksLoadingEnd');
    //     return e;
    //   }
    // },

    async createAccountBanks({commit}, data) {
      commit('changeAccountBanksBtn');
      try {
        return await API.post(`/account-bank`, data).then((response) =>{
          commit('successAccountBanksBtn');
          return response;
        });
      } catch (e) {
        commit('successAccountBanksBtn');
        return e;
      }
    },

    async updateAccountBanks({commit}, {id, data}) {
      commit('changeAccountBanksBtn');
      try {
        return await API.put(`/account-bank/${id}`, data).then((response) =>{
          commit('successAccountBanksBtn');
          return response;
        });
      } catch (e) {
        commit('successAccountBanksBtn');
        return e;
      }
    },

    async deleteAccountBanks({commit}, id) {
      try {
        return await API.delete(`/account-bank/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successAccountBanksBtn');
        return e;
      }
    },

    async fetchAccountBankType({commit}) {
      try {
        return await API.get(`/account-bank-type`).then((response) =>{
          commit('setAccountBankType',{accountBankType: response.data.data.data})
          return response;
        });
      } catch (e) {
        commit('setAccountBanksLoadingEnd');
        return e;
      }
    },

  }
}
