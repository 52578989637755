import {API} from "../../axios/axiosMainUrl";

const prefix = '/products/upc-code'

export const upcCodes = {
  state: {
    upcCodes: [],
    upcCodesItem: {},
    upcCodesCommonList: [],
    upcCodesBtn: false,
    upcCodesLoading: true,
    nextUPCCodesPage: false,
    upcCodesForPage: 25,
    upcCodesFilter: '',
  },

  mutations: {
    setUPCCodesFilter: (state, payload) => {
      state.upcCodesFilter = payload
    },

    setUPCCodes: (state, payload) => {
      if(state.nextUPCCodesPage) {
        state.upcCodes = state.upcCodes.concat(payload.upcCodes)
      } else {
        state.upcCodes = payload.upcCodes
      }
    },

    setUPCCodesCommonList: (state, payload) => {
      state.upcCodesCommonList = payload.upcCodesCommonList
    },

    setNextUPCCodesPage: (state, payload) => {
      state.nextUPCCodesPage = payload
    },

    setUPCCodesItem: (state, payload) => {
      state.upcCodesItem = payload.upcCodesItem
    },

    setUPCCodesLoadingStart: (state) => {
      state.upcCodesLoading = true
    },

    setUPCCodesLoadingEnd: (state) => {
      state.upcCodesLoading = false
    },

    changeUPCCodesBtn: (state) => {
      state.upcCodesBtn = true
    },
    successUPCCodesBtn: (state) => {
      state.upcCodesBtn = false
    },
  },

  getters: {
    getUPCCodesFilter: state => {
      return state.upcCodesFilter
    },

    getUPCCodesForPage: state => {
      return state.upcCodesForPage
    },

    getUPCCodes: state => {
      return state.upcCodes
    },

    getUPCCodesLoading: state => {
      return state.upcCodesLoading
    },


    getNextUPCCodesPage: state => {
      return state.nextUPCCodesPage
    },

    getUPCCodesItem: state => {
      return state.upcCodesItem
    },

    getUPCCodesCommonList: state => {
      return state.upcCodesCommonList
    },

    getUPCCodesBtn: state => {
      return state.upcCodesBtn
    },
  },

  actions: {

    async fetchUPCCodes({commit, getters}, filter = '') {
      if(!getters.getNextUPCCodesPage)
        commit('setUPCCodesLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setUPCCodes',{upcCodes: response.data.data.data})
          commit('setUPCCodesCommonList',{upcCodesCommonList: response.data.data})
          commit('setUPCCodesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setUPCCodesLoadingEnd');
        return e;
      }
    },

    async getUPCCodes({commit}, id) {
      commit('setUPCCodesLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setUPCCodesItem', {upcCodesItem: response.data.data})
          commit('setUPCCodesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setUPCCodesLoadingEnd');
        return e;
      }
    },

    async getCountFreeUPCCodes({commit}) {
      try {
        return await API.get(`${prefix}/count-free-codes`).then((response) =>{
          commit('setUPCCodesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setUPCCodesLoadingEnd');
        return e;
      }
    },

    async createUPCCodes({commit}, data) {
      commit('changeUPCCodesBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successUPCCodesBtn');
          return response;
        });
      } catch (e) {
        commit('successUPCCodesBtn');
        return e;
      }
    },

    async updateUPCCodes({commit}, {id, data}) {
      commit('changeUPCCodesBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successUPCCodesBtn');
          return response;
        });
      } catch (e) {
        commit('successUPCCodesBtn');
        return e;
      }
    },

    async deleteUPCCodes({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successUPCCodesBtn');
        return e;
      }
    },


    async uploadUPSCodesFile({commit}, data) {
      commit('changeUPCCodesBtn');
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${prefix}/import`, data).then((response) =>{
          commit('successUPCCodesBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successUPCCodesBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

  }
}
