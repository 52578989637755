import {API} from "../../axios/axiosMainUrl";

const prefix = '/consolidations/consolidation-union-document-form'

export const iE559Report = {
  state: {
    iE559Report: [],
    iE559ReportItem: {},
    iE559ReportCommonList: [],
    iE559ReportBtn: false,
    iE559ReportLoading: true,
    nextIE559ReportPage: false,
    iE559ReportForPage: 25,
    iE559ReportFilter: '',

    iE559ReportSocket: false,
    iE559ReportSocketChannel: 'private-consolidation-form-document-generate-channel.1',
    iE559ReportSocketEvent: 'form-document-status.updated',
  },

  mutations: {
    setIE559ReportFilter: (state, payload) => {
      state.iE559ReportFilter = payload
    },

    setIE559Report: (state, payload) => {
      if(state.nextIE559ReportPage) {
        state.iE559Report = state.iE559Report.concat(payload.iE559Report)
      } else {
        state.iE559Report = payload.iE559Report
      }
    },

    setIE559ReportCommonList: (state, payload) => {
      state.iE559ReportCommonList = payload.iE559ReportCommonList
    },

    setNextIE559ReportPage: (state, payload) => {
      state.nextIE559ReportPage = payload
    },

    setIE559ReportItem: (state, payload) => {
      state.iE559ReportItem = payload.iE559ReportItem
    },

    setIE559ReportLoadingStart: (state) => {
      state.iE559ReportLoading = true
    },

    setIE559ReportLoadingEnd: (state) => {
      state.iE559ReportLoading = false
    },

    changeIE559ReportBtn: (state) => {
      state.iE559ReportBtn = true
    },
    successIE559ReportBtn: (state) => {
      state.iE559ReportBtn = false
    },

    setIE559ReportSocket: (state, payload) => {
      state.iE559ReportSocket = payload
    },
  },

  getters: {
    getIE559ReportFilter: state => {
      return state.iE559ReportFilter
    },

    getIE559ReportForPage: state => {
      return state.iE559ReportForPage
    },

    getIE559Report: state => {
      return state.iE559Report
    },

    getIE559ReportLoading: state => {
      return state.iE559ReportLoading
    },


    getNextIE559ReportPage: state => {
      return state.nextIE559ReportPage
    },

    getIE559ReportItem: state => {
      return state.iE559ReportItem
    },

    getIE559ReportCommonList: state => {
      return state.iE559ReportCommonList
    },

    getIE559ReportBtn: state => {
      return state.iE559ReportBtn
    },

    getIE559ReportSocket: state => {
      return state.iE559ReportSocket
    },

    getIE559ReportSocketChannel: state => {
      return state.iE559ReportSocketChannel
    },

    getIE559ReportSocketEvent: state => {
      return state.iE559ReportSocketEvent
    },
  },

  actions: {

    async fetchIE559Report({commit, getters}, filter = '') {
      if(!getters.getNextIE559ReportPage)
        commit('setIE559ReportLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setIE559Report',{iE559Report: response.data.data.data})
          commit('setIE559ReportCommonList',{iE559ReportCommonList: {...response.data.data.meta, ...{
                consolidation_form_user_data: response.data.data.consolidation_form_user_data,
                data: response.data.data.data,
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev
              }}})
          commit('setIE559ReportLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setIE559ReportLoadingEnd');
        return e;
      }
    },

    async getIE559Report({commit}, id) {
      commit('setIE559ReportLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setIE559ReportItem', {iE559ReportItem: response.data.data})
          commit('setIE559ReportLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setIE559ReportLoadingEnd');
        return e;
      }
    },

    async generateLastForm({commit}, date) {
      // commit('setIE559ReportLoadingStart');
      try {
        return await API.get(`${prefix}/generate-last-form${date ? '/' + date : ''}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setIE559ReportLoadingEnd');
        return e;
      }
    },

    async createIE559Report({commit}, data) {
      commit('changeIE559ReportBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successIE559ReportBtn');
          return response;
        });
      } catch (e) {
        commit('successIE559ReportBtn');
        return e;
      }
    },

    async updateIE559Report({commit}, {id, data}) {
      commit('changeIE559ReportBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successIE559ReportBtn');
          return response;
        });
      } catch (e) {
        commit('successIE559ReportBtn');
        return e;
      }
    },

    async deleteIE559Report({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successIE559ReportBtn');
        return e;
      }
    },

  }
}
