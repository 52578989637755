import {API} from "../../axios/axiosMainUrl";

const prefix = 'expenses'

export const expenses = {
  state: {
    expenses: [],
    expensesItem: {},
    expensesCommonList: [],
    expensesBtn: false,
    expensesLoading: true,
    nextExpensesPage: false,
    expensesForPage: 25,
    expensesFilter: '',
  },

  mutations: {
    setExpensesFilter: (state, payload) => {
      state.expensesFilter = payload
    },

    setExpenses: (state, payload) => {
      if(state.nextExpensesPage) {
        state.expenses = state.expenses.concat(payload.expenses)
      } else {
        state.expenses = payload.expenses
      }
    },

    setExpensesCommonList: (state, payload) => {
      state.expensesCommonList = payload.expensesCommonList
    },

    setNextExpensesPage: (state, payload) => {
      state.nextExpensesPage = payload
    },

    setExpensesItem: (state, payload) => {
      state.expensesItem = payload.expensesItem
    },

    setExpensesLoadingStart: (state) => {
      state.expensesLoading = true
    },

    setExpensesLoadingEnd: (state) => {
      state.expensesLoading = false
    },

    changeExpensesBtn: (state) => {
      state.expensesBtn = true
    },
    successExpensesBtn: (state) => {
      state.expensesBtn = false
    },
  },

  getters: {
    getExpensesFilter: state => {
      return state.expensesFilter
    },

    getExpensesForPage: state => {
      return state.expensesForPage
    },

    getExpenses: state => {
      return state.expenses
    },

    getExpensesLoading: state => {
      return state.expensesLoading
    },


    getNextExpensesPage: state => {
      return state.nextExpensesPage
    },

    getExpensesItem: state => {
      return state.expensesItem
    },

    getExpensesCommonList: state => {
      return state.expensesCommonList
    },

    getExpensesBtn: state => {
      return state.expensesBtn
    },
  },

  actions: {

    async fetchExpenses({commit, getters}, filter = '') {
      if(!getters.getNextExpensesPage)
        commit('setExpensesLoadingStart')
      try {
        return await API.get(`/${prefix}${filter}`).then((response) =>{
          commit('setExpenses',{expenses: response.data.data.data})
          commit('setExpensesCommonList',{expensesCommonList: response.data.data})
          commit('setExpensesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setExpensesLoadingEnd');
        return e;
      }
    },

    async getExpenses({commit}, id) {
      try {
        return await API.get(`/${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setExpensesItem', {expensesItem: response.data.data})
          commit('setExpensesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setExpensesLoadingEnd');
        return e;
      }
    },

    async getExpensesCreate({commit}) {
      try {
        return await API.get(`/${prefix}/create`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setExpensesLoadingEnd');
        return e;
      }
    },

    async createExpenses({commit}, data) {
      commit('changeExpensesBtn');
      try {
        return await API.post(`/${prefix}`, data).then((response) =>{
          commit('successExpensesBtn');
          return response;
        });
      } catch (e) {
        commit('successExpensesBtn');
        return e;
      }
    },

    async updateExpenses({commit}, {id, data}) {
      commit('changeExpensesBtn');
      try {
        return await API.put(`/${prefix}/${id}`, data).then((response) =>{
          commit('successExpensesBtn');
          return response;
        });
      } catch (e) {
        commit('successExpensesBtn');
        return e;
      }
    },

    async uploadFileExpenses({commit}, data) {
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`/${prefix}/upload-file`, data).then((response) =>{
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successExpensesBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async deleteExpenses({commit}, id) {
      try {
        return await API.delete(`/${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successExpensesBtn');
        return e;
      }
    },

    async getExportExpenses({commit}, {filter , type}) {
      commit('setExportBtnLoadingStart')
      commit('setLoadingEnd')
      try {
        return await API.get(`/${prefix}/export/${type}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

    async postImportExpenses({commit}, data) {
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`/${prefix}/import`, data).then((response) =>{
          commit('successProductsBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successProductsBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async getImportExampleXLSXExpenses({commit}) {
      commit('setLoadingEnd')
      try {
        return await API.get(`/${prefix}/import-example-xlsx`, { responseType: 'blob' }).then((response) =>{
          return response
        })
      } catch (e) {
        return e;
      }
    },

  }
}
