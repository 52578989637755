import {API} from "../../axios/axiosMainUrl";

const prefix = '/return-parcels/requests'

export const requestReturnGoods = {
  state: {
    requestReturnGoods: [],
    requestReturnGoodsItem: {},
    requestReturnGoodsCommonList: [],
    requestReturnGoodsBtn: false,
    requestReturnGoodsLoading: true,
    nextRequestReturnGoodsPage: false,
    requestReturnGoodsForPage: 25,
    requestReturnGoodsFilter: '',
  },

  mutations: {
    setRequestReturnGoodsFilter: (state, payload) => {
      state.requestReturnGoodsFilter = payload
    },

    setRequestReturnGoods: (state, payload) => {
      if(state.nextRequestReturnGoodsPage) {
        state.requestReturnGoods = state.requestReturnGoods.concat(payload.requestReturnGoods)
      } else {
        state.requestReturnGoods = payload.requestReturnGoods
      }
    },

    setRequestReturnGoodsCommonList: (state, payload) => {
      state.requestReturnGoodsCommonList = payload.requestReturnGoodsCommonList
    },

    setNextRequestReturnGoodsPage: (state, payload) => {
      state.nextRequestReturnGoodsPage = payload
    },

    setRequestReturnGoodsItem: (state, payload) => {
      state.requestReturnGoodsItem = payload.requestReturnGoodsItem
    },

    setRequestReturnGoodsLoadingStart: (state) => {
      state.requestReturnGoodsLoading = true
    },

    setRequestReturnGoodsLoadingEnd: (state) => {
      state.requestReturnGoodsLoading = false
    },

    changeRequestReturnGoodsBtn: (state) => {
      state.requestReturnGoodsBtn = true
    },
    successRequestReturnGoodsBtn: (state) => {
      state.requestReturnGoodsBtn = false
    },
  },

  getters: {
    getRequestReturnGoodsFilter: state => {
      return state.requestReturnGoodsFilter
    },

    getRequestReturnGoodsForPage: state => {
      return state.requestReturnGoodsForPage
    },

    getRequestReturnGoods: state => {
      return state.requestReturnGoods
    },

    getRequestReturnGoodsLoading: state => {
      return state.requestReturnGoodsLoading
    },


    getNextRequestReturnGoodsPage: state => {
      return state.nextRequestReturnGoodsPage
    },

    getRequestReturnGoodsItem: state => {
      return state.requestReturnGoodsItem
    },

    getRequestReturnGoodsCommonList: state => {
      return state.requestReturnGoodsCommonList
    },

    getRequestReturnGoodsBtn: state => {
      return state.requestReturnGoodsBtn
    },
  },

  actions: {

    async fetchRequestReturnGoods({commit, getters}, filter = '') {
      if(!getters.getNextRequestReturnGoodsPage)
        commit('setRequestReturnGoodsLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setRequestReturnGoods',{requestReturnGoods: response.data.data.data})
          commit('setRequestReturnGoodsCommonList',{requestReturnGoodsCommonList: response.data.data})
          commit('setRequestReturnGoodsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setRequestReturnGoodsLoadingEnd');
        return e;
      }
    },

    async getRequestReturnGoods({commit}, id) {
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setRequestReturnGoodsItem', {requestReturnGoodsItem: response.data.data})
          return response;
        });
      } catch (e) {
        commit('setRequestReturnGoodsLoadingEnd');
        return e;
      }
    },

    async createRequestReturnGoods({commit}, data) {
      commit('changeRequestReturnGoodsBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successRequestReturnGoodsBtn');
          return response;
        });
      } catch (e) {
        commit('successRequestReturnGoodsBtn');
        return e;
      }
    },

    async updateRequestReturnGoods({commit}, {id, data}) {
      commit('changeRequestReturnGoodsBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successRequestReturnGoodsBtn');
          return response;
        });
      } catch (e) {
        commit('successRequestReturnGoodsBtn');
        return e;
      }
    },

    async deleteRequestReturnGoods({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successRequestReturnGoodsBtn');
        return e;
      }
    },
    
    async addRequestReturnGoodsFile({commit}, data) {
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${prefix}/upload-file`, data).then((response) =>{
          commit('successPayPalCasesBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successPayPalCasesBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

  }
}
