import {PayPalCases} from "@/components/modules/PayPalCasesModule/models/PayPalCases";

export const payPalCasesMixin = {

  methods: {

    changeUserMessageImg(files, maxSizeError) {
      this.PCPopup.data.Files.setFiles(files)

      this.PCPopup.data.Files.setFilesMaxSizeError(maxSizeError)
    },

    sendUserMessage(inEditCard = false){

      if(!this.PCPopup.messageValidate() || (!this.PCPopup.data.Files.firstValidation() && this.PCPopup.data.Files.getFiles().length > 0)) return

      let data = this.PCPopup.getAddMessagePayPalCases()
      this.$store.dispatch(
        'addMessagePayPalCases',
        {id: this.PCPopup.getId(), data: data}
      ).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            this.PCPopup.setMessage('')

            if (this.PCPopup.data.Files.getFiles().length > 0) {
              this.addFiles(this.PCPopup.getId(), true, true, true)
            }
            this.openNotify('success', 'common_notificationRecordChanged')
            this.$emit('close')

            if(inEditCard) {
              this.$emit('reload')
              this.PCPopup = new PayPalCases({files: 1})
            }

            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })

    },

    // parseDataForSelectUse({data, name, value}) {
    //   if(Object.keys(data).length === 0) return []
    //
    //   let newArray = []
    //
    //   for (let item of data) {
    //     let newArrayItem = {}
    //     newArrayItem[name] = item["currentTranslate"]["name"]
    //     newArrayItem[value] = item["id"]
    //     newArray.push(newArrayItem)
    //   }
    //
    //   return newArray
    // },

    getFilteredTransactions(value, that, optionName, loading) {
      if(this.transactionFilterTimeoutTime !== undefined) {
        let dateNow = new Date()
        if(dateNow - this.transactionFilterTimeoutTime < 500){
          console.log(dateNow - this.transactionFilterTimeoutTime)
          clearTimeout(this.transactionFilterTimeout)
          createTimeOut()
        }
      } else {
        createTimeOut()
      }

      function createTimeOut() {
        that.transactionFilterTimeoutTime = new Date()
        that.transactionFilterTimeout = setTimeout(() => {
          search()
        },500)
      }

      function search() {
        that.transactionFilterTimeoutTime = undefined

        if(value.length === 0) {
          that[optionName] = []
        }
        if(value.length < 1) {
          loading(false)
          return
        }

        const query = new that.Query();
        let url = '?';
        let myQuery = query
            .for('posts')
        myQuery.where('PaymentTransactionId', value)
        myQuery.where('PaymentTransactionStatus', 'success')
        myQuery.where('excludeOpenedComplaint', '1')
        myQuery.appends('freeAmount')

        url = url + myQuery.limit(100000).page(1).url().split('?')[1]
        that.$store.dispatch('fetchPayPalCasesTrnsList', url).then(response => {
          that[optionName] = response.data.data.data
          loading(false)
        })
      }
    },

    async addFiles(id, edit, partialEdit = false, popupType = false) {
      if(popupType) {
        if(this.PCPopup.data.Files.getFiles().length > 0){
          await this.addCaseFiles(id, popupType)
        }
      } else {
        if(this.PC.data.Files.getFiles().length > 0){
          await this.addCaseFiles(id, popupType)
        }
      }

      if (edit && !partialEdit) {
        this.openNotify('success', 'common_notificationRecordChanged')
      }
      else if (!partialEdit) {
        this.openNotify('success', 'common_notificationRecordCreated')
      }

      if (!partialEdit)
        this.$router.push(this.$store.getters.GET_PATHS.financePayPalCases)
    },

    addCaseFiles(id, popupType) {

      let data = popupType ? this.PCPopup.prepareFilesData(id) : this.PC.prepareFilesData(id)
      if(data.length === 0) return Promise.resolve()

      data.map(file => {
        return this.$store.dispatch('addPayPalCasesFile', file).then(response => {
          this.responseFilesProcessing(response)
          return response
        })
      })
    },

    getFileFromServer(file, type = null){
      if(!file) return

      let localItem = file

      return this.$store.dispatch('getFileFromServer', file.id)
          .then((response) => {
            console.log(response);
            let fileBase64 = response
            switch (type) {
              case 'image':
                localItem.typeBase64 = `image`
                localItem.base64 = fileBase64[file.id]
                break
              default:
                localItem.base64 = fileBase64[file.id]
                break
            }

            return localItem
            // let fileBase64 = response
            // console.log(response);
            // Object.keys(response).map(item => {
            //   downloadFiles.push({
            //     id: item,
            //     type: file.mime_type,
            //     uuid: file.uuid,
            //     original_filename: file.original_filename,
            //     base64: fileBase64[item],
            //   })
            // })
          })

    },

    responseFilesProcessing(response){
      if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')
      switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
        case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
          break
        }
          /**
           * Validation Error
           */
        case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
          let errors = response.response.data.errors;
          this.notifyErrorHelper(errors)
          break
        }
          /**
           * Undefined Error
           */
        default: {
          this.openNotify('error', 'common_notificationUndefinedError')
        }
      }
    },

    sendMessage(edit = false, noNotification = false) {
      if (!this.PC.messageValidate()) return

      return this.$store.dispatch(
          'addMessagePayPalCases',
          {id: this.PC.getId(), data: {message: this.PC.getMessage()}}
      ).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            if(edit && !noNotification){
              this.openNotify('success', 'common_notificationRecordChanged')
              this.PC.addComplaintMessages(this._.last(this.getRespData(response)?.dialogs))
              this.PC.setMessage('')
            } else if (!noNotification) {
              this.openNotify('success', 'common_notificationRecordCreated')
            }

            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },

    save(edit = false) {

      if((
          !this.PC.firstValidationUser()
          // || !this.PC.validateFreeAmount()
          || !this.PC.validateCloseDate()
          || (!this.PC.data.Files.firstValidation() && this.PC.data.Files.getFiles().length > 0)
          )
          && this.isAdmin) return

      let data = this.PC.prepareSave(edit)

      let saveType = 'createPayPalCases'

      if (edit) {
        data = {
          id: this.PC.getId(),
          data: data
        }
        saveType = 'updatePayPalCases'
      }

      this.$store.dispatch(saveType, data).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')
        console.log(data);

        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            if (this.PC.data.Files.getFiles().length > 0) {
              if (edit) {
                this.addFiles(data.id, edit)
              }
              else {
                this.addFiles(this.getRespData(response)?.complaint?.id, edit)
              }


            }

            if(this.PC.getMessage().length > 0){
              if (!edit) {
                this.PC.setId(this.getRespData(response)?.complaint?.id)
              }
              this.sendMessage(edit, this.PC.data.Files.getFiles().length > 0).then(() => {
                this.$router.push(this.$store.getters.GET_PATHS.financePayPalCases)
              })

            }

            if (this.PC.data.Files.getFiles().length > 0 || this.PC.getMessage().length > 0) {
              return
            }

            if (edit) {
              this.openNotify('success', 'common_notificationRecordChanged')
            } else {
              this.openNotify('success', 'common_notificationRecordCreated')
            }
            console.log(response);

            this.$router.push(this.$store.getters.GET_PATHS.financePayPalCases)
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })

      console.log(data);
      console.log(this.PC);
    }
  }

}
