import {API} from "../../axios/axiosMainUrl";

const prefix = '/delivery/insurances'

export const insuranceSkladUsa = {
  state: {
    insuranceSkladUsa: [],
    insuranceSkladUsaItem: {},
    insuranceSkladUsaByUserId: [],
    insuranceSkladUsaCommonList: [],
    insuranceSkladUsaBtn: false,
    insuranceSkladUsaLoading: true,
    nextInsuranceSkladUsaPage: false,
    insuranceSkladUsaForPage: 25,
    insuranceSkladUsaFilter: '',
  },

  mutations: {
    setInsuranceSkladUsaFilter: (state, payload) => {
      state.insuranceSkladUsaFilter = payload
    },

    setInsuranceSkladUsa: (state, payload) => {
      if(state.nextInsuranceSkladUsaPage) {
        state.insuranceSkladUsa = state.insuranceSkladUsa.concat(payload.insuranceSkladUsa)
      } else {
        state.insuranceSkladUsa = payload.insuranceSkladUsa
      }
    },

    setInsuranceSkladUsaCommonList: (state, payload) => {
      state.insuranceSkladUsaCommonList = payload.insuranceSkladUsaCommonList
    },

    setNextInsuranceSkladUsaPage: (state, payload) => {
      state.nextInsuranceSkladUsaPage = payload
    },

    setInsuranceSkladUsaItem: (state, payload) => {
      state.insuranceSkladUsaItem = payload.insuranceSkladUsaItem
    },

    setInsuranceSkladUsaByUserId: (state, payload) => {
      state.insuranceSkladUsaByUserId = payload.insuranceSkladUsaByUserId
    },

    setInsuranceSkladUsaLoadingStart: (state) => {
      state.insuranceSkladUsaLoading = true
    },

    setInsuranceSkladUsaLoadingEnd: (state) => {
      state.insuranceSkladUsaLoading = false
    },

    changeInsuranceSkladUsaBtn: (state) => {
      state.insuranceSkladUsaBtn = true
    },
    successInsuranceSkladUsaBtn: (state) => {
      state.insuranceSkladUsaBtn = false
    },
    removeInsuranceSkladUsaItem: (state, id) => {
      let itemIndex = state.insuranceSkladUsa.findIndex(item => item.id === id)
      state.insuranceSkladUsa.splice(itemIndex, 1)
    }
  },

  getters: {
    getInsuranceSkladUsaFilter: state => {
      return state.insuranceSkladUsaFilter
    },

    getInsuranceSkladUsaForPage: state => {
      return state.insuranceSkladUsaForPage
    },

    getInsuranceSkladUsa: state => {
      return state.insuranceSkladUsa
    },

    getInsuranceSkladUsaLoading: state => {
      return state.insuranceSkladUsaLoading
    },


    getNextInsuranceSkladUsaPage: state => {
      return state.nextInsuranceSkladUsaPage
    },

    getInsuranceSkladUsaItem: state => {
      return state.insuranceSkladUsaItem
    },

    getInsuranceSkladUsaByUserId: state => {
      return state.insuranceSkladUsaByUserId
    },

    getInsuranceSkladUsaCommonList: state => {
      return state.insuranceSkladUsaCommonList
    },

    getInsuranceSkladUsaBtn: state => {
      return state.insuranceSkladUsaBtn
    },
  },

  actions: {

    async fetchInsuranceSkladUsa({commit, getters}, filter = '') {
      if(!getters.getNextInsuranceSkladUsaPage)
        commit('setInsuranceSkladUsaLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setInsuranceSkladUsa',{insuranceSkladUsa: response.data.data.data})
          commit('setInsuranceSkladUsaCommonList',{insuranceSkladUsaCommonList: response.data.data})
          commit('setInsuranceSkladUsaLoadingEnd');
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('setInsuranceSkladUsaLoadingEnd');
        return e;
      }
    },


    async getInsuranceSkladUsa({commit}, id) {
      commit('setInsuranceSkladUsaLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          //console.log(response)
          commit('setInsuranceSkladUsaItem', {insuranceSkladUsaItem: response.data.data})
          commit('setInsuranceSkladUsaLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setInsuranceSkladUsaLoadingEnd');
        return e;
      }
    },

    async getInsuranceSkladUsaCreate({commit},) {
      try {
        return await API.get(`${prefix}/create`).then((response) => {
          return response;
        });
      } catch (e) {
        commit('setInsuranceSkladUsaLoadingEnd');
        return e;
      }
    },

    async createInsuranceSkladUsa({commit}, data) {
      commit('changeInsuranceSkladUsaBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successInsuranceSkladUsaBtn');
          return response;
        });
      } catch (e) {
        commit('successInsuranceSkladUsaBtn');
        return e;
      }
    },

    async addMessageInsuranceSkladUsa({commit}, {id: id, data: data}) {
      commit('changeInsuranceSkladUsaBtn');
      try {
        return await API.post(`${prefix}/${id}/add-dialog-message`, data).then((response) =>{
          commit('successInsuranceSkladUsaBtn');
          return response;
        });
      } catch (e) {
        commit('successInsuranceSkladUsaBtn');
        return e;
      }
    },

    async updateInsuranceSkladUsa({commit}, {id, data}) {
      commit('changeInsuranceSkladUsaBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successInsuranceSkladUsaBtn');
          return response;
        });
      } catch (e) {
        commit('successInsuranceSkladUsaBtn');
        return e;
      }
    },

    async partialUpdateInsuranceSkladUsa({commit}, {id, data}) {
      commit('changeInsuranceSkladUsaBtn');
      try {
        return await API.patch(`${prefix}/${id}/partial`, data).then((response) =>{
          commit('successInsuranceSkladUsaBtn');
          return response;
        });
      } catch (e) {
        commit('successInsuranceSkladUsaBtn');
        return e;
      }
    },

    async deleteInsuranceSkladUsa({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          commit('removeInsuranceSkladUsaItem', id)
          return response;
        });
      } catch (e) {
        commit('successInsuranceSkladUsaBtn');
        return e;
      }
    },

    async addInsuranceSkladUsaFile({commit}, data) {
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${prefix}/upload-file`, data).then((response) =>{
          commit('successInsuranceSkladUsaBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successInsuranceSkladUsaBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async getImportInsuranceSkladUsa({commit}, data) {
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${prefix}/import`, data).then((response) =>{
          commit('successInsuranceSkladUsaBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successInsuranceSkladUsaBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },
  }
}
