
import {routerPaths} from '../../../router/routerPaths'

const Calculator = () => import(/* webpackChunkName: "group-calculator-engraving" */ './components/Calculator/Calculator.vue')
const moduleKey = 'calculator'

export const CalculatorEngravingRoutes = [
  {
    path: routerPaths.calculatorEngraving,
    component: Calculator,
    meta: {
      breadcrumbs: {
        key: [
          'calculator'
        ],
        items: {
          'calculator': {
            name: 'breadcrumbs_ShippingCalculatorEngraving',
            link: routerPaths.calculatorEngraving,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_ShippingCalculatorEngraving'
    },
  },
]
