import {API} from "../../axios/axiosMainUrl";

const apiPaymentsPrefix = 'api-payments'
const payoneerAPIPrefix = 'balance-output/payoneer'

export const account = {
  state: {
    account: [],
    accountItem: {},
    accountCommonList: [],
    accountBtn: false,
    accountLoading: true,
    nextAccountPage: false,
    accountForPage: 25,
    accountFilter: '',
    accountType: '',
  },

  mutations: {
    setAccountFilter: (state, payload) => {
      state.accountFilter = payload
    },

    setAccount: (state, payload) => {
      if(state.nextAccountPage) {
        state.account = state.account.concat(payload.account)
      } else {
        state.account = payload.account
      }
    },

    setAccountCommonList: (state, payload) => {
      state.accountCommonList = payload.accountCommonList
    },

    setNextAccountPage: (state, payload) => {
      state.nextAccountPage = payload
    },

    setAccountItem: (state, payload) => {
      state.accountItem = payload.accountItem
    },

    setAccountType: (state, payload) => {
      state.accountType = payload.accountType
    },

    setAccountLoadingStart: (state) => {
      state.accountLoading = true
    },

    setAccountLoadingEnd: (state) => {
      state.accountLoading = false
    },

    changeAccountBtn: (state) => {
      state.accountBtn = true
    },
    successAccountBtn: (state) => {
      state.accountBtn = false
    },
  },

  getters: {
    getAccountFilter: state => {
      return state.accountFilter
    },

    getAccountForPage: state => {
      return state.accountForPage
    },

    getAccount: state => {
      return state.account
    },

    getAccountLoading: state => {
      return state.accountLoading
    },


    getNextAccountPage: state => {
      return state.nextAccountPage
    },

    getAccountItem: state => {
      return state.accountItem
    },

    getAccountType: state => {
      return state.accountType
    },

    getAccountCommonList: state => {
      return state.accountCommonList
    },

    getAccountBtn: state => {
      return state.accountBtn
    },
  },

  actions: {

    async fetchAccount({commit, getters}, filter = '') {
      if(!getters.getNextAccountPage)
        commit('setAccountLoadingStart')
      try {
        return await API.get(`/account/public-list${filter}`).then((response) =>{
          commit('setAccount',{account: response.data.data.data})
          commit('setAccountCommonList',{accountCommonList: response.data.data})
          commit('setAccountLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setAccountLoadingEnd');
        return e;
      }
    },

    async fetchAccountWithPermissions({commit, getters}, filter = '') {
      if(!getters.getNextAccountPage)
        commit('setAccountLoadingStart')
      try {
        return await API.get(`/account${filter}`).then((response) =>{
          commit('setAccount',{account: response.data.data.data})
          commit('setAccountCommonList',{accountCommonList: response.data.data})
          commit('setAccountLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setAccountLoadingEnd');
        return e;
      }
    },

    async fetchAccountType({commit, getters}, filter = '') {
      if(!getters.getNextAccountPage)
        commit('setAccountLoadingStart')
      try {
        return await API.get(`/account-type${filter}`).then((response) =>{
          commit('setAccountType',{account: response.data.data.data})
          commit('setAccountLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setAccountLoadingEnd');
        return e;
      }
    },

    async getAccount({commit}, id) {
      commit('setAccountLoadingStart');
      try {
        return await API.get(`/account/${id}`).then((response) =>{
          commit('setAccountItem', {accountItem: response.data.data})
          commit('setAccountLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setAccountLoadingEnd');
        return e;
      }
    },

    async sendAccountToArchive({commit}, id) {
      commit('setAccountLoadingStart');
      try {
        return await API.patch(`/account/${id}/send-to-archive`).then((response) =>{
          commit('setAccountLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setAccountLoadingEnd');
        return e;
      }
    },
    async restoreAccountFromArchive({commit}, id) {
      commit('setAccountLoadingStart');
      try {
        return await API.patch(`/account/${id}/restore-from-archive`).then((response) =>{
          commit('setAccountLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setAccountLoadingEnd');
        return e;
      }
    },

    async createAccount({commit}, data) {
      commit('changeAccountBtn');
      try {
        return await API.post(`/account`, data).then((response) =>{
          commit('successAccountBtn');
          return response;
        });
      } catch (e) {
        commit('successAccountBtn');
        return e;
      }
    },

    async getWiseAccountCreate({commit},) {
      try {
        return await API.get(`/account/create`).then((response) => {
          return response;
        });
      } catch (e) {
        commit('setAccountLoadingEnd');
        return e;
      }
    },

    async updateAccount({commit}, {id, data}) {
      commit('changeAccountBtn');
      try {
        return await API.put(`/account/${id}`, data).then((response) =>{
          commit('successAccountBtn');
          return response;
        });
      } catch (e) {
        commit('successAccountBtn');
        return e;
      }
    },

    async updateAccountIban({commit}, {id, data}) {
      commit('changeAccountBtn');
      try {
        return await API.post(`/account/${id}/update-account-iban`, data).then((response) =>{
          commit('successAccountBtn');
          return response;
        });
      } catch (e) {
        commit('successAccountBtn');
        return e;
      }
    },

    async deleteAccount({commit}, id) {
      try {
        return await API.delete(`/account/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successAccountBtn');
        return e;
      }
    },


    /**
     * PAYONEER API
     */


    async getPayoneerLoginLink({commit}, data) {
      try {
        return await API.post(`${apiPaymentsPrefix}/${payoneerAPIPrefix}/make-login-link`, data).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successAccountBtn');
        return e;
      }
    },

    async confirmConnectPayoneerAccount({commit}, data) {
      try {
        return await API.post(`${apiPaymentsPrefix}/${payoneerAPIPrefix}/confirm-connect-account`, data).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successAccountBtn');
        return e;
      }
    },

    async addRAMPCards({commit}, data) {
      commit('changeAccountBtn');
      try {
        return await API.post(`${apiPaymentsPrefix}/ramp/request-card`, data).then((response) =>{
          commit('successAccountBtn');
          return response;
        });
      } catch (e) {
        commit('successAccountBtn');
        return e;
      }
    },



  }
}
