import {API} from "../../axios/axiosMainUrl";

const prefix = '/delivery/packing-price'
const prefixPackingType = '/delivery/packing-type'
const prefixGiftPapers = '/gift-papers'
const prefixCustomizationOffer = '/delivery/customization-offer'

/**
 *
 *  This module unify packing price routes and gift papers in one store State
 *  Create, update and tale files has checks (if gift papers)
 *
 */

export const packagingPrice = {
  state: {
    packagingPrice: [],
    packagingPriceItem: {},
    packagingPriceCommonList: [],
    packagingPriceBtn: false,
    packagingPriceLoading: true,
    nextPackagingPricePage: false,
    packagingPriceForPage: 25,
    packagingPriceFilter: '',
  },

  mutations: {
    setPackagingPriceFilter: (state, payload) => {
      state.packagingPriceFilter = payload
    },

    setPackagingPrice: (state, payload) => {
      if(state.nextPackagingPricePage) {
        state.packagingPrice = state.packagingPrice.concat(payload.packagingPrice)
      } else {
        state.packagingPrice = payload.packagingPrice
      }
    },

    setPackagingPriceCommonList: (state, payload) => {
      state.packagingPriceCommonList = payload.packagingPriceCommonList
    },

    setNextPackagingPricePage: (state, payload) => {
      state.nextPackagingPricePage = payload
    },

    setPackagingPriceItem: (state, payload) => {
      state.packagingPriceItem = payload.packagingPriceItem
    },

    setPackagingPriceLoadingStart: (state) => {
      state.packagingPriceLoading = true
    },

    setPackagingPriceLoadingEnd: (state) => {
      state.packagingPriceLoading = false
    },

    changePackagingPriceBtn: (state) => {
      state.packagingPriceBtn = true
    },
    successPackagingPriceBtn: (state) => {
      state.packagingPriceBtn = false
    },
  },

  getters: {
    getPackagingPriceFilter: state => {
      return state.packagingPriceFilter
    },

    getPackagingPriceForPage: state => {
      return state.packagingPriceForPage
    },

    getPackagingPrice: state => {
      return state.packagingPrice
    },

    getPackagingPriceLoading: state => {
      return state.packagingPriceLoading
    },


    getNextPackagingPricePage: state => {
      return state.nextPackagingPricePage
    },

    getPackagingPriceItem: state => {
      return state.packagingPriceItem
    },

    getPackagingPriceCommonList: state => {
      return state.packagingPriceCommonList
    },

    getPackagingPriceBtn: state => {
      return state.packagingPriceBtn
    },
  },

  actions: {

    async fetchPackagingPrice({commit, getters}, filter = '') {
      if(!getters.getNextPackagingPricePage)
        commit('setPackagingPriceLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setPackagingPrice',{packagingPrice: response.data.data.data})
          commit('setPackagingPriceCommonList',{packagingPriceCommonList: response.data.data})
          commit('setPackagingPriceLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setPackagingPriceLoadingEnd');
        return e;
      }
    },

    async getPackagingPrice({commit}, id) {
      commit('setPackagingPriceLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setPackagingPriceItem', {packagingPriceItem: response.data.data})
          commit('setPackagingPriceLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setPackagingPriceLoadingEnd');
        return e;
      }
    },

    async createPackagingPrice({commit}, data) {
      commit('changePackagingPriceBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successPackagingPriceBtn');
          return response;
        });
      } catch (e) {
        commit('successPackagingPriceBtn');
        return e;
      }
    },

    async updatePackagingPrice({commit}, {id, data}) {
      commit('changePackagingPriceBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successPackagingPriceBtn');
          return response;
        });
      } catch (e) {
        commit('successPackagingPriceBtn');
        return e;
      }
    },

    async deletePackagingPrice({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successPackagingPriceBtn');
        return e;
      }
    },




    /**
     *
     *  Actions by Gift Papers
     *
     */

    async fetchPackagingGiftPapers({commit, getters}, filter = '') {
      if(!getters.getNextPackagingPricePage)
        commit('setPackagingPriceLoadingStart')
      try {
        return await API.get(`${prefixGiftPapers}${filter}`).then((response) =>{
          commit('setPackagingPrice',{packagingPrice: response.data.data.data})
          commit('setPackagingPriceCommonList',{packagingPriceCommonList: response.data.data})
          commit('setPackagingPriceLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setPackagingPriceLoadingEnd');
        return e;
      }
    },

    async getGiftPapers({commit}, id) {
      commit('setPackagingPriceLoadingStart');
      try {
        return await API.get(`${prefixGiftPapers}/${id}`).then((response) =>{
          console.log(response)
          commit('setPackagingPriceItem', {packagingPriceItem: response.data.data})
          commit('setPackagingPriceLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setPackagingPriceLoadingEnd');
        return e;
      }
    },

    async createGiftPapers({commit}, data) {
      commit('changePackagingPriceBtn');
      try {
        return await API.post(`${prefixGiftPapers}`, data).then((response) =>{
          commit('successPackagingPriceBtn');
          return response;
        });
      } catch (e) {
        commit('successPackagingPriceBtn');
        return e;
      }
    },

    async updateGiftPapers({commit}, {id, data}) {
      commit('changePackagingPriceBtn');
      try {
        return await API.put(`${prefixGiftPapers}/${id}`, data).then((response) =>{
          commit('successPackagingPriceBtn');
          return response;
        });
      } catch (e) {
        commit('successPackagingPriceBtn');
        return e;
      }
    },

    async deleteGiftPapers({commit}, id) {
      try {
        return await API.delete(`${prefixGiftPapers}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successPackagingPriceBtn');
        return e;
      }
    },

    async addPhotoToGiftPapers({commit}, {data, id}) {
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${prefixGiftPapers}/${id}/upload-file`, data).then((response) =>{
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successPackagingPriceBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async updatePackingType({commit}, {id, data}) {
      commit('changePackagingPriceBtn');
      try {
        return await API.put(`${prefixPackingType}/${id}`, data).then((response) =>{
          commit('successPackagingPriceBtn');
          return response;
        });
      } catch (e) {
        commit('successPackagingPriceBtn');
        return e;
      }
    },




    /**
     *
     *  Actions by Customization Offer
     *
     */

    async fetchCustomizationOffer({commit, getters}, filter = '') {
      if(!getters.getNextPackagingPricePage)
        commit('setPackagingPriceLoadingStart')
      try {
        return await API.get(`${prefixCustomizationOffer}${filter}`).then((response) =>{
          commit('setPackagingPrice',{packagingPrice: response.data.data.data})
          commit('setPackagingPriceCommonList',{packagingPriceCommonList: {...response.data.data.meta, ...{
                addition_export: response.data.data.addition_export,
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev
              }}})
          commit('setPackagingPriceLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setPackagingPriceLoadingEnd');
        return e;
      }
    },

    async getCustomizationOffer({commit}, id) {
      commit('setPackagingPriceLoadingStart');
      try {
        return await API.get(`${prefixCustomizationOffer}/${id}`).then((response) =>{
          commit('setPackagingPriceItem', {packagingPriceItem: response.data.data})
          commit('setPackagingPriceLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setPackagingPriceLoadingEnd');
        return e;
      }
    },

    async getCreateCustomizationOffer({commit}) {
      commit('setPackagingPriceLoadingStart');
      try {
        return await API.get(`${prefixCustomizationOffer}/create`).then((response) =>{
          commit('setPackagingPriceLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setPackagingPriceLoadingEnd');
        return e;
      }
    },

    async createCustomizationOffer({commit}, data) {
      commit('changePackagingPriceBtn');
      try {
        return await API.post(`${prefixCustomizationOffer}`, data).then((response) =>{
          commit('successPackagingPriceBtn');
          return response;
        });
      } catch (e) {
        commit('successPackagingPriceBtn');
        return e;
      }
    },

    async updateCustomizationOffer({commit}, {id, data}) {
      commit('changePackagingPriceBtn');
      try {
        return await API.put(`${prefixCustomizationOffer}/${id}`, data).then((response) =>{
          commit('successPackagingPriceBtn');
          return response;
        });
      } catch (e) {
        commit('successPackagingPriceBtn');
        return e;
      }
    },

    async deleteCustomizationOffer({commit}, id) {
      try {
        return await API.delete(`${prefixCustomizationOffer}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successPackagingPriceBtn');
        return e;
      }
    },
  }
}
