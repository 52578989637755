import {API} from "../../axios/axiosMainUrl";

const prefix = '/settings/working-days'

export const freshChatWorkingDays = {
  state: {
    freshChatWorkingDays: [],
    freshChatWorkingDaysItem: {},
    freshChatWorkingDaysCommonList: [],
    freshChatWorkingDaysBtn: false,
    freshChatWorkingDaysLoading: true,
    nextFreshChatWorkingDaysPage: false,
    freshChatWorkingDaysForPage: 25,
    freshChatWorkingDaysFilter: '',
  },

  mutations: {
    setFreshChatWorkingDaysFilter: (state, payload) => {
      state.freshChatWorkingDaysFilter = payload
    },

    setFreshChatWorkingDays: (state, payload) => {
      if(state.nextFreshChatWorkingDaysPage) {
        state.freshChatWorkingDays = state.freshChatWorkingDays.concat(payload.freshChatWorkingDays)
      } else {
        state.freshChatWorkingDays = payload.freshChatWorkingDays
      }
    },

    setFreshChatWorkingDaysCommonList: (state, payload) => {
      state.freshChatWorkingDaysCommonList = payload.freshChatWorkingDaysCommonList
    },

    setNextFreshChatWorkingDaysPage: (state, payload) => {
      state.nextFreshChatWorkingDaysPage = payload
    },

    setFreshChatWorkingDaysItem: (state, payload) => {
      state.freshChatWorkingDaysItem = payload.freshChatWorkingDaysItem
    },

    setFreshChatWorkingDaysLoadingStart: (state) => {
      state.freshChatWorkingDaysLoading = true
    },

    setFreshChatWorkingDaysLoadingEnd: (state) => {
      state.freshChatWorkingDaysLoading = false
    },

    changeFreshChatWorkingDaysBtn: (state) => {
      state.freshChatWorkingDaysBtn = true
    },
    successFreshChatWorkingDaysBtn: (state) => {
      state.freshChatWorkingDaysBtn = false
    },
  },

  getters: {
    getFreshChatWorkingDaysFilter: state => {
      return state.freshChatWorkingDaysFilter
    },

    getFreshChatWorkingDaysForPage: state => {
      return state.freshChatWorkingDaysForPage
    },

    getFreshChatWorkingDays: state => {
      return state.freshChatWorkingDays
    },

    getFreshChatWorkingDaysLoading: state => {
      return state.freshChatWorkingDaysLoading
    },


    getNextFreshChatWorkingDaysPage: state => {
      return state.nextFreshChatWorkingDaysPage
    },

    getFreshChatWorkingDaysItem: state => {
      return state.freshChatWorkingDaysItem
    },

    getFreshChatWorkingDaysCommonList: state => {
      return state.freshChatWorkingDaysCommonList
    },

    getFreshChatWorkingDaysBtn: state => {
      return state.freshChatWorkingDaysBtn
    },
  },

  actions: {

    async fetchFreshChatWorkingDays({commit, getters}, filter = '') {
      if(!getters.getNextFreshChatWorkingDaysPage)
        commit('setFreshChatWorkingDaysLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setFreshChatWorkingDays',{freshChatWorkingDays: response.data.data})
          // commit('setFreshChatWorkingDaysCommonList',{freshChatWorkingDaysCommonList: response.data.data})
          commit('setFreshChatWorkingDaysLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setFreshChatWorkingDaysLoadingEnd');
        return e;
      }
    },

    async getFreshChatWorkingDays({commit}, name) {
      // commit('setFreshChatWorkingDaysLoadingStart');
      try {
        return await API.get(`${prefix}/${name}`).then((response) =>{
          console.log(response)
          commit('setFreshChatWorkingDaysItem', {freshChatWorkingDaysItem: response.data.data})
          // commit('setFreshChatWorkingDaysLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setFreshChatWorkingDaysLoadingEnd');
        return e;
      }
    },

    async createFreshChatWorkingDays({commit}, data) {
      commit('changeFreshChatWorkingDaysBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successFreshChatWorkingDaysBtn');
          return response;
        });
      } catch (e) {
        commit('successFreshChatWorkingDaysBtn');
        return e;
      }
    },

    async updateFreshChatWorkingDays({commit}, data) {
      commit('changeFreshChatWorkingDaysBtn');
      try {
        return await API.put(`${prefix}`, data).then((response) =>{
          commit('successFreshChatWorkingDaysBtn');
          return response;
        });
      } catch (e) {
        commit('successFreshChatWorkingDaysBtn');
        return e;
      }
    },

    async deleteFreshChatWorkingDays({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successFreshChatWorkingDaysBtn');
        return e;
      }
    },

  }
}
