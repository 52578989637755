import {checkValid} from "./functions/ModelsValidate";


export function Files(params) {

  this.init = () => {
    if(params?.maxCountFiles){
      this.data.maxCountFiles = params.maxCountFiles
    }
    if(params?.maxSizeFiles){
      this.data.maxSizeFiles = params.maxSizeFiles
    }
  }

  this.checkValid = checkValid

  this.data = {
    files: [],
    filesMaxSizeError: false,
    downloadFiles: [],
    maxCountFiles: 1,
    maxSizeFiles: 3,

    orderFiles: [],
  }

  this.validation = {
    files: false,
    filesMaxSizeError: false,
  }

  this.validationTranslate = {
    files: '',
    filesMaxSizeError: '',
  }

  this.validationTxt = {
    files: false,
    filesMaxSizeError: false,
  }

  this.init()

  /**
   * Getters
   */
  this.getFiles = () => {
    return this.data.files
  }
  this.getFilesMaxSizeError = () => {
    return this.data.filesMaxSizeError
  }
  this.getDownloadFiles = () => {
    return this.data.downloadFiles
  }
  this.getOrderFiles = () => {
    return this.data.orderFiles
  }

  /**
   * Setters
   */
  this.setFiles = (val) => {
    this.data.files = val
  }

  this.setFilesMaxSizeError = (val) => {
    this.data.filesMaxSizeError = val
  }
  this.setDownloadFiles = (val) => {
    this.data.downloadFiles = val
  }
  this.setOrderFiles = (val) => {
    this.data.orderFiles = val
  }

}



/**
 * Global Setters
 */

Files.prototype.setItem = function() {

}

Files.prototype.pushDownloadFiles = function(val) {
  this.data.downloadFiles.push(val)
}

Files.prototype.removeDownloadFile = function(index) {
  this.data.downloadFiles.splice(index, 1)
}


/**
 * Prepare Data
 */

Files.prototype.prepareSave = function() {

}


/**
 * Validations
 */

Files.prototype.firstValidation = function () {

  // let data = this.data
  //
  // let validationItems = {
  //   filesMaxSizeError: data.listingName,
  // }
  //
  // let validationOptions = {
  //   filesMaxSizeError: {type: ['empty']},
  // }

  // if(filesMaxSizeError)

  // return this.data.files.length + this.data.downloadFiles.length > 0

  if(this.data.filesMaxSizeError) return false

  let data = this.data,
    files =
      (Object.keys(data.files).length > 0 ||
        Object.keys(data.downloadFiles).length > 0) ? 1 : '',

    validationItems = {
      files: files,
    },
    validationOptions = {
      files: {type: ['empty']},
    }

  return this.checkValid(validationItems, validationOptions)

  // return this.checkValid(validationItems, validationOptions)
}

Files.prototype.fileSizeValidation = function () {
  if(this.data.filesMaxSizeError) return false

  return true
}