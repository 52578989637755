<template>
  <div class="search-input">
    <div class="search-input__inner">
      <label :for="'default-input-' + id" class="search-input__label-wrap"
             :class="{
                active: activeInput || value.length > 0,
                'disabled' : disabled === true
              }">
        <input
                :id="'default-input-' + id"
                :type="type"
                :disabled="disabled === true"
                class="search-input__input"
                v-on:input="handleInput($event.target.value)"
                :value="value"
                @focus="activeInput = true"
                @blur="activeInput = false"
        >
        <span class="search-input__label"
              v-if="label"
        >
          {{ label }}
        </span>
      </label>
      <button
          class="search-input__btn"
          v-bind:class="{'disabled-btn' : disabled === true}"
          @click="$emit('submit')"
      ></button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "SearchInput",


    data () {
      return {
        id: null,
        isShowPass: false,
        content: this.value,
        activeInput: false,
      }
    },

    props: [
      'type',
      'label',
      'placeholder',
      'value',
      'disabled',
    ],

    mounted () {
      this.id = this._uid
    },

    methods: {
      handleInput (value) {
        this.$emit('input', value)
      }
    }
  }
</script>

<style lang="scss">
  @import "../../../../scss/mixins/mixins";
  @import "../../../../scss/colors";

  .search-input{

    &.scan-ico{
      position: relative;

      &:before{
        content: '';
        position: absolute;
        left: 16px;
        top: 14px;
        width: 12px;
        height: 12px;
        background: url("../../../../assets/img/UI-group/scan-ico.svg") center/contain no-repeat;
        z-index: 2;
      }
    }
    &.scan-ico &__input{
      padding-left: 36px;
      background: $mainBg;
      border: 1px solid $borderBrown;
    }
    &.scan-ico:not(.active) &__label{
      //padding-left: 20px;
      left: 36px;
    }

    &__inner{
      display: flex;
    }

    &__label-wrap{
      display: block;
      position: relative;
      width: 100%;

      &.active input{
        border-color: #CD9E64;
      }

      &.active .search-input__label{
        top: -5px;
        background: white;
        background: linear-gradient(0deg, #F8F4EE 0%, #FFFFFF 105.56%);
        padding: 0 2px;
        font-size: 10px;
        line-height: 12px;
      }
    }



    &__input{
      font-size: 14px;
      line-height: 16px;
      width: 100%;

      padding-left: 16px;
      height: 42px;
      background: $white;
      border: 1px solid $lightBorder;
      border-right: 0;
      border-radius: 5px 0px 0px 5px;

      &::placeholder{
        font-size: 14px;
        line-height: 16px;
        color: #BBAD9C;
        line-height: revert;
      }
    }

    &.active input{
      border-color: #CD9E64;
    }

    &__label{
      position: absolute;
      left: 16px;
      top: 13px;
      font-size: 14px;
      line-height: 16px;
      color: #BBAD9C;
      transition: 0.15s all;
      cursor: text;
      user-select: none;
    }

    &__btn{
      background: #CD9E64 url("../../../../assets/img/header-group/search.svg") center center no-repeat;
      border-radius: 0px 5px 5px 0px;
      width: 42px;
      height: 42px;
      border: 0;
      outline: 0;
      cursor: pointer;
    }

  }

</style>
