import {API} from "../../axios/axiosMainUrl";

const prefix = '/settings/working-schedule-messages'

export const freshChatMessages = {
  state: {
    freshChatMessages: [],
    freshChatMessagesItem: {},
    freshChatMessagesCommonList: [],
    freshChatMessagesBtn: false,
    freshChatMessagesLoading: true,
    nextFreshChatMessagesPage: false,
    freshChatMessagesForPage: 25,
    freshChatMessagesFilter: '',
  },

  mutations: {
    setFreshChatMessagesFilter: (state, payload) => {
      state.freshChatMessagesFilter = payload
    },

    setFreshChatMessages: (state, payload) => {
      if(state.nextFreshChatMessagesPage) {
        state.freshChatMessages = state.freshChatMessages.concat(payload.freshChatMessages)
      } else {
        state.freshChatMessages = payload.freshChatMessages
      }
    },

    setFreshChatMessagesCommonList: (state, payload) => {
      state.freshChatMessagesCommonList = payload.freshChatMessagesCommonList
    },

    setNextFreshChatMessagesPage: (state, payload) => {
      state.nextFreshChatMessagesPage = payload
    },

    setFreshChatMessagesItem: (state, payload) => {
      state.freshChatMessagesItem = payload.freshChatMessagesItem
    },

    setFreshChatMessagesLoadingStart: (state) => {
      state.freshChatMessagesLoading = true
    },

    setFreshChatMessagesLoadingEnd: (state) => {
      state.freshChatMessagesLoading = false
    },

    changeFreshChatMessagesBtn: (state) => {
      state.freshChatMessagesBtn = true
    },
    successFreshChatMessagesBtn: (state) => {
      state.freshChatMessagesBtn = false
    },
  },

  getters: {
    getFreshChatMessagesFilter: state => {
      return state.freshChatMessagesFilter
    },

    getFreshChatMessagesForPage: state => {
      return state.freshChatMessagesForPage
    },

    getFreshChatMessages: state => {
      return state.freshChatMessages
    },

    getFreshChatMessagesLoading: state => {
      return state.freshChatMessagesLoading
    },


    getNextFreshChatMessagesPage: state => {
      return state.nextFreshChatMessagesPage
    },

    getFreshChatMessagesItem: state => {
      return state.freshChatMessagesItem
    },

    getFreshChatMessagesCommonList: state => {
      return state.freshChatMessagesCommonList
    },

    getFreshChatMessagesBtn: state => {
      return state.freshChatMessagesBtn
    },
  },

  actions: {

    async fetchFreshChatMessages({commit, getters}, filter = '') {
      if(!getters.getNextFreshChatMessagesPage)
        commit('setFreshChatMessagesLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setFreshChatMessages',{freshChatMessages: response.data.data.data})
          commit('setFreshChatMessagesCommonList',{freshChatMessagesCommonList: response.data.data})
          commit('setFreshChatMessagesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setFreshChatMessagesLoadingEnd');
        return e;
      }
    },

    async getFreshChatMessages({commit}) {
      commit('setFreshChatMessagesLoadingStart');
      try {
        return await API.get(`${prefix}`).then((response) =>{
          console.log(response)
          commit('setFreshChatMessagesItem', {freshChatMessagesItem: response.data.data})
          commit('setFreshChatMessagesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setFreshChatMessagesLoadingEnd');
        return e;
      }
    },

    async createFreshChatMessages({commit}, data) {
      commit('changeFreshChatMessagesBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successFreshChatMessagesBtn');
          return response;
        });
      } catch (e) {
        commit('successFreshChatMessagesBtn');
        return e;
      }
    },

    async updateFreshChatMessages({commit}, data) {
      commit('changeFreshChatMessagesBtn');
      try {
        return await API.put(`${prefix}`, data).then((response) =>{
          commit('successFreshChatMessagesBtn');
          return response;
        });
      } catch (e) {
        commit('successFreshChatMessagesBtn');
        return e;
      }
    },

    async deleteFreshChatMessages({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successFreshChatMessagesBtn');
        return e;
      }
    },

  }
}
