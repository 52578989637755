import {API} from "../../axios/axiosMainUrl";

const prefix  = '/easy-post/invoices'

export const easypostInvoices = {
  state: {
    easypostInvoices: [],
    easypostInvoicesItem: {},
    easypostInvoicesCommonList: [],
    easypostInvoicesBtn: false,
    easypostInvoicesLoading: true,
    nextEasypostInvoicesPage: false,
    easypostInvoicesForPage: 25,
    easypostInvoicesFilter: '',
  },

  mutations: {
    setEasypostInvoicesFilter: (state, payload) => {
      state.easypostInvoicesFilter = payload
    },

    setEasypostInvoices: (state, payload) => {
      if(state.nextEasypostInvoicesPage) {
        state.easypostInvoices = state.easypostInvoices.concat(payload.easypostInvoices)
      } else {
        state.easypostInvoices = payload.easypostInvoices
      }
    },

    setEasypostInvoicesCommonList: (state, payload) => {
      state.easypostInvoicesCommonList = payload.easypostInvoicesCommonList
    },

    setNextEasypostInvoicesPage: (state, payload) => {
      state.nextEasypostInvoicesPage = payload
    },

    setEasypostInvoicesItem: (state, payload) => {
      state.easypostInvoicesItem = payload.easypostInvoicesItem
    },

    setEasypostInvoicesLoadingStart: (state) => {
      state.easypostInvoicesLoading = true
    },

    setEasypostInvoicesLoadingEnd: (state) => {
      state.easypostInvoicesLoading = false
    },

    changeEasypostInvoicesBtn: (state) => {
      state.easypostInvoicesBtn = true
    },
    successEasypostInvoicesBtn: (state) => {
      state.easypostInvoicesBtn = false
    },
  },

  getters: {
    getEasypostInvoicesFilter: state => {
      return state.easypostInvoicesFilter
    },

    getEasypostInvoicesForPage: state => {
      return state.easypostInvoicesForPage
    },

    getEasypostInvoices: state => {
      return state.easypostInvoices
    },

    getEasypostInvoicesLoading: state => {
      return state.easypostInvoicesLoading
    },


    getNextEasypostInvoicesPage: state => {
      return state.nextEasypostInvoicesPage
    },

    getEasypostInvoicesItem: state => {
      return state.easypostInvoicesItem
    },

    getEasypostInvoicesCommonList: state => {
      return state.easypostInvoicesCommonList
    },

    getEasypostInvoicesBtn: state => {
      return state.easypostInvoicesBtn
    },
  },

  actions: {

    async fetchEasypostInvoices({commit, getters}, filter = '') {
      if(!getters.getNextEasypostInvoicesPage)
        commit('setEasypostInvoicesLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setEasypostInvoices',{easypostInvoices: response.data.data.data})
          commit('setEasypostInvoicesCommonList',{easypostInvoicesCommonList: response.data.data})
          commit('setEasypostInvoicesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setEasypostInvoicesLoadingEnd');
        return e;
      }
    },

    async getEasypostInvoices({commit}, id) {
      commit('setEasypostInvoicesLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setEasypostInvoicesItem', {easypostInvoicesItem: response.data.data})
          commit('setEasypostInvoicesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setEasypostInvoicesLoadingEnd');
        return e;
      }
    },

    async createEasypostInvoices({commit}, data) {
      commit('changeEasypostInvoicesBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successEasypostInvoicesBtn');
          return response;
        });
      } catch (e) {
        commit('successEasypostInvoicesBtn');
        return e;
      }
    },

    async updateEasypostInvoices({commit}, {id, data}) {
      commit('changeEasypostInvoicesBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successEasypostInvoicesBtn');
          return response;
        });
      } catch (e) {
        commit('successEasypostInvoicesBtn');
        return e;
      }
    },

    async deleteEasypostInvoices({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successEasypostInvoicesBtn');
        return e;
      }
    },



    async getExportEasypostInvoices({commit}, {filter, type}) {
      commit('setExportBtnLoadingStart')
      commit('setLoadingEnd')
      try {
        return await API.get(`${prefix}/export/${type}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

  }
}
