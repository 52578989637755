import {API} from "../../axios/axiosMainUrl";

const prefix = 'consolidations/consolidation-persons'

export const consolidationUsers = {
  state: {
    consolidationUsers: [],
    consolidationUsersItem: {},
    consolidationUsersCommonList: [],
    consolidationUsersBtn: false,
    consolidationUsersLoading: true,
    nextConsolidationUsersPage: false,
    consolidationUsersForPage: 25,
    consolidationUsersFilter: '',
  },

  mutations: {
    setConsolidationUsersFilter: (state, payload) => {
      state.consolidationUsersFilter = payload
    },

    setConsolidationUsers: (state, payload) => {
      if(state.nextConsolidationUsersPage) {
        state.consolidationUsers = state.consolidationUsers.concat(payload.consolidationUsers)
      } else {
        state.consolidationUsers = payload.consolidationUsers
      }
    },

    setConsolidationUsersCommonList: (state, payload) => {
      state.consolidationUsersCommonList = payload.consolidationUsersCommonList
    },

    setNextConsolidationUsersPage: (state, payload) => {
      state.nextConsolidationUsersPage = payload
    },

    setConsolidationUsersItem: (state, payload) => {
      state.consolidationUsersItem = payload.consolidationUsersItem
    },

    setConsolidationUsersLoadingStart: (state) => {
      state.consolidationUsersLoading = true
    },

    setConsolidationUsersLoadingEnd: (state) => {
      state.consolidationUsersLoading = false
    },

    changeConsolidationUsersBtn: (state) => {
      state.consolidationUsersBtn = true
    },
    successConsolidationUsersBtn: (state) => {
      state.consolidationUsersBtn = false
    },
  },

  getters: {
    getConsolidationUsersFilter: state => {
      return state.consolidationUsersFilter
    },

    getConsolidationUsersForPage: state => {
      return state.consolidationUsersForPage
    },

    getConsolidationUsers: state => {
      return state.consolidationUsers
    },

    getConsolidationUsersLoading: state => {
      return state.consolidationUsersLoading
    },


    getNextConsolidationUsersPage: state => {
      return state.nextConsolidationUsersPage
    },

    getConsolidationUsersItem: state => {
      return state.consolidationUsersItem
    },

    getConsolidationUsersCommonList: state => {
      return state.consolidationUsersCommonList
    },

    getConsolidationUsersBtn: state => {
      return state.consolidationUsersBtn
    },
  },

  actions: {

    async fetchConsolidationUsersWithPermissions({commit, getters}, filter = '') {
      if(!getters.getNextConsolidationUsersPage)
        commit('setConsolidationUsersLoadingStart')
      try {
        return await API.get(`/${prefix}${filter}`).then((response) =>{
          commit('setConsolidationUsers',{consolidationUsers: response.data.data.data})
          commit('setConsolidationUsersCommonList',{consolidationUsersCommonList: response.data.data})
          commit('setConsolidationUsersLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setConsolidationUsersLoadingEnd');
        return e;
      }
    },

    async fetchConsolidationUsers({commit, getters}, filter = '') {
      if(!getters.getNextConsolidationUsersPage)
        commit('setConsolidationUsersLoadingStart')
      try {
        return await API.get(`/${prefix}/public-list${filter}`).then((response) =>{
          commit('setConsolidationUsers',{consolidationUsers: response.data.data.data})
          commit('setConsolidationUsersCommonList',{consolidationUsersCommonList: response.data.data})
          commit('setConsolidationUsersLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setConsolidationUsersLoadingEnd');
        return e;
      }
    },

    async getConsolidationUsers({commit}, id) {
      try {
        return await API.get(`/${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setConsolidationUsersItem', {consolidationUsersItem: response.data.data})
          commit('setConsolidationUsersLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setConsolidationUsersLoadingEnd');
        return e;
      }
    },

    async createConsolidationUsers({commit}, data) {
      commit('changeConsolidationUsersBtn');
      try {
        return await API.post(`/${prefix}`, data).then((response) =>{
          commit('successConsolidationUsersBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationUsersBtn');
        return e;
      }
    },

    async updateConsolidationUsers({commit}, {id, data}) {
      commit('changeConsolidationUsersBtn');
      try {
        return await API.put(`/${prefix}/${id}`, data).then((response) =>{
          commit('successConsolidationUsersBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationUsersBtn');
        return e;
      }
    },

    async deleteConsolidationUsers({commit}, id) {
      try {
        return await API.delete(`/${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successConsolidationUsersBtn');
        return e;
      }
    },

  }
}
