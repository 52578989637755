import {API} from "../../axios/axiosMainUrl";

const apiOrderInternal = 'api-order-internal'

export const cdek = {
  state: {
    cdek: [],
    cdekItem: {},
    cdekCommonList: [],
    cdekBtn: false,
    cdekLoading: true,
    nextCDEKPage: false,
    cdekForPage: 25,
    cdekFilter: '',
  },

  mutations: {
    setCDEKFilter: (state, payload) => {
      state.cdekFilter = payload
    },

    setCDEK: (state, payload) => {
      if(state.nextCDEKPage) {
        state.cdek = state.cdek.concat(payload.cdek)
      } else {
        state.cdek = payload.cdek
      }
    },

    setCDEKCommonList: (state, payload) => {
      state.cdekCommonList = payload.cdekCommonList
    },

    setNextCDEKPage: (state, payload) => {
      state.nextCDEKPage = payload
    },

    setCDEKItem: (state, payload) => {
      state.cdekItem = payload.cdekItem
    },

    setCDEKLoadingStart: (state) => {
      state.cdekLoading = true
    },

    setCDEKLoadingEnd: (state) => {
      state.cdekLoading = false
    },

    changeCDEKBtn: (state) => {
      state.cdekBtn = true
    },
    successCDEKBtn: (state) => {
      state.cdekBtn = false
    },
  },

  getters: {
    getCDEKFilter: state => {
      return state.cdekFilter
    },

    getCDEKForPage: state => {
      return state.cdekForPage
    },

    getCDEK: state => {
      return state.cdek
    },

    getCDEKLoading: state => {
      return state.cdekLoading
    },


    getNextCDEKPage: state => {
      return state.nextCDEKPage
    },

    getCDEKItem: state => {
      return state.cdekItem
    },

    getCDEKCommonList: state => {
      return state.cdekCommonList
    },

    getCDEKBtn: state => {
      return state.cdekBtn
    },
  },

  actions: {

    async fetchCDEK({commit, getters}, filter = '') {
      if(!getters.getNextCDEKPage)
        commit('setCDEKLoadingStart')
      try {
        return await API.get(`/cdek${filter}`).then((response) =>{
          commit('setCDEK',{cdek: response.data.data.data})
          commit('setCDEKCommonList',{cdekCommonList: response.data.data})
          commit('setCDEKLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setCDEKLoadingEnd');
        return e;
      }
    },

    async getCDEK({commit}, id) {
      commit('setCDEKLoadingStart');
      try {
        return await API.get(`/cdek/${id}`).then((response) =>{
          console.log(response)
          commit('setCDEKItem', {cdekItem: response.data.data})
          commit('setCDEKLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setCDEKLoadingEnd');
        return e;
      }
    },


    // async createCDEK({commit}, data) {
    //   commit('changeCDEKBtn');
    //   try {
    //     return await API.post(`${orderInternal}`, data).then((response) =>{
    //       commit('successCDEKBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successCDEKBtn');
    //     return e;
    //   }
    // },


    async updateCDEK({commit}, {id, data}) {
      commit('changeCDEKBtn');
      try {
        return await API.put(`/cdek/${id}`, data).then((response) =>{
          commit('successCDEKBtn');
          return response;
        });
      } catch (e) {
        commit('successCDEKBtn');
        return e;
      }
    },

    async deleteCDEK({commit}, id) {
      try {
        return await API.delete(`/cdek/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successCDEKBtn');
        return e;
      }
    },


    // async getUserSenderWarehouses({commit}, data) {
    //   try {
    //     return await API.post(`${apiOrderInternal}/cdek/get-user-sender-warehouses`, data).then((response) =>{
    //       return response.data;
    //     });
    //   } catch (e) {
    //     commit('successCDEKBtn');
    //     return e;
    //   }
    // },

    // async getUserSenderAddresses({commit}, data) {
    //   try {
    //     return await API.post(`${apiOrderInternal}/cdek/get-user-sender-addresses`, data).then((response) =>{
    //       return response.data;
    //     });
    //   } catch (e) {
    //     commit('successCDEKBtn');
    //     return e;
    //   }
    // },

    async getCDEKDeliveryPrice({commit}, data) {
      try {
        return await API.post(`${apiOrderInternal}/cdek/get-rate`, data).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successCDEKBtn');
        return e;
      }
    },

    async createCDEKAddress({commit}, data) {
      try {
        return await API.post(`${apiOrderInternal}/cdek/create-user-address`, data).then((response) =>{
          return response.data.data;
        });
      } catch (e) {
        commit('successCDEKBtn');
        return e;
      }
    },


    async getCDEKCities({commit}, data) {
      try {
        return await API.post(`${apiOrderInternal}/cdek/get-search-city`, data).then((response) =>{
          return response.data.data;
        });
      } catch (e) {
        commit('setCDEKLoadingEnd');
        return e;
      }
    },


    async getCDEKStreets({commit}, data) {
      try {
        return await API.post(`${apiOrderInternal}/cdek/get-search-street-by-city-ref`, data).then((response) =>{
          return response.data.data;
        });
      } catch (e) {
        commit('setCDEKLoadingEnd');
        return e;
      }
    },

    async getCDEKOffices({commit}, data) {
      try {
        return await API.post(`${apiOrderInternal}/cdek/get-offices`, data).then((response) =>{
          return response.data.data;
        });
      } catch (e) {
        commit('setCDEKLoadingEnd');
        return e;
      }
    },

    async getCDEKOrderLabel({commit}, id) {
      try {
        return await API.post(`${apiOrderInternal}/cdek/${id}/get-order-label`).then((response) =>{
          return response.data.data;
        });
      } catch (e) {
        commit('setCDEKLoadingEnd');
        return e;
      }
    },

    async getCDEKProductLabel({commit}, id) {
      try {
        return await API.post(`${apiOrderInternal}/cdek/${id}/get-product-label`).then((response) =>{
          return response.data.data;
        });
      } catch (e) {
        commit('setCDEKLoadingEnd');
        return e;
      }
    },

    async createUserCDEKAddress({commit}, data) {
      commit('changeCDEKBtn');
      try {
        return await API.post(`${apiOrderInternal}/cdek/create-user-address`, data).then((response) =>{
          commit('successCDEKBtn');
          return response;
        });
      } catch (e) {
        commit('successCDEKBtn');
        return e;
      }
    },

    async createCDEKLabel({commit}, id) {
      commit('changeCDEKBtn');
      try {
        return await API.post(`${apiOrderInternal}/cdek/${id}/create-label`).then((response) =>{
          commit('successCDEKBtn');
          return response;
        });
      } catch (e) {
        commit('successCDEKBtn');
        return e;
      }
    },

  }
}
