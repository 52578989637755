import {API} from "../../axios/axiosMainUrl";

export const shops = {
  state: {
    shops: [],
    shopsCommonList: {},
    shopsItem: {},
    loadingShops: true,
    loadingProductItem: false,
    shopBtn: false,
    nextShopPage: false,

    shopTypes: [],
    shopTypesParent: [],
    shopTypesChild: [],
    shopTypesChildWithActive: [],
    loadingShopTypes: [],
    shopTypesBtn: false,

    shopsPermissionData: '',

    shopsFilterDate: [],
    shopsFilter: '',
    shopsForPage: 25,

    defaultShopsTypes: {
      etsy: {
        type: 'etsy',
        name: 'Etsy',
        ico: '/img/shops-group/etsy-shop-icon.png',
      },
      ebay: {
        type: 'ebay',
        name: 'Ebay',
        ico: '/img/shops-group/ebay-shop-icon.png'
      },
      amazonmws: {
        type: 'amazonmws',
        name: 'Amazon',
        ico: '/img/shops-group/amazon-shop-icon.png'
      },
      other: {
        type: 'other',
        name: 'Other',
        ico: '',
      },
      ukrainiantreasures: {
        type: 'ukrainiantreasures',
        name: 'Ukrainiantreasures',
        ico: '/img/shops-group/ukr-shops.png'
      },
      shopify: {
        type: 'shopify',
        name: 'Shopify',
        ico: '',
      },
    }
  },

  mutations: {
    setShopsFilterDate: (state, payload) => {
      state.shopsFilterDate = payload
    },

    setShopsFilter: (state, payload) => {
      state.shopsFilter = payload
    },

    setShops: (state, payload) => {
      if(state.nextShopPage) {
        state.shops = state.shops.concat(payload.shops)
      } else {
        state.shops = payload.shops
      }
    },

    setNextShopPage: (state, payload) => {
      state.nextShopPage = payload
    },

    setShopsCommonList: (state, payload) => {
      state.shopsCommonList = payload.shopsCommonList
    },

    setShopsItem: (state, payload) => {
      state.shopsItem = payload.shopsItem
    },

    setShopTypesParent: (state, payload) => {
      state.shopTypesParent = payload
    },

    setShopTypesChild: (state, payload) => {
      state.shopTypesChild = payload
    },

    setShopTypesChildWithActive: (state, payload) => {
      state.shopTypesChildWithActive = payload
    },

    setShopsPermissionData: (state, payload) => {
      state.shopsPermissionData = payload
    },

    setLoadingStart: (state) => {
      state.loadingShops = true
    },
    setLoadingRoleItemStart: (state) => {
      state.loadingProductItem = true
    },

    setLoadingShopsEnd: (state) => {
      state.loadingShops = false
    },

    setLoadingRoleItemEnd: (state) => {
      state.loadingProductItem = false
    },


    setShopTypes: (state, payload) => {
      state.shopTypes = payload.shopTypes
    },
    setLoadingShopTypesStart: (state) => {
      state.loadingShopTypes = true
    },
    setLoadingShopTypesEnd: (state) => {
      state.loadingShopTypes = false
    },
    changeShopTypes: (state) => {
      state.shopTypesBtn = true
    },
    successShopTypes: (state) => {
      state.shopTypesBtn = false
    },
    changeShopBtn: (state) => {
      state.shopBtn = true
    },
    successShopBtn: (state) => {
      state.shopBtn = false
    },

    clearShopsItem: (state) => {
      state.shopsItem = {}
    }
  },

  getters: {
    getShopsFilterDate: state => {
      return state.shopsFilterDate
    },

    getShopsFilter: state => {
      return state.shopsFilter
    },

    getShopsForPage: state => {
      return state.shopsForPage
    },

    getShops: state => {
      return state.shops
    },

    getNextShopPage: state => {
      return state.nextShopPage
    },

    getLoadingShops: state => {
      return state.loadingShops
    },

    getShopsCommonList: state => {
      return state.shopsCommonList
    },

    getShopsItem: state => {
      return state.shopsItem
    },

    getShopTypesParent: state => {
      return state.shopTypesParent
    },

    getShopTypesChild: state => {
      return state.shopTypesChild
    },

    getShopTypesChildWithActive: state => {
      return state.shopTypesChildWithActive
    },

    getShopsTypes: state => {
      return state.shopTypes
    },

    getShopsPermissionData: state => {
      return state.shopsPermissionData
    },

    getDefaultShopsTypes: state => {
      return state.defaultShopsTypes
    },

    getShopBtn: state => {
      return state.shopBtn
    },
  },

  actions: {

    async fetchShops({commit, getters}, filter = '') {
      if(!getters.getNextShopPage)
        commit('setLoadingStart')

      try {
        return await API.get(`/shops${filter}`).then((response) =>{
          commit('setShops',{shops: response.data.data.data})
          commit('setShopsCommonList',{shopsCommonList: response.data.data})
          commit('setLoadingShopsEnd');

          return response;
        });
      } catch (e) {
        commit('setLoadingShopsEnd');
        return e;
      }
    },

    async fetchShopsHeartysan({commit, getters}, filter = '') {
      if(!getters.getNextShopPage)
        commit('setLoadingStart')

      try {
        return await API.get(`/heartysan/etsy/shops${filter}`).then((response) =>{
          commit('setShops',{shops: response.data.data})
          // commit('setShopsCommonList',{shopsCommonList: response.data.data})
          commit('setLoadingShopsEnd');

          return response;
        });
      } catch (e) {
        commit('setLoadingShopsEnd');
        return e;
      }
    },

    async fetchShopsHeartysanListings({commit, getters}, filter = '') {
      if(!getters.getNextShopPage)
        commit('setLoadingStart')

      try {
        return await API.get(`/heartysan/etsy/shops-having-listings${filter}`).then((response) =>{
          commit('setShops',{shops: response.data.data})
          // commit('setShopsCommonList',{shopsCommonList: response.data.data})
          commit('setLoadingShopsEnd');

          return response;
        });
      } catch (e) {
        commit('setLoadingShopsEnd');
        return e;
      }
    },

    async fetchShopsWithoutPermission({commit, getters}, filter = '') {
      if(!getters.getNextShopPage)
        commit('setLoadingStart')

      try {
        return await API.get(`/shops/public-list${filter}`).then((response) =>{
          commit('setShops',{shops: response.data.data.data})
          commit('setShopsCommonList',{shopsCommonList: response.data.data})
          commit('setLoadingShopsEnd');

          return response;
        });
      } catch (e) {
        commit('setLoadingShopsEnd');
        return e;
      }
    },


    async getShops({commit}, id) {
      commit('setLoadingStart');
      try {
        return await API.get(`/shops/${id}`).then((response) =>{
          console.log(response)
          commit('setShopsItem', {shopsItem: response.data.data,})
          commit('setLoadingShopsEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingStart');
        return e;
      }
    },

    async createShops({commit}, data) {
      commit('changeShopBtn');
      try {
        return await API.post(`/shops`, data).then((response) =>{
          console.log(response);
          // commit('setShops',{shops: shops,})
          commit('successShopBtn');
          return response;
        });
      } catch (e) {
        commit('successShopBtn');
        return e;
      }
    },

    async updateShops({commit}, {id, data}) {
      commit('changeShopBtn');
      try {
        return await API.put(`/shops/${id}`, data).then((response) =>{
          console.log(response);
          // commit('setShops',{shops: shops,})
          commit('successShopBtn');
          return response;
        });
      } catch (e) {
        commit('successShopBtn');
        return e;
      }
    },

    async deleteShops({commit}, id) {
      commit('changeShopBtn');
      try {
        return await API.delete(`/shops/${id}`).then((response) =>{
          commit('successShopBtn');
          return response;
        });
      } catch (e) {
        commit('successShopBtn');
        return e;
      }
    },

    async fetchShopsTypes({commit}, filter = '') {
      commit('setLoadingShopTypesStart');
      try {
        return await API.get(`/shops/shop-type${filter}`).then((response) =>{

          commit('setShopTypes', {shopTypes: response.data.data})
          commit('setLoadingShopTypesEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingShopTypesEnd');
        return e;
      }
    },



    async updateShopsTypes({commit}, {id, data}) {
      commit('changeShopTypes');
      try {
        return await API.put(`/shops/${id}`, data).then((response) =>{
          console.log(response);
          // commit('setShopTypes',{shopsTypes: response.shopsTypes,})
          commit('successShopTypes');
          return response;
        });
      } catch (e) {
        commit('successShopTypes');
        return e;
      }
    },


    async updateShopsStatus({commit}, {id, data}) {
      try {
        return await API.put(`/shops/${id}/update-shop-status`, data).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successShopTypes');
        return e;
      }
    },


    async getShopListing({commit}, data) {
      commit('changeShopBtn');
      try {
        return await API.post(`/shop-api/etsy/get-listing`, data).then((response) =>{
          console.log(response);
          // commit('setShops',{shops: shops,})
          commit('successShopBtn');
          return response;
        });
      } catch (e) {
        commit('successShopBtn');
        return e;
      }
    },

    async getShopNameListing({commit}, data) {
      commit('changeShopBtn');
      try {
        return await API.post(`/shop-api/etsy/get-shop-name-listing`, data).then((response) =>{
          console.log(response);
          // commit('setShops',{shops: shops,})
          commit('successShopBtn');
          return response;
        });
      } catch (e) {
        commit('successShopBtn');
        return e;
      }
    },

    async updateEtsyShopInfo({commit}, data) {
      commit('changeShopBtn');
      try {
        return await API.post(`/shops/update-etsy-shop-info`, data).then((response) =>{
          console.log(response);
          // commit('setShops',{shops: shops,})
          commit('successShopBtn');
          return response;
        });
      } catch (e) {
        commit('successShopBtn');
        return e;
      }
    },

    async getShopPermission({commit}, id) {
      // commit('changeShopBtn');
      commit('setShopsPermissionData',{
        shopId: id
      })
      try {
        return await API.get(`/shops/permission/${id}`).then((response) =>{
          console.log(response);
          commit('setShopsPermissionData',{
            shopsPermissionLink: response.data.data.shop_permission_link,
            shopId: id
          })
          // commit('successShopBtn');
          return response;
        });
      } catch (e) {
        commit('successShopBtn');
        return e;
      }
    },

    async saveShopPermission({commit, getters}, {data, id = false}) {
      commit('changeShopBtn');
      try {
        return await API.put(`/shops/permission/${id === false ? getters.getShopsPermissionData.shopId : id}`, data).then((response) =>{
          console.log(response);
          // commit('setShops',{shops: shops,})
          commit('successShopBtn');
          return response;
        });
      } catch (e) {
        commit('successShopBtn');
        return e;
      }
    },

    async saveShopPermissionAmazon({commit}, {data}) {
      commit('changeShopBtn');
      try {
        return await API.post(`/amazon/auth/callback`, data).then((response) =>{
          console.log(response);
          // commit('setShops',{shops: shops,})
          commit('successShopBtn');
          return response;
        });
      } catch (e) {
        commit('successShopBtn');
        return e;
      }
    },

    async deleteShopPermission({commit}, id) {
      commit('changeShopBtn');
      try {
        return await API.delete(`/shops/permission/${id}`).then((response) =>{
          console.log(response);
          // commit('setShops',{shops: shops,})
          commit('successShopBtn');
          return response;
        });
      } catch (e) {
        commit('successShopBtn');
        return e;
      }
    },


    async synchronizeShop({commit}, data) {
      try {
        return await API.post(`/plugins/shop/synchronize`, data).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successShopBtn');
        return e;
      }
    },

    async getExportShops({commit}, {filter , type}) {
      commit('setExportBtnLoadingStart')
      commit('setLoadingEnd')
      try {
        return await API.get(`/shops/export/${type}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

    async setShopsAmazonAPIForUsers({commit}, data) {
      try {
        return await API.post(`/users/shops/mass-set-load-external-orders`, data).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successShopBtn');
        return e;
      }
    },

    async setShopifyConnection({commit}, data) {
      try {
        return await API.post(`/shopify-app`, data).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successShopBtn');
        return e;
      }
    },
  }
}
