import {API} from "../../axios/axiosMainUrl";

export const dashboard = {
  state: {
    dashboard: [],
    dashboardItem: {},
    dashboardCommonList: [],
    dashboardBtn: false,
    dashboardLoading: true,
    nextDashboardPage: false,
    dashboardForPage: 25,
    dashboardFilter: '',
  },

  mutations: {
    setDashboardFilter: (state, payload) => {
      state.dashboardFilter = payload
    },

    setDashboard: (state, payload) => {
      if(state.nextDashboardPage) {
        state.dashboard = state.dashboard.concat(payload.dashboard)
      } else {
        state.dashboard = payload.dashboard
      }
    },

    setDashboardCommonList: (state, payload) => {
      state.dashboardCommonList = payload.dashboardCommonList
    },

    setNextDashboardPage: (state, payload) => {
      state.nextDashboardPage = payload
    },

    setDashboardItem: (state, payload) => {
      state.dashboardItem = payload.dashboardItem
    },

    setDashboardLoadingStart: (state) => {
      state.dashboardLoading = true
    },

    setDashboardLoadingEnd: (state) => {
      state.dashboardLoading = false
    },

    changeDashboardBtn: (state) => {
      state.dashboardBtn = true
    },
    successDashboardBtn: (state) => {
      state.dashboardBtn = false
    },
  },

  getters: {
    getDashboardFilter: state => {
      return state.dashboardFilter
    },

    getDashboardForPage: state => {
      return state.dashboardForPage
    },

    getDashboard: state => {
      return state.dashboard
    },

    getDashboardLoading: state => {
      return state.dashboardLoading
    },


    getNextDashboardPage: state => {
      return state.nextDashboardPage
    },

    getDashboardItem: state => {
      return state.dashboardItem
    },

    getDashboardCommonList: state => {
      return state.dashboardCommonList
    },

    getDashboardBtn: state => {
      return state.dashboardBtn
    },
  },

  actions: {

    async fetchDashboard({commit, getters}, filter = '') {
      if(!getters.getNextDashboardPage)
        commit('setDashboardLoadingStart')
      try {
        return await API.get(`/dashboard${filter}`).then((response) =>{
          commit('setDashboard',{dashboard: response.data.data.data})
          commit('setDashboardCommonList',{dashboardCommonList: response.data.data})
          commit('setDashboardLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setDashboardLoadingEnd');
        return e;
      }
    },

    async getDashboard({commit}, id) {
      commit('setDashboardLoadingStart');
      try {
        return await API.get(`/dashboard/${id}`).then((response) =>{
          console.log(response)
          commit('setDashboardItem', {dashboardItem: response.data.data})
          commit('setDashboardLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setDashboardLoadingEnd');
        return e;
      }
    },

    async createDashboard({commit}, data) {
      commit('changeDashboardBtn');
      try {
        return await API.post(`/dashboard`, data).then((response) =>{
          commit('successDashboardBtn');
          return response;
        });
      } catch (e) {
        commit('successDashboardBtn');
        return e;
      }
    },

    async updateDashboard({commit}, {id, data}) {
      commit('changeDashboardBtn');
      try {
        return await API.put(`/dashboard/${id}`, data).then((response) =>{
          commit('successDashboardBtn');
          return response;
        });
      } catch (e) {
        commit('successDashboardBtn');
        return e;
      }
    },

    async deleteDashboard({commit}, id) {
      try {
        return await API.delete(`/dashboard/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successDashboardBtn');
        return e;
      }
    },

    /**
     * Get common dashboard data
     */

    async getDashboardData({commit}) {
      try {
        return await API.get(`/common/dashboard`).then((response) => {
          return response;
        });
      } catch (e) {
        commit('setDashboardLoadingEnd');
        return e;
      }
    },

    /**
     * Get Dashboard Draggable Block Data
     */

    async getDashboardDraggableBlockData({commit}) {
      try {
        return await API.get(`/common/dashboard-summary-items`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setDashboardLoadingEnd');
        return e;
      }
    },

    /**
     * Change Sort Dashboard Draggable Block Data
     */

    async changeSortDashboardDraggableBlockData({commit}, data) {
      try {
        return await API.post(`/common/dashboard-summary-items/change-sort`, data).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setDashboardLoadingEnd');
        return e;
      }
    },

    async getReconnectShopData({commit}) {
      try {
        return await API.get(`/system-notifications`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setDashboardLoadingEnd');
        return e;
      }
    },

    async deleteReconnectShopData({commit}, id) {
      try {
        return await API.delete(`/system-notifications/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setDashboardLoadingEnd');
        return e;
      }
    },

  }
}
