import { render, staticRenderFns } from "./NotAuthLayout.vue?vue&type=template&id=aad89732&scoped=true"
import script from "./NotAuthLayout.vue?vue&type=script&lang=js"
export * from "./NotAuthLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aad89732",
  null
  
)

export default component.exports