import {API} from "../../axios/axiosMainUrl";

const prefix = '/user-balance/transaction'
const prefixRequest = '/user-balance/payoneer-request'

export const newPaymentsPayoneer = {
  state: {
    newPaymentsPayoneer: [],
    newPaymentsPayoneerItem: {},
    newPaymentsPayoneerCommonList: [],
    newPaymentsPayoneerBtn: false,
    newPaymentsPayoneerLoading: true,
    nextNewPaymentsPayoneerPage: false,
    newPaymentsPayoneerForPage: 25,
    newPaymentsPayoneerFilter: '',
  },

  mutations: {
    setNewPaymentsPayoneerFilter: (state, payload) => {
      state.newPaymentsPayoneerFilter = payload
    },

    setNewPaymentsPayoneer: (state, payload) => {
      if(state.nextNewPaymentsPayoneerPage) {
        state.newPaymentsPayoneer = state.newPaymentsPayoneer.concat(payload.newPaymentsPayoneer)
      } else {
        state.newPaymentsPayoneer = payload.newPaymentsPayoneer
      }
    },

    setNewPaymentsPayoneerCommonList: (state, payload) => {
      state.newPaymentsPayoneerCommonList = payload.newPaymentsPayoneerCommonList
    },

    setNextNewPaymentsPayoneerPage: (state, payload) => {
      state.nextNewPaymentsPayoneerPage = payload
    },

    setNewPaymentsPayoneerItem: (state, payload) => {
      state.newPaymentsPayoneerItem = payload.newPaymentsPayoneerItem
    },

    setNewPaymentsPayoneerLoadingStart: (state) => {
      state.newPaymentsPayoneerLoading = true
    },

    setNewPaymentsPayoneerLoadingEnd: (state) => {
      state.newPaymentsPayoneerLoading = false
    },

    changeNewPaymentsPayoneerBtn: (state) => {
      state.newPaymentsPayoneerBtn = true
    },
    successNewPaymentsPayoneerBtn: (state) => {
      state.newPaymentsPayoneerBtn = false
    },
  },

  getters: {
    getNewPaymentsPayoneerFilter: state => {
      return state.newPaymentsPayoneerFilter
    },

    getNewPaymentsPayoneerForPage: state => {
      return state.newPaymentsPayoneerForPage
    },

    getNewPaymentsPayoneer: state => {
      return state.newPaymentsPayoneer
    },

    getNewPaymentsPayoneerLoading: state => {
      return state.newPaymentsPayoneerLoading
    },


    getNextNewPaymentsPayoneerPage: state => {
      return state.nextNewPaymentsPayoneerPage
    },

    getNewPaymentsPayoneerItem: state => {
      return state.newPaymentsPayoneerItem
    },

    getNewPaymentsPayoneerCommonList: state => {
      return state.newPaymentsPayoneerCommonList
    },

    getNewPaymentsPayoneerBtn: state => {
      return state.newPaymentsPayoneerBtn
    },
  },

  actions: {

    async fetchNewPaymentsPayoneer({commit, getters}, filter = '') {
      if(!getters.getNextNewPaymentsPayoneerPage)
        commit('setNewPaymentsPayoneerLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setNewPaymentsPayoneer',{newPaymentsPayoneer: response.data.data.data})
          // commit('setNewPaymentsPayoneerCommonList',{newPaymentsPayoneerCommonList: response.data.data})
          commit('setNewPaymentsPayoneerCommonList',{newPaymentsPayoneerCommonList: {...response.data.data.meta, ...{
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev
              }}})
          commit('setNewPaymentsPayoneerLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setNewPaymentsPayoneerLoadingEnd');
        return e;
      }
    },

    async fetchPayoneerRequest({commit, getters}, filter = '') {
      if(!getters.getNextNewPaymentsPayoneerPage)
        commit('setNewPaymentsPayoneerLoadingStart')
      try {
        return await API.get(`${prefixRequest}${filter}`).then((response) =>{
          commit('setNewPaymentsPayoneer',{newPaymentsPayoneer: response.data.data.data})
          // commit('setNewPaymentsPayoneerCommonList',{newPaymentsPayoneerCommonList: response.data.data})
          commit('setNewPaymentsPayoneerCommonList',{newPaymentsPayoneerCommonList: {...response.data.data.meta, ...{
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev
              }}})
          commit('setNewPaymentsPayoneerLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setNewPaymentsPayoneerLoadingEnd');
        return e;
      }
    },

    async getNewPaymentsPayoneer({commit}, id) {
      // commit('setNewPaymentsPayoneerLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setNewPaymentsPayoneerItem', {newPaymentsPayoneerItem: response.data.data})
          // commit('setNewPaymentsPayoneerLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setNewPaymentsPayoneerLoadingEnd');
        return e;
      }
    },

    async createNewPaymentsPayoneer({commit}, data) {
      commit('changeNewPaymentsPayoneerBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successNewPaymentsPayoneerBtn');
          return response;
        });
      } catch (e) {
        commit('successNewPaymentsPayoneerBtn');
        return e;
      }
    },

    async updateNewPaymentsPayoneer({commit}, {id, data}) {
      commit('changeNewPaymentsPayoneerBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successNewPaymentsPayoneerBtn');
          return response;
        });
      } catch (e) {
        commit('successNewPaymentsPayoneerBtn');
        return e;
      }
    },

    async deleteNewPaymentsPayoneer({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successNewPaymentsPayoneerBtn');
        return e;
      }
    },

    async payNewPaymentsPayoneer({commit}, id) {
      commit('changeNewPaymentsBtn');
      try {
        return await API.post(`${prefix}/${id}/pay`).then((response) =>{
          commit('successNewPaymentsBtn');
          return response;
        });
      } catch (e) {
        commit('successNewPaymentsBtn');
        return e;
      }
    },

    async getExportNewPaymentsPayoneer({commit}, {filter , type}) {
      commit('setExportBtnLoadingStart')
      commit('setLoadingEnd')
      try {
        return await API.get(`${prefix}/export/${type}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

    async getExportCheckPayoneer({commit}, {filter , type}) {
      commit('setExportBtnLoadingStart')
      commit('setLoadingEnd')
      try {
        return await API.get(`${prefixRequest}/export/${type}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

    async createCheckPayoneer({commit}, data) {
      commit('changeNewPaymentsPayoneerBtn');
      try {
        return await API.post(`${prefixRequest}`, data).then((response) =>{
          commit('successNewPaymentsPayoneerBtn');
          return response;
        });
      } catch (e) {
        commit('successNewPaymentsPayoneerBtn');
        return e;
      }
    },

    async deleteCheckPayoneer({commit}, id) {
      try {
        return await API.delete(`${prefixRequest}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successNewPaymentsPayoneerBtn');
        return e;
      }
    },
  }
}
