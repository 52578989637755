import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const NewPaymentsTable = () => import(/* webpackChunkName: "group-new-payments" */ './components/NewPaymentsTable/NewPaymentsTable.vue')
const NewPaymentsCreate = () => import(/* webpackChunkName: "group-new-payments" */ './components/NewPaymentsCreate/NewPaymentsCreate.vue')
const NewPaymentsEdit = () => import(/* webpackChunkName: "group-new-payments" */ './components/NewPaymentsEdit/NewPaymentsEdit.vue')
const moduleKey = 'new-payments'

export const NewPaymentsRoutes = [
  {
    path: routerPaths.financeNewPayment,
    component: NewPaymentsTable,
    meta: {
      breadcrumbs: {
        key: [
          'finance',
          'financeNewPayment'
        ],
        items: {
          'finance': {
            name: 'menu_finance',
            link: routerPaths.financeNewPayment,
          },
          'financeNewPayment': {
            name: 'menu_financeNewPayments',
            link: routerPaths.financeNewPayment,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.OTHER_PAYMENT_MANAGE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_financeNewPayments'
    },
  },
  {
    path: routerPaths.financeNewPaymentCreate,
    component: NewPaymentsCreate,
    meta: {
      breadcrumbs: {
        key: [
          'finance',
          'financeNewPayment',
          'financeNewPaymentCreate'
        ],
        items: {
          'finance': {
            name: 'menu_finance',
            link: routerPaths.financeNewPayment,
          },
          'financeNewPayment': {
            name: 'menu_financeNewPayments',
            link: routerPaths.financeNewPayment,
          },
          'financeNewPaymentCreate': {
            name: 'menu_financeNewPaymentsCreate',
            link: routerPaths.financeNewPaymentCreate,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.OTHER_PAYMENT_MANAGE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_financeNewPaymentsCreate'
    },
  },


  {
    path: routerPaths.financeNewPaymentEdit,
    component: NewPaymentsEdit,
    meta: {
      breadcrumbs: {
        key: [
          'finance',
          'financeNewPayment',
          'financeNewPaymentEdit'
        ],
        items: {
          'finance': {
            name: 'menu_finance',
            link: routerPaths.financeNewPayment,
          },
          'financeNewPayment': {
            name: 'menu_financeNewPayments',
            link: routerPaths.financeNewPayment,
          },
          'financeNewPaymentEdit': {
            name: 'breadcrumbs_editNewPayments',
            link: routerPaths.financeNewPaymentCreate,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.OTHER_PAYMENT_MANAGE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_editNewPayments'
    },
  },
]
