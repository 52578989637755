
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const PromoCodeTable = () => import(/* webpackChunkName: "group-promo-code" */ './components/PromoCodeTable/PromoCodeTable.vue')
const moduleKey = 'consolidation-users'

export const PromoCodeRoutes = [
  {
    path: routerPaths.mainSettingsPromoCode,
    component: PromoCodeTable,
    meta: {
      breadcrumbs: {
        key: [
          'menu_settings',
          'mainSettingsPromoCode'
        ],
        items: {
          'menu_settings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsPromoCode,
          },
          'mainSettingsPromoCode': {
            name: 'menu_PromoCode',
            link: routerPaths.mainSettingsPromoCode,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.PROMOCODE_SETTINGS],

      moduleKey: moduleKey,
      pageTitle: 'menu_PromoCode'
    },
  }
]
