<template>
  <div class="auth-layout">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: "AuthLayout"
  }
</script>

<style scoped>

</style>
