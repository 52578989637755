
import {routerPaths} from '../../../router/routerPaths'
import permissions from "@/router/middleware/permissions";
import {PERMISSIONS} from "@/staticData/permissionsStatic";

const NegativeBalanceTable = () => import(/* webpackChunkName: "group-other-shippers" */ './components/NegativeBalanceTableBlock/NegativeBalanceTableBlock.vue')
const moduleKey = 'negative-balance'


export const NegativeBalanceRoutes = [
  {
    path: routerPaths.negativeBalance,
    component: NegativeBalanceTable,
    meta: {
      breadcrumbs: {
        key: [
          'menu_finance',
          'mainSettingsNegativeBalance'
        ],
        items: {
          'menu_finance': {
            name: 'menu_finance',
            link: routerPaths.negativeBalance,
          },
          'mainSettingsNegativeBalance': {
            name: 'menu_negativeBalance',
            link: routerPaths.negativeBalance,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.NEGATIVE_BALANCE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_negativeBalance'
    },
  }
]
