<template>
  <div class="default-checkbox"
       :class="{
          'empty-label' : !label,
          'default-checkbox__big-checkbox-style' : bigCheckboxStyle
        }"
  >
    <input type="checkbox"
           class="default-checkbox__input"
           :id="'default-checkbox-' + id"
           :checked="checked"
           :disabled="disabled"
           @change="change"
           :data-row-name="dataValue"
           :data-order-id="dataOrderId ? dataOrderId : false"
    >
    <label :for="'default-checkbox-' + id" class="default-checkbox__label">
      {{ label }}

      <slot name="content">

      </slot>
    </label>

    <div class="default-checkbox__help-text">
      {{ helpText }}
    </div>

    <span class="default-checkbox__error error-field" v-if="error && errorTxt">{{errorTxt}}</span>
  </div>
</template>

<script>
  export default {
    name: "DefaultCheckbox",

    data () {
      return {
        id: null,
        checked: this.value,
      }
    },

    props: [
      'label',
      'value',
      'helpText',
      'errorTxt',
      'error',
      'disabled',
      'selectedNow',
      'dataValue',
      'bigCheckboxStyle',
      'dataOrderId',
    ],

    mounted () {
      this.id = this._uid
    },

    methods: {
      changeCheckbox(val){
        this.$emit('change', val.target.value);
      },

      change: function() {
        this.checked = !this.checked;
        this.$emit('input', this.checked);
        this.$emit('changeCheckbox', this.checked);
      }
    },

    watch: {
      value: function(newVal) {
        this.checked = newVal
      },
      selectedNow: function(newVal) {
        this.checked = newVal
      },
    }
  }
</script>

<style lang="scss">
  @import "../../../../scss/mixins/mixins";
  @import "../../../../scss/colors";


  .default-checkbox{
    position: relative;
    width: fit-content;

    &__input{
      display: none;
    }

    &.block &__label{
      display: block;
    }

    &.bold &__label{
      font-weight: 500;
    }

    &__label{
      position: relative;
      padding-left: 24px;
      cursor: pointer;
      font-size: 14px;
      line-height: normal;
      display: flex;


      &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 15px;
        height: 15px;
        border: 2px solid #BBAD9C;
        box-sizing: border-box;
        border-radius: 3px;
        display: flex;
      }


      &:after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 15px;
        height: 15px;
        border: 1px solid transparent;
        border-radius: 3px;
        display: flex;
        background-image: url("../../../../assets/img/UI-group/white-check.svg");
        background-repeat: no-repeat;
        background-position: center;
        /*background-size: 11px;*/
        background-size: contain;
        opacity: 0;
      }
    }

    &.white &__label{

      &:before{
        background: white;
        box-shadow: 0px 0px 1px 0px white;
      }

      &:after{

      }
    }

    &.brown-border &__label {
      &:before {
        border: 2px solid $brown;
      }
    }

    &__input:checked + &__label{
      &:before{
        background-color: $brown;
        border-color: $brown;
      }

      &:after{
        opacity: 1;
      }
    }

    &__input:disabled + &__label{
      cursor: default;

      &:before{
        opacity: 0.4;
      }
    }

    &__help-text{
      position: absolute;
      top: 100%;
      left: 24px;
      font-size: 12px;
      line-height: 18px;
      color: #8F7A61;
    }

    &__error{
      position: absolute;
      bottom: -18px;
      left: 0;
      font-size: 11px;
      line-height: 13px;
      color: $orange;
    }

    &__big-checkbox-style{
      .default-checkbox__label{
        &:before{
          left: 0;
          top: 0;
          width: 19px;
          height: 19px;
        }

        &:after{
          left: 0;
          top: 0;
          width: 19px;
          height: 19px;
        }
      }
    }
  }

  .empty-label{
    min-height: 16px;
  }

</style>
