<template>
  <div class="fragment">

    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('account_personalInformation.localization_value.value')}}</b></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('common_email.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_personal_contact.email'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_personal_contact.email'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('common_name.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_personal_contact.name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_personal_contact.name'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('common_surName.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_personal_contact.last_name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_personal_contact.last_name'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('profile_fullName.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_personal_contact.cyrillic_full_name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_personal_contact.cyrillic_full_name'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('profile_companyName.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_personal_contact.company_name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_personal_contact.company_name'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('userModule_Phone1.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_personal_contact.contact_phones.0.phone_number'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_personal_contact.contact_phones.0.phone_number'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('userModule_Phone2.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_personal_contact.contact_phones.1.phone_number'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_personal_contact.contact_phones.1.phone_number'"/></div>
    </div>
<!--    <div class="history-table__sub-row">-->
<!--      <div class="history-table__sub-col">{{ $t('userModule_Balance.localization_value.value') }}</div>-->
<!--      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user.balance'"/></div>-->
<!--      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user.balance'"/></div>-->
<!--    </div>-->

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('profile_aditionalEmail.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_personal_contact.additional_email'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_personal_contact.additional_email'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('profile_vat.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.vat_number'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.vat_number'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('profile_birthday.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_personal_contact.birthday'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_personal_contact.birthday'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('common_address.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_personal_contact.address'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_personal_contact.address'"/></div>
    </div>
<!--    <div class="history-table__sub-row">-->
<!--      <div class="history-table__sub-col">{{ $t('common_cityCyrillic.localization_value.value') }}</div>-->
<!--      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.address_information.contacts.address_lines'"/></div>-->
<!--      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.address_information.contacts.address_lines'"/></div>-->
<!--    </div>-->
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('common_city.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_personal_contact.city'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_personal_contact.city'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('common_regions.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_personal_contact.region.name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_personal_contact.region.name'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('common_country.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_personal_contact.country.name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_personal_contact.country.name'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('common_postNumber.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_personal_contact.zip'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_personal_contact.zip'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('userModule_ITNCode.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.itn_number'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.itn_number'"/></div>
    </div>

    <template v-if="pevItem && pevItem.history.phones && pevItem.history.phones.length > 0">
      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{ $t('userModule_Phone.localization_value.value') }}</div>
        <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_personal_contact.phone'"/></div>
        <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_personal_contact.phone'"/></div>
      </div>
    </template>


    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('userModule_AccessFunctionality.localization_value.value')}}</b></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('userModule_BalanceMoney.localization_value.value')}}</b></div>
    </div>


    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_ATM.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox
            v-if="pevItem && pevItem.history.user_setting"
            :value="pevItem.history.user_setting.atm_output === '1'"
            :disabled="true"
        />
        <template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox
            v-if="item && item.history.user_setting"
            :value="item.history.user_setting.atm_output === '1'"
            :disabled="true"
        />
        <template v-else>—</template>
      </div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('common_ChangePassword.localization_value.value')}}
      </div>
      <div class="history-table__sub-col justify-content-end">
<!--        <DefaultCheckbox-->
<!--            v-if="pevItem && pevItem.history.password"-->
<!--            :value="pevItem.history.password !== item.history.password"-->
<!--            :disabled="true"-->
<!--        />-->
        <template
            v-if="pevItem && pevItem.history.password">
          <StatusIcoBtn :type="'active'" class="mr-5" v-if="pevItem.history.password !== item.history.password"/>
          <StatusIcoBtn :type="'cancel-red'" class="mr-5" v-else/>
        </template>
        <template v-else>—</template>
      </div>
      <div class="history-table__sub-col"></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('userModule_MaxWithdrawTime.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_setting.atm_limit_for_one_time'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_setting.atm_limit_for_one_time'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('userModule_MaxWithdrawWeek.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_setting.atm_limit_for_week'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_setting.atm_limit_for_week'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('userModule_MaxWithdrawDay.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_setting.atm_limit_for_per_day'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_setting.atm_limit_for_per_day'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('userModule_MaxWithdrawPercent.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_setting.atm_limit_max_percent'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_setting.atm_limit_max_percent'"/></div>
    </div>


    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('userModule_Status.localization_value.value')}}</b></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_NewUser.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox
            v-if="pevItem"
            :value="pevItem.history.user_setting.n_mark === 1"
            :disabled="true"
        />
        <template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox
            v-if="item"
            :value="item.history.user_setting.n_mark === 1"
            :disabled="true"
        />
        <template v-else>—</template>
      </div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('userModule_Fullfilment.localization_value.value')}}</b></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_amazon.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem" :value="pevItem.history.fulfilment_by_amazon === 1" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.fulfilment_by_amazon === 1" :disabled="true"/><template v-else>—</template>
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_fulfillmentMerchant.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem" :value="pevItem.history.fulfilment_by_merchant === 1" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.fulfilment_by_merchant === 1" :disabled="true"/><template v-else>—</template>
      </div>
    </div>


    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('userModule_trackNumbers.localization_value.value')}}</b></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_sendToEtsy.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem && _.has(pevItem.history, 'user_setting')" :value="pevItem.history.user_setting.send_track_number_etsy === '1'" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.user_setting.send_track_number_etsy === '1'" :disabled="true"/><template v-else>—</template>
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_sendToAmazon.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem && _.has(pevItem.history, 'user_setting')" :value="pevItem.history.user_setting.send_track_number_amazon === '1'" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.user_setting.send_track_number_amazon === '1'" :disabled="true"/><template v-else>—</template>
      </div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('userModule_platformsPermissions.localization_value.value')}}</b></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_etsyApiToEmail.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem && _.has(pevItem.history, 'user_setting')" :value="pevItem.history.user_setting.receive_etsy_information_email === '1'" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.user_setting.receive_etsy_information_email === '1'" :disabled="true"/><template v-else>—</template>
      </div>
    </div>


    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>Others</b></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_paypalPay.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem && _.has(pevItem.history, 'user_setting')" :value="pevItem.history.user_setting.use_paypal === 1" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.user_setting.use_paypal === 1" :disabled="true"/><template v-else>—</template>
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_useKITGroup.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem && _.has(pevItem.history, 'user_setting')" :value="pevItem.history.user_setting.use_kit_group_bank" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.user_setting.use_kit_group_bank" :disabled="true"/><template v-else>—</template>
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_showPayPalButton.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem && _.has(pevItem.history, 'user_setting')" :value="pevItem.history.user_setting.use_paypal_button" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.user_setting.use_paypal_button" :disabled="true"/><template v-else>—</template>
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_dontBlockForNB.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem && _.has(pevItem.history, 'user_setting')" :value="pevItem.history.user_setting.allow_access_with_negative_balance === 1" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.user_setting.allow_access_with_negative_balance === 1" :disabled="true"/><template v-else>—</template>
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_consolidationPrepayment.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem && _.has(pevItem.history, 'user_setting')" :value="pevItem.history.user_setting.consolidation_order_prepayment" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.user_setting.consolidation_order_prepayment" :disabled="true"/><template v-else>—</template>
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_GoogleAuthentication.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem && _.has(pevItem.history, 'user_setting')" :value="pevItem.history.user_setting.allow_access_without_google_auth" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.user_setting.allow_access_without_google_auth" :disabled="true"/><template v-else>—</template>
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_paymentListings.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem && _.has(pevItem.history, 'user_setting')" :value="pevItem.history.user_setting.use_payment_listing" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.user_setting.use_payment_listing" :disabled="true"/><template v-else>—</template>
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_orderExpertUserInterface.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem && _.has(pevItem.history, 'user_setting')" :value="pevItem.history.user_setting.express_order_template_type" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.user_setting.express_order_template_type" :disabled="true"/><template v-else>—</template>
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_withdrawFountWithoutTracking.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem && _.has(pevItem.history, 'user_setting')" :value="pevItem.history.user_setting.withdraw_without_tracking === '1'" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.user_setting.withdraw_without_tracking === '1'" :disabled="true"/><template v-else>—</template>
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('userModule_MaxWithoutTrackingDayLimit.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_setting.withdraw_without_tracking_day_limit'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_setting.withdraw_without_tracking_day_limit'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_BlockUser.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem" :value="pevItem.history.locked" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.locked" :disabled="true"/><template v-else>—</template>
      </div>
    </div>


    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{ $t('userModule_UsersPresets.localization_value.value') }}</b></div>
    </div>


    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_OrderFeesByFormula.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem && _.has(pevItem.history, 'user_setting')" :value="pevItem.history.user_setting.autodetect_fee === 1" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.user_setting.autodetect_fee === 1" :disabled="true"/><template v-else>—</template>
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('userModule_SkladUSAFees.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_setting.order_process_fee.cost'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_setting.order_process_fee.cost'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_UseEasyPost.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem && _.has(pevItem.history, 'user_setting')" :value="pevItem.history.user_setting.order_fbm_self_create_label === '1'" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.user_setting.order_fbm_self_create_label === '1'" :disabled="true"/><template v-else>—</template>
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('userModule_Commission.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_setting.user_create_label_fee.cost'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_setting.user_create_label_fee.cost'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('userModule_fbaAccountType.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_setting.order_fba_process_fee.cost'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_setting.order_fba_process_fee.cost'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('userModule_CommissionSkladUSA.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.commission'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.commission'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('userModule_orderFeeForManyProducts.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_setting.user_order_item_fee.item_fee'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_setting.user_order_item_fee.item_fee'"/></div>
    </div>


    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{ $t('userModule_Shippers.localization_value.value') }}</b></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_ChangeThePriceExpress.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem && _.has(pevItem.history, 'user_setting')" :value="pevItem.history.user_setting.change_order_express_price === '1'" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.user_setting.change_order_express_price === '1'" :disabled="true"/><template v-else>—</template>
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_AllowDHL.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem && _.has(pevItem.history, 'user_setting')" :value="pevItem.history.user_setting.dhl === '1'" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.user_setting.dhl === '1'" :disabled="true"/><template v-else>—</template>
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_DHLNoTransactions.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem && _.has(pevItem.history, 'user_setting')" :value="pevItem.history.user_setting.dhl_no_transaction === '1'" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.user_setting.dhl_no_transaction === '1'" :disabled="true"/><template v-else>—</template>
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_AllowTNT.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem && _.has(pevItem.history, 'user_setting')" :value="pevItem.history.user_setting.tnt === '1'" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.user_setting.tnt === '1'" :disabled="true"/><template v-else>—</template>
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_TNTAallCountries.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem && _.has(pevItem.history, 'user_setting')" :value="pevItem.history.user_setting.tnt_all_countries === '1'" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.user_setting.tnt_all_countries === '1'" :disabled="true"/><template v-else>—</template>
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_TNTNoTransactions.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem && _.has(pevItem.history, 'user_setting')" :value="pevItem.history.user_setting.tnt_no_transaction === '1'" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.user_setting.tnt_no_transaction === '1'" :disabled="true"/><template v-else>—</template>
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        FedEx
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem && _.has(pevItem.history, 'user_setting')" :value="pevItem.history.user_setting.fedex === '1'" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.user_setting.fedex === '1'" :disabled="true"/><template v-else>—</template>
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('userModule_FedExNoTransactions.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem && _.has(pevItem.history, 'user_setting')" :value="pevItem.history.user_setting.fedex_no_transaction === '1'" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.user_setting.fedex_no_transaction === '1'" :disabled="true"/><template v-else>—</template>
      </div>
    </div>


    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{ $t('userModule_Documents.localization_value.value') }}</b></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>Driver License</b></div>
    </div>


    <template v-for="(file, index) in item.history.files">
      <div class="history-table__sub-row" :key="index" v-if="(file.type === 'big_image' || file.extension === 'pdf') && file.group === 'diver_license_documents'">
        <div class="history-table__sub-col">File</div>
        <div class="history-table__sub-col">
          <template v-if="pevItem && _.has(pevItem.history.files, index)"><FileBlock :files="pevItem.history.files" :file="pevItem.history.files[index]"/></template>
          <template v-else>—</template>
        </div>
        <div class="history-table__sub-col">
          <template><FileBlock :files="item.history.files" :file="file"/></template>
        </div>
      </div>
    </template>

    <template v-if="pevItem && pevItem.history && pevItem.history.files.length > item.history.files.length">
      <template v-for="(file, index) in pevItem.history.files">
        <div class="history-table__sub-row" :key="index"
             v-if="(file.type === 'big_image' || file.extension === 'pdf') && file.group === 'diver_license_documents' && index > item.history.files.length - 1">
          <div class="history-table__sub-col">File</div>
          <div class="history-table__sub-col">
            <template><FileBlock :files="pevItem.history.files" :file="file"/></template>
<!--            <template v-else>—</template>-->
          </div>
          <div class="history-table__sub-col">
            —
          </div>
        </div>
      </template>
    </template>


    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>Passport</b></div>
    </div>
    <template v-for="(file, index) in item.history.files">
      <div class="history-table__sub-row" :key="index" v-if="(file.type === 'big_image' || file.extension === 'pdf') && file.group === 'user_passport_documents'">
        <div class="history-table__sub-col">File</div>
        <div class="history-table__sub-col">
          <template v-if="pevItem && _.has(pevItem.history.files, index)"><FileBlock :files="pevItem.history.files" :file="pevItem.history.files[index]"/></template>
          <template v-else>—</template>
        </div>
        <div class="history-table__sub-col">
          <template><FileBlock :files="item.history.files" :file="file"/></template>
        </div>
      </div>
    </template>

    <template v-if="pevItem && pevItem.history && pevItem.history.files.length > item.history.files.length">
      <template v-for="(file, index) in pevItem.history.files">
        <div class="history-table__sub-row" :key="index"
             v-if="(file.type === 'big_image' || file.extension === 'pdf') && file.group === 'user_passport_documents' && index > item.history.files.length - 1">
          <div class="history-table__sub-col">File</div>
          <div class="history-table__sub-col">
            <template><FileBlock :files="pevItem.history.files" :file="file"/></template>
<!--            <template v-else>—</template>-->
          </div>
          <div class="history-table__sub-col">
            —
          </div>
        </div>
      </template>
    </template>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('userModule_MarkAsChecked.localization_value.value') }}</div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem && _.has(pevItem.history, 'user_setting')" :value="pevItem.history.user_setting.documents_checked === 1" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.user_setting.documents_checked === 1" :disabled="true"/><template v-else>—</template>
      </div>
    </div>

  </div>
</template>

<script>
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import FileBlock from "@/components/coreComponents/Popups/HistoryOfChangesPopup/chunks/FileBlock/FileBlock";
import StatusIcoBtn from "@/components/UI/status/StatusIcoBtn/StatusIcoBtn";
export default {
  name: "UserSettingsType",
  components: {
    StatusIcoBtn, FileBlock,
    DefaultCheckbox, ValueHelper},

  props: {
    pevItem: Object,
    item: Object,
  },

}
</script>

<style scoped>

</style>