import {routerPaths} from '../../../router/routerPaths'

const OrderBinTable = () => import(/* webpackChunkName: "group-order-bin-table" */ './components/OrderBinTable/OrderBinTable.vue')
const moduleKey = 'order-bin'

export const OrderBinRoutes = [
  {
    path: routerPaths.settingsOrderBin,
    component: OrderBinTable,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'settingsOrderBin'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.settingsOrderBin,
          },
          'settingsOrderBin': {
            name: 'menu_orderBin',
            link: routerPaths.settingsOrderBin,
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'menu_orderBin'
    },
  },
]
