import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const ScanBarcode = () => import(/* webpackChunkName: "group-thermal-print" */ './components/ScanBarcode/ScanBarcode.vue')
const moduleKey = 'scan-barcode'

export const ScanBarcodeRoutes = [
  {
    path: routerPaths.scanBarcode,
    component: ScanBarcode,
    meta: {
      breadcrumbs: {
        key: [
          'scanBarcode'
        ],
        items: {
          'scanBarcode': {
            name: 'menu_scanBarcode',
            link: routerPaths.scanBarcode,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.SCAN_BARCODE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_scanBarcode'
    },
  },
]
