
import {routerPaths} from '../../../router/routerPaths'

const ShopsTablePage = () => import(/* webpackChunkName: "group-shops" */ './components/ShopsTablePage/ShopsTablePage.vue')
const ShopsCreation = () => import(/* webpackChunkName: "group-shops" */ './components/ShopsCreation/ShopsCreation.vue')
const ShopsEditing = () => import(/* webpackChunkName: "group-shops" */ './components/ShopsEditing/ShopsEditing.vue')
const moduleKey = 'shops'

export const ShopsRoutes = [
  {
    path: routerPaths.mainSettingsShops,
    component: ShopsTablePage,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'mainSettingsShops'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsShops,
          },
          'mainSettingsShops': {
            name: 'menu_shops',
            link: routerPaths.mainSettingsShops,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'menu_shops'
    },
  },

  {
    path: routerPaths.mainSettingsShopsCreate,
    component: ShopsCreation,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'mainSettingsShops',
          'mainSettingsShopsCreate'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsShops,
          },
          'mainSettingsShops': {
            name: 'menu_shops',
            link: routerPaths.mainSettingsShops,
          },
          'mainSettingsShopsCreate': {
            name: 'breadcrumbs_NewShop',
            link: routerPaths.mainSettingsShopsCreate,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewShop'
    },
  },

  {
    path: routerPaths.mainSettingsShopsEdit,
    component: ShopsEditing,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'mainSettingsShops',
          'mainSettingsShopsCreate'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsShops,
          },
          'mainSettingsShops': {
            name: 'menu_shops',
            link: routerPaths.mainSettingsShops,
          },
          'mainSettingsShopsCreate': {
            name: 'breadcrumbs_EditShop',
            link: routerPaths.mainSettingsShopsCreate,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_EditShop'
    },
  },
]
