import {API} from "../../axios/axiosMainUrl";

let prefix = '/settings/fresh-chat'

export const freshChat = {
  state: {
    freshChat: [],
    freshChatItem: {},
    freshChatCommonList: [],
    freshChatBtn: false,
    freshChatLoading: true,
    nextFreshChatPage: false,
    freshChatForPage: 25,
    freshChatFilter: '',
  },

  mutations: {
    setFreshChatFilter: (state, payload) => {
      state.freshChatFilter = payload
    },

    setFreshChat: (state, payload) => {
      if(state.nextFreshChatPage) {
        state.freshChat = state.freshChat.concat(payload.freshChat)
      } else {
        state.freshChat = payload.freshChat
      }
    },

    setFreshChatCommonList: (state, payload) => {
      state.freshChatCommonList = payload.freshChatCommonList
    },

    setNextFreshChatPage: (state, payload) => {
      state.nextFreshChatPage = payload
    },

    setFreshChatItem: (state, payload) => {
      state.freshChatItem = payload.freshChatItem
    },

    setFreshChatLoadingStart: (state) => {
      state.freshChatLoading = true
    },

    setFreshChatLoadingEnd: (state) => {
      state.freshChatLoading = false
    },

    changeFreshChatBtn: (state) => {
      state.freshChatBtn = true
    },
    successFreshChatBtn: (state) => {
      state.freshChatBtn = false
    },
  },

  getters: {
    getFreshChatFilter: state => {
      return state.freshChatFilter
    },

    getFreshChatForPage: state => {
      return state.freshChatForPage
    },

    getFreshChat: state => {
      return state.freshChat
    },

    getFreshChatLoading: state => {
      return state.freshChatLoading
    },


    getNextFreshChatPage: state => {
      return state.nextFreshChatPage
    },

    getFreshChatItem: state => {
      return state.freshChatItem
    },

    getFreshChatCommonList: state => {
      return state.freshChatCommonList
    },

    getFreshChatBtn: state => {
      return state.freshChatBtn
    },
  },

  actions: {

    async fetchFreshChat({commit, getters}, filter = '') {
      if(!getters.getNextFreshChatPage)
        commit('setFreshChatLoadingStart')
      try {
        return await API.get(`${prefix}/fresh-chat-id${filter}`).then((response) =>{
          commit('setFreshChat',{freshChat: response.data.data.data})
          commit('setFreshChatCommonList',{freshChatCommonList: response.data.data})
          commit('setFreshChatLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setFreshChatLoadingEnd');
        return e;
      }
    },

    async getFreshChat({commit}) {
      commit('setFreshChatLoadingStart');
      try {
        return await API.get(`${prefix}/schedule`).then((response) =>{
          console.log(response)
          commit('setFreshChatItem', {freshChatItem: response.data.data})
          commit('setFreshChatLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setFreshChatLoadingEnd');
        return e;
      }
    },

    async createFreshChat({commit}, data) {
      commit('changeFreshChatBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successFreshChatBtn');
          return response;
        });
      } catch (e) {
        commit('successFreshChatBtn');
        return e;
      }
    },

    async updateFreshChat({commit}, {data, id}) {
      commit('changeFreshChatBtn');
      try {
        return await API.put(`${prefix}/fresh-chat-id/${id}`, data).then((response) =>{
          commit('successFreshChatBtn');
          return response;
        });
      } catch (e) {
        commit('successFreshChatBtn');
        return e;
      }
    },

    async deleteFreshChat({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successFreshChatBtn');
        return e;
      }
    },

  }
}
