import {API} from "../../axios/axiosMainUrl";

export const notifications = {
  state: {
    notifications: [],
    notificationsNoRead: [],
    notificationsCommonData: {},
    notificationItem: {},
    loadingNotifications: true,
    loadingNotificationItem: false,
    notificationBtn: false,
    nextPageNotification: false,
    openNotificationPopup: false,
    notificationsCount: 0
  },

  mutations: {
    setNotifications: (state, payload) => {
      if(state.nextPageNotification) {
        state.notifications = state.notifications.concat(payload.notifications)
      } else {
        state.notifications = payload.notifications
      }
    },

    setNotificationsNoRead: (state, payload) => {
      state.notificationsNoRead = payload.notificationsNoRead
    },

    setNotificationsCommonData: (state, payload) => {
      state.notificationsCommonData = payload.notificationsCommonData
    },


    setOpenNotificationPopup: (state, payload) => {

      state.openNotificationPopup = payload
    },

    setNotificationItem: (state, payload) => {
      state.notificationItem = payload.notificationItem
    },

    setResetNotificationItem: (state) => {
      state.notificationItem = {}
    },

    setLoadingStart: (state) => {
      state.loadingNotifications = true
    },
    setLoadingNotificationItemStart: (state) => {
      state.loadingNotificationItem = true
    },

    setLoadingEnd: (state) => {
      state.loadingNotifications = false
    },
    setLoadingNotificationItemEnd: (state) => {
      state.loadingNotificationItem = false
    },

    changeNotificationBtn: (state) => {
      state.notificationBtn = true
    },
    successNotificationBtn: (state) => {
      state.notificationBtn = false
    },

    setNextNotificationPage: (state, payload) => {
      state.nextPageNotification = payload
    },

    setNotificationsCount: (state, payload) => {
      state.notificationsCount = payload
    },

  },

  getters: {
    getNotifications: state => {
      return state.notifications
    },

    getNotificationsLoading: state => {
      return state.loadingNotifications
    },

    getNotificationsNoRead: state => {
      return state.notificationsNoRead
    },

    getNotificationsCommonData: state => {
      return state.notificationsCommonData
    },

    getNotificationItem: state => {
      return state.notificationItem
    },

    getOpenNotificationPopup: state => {
      return state.openNotificationPopup
    },

    getNotificationsCount: state => {
      return state.notificationsCount
    },

    getNextPageNotification: state => {
      return state.nextPageNotification
    },

    getNotificationBtn: state => {
      return state.notificationBtn
    },
  },

  actions: {

    async fetchNotifications({commit, getters}, filter = '') {
      if(!getters.getNextPageNotification)
        commit('setLoadingStart');
      try {
        return await API.get(`/notifications/section-list${filter}`).then((response) =>{
          commit('setNotifications', {notifications: response.data.data.data})
          commit('setNotificationsCommonData', {notificationsCommonData: response.data.data})
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async fetchNotificationsNoRead({commit}, filter = '?filter[fresh_notifications]=true') {
      commit('setLoadingStart');
      try {
        return await API.get(`/notifications${filter}`).then((response) =>{
          // console.log(response)
          commit('setNotificationsNoRead', {notificationsNoRead: response.data.data.data})
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },


    async getNotification({commit}, id) {
      commit('setLoadingNotificationItemStart');
      try {
        return await API.get(`/notifications/${id}`).then((response) =>{
          commit('setNotificationItem', {notificationItem: response.data.data})
          return response;
        });
      } catch (e) {
        commit('setLoadingNotificationItemEnd');
        return e;
      }
    },

    async updateNotification({commit}, {id, data}) {
       commit('changeNotificationBtn');
      try {
        return await API.put(`/notifications/${id}`, data).then((response) =>{
          commit('successNotificationBtn');
          return response;
        });
      } catch (e) {
        commit('successNotificationBtn');
        return e;
      }
    },

    async createNotifications({commit}, data) {
      commit('changeNotificationBtn');
      try {
        return await API.post(`/notifications`, data).then((response) =>{
          commit('successNotificationBtn');
          return response;
        });
      } catch (e) {
        commit('successNotificationBtn');
        return e;
      }
    },

    async deleteNotification({commit}, id) {
      commit('changeNotificationBtn');
      try {
        return await API.delete(`/notifications/${id}`).then((response) =>{
          commit('successNotificationBtn');
          return response;
        });
      } catch (e) {
        commit('successNotificationBtn');
        return e;
      }
    },

    async fetchNotificationsCount({commit}) {
      commit('setLoadingStart');
      try {
        return await API.get(`/notifications/count-new-notifications`).then((response) =>{
          commit('setNotificationsCount', response.data.data.count)
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

  }
}
