import {API} from "../../axios/axiosMainUrl";

let prefix = '/products/proform-packaging'

export const profilesPackaging = {
  state: {
    profilesPackaging: [],
    profilesPackagingItem: {},
    profilesPackagingCommonList: [],
    profilesPackagingBtn: false,
    profilesPackagingLoading: true,
    nextProfilesPackagingPage: false,
    profilesPackagingForPage: 25,
    profilesPackagingFilter: '',
  },

  mutations: {
    setProfilesPackagingFilter: (state, payload) => {
      state.profilesPackagingFilter = payload
    },

    setProfilesPackaging: (state, payload) => {
      if(state.nextProfilesPackagingPage) {
        state.profilesPackaging = state.profilesPackaging.concat(payload.profilesPackaging)
      } else {
        state.profilesPackaging = payload.profilesPackaging
      }
    },

    setProfilesPackagingCommonList: (state, payload) => {
      state.profilesPackagingCommonList = payload.profilesPackagingCommonList
    },

    setNextProfilesPackagingPage: (state, payload) => {
      state.nextProfilesPackagingPage = payload
    },

    setProfilesPackagingItem: (state, payload) => {
      state.profilesPackagingItem = payload.profilesPackagingItem
    },

    setProfilesPackagingLoadingStart: (state) => {
      state.profilesPackagingLoading = true
    },

    setProfilesPackagingLoadingEnd: (state) => {
      state.profilesPackagingLoading = false
    },

    changeProfilesPackagingBtn: (state) => {
      state.profilesPackagingBtn = true
    },
    successProfilesPackagingBtn: (state) => {
      state.profilesPackagingBtn = false
    },
  },

  getters: {
    getProfilesPackagingFilter: state => {
      return state.profilesPackagingFilter
    },

    getProfilesPackagingForPage: state => {
      return state.profilesPackagingForPage
    },

    getProfilesPackaging: state => {
      return state.profilesPackaging
    },

    getProfilesPackagingLoading: state => {
      return state.profilesPackagingLoading
    },


    getNextProfilesPackagingPage: state => {
      return state.nextProfilesPackagingPage
    },

    getProfilesPackagingItem: state => {
      return state.profilesPackagingItem
    },

    getProfilesPackagingCommonList: state => {
      return state.profilesPackagingCommonList
    },

    getProfilesPackagingBtn: state => {
      return state.profilesPackagingBtn
    },
  },

  actions: {

    async fetchProfilesPackagingWithPermissions({commit, getters}, filter = '') {
      if(!getters.getNextProfilesPackagingPage)
        commit('setProfilesPackagingLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setProfilesPackaging',{profilesPackaging: response.data.data.data})
          commit('setProfilesPackagingCommonList',{profilesPackagingCommonList: response.data.data})
          commit('setProfilesPackagingLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setProfilesPackagingLoadingEnd');
        return e;
      }
    },

    async fetchProfilesPackaging({commit, getters}, filter = '') {
      if(!getters.getNextProfilesPackagingPage)
        commit('setProfilesPackagingLoadingStart')
      try {
        return await API.get(`${prefix}/public-list${filter}`).then((response) =>{
          commit('setProfilesPackaging',{profilesPackaging: response.data.data.data})
          commit('setProfilesPackagingCommonList',{profilesPackagingCommonList: response.data.data})
          commit('setProfilesPackagingLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setProfilesPackagingLoadingEnd');
        return e;
      }
    },

    async getProfilesPackaging({commit}, id) {
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setProfilesPackagingItem', {profilesPackagingItem: response.data.data})
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async createProfilesPackaging({commit}, data) {
      commit('changeProfilesPackagingBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successProfilesPackagingBtn');
          return response;
        });
      } catch (e) {
        commit('successProfilesPackagingBtn');
        return e;
      }
    },

    async updateProfilesPackaging({commit}, {id, data}) {
      commit('changeProfilesPackagingBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successProfilesPackagingBtn');
          return response;
        });
      } catch (e) {
        commit('successProfilesPackagingBtn');
        return e;
      }
    },

    async deleteProfilesPackaging({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successProfilesPackagingBtn');
        return e;
      }
    },

  }
}
