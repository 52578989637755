import Vue from 'vue';
import Vuex from 'vuex';
import {auth} from './auth'
import {countries} from './countries'
import {user} from './user'
import {cusPayments} from './cusPayments'
import {shops} from './shops'
import {permissions} from './accessSettings/permissions'
import {roles} from './accessSettings/roles'
import {permissionsGroups} from './accessSettings/permissionsGroup'
import {vueComponents} from './accessSettings/vueComponents'
import {translationsGroups} from './accessSettings/translationsGroup'
import {translations} from './accessSettings/translations'
import {notifications} from './notifications'
import {datePickerTranslations} from './datePickerTranslations'
import {importOrders} from './importOrders'
import {EN_LANG, DEVELOPER, TRANSLATE_MODE_KEY, TRANSLATE_MODE_VARIABLE} from '../staticData/staticVariables'
import {API} from "../axios/axiosMainUrl";
import {products} from "./products";
import {warehouses} from "./warehousesManagement";
import {downloadPlugins} from "./downloadPlugins";
import {payPallAPI} from "./payPallAPI";
import {authorizeAPI} from "./authorizeAPI";
import {balanceHistory} from "./balanceHistory";
import {myPayments} from "./myPayments";
import {unidentifiedPayments} from "./unidentifiedPayments";
import {requestReturn} from "./requestReturn";
import {proformCategory} from "./proformCategory";
import {proformData} from "./proformData";
import {profilesProform} from "./profiles/profilesProform.js";
import {profilesPackaging} from "./profiles/profilesPackaging.js";
import {express} from "./express/index.js";
import {newPayments} from "./newPayments/index.js";
import {ordersFBM} from "./ordersFBM/index.js";
import {delivery} from "./delivery/index.js";
import {ordersInternal} from "./ordersInternal/index.js";
import {novaPoshta} from "./ordersInternal/novaPoshta.js";
import {belPoshta} from "./ordersInternal/belPoshta.js";
import {ukrPoshta} from "./ordersInternal/ukrPoshta.js";
import {cdek} from "./ordersInternal/cdek.js";
import {customLimits} from "./customLimits/index.js";
import {invoices} from "./invoices/index.js";
import {mainSiteSettings} from "./mainSiteSettings/index.js";
import {hSCodes} from "./hSCodes/index.js";
import {simpleProductProfile} from "./simpleProductProfile/index.js";
import {ukrTreasures} from "./ukrTreasures/index.js";
import {shippingCompany} from "./shippingCompany/index.js";
import {accountBanks} from "./accountBanks/index.js";
import {account} from "./account/index.js";
import {shippingCost} from "./shippingCost/index.js";
import {getFileEntries} from "./getFileEntries/index.js";
import {etsyTransfer} from "./etsyTransfer/index.js";
import {forbiddenKeywords} from "./forbiddenKeywords/index.js";
import {forbiddenGoods} from "./forbiddenGoods/index.js";
import {etsyListings} from "./etsyListings/index.js";
import {packagingPrice} from "./packagingPrice/index.js";
import {warehouseStorage} from "./warehouseStorage/index.js";
import {ordersFBA} from "./ordersFBA/index.js";
import {shippingInsurances} from "./shippingInsurances/index.js";
import {requestFunds} from "./requestFunds/index.js";
import {ordersInbound} from "./ordersInbound/index.js";
import {dashboard} from "./dashboard/index.js";
import {consolidationUsers} from "./consolidationUsers/index.js";
import {expensesTypes} from "./expensesTypes/index.js";
import {expenses} from "./expenses/index.js";
import {fillClaim} from "./fillClaim/index.js";
import {upcCodes} from "./upcCodes/index.js";
import {userNotes} from "./userNotes/index.js";
import {multiSearch} from "./multiSearch/index.js";
import {generateReport} from "./generateReport";
import {manageUsers} from "./user/manageUsers.js";
import {payPalCases} from "./payPalCases";
import {iossNumbers} from "./iossNumbers";
import {insuranceSkladUsa} from "./insuranceSkladUsa";
import {payPalButtons} from "./payPalButtons";
import {consolidation} from "./consolidation";
import {storageDocuments} from "./storageDocuments";
import {trackingLog} from "./trackingLog";
import {returnGoods} from "./returnGoods";
import {requestReturnGoods} from "./requestReturnGoods";
import {returnGoodsDocuments} from "./returnGoodsDocuments";
import {consolidationUnion} from "./consolidationUnion";
import {consolidationMeest} from "./consolidationMeest";
import {search} from "./search";
import {connectionsHistory} from "./connectionsHistory";
import {APCDelcon} from "./APCDelcon";
import {profitReport} from "./profitReport";
import {processFBMReport} from "./processFBMReport";
import {orderPrice} from "./orderPrice";
import {orderPriceDeliveryServices} from "./orderPrice/deliveryServices.js";
import {consolidationOrders} from "./consolidationOrders";
import {shippingCompanyForOrder} from "./shippingCompanyForOrder";
import {shippingMethodsForOrder} from "./shippingMethodsForOrder";
import {productsReport} from "./productsReport";
import {otherIncome} from "./otherIncome";
import {trackingForPayPal} from "./trackingForPayPal";
import {cellsReport} from "./cellsReport";
import {authTokens} from "./authTokens";
import {fastOrders} from "./fastOrders";
import {trackTest} from "./trackTest";
import {freshChatWorkingDays} from "./freshChatWorkingDays";
import {holidays} from "./holidays";
import {freshChatMessages} from "./freshChatMessages";
import {freshChat} from "./freshChat";
import {guide} from "./guide";
import {zonePrice} from "./zonePrice";
import {charts} from "./charts";
import {chat} from "./chat";
import {chatMassage} from "./chatMassage";
import {historyChanges} from "./historyChanges";
import {manageUsersGroups} from "./user/manageUsersGroups";
import {heartysan} from "./heartysan";
import {heartysanCategory} from "./heartysan/heartysanCategory";
import {heartysanProducts} from "./heartysan/heartysanProducts";
import {heartysanDeal} from "./heartysan/heartysanDeal";
import {heartysanChat} from "./heartysan/heartysanChat";
import {heartysanEtsy} from "./heartysan/heartysanEtsy";
import {errorLogs} from "./errorLogs";
import {errorLogsBackend} from "./errorLogs/errorLogsBackend.js";
import {shippingReport} from "./shippingReport";
import {inactiveFBMUsers} from "./inactiveFBMUsers";
import {orderNotification} from "./orderNotification";
import {exportRequests} from "@/store/exportRequests";
import {manifests} from "./manifests";
import {orderBin} from "./orderBin";
import {brainTreeAPI} from "./brainTreeAPI";
import {orderSize} from "./orderSize";
import {easypostInvoices} from "./easypostInvoices";
import {marketplaceProducts} from "./marketplaceProducts";
import {marketplaceReviews} from "./marketplaceReviews";
import {marketplaceSettings} from "./marketplaceSettings";
import {promoCode} from "./promoCode";
import {refillProducts} from "./refillProducts";
import {marketplaceRates} from "./marketplaceRates";
import {balancePayoneerHistory} from "./balancePayoneerHistory";
import {newPaymentsPayoneer} from "./newPaymentsPayoneer";
import {expressPoshtaReport} from "./expressPoshtaReport";
import {calculatorSettings} from "./calculatorSettings";
import {invoiceSeller} from "./invoiceSeller";
import {invoiceProduct} from "./invoiceProduct";
import {iE559Report} from "./iE559Report";
import {orderSendFrom} from "./orderSendFrom";
import {expressFastOrders} from "./expressFastOrders";
import {expressProblem} from "./expressProblem";
import {scanFormDP} from "./scanFormDP";

Vue.use(Vuex);

export const store = new Vuex.Store({

  modules: {
    auth: auth,
    countries: countries,
    user: user,
    cusPayments: cusPayments,
    shops: shops,
    permissions: permissions,
    roles: roles,
    permissionsGroups: permissionsGroups,
    vueComponents: vueComponents,
    translations: translations,
    translationsGroups: translationsGroups,
    products: products,
    notifications: notifications,
    datePickerTranslations: datePickerTranslations,
    warehouses: warehouses,
    importOrders: importOrders,
    downloadPlugins: downloadPlugins,
    payPall: payPallAPI,
    authorizeAPI: authorizeAPI,
    balanceHistory: balanceHistory,
    myPayments: myPayments,
    unidentifiedPayments: unidentifiedPayments,
    requestReturn: requestReturn,
    proformCategory: proformCategory,
    proformData: proformData,
    profilesProform: profilesProform,
    profilesPackaging: profilesPackaging,
    express: express,
    newPayments: newPayments,
    ordersFBM: ordersFBM,
    delivery: delivery,
    ordersInternal: ordersInternal,
    novaPoshta: novaPoshta,
    belPoshta: belPoshta,
    ukrPoshta: ukrPoshta,
    cdek: cdek,
    customLimits: customLimits,
    invoices: invoices,
    mainSiteSettings: mainSiteSettings,
    hSCodes: hSCodes,
    simpleProductProfile: simpleProductProfile,
    ukrTreasures: ukrTreasures,
    shippingCompany: shippingCompany,
    accountBanks: accountBanks,
    account: account,
    shippingCost: shippingCost,
    getFileEntries: getFileEntries,
    etsyTransfer: etsyTransfer,
    forbiddenKeywords: forbiddenKeywords,
    forbiddenGoods: forbiddenGoods,
    etsyListings: etsyListings,
    packagingPrice: packagingPrice,
    warehouseStorage: warehouseStorage,
    ordersFBA: ordersFBA,
    shippingInsurances: shippingInsurances,
    requestFunds: requestFunds,
    ordersInbound: ordersInbound,
    dashboard: dashboard,
    consolidationUsers: consolidationUsers,
    expensesTypes: expensesTypes,
    expenses: expenses,
    fillClaim: fillClaim,
    upcCodes: upcCodes,
    userNotes: userNotes,
    multiSearch: multiSearch,
    manageUsers: manageUsers,
    payPalCases: payPalCases,
    iossNumbers: iossNumbers,
    insuranceSkladUsa: insuranceSkladUsa,
    payPalButtons: payPalButtons,
    consolidation: consolidation,
    storageDocuments: storageDocuments,
    trackingLog: trackingLog,
    generateReport: generateReport,
    returnGoods: returnGoods,
    requestReturnGoods: requestReturnGoods,
    returnGoodsDocuments: returnGoodsDocuments,
    consolidationUnion: consolidationUnion,
    consolidationMeest: consolidationMeest,
    search: search,
    connectionsHistory: connectionsHistory,
    APCDelcon: APCDelcon,
    profitReport: profitReport,
    processFBMReport: processFBMReport,
    orderPrice: orderPrice,
    orderPriceDeliveryServices: orderPriceDeliveryServices,
    consolidationOrders: consolidationOrders,
    shippingCompanyForOrder: shippingCompanyForOrder,
    shippingMethodsForOrder: shippingMethodsForOrder,
    productsReport: productsReport,
    otherIncome: otherIncome,
    trackingForPayPal: trackingForPayPal,
    cellsReport: cellsReport,
    authTokens: authTokens,
    fastOrders: fastOrders,
    trackTest: trackTest,
    freshChatWorkingDays: freshChatWorkingDays,
    holidays: holidays,
    freshChatMessages: freshChatMessages,
    freshChat: freshChat,
    guide: guide,
    zonePrice: zonePrice,
    charts: charts,
    chat: chat,
    chatMassage: chatMassage,
    historyChanges: historyChanges,
    manageUsersGroups: manageUsersGroups,
    heartysan: heartysan,
    heartysanCategory: heartysanCategory,
    heartysanProducts: heartysanProducts,
    heartysanDeal: heartysanDeal,
    heartysanChat: heartysanChat,
    heartysanEtsy: heartysanEtsy,
    errorLogs: errorLogs,
    errorLogsBackend: errorLogsBackend,
    shippingReport: shippingReport,
    inactiveFBMUsers: inactiveFBMUsers,
    orderNotification: orderNotification,
    exportRequests: exportRequests,
    manifests: manifests,
    orderBin: orderBin,
    brainTreeAPI: brainTreeAPI,
    orderSize: orderSize,
    easypostInvoices: easypostInvoices,
    marketplaceProducts: marketplaceProducts,
    marketplaceReviews: marketplaceReviews,
    marketplaceSettings: marketplaceSettings,
    promoCode: promoCode,
    refillProducts: refillProducts,
    marketplaceRates: marketplaceRates,
    balancePayoneerHistory: balancePayoneerHistory,
    newPaymentsPayoneer: newPaymentsPayoneer,
    expressPoshtaReport: expressPoshtaReport,
    calculatorSettings: calculatorSettings,
    invoiceSeller: invoiceSeller,
    invoiceProduct: invoiceProduct,
    iE559Report: iE559Report,
    orderSendFrom: orderSendFrom,
    expressFastOrders: expressFastOrders,
    expressProblem: expressProblem,
    scanFormDP: scanFormDP,
  },

  state: {
    lang: localStorage.getItem('translationLang') ? localStorage.getItem('translationLang') : EN_LANG,
    translateMode: localStorage.getItem(TRANSLATE_MODE_KEY) === TRANSLATE_MODE_VARIABLE,
    languages: [],
    hideMenuAndHeader: false,
    PATHS: {},

    COMMON: [],
    COMMON_AUTHORIZED: {},

    ROUTER_HISTORY: [],

    ERRORS: {
      SUCCESS_CODE: 200,
      NO_CONTENT_CODE: 204,
      SERVER_ERROR_CODE: 500,
      VALIDATION_ERROR_CODE: 422,
      UNAUTHORIZED_CODE: 401,
      FORBIDDEN_CODE: 403,
      NOT_FOUND_CODE: 404,
      BAD_REQUEST_CODE: 400,
      BLOCKED_CODE: 423,
      TECHNICAL_WORKS_CODE: 503,
    },

    DEVELOPER_ROLE: DEVELOPER,

    TECHNICAL_WORKS_MODE: false,

    MOBILE_MENU: false,

    confirmDeletePopup: false,

    saveInDraftsPopup: false,

    historyOfChangesPopup: false,

    exportBtnLoading: false,

    globalUpdateTable: false,

    globalUpdateManagerButton: false,
  },

  getters: {
    GET_MOBILE_MENU: state => {
      return state.MOBILE_MENU;
    },

    GET_LANG: state => {
      return state.lang;
    },

    GET_LANGUAGES: state => {
      return state.languages;
    },

    GET_PATHS: state => {
      return state.PATHS;
    },

    GET_ERRORS: state => {
      return state.ERRORS;
    },

    GET_DEVELOPER_ROLE: state => {
      return state.DEVELOPER_ROLE;
    },

    GET_TECHNICAL_WORKS_MODE: state => {
      return state.TECHNICAL_WORKS_MODE;
    },

    GET_TRANSLATION_MODE: state => {
      return state.translateMode;
    },

    GET_COMMON: state => {
      return state.COMMON;
    },

    GET_COMMON_AUTHORIZED: state => {
      return state.COMMON_AUTHORIZED;
    },

    GET_ROUTER_HISTORY: state => {
      return state.ROUTER_HISTORY;
    },

    getHideMenuAndHeader: state => {
      return state.hideMenuAndHeader;
    },

    getConfirmDeletePopup: state => {
      return state.confirmDeletePopup
    },

    getSaveInDraftsPopup: state => {
      return state.saveInDraftsPopup
    },

    getHistoryOfChangesPopup: state => {
      return state.historyOfChangesPopup
    },

    getExportBtnLoading: state => {
      return state.exportBtnLoading
    },

    getGlobalUpdateTable: state => {
      return state.globalUpdateTable
    },

    getGlobalUpdateManagerButton: state => {
      return state.globalUpdateManagerButton
    },
  },

  mutations: {
    SET_MOBILE_MENU: (state, payload) => {

      state.MOBILE_MENU = payload;
      state.MOBILE_MENU && window.innerWidth < 680 ?
        document.getElementsByTagName('body')[0].style.overflow = 'hidden' :
        document.getElementsByTagName('body')[0].style.overflow = 'initial'
    },
    SET_LANG: (state, payload) => {
      state.lang = payload;
    },
    SET_PATHS: (state, payload) => {
      state.PATHS = payload;
    },
    SET_LANGUAGES: (state, payload) => {
      state.languages = payload.languages;
    },
    SET_TRANSLATION_MODE: (state, payload) => {
      state.translateMode = payload;
    },
    SET_TECHNICAL_WORKS_MODE: (state, payload) => {
      state.TECHNICAL_WORKS_MODE = payload;
    },
    SET_COMMON: (state, payload) => {
      state.COMMON = payload;
    },

    SET_COMMON_AUTHORIZED: (state, payload) => {
      state.COMMON_AUTHORIZED = payload.COMMON_AUTHORIZED;
    },

    SET_ROUTER_HISTORY: (state, payload) => {
      state.ROUTER_HISTORY = state.ROUTER_HISTORY.concat(payload)
    },

    setHideMenuAndHeader: (state) => {
      state.hideMenuAndHeader = true;
    },

    setShowMenuAndHeader: (state) => {
      state.hideMenuAndHeader = false;
    },

    setConfirmDeletePopup: (state, payload) => {
      state.confirmDeletePopup = payload
    },

    setSaveInDraftsPopup: (state, payload) => {
      state.saveInDraftsPopup = payload
    },

    setHistoryOfChangesPopup: (state, payload) => {
      state.historyOfChangesPopup = payload
    },

    setExportBtnLoadingStart: state => {
      state.exportBtnLoading = true;
    },

    setExportBtnLoadingEnd: state => {
      state.exportBtnLoading = false;
    },

    setGlobalUpdateTable: (state, payload) => {
      state.globalUpdateTable = payload
    },

    setGlobalUpdateManagerButton: (state, payload) => {
      state.globalUpdateManagerButton = payload
    },
  },

  actions: {
    async fetchCommonData({commit}) {
      // commit('setLoadingStart');
      try {
        return await API.get(`common/not-authorized-config`).then((response) => {
          commit('SET_COMMON', {COMMON: response.data.data});
          commit('SET_LANGUAGES', {languages: response.data.data.languages});
          // commit('setLoadingEnd')
          return response
        })
      } catch (e) {
        // commit('setLoadingEnd')
        return e;
      }
    },

    async fetchCommonAuthorizedData({commit}) {
      // commit('setLoadingStart');
      try {
        return await API.get(`common/authorized-config`).then((response) => {
          commit('SET_COMMON_AUTHORIZED', {COMMON_AUTHORIZED: response.data.data})
          commit('setUserProfile', {userProfile: response.data.data.user})
          commit('setUserSettings', {userSettings: response.data.data.settings})

          if (response.data.data.user.small_avatar_file.length > 0) {
            let imgSmall = `data:image/${response.data.data.user.small_avatar_file[0].extension};base64,`
              + response.data.data.user.small_avatar_file[0].file

            commit('setAvatarSmall', imgSmall)
            localStorage.setItem('avatar', imgSmall)

          }

          return response
        })
      } catch (e) {
        // commit('setLoadingEnd')
        return e;
      }
    },

    async fetchCommonAllowAccessWithNegativeBalance({commit}) {
      // commit('setLoadingStart');
      try {
        return await API.get(`common/allowed-negative-balance-access`).then((response) => {

          return response
        })
      } catch (e) {
        commit('setLoadingEnd')
        return e;
      }
    },

    async updateCommonAuthorizedData({commit}) {
      try {
        return await API.get(`common/authorized-config`).then((response) => {
          commit('SET_COMMON_AUTHORIZED', {COMMON_AUTHORIZED: response.data.data})
          commit('setUserProfile', {userProfile: response.data.data.user})
          commit('setUserSettings', {userSettings: response.data.data.settings})
          return response
        })
      } catch (e) {
        return e;
      }
    },


    async getFileFromServer({commit}, id) {
      commit('setLoadingEnd')
      try {
        return await API.get(`file-storage/${id}/download`).then((response) => {
          return response.data.data
        })
      } catch (e) {
        return e;
      }
    },

    async getCommonTag({commit}) {
      try {
        return await API.get(`common/tag`).then((response) => {
          return response
        })
      } catch (e) {
        commit('setLoadingEnd')
        return e;
      }
    },

    async downloadFileFromServer({commit}, id) {
      commit('setLoadingEnd')
      try {
        return await API.get(`file-storage/${id}/download-file`, { responseType: 'blob' }).then((response) => {
          return response
        })
      } catch (e) {
        return e;
      }
    },

    async blockSystem({commit}, data) {
      commit('setLoadingEnd');
      try {
        return await API.post(`/common/system-down`, data).then((response) => {
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async unblockSystem({commit}, data) {
      commit('setLoadingEnd');
      try {
        return await API.post(`/common/system-up`, data).then((response) => {
          return response;
        });
      } catch (e) {
        return e;
      }
    },

  },
});
