
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const OtherIncomeTable = () => import(/* webpackChunkName: "group-other-income" */ './components/OtherIncomeTable/OtherIncomeTable.vue')
const OtherIncomeCreate = () => import(/* webpackChunkName: "group-other-income-create" */ './components/OtherIncomeCreation/OtherIncomeCreation.vue')
const OtherIncomeEditing = () => import(/* webpackChunkName: "group-other-income-edit" */ './components/OtherIncomeEditing/OtherIncomeEditing.vue')
const moduleKey = 'other-income'

export const OtherIncomeRoutes = [
  {
    path: routerPaths.otherIncome,
    component: OtherIncomeTable,
    meta: {
      breadcrumbs: {
        key: [
          'incomeAndExpenses',
          'otherIncome'
        ],
        items: {
          'incomeAndExpenses': {
            name: 'menu_incomeExpenses',
            link: routerPaths.otherIncome,
          },
          'otherIncome': {
            name: 'menu_otherIncome',
            link: routerPaths.otherIncome,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.INCOME_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_otherIncome'
    },
  },

  {
    path: routerPaths.incomeOtherIncomeCreate,
    component: OtherIncomeCreate,
    meta: {
      breadcrumbs: {
        key: [
          'incomeAndExpenses',
          'otherIncome',
          'incomeOtherIncomeCreate'
        ],
        items: {
          'incomeAndExpenses': {
            name: 'menu_incomeExpenses',
            link: routerPaths.otherIncome,
          },
          'otherIncome': {
            name: 'menu_otherIncome',
            link: routerPaths.otherIncome,
          },
          'incomeOtherIncomeCreate': {
            name: 'breadcrumbs_newOtherIncome',
            link: routerPaths.incomeOtherIncomeCreate,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.INCOME_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_newOtherIncome'
    },
  },

  {
    path: routerPaths.incomeOtherIncomeEditItem,
    component: OtherIncomeEditing,
    meta: {
      breadcrumbs: {
        key: [
          'incomeAndExpenses',
          'otherIncome',
          'incomeOtherIncomeEdit'
        ],
        items: {
          'incomeAndExpenses': {
            name: 'menu_incomeExpenses',
            link: routerPaths.otherIncome,
          },
          'otherIncome': {
            name: 'menu_otherIncome',
            link: routerPaths.otherIncome,
          },
          'incomeOtherIncomeEdit': {
            name: 'breadcrumbs_editOtherIncome',
            link: routerPaths.incomeOtherIncomeEditItem,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.INCOME_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_editOtherIncome'
    },
  },
]
