
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const RefillProductsTable = () => import(/* webpackChunkName: "group-refill-products-table" */ './components/RefillProductsTable/RefillProductsTable.vue')
const moduleKey = 'refill-products'


export const RefillProductsRoutes = [
  {
    path: routerPaths.refillProducts,
    component: RefillProductsTable,
    meta: {
      breadcrumbs: {
        key: [
          'warehouse',
          'refillProducts'
        ],
        items: {
          'warehouse': {
            name: 'menu_warehouse',
            link: routerPaths.refillProducts,  // TODO change route on warehouse
          },
          'refillProducts': {
            name: 'menu_refillProducts',
            link: routerPaths.refillProducts,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.REFILL_PRODUCTS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_refillProducts'
    },
  },
]
