import {API} from "../../axios/axiosMainUrl";

export const invoices = {
  state: {
    invoices: [],
    invoicesItem: {},
    invoicesCommonList: [],
    invoicesBtn: false,
    invoicesLoading: true,
    nextInvoicesPage: false,
    invoicesForPage: 25,
    invoicesFilter: '',
  },

  mutations: {
    setInvoicesFilter: (state, payload) => {
      state.invoicesFilter = payload
    },

    setInvoices: (state, payload) => {
      if(state.nextInvoicesPage) {
        state.invoices = state.invoices.concat(payload.invoices)
      } else {
        state.invoices = payload.invoices
      }
    },

    setInvoicesCommonList: (state, payload) => {
      state.invoicesCommonList = payload.invoicesCommonList
    },

    setNextInvoicesPage: (state, payload) => {
      state.nextInvoicesPage = payload
    },

    setInvoicesItem: (state, payload) => {
      state.invoicesItem = payload.invoicesItem
    },

    setInvoicesLoadingStart: (state) => {
      state.invoicesLoading = true
    },

    setInvoicesLoadingEnd: (state) => {
      state.invoicesLoading = false
    },

    changeInvoicesBtn: (state) => {
      state.invoicesBtn = true
    },
    successInvoicesBtn: (state) => {
      state.invoicesBtn = false
    },
  },

  getters: {
    getInvoicesFilter: state => {
      return state.invoicesFilter
    },

    getInvoicesForPage: state => {
      return state.invoicesForPage
    },

    getInvoices: state => {
      return state.invoices
    },

    getInvoicesLoading: state => {
      return state.invoicesLoading
    },


    getNextInvoicesPage: state => {
      return state.nextInvoicesPage
    },

    getInvoicesItem: state => {
      return state.invoicesItem
    },

    getInvoicesCommonList: state => {
      return state.invoicesCommonList
    },

    getInvoicesBtn: state => {
      return state.invoicesBtn
    },
  },

  actions: {

    async fetchInvoices({commit, getters}, filter = '') {
      if(!getters.getNextInvoicesPage)
        commit('setInvoicesLoadingStart')
      try {
        return await API.get(`/invoices${filter}`).then((response) =>{
          commit('setInvoices',{invoices: response.data.data.data})
          commit('setInvoicesCommonList',{invoicesCommonList: {...response.data.data.meta, ...{
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev,
                addition_export: response.data.data.addition_export,
                send_from_list: response.data.data.send_from_list,
              }}})
          commit('setInvoicesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setInvoicesLoadingEnd');
        return e;
      }
    },

    async getInvoices({commit}, id) {
      // commit('setInvoicesLoadingStart');
      try {
        return await API.get(`/invoices/${id}`).then((response) =>{
          console.log(response)
          commit('setInvoicesItem', {invoicesItem: response.data.data})
          // commit('setInvoicesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setInvoicesLoadingEnd');
        return e;
      }
    },

    async createInvoices({commit}, data) {
      commit('changeInvoicesBtn');
      try {
        return await API.post(`/invoices`, data).then((response) =>{
          commit('successInvoicesBtn');
          return response;
        });
      } catch (e) {
        commit('successInvoicesBtn');
        return e;
      }
    },

    async updateInvoices({commit}, {id, data}) {
      commit('changeInvoicesBtn');
      try {
        return await API.put(`/invoices/${id}`, data).then((response) =>{
          commit('successInvoicesBtn');
          return response;
        });
      } catch (e) {
        commit('successInvoicesBtn');
        return e;
      }
    },

    async deleteInvoices({commit}, id) {
      try {
        return await API.delete(`/invoices/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successInvoicesBtn');
        return e;
      }
    },

    async getInvoicesPrintPdf({commit}, data) {
      commit('setExportBtnLoadingStart')
      try {
        return await API.get(`invoices/print-total${data}`).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        commit('setLoadingEnd')
        return e;
      }
    },

    async createCustomInvoice({commit}, data) {
      commit('changeInvoicesBtn');
      try {
        return await API.post(`/invoices/custom`, data).then((response) =>{
          commit('successInvoicesBtn');
          return response;
        });
      } catch (e) {
        commit('successInvoicesBtn');
        return e;
      }
    },

    async updateCustomInvoice({commit}, {id, data}) {
      commit('changeInvoicesBtn');
      try {
        return await API.put(`/invoices/custom/${id}`, data).then((response) =>{
          commit('successInvoicesBtn');
          return response;
        });
      } catch (e) {
        commit('successInvoicesBtn');
        return e;
      }
    },

    async cancelInvoices({commit}, id) {
      commit('changeInvoicesBtn');
      try {
        return await API.patch(`/invoices/${id}/cancel`).then((response) =>{
          commit('successInvoicesBtn');
          return response;
        });
      } catch (e) {
        commit('successInvoicesBtn');
        return e;
      }
    },


    async getExportInvoices({commit}, {filter , type}) {
      commit('setExportBtnLoadingStart')
      commit('setLoadingEnd')
      try {
        return await API.get(`/invoices/export/${type}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

    async uploadFilesForInvoice({commit}, data) {
      commit('changeInvoicesBtn');
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`/invoices/custom/upload-files`, data).then((response) =>{
          commit('successInvoicesBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successInvoicesBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async getInvoicesFiles({commit}, id) {
      // commit('setInvoicesLoadingStart');
      try {
        return await API.get(`/invoices/custom/${id}/show-files`).then((response) =>{
          console.log(response)
          return response;
        });
      } catch (e) {
        commit('setInvoicesLoadingEnd');
        return e;
      }
    },

    async getPolandInvoiceReport({commit}, date) {
      commit('changeInvoicesBtn');
      try {
        return await API.get(`/report/poland-invoice-report/generate-file/${date}`).then((response) =>{
          commit('successInvoicesBtn');
          return response;
        });
      } catch (e) {
        commit('successInvoicesBtn');
        return e;
      }
    },

  }
}
