import {API} from "../../axios/axiosMainUrl";

export const balanceHistory = {
  state: {
    balanceHistory: [],
    // balanceHistoryItem: {},
    balanceHistoryCommonList: [],
    balanceHistoryBtn: false,
    balanceHistoryLoading: true,
    nextBalanceHistoryPage: false,
    balanceHistoryForPage: 25,
    balanceHistoryFilter: '',
  },

  mutations: {
    setBalanceHistoryFilter: (state, payload) => {
      state.balanceHistoryFilter = payload
    },

    setBalanceHistory: (state, payload) => {
      if(state.nextBalanceHistoryPage) {
        state.balanceHistory = state.balanceHistory.concat(payload.balanceHistory)
      } else {
        state.balanceHistory = payload.balanceHistory
      }
    },

    setNextBalanceHistoryPage: (state, payload) => {
      state.nextBalanceHistoryPage = payload
    },

    setBalanceHistoryCommonList: (state, payload) => {
      state.balanceHistoryCommonList = payload.balanceHistoryCommonList
    },

    // setBalanceHistoryItem: (state, payload) => {
    //   state.balanceHistoryItem = payload.balanceHistoryItem
    // },

    setBalanceHistoryLoadingStart: (state) => {
      state.balanceHistoryLoading = true
    },

    setBalanceHistoryLoadingEnd: (state) => {
      state.balanceHistoryLoading = false
    },

    changeBalanceHistoryBtn: (state) => {
      state.balanceHistoryBtn = true
    },
    successBalanceHistoryBtn: (state) => {
      state.balanceHistoryBtn = false
    },
  },

  getters: {
    getBalanceHistoryFilter: state => {
      return state.balanceHistoryFilter
    },

    getBalanceHistoryForPage: state => {
      return state.balanceHistoryForPage
    },

    getBalanceHistory: state => {
      return state.balanceHistory
    },

    getBalanceHistoryLoading: state => {
      return state.balanceHistoryLoading
    },

    getNextBalanceHistoryPage: state => {
      return state.nextBalanceHistoryPage
    },

    // getBalanceHistoryItem: state => {
    //   return state.balanceHistoryItem
    // },

    getBalanceHistoryCommonList: state => {
      return state.balanceHistoryCommonList
    },

    getBalanceHistoryBtn: state => {
      return state.balanceHistoryBtn
    },
  },

  actions: {

    async fetchBalanceHistory({commit, getters}, filter = '') {
      if(!getters.getNextBalanceHistoryPage)
        commit('setBalanceHistoryLoadingStart')

      try {
        return await API.get(`/payments/payment-history/list${filter}`).then((response) =>{
          commit('setBalanceHistory',{balanceHistory: response.data.data.data})
          commit('setBalanceHistoryCommonList',{balanceHistoryCommonList: response.data.data})
          commit('setBalanceHistoryLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setBalanceHistoryLoadingEnd');
        return e;
      }
    },

    async getExportBalanceHistory({commit}, {filter , type}) {
      commit('setExportBtnLoadingStart')
      commit('setLoadingEnd')
      try {
        return await API.get(`/payments/payment-history/export/${type}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

    // async getBalanceHistory({commit}, id) {
    //   commit('setBalanceHistoryLoadingStart');
    //   try {
    //     return await API.get(`/balanceHistory/${id}`).then((response) =>{
    //       console.log(response)
    //       commit('setBalanceHistoryItem', {balanceHistoryItem: response.data.data})
    //       commit('setBalanceHistoryLoadingEnd');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('setBalanceHistoryLoadingEnd');
    //     return e;
    //   }
    // },
    //
    // async createBalanceHistory({commit}, data) {
    //   commit('changeBalanceHistoryBtn');
    //   try {
    //     return await API.post(`/balanceHistory`, data).then((response) =>{
    //       commit('successBalanceHistoryBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successBalanceHistoryBtn');
    //     return e;
    //   }
    // },
    //
    // async updateBalanceHistory({commit}, {id, data}) {
    //   commit('changeBalanceHistoryBtn');
    //   try {
    //     return await API.put(`/balanceHistory/${id}`, data).then((response) =>{
    //       commit('successBalanceHistoryBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successBalanceHistoryBtn');
    //     return e;
    //   }
    // },
    //
    // async deleteBalanceHistory({commit}, id) {
    //   try {
    //     return await API.delete(`/balanceHistory/${id}`).then((response) =>{
    //       console.log(response);
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successBalanceHistoryBtn');
    //     return e;
    //   }
    // },

  }
}
