import {API} from "../../axios/axiosMainUrl";

const prefix = '/consolidations'

export const consolidation = {
  state: {
    consolidation: [],
    consolidationItem: {},
    consolidationByUserId: [],
    consolidationCommonList: [],
    consolidationBtn: false,
    consolidationLoading: true,
    nextConsolidationPage: false,
    consolidationForPage: 25,
    consolidationFilter: '',
  },

  mutations: {
    setConsolidationFilter: (state, payload) => {
      state.consolidationFilter = payload
    },

    setConsolidation: (state, payload) => {
      if(state.nextConsolidationPage) {
        state.consolidation = state.consolidation.concat(payload.consolidation)
      } else {
        state.consolidation = payload.consolidation
      }
    },

    setConsolidationCommonList: (state, payload) => {
      state.consolidationCommonList = payload.consolidationCommonList
    },

    setNextConsolidationPage: (state, payload) => {
      state.nextConsolidationPage = payload
    },

    setConsolidationItem: (state, payload) => {
      state.consolidationItem = payload.consolidationItem
    },

    setConsolidationByUserId: (state, payload) => {
      state.consolidationByUserId = payload.consolidationByUserId
    },

    setConsolidationLoadingStart: (state) => {
      state.consolidationLoading = true
    },

    setConsolidationLoadingEnd: (state) => {
      state.consolidationLoading = false
    },

    changeConsolidationBtn: (state) => {
      state.consolidationBtn = true
    },
    successConsolidationBtn: (state) => {
      state.consolidationBtn = false
    },
  },

  getters: {
    getConsolidationFilter: state => {
      return state.consolidationFilter
    },

    getConsolidationForPage: state => {
      return state.consolidationForPage
    },

    getConsolidation: state => {
      return state.consolidation
    },

    getConsolidationLoading: state => {
      return state.consolidationLoading
    },


    getNextConsolidationPage: state => {
      return state.nextConsolidationPage
    },

    getConsolidationItem: state => {
      return state.consolidationItem
    },

    getConsolidationByUserId: state => {
      return state.consolidationByUserId
    },

    getConsolidationCommonList: state => {
      return state.consolidationCommonList
    },

    getConsolidationBtn: state => {
      return state.consolidationBtn
    },
  },

  actions: {

    async fetchConsolidation({commit, getters}, {filter = '', noCommit = false}) {
      if(!getters.getNextConsolidationPage && !noCommit)
        commit('setConsolidationLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          if (!noCommit) {
            commit('setConsolidation',{consolidation: response.data.data.data})
            commit('setConsolidationCommonList',{consolidationCommonList: response.data.data})
            commit('setConsolidationLoadingEnd');
          }

          return response;
        });
      } catch (e) {
        commit('setConsolidationLoadingEnd');
        return e;
      }
    },


    async getConsolidation({commit}, id) {
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setConsolidationItem', {consolidationItem: response.data.data})
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async getConsolidationEdit({commit}, id) {
      try {
        return await API.get(`${prefix}/${id}/edit`).then((response) =>{
          commit('setConsolidationItem', {consolidationItem: response.data.data})
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async getConsolidationById({commit}, id) {
      try {
        return await API.get(`${prefix}/get-user-notes?user_id=${id}`).then((response) =>{
          commit('setConsolidationByUserId', {consolidationByUserId: response.data.data})
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async getConsolidationCreate({commit},) {
      try {
        return await API.get(`${prefix}/create`).then((response) => {
          return response;
        });
      } catch (e) {
        commit('setConsolidationLoadingEnd');
        return e;
      }
    },

    async getConsolidationDeliveryPrices({commit},) {
      try {
        return await API.get(`/consolidation-delivery-price`).then((response) => {
          return response;
        });
      } catch (e) {
        commit('setConsolidationLoadingEnd');
        return e;
      }
    },

    async createConsolidation({commit}, data) {
      commit('changeConsolidationBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successConsolidationBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationBtn');
        return e;
      }
    },

    async getConsolidationShippingCost({commit}, data) {
      commit('changeConsolidationBtn');
      try {
        return await API.post(`${prefix}/calculate-packaging-amount`, data).then((response) =>{
          commit('successConsolidationBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationBtn');
        return e;
      }
    },

    async addMessageConsolidation({commit}, {id: id, data: data}) {
      commit('changeConsolidationBtn');
      try {
        return await API.post(`${prefix}/${id}/add-dialog-message`, data).then((response) =>{
          commit('successConsolidationBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationBtn');
        return e;
      }
    },

    async updateConsolidation({commit}, {id, data}) {
      commit('changeConsolidationBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successConsolidationBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationBtn');
        return e;
      }
    },

    async partialUpdateConsolidation({commit}, {id, data}) {
      commit('changeConsolidationBtn');
      try {
        return await API.patch(`${prefix}/${id}/partial`, data).then((response) =>{
          commit('successConsolidationBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationBtn');
        return e;
      }
    },

    async updateTrackNumberConsolidation({commit}, {id, data}) {
      commit('changeConsolidationBtn');
      try {
        return await API.patch(`${prefix}/attach-tracking-number/${id}`, data).then((response) =>{
          commit('successConsolidationBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationBtn');
        return e;
      }
    },

    async deleteConsolidation({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successConsolidationBtn');
        return e;
      }
    },

    async addConsolidationFile({commit}, data) {
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${prefix}/upload-file`, data).then((response) =>{
          commit('successConsolidationBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successConsolidationBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async getConsolidationInnerLabels({commit}, data) {
      commit('changeConsolidationBtn')
      try {
        return await API.get(`${prefix}/all-labels/${data.id}/${data.type}`).then((response) =>{
          commit('successConsolidationBtn')
          return response
        })
      } catch (e) {
        commit('successConsolidationBtn')
        commit('setLoadingEnd')
        return e;
      }
    },

    async getConsolidationFile({commit}, data) {
      commit('changeConsolidationBtn')
      try {
        return await API.get(`${prefix}/${data.id}/marking/${data.type}`).then((response) =>{
          commit('successConsolidationBtn')
          return response
        })
      } catch (e) {
        commit('successConsolidationBtn')
        commit('setLoadingEnd')
        return e;
      }
    },

    async getConsolidationFileBlob({commit}, data) {
      commit('changeConsolidationBtn')
      let config = {
        headers: {'Access-Control-Expose-Headers' : 'Content-Disposition'},
        responseType: 'blob'
      }
      try {
        return await API.get(`${prefix}/${data.id}/marking/${data.type}`, config).then((response) =>{
          commit('successConsolidationBtn')
          return response
        })
      } catch (e) {
        commit('successConsolidationBtn')
        commit('setLoadingEnd')
        return e;
      }
    },

    async getConsolidationProformFile({commit}, data) {
      commit('changeConsolidationBtn');
      try {
        return await API.post(`${prefix}/export-product-invoice`, data).then((response) =>{
          commit('successConsolidationBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationBtn');
        return e;
      }
    },

    async payConsolidation({commit}, data) {
      commit('changeConsolidationBtn');
      try {
        return await API.post(`${prefix}/pay`, data).then((response) =>{
          commit('successConsolidationBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationBtn');
        return e;
      }
    },

    async getCostConsolidation({commit}, id) {
      commit('changeConsolidationBtn');
      try {
        return await API.get(`${prefix}/shipping-cost/${id}`).then((response) =>{
          commit('successConsolidationBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationBtn');
        return e;
      }
    },

    async getExportConsolidation({commit}, {filter, exportType}) {
      commit('setExportBtnLoadingStart')
      commit('setLoadingEnd')
      try {
        return await API.get(`${prefix}/export/${exportType}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

    async getConsolidationProductCellsPdf({commit}, data) {
      commit('changeConsolidationBtn');
      try {
        return await API.post(`${prefix}/pdf-product-list`, data).then((response) =>{
          commit('successConsolidationBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationBtn');
        return e;
      }
    },

    async updateConsolidationProductInCells({commit}, {id, data}) {
      commit('changeOrdersInboundBtn')
      try {
        return await API.patch(`${prefix}/${id}`, data).then((response) =>{
          commit('successOrdersInboundBtn')
          return response;
        });
      } catch (e) {
        commit('successOrdersInboundBtn')
        return e;
      }
    },


    async updateConsolidationOrderCheck({commit}, {id, data}) {
      commit('changeConsolidationBtn');
      try {
        return await API.patch(`${prefix}/${id}/order-check`, data).then((response) =>{
          commit('successConsolidationBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationBtn');
        return e;
      }
    },

    // async consolidationDHLCreateLabel({commit}, data) {
    //   commit('changeConsolidationBtn');
    //   try {
    //     return await API.post(`/delivery-api${prefix}/dhl/create-label`, data).then((response) =>{
    //       commit('successConsolidationBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successConsolidationBtn');
    //     return e;
    //   }
    // },

    async consolidationCreateLabel({commit}, data) {
      commit('changeConsolidationBtn');
      try {
        return await API.post(`/delivery-api${prefix}/create-label`, data).then((response) =>{
          commit('successConsolidationBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationBtn');
        return e;
      }
    },

    async consolidationCallCourier({commit}, {data: data}) {
      commit('changeConsolidationBtn');
      try {
        return await API.post(`delivery-api${prefix}/courier-call`, data).then((response) =>{
          console.log(response);
          commit('successConsolidationBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationBtn');
        return e;
      }
    },

    async consolidationCancelCourier({commit}, {data: data}) {
      commit('changeConsolidationBtn');
      try {
        return await API.post(`delivery-api${prefix}/courier-cancel`, data).then((response) =>{
          console.log(response);
          commit('successConsolidationBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationBtn');
        return e;
      }
    },

    async consolidationAfterInvoicePayStatusUpdate({commit}, id) {
      try {
        return await API.patch(`${prefix}/after-pay-processing/${id}`).then((response) =>{
          return response
        })
      } catch (e) {
        commit('successConsolidationBtn')
        return e;
      }
    },

    async consolidationRefundLabel({commit}, data) {
      commit('changeConsolidationBtn');
      try {
        return await API.post(`delivery-api${prefix}/refund-label`, data).then((response) =>{
          console.log(response);
          commit('successConsolidationBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationBtn');
        return e;
      }
    },

    async consolidationStopOrder({commit}, id) {
      commit('changeConsolidationBtn');
      try {
        return await API.post(`${prefix}/stop-consolidation/${id}`).then((response) =>{
          console.log(response);
          commit('successConsolidationBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationBtn');
        return e;
      }
    },
  }
}
