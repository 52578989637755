import {API} from "../../axios/axiosMainUrl";

export const newPayments = {
  state: {
    newPayments: [],
    newPaymentsItem: {},
    newPaymentsCommonList: [],
    newPaymentsBtn: false,
    newPaymentsLoading: true,
    nextNewPaymentsPage: false,
    newPaymentsForPage: 25,
    newPaymentsFilter: '',
  },

  mutations: {
    setNewPaymentsFilter: (state, payload) => {
      state.newPaymentsFilter = payload
    },

    setNewPayments: (state, payload) => {
      if(state.nextNewPaymentsPage) {
        state.newPayments = state.newPayments.concat(payload.newPayments)
      } else {
        state.newPayments = payload.newPayments
      }
    },

    setNewPaymentsCommonList: (state, payload) => {
      state.newPaymentsCommonList = payload.newPaymentsCommonList
    },

    setNextNewPaymentsPage: (state, payload) => {
      state.nextNewPaymentsPage = payload
    },

    setNewPaymentsItem: (state, payload) => {
      state.newPaymentsItem = payload.newPaymentsItem
    },

    setNewPaymentsLoadingStart: (state) => {
      state.newPaymentsLoading = true
    },

    setNewPaymentsLoadingEnd: (state) => {
      state.newPaymentsLoading = false
    },

    changeNewPaymentsBtn: (state) => {
      state.newPaymentsBtn = true
    },
    successNewPaymentsBtn: (state) => {
      state.newPaymentsBtn = false
    },
  },

  getters: {
    getNewPaymentsFilter: state => {
      return state.newPaymentsFilter
    },

    getNewPaymentsForPage: state => {
      return state.newPaymentsForPage
    },

    getNewPayments: state => {
      return state.newPayments
    },

    getNewPaymentsLoading: state => {
      return state.newPaymentsLoading
    },


    getNextNewPaymentsPage: state => {
      return state.nextNewPaymentsPage
    },

    getNewPaymentsItem: state => {
      return state.newPaymentsItem
    },

    getNewPaymentsCommonList: state => {
      return state.newPaymentsCommonList
    },

    getNewPaymentsBtn: state => {
      return state.newPaymentsBtn
    },
  },

  actions: {

    async fetchNewPayments({commit, getters}, filter = '') {
      if(!getters.getNextNewPaymentsPage)
        commit('setNewPaymentsLoadingStart')
      try {
        return await API.get(`/payments/other${filter}`).then((response) =>{
          commit('setNewPayments',{newPayments: response.data.data.data})
          commit('setNewPaymentsCommonList',{newPaymentsCommonList: response.data.data})
          commit('setNewPaymentsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setNewPaymentsLoadingEnd');
        return e;
      }
    },

    async getNewPayments({commit}, id) {
      // commit('setNewPaymentsLoadingStart');
      try {
        return await API.get(`/payments/${id}`).then((response) =>{
          console.log(response)
          commit('setNewPaymentsItem', {newPaymentsItem: response.data.data})
          // commit('setNewPaymentsLoadingEnd');
          return response;
        });
      } catch (e) {
        // commit('setNewPaymentsLoadingEnd');
        return e;
      }
    },

    async createNewPayments({commit}, data) {
      commit('changeNewPaymentsBtn');
      try {
        return await API.post(`/payments/other`, data).then((response) =>{
          commit('successNewPaymentsBtn');
          return response;
        });
      } catch (e) {
        commit('successNewPaymentsBtn');
        return e;
      }
    },

    async updateNewPayments({commit}, {id, data}) {
      commit('changeNewPaymentsBtn');
      try {
        return await API.put(`/payments/other/${id}`, data).then((response) =>{
          commit('successNewPaymentsBtn');
          return response;
        });
      } catch (e) {
        commit('successNewPaymentsBtn');
        return e;
      }
    },

    async updateCommentNewPayments({commit}, {id, data}) {
      commit('changeNewPaymentsBtn');
      try {
        return await API.put(`/payments/other/update-comment/${id}`, data).then((response) =>{
          commit('successNewPaymentsBtn');
          return response;
        });
      } catch (e) {
        commit('successNewPaymentsBtn');
        return e;
      }
    },

    async deleteNewPayments({commit}, id) {
      try {
        return await API.delete(`/payments/other/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successNewPaymentsBtn');
        return e;
      }
    },

    async payNewPayments({commit}, id) {
      commit('changeNewPaymentsBtn');
      try {
        return await API.put(`/payments/other/pay/${id}`).then((response) =>{
          commit('successNewPaymentsBtn');
          return response;
        });
      } catch (e) {
        commit('successNewPaymentsBtn');
        return e;
      }
    },

    async getExportNewPayments({commit}, {filter , type}) {
      commit('setExportBtnLoadingStart')
      commit('setLoadingEnd')
      try {
        return await API.get(`/payments/other/export/${type}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

  }
}
