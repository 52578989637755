<template>
  <div class="file-layout">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: "FileLayout"
  }
</script>

<style scoped>

</style>
