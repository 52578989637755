<template>
<div class="fragment">
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{$t('fbm_status.localization_value.value')}}</div>
    <div class="history-table__sub-col"><span v-if="pevItem">{{$t(getStatusItemPrev + '.localization_value.value')}}</span> <span v-else>{{getStatusItemPrev}}</span></div>
    <div class="history-table__sub-col">{{$t(getStatusItem + '.localization_value.value')}}</div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_WasSentAt.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.delivery_date'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.delivery_date'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_TrackingNumber.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.tracking_number'" /></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.tracking_number'" /></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_OrderPrice.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.order_fee.cost'" /></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.order_fee.cost'" /></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_shippingCost.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.delivery_cost'" /></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.delivery_cost'" /></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_PrepaidTotal.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.prepay_delivery_cost'" /></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.prepay_delivery_cost'" /></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_SkladUsaFee.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.order_admin_fee'" /></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.order_admin_fee'" /></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col w-100 mw-100"><b>{{$t('fbm_CustomerInformation.localization_value.value')}}</b></div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_ContactName.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.recipient_contact_name'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.recipient_contact_name'"/></div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_PhoneNumber.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.recipient_phone_number'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.recipient_phone_number'"/></div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_CompanyName.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.recipient_company_name'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.recipient_company_name'"/></div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_Email.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.recipient_email'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.recipient_email'"/></div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_Address.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.recipient_address'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.recipient_address'"/></div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('common_addressSecond.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.recipient_address_1'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.recipient_address_1'"/></div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_City.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.recipient_city'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.recipient_city'"/></div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_Region.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.recipient_region'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.recipient_region'"/></div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_ZipCode.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.recipient_zip_code'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.recipient_zip_code'"/></div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_Country.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.recipient_country.name'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.recipient_country.name'"/></div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_CommentOptional.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.comment'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.comment'"/></div>
  </div>


<!--  <div class="history-table__sub-row">-->
<!--    <div class="history-table__sub-col w-100 mw-100"><b>{{$t('fbm_OrderDetails.localization_value.value')}}</b></div>-->
<!--  </div>-->
  <!--    /////////////-->
  <div class="history-table__sub-row">
    <div class="history-table__sub-col w-100 mw-100"><b>{{$t('fbm_ProductConsolidation.localization_value.value')}}</b></div>
  </div>

  <template v-for="(proform, index) in item.history.proform_entities">
<!--    <CellsTable-->
<!--        :key="index + 'cells'"-->
<!--        :pevCells="pevItem && _.has(pevItem.history.proform_entities, index) ? pevItem.history.proform_entities[index].cells : pevItem"-->
<!--        :itemCells="proform.cells"-->
<!--    />-->
    <ProformTable
        :key="index + 'proform'"
        :pevItem="pevItem && _.has(getActualOrderProform, index) ? {history: getActualOrderProform[index]} : null"
        :item="{history: proform}"
    />
  </template>
  <template v-if="pevItem && getDeletedOrderProform">
    <template v-for="(proform, index) in getDeletedOrderProform">
<!--      <CellsTable-->
<!--          :key="index + 'cellsPrev'"-->
<!--          :pevCells="proform.cells"-->
<!--          :itemCells="null"-->
<!--      />-->
      <ProformTable
          :key="index + 'proform0'"
          :pevItem="{history: proform}"
          :item="null"
      />
    </template>
  </template>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col w-100 mw-100"><b>{{$t('fbm_ProductWarehouse.localization_value.value')}}</b></div>
  </div>

  <template v-for="(product, index) in item.history.product_entities">
    <CellsTable
        :key="index + 'cells'"
        :pevCells="pevItem && _.has(pevItem.history.product_entities, index) ? pevItem.history.product_entities[index].cells : null"
        :itemCells="product.cells"
    />
    <ProductTable
        :key="index + 'product'"
        :quantity="true"
        :hsCode="true"
        :description="true"
        :price="true"
        :pevItem="pevItem && _.has(pevItem.history.product_entities, index) ? pevItem.history.product_entities[index] : pevItem"
        :item="product"
    />
  </template>
  <template v-if="pevItem && pevItem.history.product_entities.length > item.history.product_entities.length">
    <template v-for="(product, index) in pevItem.history.product_entities">
      <template
          v-if="index > item.history.product_entities.length - 1">
        <CellsTable
            :key="index + 'cellsPrev'"
            :pevCells="product.cells"
            :itemCells="[]"
        />
        <ProductTable
            :key="index + 'product0'"
            :quantity="true"
            :hsCode="true"
            :description="true"
            :price="true"
            :pevItem="product"
            :item="null"
        />
      </template>
    </template>
  </template>

<!--  ENGRAVINGS  -->
  <div class="history-table__sub-row">
    <div class="history-table__sub-col w-100 mw-100"><b> {{$t('fbm_EngravingFileName.localization_value.value')}}</b></div>
  </div>
  <div v-for="(engraving, engravingIndex) in item.history.engravings" :key="engravingIndex">
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('fbm_engravingLength.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.engravings.' + engravingIndex + '.length'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="engraving" :deep="'length'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('fbm_engravingWidth.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.engravings.' + engravingIndex + '.width'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="engraving" :deep="'width'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('fbm_engravingCount.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.engravings.' + engravingIndex + '.count'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="engraving" :deep="'count'"/></div>
    </div>
<!--    <div class="history-table__sub-row">-->
<!--      <div class="history-table__sub-col">{{ $t('calculatorEngraving_product.localization_value.value') }}</div>-->
<!--      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.engravings.' + engravingIndex + '.cost'"/></div>-->
<!--      <div class="history-table__sub-col"><ValueHelper :value="engraving" :deep="'cost'"/></div>-->
<!--    </div>-->
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('fbm_engravingPrice.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.engravings.' + engravingIndex + '.cost'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="engraving" :deep="'cost'"/></div>
    </div>

    <template v-if="engraving.files">
      <div class="history-table__sub-row" v-for="(cusFile, cusFileIndex) in engraving.files" :key="cusFileIndex">
        <div class="history-table__sub-col">Customization file</div>
        <div class="history-table__sub-col">
          <template v-if="pevItem && pevItem.history.engravings && pevItem.history.engravings[engravingIndex] &&
            pevItem.history.engravings[engravingIndex].files && pevItem.history.engravings[engravingIndex].files[cusFileIndex]">
            <FileBlock :files="pevItem.history.engravings[engravingIndex].files" :file="pevItem.history.engravings[engravingIndex].files[cusFileIndex]"/>
          </template>
          <template v-else>—</template>
        </div>
        <div class="history-table__sub-col">
          <template><FileBlock :files="engraving.files" :file="cusFile"/></template>
<!--          <template v-else>—</template>-->
        </div>
      </div>
    </template>

  </div>




  <div class="history-table__sub-row">
    <div class="history-table__sub-col w-100 mw-100"><b>{{$t('fbm_UserDimentions.localization_value.value')}}</b></div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_DimentionsWeightLB.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.order_dimensions.0.imperial.weight_lb'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.order_dimensions.0.imperial.weight_lb'"/></div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_DimentionsWeightOZ.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.order_dimensions.0.imperial.weight_oz'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.order_dimensions.0.imperial.weight_oz'"/></div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_DimentionsHeight.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.order_dimensions.0.height'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.order_dimensions.0.height'"/></div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_DimentionsWidth.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.order_dimensions.0.width'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.order_dimensions.0.width'"/></div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_DimentionsLength.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.order_dimensions.0.length'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.order_dimensions.0.length'"/></div>
  </div>


  <div class="history-table__sub-row">
    <div class="history-table__sub-col w-100 mw-100"><b>Admin {{$t('fbm_Dimentions.localization_value.value')}}</b></div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_DimentionsWeightLB.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.order_admin_dimensions.imperial.weight_lb'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.order_admin_dimensions.imperial.weight_lb'"/></div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_DimentionsWeightOZ.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.order_admin_dimensions.imperial.weight_oz'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.order_admin_dimensions.imperial.weight_oz'"/></div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_DimentionsHeight.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.order_admin_dimensions.imperial.height_inch'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.order_admin_dimensions.imperial.height_inch'"/></div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_DimentionsWidth.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.order_admin_dimensions.imperial.width_inch'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.order_admin_dimensions.imperial.width_inch'"/></div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_DimentionsLength.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.order_admin_dimensions.imperial.length_inch'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.order_admin_dimensions.imperial.length_inch'"/></div>
  </div>

<!--  <div class="history-table__sub-row">-->
<!--    <div class="history-table__sub-col w-100 mw-100"><b>{{$t('fbm_ShippingPartner.localization_value.value')}}</b></div>-->
<!--  </div>-->
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_ShippingPartner.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.delivery_method.name'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.delivery_method.name'"/></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('FBM_marketplace.localization_value.value') }}</div>
    <div class="history-table__sub-col">
      <ValueHelper v-if="pevItem && pevItem.history.vat_tax && pevItem.history.vat_tax.use_vat_id === 1 && (isDPD(pevItem) || isPostNL(pevItem))"
                   :value="pevItem" :deep="'history.order_ioss.marketplace'"/>
      <span v-else>—</span>
    </div>
    <div class="history-table__sub-col">
      <ValueHelper v-if="item.history.vat_tax && item.history.vat_tax.use_vat_id === 1 && (isDPD(item) || isPostNL(item))"
                   :value="item" :deep="'history.order_ioss.marketplace'"/>
      <span v-else>—</span>
    </div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('FBM_IOSS.localization_value.value') }}</div>
    <div class="history-table__sub-col">
      <ValueHelper v-if="pevItem && pevItem.history.vat_tax && pevItem.history.vat_tax.use_vat_id === 1 && (isDPD(pevItem) || isPostNL(pevItem))"
                   :value="pevItem" :deep="'history.order_ioss.number'"/>
      <span v-else>—</span>
    </div>
    <div class="history-table__sub-col">
      <ValueHelper v-if="item.history.vat_tax && item.history.vat_tax.use_vat_id === 1 && (isDPD(item) || isPostNL(item))"
                   :value="item" :deep="'history.order_ioss.number'"/>
      <span v-else>—</span>
    </div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('common_vatNumber.localization_value.value') }}</div>
    <div class="history-table__sub-col">
      <ValueHelper v-if="pevItem && pevItem.history.vat_tax && pevItem.history.vat_tax.use_vat_id === 1 && !isDPD(pevItem) && !isPostNL(pevItem)" :value="pevItem" :deep="'history.vat_tax.vat_tax'"/>
      <span v-else>—</span>
    </div>
    <div class="history-table__sub-col">
      <ValueHelper v-if="item.history.vat_tax && item.history.vat_tax.use_vat_id === 1 && !isDPD(item) && !isPostNL(item)" :value="item" :deep="'history.vat_tax.vat_tax'"/>
      <span v-else>—</span>
    </div>
  </div>

<!--  <div class="history-table__sub-row">-->
<!--    <div class="history-table__sub-col">{{ $t('common_vatNumber.localization_value.value') }}</div>-->
<!--    <div class="history-table__sub-col"><span v-if="pevItem && pevItem.history.addition_info && !Array.isArray(pevItem.history.addition_info.info)">—</span><ValueHelper v-else :value="pevItem" :deep="'history.vat_tax.vat_tax'"/></div>-->
<!--    <div class="history-table__sub-col"><span v-if="item.history.addition_info && !Array.isArray(item.history.addition_info.info)">—</span><ValueHelper v-else :value="item" :deep="'history.vat_tax.vat_tax'"/></div>-->
<!--  </div>-->

<!--  <div class="history-table__sub-row" v-if="(pevItem && _.find(pevItem.history.files, {type: 'vat_tax'})) || _.find(item.history.files, {type: 'vat_tax'})">-->
<!--    <div class="history-table__sub-col">{{$t('common_vatNumber.localization_value.value')}} file</div>-->
<!--    <div class="history-table__sub-col">-->
<!--      <template v-if="pevItem && _.find(pevItem.history.files, {type: 'vat_tax'})"><FileBlock :files="pevItem.history.files" :file="_.find(pevItem.history.files, {type: 'vat_tax'})"/></template>-->
<!--      <template v-else>—</template>-->
<!--    </div>-->
<!--    <div class="history-table__sub-col">-->
<!--      <template v-if="_.find(item.history.files, {type: 'vat_tax'})"><FileBlock :files="item.history.files" :file="_.find(item.history.files, {type: 'vat_tax'})"/></template>-->
<!--      <template v-else>—</template>-->
<!--    </div>-->
<!--  </div>-->

  <div class="history-table__sub-row" v-if="pevItem && pevItem.history.vat_tax && pevItem.history.vat_tax.vat_tax_file || item.history.vat_tax && item.history.vat_tax.vat_tax_file">
    <div class="history-table__sub-col">{{$t('common_vatNumber.localization_value.value')}} file</div>
    <div class="history-table__sub-col">
      <template v-if="pevItem && pevItem.history.vat_tax && pevItem.history.vat_tax.use_vat_id === 1 && pevItem.history.vat_tax.vat_tax_file.length > 0"><FileBlock :files="pevItem.history.vat_tax.vat_tax_file" :file="_.first(pevItem.history.vat_tax.vat_tax_file)"/></template>
      <template v-else>—</template>
    </div>
    <div class="history-table__sub-col">
      <template v-if="item.history.vat_tax && item.history.vat_tax.use_vat_id === 1 && item.history.vat_tax.vat_tax_file.length > 0"><FileBlock :files="item.history.vat_tax.vat_tax_file" :file="_.first(item.history.vat_tax.vat_tax_file)"/></template>
      <template v-else>—</template>
    </div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">
      {{$t('fbm_TheSignFromRecepient.localization_value.value')}}
    </div>
    <div class="history-table__sub-col" >
      <DefaultCheckbox
          v-if="pevItem"
          :value="pevItem.history.require_signature"
          :disabled="true"
      />
      <template v-else>—</template>
    </div>
    <div class="history-table__sub-col" >
      <DefaultCheckbox
          v-if="item"
          :value="item.history.require_signature"
          :disabled="true"
      />
      <template v-else>—</template>
    </div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_transactionAmount.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.addition_info.info.tax_clearance.total_sum'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.addition_info.info.tax_clearance.total_sum'"/></div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_customsClearance.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.addition_info.info.tax_clearance.cost'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.addition_info.info.tax_clearance.cost'"/></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_Packaging.localization_value.value') }}</div>
    <div class="history-table__sub-col">
      <template v-if="!pevItem || !pevItem.history.packing_type">
        —
      </template>
      <template v-else>
        <ValueHelper class="mr-1" :value="pevItem" :deep="'history.packing_type.currentTranslate.name'"/>
        <template v-if="pevItem.history.packing_price && pevItem.history.packing_price.price">
          (<ValueHelper class="mr-1" :value="pevItem" :deep="'history.packing_price.currentTranslate.name'"/>
          $<ValueHelper :value="pevItem" :deep="'history.packing_price.price'"/>)
        </template>
      </template>
    </div>
    <div class="history-table__sub-col">
      <template v-if="!item || !item.history.packing_type">
        —
      </template>
      <template v-else>
        <ValueHelper class="mr-1" :value="item" :deep="'history.packing_type.currentTranslate.name'"/>
        <template v-if="item.history.packing_price && item.history.packing_price.price">
          (<ValueHelper class="mr-1" :value="item" :deep="'history.packing_price.currentTranslate.name'"/>
          $<ValueHelper :value="item" :deep="'history.packing_price.price'"/>)
        </template>
      </template>
    </div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_giftPackaging.localization_value.value') }}</div>
    <div class="history-table__sub-col">
      <template v-if="!pevItem || !pevItem.history.order_gift_packing">
        —
      </template>
      <template v-else>
        <template v-if="pevItem.history.order_gift_packing && pevItem.history.order_gift_packing.price">
          <ValueHelper class="mr-1" :value="pevItem" :deep="'history.order_gift_packing.currentTranslate.name'"/>
          $<ValueHelper :value="pevItem" :deep="'history.order_gift_packing.price'"/>
        </template>
      </template>
    </div>
    <div class="history-table__sub-col">
      <template v-if="!item || !item.history.order_gift_packing">
        —
      </template>
      <template v-else>
        <template v-if="item.history.order_gift_packing && item.history.order_gift_packing.price">
          <ValueHelper class="mr-1" :value="item" :deep="'history.order_gift_packing.currentTranslate.name'"/>
          $<ValueHelper :value="item" :deep="'history.order_gift_packing.price'"/>
        </template>
      </template>
    </div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_InsuranceAmount.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.insurance_amount'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.insurance_amount'"/></div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_InsuranceExtended.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.insurance.price'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.insurance.price'"/></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_GiftMessage.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.gift_paper.name'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.gift_paper.name'"/></div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">Gift Price</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.gift_paper.price'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.gift_paper.price'"/></div>
  </div>
  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_GiftMessageLabel.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.gift_message'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.gift_message'"/></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_insurancePrice.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.payed_invoice.summary_data.insurance_from_delivery_system'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.payed_invoice.summary_data.insurance_from_delivery_system'"/></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_OrderPrice.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.payed_invoice.order_price_amount'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.payed_invoice.order_price_amount'"/></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('invoices_Total.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.payed_invoice.total_amount'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.payed_invoice.total_amount'"/></div>
  </div>


  <div class="history-table__sub-row" v-if="(pevItem && _.find(pevItem.history.files, {type: 'orderFbmPdfLabelFileType'})) || _.find(item.history.files, {type: 'orderFbmPdfLabelFileType'})">
    <div class="history-table__sub-col">{{$t('fbm_LabelAdmin.localization_value.value')}} file</div>
    <div class="history-table__sub-col">
      <template v-if="pevItem && _.findLast(pevItem.history.files, {type: 'orderFbmPdfLabelFileType'})"><FileBlock :files="pevItem.history.files" :file="_.findLast(pevItem.history.files, {type: 'orderFbmPdfLabelFileType'})"/></template>
      <template v-else>—</template>
    </div>
    <div class="history-table__sub-col">
      <template v-if="_.findLast(item.history.files, {type: 'orderFbmPdfLabelFileType'})"><FileBlock :files="item.history.files" :file="_.findLast(item.history.files, {type: 'orderFbmPdfLabelFileType'})"/></template>
      <template v-else>—</template>
    </div>
  </div>


  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_apc.localization_value.value') }}</div>
    <div class="history-table__sub-col"><span v-if="pevItem && pevItem.history.consolidation_order_union">#<ValueHelper :value="pevItem" :deep="'history.consolidation_order_union.id'" /></span><span v-else>—</span></div>
    <div class="history-table__sub-col"><span v-if="item && item.history.consolidation_order_union">#<ValueHelper :value="item" :deep="'history.consolidation_order_union.id'" /></span><span v-else>—</span></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_AdminReturnId.localization_value.value') }}</div>
    <div class="history-table__sub-col"><span v-if="pevItem && pevItem.history.return_parcel">#<ValueHelper :value="pevItem" :deep="'history.return_parcel.id'" /></span><span v-else>—</span></div>
    <div class="history-table__sub-col"><span v-if="item && item.history.return_parcel">#<ValueHelper :value="item" :deep="'history.return_parcel.id'" /></span><span v-else>—</span></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_ConsoID.localization_value.value') }}</div>
    <div class="history-table__sub-col"><span v-if="pevItem && pevItem.history.consolidation">#<ValueHelper :value="pevItem" :deep="'history.consolidation.id'" /></span><span v-else>—</span></div>
    <div class="history-table__sub-col"><span v-if="item && item.history.consolidation">#<ValueHelper :value="item" :deep="'history.consolidation.id'" /></span><span v-else>—</span></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_DHLTracknumber.localization_value.value') }}</div>
    <div class="history-table__sub-col"><span v-if="pevItem && pevItem.history.consolidation">#<ValueHelper :value="pevItem" :deep="'history.consolidation.user_tracking_number'" /></span><span v-else>—</span></div>
    <div class="history-table__sub-col"><span v-if="item && item.history.consolidation">#<ValueHelper :value="item" :deep="'history.consolidation.user_tracking_number'" /></span><span v-else>—</span></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('fbm_Paid.localization_value.value') }}</div>
    <div class="history-table__sub-col">
      <StatusIcoBtn :type="'active'" class="mr-1" v-if="pevItem && pevItem.history.prepay"/>
      <StatusIcoBtn :type="'cancel-red'" class="mr-1" v-else/>
    </div>
    <div class="history-table__sub-col">
      <StatusIcoBtn :type="'active'" class="mr-1" v-if="item && item.history.prepay"/>
      <StatusIcoBtn :type="'cancel-red'" class="mr-1" v-else/>
    </div>
  </div>

</div>
</template>

<script>
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import ProformTable from "@/components/coreComponents/Popups/HistoryOfChangesPopup/chunks/ProformTable/ProformTable";
import ProductTable from "@/components/coreComponents/Popups/HistoryOfChangesPopup/chunks/ProductTable/PackagingTable";
import {
  DPD_SHIPPING_COMPANY,
  FBM_COPY_STATUS,
  FBM_STATUSES,
  POSTNL_SHIPPING_COMPANY
} from "@/staticData/staticVariables";
import CellsTable from "@/components/coreComponents/Popups/HistoryOfChangesPopup/chunks/CellsTable/CellsTable";
import StatusIcoBtn from "@/components/UI/status/StatusIcoBtn/StatusIcoBtn";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import FileBlock from "@/components/coreComponents/Popups/HistoryOfChangesPopup/chunks/FileBlock/FileBlock";

export default {
  name: "FBMType",

  components: {FileBlock, DefaultCheckbox, StatusIcoBtn, CellsTable, ProductTable, ProformTable, ValueHelper},

  props: {
    pevItem: Object,
    item: Object,
  },

  computed: {


    getStatusItem() {
      return this._.find(Object.assign(FBM_STATUSES, {FBM_COPY_STATUS}), {value: this.item.history.status})?.translation
    },
    getStatusItemPrev() {
      if(!this.pevItem) return '—'
      return this._.find(Object.assign(FBM_STATUSES, {FBM_COPY_STATUS}), {value: this.pevItem.history.status})?.translation
    },

    getActualOrderProform() {
      if(!this.pevItem) return false
      if(!this.item.history?.deleted_proform_ids?.proform_data) return this.pevItem?.history?.proform_entities

      let actualProform = []
      let deletedIds = this.item.history?.deleted_proform_ids?.proform_data

      this.pevItem?.history?.proform_entities.map(prevProformItem => {
        let foundDeleted = false
        deletedIds.map(deletedId => {
          if(parseInt(prevProformItem.id) === parseInt(deletedId)) foundDeleted = true
        })
        if(!foundDeleted) actualProform.push(prevProformItem)
      })
      if(actualProform.length > 0) return actualProform

      return false
    },

    getDeletedOrderProform() {
      if(!this.pevItem || !this.item.history?.deleted_proform_ids?.proform_data) return false

      let deletedProform = []
      let deletedIds = this.item.history?.deleted_proform_ids?.proform_data

      deletedIds.map(deletedId => {
        let product = this._.find(this.pevItem?.history?.proform_entities, {id: parseInt(deletedId)})
        if(product) deletedProform.push(product)
      })
      console.log(deletedIds);
      console.log(this.pevItem?.history?.proform_entities);
      if(deletedProform.length > 0) return deletedProform

      return false
    },
  },

  methods: {

    isDPD(item) {
      return item.history?.delivery_method?.id === DPD_SHIPPING_COMPANY.id
    },
    isPostNL(item) {
      return item.history?.delivery_method?.id === POSTNL_SHIPPING_COMPANY.id
    },
  }


}
</script>

<style scoped>

</style>