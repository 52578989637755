
// import HeaderNotification from './components/HeaderNotification/HeaderNotification'
import HeaderSettings from './components/HeaderSettings/HeaderSettings'
import SearchInput from '../../UI/inputs/SearchInput/SearchInput'
import BurgerIco from '../../../../public/img/header/burger-icon.svg?inline'
import BurgerCloseIco from '../../../../public/img/header/burger-close-icon.svg?inline'
import HeaderAdmin from "./HeaderAdmin/HeaderAdmin";
import GuideBlock from "../../coreComponents/GuideBlock/GuideBlock";

export default {
  name: "Header",

  components: {
    // HeaderNotification,
    HeaderSettings,
    SearchInput,
    BurgerIco,
    BurgerCloseIco,
    HeaderAdmin,
    GuideBlock
  },

  props: [
    'showMobileMenu'
  ],

  data() {
    return {
      search: '',
    }
  },

  methods: {
    toggleMobileMenu(){
      // this.$emit('toggleMenu', !this.showMobileMenu);
      this.$store.commit('SET_MOBILE_MENU', !this.$store.getters.GET_MOBILE_MENU)
    },

    multiSearch(e, checkPressedKey = false, searchString = false){
      if (checkPressedKey && e.code !== 'Enter' && e.code !== 'NumpadEnter') return

      if (searchString) {
        this.search = searchString
      }
      this.$router.push(this.$store.getters.GET_PATHS.multiSearch + `/${this.search}`)
    },

    logout() {
      this.$store.dispatch('logOut')
      //clear authorized user data
      this.$store.commit('SET_COMMON_AUTHORIZED', {COMMON_AUTHORIZED: {} })
    }
  },



}
