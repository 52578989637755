import {API} from "../../axios/axiosMainUrl";

export const importOrders = {
  state: {
    importOrders: [],
    importOrdersItem: {},
    importOrdersCommonList: [],
    importOrdersBtn: false,
    importOrdersLoading: true,
    nextImportOrdersPage: false,
    importOrdersFilter: '',

    importOrdersForPage: 25,

    headerDefaultShops: {
      etsy: {
        type: 'etsy',
        name: 'Etsy',
        ico: '/img/company-icons-group/tab-etsy.png',
      },
      ebay: {
        type: 'ebay',
        name: 'Ebay',
        ico: '/img/company-icons-group/tab-ebay.png'
      },
      amazonmws: {
        type: 'amazonmws',
        name: 'Amazon',
        ico: '/img/company-icons-group/tab-amozon.png'
      },
      opencart: {
        type: 'opencart',
        name: 'Opencart',
        ico: '/img/company-icons-group/tab-opencart.png',
      },
      woocommerce: {
        type: 'woocommerce',
        name: 'Woocommerce',
        ico: '/img/company-icons-group/tab-woo-commerce.png',
      },
      shopify: {
        type: 'shopify',
        name: 'Shopify',
        ico: '/img/company-icons-group/tab-shopify.png',
      },
    }
  },

  mutations: {
    setImportOrdersFilter: (state, payload) => {
      state.importOrdersFilter = payload
    },

    setImportOrdersForPage: (state, payload) => {
      state.importOrdersForPage = payload
    },

    setImportOrders: (state, payload) => {
      if(state.nextImportOrdersPage){
        state.importOrders = state.importOrders.concat(payload.importOrders)
      } else {
        state.importOrders = payload.importOrders
      }
    },

    setNextImportOrdersPage: (state, payload) => {
      state.nextImportOrdersPage = payload
    },

    setImportOrdersItem: (state, payload) => {
      state.importOrdersItem = payload.importOrdersItem
    },

    setImportOrdersCommonList: (state, payload) => {
      state.importOrdersCommonList = payload.importOrdersCommonList
    },

    setImportOrdersLoadingStart: (state) => {
      state.importOrdersLoading = true
    },

    setImportOrdersLoadingEnd: (state) => {
      state.importOrdersLoading = false
    },

    changeImportOrdersBtn: (state) => {
      state.importOrdersBtn = true
    },
    successImportOrdersBtn: (state) => {
      state.importOrdersBtn = false
    },
  },

  getters: {
    getImportOrdersFilter: state => {
      return state.importOrdersFilter
    },

    getImportOrders: state => {
      return state.importOrders
    },

    getNextImportOrdersPage: state => {
      return state.nextImportOrdersPage
    },

    getImportOrdersLoading: state => {
      return state.importOrdersLoading
    },

    getImportOrdersItem: state => {
      return state.importOrdersItem
    },

    getImportOrdersCommonList: state => {
      return state.importOrdersCommonList
    },

    getImportOrdersBtn: state => {
      return state.importOrdersBtn
    },

    getHeaderDefaultShops: state => {
      return state.headerDefaultShops
    },

    getImportOrdersForPage: state => {
      return state.importOrdersForPage
    },
  },

  actions: {

    async fetchImportOrders({commit, getters}, filter = '') {
      if(!getters.getNextImportOrdersPage)
        commit('setImportOrdersLoadingStart')

      try {
        return await API.get(`/orders-external${filter}`).then((response) =>{
          commit('setImportOrders',{importOrders: response.data.data.data})
          commit('setImportOrdersCommonList',{importOrdersCommonList: response.data.data})
          commit('setImportOrdersLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setImportOrdersLoadingEnd');
        return e;
      }
    },

    async getImportOrders({commit}, id) {
      // commit('setImportOrdersLoadingStart');
      try {
        return await API.get(`/orders-external/${id}`).then((response) =>{
          console.log(response)
          commit('setImportOrdersItem', {importOrdersItem: response.data.data})
          // commit('setImportOrdersLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setImportOrdersLoadingEnd');
        return e;
      }
    },

    async createImportOrders({commit}, data) {
      commit('changeImportOrdersBtn');
      try {
        return await API.post(`/orders-external`, data).then((response) =>{
          commit('successImportOrdersBtn');
          return response;
        });
      } catch (e) {
        commit('successImportOrdersBtn');
        return e;
      }
    },

    async updateImportOrders({commit}, {id, data}) {
      commit('changeImportOrdersBtn');
      try {
        return await API.put(`/orders-external/${id}`, data).then((response) =>{
          commit('successImportOrdersBtn');
          return response;
        });
      } catch (e) {
        commit('successImportOrdersBtn');
        return e;
      }
    },

    async deleteImportOrders({commit}, id) {
      try {
        return await API.delete(`/orders-external/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successImportOrdersBtn');
        return e;
      }
    },

    async updateImportOrdersTrackingNumber({commit}, {id, data}) {
      commit('changeImportOrdersBtn');
      try {
        return await API.put(`/orders-external/${id}/set-tracking-number`, data).then((response) =>{
          commit('successImportOrdersBtn');
          return response;
        });
      } catch (e) {
        commit('successImportOrdersBtn');
        return e;
      }
    },

    async getExportImportOrders({commit}, {filter , type}) {
      commit('setExportBtnLoadingStart')
      commit('setLoadingEnd')
      try {
        return await API.get(`orders-external/export/${type}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

    async downloadOldImportOrders({commit}, {data , id}) {
      commit('changeImportOrdersBtn')
      try {
        return await API.post(`/orders-external/shop/${id}/import`, data).then((response) =>{
          commit('successImportOrdersBtn')
          return response
        })
      } catch (e) {
        commit('successImportOrdersBtn')
        return e;
      }
    },

    async getImportImportOrdersAmazon({commit}, {data, id}) {
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      commit('changeImportOrdersBtn')
      commit('setLoadingEnd')
      try {
        return await API.post(`/orders-external/shop/${id}/import-amazon`, data).then((response) =>{
          commit('successImportOrdersBtn')
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successImportOrdersBtn')
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async getImportSingleOrder({commit}, data) {
      commit('changeImportOrdersBtn')
      commit('setLoadingEnd')
      try {
        return await API.post(`/orders-external/shop/import-single-order`, data).then((response) =>{
          commit('successImportOrdersBtn')
          return response;
        });
      } catch (e) {
        commit('successImportOrdersBtn')
        return e;
      }
    },

    async getImportExampleXLSXImportOrdersAmazon({commit}) {
      commit('setLoadingEnd')
      try {
        return await API.get(`/orders-external/import-example/amazon-xlsx`, { responseType: 'blob' }).then((response) =>{
          return response
        })
      } catch (e) {
        return e;
      }
    },

  }
}
