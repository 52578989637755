import {API} from "../../axios/axiosMainUrl";

const prefix = '/warehouse/storage/log/storage-without-shipping'

export const inactiveFBMUsers = {
  state: {
    inactiveFBMUsers: [],
    inactiveFBMUsersItem: {},
    inactiveFBMUsersCommonList: [],
    inactiveFBMUsersBtn: false,
    inactiveFBMUsersLoading: true,
    nextInactiveFBMUsersPage: false,
    inactiveFBMUsersForPage: 25,
    inactiveFBMUsersFilter: '',
  },

  mutations: {
    setInactiveFBMUsersFilter: (state, payload) => {
      state.inactiveFBMUsersFilter = payload
    },

    setInactiveFBMUsers: (state, payload) => {
      if(state.nextInactiveFBMUsersPage) {
        state.inactiveFBMUsers = state.inactiveFBMUsers.concat(payload.inactiveFBMUsers)
      } else {
        state.inactiveFBMUsers = payload.inactiveFBMUsers
      }
    },

    setInactiveFBMUsersCommonList: (state, payload) => {
      state.inactiveFBMUsersCommonList = payload.inactiveFBMUsersCommonList
    },

    setNextInactiveFBMUsersPage: (state, payload) => {
      state.nextInactiveFBMUsersPage = payload
    },

    setInactiveFBMUsersItem: (state, payload) => {
      state.inactiveFBMUsersItem = payload.inactiveFBMUsersItem
    },

    setInactiveFBMUsersLoadingStart: (state) => {
      state.inactiveFBMUsersLoading = true
    },

    setInactiveFBMUsersLoadingEnd: (state) => {
      state.inactiveFBMUsersLoading = false
    },

    changeInactiveFBMUsersBtn: (state) => {
      state.inactiveFBMUsersBtn = true
    },
    successInactiveFBMUsersBtn: (state) => {
      state.inactiveFBMUsersBtn = false
    },
  },

  getters: {
    getInactiveFBMUsersFilter: state => {
      return state.inactiveFBMUsersFilter
    },

    getInactiveFBMUsersForPage: state => {
      return state.inactiveFBMUsersForPage
    },

    getInactiveFBMUsers: state => {
      return state.inactiveFBMUsers
    },

    getInactiveFBMUsersLoading: state => {
      return state.inactiveFBMUsersLoading
    },


    getNextInactiveFBMUsersPage: state => {
      return state.nextInactiveFBMUsersPage
    },

    getInactiveFBMUsersItem: state => {
      return state.inactiveFBMUsersItem
    },

    getInactiveFBMUsersCommonList: state => {
      return state.inactiveFBMUsersCommonList
    },

    getInactiveFBMUsersBtn: state => {
      return state.inactiveFBMUsersBtn
    },
  },

  actions: {

    async fetchInactiveFBMUsers({commit, getters}, filter = '') {
      if(!getters.getNextInactiveFBMUsersPage)
        commit('setInactiveFBMUsersLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          // commit('setInactiveFBMUsers',{inactiveFBMUsers: response.data.data})
          commit('setInactiveFBMUsers',{inactiveFBMUsers: response.data.data.data})
          commit('setInactiveFBMUsersCommonList',{inactiveFBMUsersCommonList: response.data.data})
          commit('setInactiveFBMUsersLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setInactiveFBMUsersLoadingEnd');
        return e;
      }
    },

    async getInactiveFBMUsers({commit}, id) {
      commit('setInactiveFBMUsersLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setInactiveFBMUsersItem', {inactiveFBMUsersItem: response.data.data})
          commit('setInactiveFBMUsersLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setInactiveFBMUsersLoadingEnd');
        return e;
      }
    },

    async createInactiveFBMUsers({commit}, data) {
      commit('changeInactiveFBMUsersBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successInactiveFBMUsersBtn');
          return response;
        });
      } catch (e) {
        commit('successInactiveFBMUsersBtn');
        return e;
      }
    },

    async updateInactiveFBMUsers({commit}, {id, data}) {
      commit('changeInactiveFBMUsersBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successInactiveFBMUsersBtn');
          return response;
        });
      } catch (e) {
        commit('successInactiveFBMUsersBtn');
        return e;
      }
    },

    async deleteInactiveFBMUsers({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successInactiveFBMUsersBtn');
        return e;
      }
    },

    async getExportInactiveFBMUsers({commit}, {filter , type}) {
      commit('changeInactiveFBMUsersBtn')
      try {
        return await API.get(`/warehouse/storage/log/storage-without-shipping/export/${type}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('successInactiveFBMUsersBtn')
          return response
        })
      } catch (e) {
        commit('successInactiveFBMUsersBtn')
        return e;
      }
    },

  }
}
