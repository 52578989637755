<template>
  <div class="fragment">
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_firstName.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.first_name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.first_name'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_lastName.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.last_name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.last_name'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_middleName.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.middle_name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.middle_name'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_fullName.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.full_name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.full_name'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_cardNumber.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.card_number'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.card_number'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_cardIdNumber.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.card_id_number'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.card_id_number'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_accountNumber.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.account_number'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.account_number'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_phone.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.phone'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.phone'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_email.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.email'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.email'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_address.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.address'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.address'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_country.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.country'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.country'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_city.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.city'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.city'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_state.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.state'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.state'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_postCode.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.post_code'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.post_code'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_correspondentBankName.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.correspondent_bank_name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.correspondent_bank_name'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_correspondentBankSwift.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.correspondent_bank_swift'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.correspondent_bank_swift'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_beneficiaryBankName.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.beneficiary_bank_name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.beneficiary_bank_name'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_beneficiaryBankSwift.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.beneficiary_bank_swift'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.beneficiary_bank_swift'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_iBan.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.iban'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.iban'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_recipientType.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.recipient_type'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.recipient_type'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_forUsa.localization_value.value')}}</div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem && _.has(pevItem.history, 'for_usa')" :value="pevItem.history.active === 1" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.for_usa === 1" :disabled="true"/><template v-else>—</template>
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_currency.localization_value.value')}}</div>
      <div class="history-table__sub-col">{{ getCurrency(pevItem) }}</div>
      <div class="history-table__sub-col">{{ getCurrency(item) }}</div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_routingNumber.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.routing_code'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.routing_code'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_usreou.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.usreou'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.usreou'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_type.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.bank'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.bank'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_cryptoName.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.crypto_name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.crypto_name'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_cryptoWallet.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.crypto_wallet'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.crypto_wallet'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_cryptoTag.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.crypto_tag'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.crypto_tag'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('account_cryptoExchangePlatform.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.crypto_exchange_platform'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.crypto_exchange_platform'"/></div>
    </div>



  </div>
</template>

<script>
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import {CURRENCIES} from "../../../../../../../../staticData/staticVariables";

export default {
  name: "AccountsType",

  components: {DefaultCheckbox, ValueHelper},

  props: {
    pevItem: Object,
    item: Object,
  },

  computed: {

  },

  methods: {
    getCurrency(item) {
      if (!item?.history.currency_id) return '—'

      let currencyItem = false
      Object.keys(CURRENCIES).forEach(itemFind => {
        if (CURRENCIES[itemFind].id.toString() === item?.history.currency_id.toString()) {
          currencyItem = CURRENCIES[itemFind]
        }
      })

      return currencyItem?.name || '—'
    },
  },

}
</script>

<style scoped>

</style>