import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const CellsContent = () => import(/* webpackChunkName: "group-cells" */ './components/CellsContent/CellsContent.vue')
const moduleKey = 'cells'

export const CellsRoutes = [
  {
    path: routerPaths.mainSettingsCells,
    component: CellsContent,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettings',
          'mainSettingsCells',
        ],
        items: {
          'mainSettings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsCells,
          },
          'mainSettingsCells': {
            name: 'breadcrumbs_mainSettingsCells',
            link: routerPaths.mainSettingsCells,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.WAREHOUSE_STORAGE_CELL_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_mainSettingsCells'
    },
  },
]
