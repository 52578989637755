<template>
  <div class="detail-page__right-inner">
    <slot name="header"></slot>
    <slot name="body"></slot>
    <slot name="footer"></slot>
  </div>


</template>

<script>


  export default {
    name: "CardRightBlock",
    components: {
    },

    props: [
      'name',
      'value',
    ]

  }

</script>

<style lang="scss">
  @import "./CardRightBlock";
</style>
