import {API} from "../../axios/axiosMainUrl";

const prefix = '/payments/complaints'

export const payPalCases = {
  state: {
    payPalCases: [],
    payPalCasesItem: {},
    payPalCasesByUserId: [],
    payPalCasesCommonList: [],
    payPalCasesBtn: false,
    payPalCasesLoading: true,
    nextPayPalCasesPage: false,
    payPalCasesForPage: 25,
    payPalCasesFilter: '',
  },

  mutations: {
    setPayPalCasesFilter: (state, payload) => {
      state.payPalCasesFilter = payload
    },

    setPayPalCases: (state, payload) => {
      if(state.nextPayPalCasesPage) {
        state.payPalCases = state.payPalCases.concat(payload.payPalCases)
      } else {
        state.payPalCases = payload.payPalCases
      }
    },

    setPayPalCasesCommonList: (state, payload) => {
      state.payPalCasesCommonList = payload.payPalCasesCommonList
    },

    setNextPayPalCasesPage: (state, payload) => {
      state.nextPayPalCasesPage = payload
    },

    setPayPalCasesItem: (state, payload) => {
      state.payPalCasesItem = payload.payPalCasesItem
    },

    setPayPalCasesByUserId: (state, payload) => {
      state.payPalCasesByUserId = payload.payPalCasesByUserId
    },

    setPayPalCasesLoadingStart: (state) => {
      state.payPalCasesLoading = true
    },

    setPayPalCasesLoadingEnd: (state) => {
      state.payPalCasesLoading = false
    },

    changePayPalCasesBtn: (state) => {
      state.payPalCasesBtn = true
    },
    successPayPalCasesBtn: (state) => {
      state.payPalCasesBtn = false
    },
  },

  getters: {
    getPayPalCasesFilter: state => {
      return state.payPalCasesFilter
    },

    getPayPalCasesForPage: state => {
      return state.payPalCasesForPage
    },

    getPayPalCases: state => {
      return state.payPalCases
    },

    getPayPalCasesLoading: state => {
      return state.payPalCasesLoading
    },


    getNextPayPalCasesPage: state => {
      return state.nextPayPalCasesPage
    },

    getPayPalCasesItem: state => {
      return state.payPalCasesItem
    },

    getPayPalCasesByUserId: state => {
      return state.payPalCasesByUserId
    },

    getPayPalCasesCommonList: state => {
      return state.payPalCasesCommonList
    },

    getPayPalCasesBtn: state => {
      return state.payPalCasesBtn
    },
  },

  actions: {

    async fetchPayPalCases({commit, getters}, filter = '') {
      if(!getters.getNextPayPalCasesPage)
        commit('setPayPalCasesLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setPayPalCases',{payPalCases: response.data.data.data})
          commit('setPayPalCasesCommonList',{payPalCasesCommonList: response.data.data})
          commit('setPayPalCasesLoadingEnd');
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('setPayPalCasesLoadingEnd');
        return e;
      }
    },

    async fetchPayPalCasesTrnsList({commit, getters}, filter = '') {
      if(!getters.getNextPayPalCasesPage)
        commit('setPayPalCasesLoadingStart')
      try {
        return await API.get(`${prefix}/transaction-list${filter}`).then((response) =>{
          // commit('setPayPalCases',{payPalCases: response.data.data.data})
          // commit('setPayPalCasesCommonList',{payPalCasesCommonList: response.data.data})
          // commit('setPayPalCasesLoadingEnd');
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('setPayPalCasesLoadingEnd');
        return e;
      }
    },


    async getPayPalCases({commit}, id) {
      commit('setPayPalCasesLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          //console.log(response)
          commit('setPayPalCasesItem', {payPalCasesItem: response.data.data})
          commit('setPayPalCasesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setPayPalCasesLoadingEnd');
        return e;
      }
    },

    async getPayPalCasesById({commit}, id) {
      try {
        return await API.get(`${prefix}/get-user-notes?user_id=${id}`).then((response) =>{
          commit('setPayPalCasesByUserId', {payPalCasesByUserId: response.data.data})
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async getPayPalCasesCreate({commit},) {
      try {
        return await API.get(`${prefix}/create`).then((response) => {
          return response;
        });
      } catch (e) {
        commit('setPayPalCasesLoadingEnd');
        return e;
      }
    },

    async createPayPalCases({commit}, data) {
      commit('changePayPalCasesBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successPayPalCasesBtn');
          return response;
        });
      } catch (e) {
        commit('successPayPalCasesBtn');
        return e;
      }
    },

    async addMessagePayPalCases({commit}, {id: id, data: data}) {
      commit('changePayPalCasesBtn');
      try {
        return await API.post(`${prefix}/${id}/add-dialog-message`, data).then((response) =>{
          commit('successPayPalCasesBtn');
          return response;
        });
      } catch (e) {
        commit('successPayPalCasesBtn');
        return e;
      }
    },

    async updatePayPalCases({commit}, {id, data}) {
      commit('changePayPalCasesBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successPayPalCasesBtn');
          return response;
        });
      } catch (e) {
        commit('successPayPalCasesBtn');
        return e;
      }
    },

    async partialUpdatePayPalCases({commit}, {id, data}) {
      commit('changePayPalCasesBtn');
      try {
        return await API.patch(`${prefix}/${id}/partial`, data).then((response) =>{
          commit('successPayPalCasesBtn');
          return response;
        });
      } catch (e) {
        commit('successPayPalCasesBtn');
        return e;
      }
    },

    async deletePayPalCases({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successPayPalCasesBtn');
        return e;
      }
    },

    async addPayPalCasesFile({commit}, data) {
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${prefix}/upload-file`, data).then((response) =>{
          commit('successPayPalCasesBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successPayPalCasesBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async getExportPayPalCases({commit}, {filter, exportType}) {
      commit('setExportBtnLoadingStart')
      commit('setLoadingEnd')
      try {
        return await API.get(`${prefix}/export/${exportType}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },
  }
}
