
import {routerPaths} from '../../../router/routerPaths'

const GoogleAuthentication = () => import(/* webpackChunkName: "group-authentication" */ './components/GoogleAuthentication/GoogleAuthentication.vue')
const EmailAuthentication = () => import(/* webpackChunkName: "group-authentication" */ './components/EmailAuthentication/EmailAuthentication.vue')

export const AuthenticationRoutes = [
  {
    path: routerPaths.authenticationGoogleType,
    component: GoogleAuthentication,
    meta: {
      pageTitle: 'profile_googleAuthentication'
    }
  },
  {
    path: routerPaths.authenticationEmailType,
    component: EmailAuthentication,
    meta: {
      pageTitle: 'profile_verifyYourEmail'
    }
  },
]
