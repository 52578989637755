<template>
  <div class="order-create__row custom-row"
       v-if="PC.data.complaintMessages.length > 0">
    <div class="order-create__col custom-col custom-col--md-100">
      <div class="messages">
        <template>
          <div class="messages__item"
               v-for="(item, index) in PC.data.complaintMessages"
               v-bind:class="{'messages__item--right': item.author && !item.author.isAdmin}"
               :key="index"
          >
            <div class="messages__login"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['payPalCases_nameMessage', 'payPalCases_date'])"></div>
              <b>{{ $t('payPalCases_nameMessage.localization_value.value') }}</b>
              {{item.author.user_personal_contact.user_full_name}}
              <br>
              <b>{{ $t('payPalCases_date.localization_value.value') }}</b>
              {{item.created_at}}
            </div>
            <div class="messages__txt">
              {{item.message}}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "PayPalCasesMessagesItems",

    props: {
      PC: Object,
    },

  }
</script>

<style scoped lang="scss">

  .messages {
    background-color: rgba(143, 122, 97, 0.2);
    padding: 16px;
    border-radius: 5px;

    &__item {
      border-bottom: 1px solid #bbad9c;
      padding-bottom: 16px;
      margin-bottom: 16px;
      line-height: 20px;

      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }

      &--right {
        text-align: right;
      }
    }

    &__txt{
      word-break: break-all;
    }

    &__login {
      padding-top: 10px;
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
</style>
