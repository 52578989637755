<template>
  <modal
      @close="$store.commit('setOpenNotificationPopup', false)"
      class="custom-popup"
  >
    <template slot="header">

      <template>
        {{$store.getters.getNotificationItem.currentTranslate.title}}
      </template>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="custom-popup__text" v-html="this.$store.getters.getNotificationItem.currentTranslate.text"></div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";

  export default {
    name: "DetailNotificationPopup",
    components: {
      Modal,
    },

    props: [
      'notificationId'
    ],

    data() {
      return {
        loaded: false,
      }
    },

    created() {
      // console.log(this.$store.getters.getNotificationItem.currentTranslate.title);
    },

    beforeDestroy() {
      this.$store.commit('setResetNotificationItem')
    }

  }
</script>

<style lang="scss">

</style>