<template>
  <div class="fragment">

    <!--    /////////////-->
    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('product_ProductDetails.localization_value.value')}}</b></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('product_ProductName.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.name'"/></div>
    </div>

    <ProformTable
        :pevItem="pevItem && pevItem.history && Object.keys(pevItem.history.proform_entities).length > 0 ? {history: getProformPrev} : pevItem"
        :item="{history: getProform}"
    />

    <!--    /////////////-->
    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('product_SenderInfo.localization_value.value')}}</b></div>
    </div>


    <template v-for="(file, index) in item.history.files">
      <div class="history-table__sub-row" :key="index" v-if="file.type === 'big_image'">
        <div class="history-table__sub-col">File</div>
        <div class="history-table__sub-col">
          <template v-if="pevItem && _.has(pevItem.history.files, index) && pevItem.history.files[index].type === 'big_image'"><FileBlock :files="pevItem.history.files" :file="pevItem.history.files[index]"/></template>
          <template v-else>—</template>
        </div>
        <div class="history-table__sub-col">
          <template v-if="file.type === 'big_image'"><FileBlock :files="item.history.files" :file="file"/></template>
          <template v-else>—</template>
        </div>
      </div>
    </template>

    <template v-if="pevItem && pevItem.history && pevItem.history.files.length > item.history.files.length">
      <template v-for="(file, index) in pevItem.history.files">
        <div class="history-table__sub-row" :key="index"
             v-if="file.type === 'big_image' && index > item.history.files.length-1">
          <div class="history-table__sub-col">File</div>
          <div class="history-table__sub-col">
            <template v-if="file.type === 'big_image'"><FileBlock :files="pevItem.history.files" :file="file"/></template>
            <template v-else>—</template>
          </div>
          <div class="history-table__sub-col">
            —
          </div>
        </div>
      </template>
    </template>



    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_Codes.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.upc_code'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.upc_code'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('product_SKU.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.sku_code'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.sku_code'"/></div>
    </div>

  </div>
</template>

<script>
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import ProformTable from "@/components/coreComponents/Popups/HistoryOfChangesPopup/chunks/ProformTable/ProformTable";
import FileBlock from "@/components/coreComponents/Popups/HistoryOfChangesPopup/chunks/FileBlock/FileBlock";

export default {
  name: "ProductType",

  components: {FileBlock, ProformTable, ValueHelper},

  props: {
    pevItem: Object,
    item: Object,
  },

  computed: {
    getProform() {
      let proform = this.item.history.proform_entities
      proform.hs_code = this.item.history.hs_code
      return proform
    },

    getProformPrev() {
      let proform = this.pevItem.history.proform_entities
      proform.hs_code = this.pevItem.history.hs_code
      return proform
    },
  },

}
</script>

<style scoped>

</style>