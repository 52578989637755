import {API} from "../../axios/axiosMainUrl";

const prefix = '/consolidations/union'
const prefixLabelAPI = '/delivery-api/consolidation-union'

export const consolidationUnion = {
  state: {
    consolidationUnion: [],
    consolidationUnionItem: {},
    consolidationUnionByUserId: [],
    consolidationUnionCommonList: [],
    consolidationUnionBtn: false,
    consolidationUnionLoading: true,
    nextConsolidationUnionPage: false,
    consolidationUnionForPage: 25,
    consolidationUnionFilter: '',
  },

  mutations: {
    setConsolidationUnionFilter: (state, payload) => {
      state.consolidationUnionFilter = payload
    },

    setConsolidationUnion: (state, payload) => {
      if(state.nextConsolidationUnionPage) {
        state.consolidationUnion = state.consolidationUnion.concat(payload.consolidationUnion)
      } else {
        state.consolidationUnion = payload.consolidationUnion
      }
    },

    setConsolidationUnionCommonList: (state, payload) => {
      state.consolidationUnionCommonList = payload.consolidationUnionCommonList
    },

    setNextConsolidationUnionPage: (state, payload) => {
      state.nextConsolidationUnionPage = payload
    },

    setConsolidationUnionItem: (state, payload) => {
      state.consolidationUnionItem = payload.consolidationUnionItem
    },

    setConsolidationUnionByUserId: (state, payload) => {
      state.consolidationUnionByUserId = payload.consolidationUnionByUserId
    },

    setConsolidationUnionLoadingStart: (state) => {
      state.consolidationUnionLoading = true
    },

    setConsolidationUnionLoadingEnd: (state) => {
      state.consolidationUnionLoading = false
    },

    changeConsolidationUnionBtn: (state) => {
      state.consolidationUnionBtn = true
    },
    successConsolidationUnionBtn: (state) => {
      state.consolidationUnionBtn = false
    },
  },

  getters: {
    getConsolidationUnionFilter: state => {
      return state.consolidationUnionFilter
    },

    getConsolidationUnionForPage: state => {
      return state.consolidationUnionForPage
    },

    getConsolidationUnion: state => {
      return state.consolidationUnion
    },

    getConsolidationUnionLoading: state => {
      return state.consolidationUnionLoading
    },


    getNextConsolidationUnionPage: state => {
      return state.nextConsolidationUnionPage
    },

    getConsolidationUnionItem: state => {
      return state.consolidationUnionItem
    },

    getConsolidationUnionByUserId: state => {
      return state.consolidationUnionByUserId
    },

    getConsolidationUnionCommonList: state => {
      return state.consolidationUnionCommonList
    },

    getConsolidationUnionBtn: state => {
      return state.consolidationUnionBtn
    },
  },

  actions: {

    async fetchConsolidationUnion({commit, getters}, filter = '') {
      if(!getters.getNextConsolidationUnionPage)
        commit('setConsolidationUnionLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setConsolidationUnion',{consolidationUnion: response.data.data.data})
          commit('setConsolidationUnionCommonList',{consolidationUnionCommonList: response.data.data})
          commit('setConsolidationUnionLoadingEnd');
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('setConsolidationUnionLoadingEnd');
        return e;
      }
    },


    async getConsolidationUnion({commit}, id) {
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setConsolidationUnionItem', {consolidationUnionItem: response.data.data})
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async getConsolidationUnionConsolidations({commit}, id) {
      try {
        return await API.get(`${prefix}/${id}/consolidations`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setConsolidationUnionLoadingEnd');
        return e;
      }
    },

    async getConsolidationUnionEdit({commit}, id) {
      try {
        return await API.get(`${prefix}/${id}/edit`).then((response) =>{
          commit('setConsolidationUnionItem', {consolidationUnionItem: response.data.data})
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async getConsolidationUnionCreate({commit},) {
      try {
        return await API.get(`${prefix}/create`).then((response) => {
          return response;
        });
      } catch (e) {
        commit('setConsolidationUnionLoadingEnd');
        return e;
      }
    },

    async getConsolidationUnionDeliveryPrices({commit},) {
      try {
        return await API.get(`/consolidationUnion-delivery-price`).then((response) => {
          return response;
        });
      } catch (e) {
        commit('setConsolidationUnionLoadingEnd');
        return e;
      }
    },

    async createConsolidationUnion({commit}, data) {
      commit('changeConsolidationUnionBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successConsolidationUnionBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationUnionBtn');
        return e;
      }
    },

    async consolidationUnionDepartConsolidations({commit}, data) {
      commit('changeConsolidationUnionBtn');
      try {
        return await API.post(`${prefix}/depart`, data).then((response) =>{
          commit('successConsolidationUnionBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationUnionBtn');
        return e;
      }
    },

    async consolidationUnionReceiveUnions({commit}, data) {
      commit('changeConsolidationUnionBtn');
      try {
        return await API.post(`${prefix}/receive`, data).then((response) =>{
          commit('successConsolidationUnionBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationUnionBtn');
        return e;
      }
    },

    async getConsolidationUnionShippingCost({commit}, data) {
      commit('changeConsolidationUnionBtn');
      try {
        return await API.post(`${prefix}/calculate-packaging-amount`, data).then((response) =>{
          commit('successConsolidationUnionBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationUnionBtn');
        return e;
      }
    },

    async addMessageConsolidationUnion({commit}, {id: id, data: data}) {
      commit('changeConsolidationUnionBtn');
      try {
        return await API.post(`${prefix}/${id}/add-dialog-message`, data).then((response) =>{
          commit('successConsolidationUnionBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationUnionBtn');
        return e;
      }
    },

    async updateConsolidationUnion({commit}, {id, data}) {
      commit('changeConsolidationUnionBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successConsolidationUnionBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationUnionBtn');
        return e;
      }
    },

    async updateCommentConsolidationUnion({commit}, {id, data}) {
      commit('changeConsolidationUnionBtn');
      try {
        return await API.patch(`${prefix}/${id}/add-comment`, data).then((response) =>{
          commit('successConsolidationUnionBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationUnionBtn');
        return e;
      }
    },

    async updateFormNumberConsolidationUnion({commit}, {id, data}) {
      commit('changeConsolidationUnionBtn');
      try {
        return await API.patch(`${prefix}/${id}/update-form-number`, data).then((response) =>{
          commit('successConsolidationUnionBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationUnionBtn');
        return e;
      }
    },

    async updateConsoUserConsolidationUnion({commit}, {id, data}) {
      commit('changeConsolidationUnionBtn');
      try {
        return await API.patch(`${prefix}/${id}/add-consolidation-person`, data).then((response) =>{
          commit('successConsolidationUnionBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationUnionBtn');
        return e;
      }
    },

    async updateTagConsolidationUnion({commit}, {id, data}) {
      commit('changeConsolidationUnionBtn');
      try {
        return await API.patch(`${prefix}/${id}/add-tag`, data).then((response) =>{
          commit('successConsolidationUnionBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationUnionBtn');
        return e;
      }
    },

    async deleteTagConsolidationUnion({commit}, id) {
      commit('changeConsolidationUnionBtn');
      try {
        return await API.patch(`${prefix}/${id}/remove-tag`).then((response) =>{
          commit('successConsolidationUnionBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationUnionBtn');
        return e;
      }
    },

    async deleteConsolidationUnion({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successConsolidationUnionBtn');
        return e;
      }
    },

    async addConsolidationUnionFile({commit}, data) {
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${prefix}/upload-file`, data).then((response) =>{
          commit('successConsolidationUnionBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successConsolidationUnionBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async getConsolidationUnionFile({commit}, data) {
      commit('changeConsolidationUnionBtn')
      try {
        return await API.get(`${prefix}/${data.id}/marking/${data.type}`).then((response) =>{
          commit('successConsolidationUnionBtn')
          return response
        })
      } catch (e) {
        commit('successConsolidationUnionBtn')
        commit('setLoadingEnd')
        return e;
      }
    },

    async getConsolidationUnionFileBlob({commit}, data) {
      commit('changeConsolidationUnionBtn')
      let config = {
        headers: {'Access-Control-Expose-Headers' : 'Content-Disposition'},
        responseType: 'blob'
      }
      try {
        return await API.get(`${prefix}/${data.id}/marking/${data.type}`, config).then((response) =>{
          commit('successConsolidationUnionBtn')
          return response
        })
      } catch (e) {
        commit('successConsolidationUnionBtn')
        commit('setLoadingEnd')
        return e;
      }
    },

    async getConsolidationUnionProformFile({commit}, data) {
      commit('changeConsolidationUnionBtn');
      try {
        return await API.post(`${prefix}/export-product-invoice`, data).then((response) =>{
          commit('successConsolidationUnionBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationUnionBtn');
        return e;
      }
    },

    async getConsolidationUnionProformFileByState({commit}, {id, state}) {
      commit('changeConsolidationUnionBtn');
      try {
        return await API.get(`${prefix}/${id}/proforma/${state}`).then((response) =>{
          commit('successConsolidationUnionBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationUnionBtn');
        return e;
      }
    },

    /******************************************/
    /***************** LABEL ******************/
    /******************************************/

    async getConsolidationUnionCreateLabel({commit}, query) {
      try {
        return await API.get(`/consolidations/consolidation-union-label/create${query}`).then((response) => {
          return response;
        });
      } catch (e) {
        commit('setConsolidationUnionLoadingEnd');
        return e;
      }
    },

    async createConsolidationUnionLabel({commit}, data) {
      commit('changeConsolidationUnionBtn');
      try {
        return await API.post(`/consolidations/consolidation-union-label`, data).then((response) =>{
          commit('successConsolidationUnionBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationUnionBtn');
        return e;
      }
    },

    async updateConsolidationUnionLabel({commit}, {id, data}) {
      commit('changeConsolidationUnionBtn');
      try {
        return await API.put(`/consolidations/consolidation-union-label/${id}`, data).then((response) =>{
          commit('successConsolidationUnionBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationUnionBtn');
        return e;
      }
    },

    async getConsolidationUnionLabelEdit({commit}, id) {
      try {
        return await API.get(`/consolidations/consolidation-union-label/${id}/edit`).then((response) =>{
          // commit('setConsolidationUnionLabelItem', {consolidationUnionLabelItem: response.data.data})
          return response;
        });
      } catch (e) {
        commit('setConsolidationUnionLoadingEnd');
        return e;
      }
    },

    async getConsolidationUnionLabelDeliveryPrice({commit}, data) {
      commit('changeConsolidationUnionBtn');
      try {
        return await API.post(`${prefixLabelAPI}/get-cost`, data).then((response) =>{
          commit('successConsolidationUnionBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationUnionBtn');
        return e;
      }
    },

    async createConsolidationUnionLabelAPI({commit}, data) {
      commit('changeConsolidationUnionBtn');
      try {
        return await API.post(`${prefixLabelAPI}/make-label`, data).then((response) =>{
          commit('successConsolidationUnionBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationUnionBtn');
        return e;
      }
    },

    async uploadConsolidationUnionLabelFile({commit}, data) {
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`/consolidations/consolidation-union-label/upload-file`, data).then((response) =>{
          commit('successConsolidationUnionBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successConsolidationUnionBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async getExportConsolidationUnion({commit}, {filter , type}) {
      commit('setExportBtnLoadingStart')
      commit('setLoadingEnd')
      try {
        return await API.get(`/report/consolidation-union/export/${type}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

    async getReportSendTrackingNumber({commit}, id) {
      commit('changeConsolidationUnionBtn')
      try {
        return await API.get(`${prefix}/send-track-number/${id}`).then((response) =>{
          commit('successConsolidationUnionBtn')
          return response
        })
      } catch (e) {
        commit('successConsolidationUnionBtn')
        return e;
      }
    },
  }
}
