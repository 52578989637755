import {API} from "../../axios/axiosMainUrl";

const prefix = '/return-parcels/documents'

export const returnGoodsDocuments = {
  state: {
    returnGoodsDocuments: [],
    returnGoodsDocumentsItem: {},
    returnGoodsDocumentsCommonList: [],
    returnGoodsDocumentsBtn: false,
    returnGoodsDocumentsLoading: true,
    nextReturnGoodsDocumentsPage: false,
    returnGoodsDocumentsForPage: 25,
    returnGoodsDocumentsFilter: '',
  },

  mutations: {
    setReturnGoodsDocumentsFilter: (state, payload) => {
      state.returnGoodsDocumentsFilter = payload
    },

    setReturnGoodsDocuments: (state, payload) => {
      if(state.nextReturnGoodsDocumentsPage) {
        state.returnGoodsDocuments = state.returnGoodsDocuments.concat(payload.returnGoodsDocuments)
      } else {
        state.returnGoodsDocuments = payload.returnGoodsDocuments
      }
    },

    setReturnGoodsDocumentsCommonList: (state, payload) => {
      state.returnGoodsDocumentsCommonList = payload.returnGoodsDocumentsCommonList
    },

    setNextReturnGoodsDocumentsPage: (state, payload) => {
      state.nextReturnGoodsDocumentsPage = payload
    },

    setReturnGoodsDocumentsItem: (state, payload) => {
      state.returnGoodsDocumentsItem = payload.returnGoodsDocumentsItem
    },

    setReturnGoodsDocumentsLoadingStart: (state) => {
      state.returnGoodsDocumentsLoading = true
    },

    setReturnGoodsDocumentsLoadingEnd: (state) => {
      state.returnGoodsDocumentsLoading = false
    },

    changeReturnGoodsDocumentsBtn: (state) => {
      state.returnGoodsDocumentsBtn = true
    },
    successReturnGoodsDocumentsBtn: (state) => {
      state.returnGoodsDocumentsBtn = false
    },
  },

  getters: {
    getReturnGoodsDocumentsFilter: state => {
      return state.returnGoodsDocumentsFilter
    },

    getReturnGoodsDocumentsForPage: state => {
      return state.returnGoodsDocumentsForPage
    },

    getReturnGoodsDocuments: state => {
      return state.returnGoodsDocuments
    },

    getReturnGoodsDocumentsLoading: state => {
      return state.returnGoodsDocumentsLoading
    },


    getNextReturnGoodsDocumentsPage: state => {
      return state.nextReturnGoodsDocumentsPage
    },

    getReturnGoodsDocumentsItem: state => {
      return state.returnGoodsDocumentsItem
    },

    getReturnGoodsDocumentsCommonList: state => {
      return state.returnGoodsDocumentsCommonList
    },

    getReturnGoodsDocumentsBtn: state => {
      return state.returnGoodsDocumentsBtn
    },
  },

  actions: {

    async fetchReturnGoodsDocuments({commit, getters}, filter = '') {
      if(!getters.getNextReturnGoodsDocumentsPage)
        commit('setReturnGoodsDocumentsLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setReturnGoodsDocuments',{returnGoodsDocuments: response.data.data.data})
          commit('setReturnGoodsDocumentsCommonList',{returnGoodsDocumentsCommonList: response.data.data})
          commit('setReturnGoodsDocumentsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setReturnGoodsDocumentsLoadingEnd');
        return e;
      }
    },

    async getReturnGoodsDocuments({commit}, id) {
      commit('setReturnGoodsDocumentsLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setReturnGoodsDocumentsItem', {returnGoodsDocumentsItem: response.data.data})
          commit('setReturnGoodsDocumentsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setReturnGoodsDocumentsLoadingEnd');
        return e;
      }
    },

    async createReturnGoodsDocuments({commit}, data) {
      commit('changeReturnGoodsDocumentsBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successReturnGoodsDocumentsBtn');
          return response;
        });
      } catch (e) {
        commit('successReturnGoodsDocumentsBtn');
        return e;
      }
    },

    async updateReturnGoodsDocuments({commit}, {id, data}) {
      commit('changeReturnGoodsDocumentsBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successReturnGoodsDocumentsBtn');
          return response;
        });
      } catch (e) {
        commit('successReturnGoodsDocumentsBtn');
        return e;
      }
    },

    async deleteReturnGoodsDocuments({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successReturnGoodsDocumentsBtn');
        return e;
      }
    },


    async meestMassThermalPrint({commit}, data) {
      commit('changeProductsBtn');
      try {
        return await API.post(`${prefix}/marking-print`, data).then((response) =>{
          commit('successProductsBtn');
          return response;
        });
      } catch (e) {
        commit('successProductsBtn');
        return e;
      }
    },
  }
}
