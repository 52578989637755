<template>
  <div class="fragment">
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('invoices_invoiceStatus.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.status'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.status'"/></div>
    </div>
  </div>
</template>

<script>
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";

export default {
  name: "InvoicesType",

  components: {ValueHelper},

  props: {
    pevItem: Object,
    item: Object,
  },

  computed: {

  },

  methods: {

  }

}
</script>

<style scoped>

</style>