<template>
  <div class="fragment">
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_comment.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.description'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.description'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_OrderNotificationActive.localization_value.value')}}</div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="pevItem && _.has(pevItem.history, 'active')" :value="pevItem.history.active === 1" :disabled="true"/><template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox v-if="item" :value="item.history.active === 1" :disabled="true"/><template v-else>—</template>
      </div>
    </div>
  </div>
</template>

<script>
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

export default {
  name: "OrderNotificationType",

  components: {DefaultCheckbox, ValueHelper},

  props: {
    pevItem: Object,
    item: Object,
  },

  computed: {

  },

  methods: {

  }

}
</script>

<style scoped>

</style>