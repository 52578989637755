import {API} from "../../axios/axiosMainUrl";

const prefix = '/orders/shipping-method'

export const shippingMethodsForOrder = {
  state: {
    shippingMethodsForOrder: [],
    shippingMethodsForOrderItem: {},
    shippingMethodsForOrderCommonList: [],
    shippingMethodsForOrderBtn: false,
    shippingMethodsForOrderLoading: true,
    nextShippingMethodsForOrderPage: false,
    shippingMethodsForOrderForPage: 25,
    shippingMethodsForOrderFilter: '',
  },

  mutations: {
    setShippingMethodsForOrderFilter: (state, payload) => {
      state.shippingMethodsForOrderFilter = payload
    },

    setShippingMethodsForOrder: (state, payload) => {
      if(state.nextShippingMethodsForOrderPage) {
        state.shippingMethodsForOrder = state.shippingMethodsForOrder.concat(payload.shippingMethodsForOrder)
      } else {
        state.shippingMethodsForOrder = payload.shippingMethodsForOrder
      }
    },

    setShippingMethodsForOrderCommonList: (state, payload) => {
      state.shippingMethodsForOrderCommonList = payload.shippingMethodsForOrderCommonList
    },

    setNextShippingMethodsForOrderPage: (state, payload) => {
      state.nextShippingMethodsForOrderPage = payload
    },

    setShippingMethodsForOrderItem: (state, payload) => {
      state.shippingMethodsForOrderItem = payload.shippingMethodsForOrderItem
    },

    setShippingMethodsForOrderLoadingStart: (state) => {
      state.shippingMethodsForOrderLoading = true
    },

    setShippingMethodsForOrderLoadingEnd: (state) => {
      state.shippingMethodsForOrderLoading = false
    },

    changeShippingMethodsForOrderBtn: (state) => {
      state.shippingMethodsForOrderBtn = true
    },
    successShippingMethodsForOrderBtn: (state) => {
      state.shippingMethodsForOrderBtn = false
    },
  },

  getters: {
    getShippingMethodsForOrderFilter: state => {
      return state.shippingMethodsForOrderFilter
    },

    getShippingMethodsForOrderForPage: state => {
      return state.shippingMethodsForOrderForPage
    },

    getShippingMethodsForOrder: state => {
      return state.shippingMethodsForOrder
    },

    getShippingMethodsForOrderLoading: state => {
      return state.shippingMethodsForOrderLoading
    },


    getNextShippingMethodsForOrderPage: state => {
      return state.nextShippingMethodsForOrderPage
    },

    getShippingMethodsForOrderItem: state => {
      return state.shippingMethodsForOrderItem
    },

    getShippingMethodsForOrderCommonList: state => {
      return state.shippingMethodsForOrderCommonList
    },

    getShippingMethodsForOrderBtn: state => {
      return state.shippingMethodsForOrderBtn
    },
  },

  actions: {

    async fetchShippingMethodsForOrder({commit, getters}, filter = '') {
      if(!getters.getNextShippingMethodsForOrderPage)
        commit('setShippingMethodsForOrderLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setShippingMethodsForOrder',{shippingMethodsForOrder: response.data.data.data})
          commit('setShippingMethodsForOrderCommonList',{shippingMethodsForOrderCommonList: response.data.data})
          commit('setShippingMethodsForOrderLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setShippingMethodsForOrderLoadingEnd');
        return e;
      }
    },

    async getShippingMethodsCreate({commit}) {
      // commit('setShippingMethodsForOrderLoadingStart');
      try {
        return await API.get(`${prefix}/create`).then((response) =>{
          console.log(response)
          commit('setShippingMethodsForOrderItem', {shippingMethodsForOrderItem: response.data.data})
          // commit('setShippingMethodsForOrderLoadingEnd');
          return response;
        });
      } catch (e) {
        // commit('setShippingMethodsForOrderLoadingEnd');
        return e;
      }
    },

    async getShippingMethodsForOrder({commit}, id) {
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setShippingMethodsForOrderItem', {shippingMethodsForOrderItem: response.data.data})
          return response;
        });
      } catch (e) {
        commit('setShippingMethodsForOrderLoadingEnd');
        return e;
      }
    },

    async createShippingMethodsForOrder({commit}, data) {
      commit('changeShippingMethodsForOrderBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successShippingMethodsForOrderBtn');
          return response;
        });
      } catch (e) {
        commit('successShippingMethodsForOrderBtn');
        return e;
      }
    },

    async updateShippingMethodsForOrder({commit}, {id, data}) {
      commit('changeShippingMethodsForOrderBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successShippingMethodsForOrderBtn');
          return response;
        });
      } catch (e) {
        commit('successShippingMethodsForOrderBtn');
        return e;
      }
    },

    async deleteShippingMethodsForOrder({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successShippingMethodsForOrderBtn');
        return e;
      }
    },

  }
}
