import {API} from "../../axios/axiosMainUrl";

const prefix = '/express-orders/fast-order'

export const expressFastOrders = {
  state: {
    expressFastOrders: [],
    expressFastOrdersItem: {},
    expressFastOrdersCommonList: [],
    expressFastOrdersBtn: false,
    expressFastOrdersLoading: true,
    nextExpressFastOrdersPage: false,
    expressFastOrdersForPage: 25,
    expressFastOrdersFilter: '',
  },

  mutations: {
    setExpressFastOrdersFilter: (state, payload) => {
      state.expressFastOrdersFilter = payload
    },

    setExpressFastOrders: (state, payload) => {
      if(state.nextExpressFastOrdersPage) {
        state.expressFastOrders = state.expressFastOrders.concat(payload.expressFastOrders)
      } else {
        state.expressFastOrders = payload.expressFastOrders
      }
    },

    setExpressFastOrdersCommonList: (state, payload) => {
      state.expressFastOrdersCommonList = payload.expressFastOrdersCommonList
    },

    setNextExpressFastOrdersPage: (state, payload) => {
      state.nextExpressFastOrdersPage = payload
    },

    setExpressFastOrdersItem: (state, payload) => {
      state.expressFastOrdersItem = payload.expressFastOrdersItem
    },

    setExpressFastOrdersLoadingStart: (state) => {
      state.expressFastOrdersLoading = true
    },

    setExpressFastOrdersLoadingEnd: (state) => {
      state.expressFastOrdersLoading = false
    },

    changeExpressFastOrdersBtn: (state) => {
      state.expressFastOrdersBtn = true
    },
    successExpressFastOrdersBtn: (state) => {
      state.expressFastOrdersBtn = false
    },
  },

  getters: {
    getExpressFastOrdersFilter: state => {
      return state.expressFastOrdersFilter
    },

    getExpressFastOrdersForPage: state => {
      return state.expressFastOrdersForPage
    },

    getExpressFastOrders: state => {
      return state.expressFastOrders
    },

    getExpressFastOrdersLoading: state => {
      return state.expressFastOrdersLoading
    },


    getNextExpressFastOrdersPage: state => {
      return state.nextExpressFastOrdersPage
    },

    getExpressFastOrdersItem: state => {
      return state.expressFastOrdersItem
    },

    getExpressFastOrdersCommonList: state => {
      return state.expressFastOrdersCommonList
    },

    getExpressFastOrdersBtn: state => {
      return state.expressFastOrdersBtn
    },
  },

  actions: {

    async fetchExpressFastOrders({commit, getters}, filter = '') {
      if(!getters.getNextExpressFastOrdersPage)
        commit('setExpressFastOrdersLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setExpressFastOrders',{expressFastOrders: response.data.data.data})
          // commit('setExpressFastOrdersCommonList',{expressFastOrdersCommonList: response.data.data})
          commit('setExpressFastOrdersCommonList',{expressFastOrdersCommonList: {...response.data.data.meta, ...{
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev
              }}})
          commit('setExpressFastOrdersLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setExpressFastOrdersLoadingEnd');
        return e;
      }
    },

    async getExpressFastOrders({commit}, id) {
      commit('setExpressFastOrdersLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setExpressFastOrdersItem', {expressFastOrdersItem: response.data.data})
          commit('setExpressFastOrdersLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setExpressFastOrdersLoadingEnd');
        return e;
      }
    },

    async getFastExpressOrderById({commit}, id) {
      commit('setExpressFastOrdersLoadingStart');
      try {
        return await API.get(`${prefix}/add-order-express/${id}`).then((response) =>{
          commit('setExpressFastOrdersItem', {expressFastOrdersItem: response.data.data})
          commit('setExpressFastOrdersLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setExpressFastOrdersLoadingEnd');
        return e;
      }
    },

    async createExpressFastOrders({commit}, data) {
      commit('changeExpressFastOrdersBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successExpressFastOrdersBtn');
          return response;
        });
      } catch (e) {
        commit('successExpressFastOrdersBtn');
        return e;
      }
    },

    async updateExpressFastOrders({commit}, {id, data}) {
      commit('changeExpressFastOrdersBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successExpressFastOrdersBtn');
          return response;
        });
      } catch (e) {
        commit('successExpressFastOrdersBtn');
        return e;
      }
    },

    async deleteExpressFastOrders({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successExpressFastOrdersBtn');
        return e;
      }
    },

    async reCreateLabelExpressFastOrders({commit}, {idReport, idElement}) {
      commit('changeExpressFastOrdersBtn');
      try {
        return await API.patch(`${prefix}/${idReport}/${idElement}/update-label`).then((response) =>{
          commit('successExpressFastOrdersBtn');
          return response;
        });
      } catch (e) {
        commit('successExpressFastOrdersBtn');
        return e;
      }
    },

    async getExportExpressFastOrders({commit}, id) {
      commit('changeExpressFastOrdersBtn')
      try {
        return await API.get(`${prefix}/export/${id}`, { responseType: 'blob' }).then((response) =>{
          commit('successExpressFastOrdersBtn')
          return response
        })
      } catch (e) {
        commit('successExpressFastOrdersBtn')
        return e;
      }
    },

    async getExpressFastOrdersLabel({commit}, id) {
      try {
        return await API.get(`${prefix}/${id}/merge-labels`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successFastOrdersBtn');
        return e;
      }
    },

    async sendExpressPhotoFastOrder({commit}, {id, data}) {
      try {
        return await API.post(`${prefix}/${id}/upload-photos`, data).then((response) =>{
          // console.log(response.data);+6
          // commit('setShippingCompanyData', {
          //   shippingCompanyData: response.data.data.shipping_companies
          // })
          return response;
        });
      } catch (e) {
        commit('setOrdersFBMLoadingEnd');
        return e;
      }
    },

  }
}
