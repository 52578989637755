
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";


const ImportOrdersTable = () => import(/* webpackChunkName: "group-import-orders" */ './components/ImportOrdersTable/ImportOrdersTable.vue')
const moduleKey = 'import-orders'

export const ImportOrdersRoutes = [
  {
    path: routerPaths.importOrders,
    component: ImportOrdersTable,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'expressOrders'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'expressOrders': {
            name: 'menu_importOrders',
            link: routerPaths.importOrders,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_EXTERNAL_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_importOrders'
    },
  }
]
