<template>
<div class="fragment">

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{$t('shops_shopName.localization_value.value')}}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.shop_name'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.shop_name'"/></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{$t('shops_linkToShop.localization_value.value')}}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.shop_param.shop_link'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.shop_param.shop_link'"/></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{$t('shops_etsyTrackSetting.localization_value.value')}}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.shop_settings.0.shop_setting.currentTranslate.label'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.shop_settings.0.shop_setting.currentTranslate.label'"/></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">
      {{$t('shops_uploadOrdersAPI.localization_value.value')}}
    </div>
    <div class="history-table__sub-col" >
      <DefaultCheckbox
          v-if="pevItem"
          :value="pevItem.history.shop_state.load_orders_from_api === 1"
          :disabled="true"
      />
      <template v-else>—</template>
    </div>
    <div class="history-table__sub-col" >
      <DefaultCheckbox
          v-if="item"
          :value="item.history.shop_state.load_orders_from_api === 1"
          :disabled="true"
      />
      <template v-else>—</template>
    </div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{$t('shop_type.localization_value.value')}}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.shop_type.currentTranslate.label'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.shop_type.currentTranslate.label'"/></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">Shop ID (for Etsy Import)</div>
    <div class="history-table__sub-col"><ValueHelper v-if="checkShopTypePrev(shopsTypes.etsy.type)" :value="pevItem" :deep="'history.shop_param.remote_shop_id'"/><span v-else>—</span></div>
    <div class="history-table__sub-col"><ValueHelper v-if="checkShopType(shopsTypes.etsy.type)" :value="item" :deep="'history.shop_param.remote_shop_id'"/><span v-else>—</span></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">Seller ID</div>
    <div class="history-table__sub-col"><ValueHelper v-if="checkShopTypePrev(shopsTypes.amazonmws.type)" :value="pevItem" :deep="'history.shop_param.remote_shop_api_key'"/><span v-else>—</span></div>
    <div class="history-table__sub-col"><ValueHelper v-if="checkShopType(shopsTypes.amazonmws.type)" :value="item" :deep="'history.shop_param.remote_shop_api_key'"/><span v-else>—</span></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">Amazon MWS Token</div>
    <div class="history-table__sub-col"><ValueHelper v-if="checkShopTypePrev(shopsTypes.amazonmws.type)" :value="pevItem" :deep="'history.shop_param.remote_shop_api_secret'"/><span v-else>—</span></div>
    <div class="history-table__sub-col"><ValueHelper v-if="checkShopType(shopsTypes.amazonmws.type)" :value="item" :deep="'history.shop_param.remote_shop_api_secret'"/><span v-else>—</span></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">Login</div>
    <div class="history-table__sub-col"><ValueHelper v-if="checkShopTypePrev(defaultShops)" :value="pevItem" :deep="'history.shop_param.shop_login'"/><span v-else>—</span></div>
    <div class="history-table__sub-col"><ValueHelper v-if="checkShopType(defaultShops)" :value="item" :deep="'history.shop_param.shop_login'"/><span v-else>—</span></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('login_password.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper v-if="checkShopTypePrev(defaultShops)" :value="pevItem" :deep="'history.shop_param.shop_password'"/><span v-else>—</span></div>
    <div class="history-table__sub-col"><ValueHelper v-if="checkShopType(defaultShops)" :value="item" :deep="'history.shop_param.shop_password'"/><span v-else>—</span></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">Remote Shop API URL</div>
    <div class="history-table__sub-col"><ValueHelper v-if="checkShopTypePrev(defaultShops.opencart) || checkShopTypePrev(defaultShops.woocommerce)" :value="pevItem" :deep="'history.shop_param.remote_shop_url'"/><span v-else>—</span></div>
    <div class="history-table__sub-col"><ValueHelper v-if="checkShopType(defaultShops.opencart) || checkShopType(defaultShops.woocommerce)" :value="item" :deep="'history.shop_param.remote_shop_url'"/><span v-else>—</span></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{ $t('shops_remoteShopAPIKey.localization_value.value') }}</div>
    <div class="history-table__sub-col"><ValueHelper v-if="checkShopTypePrev(defaultShops.opencart) || checkShopTypePrev(defaultShops.woocommerce)" :value="pevItem" :deep="'history.shop_param.remote_shop_api_key'"/><span v-else>—</span></div>
    <div class="history-table__sub-col"><ValueHelper v-if="checkShopType(defaultShops.opencart) || checkShopType(defaultShops.woocommerce)" :value="item" :deep="'history.shop_param.remote_shop_api_key'"/><span v-else>—</span></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">Remote Shop API Secret</div>
    <div class="history-table__sub-col"><ValueHelper v-if="checkShopTypePrev(defaultShops.opencart) || checkShopTypePrev(defaultShops.woocommerce)" :value="pevItem" :deep="'history.shop_param.remote_shop_api_secret'"/><span v-else>—</span></div>
    <div class="history-table__sub-col"><ValueHelper v-if="checkShopType(defaultShops.opencart) || checkShopType(defaultShops.woocommerce)" :value="item" :deep="'history.shop_param.remote_shop_api_secret'"/><span v-else>—</span></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">
      {{$t('shops_isTradeMark.localization_value.value')}}
    </div>
    <div class="history-table__sub-col" >
      <DefaultCheckbox
          v-if="pevItem"
          :value="pevItem.history.shop_param.is_trade_mark === '1'"
          :disabled="true"
      />
      <template v-else>—</template>
    </div>
    <div class="history-table__sub-col" >
      <DefaultCheckbox
          v-if="item"
          :value="item.history.shop_param.is_trade_mark === '1'"
          :disabled="true"
      />
      <template v-else>—</template>
    </div>
  </div>

</div>
</template>

<script>
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

export default {
  name: "ShopType",

  components: {DefaultCheckbox, ValueHelper},

  props: {
    pevItem: Object,
    item: Object,
  },

  data() {
    return {
      shopsTypes: this.$store.getters.getDefaultShopsTypes,
      defaultShops: {
        opencart: 'opencart',
        woocommerce: 'woocommerce',
        // tilda: 'tilda',
        // raverly: 'raverly',
        nothingAbove: 'nothing_above',
        shopify: 'shopify',
        wish: 'wish',
      },
    }
  },

  methods: {
    checkShopType(type){
      if(type?.opencart){
        return this._.find(this.defaultShops, (o) => { return o === this.item?.history?.shop_type?.name }) !== undefined
      }
      return this.item?.history?.shop_type?.name === type
    },
    checkShopTypePrev(type){
      if(type?.opencart){
        return this._.find(this.defaultShops, (o) => { return o === this.pevItem?.history?.shop_type?.name }) !== undefined
      }
      return this.pevItem?.history?.shop_type?.name === type
    },
  }

}
</script>

<style scoped>

</style>