import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const ConsolidationMeestTable = () => import(/* webpackChunkName: "paypal-cases" */ './components/ConsolidationMeestTable/ConsolidationMeestTable.vue')
const ConsolidationMeestCreation = () => import(/* webpackChunkName: "paypal-cases-create" */ './components/ConsolidationMeestCreation/ConsolidationMeestCreation.vue')
const ConsolidationMeestEditing = () => import(/* webpackChunkName: "paypal-cases-create" */ './components/ConsolidationMeestEditing/ConsolidationMeestEditing.vue')
const ConsolidationMeestShow = () => import(/* webpackChunkName: "paypal-cases-create" */ './components/ConsolidationMeestShow/ConsolidationMeestShow.vue')
const moduleKey = 'consolidations-meest'


export const ConsolidationMeestRoutes = [
  {
    path: routerPaths.reportsConsolidationMeest,
    component: ConsolidationMeestTable,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'consolidationMeest'
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.reportsConsolidationMeest,
          },
          'consolidationMeest': {
            name: 'menu_consolidationMeest',
            link: routerPaths.reportsConsolidationMeest,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.CONSOLIDATION_UNION_MEEST_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_consolidationMeest'
    },
  },

  {
    path: routerPaths.reportsConsolidationMeestCreation,
    component: ConsolidationMeestCreation,
    meta: {
      breadcrumbs: {
        key: [
          'consolidationMeest',
          'problemsConsolidationMeestCreation'
        ],
        items: {
          'consolidationMeest': {
            name: 'menu_consolidationMeest',
            link: routerPaths.reportsConsolidationMeest,
          },
          'problemsConsolidationMeestCreation': {
            name: 'menu_fileAClaim',
            link: routerPaths.reportsConsolidationMeestCreation,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.CONSOLIDATION_UNION_MEEST_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_consolidationMeest'
    },
  },

  {
    path: routerPaths.reportsConsolidationMeestEdit,
    component: ConsolidationMeestEditing,
    meta: {
      breadcrumbs: {
        key: [
          'consolidationMeest',
          'reportsConsolidationMeestEditing'
        ],
        items: {
          'consolidationMeest': {
            name: 'menu_consolidationMeest',
            link: routerPaths.reportsConsolidationMeest,
          },
          'reportsConsolidationMeestEditing': {
            name: 'menu_consolidationMeest',
            link: routerPaths.reportsConsolidationMeestEdit,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.CONSOLIDATION_UNION_MEEST_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_consolidationMeest'
    },
  },

  {
    path: routerPaths.reportsConsolidationMeestShow,
    component: ConsolidationMeestShow,
    meta: {
      breadcrumbs: {
        key: [
          'consolidationMeest',
          'reportsConsolidationMeestEditing'
        ],
        items: {
          'consolidationMeest': {
            name: 'menu_consolidationMeest',
            link: routerPaths.reportsConsolidationMeest,
          },
          'reportsConsolidationMeestEditing': {
            name: 'menu_consolidationMeest',
            link: routerPaths.reportsConsolidationMeestShow,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.CONSOLIDATION_UNION_MEEST_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_consolidationMeest'
    },
  },



  {
    path: routerPaths.reportsConsolidationMeestSent,
    component: ConsolidationMeestTable,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'consolidationMeest'
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.reportsConsolidationMeestSent,
          },
          'consolidationMeest': {
            name: 'menu_consolidationMeestSent',
            link: routerPaths.reportsConsolidationMeestSent,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.CONSOLIDATION_UNION_MEEST_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_consolidationMeestSent'
    },
  },


  {
    path: routerPaths.reportsConsolidationMeestSentShow,
    component: ConsolidationMeestShow,
    meta: {
      breadcrumbs: {
        key: [
          'consolidationMeest',
          'reportsConsolidationMeestEditing'
        ],
        items: {
          'consolidationMeest': {
            name: 'menu_consolidationMeest',
            link: routerPaths.reportsConsolidationMeest,
          },
          'reportsConsolidationMeestEditing': {
            name: 'menu_consolidationMeestSent',
            link: routerPaths.reportsConsolidationMeestSentShow,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.CONSOLIDATION_UNION_MEEST_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_consolidationMeestSent'
    },
  },
]
