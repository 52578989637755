
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const MarketplaceReviewsTable = () => import(/* webpackChunkName: "group-marketplace-reviews" */ './components/MarketplaceReviewsTable/MarketplaceReviewsTable.vue')
const moduleKey = 'marketplace-reviews'


export const MarketplaceReviewsRoutes = [
  {
    path: routerPaths.marketplaceReviews,
    component: MarketplaceReviewsTable,
    meta: {
      breadcrumbs: {
        key: [
          'marketplace',
          'products'
        ],
        items: {
          'marketplace': {
            name: 'menu_marketplace',
            link: routerPaths.marketplaceReviews,
          },
          'products': {
            name: 'menu_marketplaceReviews',
            link: routerPaths.marketplaceReviews,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.UKRAINIAN_TREASURES_REVIEWS],

      moduleKey: moduleKey,
      pageTitle: 'menu_marketplaceReviews'
    },
  },
]
