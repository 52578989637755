<template>
  <div class="fragment">

    <!--    /////////////-->
    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('ordersInbound_shipping.localization_value.value')}}</b></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('ordersInbound_shippingCompany.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.delivery_service.name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.delivery_service.name'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('ordersInbound_shippingDate.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.shipped_at'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.shipped_at'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('ordersInbound_trackingNumber.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.tracking_number'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.tracking_number'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('ordersInbound_condition.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.product_condition.name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.product_condition.name'"/></div>
    </div>

    <div class="history-table__sub-row" v-if="(pevItem && _.find(pevItem.history.files, {group: 'order_inbound'})) || _.find(item.history.files, {group: 'order_inbound'})">
      <div class="history-table__sub-col">{{$t('ordersInbound_documents.localization_value.value')}} file</div>
      <div class="history-table__sub-col">
        <template v-if="pevItem && _.find(pevItem.history.files, {group: 'order_inbound'})"><FileBlock :files="pevItem.history.files" :file="_.find(pevItem.history.files, {group: 'order_inbound'})"/></template>
        <template v-else>—</template>
      </div>
      <div class="history-table__sub-col">
        <template v-if="_.find(item.history.files, {group: 'order_inbound'})"><FileBlock :files="item.history.files" :file="_.find(item.history.files, {group: 'order_inbound'})"/></template>
        <template v-else>—</template>
      </div>
    </div>

    <div class="history-table__sub-row" v-if="(pevItem && _.find(pevItem.history.files, {type: 'orderInboundPdfLabelFileType'})) || _.find(item.history.files, {type: 'orderInboundPdfLabelFileType'})">
      <div class="history-table__sub-col">{{$t('common_GetLabel.localization_value.value')}} file</div>
      <div class="history-table__sub-col">
        <template v-if="pevItem && _.find(pevItem.history.files, {type: 'orderInboundPdfLabelFileType'})"><FileBlock :files="pevItem.history.files" :file="_.find(pevItem.history.files, {type: 'orderInboundPdfLabelFileType'})"/></template>
        <template v-else>—</template>
      </div>
      <div class="history-table__sub-col">
        <template v-if="_.find(item.history.files, {type: 'orderInboundPdfLabelFileType'})"><FileBlock :files="item.history.files" :file="_.find(item.history.files, {type: 'orderInboundPdfLabelFileType'})"/></template>
        <template v-else>—</template>
      </div>
    </div>


    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_comment.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.comment'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.comment'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('ordersInbound_checkAndRepack.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox
            v-if="pevItem"
            :value="pevItem.history.check_and_repack"
            :disabled="true"
        />
        <template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox
            v-if="item"
            :value="item.history.check_and_repack"
            :disabled="true"
        />
        <template v-else>—</template>
      </div>
    </div>

    <!--    /////////////-->
    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('ordersInbound_products.localization_value.value')}}</b></div>
    </div>

    <template v-for="(proform, index) in item.history.productProformEntities">
      <div class="history-table__sub-row"
           :key="index + 'product'">
        <div class="history-table__sub-col">{{$t('product_ProductName.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper v-if="pevItem" :value="getActualOrderProform" :deep="index+'.product.product.name_for_fba'"/></div>
        <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.productProformEntities.'+index+'.product.product.name_for_fba'"/></div>
      </div>
      <CellsTable
          :key="index + 'cells'"
          :pevCells="pevItem && _.has(getActualOrderProform, index) ? getActualOrderProform[index].product.cells : []"
          :itemCells="proform.product.cells"
      />
<!--      <ProformTable-->
<!--          :key="index + 'proform'"-->
<!--          :pevItem="pevItem && _.has(pevItem.history.productProformEntities, index) ? {history: pevItem.history.productProformEntities[index].proform_entities[0]} : pevItem"-->
<!--          :item="{history: proform.proform_entities[0]}"-->
<!--      />-->
      <ProformTable
          :key="index + 'proform'"
          :pevItem="pevItem && _.has(getActualOrderProform, index) ? {history: getActualOrderProform[index].proform_entities} : null"
          :item="{history: proform.proform_entities}"
      />
    </template>
    <template v-if="pevItem && getDeletedOrderProform">
      <template v-for="(proform, index) in getDeletedOrderProform">
<!--        <template>-->
          <div class="history-table__sub-row"
               :key="index + 'productPrev'">
            <div class="history-table__sub-col">{{$t('product_ProductName.localization_value.value')}}</div>
            <div class="history-table__sub-col"><ValueHelper :value="proform" :deep="'product.product.name_for_fba'"/><pre>{{proform.product_id}}</pre></div>
            <div class="history-table__sub-col"><ValueHelper :value="null"/></div>
          </div>
          <CellsTable
              :key="index + 'cellsPrev'"
              :pevCells="proform.product.cells"
              :itemCells="[]"
          />
          <ProformTable
              :key="index + 'proform0'"
              :pevItem="{history: proform.proform_entities}"
              :item="null"
          />
<!--        </template>-->
      </template>
    </template>

    <!--    /////////////-->
    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('ordersInbound_shippingDetails.localization_value.value')}}</b></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_ContactName.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.sender_customer_name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.sender_customer_name'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_phone.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.sender_phone'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.sender_phone'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('ordersInbound_CompanyName.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.sender_company_name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.sender_company_name'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_address.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.sender_address'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.sender_address'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_addressSecond.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.sender_address_line_2'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.sender_address_line_2'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_city.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.sender_city'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.sender_city'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_region.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.sender_region'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.sender_region'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_country.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.sender_country.name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.sender_country.name'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_zipCode.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.sender_zip'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.sender_zip'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_weightLb.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.weight_lb'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.weight_lb'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_weightOz.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.weight_oz'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.weight_oz'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_heightCm.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.height'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.height'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_lengthCm.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.length'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.length'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_widthCm.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.width'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.width'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('ordersInbound_status.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.status.name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.status.name'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('ordersInbound_receivedAt.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.received_at'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.received_at'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('ordersInbound_orderProcessPrice.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.order_process_price'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.order_process_price'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('ordersInbound_totalOrderProcessPrice.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.total_order_process_price'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.total_order_process_price'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('ordersInbound_shippingPrice.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.shipping_price'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.shipping_price'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('ordersInbound_packagingPrice.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.packaging_price'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.packaging_price'"/></div>
    </div>

  </div>
</template>

<script>

import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import ProformTable from "@/components/coreComponents/Popups/HistoryOfChangesPopup/chunks/ProformTable/ProformTable";
import FileBlock from "@/components/coreComponents/Popups/HistoryOfChangesPopup/chunks/FileBlock/FileBlock";
import CellsTable from "@/components/coreComponents/Popups/HistoryOfChangesPopup/chunks/CellsTable/CellsTable";
export default {
  name: "InboundType",
  components: {CellsTable, FileBlock, ProformTable, DefaultCheckbox, ValueHelper},
  props: {
    pevItem: Object,
    item: Object,
  },

  computed: {
    // _.has(pevItem.history.productProformEntities, index) ? {history: pevItem.history.productProformEntities[index].proform_entities[0]} : pevItem

    getActualOrderProform() {
      if(!this.pevItem) return false
      if(!this.item.history?.deleted_proform_ids?.proform_data) return this.pevItem?.history?.proform_entities

      let actualProform = []
      let deletedIds = this.item.history?.deleted_proform_ids?.proform_data

      this.pevItem?.history?.productProformEntities.map(prevProformItem => {
        let foundDeleted = false
        deletedIds.map(deletedId => {
          if(parseInt(prevProformItem.proform_entities.id) === parseInt(deletedId)) foundDeleted = true
        })
        if(!foundDeleted) actualProform.push(prevProformItem)
      })
      if(actualProform.length > 0) return actualProform

      return false
    },

    getDeletedOrderProform() {
      if(!this.pevItem || !this.item.history?.deleted_proform_ids?.proform_data) return false

      let deletedProform = []
      let deletedIds = this.item.history?.deleted_proform_ids?.proform_data

      deletedIds.map(deletedId => {
        this.pevItem?.history?.productProformEntities.map(proform => {
          if(proform.proform_entities.id === parseInt(deletedId)){
            deletedProform.push(proform)
          }
        })
        // let product = this._.find(this.pevItem?.history?.productProformEntities, {id: parseInt(deletedId)})
        // if(product) deletedProform.push(product)
      })
      // console.log(deletedIds);
      // console.log(this.pevItem?.history?.proform_entities);
      if(deletedProform.length > 0) return deletedProform

      return false
    },

  }

}

</script>

<style scoped>

</style>