
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const ProductsTable = () => import(/* webpackChunkName: "group-products" */ './components/ProductsTable/ProductsTable.vue')
const ProductsCreation = () => import(/* webpackChunkName: "group-products" */ './components/ProductsCreation/ProductsCreation.vue')
const ProductsEdit = () => import(/* webpackChunkName: "group-products" */ './components/ProductsEdit/ProductsEdit.vue')
const ProductsImport = () => import(/* webpackChunkName: "group-products" */ './components/ProductsImport/ProductsImport.vue')
const moduleKey = 'products'


export const ProductsRoutes = [
  {
    path: routerPaths.products,
    component: ProductsTable,
    meta: {
      breadcrumbs: {
        key: [
          'warehouse',
          'products'
        ],
        items: {
          'warehouse': {
            name: 'menu_warehouse',
            link: routerPaths.products,  // TODO change route on warehouse
          },
          'products': {
            name: 'menu_products',
            link: routerPaths.products,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.PRODUCT_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_products'
    },
  },

  {
    path: routerPaths.productsCreate,
    component: ProductsCreation,
    meta: {
      breadcrumbs: {
        key: [
          'warehouse',
          'products',
          'productsCreation'
        ],
        items: {
          'warehouse': {
            name: 'menu_warehouse',
            link: routerPaths.products,  // TODO change route on warehouse
          },
          'products': {
            name: 'menu_products',
            link: routerPaths.products,
          },
          'productsCreation': {
            name: 'breadcrumbs_NewProduct',
            link: routerPaths.productsCreate,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.PRODUCT_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewProduct'
    },
  },
  // {
  //   path: routerPaths.productsItem,
  //   component: ProductsEdit,
  //   meta: {
  //     breadcrumbs: {
  //       key: [
  //         'warehouse',
  //         'products',
  //         'productsEdit'
  //       ],
  //       items: {
  //         'warehouse': {
  //           name: 'menu_warehouse',
  //           link: routerPaths.products,  // TODO change route on warehouse
  //         },
  //         'products': {
  //           name: 'menu_products',
  //           link: routerPaths.products,
  //         },
  //         'productsEdit': {
  //           name: 'breadcrumbs_ProductEdit',
  //           link: routerPaths.productsItem,
  //         },
  //       },
  //     },
  //     pageTitle: 'breadcrumbs_ProductEdit'
  //   },
  // },
  {
    path: routerPaths.productsEdit,
    component: ProductsEdit,
    meta: {
      breadcrumbs: {
        key: [
          'warehouse',
          'products',
          'productsEdit'
        ],
        items: {
          'warehouse': {
            name: 'menu_warehouse',
            link: routerPaths.products,  // TODO change route on warehouse
          },
          'products': {
            name: 'menu_products',
            link: routerPaths.products,
          },
          'productsEdit': {
            name: 'breadcrumbs_ProductEdit',
            link: routerPaths.productsItem,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.PRODUCT_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_ProductEdit'
    },
  },
  {
    path: routerPaths.productsImport,
    component: ProductsImport,
    meta: {
      breadcrumbs: {
        key: [
          'warehouse',
          'products',
          'productsImport'
        ],
        items: {
          'warehouse': {
            name: 'menu_warehouse',
            link: routerPaths.products,  // TODO change route on warehouse
          },
          'products': {
            name: 'menu_products',
            link: routerPaths.products,
          },
          'productsImport': {
            name: 'Import',
            link: routerPaths.productsImport,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.PRODUCT_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'New Product'
    },
  },
]
