<template>
  <button class="tooltip-btn">
    <div class="tooltip-btn__ico">
      <TooltipIco/>
    </div>
  </button>
</template>

<script>
  import TooltipIco from '../../../../../public/img/UI-group/tooltip-ico.svg?inline'

  export default {
    name: "TooltipBtn",

    components: {
      TooltipIco
    },

  }
</script>

<style lang="scss">
  @import "../../../../scss/mixins/mixins";

  .tooltip-btn{
    padding: 3px 5px;
    position: relative;
    top: 1px;
    display: inline-flex;
    border: 0;
    background: transparent;
    cursor: pointer;

    @include for-768{
      /*padding: 0;*/
    }


    &:focus, &:active{
      outline: none;
    }

    &:hover &__ico svg circle{
      fill: #8F7A61;
    }

    &__ico{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 12px;
      height: 12px;

      svg{
        width: 100%;
        height: 100%;

        circle{
          transition: .3s;
        }
      }
    }

    &.top-left &__ico{

      @include from-992 {
        transform: translateX(-10px);
        margin-left: 10px;
      }
    }
  }
</style>
