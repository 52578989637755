
import {routerPaths} from '../../../router/routerPaths'

const GeneratePdfFile = () => import(/* webpackChunkName: "generate-file" */ './components/GeneratePdfFile/GeneratePdfFile.vue')


export const GenerateFileRoutes = [
  {
    path: routerPaths.generatePdfFileId,
    component: GeneratePdfFile,

    pageTitle: 'common_getFileTitle'
  },
]
