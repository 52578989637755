import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const ShippingCostTable = () => import(/* webpackChunkName: "group-shipping-cost" */ './components/ShippingCostTable/ShippingCostTable.vue')
const moduleKey = 'shipping-cost'

export const ShippingCostRoutes = [
  {
    path: routerPaths.mainSettingsShippingCost,
    component: ShippingCostTable,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'breadcrumbs_shippingCost'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsShippingCost,
          },
          'breadcrumbs_shippingCost': {
            name: 'breadcrumbs_shippingCost',
            link: routerPaths.mainSettingsShippingCost,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.DELIVERY_SERVICE_COST_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_shippingCost'
    },
  },
]
