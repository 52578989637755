
export function GetAdminMenuTranslationsList() {

}

GetAdminMenuTranslationsList.prototype = {

  DASHBOARD: () => {
    return [
      'menu_dashboard',
    ]
  },

  ORDER_AND_DISPATHES: () => {
    return [
      'menu_ordersDispatches',
      'menu_express',
      'menu_consolidation',
      'menu_createOrders',
      'menu_ordersFBA',
      'menu_shipmentToWarehouse',
      'menu_problemsMeestReturns',
      'menu_ukrposhta',
      // 'menu_belposhta',
      'menu_cdek',
      'menu_novaPoshta',
      'menu_importOrders',
    ]
  },

  WAREHOUSE: () => {
    return [
      'menu_warehouse',
      'menu_products',
      'menu_refillProducts',
      'menu_disposalorDer',
      // 'menu_warehouseWillCreate',
      // 'menu_IWillCreateLabel',
      // 'menu_ordersEasy',
      // 'menu_returnLabels',
      // 'menu_ordersFromEtsy',
      // 'menu_ordersShopify',
      // 'menu_ordersFromEbay',
      // 'menu_ordersFBA',
      // 'menu_ordersFBM',
      // 'menu_amazonHMOrders',
      // 'menu_DHL',
      // 'menu_DHLNoTransaction',
      // 'menu_TNT',
      // 'menu_Fedex',
      // 'menu_FedexNoTransaction',
      // 'menu_productListingsOnSale',
      // 'menu_productListingsSold',
      // 'menu_ordersUkrposhta',
      // 'menu_ordersBelposhta',
      // 'menu_ordersWooCommerce',
      // 'menu_ordersOpenCart',
      // 'menu_ordersNovaPoshta',
      // 'menu_ordersCDEK',
      // 'menu_FBAInbound',
      // 'menu_FBAReceived',
      // 'menu_FBMInbound',
      // 'menu_consolidationInbound',
      // 'menu_parcelsInTransit',
      // 'menu_goodsRelocation',
      // 'menu_FillAClaim',
      // 'menu_processedPackage',
      // 'menu_unprocessedPackage',
      // 'menu_unidentifiedPackages',
      // 'menu_MeestToUkraine',
      // 'menu_UserRequests',
      // 'menu_trashPackages',
      // 'menu_importOrders',
      // 'menu_express'
    ]
  },

  EXPRESS: () => {
    return [
      'menu_express',
      'menu_customersPayments',
      'menu_unidentifiedPayments',
      'menu_paymentListings',
      'menu_etsyListings',
      'menu_requestFunds',
      'menu_requestReturn',
      'menu_invoices',
      'menu_topUpBalance',
      'menu_payPalCases',
    ]
  },

  PROBLEMS: () => {
    return [
      'menu_parcelsDays',
      'menu_problems',
      'menu_fileAClaim',
      // 'menu_myReturns',
      'menu_returnPackages',
      'menu_processedPackageReturns',
      'menu_unprocessedPackageReturns',
      'menu_unidentifiedReturns',
      'menu_requestPackageReturns',
      'menu_trashPackageReturns',
      'menu_problemsMeestReturns',
    ]
  },

  INCOME_EXPRESS: () => {
    return [
        'menu_incomeExpenses',
        'menu_expenses',
        'menu_otherIncome',
    ]
  },

  FINANCE: () => {
    return [
      'menu_finance',
      'menu_customersPayments',
      'menu_paymentListings',
      'menu_unidentifiedPayments',
      'menu_requestFunds',
      'menu_requestReturn',
      'menu_invoices',
      'menu_financeNewPayments',
      'menu_financeNewPaymentsPayoneer',
      'menu_payPalCases',
      'menu_financeReportCells',
      'menu_financeGenerateReportPage',
      'menu_forbiddenGoods',
      'menu_negativeBalance',
    ]
  },

  REPORTS: () => {
    return [
      'menu_reports',
      'menu_consolidationMeest',
      'menu_reportsAPCDelcon',
      'menu_reportsConsolidation',
      'menu_productsReport',
      'menu_reportsConsolidationOrders',
      'menu_shippingReportDHL',
      'menu_expressPoshtaReport',
      // 'menu_reportsProfitReportTotal',
      'menu_profitReport',
      'menu_financeGenerateReportPage',
      'menu_financeGenerateReportFBAPage',
      'menu_financeProcessFBMReport',
      'menu_trackingForPayPal',
      'menu_connectionsHistory',
      'menu_financeReportCells',
      'menu_fastOrders',
      'menu_TransactionExport',
    ]
  },

  HEARTYSAN: () => {
    return [
      'menu_HeartysanCategory',
      'menu_heartysanUsers',
      'menu_heartysanManufacturerListings',
    ]
  },

  MARKETPLACE: () => {
    return [
      'menu_marketplace',
      'menu_marketplaceProducts',
      'menu_marketplaceReviews',
      'menu_marketplaceRates',
    ]
  },

  SETTINGS: () => {
    return [
      'menu_settings',
      'menu_personalAccount',
      'menu_accountTypes',
      // 'menu_businessAccount',
      'menu_shops',
      'menu_forbiddenKeywords',
      // 'menu_importFromEtsyToWPOrOs',
      'menu_etsyListings',
      'menu_productImportFromEtsy',
      'menu_subuser',
      'menu_profiles',
      'menu_modules',
      'menu_customsInformation',
      'menu_notifications',
      'menu_proform',
      'menu_proformCategory',
      'menu_proformData',
      'menu_myProfile',
      'menu_warehouseManagementConsolidation',
      'menu_mainSettingsOtherShippers',
      'menu_mainSettingsOrderPrice',
      'menu_mainSettingsOrderSize',
      'menu_shippingCompany',
      'menu_mainSettingsShippingCost',
      'menu_mainSettingsPackagingTypes',
      'menu_mainSettingsCells',
      'menu_mainSettingsOrderBins',
      'menu_mainSettingsInventory',
      'menu_IOSSNumbers',
      'menu_InsuranceSkladUsa',
      'menu_thermalPrint',
      'menu_trackTest',
      'menu_consolidationUsers',
      'menu_authTokens',
      'menu_freshChat',
      'menu_zonePrice',
      'menu_PromoCode',
      'menu_systemAdminAuth',
      'menu_scanBarcodeFBM',
      'menu_calculatorSettings',
    ]
  },

  SYSTEM: () => {
    return [
      'menu_system ',
      'menu_systemAdminAuth ',
      'menu_systemUsers',
      'menu_roles',
      'menu_permissions',
      'menu_permissionsGroup',
      'menu_vueComponents',
      'menu_translations',
      'menu_translationsGroups',
    ]
  },

  SCAN_BARCODE: () => {
    return [
      'menu_scanBarcode',
    ]
  },

}
