<template>

  <div class="error-page-block technical-works mt-0 d-flex flex-column align-items-center">

    <div class="auth-head">
      <div class="auth-head__logo ml-0">
        <img src="/img/login-group/login-logo.png" alt="alt">
      </div>
    </div>

    <TechnicalWorks/>

    <p v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <span class="admin-edit" @click="editTranslate(['common_technicalWorksTxt'])"></span>
      {{$t('common_technicalWorksTxt.localization_value.value')}}
    </p>

  </div>
</template>

<script>
  import TechnicalWorks from '../../../../public/img/common/technical-work-img.svg?inline'

  export default {
    name: "TechnicalWorksModule",

    components: {
      TechnicalWorks,
    },

    data() {
      return {

      }
    },

    created() {

    },

  }
</script>

<style scoped lang="scss">

  @import "../../../scss/mixins/mixins";

  .technical-works{
    padding-top: 60px;
    height: 100vh;
    background: #F8F4EE;

    .auth-head{
      margin-bottom: 160px;
    }

    .auth-head__logo{
      padding: 0;
      margin: 0;
    }

    svg{
      width: 685px;

      @include for-992{
        width: 300px;
      }
    }
  }
</style>
