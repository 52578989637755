import {API} from "../../axios/axiosMainUrl";

const prefix = '/products/proform-update/invoice-product'

export const invoiceProduct = {
  state: {
    invoiceProduct: [],
    invoiceProductItem: {},
    invoiceProductCommonList: [],
    invoiceProductBtn: false,
    invoiceProductLoading: true,
    nextInvoiceProductPage: false,
    invoiceProductForPage: 25,
    invoiceProductFilter: '',
  },

  mutations: {
    setInvoiceProductFilter: (state, payload) => {
      state.invoiceProductFilter = payload
    },

    setInvoiceProduct: (state, payload) => {
      if(state.nextInvoiceProductPage) {
        state.invoiceProduct = state.invoiceProduct.concat(payload.invoiceProduct)
      } else {
        state.invoiceProduct = payload.invoiceProduct
      }
    },

    setInvoiceProductCommonList: (state, payload) => {
      state.invoiceProductCommonList = payload.invoiceProductCommonList
    },

    setNextInvoiceProductPage: (state, payload) => {
      state.nextInvoiceProductPage = payload
    },

    setInvoiceProductItem: (state, payload) => {
      state.invoiceProductItem = payload.invoiceProductItem
    },

    setInvoiceProductLoadingStart: (state) => {
      state.invoiceProductLoading = true
    },

    setInvoiceProductLoadingEnd: (state) => {
      state.invoiceProductLoading = false
    },

    changeInvoiceProductBtn: (state) => {
      state.invoiceProductBtn = true
    },
    successInvoiceProductBtn: (state) => {
      state.invoiceProductBtn = false
    },
  },

  getters: {
    getInvoiceProductFilter: state => {
      return state.invoiceProductFilter
    },

    getInvoiceProductForPage: state => {
      return state.invoiceProductForPage
    },

    getInvoiceProduct: state => {
      return state.invoiceProduct
    },

    getInvoiceProductLoading: state => {
      return state.invoiceProductLoading
    },


    getNextInvoiceProductPage: state => {
      return state.nextInvoiceProductPage
    },

    getInvoiceProductItem: state => {
      return state.invoiceProductItem
    },

    getInvoiceProductCommonList: state => {
      return state.invoiceProductCommonList
    },

    getInvoiceProductBtn: state => {
      return state.invoiceProductBtn
    },
  },

  actions: {

    async fetchInvoiceProduct({commit, getters}, filter = '') {
      if(!getters.getNextInvoiceProductPage)
        commit('setInvoiceProductLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setInvoiceProduct',{invoiceProduct: response.data.data.data})
          commit('setInvoiceProductCommonList',{invoiceProductCommonList: response.data.data})
          // commit('setInvoiceProductCommonList',{invoiceProductCommonList: {...response.data.data.meta, ...{
          //       next_page_url: response.data.data.links.next,
          //       first_page_url: response.data.data.links.first,
          //       last_page_url: response.data.data.links.last,
          //       prev_page_url: response.data.data.links.prev
          //     }}})
          commit('setInvoiceProductLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setInvoiceProductLoadingEnd');
        return e;
      }
    },

    async getInvoiceProduct({commit}, id) {
      // commit('setInvoiceProductLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setInvoiceProductItem', {invoiceProductItem: response.data.data})
          commit('setInvoiceProductLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setInvoiceProductLoadingEnd');
        return e;
      }
    },

    async createInvoiceProduct({commit}, data) {
      commit('changeInvoiceProductBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successInvoiceProductBtn');
          return response;
        });
      } catch (e) {
        commit('successInvoiceProductBtn');
        return e;
      }
    },

    async updateInvoiceProduct({commit}, {id, data}) {
      commit('changeInvoiceProductBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successInvoiceProductBtn');
          return response;
        });
      } catch (e) {
        commit('successInvoiceProductBtn');
        return e;
      }
    },

    async deleteInvoiceProduct({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successInvoiceProductBtn');
        return e;
      }
    },

  }
}
