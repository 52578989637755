<template>
  <div class="fragment">
    <div class="header__gide-block"
         v-if="guideItem.getLinkVideo() || guideItem.getLinkEn() || guideItem.getLinkRu() || guideItem.getLinkUa()
          || guideItem.getTextEn() || guideItem.getTextRu() || guideItem.getTextUa() || isAdmin"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">

    <span class="header__gide-link">
      <a
          v-if="guideItem.getLinkVideo()"
          class="header__gide-link admin-edit-item"
          target="_blank"
          :href="guideItem.getLinkVideo()"
      >
        {{ $t('header_video.localization_value.value') }}
      </a>
      <span
          v-if="!guideItem.getLinkVideo() && isAdmin"
          class="header__gide-link"
          style="cursor: pointer;"
          @click="isModalGuidePopup = true"
      >
        {{ $t('header_video.localization_value.value') }}
      </span>
    </span>

      <span class="header__gide-separator"></span>
      <span class="header__gide-link"
            v-bind:class="{'header__gide-link--separate': (guideItem.getLinkVideo() && (($store.getters.GET_LANG === EN_LANG && (guideItem.getLinkEn() || guideItem.getTextEn()))
            || ($store.getters.GET_LANG === RU_LANG && (guideItem.getLinkRu() || guideItem.getTextRu()))
             || ($store.getters.GET_LANG === UA_LANG && (guideItem.getLinkUa() || guideItem.getTextUa()))
              || ($store.getters.GET_LANG === LV_LANG && (guideItem.getLinkLv() || guideItem.getTextLv()))
               || ($store.getters.GET_LANG === PL_LANG && (guideItem.getLinkPl() || guideItem.getTextPl())) )) || isAdmin}"
      >
        <template v-if="$store.getters.GET_LANG === EN_LANG">
          <a
              v-if="guideItem.getLinkEn()"
              class="header__gide-link"
              target="_blank"
              :href="guideItem.getLinkEn()"
          >
            {{ $t('header_readGuide.localization_value.value') }}
          </a>
          <span
              v-else-if="guideItem.getTextEn()"
              class="header__gide-link"
              style="cursor: pointer;"
              @click="isModalShowReadGuidePopup = true"
          >
            {{ $t('header_readGuide.localization_value.value') }}
          </span>
          <span
              v-else-if="isAdmin"
              class="header__gide-link"
              style="cursor: pointer;"
              @click="isModalGuidePopup = true"
          >
            {{ $t('header_readGuide.localization_value.value') }}
          </span>
        </template>

        <template v-if="$store.getters.GET_LANG === RU_LANG">
          <a
              v-if="guideItem.getLinkRu()"
              class="header__gide-link"
              target="_blank"
              :href="guideItem.getLinkRu()"
          >
            {{ $t('header_readGuide.localization_value.value') }}
          </a>
          <span
              v-else-if="guideItem.getTextRu()"
              class="header__gide-link"
              style="cursor: pointer;"
              @click="isModalShowReadGuidePopup = true"
          >
            {{ $t('header_readGuide.localization_value.value') }}
          </span>
          <span
              v-else-if="isAdmin"
              class="header__gide-link"
              style="cursor: pointer;"
              @click="isModalGuidePopup = true"
          >
            {{ $t('header_readGuide.localization_value.value') }}
          </span>
        </template>

        <template v-if="$store.getters.GET_LANG === UA_LANG">
          <a
              v-if="guideItem.getLinkUa()"
              class="header__gide-link"
              target="_blank"
              :href="guideItem.getLinkUa()"
          >
            {{ $t('header_readGuide.localization_value.value') }}
          </a>
          <span
              v-else-if="guideItem.getTextUa()"
              class="header__gide-link"
              style="cursor: pointer;"
              @click="isModalShowReadGuidePopup = true"
          >
            {{ $t('header_readGuide.localization_value.value') }}
          </span>
          <span
              v-else-if="isAdmin"
              class="header__gide-link"
              style="cursor: pointer;"
              @click="isModalGuidePopup = true"
          >
            {{ $t('header_readGuide.localization_value.value') }}
          </span>
        </template>

        <template v-if="$store.getters.GET_LANG === LV_LANG">
          <a
              v-if="guideItem.getLinkLv()"
              class="header__gide-link"
              target="_blank"
              :href="guideItem.getLinkLv()"
          >
            {{ $t('header_readGuide.localization_value.value') }}
          </a>
          <span
              v-else-if="guideItem.getTextLv()"
              class="header__gide-link"
              style="cursor: pointer;"
              @click="isModalShowReadGuidePopup = true"
          >
            {{ $t('header_readGuide.localization_value.value') }}
          </span>
          <span
              v-else-if="isAdmin"
              class="header__gide-link"
              style="cursor: pointer;"
              @click="isModalGuidePopup = true"
          >
            {{ $t('header_readGuide.localization_value.value') }}
          </span>
        </template>

        <template v-if="$store.getters.GET_LANG === PL_LANG">
          <a
              v-if="guideItem.getLinkPl()"
              class="header__gide-link"
              target="_blank"
              :href="guideItem.getLinkPl()"
          >
            {{ $t('header_readGuide.localization_value.value') }}
          </a>
          <span
              v-else-if="guideItem.getTextPl()"
              class="header__gide-link"
              style="cursor: pointer;"
              @click="isModalShowReadGuidePopup = true"
          >
            {{ $t('header_readGuide.localization_value.value') }}
          </span>
          <span
              v-else-if="isAdmin"
              class="header__gide-link"
              style="cursor: pointer;"
              @click="isModalGuidePopup = true"
          >
            {{ $t('header_readGuide.localization_value.value') }}
          </span>
        </template>
    </span>

      <span class="admin-edit" @click="editTranslate(['header_video', 'header_readGuide'])"></span>
      <span v-if="isAdmin" class="guide-edit-btn" @click="isModalGuidePopup = true"></span>
    </div>

    <GuideEditPopup
        v-if="isModalGuidePopup"
        :guideItem="guideItem"
        :edit="edit"
        @close="isModalGuidePopup = false"
        @save="saveGuide"
    />

    <ReadGuideViewPopup
        v-if="isModalShowReadGuidePopup"
        :guideItem="guideItem"
        @close="isModalShowReadGuidePopup = false"
    />
  </div>

</template>

<script>
import GuideEditPopup from "./popups/GuideEditPopup";
import {Guide} from "../../globalModels/Guide";
import {guideMixin} from "../../../mixins/guideMixins/guideMixin";
import {EN_LANG, RU_LANG, UA_LANG, LV_LANG, PL_LANG} from "../../../staticData/staticVariables";
import ReadGuideViewPopup from "./popups/ReadGuideViewPopup";

export default {
  name: "GuideBlock",
  components: {ReadGuideViewPopup, GuideEditPopup},
  mixins: [
      guideMixin
  ],

  watch: {
    '$route.meta.moduleKey' () {
      this.initGuide()
    },
  },

  created() {
    this.initGuide()
  },

  data() {
    return {
      isModalGuidePopup: false,
      isModalShowReadGuidePopup: false,
      guideItem: new Guide(),
      edit: false,
      EN_LANG: EN_LANG,
      RU_LANG: RU_LANG,
      UA_LANG: UA_LANG,
      LV_LANG: LV_LANG,
      PL_LANG: PL_LANG,

      typeWatcher: null,
    }
  },

  methods: {
    initGuide() {
      if (this.$route.meta?.moduleKey) {

        let guideKey = false
        if (this.typeWatcher === null && this.$route.meta?.moduleKeyChapters) {
          if (this.$route.query?.type && this.$route.query?.type !== 'all') {
            guideKey = this.$route.meta.moduleKey + "-" + this.$route.query.type
          }
          //создаем вотчер который мы потом можем отключить если у нашего роута нету меты moduleKeyChapters
          this.typeWatcher = this.$watch('$route.query.type', () => {
            this.initGuide()
          })
        }
        else if (this.typeWatcher !== null && this.$route.meta?.moduleKeyChapters) {
          //модифицируем ключ отработал вотчер
          if (this.$route.query?.type && this.$route.query?.type !== 'all') {
            guideKey = this.$route.meta.moduleKey + "-" + this.$route.query.type
          }
        }
        else if (this.typeWatcher !== null && !this.$route.meta?.moduleKeyChapters) {
          //останавливаем вотчер если он есть и перешли в раздел без меты moduleKeyChapters
          this.typeWatcher()
        }

        this.$store.dispatch('getGuideByKey', guideKey ? guideKey : this.$route.meta.moduleKey).then((response) => {
          let respData = this.getRespData(response)

          if (respData.length) {
            this.guideItem.setGuideItem(respData[0])
            this.edit = true
          }
          else {
            //reset item if no guide for this key
            this.guideItem = new Guide()
            this.guideItem.setChapterKey(guideKey ? guideKey : this.$route.meta.moduleKey)
            this.edit = false
          }
        })
      }
      else {
        this.guideItem = new Guide()
        this.edit = false
      }
    }
  },
}
</script>

<style scoped>
.header__gide-block {
  position: relative;
}

.header__gide-block:hover .guide-edit-btn {
  opacity: 1;
  z-index: 2;
  transition: 0.3s 0.3s;
}
</style>