
export function ErrorLog() {

  this.data = {
    id: '',
    status: '',
    baseURI: '',
    vueComponent: '',
    user: '',
    userAgent: '',
    error: '',
    typeError: '',
    routeHistory: '',
    createdAt: '',
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getStatus = () => {
    return this.data.status
  }
  this.getBaseURI = () => {
    return this.data.baseURI
  }
  this.getVueComponent = () => {
    return this.data.vueComponent
  }
  this.getUserAgent = () => {
    return this.data.userAgent
  }
  this.getError = () => {
    return this.data.error
  }
  this.getTypeError = () => {
    return this.data.typeError
  }
  this.getUser = () => {
    return this.data.user
  }
  this.getRouteHistory = () => {
    return this.data.routeHistory
  }

  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setStatus = (val) => {
    this.data.status = val
  }
  this.setBaseURI = (val) => {
    this.data.baseURI = val
  }
  this.setVueComponent = (val) => {
    this.data.vueComponent = val
  }
  this.setUserAgent = (val) => {
    this.data.userAgent = val
  }
  this.setError = (val) => {
    this.data.error = val
  }
  this.setTypeError = (val) => {
    this.data.typeError = val
  }
  this.setUser = (val) => {
    this.data.user = val
  }
  this.setRouteHistory = (val) => {
    this.data.routeHistory = val
  }
  this.setCreatedAt = (val) => {
    this.data.createdAt = val
  }


  this.validation = {
    id: false,
    status: false,
    baseURI: false,
    vueComponent: false,
    user: false,
    userAgent: false,
    error: false,
    typeError: false,
  }

  this.validationTranslate = {
    id: '',
    status: '',
    baseURI: '',
    vueComponent: '',
    user: '',
    userAgent: '',
    error: '',
    typeError: '',
  }

  this.validationTxt = {
    id: false,
    status: false,
    baseURI: false,
    vueComponent: false,
    user: false,
    userAgent: false,
    error: false,
    typeError: false,
  }

}


ErrorLog.prototype.setItem = function (
  {
    baseURI,
    vueComponent,
    user = null,
    userAgent,
    error,
    typeError,
    routeHistory = null,
  },
  item = null)
{

  if(!item) {
    this.setBaseURI(baseURI)
    this.setVueComponent(vueComponent)
    this.setUserAgent(userAgent)
    this.setError(error)
    this.setTypeError(typeError)
    this.setRouteHistory(routeHistory)
    this.setUser(user)
  }
  else {
    if(item?.additional_data.length > 0) {
      let user = item?.additional_data[0].split('User:')
      if(user && user.length > 0) {
        this.setTypeError(user[0])
      }
      if(user && user.length > 1) {
        this.setUser(user[1])
      }
    }


    this.setBaseURI(item?.route_path)
    this.setVueComponent(item?.vue_component)
    this.setUserAgent(item?.user_agent)
    this.setError(item?.trace_line)
    this.setCreatedAt(item?.created_at)
    this.setRouteHistory(item.route_history)
    this.setId(item?.id)
  }

  // this.setStatus()
}

ErrorLog.prototype.prepareSave = function (edit = false) {
  let data = {
    'trace_line' : this.getError(),
    'route_path' : this.getBaseURI(),
    'vue_component' : this.getVueComponent(),
    'user_agent' : this.getUserAgent(),
    'screen_data' : null,
    'route_history' : this.getRouteHistory(),
    'shot_screen' : null,
    'additional_data' : [this.getTypeError() + ' User:' + this.getUser()],
  }

  if(edit) {
    console.log(edit)
  }

  return data

}
