import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const AccountBanksTable = () => import(/* webpackChunkName: "group-account-types" */ './components/AccountBanksTable/AccountBanksTable.vue')
const AccountBanksCreate = () => import(/* webpackChunkName: "group-account-types" */ './components/AccountBanksCreate/AccountBanksCreate.vue')
const AccountBanksEdit = () => import(/* webpackChunkName: "group-account-types" */ './components/AccountBanksEdit/AccountBanksEdit.vue')
const moduleKey = 'account-banks'

export const AccountBanksRoutes = [
  {
    path: routerPaths.accountTypes,
    component: AccountBanksTable,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettings',
          'accountTypes',
        ],
        items: {
          'mainSettings': {
            name: 'menu_settings',
            link: routerPaths.accountTypes,
          },
          'accountTypes': {
            name: 'breadcrumbs_accountTypes',
            link: routerPaths.accountTypes,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ACCOUNT_BANKS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_accountTypes'
    },
  },
  {
    path: routerPaths.accountTypesCreate,
    component: AccountBanksCreate,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettings',
          'accountTypes',
          'accountTypesCreate',
        ],
        items: {
          'mainSettings': {
            name: 'menu_settings',
            link: routerPaths.accountTypes,
          },
          'accountTypes': {
            name: 'breadcrumbs_accountTypes',
            link: routerPaths.accountTypes,
          },
          'accountTypesCreate': {
            name: 'breadcrumbs_accountTypesCreate',
            link: routerPaths.accountTypesCreate,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ACCOUNT_BANKS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_accountTypesCreate'
    },
  },
  {
    path: routerPaths.accountTypesEditing,
    component: AccountBanksEdit,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettings',
          'accountTypes',
          'accountTypesEdit',
        ],
        items: {
          'mainSettings': {
            name: 'menu_settings',
            link: routerPaths.accountTypes,
          },
          'accountTypes': {
            name: 'breadcrumbs_accountTypes',
            link: routerPaths.accountTypes,
          },
          'accountTypesEdit': {
            name: 'breadcrumbs_accountTypesEdit',
            link: routerPaths.accountTypesEditing,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ACCOUNT_BANKS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_accountTypesEdit'
    },
  },
]
