
import {routerPaths} from '../../../router/routerPaths'
import permissions from "@/router/middleware/permissions";
import {PERMISSIONS} from "@/staticData/permissionsStatic";

const ParcelsTable = () => import(/* webpackChunkName: "group-parcels" */ './components/ParcelsTable/ParcelsTable.vue')
const moduleKey = 'parcels'

export const ParcelsRoutes = [
  {
    path: routerPaths.problemsParcels,
    component: ParcelsTable,
    meta: {
      breadcrumbs: {
        key: [
          'problems',
          'parcels'
        ],
        items: {
          'problems': {
            name: 'menu_problems',
            link: routerPaths.problemsParcels,
          },
          'parcels': {
            name: 'menu_parcelsDays',
            link: routerPaths.problemsParcels,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.PARCELS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_parcelsDays'
    },
  },
]
