
import {API} from '../../../axios/axiosMainUrl'
import {setLanguage} from "../../../language/language";

export const translations = {
  state: {
    translations: [],
    translationItem: {},
    loadingTranslations: false,
    loadingTranslationItem: false,
    translationBtn: false,
    nextPage: false,



    translationsPopup: false,
    translationsPopupFields: [],
    translationsPopupBtn: false,
    loadingTranslationsPopup: false,

    translationsSite: [],

    // translationsSite: {
    //   'common_create': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Create'
    //       }
    //     ]
    //   },
    //   'common_edit': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Edit'
    //       }
    //     ]
    //   },
    //   'common_delete': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Delete'
    //       }
    //     ]
    //   },
    //   'common_submit': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Delete'
    //       }
    //     ]
    //   },
    //   'common_filters': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Filters'
    //       }
    //     ]
    //   },
    //   'common_filtersApplied': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Filters Applied'
    //       }
    //     ]
    //   },
    //
    //   'login_title': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Sign In'
    //       }
    //     ]
    //   },
    //   'login_welcome': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Welcome to Ukrainian Delivery Company “SkladUSA”!\n' +
    //             'Please login to your account to start managing your orders'
    //       }
    //     ]
    //   },
    //   'login_email': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'E-mail'
    //       }
    //     ]
    //   },
    //   'login_password': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Password'
    //       }
    //     ]
    //   },
    //   'login_createPassword': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Password'
    //       }
    //     ]
    //   },
    //   'login_loginButton': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Login'
    //       }
    //     ]
    //   },
    //   'login_newUser': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'New to SkladUSA?'
    //       }
    //     ]
    //   },
    //   'login_createAccount': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Create an Account'
    //       }
    //     ]
    //   },
    //   'login_loginForgot': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Forgot Your Password?'
    //       }
    //     ]
    //   },
    //   'login_forgotTitle': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Forgot Password?'
    //       }
    //     ]
    //   },
    //   'login_forgotSubtitle': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Please enter your email. If the email exists you will receive a recovery link'
    //       }
    //     ]
    //   },
    //   'login_enterPlatform': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'To enter the platform'
    //       }
    //     ]
    //   },
    //   'login_or': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'or'
    //       }
    //     ]
    //   },
    //   'registration_title': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Sign Up'
    //       }
    //     ]
    //   },
    //   'registration_subTitle': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Please, note that we will need e-mail confirmation afterall fields are filled out'
    //       }
    //     ]
    //   },
    //   'registration_personalInformation': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Personal Information'
    //       }
    //     ]
    //   },
    //   'registration_accountDetails': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Account Details'
    //       }
    //     ]
    //   },
    //   'registration_fullfilment': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Fullfilment (choose at least 1 option)'
    //       }
    //     ]
    //   },
    //   'registration_byAmazon': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'By Amazon'
    //       }
    //     ]
    //   },
    //   'registration_byMerchant': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'By Merchant'
    //       }
    //     ]
    //   },
    //   'registration_byMerchantCaption': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Ebay, Etsy, Online Store'
    //       }
    //     ]
    //   },
    //   'registration_agree': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'I Agree with Terms & Conditions and Privacy Policy of SkladUSA'
    //       }
    //     ]
    //   },
    //   'registration_haveAccount': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Have an Account? LogIn'
    //       }
    //     ]
    //   },
    //   'registration_createAccount': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Create Account'
    //       }
    //     ]
    //   },
    //   'common_name': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Name'
    //       }
    //     ]
    //   },
    //   'common_surName': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Surname'
    //       }
    //     ]
    //   },
    //   'common_phone': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Phone'
    //       }
    //     ]
    //   },
    //   'common_location': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Location'
    //       }
    //     ]
    //   },
    //   'common_address': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Address'
    //       }
    //     ]
    //   },
    //   'common_city': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'City'
    //       }
    //     ]
    //   },
    //   'common_country': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Country'
    //       }
    //     ]
    //   },
    //   'common_regions': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Regions'
    //       }
    //     ]
    //   },
    //   'common_postNumber': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Post Number'
    //       }
    //     ]
    //   },
    //   'common_copyright': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Copyright © 2020SkladUsa.com Inc'
    //       }
    //     ]
    //   },
    //   'common_rightsReserved': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'All Rights Reserved'
    //       }
    //     ]
    //   },
    //   'error_required': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'This field is required'
    //       }
    //     ]
    //   },
    //   'error_email': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Write valid email'
    //       }
    //     ]
    //   },
    //   'error_password': {
    //     group_id: 1,
    //     id: 1,
    //     key: "common_create",
    //     localization_values: [
    //       {
    //         value: 'Password will be lot of 8 symbols'
    //       }
    //     ]
    //   },
    //   // 'error_password': {
    //   //   group_id: 1,
    //   //   id: 1,
    //   //   key: "common_create",
    //   //   localization_values: [
    //   //     {
    //   //       value: 'Password will be lot of 8 symbols'
    //   //     }
    //   //   ]
    //   // },
    // },

  },

  mutations: {
    setTranslationsSite: (state, payload) => {
      state.translationsSite = payload.translationsSite
    },


    setNextPage: (state, payload) => {
      state.nextPage = payload
    },


    setTranslationKeys: (state, payload) => {
      state.translationKeys = payload.translationKeys
    },

    setTranslations: (state, payload) => {
      if(state.nextPage) {
        state.translations = state.translations.concat(payload.translations)
      } else {
        state.translations = payload.translations
      }
    },

    setTranslationItem: (state, payload) => {
      state.translationItem = payload.translationItem
    },

    setLoadingStart: (state) => {
      state.loadingTranslations = true
    },
    setLoadingTranslationItemStart: (state) => {
      state.loadingTranslations = true
    },

    setLoadingEnd: (state) => {
      state.loadingTranslations = false
    },
    setLoadingTranslationItemEnd: (state) => {
      state.loadingTranslations = false
    },


    changeTranslation: (state) => {
      state.translationBtn = true
    },
    successTranslation: (state) => {
      state.translationBtn = false
    },

    closeTranslationPopup: (state) => {
      state.translationsPopup = false
    },
    openTranslationPopup: (state) => {
      state.translationsPopup = true
    },


    setTranslationsPopupFields: (state, payload) => {
      state.translationsPopupFields = payload.translationsPopupFields
    },

    setLoadingTranslationsPopupStart: (state) => {
      state.loadingTranslationsPopup = true
    },
    setLoadingTranslationsPopupEnd: (state) => {
      state.loadingTranslationsPopup = true
    },

    changeTranslationPopup: (state) => {
      state.translationsPopupBtn = true
    },
    successTranslationPopup: (state) => {
      state.translationsPopupBtn = false
    },
  },

  getters: {
    translate: state => {
      return state.translationsSite
    },

    getTranslationsSite: state => {
      return state.translationsSite
    },

    getTranslationKeys: state => {
      return state.translationKeys
    },

    getNextPage: state => {
      return state.nextPage
    },

    getTranslations: state => {
      return state.translations
    },

    getTranslationItem: state => {
      return state.translationItem
    },

    getTranslationBtn: state => {
      return state.translationBtn
    },

    getOpenTranslationPopup: state => {
      return state.translationsPopup
    },

    getTranslationsPopupFields: state => {
      return state.translationsPopupFields
    },
  },

  actions: {
    async fetchTranslationItem({commit}, id) {
      commit('setLoadingTranslationItemStart');
      try {
        return await API.get(`localizations/${id}`).then((response) =>{
          commit('setTranslationItem', {translationItem: response.data.data});
          commit('setLoadingTranslationItemEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingTranslationItemEnd');
        return e;
      }
    },

    async fetchTranslations({commit}, filter = '') {
      commit('setLoadingStart');
      try {
        return await API.get(`localizations${filter}`).then((response) =>{
          commit('setTranslations', {translations: response.data.data.data});
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async getTranslations({commit}) {
      try {
        return await API.get('localizations/local-storage-list').then((response) =>{
          // console.log(response.data.data);
          // localStorage.setItem('translation', JSON.stringify(response.data.data))
          // commit('setTranslationsSite', response.data.data)

          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async updateTranslations({commit}, {id, data}) {
      commit('setLoadingStart');
      try {
        return await API.put(`localizations/${id}`, data).then((response) =>{
          commit('setTranslations', {translations: response.data.data});
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async createTranslations({commit}, data) {
      commit('setLoadingStart')
      try {
        return await API.post('localizations', data).then((response) =>{
          // commit('setTranslations', {translations: response.data.translations});
          commit('setLoadingEnd')
          return response
        });
      } catch (e) {
        commit('setLoadingEnd')
        return e
      }
    },


    async deleteTranslations({commit}, id) {
      commit('setLoadingStart');
      try {
        return await API.delete(`localizations/${id}`).then((response) =>{
          // commit('setTranslations', {translations: response.data.translations});
          commit('setLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setLoadingEnd')
        return e;
      }
    },


    async getTranslationsForKey({commit}, keys) {
      commit('setLoadingTranslationsPopupStart');
      try {
        return await API.post(`localizations/keys-translations`, {keys: keys}).then((response) =>{
          commit('openTranslationPopup')
          commit('setTranslationsPopupFields', {translationsPopupFields: response.data.data})
          commit('setLoadingTranslationsPopupEnd')
          return response;
        });
      } catch (e) {
        commit('setLoadingTranslationsPopupEnd')
        return e;
      }
    },


    async translationsForceUpdate({commit, getters}, data) {
      try {
        return await API.put(`localizations/force-update`, data).then((response) =>{
          commit('closeTranslationPopup')

          let translation = JSON.parse(localStorage.getItem('translation'))


          let locale
          getters.GET_LANGUAGES.map((item) => {
            if(item.locale === getters.GET_LANG){
              locale = item.id
            }
          })

          Object.keys(data.translations).map((key) => {
            translation[key].localization_value.value = data.translations[key][locale]
          })

          setLanguage(translation, getters.GET_LANG)

          return response;
        });
      } catch (e) {
        return e;
      }
    },

  }
}
