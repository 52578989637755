
import {routerPaths} from '../../../router/routerPaths'

const MultiSearchTable = () => import(/* webpackChunkName: "group-request-return" */ './components/MultiSearchTable/MultiSearchTable.vue')
const moduleKey = 'multi-search'


export const MultiSearchRoutes = [
  {
    path: routerPaths.multiSearchQuery,
    component: MultiSearchTable,
    meta: {
      breadcrumbs: {
        key: [
          'problemsMultiSearch'
        ],
        items: {
          'problemsMultiSearch': {
            name: 'multiSearch_title',
            link: routerPaths.multiSearchQuery,
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'multiSearch_title'
    },
  },


]
