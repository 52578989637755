import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const ConsolidationUnionTable = () => import(/* webpackChunkName: "group-consolidation-union-table" */ './components/ConsolidationUnionTable/ConsolidationUnionTable.vue')
const ConsolidationUnionCreate = () => import(/* webpackChunkName: "group-consolidation-union-create" */ './components/ConsolidationUnionCreate/ConsolidationUnionCreate.vue')
const ConsolidationUnionEditing = () => import(/* webpackChunkName: "group-consolidation-union-edit" */ './components/ConsolidationUnionEdit/ConsolidationUnionEdit.vue')
const ConsolidationUnionLabel = () => import(/* webpackChunkName: "group-consolidation-union-label" */ './components/ConsolidationUnionCreateLabel/ConsolidationUnionCreateLabel.vue')
const ConsolidationUnionLabelEditing = () => import(/* webpackChunkName: "group-consolidation-union-label-edit" */ './components/ConsolidationUnionEditLabel/ConsolidationUnionEditLabel.vue')
const moduleKey = 'consolidation-union'

export const ConsolidationUnionRoutes = [
  {
    path: routerPaths.reportsConsolidation,
    component: ConsolidationUnionTable,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'reportsConsolidation'
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.reportsConsolidation,
          },
          'reportsConsolidation': {
            name: 'menu_reportsConsolidation',
            link: routerPaths.reportsConsolidation,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.CONSOLIDATION_UNION_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_reportsConsolidation'
    },
  },
  {
    path: routerPaths.reportsConsolidationCreate,
    component: ConsolidationUnionCreate,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'reportsConsolidation',
          'reportsConsolidationCreate'
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.reportsConsolidation,
          },
          'reportsConsolidation': {
            name: 'menu_reportsConsolidation',
            link: routerPaths.reportsConsolidation,
          },
          'reportsConsolidationCreate': {
            name: 'breadcrumbs_reportsConsolidationCreate',
            link: routerPaths.reportsConsolidationCreate,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.CONSOLIDATION_UNION_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_reportsConsolidationCreate'
    },
  },
  {
    path: routerPaths.reportsConsolidationEditItem,
    component: ConsolidationUnionEditing,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'reportsConsolidation',
          'reportsConsolidationEditing'
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.reportsConsolidation,
          },
          'reportsConsolidation': {
            name: 'menu_reportsConsolidation',
            link: routerPaths.reportsConsolidation,
          },
          'reportsConsolidationEditing': {
            name: 'breadcrumbs_reportsConsolidationEdit',
            link: '/',
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.CONSOLIDATION_UNION_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_reportsConsolidationEdit'
    },
  },

  {
    path: routerPaths.reportsConsolidationCreateLabelItem,
    component: ConsolidationUnionLabel,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'reportsConsolidation',
          'reportsConsolidationCreateLabel'
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.reportsConsolidation,
          },
          'reportsConsolidation': {
            name: 'menu_reportsConsolidation',
            link: routerPaths.reportsConsolidation,
          },
          'reportsConsolidationCreateLabel': {
            name: 'breadcrumbs_reportsConsolidationCreateLabel',
            link: '/',
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.CONSOLIDATION_UNION_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_reportsConsolidationCreateLabel'
    },
  },

  {
    path: routerPaths.reportsConsolidationEditLabelItem,
    component: ConsolidationUnionLabelEditing,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'reportsConsolidation',
          'reportsConsolidationEditLabel'
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.reportsConsolidation,
          },
          'reportsConsolidation': {
            name: 'menu_reportsConsolidation',
            link: routerPaths.reportsConsolidation,
          },
          'reportsConsolidationEditLabel': {
            name: 'breadcrumbs_reportsConsolidationEditLabel',
            link: '/',
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.CONSOLIDATION_UNION_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_reportsConsolidationEditLabel'
    },
  },
]
