import {API} from "../../axios/axiosMainUrl";

const prefix = '/marketplace-listings'

export const marketplaceProducts = {
  state: {
    marketplaceProducts: [],
    marketplaceProductsItem: {},
    marketplaceProductsCommonList: [],
    marketplaceProductsBtn: false,
    marketplaceProductsLoading: true,
    nextMarketplaceProductsPage: false,
    marketplaceProductsForPage: 25,
    marketplaceProductsFilter: '',
  },

  mutations: {
    setMarketplaceProductsFilter: (state, payload) => {
      state.marketplaceProductsFilter = payload
    },

    setMarketplaceProducts: (state, payload) => {

      if(state.nextMarketplaceProductsPage) {
        state.marketplaceProducts = state.marketplaceProducts.concat(payload.marketplaceProducts)
      } else {
        state.marketplaceProducts = payload.marketplaceProducts
      }
    },

    setMarketplaceProductsCommonList: (state, payload) => {
      state.marketplaceProductsCommonList = payload.marketplaceProductsCommonList
    },

    setNextMarketplaceProductsPage: (state, payload) => {
      state.nextMarketplaceProductsPage = payload
    },

    setMarketplaceProductsItem: (state, payload) => {
      state.marketplaceProductsItem = payload.marketplaceProductsItem
    },

    setMarketplaceProductsLoadingStart: (state) => {
      state.marketplaceProductsLoading = true
    },

    setMarketplaceProductsLoadingEnd: (state) => {
      state.marketplaceProductsLoading = false
    },

    changeMarketplaceProductsBtn: (state) => {
      state.marketplaceProductsBtn = true
    },
    successMarketplaceProductsBtn: (state) => {
      state.marketplaceProductsBtn = false
    },
  },

  getters: {
    getMarketplaceProductsFilter: state => {
      return state.marketplaceProductsFilter
    },

    getMarketplaceProductsForPage: state => {
      return state.marketplaceProductsForPage
    },

    getMarketplaceProducts: state => {
      return state.marketplaceProducts
    },

    getMarketplaceProductsLoading: state => {
      return state.marketplaceProductsLoading
    },


    getNextMarketplaceProductsPage: state => {
      return state.nextMarketplaceProductsPage
    },

    getMarketplaceProductsItem: state => {
      return state.marketplaceProductsItem
    },

    getMarketplaceProductsCommonList: state => {
      return state.marketplaceProductsCommonList
    },

    getMarketplaceProductsBtn: state => {
      return state.marketplaceProductsBtn
    },
  },

  actions: {

    async fetchMarketplaceProducts({commit, getters}, filter = '') {
      if(!getters.getNextMarketplaceProductsPage)
        commit('setMarketplaceProductsLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setMarketplaceProducts',{marketplaceProducts: response.data.data})
          commit('setMarketplaceProductsCommonList',{marketplaceProductsCommonList: response.data})
          commit('setMarketplaceProductsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setMarketplaceProductsLoadingEnd');
        return e;
      }
    },

    async getMarketplaceProducts({commit}, id) {
      commit('setMarketplaceProductsLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setMarketplaceProductsItem', {marketplaceProductsItem: response.data.data})
          commit('setMarketplaceProductsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setMarketplaceProductsLoadingEnd');
        return e;
      }
    },

    async createMarketplaceProducts({commit}, data) {
      commit('changeMarketplaceProductsBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successMarketplaceProductsBtn');
          return response;
        });
      } catch (e) {
        commit('successMarketplaceProductsBtn');
        return e;
      }
    },

    async updateMarketplaceProducts({commit}, {id, data}) {
      commit('changeMarketplaceProductsBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successMarketplaceProductsBtn');
          return response;
        });
      } catch (e) {
        commit('successMarketplaceProductsBtn');
        return e;
      }
    },

    async deleteMarketplaceProducts({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successMarketplaceProductsBtn');
        return e;
      }
    },

    async getMarketplaceProductCategories({commit}) {
      try {
        return await API.get(`${prefix}/categories-list`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setMarketplaceProductsLoadingEnd');
        return e;
      }
    },

    async addPhotoToMarketplaceProduct({commit}, {id, data}) {
      commit('changeMarketplaceProductsBtn');
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${prefix}/${id}/upload-file`, data).then((response) =>{
          commit('successMarketplaceProductsBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successMarketplaceProductsBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async validatePhotoToMarketplaceProduct({commit}, data) {
      commit('changeMarketplaceProductsBtn');
      API.defaults.headers.post['Accept'] = 'multipart/form-data'
      API.defaults.headers.post['Content-Type'] ='multipart/form-data'
      try {
        return await API.post(`${prefix}/validate-files`, data).then((response) =>{
          commit('successMarketplaceProductsBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successMarketplaceProductsBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async addVideoToMarketplaceProduct({commit}, {id, data}) {
      commit('changeMarketplaceProductsBtn');
      API.defaults.headers.post['Accept'] = 'multipart/form-data'
      API.defaults.headers.post['Content-Type'] ='multipart/form-data'
      try {
        return await API.post(`${prefix}/${id}/upload-video`, data).then((response) =>{
          commit('successMarketplaceProductsBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successMarketplaceProductsBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async addDigitalProducts({commit}, {id, data}) {
      commit('changeMarketplaceProductsBtn');
      API.defaults.headers.post['Accept'] = 'multipart/form-data'
      API.defaults.headers.post['Content-Type'] ='multipart/form-data'
      try {
        return await API.post(`${prefix}/${id}/upload-download-files`, data).then((response) =>{
          commit('successMarketplaceProductsBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successMarketplaceProductsBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async deleteDigitalProduct({commit}, id) {
      commit('changeMarketplaceProductsBtn');
      try {
        return await API.delete(`${prefix}/${id}/delete-download-files`).then((response) =>{
          commit('successMarketplaceProductsBtn');
          return response;
        });
      } catch (e) {
        commit('successMarketplaceProductsBtn');
        return e;
      }
    },

  }
}
