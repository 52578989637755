
import {routerPaths} from '../../../router/routerPaths'
import permissions from "@/router/middleware/permissions";
import {PERMISSIONS} from "@/staticData/permissionsStatic";

const MeestReturnsList = () => import(/* webpackChunkName: "group-meest-orders" */ './components/MeestReturnsList/MeestReturnsList.vue')
const MeestReturnsEditing = () => import(/* webpackChunkName: "group-meest-orders-editing" */ './components/MeestReturnsEditing/MeestReturnsEditing.vue')
const MeestReturnsCreation = () => import(/* webpackChunkName: "group-meest-orders-editing" */ './components/MeestReturnsCreation/MeestReturnsCreation.vue')
const MeestReturnsShow = () => import(/* webpackChunkName: "group-meest-orders-goods-show" */ './components/MeestReturnsShow/MeestReturnsShow.vue')
const moduleKey = 'orders-meest'

export const OrdersMeestRoutes = [
  {
    path: routerPaths.ordersMeestReturns,
    component: MeestReturnsList,
    meta: {
      breadcrumbs: {
        key: [
          'problems',
          'ordersMeestReturns'
        ],
        items: {
          'problems': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersMeestReturns,
          },
          'ordersMeestReturns': {
            name: 'menu_problemsMeestReturns',
            link: routerPaths.ordersMeestReturns,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_MEEST_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_problemsMeestReturns'
    },
  },

  {
    path: routerPaths.ordersMeestReturnsCreation,
    component: MeestReturnsCreation,
    meta: {
      breadcrumbs: {
        key: [
          'problems',
          'ordersMeestReturnsCreation'
        ],
        items: {
          'problems': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersMeestReturns,
          },
          'ordersMeestReturnsCreation': {
            name: 'menu_problemsMeestReturns',
            link: routerPaths.ordersMeestReturnsCreation,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_MEEST_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_problemsMeestReturns'
    },
  },

  {
    path: routerPaths.ordersMeestReturnsEdit,
    component: MeestReturnsEditing,
    meta: {
      breadcrumbs: {
        key: [
          'problems',
          'ordersMeestReturnsEdit'
        ],
        items: {
          'problems': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersMeestReturns,
          },
          'ordersMeestReturnsEdit': {
            name: 'menu_problemsMeestReturns',
            link: routerPaths.ordersMeestReturnsEdit,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_MEEST_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_problemsMeestReturns'
    },
  },

  {
    path: routerPaths.ordersMeestReturnsShow,
    component: MeestReturnsShow,
    meta: {
      breadcrumbs: {
        key: [
          'problems',
          'ordersMeestReturnsShow'
        ],
        items: {
          'problems': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersMeestReturns,
          },
          'ordersMeestReturnsShow': {
            name: 'menu_problemsMeestReturns',
            link: routerPaths.ordersMeestReturnsShow,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_MEEST_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_problemsMeestReturns'
    },
  },
]
