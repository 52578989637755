import {API} from "../../axios/axiosMainUrl";

const prefix = '/report/charts'

export const charts = {
  state: {
    charts: [],
    chartsItem: {},
    chartsByUserId: [],
    chartsCommonList: [],
    chartsBtn: false,
    chartsLoading: true,
    nextChartsPage: false,
    chartsForPage: 25,
    chartsFilter: '',
  },

  mutations: {
    setChartsFilter: (state, payload) => {
      state.chartsFilter = payload
    },

    setCharts: (state, payload) => {
      if(state.nextChartsPage) {
        state.charts = state.charts.concat(payload.charts)
      } else {
        state.charts = payload.charts
      }
    },

    setChartsCommonList: (state, payload) => {
      state.chartsCommonList = payload.chartsCommonList
    },

    setNextChartsPage: (state, payload) => {
      state.nextChartsPage = payload
    },

    setChartsItem: (state, payload) => {
      state.chartsItem = payload.chartsItem
    },

    setChartsByUserId: (state, payload) => {
      state.chartsByUserId = payload.chartsByUserId
    },

    setChartsLoadingStart: (state) => {
      state.chartsLoading = true
    },

    setChartsLoadingEnd: (state) => {
      state.chartsLoading = false
    },

    changeChartsBtn: (state) => {
      state.chartsBtn = true
    },
    successChartsBtn: (state) => {
      state.chartsBtn = false
    },
  },

  getters: {
    getChartsFilter: state => {
      return state.chartsFilter
    },

    getChartsForPage: state => {
      return state.chartsForPage
    },

    getCharts: state => {
      return state.charts
    },

    getChartsLoading: state => {
      return state.chartsLoading
    },


    getNextChartsPage: state => {
      return state.nextChartsPage
    },

    getChartsItem: state => {
      return state.chartsItem
    },

    getChartsByUserId: state => {
      return state.chartsByUserId
    },

    getChartsCommonList: state => {
      return state.chartsCommonList
    },

    getChartsBtn: state => {
      return state.chartsBtn
    },
  },

  actions: {

    async fetchChartsOrders({commit, getters}, filter = '') {
      if(!getters.getNextChartsPage)
        commit('setChartsLoadingStart')
      try {
        return await API.get(`${prefix}/orders${filter}`).then((response) =>{
          commit('setCharts',{charts: response.data.data.data})
          commit('setChartsCommonList',{chartsCommonList: response.data.data})
          commit('setChartsLoadingEnd');
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('setChartsLoadingEnd');
        return e;
      }
    },

    async fetchChartsTransactions({commit, getters}, filter = '') {
      if(!getters.getNextChartsPage)
        commit('setChartsLoadingStart')
      try {
        return await API.get(`${prefix}/shop-transactions${filter}`).then((response) =>{
          commit('setCharts',{charts: response.data.data.data})
          commit('setChartsCommonList',{chartsCommonList: response.data.data})
          commit('setChartsLoadingEnd');
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('setChartsLoadingEnd');
        return e;
      }
    },

  }
}
