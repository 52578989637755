import {API} from "../../axios/axiosMainUrl";

export const proformCategory = {
  state: {
    proformCategory: [],
    proformCategoryItem: {},
    proformCategoryCommonList: [],
    proformCategoryBtn: false,
    proformCategoryLoading: true,
    nextProformCategoryPage: false,
    proformCategoryForPage: 25,
    proformCategoryFilter: '',

    proformProductInitialize: {},
    proformProductCategory: {},
    proformProductSearch: {},
  },

  mutations: {
    setProformCategoryFilter: (state, payload) => {
      state.proformCategoryFilter = payload
    },

    setProformProductInitialize: (state, payload) => {
      state.proformProductInitialize = payload.proformProductInitialize
    },

    setProformProductCategory: (state, payload) => {
      state.proformProductCategory = payload.proformProductCategory
    },

    setProformProductSearch: (state, payload) => {
      state.proformProductSearch = payload.proformProductSearch
    },

    setProformCategory: (state, payload) => {
      if(state.nextProformCategoryPage) {
        state.proformCategory = state.proformCategory.concat(payload.proformCategory)
      } else {
        state.proformCategory = payload.proformCategory
      }
    },

    setProformCategoryCommonList: (state, payload) => {
      state.proformCategoryCommonList = payload.proformCategoryCommonList
    },

    setNextProformCategoryPage: (state, payload) => {
      state.nextProformCategoryPage = payload
    },

    setProformCategoryItem: (state, payload) => {
      state.proformCategoryItem = payload.proformCategoryItem
    },

    setProformCategoryLoadingStart: (state) => {
      state.proformCategoryLoading = true
    },

    setProformCategoryLoadingEnd: (state) => {
      state.proformCategoryLoading = false
    },

    changeProformCategoryBtn: (state) => {
      state.proformCategoryBtn = true
    },
    successProformCategoryBtn: (state) => {
      state.proformCategoryBtn = false
    },
  },

  getters: {
    getProformCategoryFilter: state => {
      return state.proformCategoryFilter
    },

    getProformCategoryForPage: state => {
      return state.proformCategoryForPage
    },

    getProformCategory: state => {
      return state.proformCategory
    },

    getProformProductInitialize: state => {
      return state.proformProductInitialize
    },

    getProformProductCategory: state => {
      return state.proformProductCategory
    },

    getProformProductSearch: state => {
      return state.proformProductSearch
    },

    getProformCategoryLoading: state => {
      return state.proformCategoryLoading
    },


    getNextProformCategoryPage: state => {
      return state.nextProformCategoryPage
    },

    getProformCategoryItem: state => {
      return state.proformCategoryItem
    },

    getProformCategoryCommonList: state => {
      return state.proformCategoryCommonList
    },

    getProformCategoryBtn: state => {
      return state.proformCategoryBtn
    },
  },

  actions: {

    async fetchProformProductInitialize({commit}) {
      try {
        return await API.get(`/products/proform-attachment/initialize`).then((response) =>{
          commit('setProformProductInitialize',{proformProductInitialize: response.data.data})
          return response;
        });
      } catch (e) {
        return e;
      }
    },
    async fetchProformProductCategory({commit}, filter = '') {
      try {
        return await API.get(`/products/proform-attachment/filtered-list${filter}`).then((response) =>{
          commit('setProformProductCategory',{proformProductCategory: response.data.data})
          return response;
        });
      } catch (e) {
        return e;
      }
    },
    async fetchProformProductSearch({commit}, val = '') {
      try {
        return await API.get(`/products/proform-attachment/search?filter[value_name]=${val}`).then((response) =>{
          commit('setProformProductSearch',{proformProductSearch: response.data.data.data})
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async fetchProformCategory({commit}, filter = '') {
      // if(!getters.getNextProformCategoryPage)
      //   commit('setProformCategoryLoadingStart')
      try {
        return await API.get(`/products/proform-category${filter}`).then((response) =>{
          commit('setProformCategory',{proformCategory: response.data.data.data})
          commit('setProformCategoryCommonList',{proformCategoryCommonList: response.data.data})
          commit('setProformCategoryLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setProformCategoryLoadingEnd');
        return e;
      }
    },

    async getProformCategory({commit}, id) {
      try {
        return await API.get(`/products/proform-category/${id}`).then((response) =>{
          commit('setProformCategoryItem', {proformCategoryItem: response.data.data})
          return response;
        });
      } catch (e) {
        commit('setProformCategoryLoadingEnd');
        return e;
      }
    },

    async createProformCategory({commit}, data) {
      commit('changeProformCategoryBtn');
      try {
        return await API.post(`/products/proform-category`, data).then((response) =>{
          commit('successProformCategoryBtn');
          return response;
        });
      } catch (e) {
        commit('successProformCategoryBtn');
        return e;
      }
    },

    async updateProformCategory({commit}, {id, data}) {
      commit('changeProformCategoryBtn');
      try {
        return await API.put(`/products/proform-category/${id}`, data).then((response) =>{
          commit('successProformCategoryBtn');
          return response;
        });
      } catch (e) {
        commit('successProformCategoryBtn');
        return e;
      }
    },

    async updateProformTypeTranslate({commit}, {id, data}) {
      commit('changeProformCategoryBtn');
      try {
        return await API.put(`/products/proform-type/${id}`, data).then((response) =>{
          commit('successProformCategoryBtn');
          return response;
        });
      } catch (e) {
        commit('successProformCategoryBtn');
        return e;
      }
    },

    async deleteProformCategory({commit}, id) {
      try {
        return await API.delete(`/products/proform-category/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successProformCategoryBtn');
        return e;
      }
    },

  }
}
