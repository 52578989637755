import {API} from "../../axios/axiosMainUrl";

const prefix = '/report/orders-in-consolidation'

export const consolidationOrders = {
  state: {
    consolidationOrders: [],
    consolidationOrdersItem: {},
    consolidationOrdersCommonList: [],
    consolidationOrdersBtn: false,
    consolidationOrdersLoading: true,
    nextConsolidationOrdersPage: false,
    consolidationOrdersForPage: 25,
    consolidationOrdersFilter: '',
  },

  mutations: {
    setConsolidationOrdersFilter: (state, payload) => {
      state.consolidationOrdersFilter = payload
    },

    setConsolidationOrders: (state, payload) => {
      if(state.nextConsolidationOrdersPage) {
        state.consolidationOrders = state.consolidationOrders.concat(payload.consolidationOrders)
      } else {
        state.consolidationOrders = payload.consolidationOrders
      }
    },

    setConsolidationOrdersCommonList: (state, payload) => {
      state.consolidationOrdersCommonList = payload.consolidationOrdersCommonList
    },

    setNextConsolidationOrdersPage: (state, payload) => {
      state.nextConsolidationOrdersPage = payload
    },

    setConsolidationOrdersItem: (state, payload) => {
      state.consolidationOrdersItem = payload.consolidationOrdersItem
    },

    setConsolidationOrdersLoadingStart: (state) => {
      state.consolidationOrdersLoading = true
    },

    setConsolidationOrdersLoadingEnd: (state) => {
      state.consolidationOrdersLoading = false
    },

    changeConsolidationOrdersBtn: (state) => {
      state.consolidationOrdersBtn = true
    },
    successConsolidationOrdersBtn: (state) => {
      state.consolidationOrdersBtn = false
    },
  },

  getters: {
    getConsolidationOrdersFilter: state => {
      return state.consolidationOrdersFilter
    },

    getConsolidationOrdersForPage: state => {
      return state.consolidationOrdersForPage
    },

    getConsolidationOrders: state => {
      return state.consolidationOrders
    },

    getConsolidationOrdersLoading: state => {
      return state.consolidationOrdersLoading
    },


    getNextConsolidationOrdersPage: state => {
      return state.nextConsolidationOrdersPage
    },

    getConsolidationOrdersItem: state => {
      return state.consolidationOrdersItem
    },

    getConsolidationOrdersCommonList: state => {
      return state.consolidationOrdersCommonList
    },

    getConsolidationOrdersBtn: state => {
      return state.consolidationOrdersBtn
    },
  },

  actions: {

    async fetchConsolidationOrders({commit, getters}, filter = '') {
      if(!getters.getNextConsolidationOrdersPage)
        commit('setConsolidationOrdersLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setConsolidationOrders',{consolidationOrders: response.data.data.data})
          // commit('setConsolidationOrdersCommonList',{consolidationOrdersCommonList: response.data.data})
          // not a standard meta data output, fix
          commit('setConsolidationOrdersCommonList',{consolidationOrdersCommonList: {...response.data.data.meta, ...{
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev
              }}})
          commit('setConsolidationOrdersLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setConsolidationOrdersLoadingEnd');
        return e;
      }
    },

    async getExportConsolidationOrders({commit}, {filter, exportType}) {
      commit('setExportBtnLoadingStart')
      commit('setLoadingEnd')
      try {
        return await API.get(`${prefix}/export/${exportType}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

    async getExportMarketingReport({commit}, data) {
      commit('setExportBtnLoadingStart')
      API.defaults.headers.post['Accept'] = 'multipart/form-data'
      API.defaults.headers.post['Content-Type'] ='multipart/form-data'
      try {
        return await API.post(`/report/excel-by-tracking-number/from-excel-file`, data,{ responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

  }
}
