<template>
  <div class="side-bar__search-wrap">
    <div class="side-bar__search">
      <div class="side-bar__search-field"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['search_search',])"></div>
        <SearchInput
            :type="'text'"
            :label="$t('search_search.localization_value.value')"
            v-model="searchText"
            @submit="trackSearch($event, false)"
            @keyup.native="trackSearch($event, true)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SearchInput from "../../UI/inputs/SearchInput/SearchInput";
export default {
  name: "SidebarSearch",
  components: {
    SearchInput
  },

  data() {
    return {
      searchText: '',
    }
  },

  methods: {
    trackSearch(e, checkPressedKey = false){
      if (checkPressedKey && e.code !== 'Enter' && e.code !== 'NumpadEnter') return

      this.$router.push(this.$store.getters.GET_PATHS.searchTrack + `/?tracking_number=${this.searchText}`)
    }
  },
}
</script>

<style scoped>

</style>