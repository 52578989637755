import {routerPaths} from "../routerPaths";

export default function permissions({next, to, store}) {

  let middlewarePermissions = to.meta.permissions

  if (!middlewarePermissions.length) {
    return next()
  }

  let validPermission = true

  if (!store.getters.getCurrentUserRights?.additionalPermissions) {
    return store.dispatch('fetchCurrentUserRights').then(() => {
      validPermission = checkHasPermission(middlewarePermissions, store)
      if (!validPermission) {
        return next(routerPaths.notFound)
      }

      return next()
    })
  }

  validPermission = checkHasPermission(middlewarePermissions, store)
  if (!validPermission) {
    return next(routerPaths.notFound)
  }

  return next()
}

function checkHasPermission(middlewarePermissions, store) {
  let validPermission = true
  middlewarePermissions.forEach(permission => {
    let currentUserRights = store.getters.getCurrentUserRights?.additionalPermissions

    if (currentUserRights &&
      !Object.hasOwnProperty.call(currentUserRights, permission)) {
      validPermission = false
    }
  })

  return validPermission
}
