<template>
  <span>
    <template v-if="value && getCheckCost">

      <template v-if="costCurrencyType && !deep">
        {{getCostCurrencyType}}
      </template>

      <template v-if="deep">
        <template v-if="costCurrencyType && getDeepArr.success">
          {{getCostCurrencyType}}
        </template>
        {{getDeepArr.value}}</template>
      <template v-else-if="date">
        <template v-if="value">{{ value | moment(dateType) }}</template>
        <template v-else>—</template>
      </template>
      <template v-else-if="fullValue">
        {{fullValue}}
      </template>
      <template v-else-if="translation">
        {{$t(`${value}.localization_value.value`)}}
      </template>
      <template v-else>
        <template v-if="costType">
          {{parseFloat(value).toFixed(2)}}
        </template>
        <template v-else>{{value}}</template>
      </template>
    </template>
    <template v-else>
      <template v-if="!replaceValue && replaceValue !== 0 && replaceValue !== '' && replaceValue !== false">
        —
      </template>
      <template v-else>
        {{replaceValue}}
      </template>
    </template>

  </span>
</template>

<script>
  export default {
    name: "ValueHelper",

    props: {
      value: [String, Number, Object, Array],
      fullValue: [String, Number, Object],
      searchValue: [String, Number],
      replaceValue: [String, Number],
      deep: {
        type: [String, Boolean],
        default: false
      },
      date: Boolean,
      translation: Boolean,
      dateType: String,
      costType: {
        type: Boolean,
        default: false,
      },
      costCurrencyType: {
        type: String,
        default: null
      }
    },

    computed: {
      getDeepArr() {
        let value = this.replaceValue ? this.replaceValue : '—'
        let deepArray = this.deep.split('.')
        let flagNotFindElem = false

        if(!this._.has(this.value, deepArray[0]))
          return value

        value = this.value

        deepArray.map(item => {
          if(!this._.has(value, item) || value[item] === null){
            flagNotFindElem = true
            return
          }

          value = value[item]
        })

        if(flagNotFindElem) return {success: false, value: this.replaceValue ? this.replaceValue : '—'}

        if(this.date) {
          return {success: true, value: this.$moment(value).format(this.dateType)
          }
        }

        return {success: true, value: value}
      },

      getCostCurrencyType() {
        if(this.costCurrencyType === 'dollar'){
          return '$'
        }
        return ''
      },

      getCheckCost() {
        let value = this.deep ? this.getDeepArr.value : this.value

        if(this.costType && (parseFloat(value) === 0 )){
          return false
        }
        return true
      },
    },

    methods: {

    },
  }
</script>

<style scoped>

</style>
