import {API} from "../../axios/axiosMainUrl";

const prefix = '/products/proform-update/invoice-seller'

export const invoiceSeller = {
  state: {
    invoiceSeller: [],
    invoiceSellerItem: {},
    invoiceSellerCommonList: [],
    invoiceSellerBtn: false,
    invoiceSellerLoading: true,
    nextInvoiceSellerPage: false,
    invoiceSellerForPage: 25,
    invoiceSellerFilter: '',
  },

  mutations: {
    setInvoiceSellerFilter: (state, payload) => {
      state.invoiceSellerFilter = payload
    },

    setInvoiceSeller: (state, payload) => {
      if(state.nextInvoiceSellerPage) {
        state.invoiceSeller = state.invoiceSeller.concat(payload.invoiceSeller)
      } else {
        state.invoiceSeller = payload.invoiceSeller
      }
    },

    setInvoiceSellerCommonList: (state, payload) => {
      state.invoiceSellerCommonList = payload.invoiceSellerCommonList
    },

    setNextInvoiceSellerPage: (state, payload) => {
      state.nextInvoiceSellerPage = payload
    },

    setInvoiceSellerItem: (state, payload) => {
      state.invoiceSellerItem = payload.invoiceSellerItem
    },

    setInvoiceSellerLoadingStart: (state) => {
      state.invoiceSellerLoading = true
    },

    setInvoiceSellerLoadingEnd: (state) => {
      state.invoiceSellerLoading = false
    },

    changeInvoiceSellerBtn: (state) => {
      state.invoiceSellerBtn = true
    },
    successInvoiceSellerBtn: (state) => {
      state.invoiceSellerBtn = false
    },
  },

  getters: {
    getInvoiceSellerFilter: state => {
      return state.invoiceSellerFilter
    },

    getInvoiceSellerForPage: state => {
      return state.invoiceSellerForPage
    },

    getInvoiceSeller: state => {
      return state.invoiceSeller
    },

    getInvoiceSellerLoading: state => {
      return state.invoiceSellerLoading
    },


    getNextInvoiceSellerPage: state => {
      return state.nextInvoiceSellerPage
    },

    getInvoiceSellerItem: state => {
      return state.invoiceSellerItem
    },

    getInvoiceSellerCommonList: state => {
      return state.invoiceSellerCommonList
    },

    getInvoiceSellerBtn: state => {
      return state.invoiceSellerBtn
    },
  },

  actions: {

    async fetchInvoiceSeller({commit, getters}, filter = '') {
      if(!getters.getNextInvoiceSellerPage)
        commit('setInvoiceSellerLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setInvoiceSeller',{invoiceSeller: response.data.data.data})
          commit('setInvoiceSellerCommonList',{invoiceSellerCommonList: response.data.data})
          // commit('setInvoiceSellerCommonList',{invoiceSellerCommonList: {...response.data.data.meta, ...{
          //       next_page_url: response.data.data.links.next,
          //       first_page_url: response.data.data.links.first,
          //       last_page_url: response.data.data.links.last,
          //       prev_page_url: response.data.data.links.prev
          //     }}})
          commit('setInvoiceSellerLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setInvoiceSellerLoadingEnd');
        return e;
      }
    },

    async getInvoiceSeller({commit}, id) {
      // commit('setInvoiceSellerLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setInvoiceSellerItem', {invoiceSellerItem: response.data.data})
          commit('setInvoiceSellerLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setInvoiceSellerLoadingEnd');
        return e;
      }
    },

    async createInvoiceSeller({commit}, data) {
      commit('changeInvoiceSellerBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successInvoiceSellerBtn');
          return response;
        });
      } catch (e) {
        commit('successInvoiceSellerBtn');
        return e;
      }
    },

    async updateInvoiceSeller({commit}, {id, data}) {
      commit('changeInvoiceSellerBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successInvoiceSellerBtn');
          return response;
        });
      } catch (e) {
        commit('successInvoiceSellerBtn');
        return e;
      }
    },

    async deleteInvoiceSeller({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successInvoiceSellerBtn');
        return e;
      }
    },

  }
}
