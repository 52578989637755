import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const ExpressFastOrdersTable = () => import(/* webpackChunkName: "group-fast-orders-table" */ './components/ExpressFastOrdersTable/ExpressFastOrdersTable.vue')
const ExpressFastOrdersCreate = () => import(/* webpackChunkName: "group-fast-orders-create" */ './components/ExpressFastOrdersCreate/ExpressFastOrdersCreate.vue')
const ExpressFastOrdersEdit = () => import(/* webpackChunkName: "group-fast-orders-edit" */ './components/ExpressFastOrdersEdit/ExpressFastOrdersEdit.vue')
const moduleKey = 'fast-orders'

export const ExpressFastOrdersRoutes = [
  {
    path: routerPaths.reportsExpressFastOrders,
    component: ExpressFastOrdersTable,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'fastOrders'
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.reportsExpressFastOrders,
          },
          'fastOrders': {
            name: 'menu_expressFastOrders',
            link: routerPaths.reportsExpressFastOrders,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.FAST_EXPRESS_ORDER],

      moduleKey: moduleKey,
      pageTitle: 'menu_expressFastOrders'
    },
  },

  {
    path: routerPaths.reportsExpressFastOrdersCreate,
    component: ExpressFastOrdersCreate,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'fastOrders',
          'fastOrdersCreate'
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.reportsExpressFastOrders,
          },
          'fastOrders': {
            name: 'menu_expressFastOrders',
            link: routerPaths.reportsExpressFastOrders,
          },
          'fastOrdersCreate': {
            name: 'breadcrumbs_fastExpressOrdersCreate',
            link: routerPaths.reportsExpressFastOrdersCreate,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.FAST_EXPRESS_ORDER],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_fastExpressOrdersCreate'
    },
  },

  {
    path: routerPaths.reportsExpressFastOrdersEditItem,
    component: ExpressFastOrdersEdit,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'fastOrders',
          'fastOrdersEdit'
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.reportsExpressFastOrders,
          },
          'fastOrders': {
            name: 'menu_expressFastOrders',
            link: routerPaths.reportsExpressFastOrders,
          },
          'fastOrdersEdit': {
            name: 'breadcrumbs_fastExpressOrdersEdit',
            link: routerPaths.reportsExpressFastOrdersEditItem,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.FAST_EXPRESS_ORDER],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_fastExpressOrdersEdit'
    },
  },
]
