
import {routerPaths} from '../../../router/routerPaths'
// import permissions from "@/router/middleware/permissions";
// import {PERMISSIONS} from "@/staticData/permissionsStatic";

const HeartysanChoice = () => import(/* webpackChunkName: "group-heartysan-choice" */ './components/HeartysanChoice/HeartysanChoice.vue')
const HeartysanManufacturerListings = () => import(/* webpackChunkName: "group-heartysan-manufacturer-listings" */ './components/HeartysanManufacturer/HeartysanManufacturerListings/HeartysanManufacturerListings.vue')
const HeartysanManufacturerListingShow = () => import(/* webpackChunkName: "group-heartysan-manufacturer-listings" */ './components/HeartysanManufacturer/HeartysanManufacturerListingShow/HeartysanManufacturerListingShow.vue')
const HeartysanListingCreate = () => import(/* webpackChunkName: "group-heartysan-listing-create" */ './components/HeartysanManufacturer/HeartysanListingCreate/HeartysanListingCreate.vue')
const HeartysanListingEdit = () => import(/* webpackChunkName: "group-heartysan-listing-edit" */ './components/HeartysanManufacturer/HeartysanListingEdit/HeartysanListingEdit.vue')
const HeartysanSellerListings = () => import(/* webpackChunkName: "group-heartysan-seller-listings" */ './components/HeartysanSeller/HeartysanSellerListings/HeartysanSellerListings.vue')
const HeartysanSellerListingShow = () => import(/* webpackChunkName: "group-heartysan-seller-listings" */ './components/HeartysanSeller/HeartysanSellerListingShow/HeartysanSellerListingShow.vue')
const moduleKey = 'heartysan'


export const HeartysanRoutes = [
  {
    path: routerPaths.heartysanChoice,
    component: HeartysanChoice,
    meta: {
      breadcrumbs: {
        key: [
          'heartysan',
          'heartysanChoice',
        ],
        items: {
          'heartysan': {
            name: 'menu_heartysan',
            link: routerPaths.heartysanChoice,
          },
          'heartysanChoice': {
            name: 'menu_heartysanChoice',
            link: routerPaths.heartysanChoice,
          },
        },
      },

      // middleware: [permissions],
      // permissions: [PERMISSIONS.FORBIDDEN_WORDS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_heartysanChoice'
    },
  },
  {
    path: routerPaths.heartysanManufacturerListings,
    component: HeartysanManufacturerListings,
    meta: {
      breadcrumbs: {
        key: [
          'menu_heartysan',
          'menu_heartysanManufacturerListings'
        ],
        items: {
          'menu_heartysan': {
            name: 'menu_heartysan',
            link: routerPaths.heartysanManufacturerListings,
          },
          'menu_heartysanManufacturerListings': {
            name: 'menu_heartysanManufacturerListings',
            link: routerPaths.heartysanManufacturerListings,
          },
        },
      },

      // middleware: [permissions],
      // permissions: [PERMISSIONS.FORBIDDEN_WORDS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_heartysanManufacturerListings'
    },
  },
  {
    path: routerPaths.heartysanManufacturerListingsShow,
    component: HeartysanManufacturerListingShow,
    meta: {
      breadcrumbs: {
        key: [
          'menu_heartysan',
          'menu_heartysanManufacturerListingsShow'
        ],
        items: {
          'menu_heartysan': {
            name: 'menu_heartysan',
            link: routerPaths.heartysanManufacturerListings,
          },
          'menu_heartysanManufacturerListingsShow': {
            name: 'menu_heartysanListingsShow',
            link: routerPaths.heartysanManufacturerListingsShow,
          },
        },
      },

      // middleware: [permissions],
      // permissions: [PERMISSIONS.FORBIDDEN_WORDS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_heartysanListingsShow'
    },
  },
  {
    path: routerPaths.heartysanSellerListingsShow,
    component: HeartysanSellerListingShow,
    meta: {
      breadcrumbs: {
        key: [
          'menu_heartysan',
          'menu_heartysanManufacturerListingsShow'
        ],
        items: {
          'menu_heartysan': {
            name: 'menu_heartysan',
            link: routerPaths.heartysanManufacturerListings,
          },
          'menu_heartysanManufacturerListingsShow': {
            name: 'menu_heartysanListingsShow',
            link: routerPaths.heartysanSellerListingsShow,
          },
        },
      },

      // middleware: [permissions],
      // permissions: [PERMISSIONS.FORBIDDEN_WORDS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_heartysanListingsShow'
    },
  },
  {
    path: routerPaths.heartysanSellerListings,
    component: HeartysanSellerListings,
    meta: {
      breadcrumbs: {
        key: [
          'menu_heartysan',
          'menu_heartysanSellerListings'
        ],
        items: {
          'menu_heartysan': {
            name: 'menu_heartysan',
            link: routerPaths.heartysanSellerListings,
          },
          'menu_heartysanSellerListings': {
            name: 'menu_heartysanSellerListings',
            link: routerPaths.heartysanSellerListings,
          },
        },
      },

      // middleware: [permissions],
      // permissions: [PERMISSIONS.FORBIDDEN_WORDS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_heartysanSellerListings'
    },
  },
  {
    path: routerPaths.heartysanListingsCreate,
    component: HeartysanListingCreate,
    meta: {
      breadcrumbs: {
        key: [
          'menu_heartysan',
          'menu_heartysanListings'
        ],
        items: {
          'menu_heartysan': {
            name: 'menu_heartysan',
            link: routerPaths.heartysanManufacturerListings,
          },
          'menu_heartysanListings': {
            name: 'menu_heartysanListingsCreate',
            link: routerPaths.heartysanListingsCreate,
          },
        },
      },

      // middleware: [permissions],
      // permissions: [PERMISSIONS.FORBIDDEN_WORDS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_heartysanListingsCreate'
    },
  },
  {
    path: routerPaths.heartysanListingsEdit,
    component: HeartysanListingEdit,
    meta: {
      breadcrumbs: {
        key: [
          'menu_heartysan',
          'menu_heartysanListings'
        ],
        items: {
          'menu_heartysan': {
            name: 'menu_heartysan',
            link: routerPaths.heartysanManufacturerListings,
          },
          'menu_heartysanListings': {
            name: 'menu_heartysanSellerListingsEdit',
            link: routerPaths.heartysanListingsEdit,
          },
        },
      },

      // middleware: [permissions],
      // permissions: [PERMISSIONS.FORBIDDEN_WORDS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_heartysanSellerListingsEdit'
    },
  },
]
