import {API} from "../../axios/axiosMainUrl";

const prefix = '/guide'

export const guide = {
  state: {
    guide: [],
    guideItem: {},
    guideByUserId: [],
    guideCommonList: [],
    guideBtn: false,
    guideLoading: true,
    nextGuidePage: false,
    guideForPage: 25,
    guideFilter: '',
  },

  mutations: {
    setGuideFilter: (state, payload) => {
      state.guideFilter = payload
    },

    setGuide: (state, payload) => {
      if(state.nextGuidePage) {
        state.guide = state.guide.concat(payload.guide)
      } else {
        state.guide = payload.guide
      }
    },

    setGuideCommonList: (state, payload) => {
      state.guideCommonList = payload.guideCommonList
    },

    setNextGuidePage: (state, payload) => {
      state.nextGuidePage = payload
    },

    setGuideItem: (state, payload) => {
      state.guideItem = payload.guideItem
    },

    setGuideByUserId: (state, payload) => {
      state.guideByUserId = payload.guideByUserId
    },

    setGuideLoadingStart: (state) => {
      state.guideLoading = true
    },

    setGuideLoadingEnd: (state) => {
      state.guideLoading = false
    },

    changeGuideBtn: (state) => {
      state.guideBtn = true
    },
    successGuideBtn: (state) => {
      state.guideBtn = false
    },
  },

  getters: {
    getGuideFilter: state => {
      return state.guideFilter
    },

    getGuideForPage: state => {
      return state.guideForPage
    },

    getGuide: state => {
      return state.guide
    },

    getGuideLoading: state => {
      return state.guideLoading
    },


    getNextGuidePage: state => {
      return state.nextGuidePage
    },

    getGuideItem: state => {
      return state.guideItem
    },

    getGuideByUserId: state => {
      return state.guideByUserId
    },

    getGuideCommonList: state => {
      return state.guideCommonList
    },

    getGuideBtn: state => {
      return state.guideBtn
    },
  },

  actions: {

    async fetchGuide({commit, getters}, filter = '') {
      if(!getters.getNextGuidePage)
        commit('setGuideLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setGuide',{guide: response.data.data.data})
          commit('setGuideCommonList',{guideCommonList: response.data.data})
          commit('setGuideLoadingEnd');
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('setGuideLoadingEnd');
        return e;
      }
    },

    async getGuideByKey({commit}, key) {
      try {
        return await API.get(`${prefix}/by-chapter-key/${key}`).then((response) =>{
          commit('setGuideItem', {guideItem: response.data.data})
          commit('setGuideLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setGuideLoadingEnd');
        return e;
      }
    },

    async createGuide({commit}, data) {
      commit('changeGuideBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successGuideBtn');
          return response;
        });
      } catch (e) {
        commit('successGuideBtn');
        return e;
      }
    },

    async updateGuide({commit}, {id, data}) {
      commit('changeGuideBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successGuideBtn');
          return response;
        });
      } catch (e) {
        commit('successGuideBtn');
        return e;
      }
    },

  }
}
