
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const ExpressProblemTable = () => import(/* webpackChunkName: "group-express-problem" */ './components/ExpressProblemTable/ExpressProblemTable.vue')
const moduleKey = 'forbidden-goods'


export const ExpressProblemRoutes = [
  {
    path: routerPaths.reportsExpressProblem,
    component: ExpressProblemTable,
    meta: {
      breadcrumbs: {
        key: [
          'menu_finance',
          'mainSettingsExpressProblem'
        ],
        items: {
          'menu_finance': {
            name: 'menu_finance',
            link: routerPaths.reportsExpressProblem,
          },
          'mainSettingsExpressProblem': {
            name: 'menu_expressProblem',
            link: routerPaths.reportsExpressProblem,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.FAST_EXPRESS_ORDER],

      moduleKey: moduleKey,
      pageTitle: 'menu_expressProblem'
    },
  }
]
