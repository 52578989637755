var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"save-drafts-modal save-drafts",on:{"close":function($event){return _vm.$emit('close')}}},[_c('template',{slot:"header"},[_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'common_SaveInDrafts',
        ])}}}),_vm._v(" "+_vm._s(_vm.$t('common_SaveInDrafts.localization_value.value'))+"? ")])]),_c('template',{slot:"body"},[_c('div',{staticClass:"save-drafts__content",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'common_DraftTxt',
          'common_DraftTxtNext',
        ])}}}),_c('p',[_vm._v(" "+_vm._s(_vm.$t('common_DraftTxt.localization_value.value'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('common_DraftTxtNext.localization_value.value'))+" ")])])]),_c('template',{slot:"footer"},[_c('div',{staticClass:"save-drafts__btn",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'common_SaveInDrafts',
          'common_NoContinue',
        ])}}}),_c('MainButton',{staticClass:"secondary save-drafts__btn-i wfc",class:{'disabled-btn' : _vm.disabledBtn},attrs:{"value":_vm.$t('common_SaveInDrafts.localization_value.value')},nativeOn:{"click":function($event){return _vm.$emit('yes')}}}),_c('MainButton',{staticClass:"save-drafts__btn-i wfc",attrs:{"value":_vm.$t('common_NoContinue.localization_value.value')},nativeOn:{"click":function($event){return _vm.$emit('close')}}})],1)]),_c('template',{slot:"background"},[_c('div',{staticClass:"save-drafts__bg"},[_c('SaveInDraftsBg')],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }