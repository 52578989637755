<template>
  <div class="side-bar__menu-submenu-item">
    <template v-if="checkVisibleItem">
      <template v-if="item.hasChild">
        <div class="side-bar__menu-submenu-item-name accordItemTitlesJs"
             v-bind:class="{'router-link-active open' : checkActiveSubRoute(item.child)}">
          {{ $t(`${item.translation}.localization_value.value`) }}
        </div>
        <div class="side-bar__menu-mod-submenu"
             v-bind:class="{'open' : checkActiveSubRoute(item.child)}">
          <template v-for="(itemChild, index) in item.child">
            <router-link
                v-if="checkVisibleItemSubmenu(itemChild)"
                :key="index"
                v-bind:class="{'router-link-active' : checkActiveSubRouteItem(itemChild)}"
                :to="itemChild.linkPath" class="side-bar__menu-mod-submenu-item">
              {{ $t(`${itemChild.translation}.localization_value.value`) }}
            </router-link>
          </template>
        </div>
      </template>

      <template v-else>
        <router-link
                v-if="!Object.hasOwnProperty.call(item, 'linkFunc')"
                :to="item.linkPath"
                v-bind:class="{'router-link-active' : checkActiveRoute(item.linkPath)}"
                class="side-bar__menu-submenu-item-name">
          <template v-if="_.has(item, 'count')">
            <span class="side-bar__menu-item-count-wrap">
              <span class="side-bar__menu-item-count-wrap-text">
                 {{ $t(`${item.translation}.localization_value.value`) }}
              </span>


              <span class="side-bar__menu-item-count" v-if="item.count <= 99">
                {{item.count}}
              </span>
              <span class="side-bar__menu-item-count" v-else>
                +99
              </span>
            </span>
          </template>

          <template v-else>
            {{ $t(`${item.translation}.localization_value.value`) }}
          </template>


        </router-link>
        <div v-else
             class="side-bar__menu-submenu-item-name"
             v-bind:class="{'router-link-active' : checkActiveRoute(item.linkPath)}"
             @click="$emit(item.linkFunc)"
        >
         {{ $t(`${item.translation}.localization_value.value`) }}
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import {HEARTYSAN_USER_TYPES, SYSTEM_ROLES} from "@/staticData/staticVariables";

  export default {
    name: "MenuItem",

    props: {
      item: Object,
    },

    computed: {
      checkVisibleItem() {

        if(this._.has(this.item, 'permissionLinkName')){
          if(!this._.has(this.currentPermissions, this.item.permissionLinkName)){
            return false
          }
        }
        if(this._.has(this.item, 'hiddenOptionArr') && this.item.hiddenOptionArr.indexOf('paymentListings') > -1){
          if(!this.getCurrentUserSetting?.use_payment_listing && !this.getCurrentUserSetting?.use_paypal_button){
            return false
          }
        }
        if(this._.has(this.item, 'hiddenOptionArr') && this.item.hiddenOptionArr.indexOf('isBelarus') > -1){
          if(!this.isBelarus){
            return false
          }
        }
        if(this._.has(this.item, 'hiddenOptionArr') && this.item.hiddenOptionArr.indexOf('isUkrainian') > -1){
          if(!this.isUkrainian){
            return false
          }
        }
        if(this._.has(this.item, 'hiddenOptionArr') && this.item.hiddenOptionArr.indexOf('isPoland') > -1){
          if(!this.isPoland){
            return false
          }
        }
        if(this._.has(this.item, 'hiddenOptionArr') && this.item.hiddenOptionArr.indexOf('userManage') > -1){
          if(this.getUserRole?.id === SYSTEM_ROLES.SYSTEM_ROLE_SUB_USER.id)
            return false
        }

        // Heartysan
        if(this._.has(this.item, 'hiddenOptionArr') && this.item.hiddenOptionArr.indexOf('heartysanChoice') > -1){
          if(this.getCurrentUser?.hearty_user_type)
            return false
        }
        if(this._.has(this.item, 'hiddenOptionArr') && this.item.hiddenOptionArr.indexOf('heartysanManufacturer') > -1){
          if(this.getCurrentUser?.hearty_user_type?.id !== HEARTYSAN_USER_TYPES.HEARTYSAN_MANUFACTURER.id)
            return false
        }
        if(this._.has(this.item, 'hiddenOptionArr') && this.item.hiddenOptionArr.indexOf('heartysanSeller') > -1){
          if(this.getCurrentUser?.hearty_user_type?.id !== HEARTYSAN_USER_TYPES.HEARTYSAN_SELLER.id)
            return false
        }

        return true
      }
    },

    methods: {

      checkVisibleItemSubmenu(itemChild) {
        if(this._.has(itemChild, 'permissionLinkName')){
          if(!this._.has(this.currentPermissions, itemChild.permissionLinkName)){
            return false
          }
        }

        return true
      },

      checkActiveRoute(path) {
        return this.$route.path.indexOf(path) > -1
      },

      /**
       * If need active menu item other page with other route path
       */
      checkActiveSubRoute(child) {
        let flag = false
        child.map(item => {
          if (this.$route.path.indexOf(item.linkPath) > -1)
            flag = true

          if(item.linkOtherPath && this.$route.path.indexOf(item.linkOtherPath) > -1){
            flag = true
          }
        })
        return flag
      },

      checkActiveSubRouteItem(item) {
        return item.linkOtherPath && this.$route.path.indexOf(item.linkOtherPath) > -1
      },

    },

  }
</script>

<style scoped>

</style>
