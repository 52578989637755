import {API} from "../../axios/axiosMainUrl";

const prefix = 'warehouse/item/audit'

export const refillProducts = {
  state: {
    refillProducts: [],
    refillProductsItem: {},
    refillProductsByUserId: [],
    refillProductsCommonList: [],
    refillProductsBtn: false,
    refillProductsLoading: true,
    nextRefillProductsPage: false,
    refillProductsForPage: 25,
    refillProductsFilter: '',
  },

  mutations: {
    setRefillProductsFilter: (state, payload) => {
      state.refillProductsFilter = payload
    },

    setRefillProducts: (state, payload) => {
      if(state.nextRefillProductsPage) {
        state.refillProducts = state.refillProducts.concat(payload.refillProducts)
      } else {
        state.refillProducts = payload.refillProducts
      }
    },

    setRefillProductsCommonList: (state, payload) => {
      state.refillProductsCommonList = payload.refillProductsCommonList
    },

    setNextRefillProductsPage: (state, payload) => {
      state.nextRefillProductsPage = payload
    },

    setRefillProductsItem: (state, payload) => {
      state.refillProductsItem = payload.refillProductsItem
    },

    setRefillProductsByUserId: (state, payload) => {
      state.refillProductsByUserId = payload.refillProductsByUserId
    },

    setRefillProductsLoadingStart: (state) => {
      state.refillProductsLoading = true
    },

    setRefillProductsLoadingEnd: (state) => {
      state.refillProductsLoading = false
    },

    changeRefillProductsBtn: (state) => {
      state.refillProductsBtn = true
    },
    successRefillProductsBtn: (state) => {
      state.refillProductsBtn = false
    },
  },

  getters: {
    getRefillProductsFilter: state => {
      return state.refillProductsFilter
    },

    getRefillProductsForPage: state => {
      return state.refillProductsForPage
    },

    getRefillProducts: state => {
      return state.refillProducts
    },

    getRefillProductsLoading: state => {
      return state.refillProductsLoading
    },


    getNextRefillProductsPage: state => {
      return state.nextRefillProductsPage
    },

    getRefillProductsItem: state => {
      return state.refillProductsItem
    },

    getRefillProductsByUserId: state => {
      return state.refillProductsByUserId
    },

    getRefillProductsCommonList: state => {
      return state.refillProductsCommonList
    },

    getRefillProductsBtn: state => {
      return state.refillProductsBtn
    },
  },

  actions: {

    async fetchRefillProducts({commit, getters}, filter = '') {
      if(!getters.getNextRefillProductsPage)
        commit('setRefillProductsLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setRefillProducts',{refillProducts: response.data.data.data})
          commit('setRefillProductsCommonList',{refillProductsCommonList: response.data.data})
          commit('setRefillProductsLoadingEnd');
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('setRefillProductsLoadingEnd');
        return e;
      }
    },

  }
}
