import {API} from "../../axios/axiosMainUrl";

const prefix = '/orders/ioss-number'

export const iossNumbers = {
  state: {
    iossNumbers: [],
    iossNumbersItem: {},
    iossNumbersByUserId: [],
    iossNumbersCommonList: [],
    iossNumbersBtn: false,
    iossNumbersLoading: true,
    nextIOSSNumbersPage: false,
    iossNumbersForPage: 25,
    iossNumbersFilter: '',
  },

  mutations: {
    setIOSSNumbersFilter: (state, payload) => {
      state.iossNumbersFilter = payload
    },

    setIOSSNumbers: (state, payload) => {
      if(state.nextIOSSNumbersPage) {
        state.iossNumbers = state.iossNumbers.concat(payload.iossNumbers)
      } else {
        state.iossNumbers = payload.iossNumbers
      }
    },

    setIOSSNumbersCommonList: (state, payload) => {
      state.iossNumbersCommonList = payload.iossNumbersCommonList
    },

    setNextIOSSNumbersPage: (state, payload) => {
      state.nextIOSSNumbersPage = payload
    },

    setIOSSNumbersItem: (state, payload) => {
      state.iossNumbersItem = payload.iossNumbersItem
    },

    setIOSSNumbersByUserId: (state, payload) => {
      state.iossNumbersByUserId = payload.iossNumbersByUserId
    },

    setIOSSNumbersLoadingStart: (state) => {
      state.iossNumbersLoading = true
    },

    setIOSSNumbersLoadingEnd: (state) => {
      state.iossNumbersLoading = false
    },

    changeIOSSNumbersBtn: (state) => {
      state.iossNumbersBtn = true
    },
    successIOSSNumbersBtn: (state) => {
      state.iossNumbersBtn = false
    },
    removeIOSSNumbersItem: (state, id) => {
      let itemIndex = state.iossNumbers.findIndex(item => item.id === id)
      state.iossNumbers.splice(itemIndex, 1)
    }
  },

  getters: {
    getIOSSNumbersFilter: state => {
      return state.iossNumbersFilter
    },

    getIOSSNumbersForPage: state => {
      return state.iossNumbersForPage
    },

    getIOSSNumbers: state => {
      return state.iossNumbers
    },

    getIOSSNumbersLoading: state => {
      return state.iossNumbersLoading
    },


    getNextIOSSNumbersPage: state => {
      return state.nextIOSSNumbersPage
    },

    getIOSSNumbersItem: state => {
      return state.iossNumbersItem
    },

    getIOSSNumbersByUserId: state => {
      return state.iossNumbersByUserId
    },

    getIOSSNumbersCommonList: state => {
      return state.iossNumbersCommonList
    },

    getIOSSNumbersBtn: state => {
      return state.iossNumbersBtn
    },
  },

  actions: {

    async fetchIOSSNumbersWithPermissions({commit, getters}, filter = '') {
      if(!getters.getNextIOSSNumbersPage)
        commit('setIOSSNumbersLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setIOSSNumbers',{iossNumbers: response.data.data.data})
          commit('setIOSSNumbersCommonList',{iossNumbersCommonList: response.data.data})
          commit('setIOSSNumbersLoadingEnd');
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('setIOSSNumbersLoadingEnd');
        return e;
      }
    },

    async fetchIOSSNumbers({commit, getters}, filter = '') {
      if(!getters.getNextIOSSNumbersPage)
        commit('setIOSSNumbersLoadingStart')
      try {
        return await API.get(`${prefix}/public-list${filter}`).then((response) =>{
          commit('setIOSSNumbers',{iossNumbers: response.data.data.data})
          commit('setIOSSNumbersCommonList',{iossNumbersCommonList: response.data.data})
          commit('setIOSSNumbersLoadingEnd');
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('setIOSSNumbersLoadingEnd');
        return e;
      }
    },


    async getIOSSNumbers({commit}, id) {
      commit('setIOSSNumbersLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          //console.log(response)
          commit('setIOSSNumbersItem', {iossNumbersItem: response.data.data})
          commit('setIOSSNumbersLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setIOSSNumbersLoadingEnd');
        return e;
      }
    },

    async getIOSSNumbersById({commit}, id) {
      try {
        return await API.get(`${prefix}/get-user-notes?user_id=${id}`).then((response) =>{
          commit('setIOSSNumbersByUserId', {iossNumbersByUserId: response.data.data})
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async getIOSSNumbersCreate({commit},) {
      try {
        return await API.get(`${prefix}/create`).then((response) => {
          return response;
        });
      } catch (e) {
        commit('setIOSSNumbersLoadingEnd');
        return e;
      }
    },

    async createIOSSNumbers({commit}, data) {
      commit('changeIOSSNumbersBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successIOSSNumbersBtn');
          return response;
        });
      } catch (e) {
        commit('successIOSSNumbersBtn');
        return e;
      }
    },

    async addMessageIOSSNumbers({commit}, {id: id, data: data}) {
      commit('changeIOSSNumbersBtn');
      try {
        return await API.post(`${prefix}/${id}/add-dialog-message`, data).then((response) =>{
          commit('successIOSSNumbersBtn');
          return response;
        });
      } catch (e) {
        commit('successIOSSNumbersBtn');
        return e;
      }
    },

    async updateIOSSNumbers({commit}, {id, data}) {
      commit('changeIOSSNumbersBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successIOSSNumbersBtn');
          return response;
        });
      } catch (e) {
        commit('successIOSSNumbersBtn');
        return e;
      }
    },

    async partialUpdateIOSSNumbers({commit}, {id, data}) {
      commit('changeIOSSNumbersBtn');
      try {
        return await API.patch(`${prefix}/${id}/partial`, data).then((response) =>{
          commit('successIOSSNumbersBtn');
          return response;
        });
      } catch (e) {
        commit('successIOSSNumbersBtn');
        return e;
      }
    },

    async deleteIOSSNumbers({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          commit('removeIOSSNumbersItem', id)
          return response;
        });
      } catch (e) {
        commit('successIOSSNumbersBtn');
        return e;
      }
    },

    async addIOSSNumbersFile({commit}, data) {
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${prefix}/upload-file`, data).then((response) =>{
          commit('successIOSSNumbersBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successIOSSNumbersBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async getImportIOSSNumbers({commit}, data) {
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${prefix}/import`, data).then((response) =>{
          commit('successIOSSNumbersBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successIOSSNumbersBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },
  }
}
