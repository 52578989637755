export const datePickerMixin = {
  methods: {
    closeHiddenDatePicker(that, id) {
      that.listener = (e) => {
        if (e.target.closest('.mx-datepicker-main') === null && e.target.closest('#id-'+ id) === null) {
          that.open = false
        }
      }
      document.addEventListener('click', that.listener, false)
    },

    removeEventCloseDatePicker(that) {
      document.removeEventListener('click', that.listener, false);
    }
  }
}