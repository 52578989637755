<template>
  <div class="textarea-default"
       :class="{
          active: activeInput || (value && value.length > 0) || (placeholder && placeholder.length > 0),
          'disabled' : disabled === true
       }">
    <textarea
        :id="'default-input-' + id"
        class="textarea-default"
        :class="{
          'force-78px-mh' : forceMinHeight === true
       }"
        v-on:input="handleTextarea($event.target.value)"
        :value="value"
        @focus="activeInput = true"
        @blur="activeInput = false"
        :disabled="disabled"
        @keyup.enter="handleEnter"
        :placeholder="placeholder"
        :maxlength="maxlength"
    >
    </textarea>


    <label :for="'default-input-' + id" class="textarea-default__label"
           :class="{ active: activeInput || (value && value.length > 0) }">
      {{ label }}
    </label>
    <span class="textarea-default__error error-field" v-if="error && errorTxt">{{errorTxt}}</span>
  </div>
</template>

<script>
  export default {
    name: "TextareaDefault",

    data () {
      return {
        id: null,
        content: this.value,
        activeInput: false,
      }
    },

    props: [
      'label',
      'placeholder',
      'value',
      'error',
      'errorTxt',
      'disabled',
      'forceMinHeight',
      'maxlength',
    ],

    mounted () {
      this.id = this._uid
    },

    methods: {
      handleTextarea (value) {
        this.$emit('input', value)
      },

      handleEnter() {
        this.$emit('onEnter')
      },
    }
  }
</script>

<style lang="scss">
  @import "../../../../scss/mixins/mixins";
  @import "../../../../scss/colors";

  .disabled .textarea-default{
    background-color: rgba(143, 122, 97, 0.2) !important;
    border: 0 !important;
  }

  .textarea-default{
    display: block;
    position: relative;

    textarea{
      font-size: 14px;
      line-height: 16px;
      width: 100%;

      padding-left: 16px;
      padding-top: 15px;
      padding-bottom: 15px;
      background: $mainBg;
      border: 1px solid $borderBrown;
      box-sizing: border-box;
      border-radius: 5px;
      min-height: 115px;

      &.force-78px-mh {
        min-height: 78px;
      }

      @include for-992{
        font-size: 1em;
      }

      &::placeholder{
        font-size: 14px;
        /*line-height: 16px;*/
        color: $borderBrown;
        line-height: revert;
      }

      &:focus{
        outline: none;
      }

      /* width */
      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
        border: 1px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
        margin-right: 8px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $borderBrown;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $borderBrown;
        opacity: .5;
      }
    }

    &__label{
      position: absolute;
      left: 16px;
      top: 13px;
      font-size: 14px;
      line-height: 16px;
      color: $borderBrown;
      transition: 0.15s all;
    }

    &.active .textarea-default__label{
      top: -5px;
      background: white;
      background: linear-gradient(0deg, #F8F4EE 0%, #FFFFFF 105.56%);
      padding: 0 2px;
      font-size: 10px;
      line-height: 12px;
    }

    &.active textarea{
      /*border-color: $accent;*/
    }

    &:focus{
      border-color: $accent;
    }

    &__error{
      position: absolute;
      bottom: -18px;
      right: 0;
      font-size: 11px;
      line-height: 13px;
      color: $orange;
    }

    &.ui-no-valid .textarea-default{
      border: 2px solid $orange;
    }
  }
</style>
