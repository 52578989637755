
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const UsersTable = () => import(/* webpackChunkName: "group-users" */ './components/UsersTable/UsersTable.vue')
const UsersEdit = () => import(/* webpackChunkName: "group-users-edit" */ './components/UsersEdit/UsersEdit.vue')
const UsersShow = () => import(/* webpackChunkName: "group-users-show" */ './components/UsersShow/UsersShow.vue')
const CountCorrectTable = () => import(/* webpackChunkName: "group-users-correct" */ './components/CountCorrectTable/CountCorrectTable.vue')
const SubUserCreation = () => import(/* webpackChunkName: "group-users-creation" */ './components/SubUserCreation/SubUserCreation.vue')
const moduleKey = 'users'

export const UsersRoutes = [
  {
    path: routerPaths.mainSettingsUsers,
    component: UsersTable,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettingsUsers'
        ],
        items: {
          'mainSettingsUsers': {
            name: 'menu_users',
            link: routerPaths.mainSettingsUsers,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.USERS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_users'
    },
  },
  {
    path: routerPaths.mainSettingsUsersEdit,
    component: UsersEdit,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettingsUsers',
          'mainSettingsUsersCreation',
        ],
        items: {
          'mainSettingsUsers': {
            name: 'menu_users',
            link: routerPaths.mainSettingsUsers,
          },
          'mainSettingsUsersCreation': {
            name: 'breadcrumbs_NewUser',
            link: routerPaths.mainSettingsUsersEdit,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.USERS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewUser'
    },
  },
  {
    path: routerPaths.mainSettingsUsersShow,
    component: UsersShow,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettingsUsers',
          'mainSettingsUsersCreation',
        ],
        items: {
          'mainSettingsUsers': {
            name: 'menu_users',
            link: routerPaths.mainSettingsUsers,
          },
          'mainSettingsUsersCreation': {
            name: 'breadcrumbs_NewUser',
            link: routerPaths.mainSettingsUsersEdit,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.USERS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewUser'
    },
  },
  {
    path: routerPaths.mainSettingsUsersCountCorrect,
    component: CountCorrectTable,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettingsUsers',
          'mainSettingsUsersCountCorrect',
        ],
        items: {
          'mainSettingsUsers': {
            name: 'menu_users',
            link: routerPaths.mainSettingsUsers,
          },
          'mainSettingsUsersCountCorrect': {
            name: 'breadcrumbs_CountCorrect',
            link: routerPaths.mainSettingsUsersCountCorrect,
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_CountCorrect'
    },
  },
  {
    path: routerPaths.mainSettingsUsersSubAdminCreate,
    component: SubUserCreation,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettingsUsers',
          'mainSettingsUsersSubAdminCreate',
        ],
        items: {
          'mainSettingsUsers': {
            name: 'breadcrumbs_Subuser',
            link: routerPaths.mainSettingsUsers,
          },
          'mainSettingsUsersSubAdminCreate': {
            name: 'breadcrumbs_NewSubadmin',
            link: routerPaths.mainSettingsUsersSubAdminCreate,
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewSubadmin'
    },
  },
]
