<template>
  <div class="fragment">
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        Cells
      </div>
      <div class="history-table__sub-col">
        <template v-if="!pevCells || (Array.isArray(pevCells) && pevCells.length === 0)">
          —
        </template>
        <template v-else>
          <span class="mr-1 mb-1" v-for="(cell, index) in pevCells" :key="index">
            <template v-if="cell.cell">{{ cell.cell.combo_name }}</template>
            <template v-else-if="cell.damage === 0">{{$t('productForCell_GoodsWithoutCells.localization_value.value')}}</template>
            <template v-else>{{$t('productForCell_damagedCount.localization_value.value')}}</template>
            ({{ cell.item_quantity }})
          </span>
        </template>
      </div>
      <div class="history-table__sub-col">
        <template v-if="!itemCells || (Array.isArray(itemCells) && itemCells.length === 0)">
          —
        </template>
        <template v-else>
          <span class="mr-1 mb-1" v-for="(cell, index) in itemCells" :key="index">
            <template v-if="cell.cell">{{ cell.cell.combo_name }}</template>
            <template v-else-if="cell.damage === 0">{{$t('productForCell_GoodsWithoutCells.localization_value.value')}}</template>
            <template v-else>{{$t('productForCell_damagedCount.localization_value.value')}}</template>
            ({{ cell.item_quantity }})
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";

export default {
  name: "CellsTable",

  components: {
    // ValueHelper
  },

  props: {
    pevCells: Array,
    itemCells: Array,
  },

}
</script>

<style scoped>

</style>