import {API} from "../../axios/axiosMainUrl";

const prefix = '/marketplace-shipping-rates'

export const marketplaceRates = {
  state: {
    marketplaceRates: [],
    marketplaceRatesItem: {},
    marketplaceRatesCommonList: [],
    marketplaceRatesBtn: false,
    marketplaceRatesLoading: true,
    nextMarketplaceRatesPage: false,
    marketplaceRatesForPage: 25,
    marketplaceRatesFilter: '',
  },

  mutations: {
    setMarketplaceRatesFilter: (state, payload) => {
      state.marketplaceRatesFilter = payload
    },

    setMarketplaceRates: (state, payload) => {
      if(state.nextMarketplaceRatesPage) {
        state.marketplaceRates = state.marketplaceRates.concat(payload.marketplaceRates)
      } else {
        state.marketplaceRates = payload.marketplaceRates
      }
    },

    setMarketplaceRatesCommonList: (state, payload) => {
      state.marketplaceRatesCommonList = payload.marketplaceRatesCommonList
    },

    setNextMarketplaceRatesPage: (state, payload) => {
      state.nextMarketplaceRatesPage = payload
    },

    setMarketplaceRatesItem: (state, payload) => {
      state.marketplaceRatesItem = payload.marketplaceRatesItem
    },

    setMarketplaceRatesLoadingStart: (state) => {
      state.marketplaceRatesLoading = true
    },

    setMarketplaceRatesLoadingEnd: (state) => {
      state.marketplaceRatesLoading = false
    },

    changeMarketplaceRatesBtn: (state) => {
      state.marketplaceRatesBtn = true
    },
    successMarketplaceRatesBtn: (state) => {
      state.marketplaceRatesBtn = false
    },
  },

  getters: {
    getMarketplaceRatesFilter: state => {
      return state.marketplaceRatesFilter
    },

    getMarketplaceRatesForPage: state => {
      return state.marketplaceRatesForPage
    },

    getMarketplaceRates: state => {
      return state.marketplaceRates
    },

    getMarketplaceRatesLoading: state => {
      return state.marketplaceRatesLoading
    },


    getNextMarketplaceRatesPage: state => {
      return state.nextMarketplaceRatesPage
    },

    getMarketplaceRatesItem: state => {
      return state.marketplaceRatesItem
    },

    getMarketplaceRatesCommonList: state => {
      return state.marketplaceRatesCommonList
    },

    getMarketplaceRatesBtn: state => {
      return state.marketplaceRatesBtn
    },
  },

  actions: {

    async fetchMarketplaceRates({commit, getters}, filter = '') {
      if(!getters.getNextMarketplaceRatesPage)
        commit('setMarketplaceRatesLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setMarketplaceRates',{marketplaceRates: response.data.data})
          commit('setMarketplaceRatesCommonList',{marketplaceRatesCommonList: response.data})
          commit('setMarketplaceRatesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setMarketplaceRatesLoadingEnd');
        return e;
      }
    },

    async getMarketplaceRates({commit}, id) {
      // commit('setMarketplaceRatesLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setMarketplaceRatesItem', {marketplaceRatesItem: response.data.data})
          // commit('setMarketplaceRatesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setMarketplaceRatesLoadingEnd');
        return e;
      }
    },

    async createMarketplaceRates({commit}, data) {
      commit('changeMarketplaceRatesBtn');
      try {
        return await API.post(`${prefix}/create`, data).then((response) =>{
          commit('successMarketplaceRatesBtn');
          return response;
        });
      } catch (e) {
        commit('successMarketplaceRatesBtn');
        return e;
      }
    },

    async updateMarketplaceRates({commit}, {id, data}) {
      commit('changeMarketplaceRatesBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successMarketplaceRatesBtn');
          return response;
        });
      } catch (e) {
        commit('successMarketplaceRatesBtn');
        return e;
      }
    },

    async deleteMarketplaceRates({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successMarketplaceRatesBtn');
        return e;
      }
    },

  }
}
