import {API} from "../../axios/axiosMainUrl";

const prefix = '/delivery/calculator-setting'

export const calculatorSettings = {
  state: {
    calculatorSettings: [],
    calculatorSettingsItem: {},
    calculatorSettingsCommonList: [],
    calculatorSettingsBtn: false,
    calculatorSettingsLoading: true,
    nextCalculatorSettingsPage: false,
    calculatorSettingsForPage: 25,
    calculatorSettingsFilter: '',
  },

  mutations: {
    setCalculatorSettingsFilter: (state, payload) => {
      state.calculatorSettingsFilter = payload
    },

    setCalculatorSettings: (state, payload) => {
      if(state.nextCalculatorSettingsPage) {
        state.calculatorSettings = state.calculatorSettings.concat(payload.calculatorSettings)
      } else {
        state.calculatorSettings = payload.calculatorSettings
      }
    },

    setCalculatorSettingsCommonList: (state, payload) => {
      state.calculatorSettingsCommonList = payload.calculatorSettingsCommonList
    },

    setNextCalculatorSettingsPage: (state, payload) => {
      state.nextCalculatorSettingsPage = payload
    },

    setCalculatorSettingsItem: (state, payload) => {
      state.calculatorSettingsItem = payload.calculatorSettingsItem
    },

    setCalculatorSettingsLoadingStart: (state) => {
      state.calculatorSettingsLoading = true
    },

    setCalculatorSettingsLoadingEnd: (state) => {
      state.calculatorSettingsLoading = false
    },

    changeCalculatorSettingsBtn: (state) => {
      state.calculatorSettingsBtn = true
    },
    successCalculatorSettingsBtn: (state) => {
      state.calculatorSettingsBtn = false
    },
  },

  getters: {
    getCalculatorSettingsFilter: state => {
      return state.calculatorSettingsFilter
    },

    getCalculatorSettingsForPage: state => {
      return state.calculatorSettingsForPage
    },

    getCalculatorSettings: state => {
      return state.calculatorSettings
    },

    getCalculatorSettingsLoading: state => {
      return state.calculatorSettingsLoading
    },


    getNextCalculatorSettingsPage: state => {
      return state.nextCalculatorSettingsPage
    },

    getCalculatorSettingsItem: state => {
      return state.calculatorSettingsItem
    },

    getCalculatorSettingsCommonList: state => {
      return state.calculatorSettingsCommonList
    },

    getCalculatorSettingsBtn: state => {
      return state.calculatorSettingsBtn
    },
  },

  actions: {

    async fetchCalculatorSettings({commit, getters}, filter = '') {
      if(!getters.getNextCalculatorSettingsPage)
        commit('setCalculatorSettingsLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setCalculatorSettings',{calculatorSettings: response.data.data.data})
          commit('setCalculatorSettingsCommonList',{calculatorSettingsCommonList: response.data.data})
          commit('setCalculatorSettingsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setCalculatorSettingsLoadingEnd');
        return e;
      }
    },

    async getCalculatorSettings({commit}, id) {
      commit('setCalculatorSettingsLoadingStart');
      try {
        return await API.get(`${prefix}/${id}/edit`).then((response) =>{
          commit('setCalculatorSettingsItem', {calculatorSettingsItem: response.data.data})
          commit('setCalculatorSettingsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setCalculatorSettingsLoadingEnd');
        return e;
      }
    },

    async createCalculatorSettings({commit}, data) {
      commit('changeCalculatorSettingsBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successCalculatorSettingsBtn');
          return response;
        });
      } catch (e) {
        commit('successCalculatorSettingsBtn');
        return e;
      }
    },

    async updateCalculatorSettings({commit}, {id, data}) {
      commit('changeCalculatorSettingsBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successCalculatorSettingsBtn');
          return response;
        });
      } catch (e) {
        commit('successCalculatorSettingsBtn');
        return e;
      }
    },

    async deleteCalculatorSettings({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successCalculatorSettingsBtn');
        return e;
      }
    },

    async restoreCalculatorSettings({commit}, id) {
      try {
        return await API.get(`${prefix}/${id}/restore`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successCalculatorSettingsBtn');
        return e;
      }
    },

  }
}
