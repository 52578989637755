import {API} from "../../axios/axiosMainUrl";

export const manifests = {
  state: {
    manifests: [],
    manifestsItem: {},
    manifestsByUserId: [],
    manifestsCommonList: [],
    manifestsBtn: false,
    manifestsLoading: true,
    nextManifestsPage: false,
    manifestsForPage: 25,
    manifestsFilter: '',
  },

  mutations: {
    setManifestsFilter: (state, payload) => {
      state.manifestsFilter = payload
    },

    setManifests: (state, payload) => {
      if(state.nextManifestsPage) {
        state.manifests = state.manifests.concat(payload.manifests)
      } else {
        state.manifests = payload.manifests
      }
    },

    setManifestsCommonList: (state, payload) => {
      state.manifestsCommonList = payload.manifestsCommonList
    },

    setNextManifestsPage: (state, payload) => {
      state.nextManifestsPage = payload
    },

    setManifestsItem: (state, payload) => {
      state.manifestsItem = payload.manifestsItem
    },

    setManifestsByUserId: (state, payload) => {
      state.manifestsByUserId = payload.manifestsByUserId
    },

    setManifestsLoadingStart: (state) => {
      state.manifestsLoading = true
    },

    setManifestsLoadingEnd: (state) => {
      state.manifestsLoading = false
    },

    changeManifestsBtn: (state) => {
      state.manifestsBtn = true
    },
    successManifestsBtn: (state) => {
      state.manifestsBtn = false
    },
  },

  getters: {
    getManifestsFilter: state => {
      return state.manifestsFilter
    },

    getManifestsForPage: state => {
      return state.manifestsForPage
    },

    getManifests: state => {
      return state.manifests
    },

    getManifestsLoading: state => {
      return state.manifestsLoading
    },


    getNextManifestsPage: state => {
      return state.nextManifestsPage
    },

    getManifestsItem: state => {
      return state.manifestsItem
    },

    getManifestsByUserId: state => {
      return state.manifestsByUserId
    },

    getManifestsCommonList: state => {
      return state.manifestsCommonList
    },

    getManifestsBtn: state => {
      return state.manifestsBtn
    },
  },

  actions: {

    async fetchManifests({commit, getters}, filter = '') {
      if(!getters.getNextManifestsPage)
        commit('setManifestsLoadingStart')
      try {
        return await API.get(`/delivery/documents${filter}`).then((response) =>{
          commit('setManifests',{manifests: response.data.data.data})
          commit('setManifestsCommonList',{manifestsCommonList: response.data.data})
          commit('setManifestsLoadingEnd');
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('setManifestsLoadingEnd');
        return e;
      }
    },

  }
}
