<template>
  <span class="status-ico">
    <DeliveredIco v-if="type === 'delivered'"/>
    <NoInfoIco v-if="type === 'no-info'"/>
    <InTransitIco v-if="type === 'in-transit'"/>
    <ActiveIco v-if="type === 'active'"/>
    <ActiveIco v-if="type === 'valid'"/>
    <DeclinedIco v-if="type === 'declined'"/>
    <DeclinedIco v-if="type === 'cancel'"/>
    <DeclinedIcoRed v-if="type === 'cancel-red'"/>
    <InProgressIco v-if="type === 'in-progress'"/>
    <InProgressIco v-if="type === 'not_valid'"/>
    <OutForDeliveryIco v-if="type === 'out-for-delivery-2'"/>
    <InTransitIco v-if="type === 'out-for-delivery'"/>
    <CollectedIco v-if="type === 'collected'"/>
    <ReceivedIco v-if="type === 'received'"/>
    <ReturnedIco v-if="type === 'returned'"/>
    <HandmadeIco v-if="type === 'handmade'"/>
    <RejectedIco v-if="type === 'rejected'"/>
    <InvalidIco v-if="type === 'invalid'"/>
    <StarIco v-if="type === 'star'"/>
    <DotsIco v-if="type === 'dots'"/>
  </span>
</template>

<script>
  import DeliveredIco from '../../../../../public/img/common/status/delivered.svg?inline'
  import NoInfoIco from '../../../../../public/img/common/status/no-info.svg?inline'
  import InTransitIco from '../../../../../public/img/common/status/in-transit.svg?inline'
  import ActiveIco from '../../../../../public/img/common/status/active.svg?inline'
  import DeclinedIco from '../../../../../public/img/common/status/declined.svg?inline'
  import DeclinedIcoRed from '../../../../../public/img/common/status/declined-red.svg?inline'
  import InProgressIco from '../../../../../public/img/common/status/in-progress.svg?inline'
  import OutForDeliveryIco from '../../../../../public/img/common/status/out-for-delivery.svg?inline'
  import CollectedIco from '../../../../../public/img/common/status/сollected.svg?inline'
  import ReceivedIco from '../../../../../public/img/common/status/received.svg?inline'
  import ReturnedIco from '../../../../../public/img/common/status/returned.svg?inline'
  import HandmadeIco from '../../../../../public/img/common/status/handmade.svg?inline'
  import RejectedIco from '../../../../../public/img/common/status/rejected.svg?inline'
  import InvalidIco from '../../../../../public/img/common/status/invalid.svg?inline'
  import DotsIco from '../../../../../public/img/common/status/dots.svg?inline'
  import StarIco from '../../../../../public/img/common/status/star.svg?inline'

  export default {
    name: "StatusIcoBtn",

    props: ['type'],

    components: {
      DeliveredIco,
      NoInfoIco,
      InTransitIco,
      ActiveIco,
      DeclinedIco,
      InProgressIco,
      OutForDeliveryIco,
      CollectedIco,
      ReceivedIco,
      ReturnedIco,
      HandmadeIco,
      RejectedIco,
      DeclinedIcoRed,
      InvalidIco,
      DotsIco,
      StarIco,
    }
  }
</script>

<style lang="scss">

  .status-ico{
    display: inline-flex;
    margin-top: -1px;
  }

  td .status-ico{
    position: relative;
    top: 2px;
    /*top: 1px;*/
    + span {
      white-space: nowrap;
    }
  }
</style>
