import {API} from "../../axios/axiosMainUrl";

const deliveryServices = 'delivery-services'

export const shippingCompany = {
  state: {
    shippingCompany: [],
    shippingCompanyItem: {},
    shippingCompanyCommonList: [],
    shippingCompanyBtn: false,
    shippingCompanyLoading: true,
    nextShippingCompanyPage: false,
    shippingCompanyForPage: 25,
    shippingCompanyFilter: '',
  },

  mutations: {
    setShippingCompanyFilter: (state, payload) => {
      state.shippingCompanyFilter = payload
    },

    setShippingCompany: (state, payload) => {
      if(state.nextShippingCompanyPage) {
        state.shippingCompany = state.shippingCompany.concat(payload.shippingCompany)
      } else {
        state.shippingCompany = payload.shippingCompany
      }
    },

    setShippingCompanyCommonList: (state, payload) => {
      state.shippingCompanyCommonList = payload.shippingCompanyCommonList
    },

    setNextShippingCompanyPage: (state, payload) => {
      state.nextShippingCompanyPage = payload
    },

    setShippingCompanyItem: (state, payload) => {
      state.shippingCompanyItem = payload.shippingCompanyItem
    },

    setShippingCompanyLoadingStart: (state) => {
      state.shippingCompanyLoading = true
    },

    setShippingCompanyLoadingEnd: (state) => {
      state.shippingCompanyLoading = false
    },

    changeShippingCompanyBtn: (state) => {
      state.shippingCompanyBtn = true
    },
    successShippingCompanyBtn: (state) => {
      state.shippingCompanyBtn = false
    },
  },

  getters: {
    getShippingCompanyFilter: state => {
      return state.shippingCompanyFilter
    },

    getShippingCompanyForPage: state => {
      return state.shippingCompanyForPage
    },

    getShippingCompany: state => {
      return state.shippingCompany
    },

    getShippingCompanyLoading: state => {
      return state.shippingCompanyLoading
    },


    getNextShippingCompanyPage: state => {
      return state.nextShippingCompanyPage
    },

    getShippingCompanyItem: state => {
      return state.shippingCompanyItem
    },

    getShippingCompanyCommonList: state => {
      return state.shippingCompanyCommonList
    },

    getShippingCompanyBtn: state => {
      return state.shippingCompanyBtn
    },
  },

  actions: {

    async fetchShippingCompany({commit, getters}, filter = '') {
      if(!getters.getNextShippingCompanyPage)
        commit('setShippingCompanyLoadingStart')
      try {
        return await API.get(`${deliveryServices}/public-list${filter}`).then((response) =>{
          commit('setShippingCompany',{shippingCompany: response.data.data.data})
          commit('setShippingCompanyCommonList',{shippingCompanyCommonList: response.data.data})
          commit('setShippingCompanyLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setShippingCompanyLoadingEnd');
        return e;
      }
    },

    async fetchShippingCompanyWithPermissions({commit, getters}, filter = '') {
      if(!getters.getNextShippingCompanyPage)
        commit('setShippingCompanyLoadingStart')
      try {
        return await API.get(`${deliveryServices}${filter}`).then((response) =>{
          commit('setShippingCompany',{shippingCompany: response.data.data.data})
          commit('setShippingCompanyCommonList',{shippingCompanyCommonList: response.data.data})
          commit('setShippingCompanyLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setShippingCompanyLoadingEnd');
        return e;
      }
    },

    async getShippingCompanyByCountryId({commit}, id = '') {
      commit('setShippingCompanyLoadingStart')
      try {
        return await API.get(`${deliveryServices}/by-country?countryId=${id}`).then((response) =>{
          commit('setShippingCompanyLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setShippingCompanyLoadingEnd')
        return e
      }
    },

    async getShippingCompany({commit}, id) {
      // commit('setShippingCompanyLoadingStart');
      try {
        return await API.get(`${deliveryServices}/${id}`).then((response) =>{
          commit('setShippingCompanyItem', {shippingCompanyItem: response.data.data})
          commit('setShippingCompanyLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setShippingCompanyLoadingEnd');
        return e;
      }
    },

    async createShippingCompany({commit}, data) {
      commit('changeShippingCompanyBtn');
      try {
        return await API.post(`${deliveryServices}`, data).then((response) =>{
          commit('successShippingCompanyBtn');
          return response;
        });
      } catch (e) {
        commit('successShippingCompanyBtn');
        return e;
      }
    },

    async updateShippingCompany({commit}, {id, data}) {
      commit('changeShippingCompanyBtn');
      try {
        return await API.put(`${deliveryServices}/${id}`, data).then((response) =>{
          commit('successShippingCompanyBtn');
          return response;
        });
      } catch (e) {
        commit('successShippingCompanyBtn');
        return e;
      }
    },

    async deleteShippingCompany({commit}, id) {
      try {
        return await API.delete(`${deliveryServices}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successShippingCompanyBtn');
        return e;
      }
    },

  }
}
