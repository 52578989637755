
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const ManageUsersTable = () => import(/* webpackChunkName: "group-manage-users-table" */ './components/ManageUsersTable/ManageUsersTable.vue')
const ManageUsersCreate = () => import(/* webpackChunkName: "group-manage-users-create" */ './components/ManageUsersCreate/ManageUsersCreate.vue')
const ManageUsersEdit = () => import(/* webpackChunkName: "group-manage-users-create" */ './components/ManageUsersEdit/ManageUsersEdit.vue')
const ManageUsersGroupsCreate = () => import(/* webpackChunkName: "group-manage-users-preset-create" */ './components/ManageUsersGroupsCreate/ManageUsersGroupsCreate.vue')
const ManageUsersGroupsEdit = () => import(/* webpackChunkName: "group-manage-users-preset-edit" */ './components/ManageUsersGroupsEdit/ManageUsersGroupsEdit.vue')
const moduleKey = 'manage-users'

export const ManageUsersModuleRoutes = [
  {
    path: routerPaths.mainSettingsManageUsersLink,
    component: ManageUsersTable,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'mainSettingsManageUsersLink'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.dashboard,
          },
          'mainSettingsManageUsersLink': {
            name: 'menu_subuser',
            link: routerPaths.mainSettingsManageUsersLink,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.USER_MANAGE_ACCESS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_subuser'
    },
  },
  {
    path: routerPaths.mainSettingsManageUsersLinkCreate,
    component: ManageUsersCreate,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'mainSettingsManageUsersLink',
          'mainSettingsManageUsersLinkCreate',
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.dashboard,
          },
          'mainSettingsManageUsersLink': {
            name: 'menu_subuser',
            link: routerPaths.mainSettingsManageUsersLink,
          },
          'mainSettingsManageUsersLinkCreate': {
            name: 'breadcrumbs_NewSubuser',
            link: routerPaths.mainSettingsManageUsersLinkCreate,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.USER_MANAGE_ACCESS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewSubuser'
    },
  },
  {
    path: routerPaths.mainSettingsManageUsersLinkEdit,
    component: ManageUsersEdit,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'mainSettingsManageUsersLink',
          'mainSettingsManageUsersLinkCreate',
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.dashboard,
          },
          'mainSettingsManageUsersLink': {
            name: 'menu_subuser',
            link: routerPaths.mainSettingsManageUsersLink,
          },
          'mainSettingsManageUsersLinkCreate': {
            name: 'breadcrumbs_NewSubuser',
            link: routerPaths.mainSettingsManageUsersLinkEdit,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.USER_MANAGE_ACCESS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewSubuser'
    },
  },

  {
    path: routerPaths.mainSettingsManageUsersGroupLinkCreate,
    component: ManageUsersGroupsCreate,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'mainSettingsManageUsersLink',
          'mainSettingsManageUsersLinkCreate',
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.dashboard,
          },
          'mainSettingsManageUsersLink': {
            name: 'menu_subuser',
            link: routerPaths.mainSettingsManageUsersLink,
          },
          'mainSettingsManageUsersLinkCreate': {
            name: 'breadcrumbs_NewUserGroup',
            link: routerPaths.mainSettingsManageUsersGroupLinkCreate,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.USER_MANAGE_ACCESS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewUserGroup'
    },
  },
  {
    path: routerPaths.mainSettingsManageUsersGroupLinkEdit,
    component: ManageUsersGroupsEdit,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'mainSettingsManageUsersLink',
          'mainSettingsManageUsersLinkEdit',
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.dashboard,
          },
          'mainSettingsManageUsersLink': {
            name: 'menu_subuser',
            link: routerPaths.mainSettingsManageUsersLink,
          },
          'mainSettingsManageUsersLinkEdit': {
            name: 'breadcrumbs_EditUserGroup',
            link: routerPaths.mainSettingsManageUsersGroupLinkEdit,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.USER_MANAGE_ACCESS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_EditUserGroup'
    },
  },
]
