import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const ZonePriceTable = () => import(/* webpackChunkName: "group-order-price" */ './components/ZonePriceTable/ZonePriceTable.vue')
const moduleKey = 'order-price'

export const ZonePriceRoutes = [
  {
    path: routerPaths.mainSettingsZonePrice,
    component: ZonePriceTable,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'reportsConsolidation'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsZonePrice,
          },
          'reportsConsolidation': {
            name: 'breadcrumbs_mainSettingsZonePrice',
            link: routerPaths.mainSettingsZonePrice,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.EXPRESS_PRICE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_mainSettingsZonePrice'
    },
  },
]
