
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const OrdersInboundTable = () => import(/* webpackChunkName: "group-shipment-warehouse" */ './components/OrdersInboundTable/OrdersInboundTable.vue')
const OrdersInboundCreate = () => import(/* webpackChunkName: "group-shipment-warehouse" */ './components/OrdersInboundCreate/OrdersInboundCreate.vue')
const OrdersInboundEdit = () => import(/* webpackChunkName: "group-shipment-warehouse" */ './components/OrdersInboundEdit/OrdersInboundEdit.vue')
const OrdersInboundDetail = () => import(/* webpackChunkName: "group-shipment-warehouse" */ './components/OrdersInboundDetail/OrdersInboundDetail.vue')
const moduleKey = 'shipment-to-warehouse'

export const OrdersInboundRoutes = [
  {
    path: routerPaths.ordersShipmentWarehouse,
    component: OrdersInboundTable,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'ordersShipmentWarehouse'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersShipmentWarehouse,
          },
          'ordersShipmentWarehouse': {
            name: 'menu_shipmentToWarehouse',
            link: routerPaths.ordersShipmentWarehouse,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_INBOUND_GENERAL],

      moduleKey: moduleKey,
      moduleKeyChapters: true,
      pageTitle: 'menu_shipmentToWarehouse'
    },
  },
  {
    path: routerPaths.ordersShipmentWarehouseCreation,
    component: OrdersInboundCreate,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'ordersShipmentWarehouse',
          'ordersShipmentWarehouseCreate'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersShipmentWarehouse,
          },
          'ordersShipmentWarehouse': {
            name: 'menu_shipmentToWarehouse',
            link: routerPaths.ordersShipmentWarehouse,
          },
          'ordersShipmentWarehouseCreate': {
            name: 'breadcrumbs_NewShipmentWarehouse',
            link: '/',
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_INBOUND_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewShipmentWarehouse'
    },
  },
  {
    path: routerPaths.ordersShipmentWarehouseEditing,
    component: OrdersInboundEdit,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'ordersShipmentWarehouse',
          'ordersShipmentWarehouseCreate'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersShipmentWarehouse,
          },
          'ordersShipmentWarehouse': {
            name: 'menu_shipmentToWarehouse',
            link: routerPaths.ordersShipmentWarehouse,
          },
          'ordersShipmentWarehouseCreate': {
            name: 'breadcrumbs_EditShipmentWarehouse',
            link: '/',
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_INBOUND_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_EditShipmentWarehouse'
    },
  },
  {
    path: routerPaths.ordersShipmentWarehouseDetailInfo,
    component: OrdersInboundDetail,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'ordersShipmentWarehouse',
          'ordersShipmentWarehouseDetail'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersShipmentWarehouse,
          },
          'ordersShipmentWarehouse': {
            name: 'menu_shipmentToWarehouse',
            link: routerPaths.ordersShipmentWarehouse,
          },
          'ordersShipmentWarehouseDetail': {
            name: 'breadcrumbs_DetailShipmentWarehouse',
            link: '/',
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_INBOUND_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_shipmentToWarehouse'
    },
  },
]
