
import {routerPaths} from '../../../router/routerPaths'

const Login = () => import(/* webpackChunkName: "group-login" */ './components/Login/Login.vue')
const Forgot = () => import(/* webpackChunkName: "group-forgot" */ './components/Forgot/Forgot.vue')
const ForgotReset = () => import(/* webpackChunkName: "group-forgot" */ './components/ForgotReset/ForgotReset.vue')



export const AuthRoutes = [
  {
    path: routerPaths.login,
    component:  Login ,
    meta: {
      layout: {
        name: 'auth',
      },
      pageTitle: 'login_title'
    },
  },
  {
    path: routerPaths.loginAdmin,
    component:  Login ,
    meta: {
      layout: {
        name: 'auth',
      },
      pageTitle: 'login_title'
    },
  },
  {
    path: routerPaths.forgot,
    component: Forgot,
    meta: {
      layout: {
        name: 'auth',
      },
      pageTitle: 'login_forgotTitle'
    },
  },
  {
    path: routerPaths.forgotAdmin,
    component: Forgot,
    meta: {
      layout: {
        name: 'auth',
      },
      pageTitle: 'login_forgotTitle'
    },
  },
  {
    path: routerPaths.forgotReset,
    component: ForgotReset,
    meta: {
      layout: {
        name: 'auth',
      },
      pageTitle: 'login_newPassword'
    },
  },
  {
    path: routerPaths.forgotResetAdmin,
    component: ForgotReset,
    meta: {
      layout: {
        name: 'auth',
      },
      pageTitle: 'login_newPassword'
    },
  },
]
