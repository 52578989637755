import {API} from "../../axios/axiosMainUrl";

export const payPallAPI = {
  state: {
    payPall: [],
    payPallItem: {},
    payPallCommonList: [],
    payPallBtn: false,
    payPallLoading: false,
  },

  mutations: {
    setPayPall: (state, payload) => {
      state.payPall = payload.payPall
    },
    setPayPallItem: (state, payload) => {
      state.payPallItem = payload.payPallItem
    },

    setPayPallLoadingStart: (state) => {
      state.payPallLoading = true
    },

    setPayPallLoadingEnd: (state) => {
      state.payPallLoading = false
    },

    changePayPallBtn: (state) => {
      state.payPallBtn = true
    },
    successPayPallBtn: (state) => {
      state.payPallBtn = false
    },
  },

  getters: {
    getPayPall: state => {
      return state.payPall
    },

    getPayPallItem: state => {
      return state.payPallItem
    },

    getPayPallCommonList: state => {
      return state.payPallCommonList
    },

    getPayPallBtn: state => {
      return state.payPallBtn
    },
  },

  actions: {

    async fetchPayPall({commit}, filter = '') {
      commit('setPayPallLoadingStart');
      try {
        return await API.get(`/payPall${filter}`).then((response) =>{
          commit('setPayPall',{payPall: response.data.data})
          commit('setPayPallLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setPayPallLoadingEnd');
        return e;
      }
    },

    async getPayPall({commit}, id) {
      commit('setPayPallLoadingStart');
      try {
        return await API.get(`/payPall/${id}`).then((response) =>{
          console.log(response)
          commit('setPayPallItem', {payPallItem: response.data.data})
          commit('setPayPallCommonList',{payPallCommonList: response.data.data})
          commit('setPayPallLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setPayPallLoadingEnd');
        return e;
      }
    },

    async createPayPall({commit}, data) {
      commit('changePayPallBtn');
      try {
        return await API.post(`/payPall`, data).then((response) =>{
          commit('successPayPallBtn');
          return response;
        });
      } catch (e) {
        commit('successPayPallBtn');
        return e;
      }
    },

    async updatePayPall({commit}, {id, data}) {
      commit('changePayPallBtn');
      try {
        return await API.put(`/payPall/${id}`, data).then((response) =>{
          commit('successPayPallBtn');
          return response;
        });
      } catch (e) {
        commit('successPayPallBtn');
        return e;
      }
    },

    async deletePayPall({commit}, id) {
      try {
        return await API.delete(`/payPall/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successPayPallBtn');
        return e;
      }
    },

    async newPaymentPayPall({commit}, data) {
      commit('changePayPallBtn');
      try {
        return await API.post(`/api-payments/paypal/create-pay`, data).then((response) =>{
          commit('successPayPallBtn');
          return response;
        });
      } catch (e) {
        commit('successPayPallBtn');
        return e;
      }
    },

    async executePaymentPayPall({commit}, data) {
      commit('changePayPallBtn');
      try {
        return await API.post(`/api-payments/paypal/execute-pay`, data).then((response) =>{
          console.log(response);
          commit('successPayPallBtn');
          return response;
        });
      } catch (e) {
        commit('successPayPallBtn');
        return e;
      }
    },



  }
}
