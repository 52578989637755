import {API} from "../../axios/axiosMainUrl";

export const requestReturn = {
  state: {
    requestReturn: [],
    requestReturnItem: {},
    requestReturnCommonList: [],
    requestReturnBtn: false,
    requestReturnLoading: true,
    nextRequestReturnPage: false,
    requestReturnForPage: 25,
    requestReturnFilter: '',

    requestReturnSocket: false,
    requestReturnSocketChannel: 'refund-channel',
    requestReturnSocketEvent: 'status.updated',
  },

  mutations: {
    setRequestReturnFilter: (state, payload) => {
      state.requestReturnFilter = payload
    },

    setRequestReturn: (state, payload) => {
      if(state.nextRequestReturnPage) {
        state.requestReturn = state.requestReturn.concat(payload.requestReturn)
      } else {
        state.requestReturn = payload.requestReturn
      }
    },

    setRequestReturnCommonList: (state, payload) => {
      state.requestReturnCommonList = payload.requestReturnCommonList
    },

    setNextRequestReturnPage: (state, payload) => {
      state.nextRequestReturnPage = payload
    },

    setRequestReturnItem: (state, payload) => {
      state.requestReturnItem = payload.requestReturnItem
    },

    setRequestReturnLoadingStart: (state) => {
      state.requestReturnLoading = true
    },

    setRequestReturnLoadingEnd: (state) => {
      state.requestReturnLoading = false
    },

    setRequestReturnSocket: (state, payload) => {
      state.requestReturnSocket = payload
    },

    changeRequestReturnBtn: (state) => {
      state.requestReturnBtn = true
    },
    successRequestReturnBtn: (state) => {
      state.requestReturnBtn = false
    },
  },

  getters: {
    getRequestReturnFilter: state => {
      return state.requestReturnFilter
    },

    getRequestReturnForPage: state => {
      return state.requestReturnForPage
    },

    getRequestReturn: state => {
      return state.requestReturn
    },

    getRequestReturnLoading: state => {
      return state.requestReturnLoading
    },

    getRequestReturnSocket: state => {
      return state.requestReturnSocket
    },

    getRequestReturnSocketChannel: state => {
      return state.requestReturnSocketChannel
    },

    getRequestReturnSocketEvent: state => {
      return state.requestReturnSocketEvent
    },


    getNextRequestReturnPage: state => {
      return state.nextRequestReturnPage
    },

    getRequestReturnItem: state => {
      return state.requestReturnItem
    },

    getRequestReturnCommonList: state => {
      return state.requestReturnCommonList
    },

    getRequestReturnBtn: state => {
      return state.requestReturnBtn
    },
  },

  actions: {

    async fetchRequestReturn({commit, getters}, {filter = ''}) {
      if(!getters.getNextRequestReturnPage)
        commit('setRequestReturnLoadingStart')
      try {
        return await API.get(`/payments/refunds${filter}`).then((response) =>{
          commit('setRequestReturn',{requestReturn: response.data.data.data})
          commit('setRequestReturnCommonList',{requestReturnCommonList: {...response.data.data.meta, ...{
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev
              }}})
          commit('setRequestReturnLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setRequestReturnLoadingEnd');
        return e;
      }
    },

    async getRequestReturn({commit}, id) {
      // commit('setRequestReturnLoadingStart');
      try {
        return await API.get(`/payments/refunds/${id}`).then((response) =>{
          console.log(response)
          commit('setRequestReturnItem', {requestReturnItem: response.data.data})
          commit('setRequestReturnLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setRequestReturnLoadingEnd');
        return e;
      }
    },

    async createRequestReturn({commit}, data) {
      commit('changeRequestReturnBtn');
      try {
        return await API.post(`/payments/refunds/create-refund`, data).then((response) =>{
          commit('successRequestReturnBtn');
          return response;
        });
      } catch (e) {
        commit('successRequestReturnBtn');
        return e;
      }
    },

    async changeRequestReturn({commit}, {id, data}) {
      commit('changeRequestReturnBtn');
      try {
        return await API.put(`/payments/refunds/moderate/${id}`, data).then((response) =>{
          commit('successRequestReturnBtn');
          return response;
        });
      } catch (e) {
        commit('successRequestReturnBtn');
        return e;
      }
    },

    async changeRequestReturnTransactions({commit}, {id, data}) {
      commit('changeRequestReturnBtn');
      try {
        return await API.put(`/payments/refunds/${id}`, data).then((response) =>{
          commit('successRequestReturnBtn');
          return response;
        });
      } catch (e) {
        commit('successRequestReturnBtn');
        return e;
      }
    },

    async createDraftRequestReturn({commit}, data) {
      commit('changeRequestReturnBtn');
      try {
        return await API.post(`/payments/refunds/create-draft-refund`, data).then((response) =>{
          commit('successRequestReturnBtn');
          return response;
        });
      } catch (e) {
        commit('successRequestReturnBtn');
        return e;
      }
    },

    // async updateRequestReturn({commit}, {id, data}) {
    //   commit('changeRequestReturnBtn');
    //   try {
    //     return await API.put(`/requestReturn/${id}`, data).then((response) =>{
    //       commit('successRequestReturnBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successRequestReturnBtn');
    //     return e;
    //   }
    // },

    async deleteRequestReturn({commit}, id) {
      try {
        return await API.delete(`/payments/refunds/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successRequestReturnBtn');
        return e;
      }
    },

    async getExportRequestReturn({commit}, {filter , type}) {
      commit('setExportBtnLoadingStart')
      commit('setLoadingEnd')
      try {
        return await API.get(`/payments/refunds/export/${type}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

  }
}
