
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const MarketplaceRatesTable = () => import(/* webpackChunkName: "group-marketplace-rates" */ './components/MarketplaceRatesTable/MarketplaceRatesTable.vue')
const moduleKey = 'marketplace-reviews'


export const MarketplaceRatesRoutes = [
  {
    path: routerPaths.marketplaceRates,
    component: MarketplaceRatesTable,
    meta: {
      breadcrumbs: {
        key: [
          'marketplace',
          'products'
        ],
        items: {
          'marketplace': {
            name: 'menu_marketplace',
            link: routerPaths.marketplaceRates,
          },
          'products': {
            name: 'menu_marketplaceRates',
            link: routerPaths.marketplaceRates,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.UKRAINIAN_TREASURES_SHIPPING_RATE],

      moduleKey: moduleKey,
      pageTitle: 'menu_marketplaceRates'
    },
  },
]
