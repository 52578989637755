import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Files} from "../../../globalModels/Files";
import {
    PAYPAL_CASES_ARRAY_STATUSES,
    PAYPAL_CASES_STATUS_CLOSED,
    PAYPAL_CASES_STATUS_PAYPAL_REFUND,
    PAYPAL_CASES_STATUS_SYSTEM_REFUND
} from "../../../../staticData/staticVariables";

const _ = require('lodash');
// import {User} from "../../../../globalModels/User";

//rest/v1/payments/paypal?filter[PaymentTransactionId]=465456456 - GET (freeAmount)
export function PayPalCases({files = 5}) {

    this.checkValid = checkValid

    this.data = {
        // User: new User(),
        // AdminUser: new User(),
        transactionObject: null,
        id: '',
        createdAt: '',
        paymentTransactionId: '',
        paymentTransactionPaypalId: '',
        transactionUseEmail: '',
        transactionFreeAmount: '',
        transactionTotal: '',
        freeAmount: '',
        trackingNumber: '',
        status: null,
        complaintType: '',
        complaintReason: '',
        openDate: null,
        closeDate: null,
        comment: '',
        commentAdmin: '',
        complaintMessages: [],

        externalComplaintId: '',

        Files: new Files({
            maxSizeFiles: 5,
            maxCountFiles: files
        }),

        message: '',
        historyOfChanged: null,
    }

    this.validation = {
        id: false,
        paymentTransactionId: false,
        paymentTransactionPaypalId: false,
        transactionUserEmail: false,
        transactionFreeAmount: false,
        freeAmount: false,
        trackingNumber: false,
        status: false,
        complaintType: false,
        complaintReason: false,
        openDate: false,
        closeDate: false,
        comment: false,
        commentAdmin: false,

        externalComplaintId: false,

        complaintMessages: false,
        message: false,
    }

    this.validationTranslate = {
        id: '',
        paymentTransactionId: '',
        paymentTransactionPaypalId: '',
        transactionUserEmail: '',
        transactionFreeAmount: '',
        freeAmount: '',
        trackingNumber: '',
        status: '',
        complaintType: '',
        complaintReason: '',
        openDate: '',
        closeDate: '',
        comment: '',
        commentAdmin: '',

        externalComplaintId: '',

        complaintMessages: false,
        message: false,
    }

    this.validationTxt = {
        id: false,
        paymentTransactionId: false,
        paymentTransactionPaypalId: false,
        transactionUserEmail: false,
        transactionFreeAmount: false,
        freeAmount: false,
        trackingNumber: false,
        status: false,
        complaintType: false,
        complaintReason: false,
        openDate: false,
        closeDate: false,
        comment: false,
        commentAdmin: false,

        externalComplaintId: false,

        complaintMessages: false,
        message: false,
    }

    /**
     * Getters
     */
    this.getTransactionObject = () => {
        return this.data.transactionObject
    }
    this.getId = () => {
        return this.data.id
    }
    this.getCreatedAt = () => {
        return this.data.createdAt
    }
    this.getTrackingNumber = () => {
        return this.data.trackingNumber
    }
    this.getStatus = () => {
        return this.data.status
    }
    this.getPaymentTransactionId = () => {
        return this.data.paymentTransactionId
    }
    this.getPaymentTransactionPaypalId = () => {
        return this.data.paymentTransactionPaypalId
    }
    this.getTransactionUserEmail = () => {
        return this.data.transactionUserEmail
    }
    this.getTransactionFreeAmount = () => {
        return this.data.transactionFreeAmount
    }
    this.getFreeAmount = () => {
        return this.data.freeAmount
    }
    this.getcomplaintType = () => {
        return this.data.complaintType
    }
    this.getcomplaintReason = () => {
        return this.data.complaintReason
    }
    this.getOpenDate = () => {
        return this.data.openDate
    }
    this.getCloseDate = () => {
        return this.data.closeDate
    }
    this.getComment = () => {
        return this.data.comment
    }
    this.getCommentAdmin = () => {
        return this.data.commentAdmin
    }

    this.getExternalComplaintId = () => {
        return this.data.externalComplaintId
    }

    this.getComplaintMessages = () => {
        return this.data.complaintMessages
    }

    this.getMessage = () => {
        return this.data.message
    }

    /**
     * Setters
     */
    this.setId = (val) => {
        this.data.id = val
    }
    this.setTransactionObject = (val) => {
        this.data.transactionObject = val
    }
    this.setCreatedAt = (val) => {
        this.data.createdAt = val
    }
    this.setTrackingNumber = (val) => {
        this.data.trackingNumber = val
    }
    this.setStatus = (val) => {
        this.data.status = val
    }
    this.setPaymentTransactionId = (val) => {
        this.data.paymentTransactionId = val
    }
    this.setPaymentTransactionPaypalId = (val) => {
        this.data.paymentTransactionPaypalId = val
    }
    this.setFreeAmount = (val) => {
        this.data.freeAmount = val
    }
    this.setTransactionUserEmail = (val) => {
        this.data.transactionUserEmail = val
    }
    this.setTransactionFreeAmount = (val) => {
        this.data.transactionFreeAmount = val
    }
    this.setTransactionTotal = (val) => {
        this.data.transactionTotal = val
    }
    this.setcomplaintType = (val) => {
        this.data.complaintType = val
    }
    this.setcomplaintReason = (val) => {
        this.data.complaintReason = val
    }
    this.setOpenDate = (val) => {
        this.data.openDate = val
    }
    this.setCloseDate = (val) => {
        this.data.closeDate = val
    }
    this.setComment = (val) => {
        this.data.comment = val
    }
    this.setCommentAdmin = (val) => {
        this.data.commentAdmin = val
    }
    this.setExternalComplaintId = (val) => {
        this.data.externalComplaintId = val
    }

    this.setComplaintMessages = (val) => {
        this.data.complaintMessages = val
    }

    this.addComplaintMessages = (val) => {
        this.data.complaintMessages.push(val)
    }

    this.setMessage = (val) => {
        this.data.message = val
    }

    this.setHistoryOfChanged = (val) => {
        this.data.historyOfChanged = val
    }
}

/**
 * Global Setters
 */

PayPalCases.prototype.setItem = function (item, that) {

    this.setId(item?.id)
    this.setTransactionObject(item.payment_transaction)
    this.setPaymentTransactionId(item.payment_transaction_id)
    this.setPaymentTransactionPaypalId(item?.payment_transaction?.transaction_id)
    this.setTransactionUserEmail(item?.payment_transaction?.user?.email)
    this.setTransactionFreeAmount(item?.payment_transaction?.freeAmount)
    this.setTransactionTotal(item?.payment_transaction?.user_amount)
    this.setCreatedAt(item?.created_at)
    this.setTrackingNumber(item?.payment_transaction?.tracking_number)

    this.setcomplaintType(item.complaint_type)
    this.setcomplaintReason(item.complaint_reason)

    this.setStatus(_.find(PAYPAL_CASES_ARRAY_STATUSES, {name: item?.status}))
    this.setFreeAmount(item?.amount)
    this.setTrackingNumber(item?.payment_transaction?.tracking_number)
    this.setOpenDate(that.$moment(item?.opened_date).format('YYYY-MM-DD'))
    this.setCloseDate(item?.closed_date ? that.$moment(item?.closed_date).format('YYYY-MM-DD') : null)
    this.setComment(item?.user_comment || '')
    this.setCommentAdmin(item?.admin_comment || '')

    this.setExternalComplaintId(item?.external_complain_id)
    this.setComplaintMessages(item.dialogs)

    this.setHistoryOfChanged(item.activityUUID)

}

/**
 * Prepare Data
 */

PayPalCases.prototype.prepareSave = function () {
    let data = {
        'payment_transaction_id': this.getPaymentTransactionId(),
        'amount': this.getFreeAmount(),
        'complaint_type_id': this.getcomplaintType()?.id,
        'complaint_reason_id': this.getcomplaintReason()?.id,
        'opened_date': this.getOpenDate(),
        'admin_comment': this.getCommentAdmin(),
        'user_comment': this.getComment(),
        'status': this.getStatus()?.value,

        'external_complain_id': this.getExternalComplaintId(),
        'closed_date': this.getCloseDate()
    }

    return data
}

PayPalCases.prototype.prepareFilesData = function (id) {
    let readerArray = []
    this.data.Files.getFiles().map(item => {

        let reader = new FormData();
        reader.append("file", item.file);
        reader.append("complaint_id", id)
        readerArray.push(reader)
    })
    return readerArray
}

PayPalCases.prototype.getAddMessagePayPalCases = function () {
    let reader = new FormData()
    reader.append("message", this.getMessage())
    if (this.data.Files.getFiles().length > 0) {
        reader.append("file", this.data.Files.getFiles()[0].file);
    }

    // let readerArray = []
    // this.data.Files.getFiles().map(item => {
    //     let reader = new FormData();
    //     reader.append("file", item.file);
    //     readerArray.push(reader)
    // })

    return reader
}


PayPalCases.prototype.preparePartialSave = function () {
    return  {
        'user_comment': this.getComment(),
    }
}

/**
 * Validations
 */

PayPalCases.prototype.validateFreeAmount = function () {
    if (!this.validation.freeAmount) {
        if (parseFloat(this.getFreeAmount()) > parseFloat(this.getTransactionFreeAmount())) {
            this.validation.freeAmount = true
            this.validationTranslate.freeAmount = 'payPalCases_amountError'

            return false
        } else {
            this.validation.freeAmount = false
            this.validationTranslate.freeAmount = ''

            return true
        }
    }

    return true
}

PayPalCases.prototype.validateCloseDate = function () {
    let closedDateValidationStatuses = [
        PAYPAL_CASES_STATUS_CLOSED,
        PAYPAL_CASES_STATUS_SYSTEM_REFUND,
        PAYPAL_CASES_STATUS_PAYPAL_REFUND
    ]

    if (_.find(closedDateValidationStatuses, {name: this.getStatus()?.value}) && !this.getCloseDate()) {
        this.validationTranslate.closeDate = 'payPalCases_closeDateError'
        this.validation.closeDate = true

        return false
    }
    else {
        this.validationTranslate.closeDate = ''
        this.validation.closeDate = false

        return true
    }
}

PayPalCases.prototype.messageValidate = function() {

    let validationItems = {
        message: this.getMessage(),
    }

    let validationOptions = {
        message: {type: ['empty']},
    }


    return (this.checkValid(validationItems, validationOptions))
}

PayPalCases.prototype.firstValidationUser = function () {

    let validationItems = {
        paymentTransactionId: this.getPaymentTransactionId(),
        complaintType: this.getcomplaintType(),
        complaintReason: this.getcomplaintReason(),
        freeAmount: this.getFreeAmount(),
        openDate: this.getOpenDate(),
        status: this.getStatus(),
        externalComplaintId: this.getExternalComplaintId()
        // closeDate: this.getCloseDate()
    }

    let validationOptions = {
        paymentTransactionId: {type: ['empty']},
        complaintType: {type: ['empty']},
        complaintReason: {type: ['empty']},
        freeAmount: {type: ['numeric', 'not-zero', 'empty']},
        openDate: {type: ['empty']},
        status: {type: ['empty']},
        externalComplaintId: {type: ['empty']},
        // closeDate: {type: ['empty']},
    }

    return (this.checkValid(validationItems, validationOptions) && this.data.Files.fileSizeValidation())
}

PayPalCases.prototype.partialValidation = function () {

    let validationItems = {
        comment: this.getComment(),
    }

    let validationOptions = {
        comment: {type: ['empty']},
    }

    return (this.checkValid(validationItems, validationOptions))
}
