import {API} from "../../axios/axiosMainUrl";

export const etsyListings = {
  state: {
    etsyListings: [],
    etsyListingsItem: {},
    etsyListingsCommonList: [],
    etsyListingsBtn: false,
    etsyListingsLoading: true,
    etsyListingsLoadingCategory: true,
    nextEtsyListingsPage: false,
    etsyListingsForPage: 25,
    etsyListingsFilter: '',
  },

  mutations: {
    setEtsyListingsFilter: (state, payload) => {
      state.etsyListingsFilter = payload
    },

    setEtsyListings: (state, payload) => {
      if(state.nextEtsyListingsPage) {
        state.etsyListings = state.etsyListings.concat(payload.etsyListings)
      } else {
        state.etsyListings = payload.etsyListings
      }
    },

    setEtsyListingsCommonList: (state, payload) => {
      state.etsyListingsCommonList = payload.etsyListingsCommonList
    },

    setNextEtsyListingsPage: (state, payload) => {
      state.nextEtsyListingsPage = payload
    },

    setEtsyListingsItem: (state, payload) => {
      state.etsyListingsItem = payload.etsyListingsItem
    },

    setEtsyListingsLoadingStart: (state) => {
      state.etsyListingsLoading = true
    },

    setEtsyListingsLoadingEnd: (state) => {
      state.etsyListingsLoading = false
    },

    setEtsyListingsLoadingCategoryStart: (state) => {
      state.etsyListingsLoadingCategory = true
    },

    setEtsyListingsLoadingCategoryEnd: (state) => {
      state.etsyListingsLoadingCategory = false
    },

    changeEtsyListingsBtn: (state) => {
      state.etsyListingsBtn = true
    },
    successEtsyListingsBtn: (state) => {
      state.etsyListingsBtn = false
    },
  },

  getters: {
    getEtsyListingsFilter: state => {
      return state.etsyListingsFilter
    },

    getEtsyListingsForPage: state => {
      return state.etsyListingsForPage
    },

    getEtsyListings: state => {
      return state.etsyListings
    },

    getEtsyListingsLoading: state => {
      return state.etsyListingsLoading
    },

    getEtsyListingsLoadingCategory: state => {
      return state.etsyListingsLoadingCategory
    },

    getNextEtsyListingsPage: state => {
      return state.nextEtsyListingsPage
    },

    getEtsyListingsItem: state => {
      return state.etsyListingsItem
    },

    getEtsyListingsCommonList: state => {
      return state.etsyListingsCommonList
    },

    getEtsyListingsBtn: state => {
      return state.etsyListingsBtn
    },
  },

  actions: {

    async fetchEtsyListings({commit, getters}, filter = '') {
      if(!getters.getNextEtsyListingsPage)
        commit('setEtsyListingsLoadingStart')
      try {
        return await API.get(`/api-etsy-listing${filter}`).then((response) =>{
          commit('setEtsyListings',{etsyListings: response.data.data.data})
          commit('setEtsyListingsCommonList',{etsyListingsCommonList: response.data.data})
          commit('setEtsyListingsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setEtsyListingsLoadingEnd');
        return e;
      }
    },

    async getEtsyListingsFromAPI({commit, getters}, data) {
      if(!getters.getNextEtsyListingsPage)
        commit('setEtsyListingsLoadingStart')
      try {
        return await API.post(`/api-etsy-listing/get-listings`, data).then((response) =>{
          console.log(response)
          commit('setEtsyListings',{etsyListings: response.data.data.listings})
          commit('setEtsyListingsCommonList',{etsyListingsCommonList: response.data.data})
          commit('setEtsyListingsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setEtsyListingsLoadingEnd');
        return e;
      }
    },

    async getHeartysanEtsyListingsFromAPI({commit, getters}, data) {
      if(!getters.getNextEtsyListingsPage)
        commit('setEtsyListingsLoadingStart')
      try {
        return await API.get(`/heartysan/etsy/${data.shop_id}/hearty-listings`, data).then((response) =>{
          console.log(response)
          commit('setEtsyListings',{etsyListings: response.data.data.listings})
          commit('setEtsyListingsCommonList',{etsyListingsCommonList: response.data.data})
          commit('setEtsyListingsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setEtsyListingsLoadingEnd');
        return e;
      }
    },

    async getEtsyListingsItemFromAPI({commit}, data) {
      commit('setEtsyListingsLoadingStart');
      try {
        return await API.post(`/api-etsy-listing/get-listing`, data).then((response) =>{
          console.log(response)
          commit('setEtsyListingsItem', {etsyListingsItem: response.data.data})
          commit('setEtsyListingsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setEtsyListingsLoadingEnd');
        return e;
      }
    },

    async getEtsyListingsShopInfo({commit}, data) {
      commit('setEtsyListingsLoadingStart');
      try {
        return await API.post(`/api-etsy-listing/get-shop-info`, data).then((response) =>{
          // console.log(response)
          commit('setEtsyListingsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setEtsyListingsLoadingEnd');
        return e;
      }
    },

    async getEtsyListingsTaxonomyCategory({commit}, data) {
      commit('setEtsyListingsLoadingCategoryStart');
      try {
        return await API.post(`/api-etsy-listing/get-search-taxonomy`, data).then((response) =>{
          // console.log(response)
          commit('setEtsyListingsLoadingCategoryEnd');
          return response;
        });
      } catch (e) {
        commit('setEtsyListingsLoadingCategoryEnd');
        return e;
      }
    },

    async getEtsyListingsTaxonomy({commit}, id) {
      // commit('setEtsyListingsLoadingStart');
      try {
        return await API.get(`/api-etsy-listing/get-taxonomy${id}`).then((response) =>{
          // console.log(response)
          commit('setEtsyListingsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setEtsyListingsLoadingEnd');
        return e;
      }
    },
    
    async getEtsyListingsParams({commit}) {
      // commit('setEtsyListingsLoadingStart');
      try {
        return await API.get(`/api-etsy-listing/get-params`).then((response) =>{
          commit('setEtsyListingsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setEtsyListingsLoadingEnd');
        return e;
      }
    },


    async createEtsyListings({commit}, data) {
      commit('changeEtsyListingsBtn');
      try {
        return await API.post(`/api-etsy-listing/create-listing`, data).then((response) =>{
          commit('successEtsyListingsBtn');
          return response;
        });
      } catch (e) {
        commit('successEtsyListingsBtn');
        return e;
      }
    },

    async updateEtsyListings({commit}, data) {
      commit('changeEtsyListingsBtn');
      try {
        return await API.post(`/api-etsy-listing/update-listing`, data).then((response) =>{
          commit('successEtsyListingsBtn');
          return response;
        });
      } catch (e) {
        commit('successEtsyListingsBtn');
        return e;
      }
    },

    async deleteEtsyListings({commit}, data) {
         commit('changeEtsyListingsBtn');
      try {
        return await API.post(`/api-etsy-listing/delete-listing`, data).then((response) =>{
          console.log(response);
           commit('successEtsyListingsBtn');
          return response;
        });
      } catch (e) {
        commit('successEtsyListingsBtn');
        return e;
      }
    },

    async cloneEtsyListings({commit}, data) {
      commit('changeEtsyListingsBtn');
      try {
        return await API.post(`/api-etsy-listing/clone-listing`, data).then((response) =>{
          console.log(response);
           commit('successEtsyListingsBtn');
          return response;
        });
      } catch (e) {
        commit('successEtsyListingsBtn');
        return e;
      }
    },

  }
}
