
import {routerPaths} from '../../../router/routerPaths'
import {PERMISSIONS} from "@/staticData/permissionsStatic";
import permissions from "@/router/middleware/permissions";

const ProductsReportTable = () => import(/* webpackChunkName: "group-product-report" */ './components/ProductsReportTable/ProductsReportTable.vue')
const moduleKey = 'products-report'

export const ProductsReportRoutes = [
  {
    path: routerPaths.warehouseProductsReport,
    component: ProductsReportTable,
    meta: {
      breadcrumbs: {
        key: [
          'warehouse',
          'warehouseProductsReport'
        ],
        items: {
          'warehouse': {
            name: 'menu_reports',
            link: routerPaths.warehouseProductsReport,
          },
          'warehouseProductsReport': {
            name: 'menu_productsReport',
            link: routerPaths.warehouseProductsReport,
          },
        },
      },

      middleware: [permissions],
      permissions: routerPaths.warehouseProductsReport.indexOf('admin') > -1 ? [PERMISSIONS.ADMIN_REPORT_PRODUCTS_GENERAL] : [PERMISSIONS.REPORT_PRODUCTS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_productsReport'
    },
  },
]
