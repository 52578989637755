import {routerPaths} from '../../../router/routerPaths'
import permissions from "@/router/middleware/permissions";
import {PERMISSIONS} from "@/staticData/permissionsStatic";

const ReportCellsTable = () => import(/* webpackChunkName: "group-report-cells" */ './components/ReportCellsTable/ReportCellsTable.vue')
const ReportCellsByProduct = () => import(/* webpackChunkName: "group-report-cells-item" */ './components/ReportCellsByProduct/ReportCellsByProduct.vue')
const moduleKey = 'report-cells'

export const ReportCellsRoutes = [
  {
    path: routerPaths.financeReportCells,
    component: ReportCellsTable,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'financeReportCells'
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.financeReportCells,
          },
          'financeReportCells': {
            name: 'menu_financeReportCells',
            link: routerPaths.financeReportCells,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.REPORT_CELLS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_financeReportCells'
    },
  },
  {
    path: routerPaths.financeReportCellsItem,
    component: ReportCellsByProduct,
    meta: {
      breadcrumbs: {
        key: [
          'finance',
          'financeReportCells'
        ],
        items: {
          'finance': {
            name: 'menu_financeReportCells',
            link: routerPaths.financeReportCells,
          },
          'financeReportCells': {
            name: 'menu_financeReportCellsItem',
            link: routerPaths.financeReportCellsItem,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.REPORT_CELLS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_financeReportCellsItem'
    },
  },
]
