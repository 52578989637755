
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";
// import ExpressOrderShow from "./components/ExpressOrderShow/ExpressOrderShow";

// const ExpressOrdersTablePage = () => import(/* webpackChunkName: "group-express" */ './components/ExpressOrdersTablePage/ExpressOrdersTablePage.vue')
const ExpressOrderTable = () => import(/* webpackChunkName: "group-express" */ './components/ExpressOrderTable/ExpressOrderTable.vue')
const ExpressLabelsTable = () => import(/* webpackChunkName: "group-express-labels" */ './components/ExpressLabelsTable/ExpressLabelsTable.vue')
//const ExpressOrderCreationPage = () => import(/* webpackChunkName: "group-express" */ './components/ExpressOrderCreationPage/ExpressOrderCreationPage.vue')
const ExpressOrderCreation = () => import(/* webpackChunkName: "group-express" */ './components/ExpressOrderCreation/ExpressOrderCreation.vue')
const ExpressOrderEdit = () => import(/* webpackChunkName: "group-express" */ './components/ExpressOrderEdit/ExpressOrderEdit.vue')
const ExpressOrderShow = () => import(/* webpackChunkName: "group-express" */ './components/ExpressOrderShow/ExpressOrderShow.vue')
const moduleKey = 'express-orders'

export const ExpressRoutes = [
  {
    path: routerPaths.expressOrders,
    component: ExpressOrderTable,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'expressOrders'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'expressOrders': {
            name: 'breadcrumbs_ExpressOrders',
            link: routerPaths.expressOrders,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_EXPRESS_GENERAL],

      moduleKey: moduleKey,
      moduleKeyChapters: true,
      pageTitle: 'breadcrumbs_ExpressOrders'
    },
  },

  {
    path: routerPaths.expressLabels,
    component: ExpressLabelsTable,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'expressOrders'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'expressOrders': {
            name: 'breadcrumbs_ExpressOrders',
            link: routerPaths.expressOrders,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_EXPRESS_VIEW_BLOCKED_DOCS],

      moduleKey: moduleKey,
      moduleKeyChapters: true,
      pageTitle: 'breadcrumbs_ExpressOrdersLabels'
    },
  },

  {
    path: routerPaths.expressOrderCreationID,
    component: ExpressOrderCreation,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'expressOrders',
          'expressOrderCreation'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'expressOrders': {
            name: 'breadcrumbs_ExpressOrders',
            link: routerPaths.expressOrders,
          },
          'expressOrderCreation': {
            name: 'breadcrumbs_NewExpressOrder',
            link: routerPaths.expressOrderCreationID,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_EXPRESS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewExpressOrder'
    },
  },

  {
    path: routerPaths.expressOrderCreationIDTransaction,
    component: ExpressOrderCreation,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'expressOrders',
          'expressOrderCreation'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'expressOrders': {
            name: 'breadcrumbs_ExpressOrders',
            link: routerPaths.expressOrders,
          },
          'expressOrderCreation': {
            name: 'breadcrumbs_NewExpressOrder',
            link: routerPaths.expressOrderCreationID,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_EXPRESS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewExpressOrder'
    },
  },

  {
    path: routerPaths.expressOrderEditItem,
    component: ExpressOrderEdit,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'expressOrders',
          'expressOrderCreation'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'expressOrders': {
            name: 'breadcrumbs_ExpressOrders',
            link: routerPaths.expressOrders,
          },
          'expressOrderCreation': {
            name: 'breadcrumbs_EditExpressOrder',
            link: routerPaths.expressOrderCreationID,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_EXPRESS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_EditExpressOrder'
    },
  },

  {
    path: routerPaths.expressOrderShowItem,
    component: ExpressOrderShow,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'expressOrders',
          'expressOrderCreation'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'expressOrders': {
            name: 'breadcrumbs_ExpressOrders',
            link: routerPaths.expressOrders,
          },
          'expressOrderCreation': {
            name: 'breadcrumbs_ViewExpressOrder',
            link: routerPaths.expressOrderShowItem,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_EXPRESS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_ViewExpressOrder'
    },
  },
]
