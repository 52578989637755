import {API} from "../../axios/axiosMainUrl";

const prefix = '/orders/send-from'

export const orderSendFrom = {
  state: {
    orderSendFrom: [],
    orderSendFromItem: {},
    orderSendFromCommonList: [],
    orderSendFromBtn: false,
    orderSendFromLoading: true,
    nextOrderSendFromPage: false,
    orderSendFromForPage: 25,
    orderSendFromFilter: '',
  },

  mutations: {
    setOrderSendFromFilter: (state, payload) => {
      state.orderSendFromFilter = payload
    },

    setOrderSendFrom: (state, payload) => {
      if(state.nextOrderSendFromPage) {
        state.orderSendFrom = state.orderSendFrom.concat(payload.orderSendFrom)
      } else {
        state.orderSendFrom = payload.orderSendFrom
      }
    },

    setOrderSendFromCommonList: (state, payload) => {
      state.orderSendFromCommonList = payload.orderSendFromCommonList
    },

    setNextOrderSendFromPage: (state, payload) => {
      state.nextOrderSendFromPage = payload
    },

    setOrderSendFromItem: (state, payload) => {
      state.orderSendFromItem = payload.orderSendFromItem
    },

    setOrderSendFromLoadingStart: (state) => {
      state.orderSendFromLoading = true
    },

    setOrderSendFromLoadingEnd: (state) => {
      state.orderSendFromLoading = false
    },

    changeOrderSendFromBtn: (state) => {
      state.orderSendFromBtn = true
    },
    successOrderSendFromBtn: (state) => {
      state.orderSendFromBtn = false
    },
  },

  getters: {
    getOrderSendFromFilter: state => {
      return state.orderSendFromFilter
    },

    getOrderSendFromForPage: state => {
      return state.orderSendFromForPage
    },

    getOrderSendFrom: state => {
      return state.orderSendFrom
    },

    getOrderSendFromLoading: state => {
      return state.orderSendFromLoading
    },


    getNextOrderSendFromPage: state => {
      return state.nextOrderSendFromPage
    },

    getOrderSendFromItem: state => {
      return state.orderSendFromItem
    },

    getOrderSendFromCommonList: state => {
      return state.orderSendFromCommonList
    },

    getOrderSendFromBtn: state => {
      return state.orderSendFromBtn
    },
  },

  actions: {

    async fetchOrderSendFrom({commit, getters}, filter = '') {
      if(!getters.getNextOrderSendFromPage)
        commit('setOrderSendFromLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setOrderSendFrom',{orderSendFrom: response.data.data.data})
          commit('setOrderSendFromCommonList',{orderSendFromCommonList: response.data.data})
          commit('setOrderSendFromLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrderSendFromLoadingEnd');
        return e;
      }
    },

    async getOrderSendFrom({commit}, id) {
      // commit('setOrderSendFromLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setOrderSendFromItem', {orderSendFromItem: response.data.data})
          commit('setOrderSendFromLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrderSendFromLoadingEnd');
        return e;
      }
    },

    async createOrderSendFrom({commit}, data) {
      commit('changeOrderSendFromBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successOrderSendFromBtn');
          return response;
        });
      } catch (e) {
        commit('successOrderSendFromBtn');
        return e;
      }
    },

    async updateOrderSendFrom({commit}, {id, data}) {
      commit('changeOrderSendFromBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successOrderSendFromBtn');
          return response;
        });
      } catch (e) {
        commit('successOrderSendFromBtn');
        return e;
      }
    },

    async deleteOrderSendFrom({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successOrderSendFromBtn');
        return e;
      }
    },

  }
}
