
import {API} from '../../../axios/axiosMainUrl'

export const permissionsGroups = {
  state: {
    permissionsGroups: [],
    permissionsGroupItem: {},
    loadingPermissionsGroups: false,
    loadingPermissionsGroupItem: false,
    permissionsGroupBtn: false,
  },

  mutations: {
    setPermissionsGroups: (state, payload) => {
      state.permissionsGroups = payload.permissionsGroups
    },
    setPermissionsGroupItem: (state, payload) => {
      state.permissionsGroupItem = payload.permissionsGroupItem
    },

    setLoadingStart: (state) => {
      state.loadingPermissionsGroups = true
    },
    setLoadingPermissionsGroupItemStart: (state) => {
      state.loadingPermissionsGroups = true
    },

    setLoadingEnd: (state) => {
      state.loadingPermissionsGroups = false
    },
    setLoadingPermissionsGroupItemEnd: (state) => {
      state.loadingPermissionsGroups = false
    },


    changePermissionsGroup: (state) => {
      state.permissionsGroupBtn = true
    },
    successPermissionsGroup: (state) => {
      state.permissionsGroupBtn = false
    },
  },

  getters: {
    getPermissionsGroups: state => {
      return state.permissionsGroups
    },

    getPermissionsGroupItem: state => {
      return state.permissionsGroupItem
    },

    getPermissionsGroupBtn: state => {
      return state.permissionsGroupBtn
    }
  },

  actions: {
    async fetchPermissionsGroupItem({commit}, permissionsGroupId) {
      commit('setLoadingPermissionsGroupItemStart');
      try {
        return await API.get(`access-controls/permission-groups/${permissionsGroupId}`).then((response) =>{
          commit('setPermissionsGroupItem', {permissionsGroupItem: response.data.data});
          commit('setLoadingPermissionsGroupItemEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingPermissionsGroupItemEnd');
        return e;
      }
    },

    async fetchPermissionsGroups({commit}, filter = '') {
      commit('setLoadingStart');
      try {
        return await API.get(`access-controls/permission-groups${filter}`).then((response) =>{
          commit('setPermissionsGroups', {permissionsGroups: response.data.data.data});
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async updatePermissionsGroups({commit}, {id, data}) {
      commit('setLoadingStart');
      try {
        return await API.put(`access-controls/permission-groups/${id}`, data).then((response) =>{
          commit('setPermissionsGroups', {permissionsGroups: response.data.data});
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async createPermissionsGroups({commit}, data) {
      commit('setLoadingStart');
      try {
        return await API.post('access-controls/permission-groups', data).then((response) =>{
          // commit('setPermissionsGroups', {permissionsGroups: response.data.permissionsGroups});
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },


    async deletePermissionsGroups({commit}, id) {
      commit('setLoadingStart');
      try {
        return await API.delete(`access-controls/permission-groups/${id}`).then((response) =>{
          // commit('setPermissionsGroups', {permissionsGroups: response.data.permissionsGroups});
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

  }
}
