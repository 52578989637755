import {API} from "../../axios/axiosMainUrl";

const prefix = '/marketplace-shop-review'

export const marketplaceReviews = {
  state: {
    marketplaceReviews: [],
    marketplaceReviewsItem: {},
    marketplaceReviewsCommonList: [],
    marketplaceReviewsBtn: false,
    marketplaceReviewsLoading: true,
    nextMarketplaceReviewsPage: false,
    marketplaceReviewsForPage: 25,
    marketplaceReviewsFilter: '',
  },

  mutations: {
    setMarketplaceReviewsFilter: (state, payload) => {
      state.marketplaceReviewsFilter = payload
    },

    setMarketplaceReviews: (state, payload) => {
      if(state.nextMarketplaceReviewsPage) {
        state.marketplaceReviews = state.marketplaceReviews.concat(payload.marketplaceReviews)
      } else {
        state.marketplaceReviews = payload.marketplaceReviews
      }
    },

    setMarketplaceReviewsCommonList: (state, payload) => {
      state.marketplaceReviewsCommonList = payload.marketplaceReviewsCommonList
    },

    setNextMarketplaceReviewsPage: (state, payload) => {
      state.nextMarketplaceReviewsPage = payload
    },

    setMarketplaceReviewsItem: (state, payload) => {
      state.marketplaceReviewsItem = payload.marketplaceReviewsItem
    },

    setMarketplaceReviewsLoadingStart: (state) => {
      state.marketplaceReviewsLoading = true
    },

    setMarketplaceReviewsLoadingEnd: (state) => {
      state.marketplaceReviewsLoading = false
    },

    changeMarketplaceReviewsBtn: (state) => {
      state.marketplaceReviewsBtn = true
    },
    successMarketplaceReviewsBtn: (state) => {
      state.marketplaceReviewsBtn = false
    },
  },

  getters: {
    getMarketplaceReviewsFilter: state => {
      return state.marketplaceReviewsFilter
    },

    getMarketplaceReviewsForPage: state => {
      return state.marketplaceReviewsForPage
    },

    getMarketplaceReviews: state => {
      return state.marketplaceReviews
    },

    getMarketplaceReviewsLoading: state => {
      return state.marketplaceReviewsLoading
    },


    getNextMarketplaceReviewsPage: state => {
      return state.nextMarketplaceReviewsPage
    },

    getMarketplaceReviewsItem: state => {
      return state.marketplaceReviewsItem
    },

    getMarketplaceReviewsCommonList: state => {
      return state.marketplaceReviewsCommonList
    },

    getMarketplaceReviewsBtn: state => {
      return state.marketplaceReviewsBtn
    },
  },

  actions: {

    async fetchMarketplaceReviews({commit, getters}, filter = '') {
      if(!getters.getNextMarketplaceReviewsPage)
        commit('setMarketplaceReviewsLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setMarketplaceReviews',{marketplaceReviews: response.data.data})
          commit('setMarketplaceReviewsCommonList',{marketplaceReviewsCommonList: response.data})
          commit('setMarketplaceReviewsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setMarketplaceReviewsLoadingEnd');
        return e;
      }
    },

    async getMarketplaceReviews({commit}, id) {
      commit('setMarketplaceReviewsLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setMarketplaceReviewsItem', {marketplaceReviewsItem: response.data.data})
          commit('setMarketplaceReviewsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setMarketplaceReviewsLoadingEnd');
        return e;
      }
    },

    async updateMarketplaceReviewsStatus({commit}, {id, data}) {
      commit('changeMarketplaceReviewsBtn');
      try {
        return await API.post(`${prefix}/${id}/change-status`, data).then((response) =>{
          commit('successMarketplaceReviewsBtn');
          return response;
        });
      } catch (e) {
        commit('successMarketplaceReviewsBtn');
        return e;
      }
    },

    async deleteMarketplaceReviews({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successMarketplaceReviewsBtn');
        return e;
      }
    },

    async syncMarketplaceReviews({commit}, id) {
      commit('changeMarketplaceReviewsBtn');
      try {
        return await API.get(`${prefix}/${id}/sync-reviews`).then((response) =>{
          commit('successMarketplaceReviewsBtn');
          return response;
        });
      } catch (e) {
        commit('successMarketplaceReviewsBtn');
        return e;
      }
    },

  }
}
