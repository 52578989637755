import {API} from "../../axios/axiosMainUrl";

const apiOrderInternal = 'api-order-internal'

export const ukrPoshta = {
  state: {
    ukrPoshta: [],
    ukrPoshtaItem: {},
    ukrPoshtaCommonList: [],
    ukrPoshtaBtn: false,
    ukrPoshtaLoading: true,
    nextUkrPoshtaPage: false,
    ukrPoshtaForPage: 25,
    ukrPoshtaFilter: '',
  },

  mutations: {
    setUkrPoshtaFilter: (state, payload) => {
      state.ukrPoshtaFilter = payload
    },

    setUkrPoshta: (state, payload) => {
      if(state.nextUkrPoshtaPage) {
        state.ukrPoshta = state.ukrPoshta.concat(payload.ukrPoshta)
      } else {
        state.ukrPoshta = payload.ukrPoshta
      }
    },

    setUkrPoshtaCommonList: (state, payload) => {
      state.ukrPoshtaCommonList = payload.ukrPoshtaCommonList
    },

    setNextUkrPoshtaPage: (state, payload) => {
      state.nextUkrPoshtaPage = payload
    },

    setUkrPoshtaItem: (state, payload) => {
      state.ukrPoshtaItem = payload.ukrPoshtaItem
    },

    setUkrPoshtaLoadingStart: (state) => {
      state.ukrPoshtaLoading = true
    },

    setUkrPoshtaLoadingEnd: (state) => {
      state.ukrPoshtaLoading = false
    },

    changeUkrPoshtaBtn: (state) => {
      state.ukrPoshtaBtn = true
    },
    successUkrPoshtaBtn: (state) => {
      state.ukrPoshtaBtn = false
    },
  },

  getters: {
    getUkrPoshtaFilter: state => {
      return state.ukrPoshtaFilter
    },

    getUkrPoshtaForPage: state => {
      return state.ukrPoshtaForPage
    },

    getUkrPoshta: state => {
      return state.ukrPoshta
    },

    getUkrPoshtaLoading: state => {
      return state.ukrPoshtaLoading
    },


    getNextUkrPoshtaPage: state => {
      return state.nextUkrPoshtaPage
    },

    getUkrPoshtaItem: state => {
      return state.ukrPoshtaItem
    },

    getUkrPoshtaCommonList: state => {
      return state.ukrPoshtaCommonList
    },

    getUkrPoshtaBtn: state => {
      return state.ukrPoshtaBtn
    },
  },

  actions: {

    async fetchUkrPoshta({commit, getters}, filter = '') {
      if(!getters.getNextUkrPoshtaPage)
        commit('setUkrPoshtaLoadingStart')
      try {
        return await API.get(`/ukrPoshta${filter}`).then((response) =>{
          commit('setUkrPoshta',{ukrPoshta: response.data.data.data})
          commit('setUkrPoshtaCommonList',{ukrPoshtaCommonList: response.data.data})
          commit('setUkrPoshtaLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setUkrPoshtaLoadingEnd');
        return e;
      }
    },

    async getUkrPoshta({commit}, id) {
      commit('setUkrPoshtaLoadingStart');
      try {
        return await API.get(`/ukrPoshta/${id}`).then((response) =>{
          console.log(response)
          commit('setUkrPoshtaItem', {ukrPoshtaItem: response.data.data})
          commit('setUkrPoshtaLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setUkrPoshtaLoadingEnd');
        return e;
      }
    },


    // async createUkrPoshta({commit}, data) {
    //   commit('changeUkrPoshtaBtn');
    //   try {
    //     return await API.post(`${orderInternal}`, data).then((response) =>{
    //       commit('successUkrPoshtaBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successUkrPoshtaBtn');
    //     return e;
    //   }
    // },


    async updateUkrPoshta({commit}, {id, data}) {
      commit('changeUkrPoshtaBtn');
      try {
        return await API.put(`/ukrPoshta/${id}`, data).then((response) =>{
          commit('successUkrPoshtaBtn');
          return response;
        });
      } catch (e) {
        commit('successUkrPoshtaBtn');
        return e;
      }
    },

    async deleteUkrPoshta({commit}, id) {
      try {
        return await API.delete(`/ukrPoshta/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successUkrPoshtaBtn');
        return e;
      }
    },


    async getRateUkrPoshta({commit}, data) {
      commit('changeUkrPoshtaBtn');
      try {
        return await API.post(`${apiOrderInternal}/ukrposhta/get-rate`, data).then((response) =>{
          commit('successUkrPoshtaBtn');
          return response;
        });
      } catch (e) {
        commit('successUkrPoshtaBtn');
        return e;
      }
    },

    async createUkrPoshtaLabel({commit}, id) {
      commit('changeUkrPoshtaBtn');
      try {
        return await API.post(`${apiOrderInternal}/ukrposhta/${id}/create-label`).then((response) =>{
          commit('successUkrPoshtaBtn');
          return response;
        });
      } catch (e) {
        commit('successUkrPoshtaBtn');
        return e;
      }
    },

    async getUkrPoshtaLabel({commit}, id) {
      commit('changeUkrPoshtaBtn');
      try {
        return await API.post(`${apiOrderInternal}/ukrposhta/${id}/get-label`).then((response) =>{
          commit('successUkrPoshtaBtn');
          return response;
        });
      } catch (e) {
        commit('successUkrPoshtaBtn');
        return e;
      }
    },

  }
}
