import {API} from "../../axios/axiosMainUrl";

const prefix = '/orders/scan-form'

export const scanFormDP = {
  state: {
    scanFormDP: [],
    scanFormDPItem: {},
    scanFormDPCommonList: [],
    scanFormDPBtn: false,
    scanFormDPLoading: true,
    nextScanFormDPPage: false,
    scanFormDPForPage: 25,
    scanFormDPFilter: '',
  },

  mutations: {
    setScanFormDPFilter: (state, payload) => {
      state.scanFormDPFilter = payload
    },

    setScanFormDP: (state, payload) => {
      if(state.nextScanFormDPPage) {
        state.scanFormDP = state.scanFormDP.concat(payload.scanFormDP)
      } else {
        state.scanFormDP = payload.scanFormDP
      }
    },

    setScanFormDPCommonList: (state, payload) => {
      state.scanFormDPCommonList = payload.scanFormDPCommonList
    },

    setNextScanFormDPPage: (state, payload) => {
      state.nextScanFormDPPage = payload
    },

    setScanFormDPItem: (state, payload) => {
      state.scanFormDPItem = payload.scanFormDPItem
    },

    setScanFormDPLoadingStart: (state) => {
      state.scanFormDPLoading = true
    },

    setScanFormDPLoadingEnd: (state) => {
      state.scanFormDPLoading = false
    },

    changeScanFormDPBtn: (state) => {
      state.scanFormDPBtn = true
    },
    successScanFormDPBtn: (state) => {
      state.scanFormDPBtn = false
    },
  },

  getters: {
    getScanFormDPFilter: state => {
      return state.scanFormDPFilter
    },

    getScanFormDPForPage: state => {
      return state.scanFormDPForPage
    },

    getScanFormDP: state => {
      return state.scanFormDP
    },

    getScanFormDPLoading: state => {
      return state.scanFormDPLoading
    },


    getNextScanFormDPPage: state => {
      return state.nextScanFormDPPage
    },

    getScanFormDPItem: state => {
      return state.scanFormDPItem
    },

    getScanFormDPCommonList: state => {
      return state.scanFormDPCommonList
    },

    getScanFormDPBtn: state => {
      return state.scanFormDPBtn
    },
  },

  actions: {

    async fetchScanFormDP({commit, getters}, filter = '') {
      if(!getters.getNextScanFormDPPage)
        commit('setScanFormDPLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setScanFormDP',{scanFormDP: response.data.data.data})
          commit('setScanFormDPCommonList',{scanFormDPCommonList: {...response.data.data.meta, ...{
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev
              }}})
          commit('setScanFormDPLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setScanFormDPLoadingEnd');
        return e;
      }
    },

    async getScanFormDP({commit}, id) {
      commit('setScanFormDPLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setScanFormDPItem', {scanFormDPItem: response.data.data})
          commit('setScanFormDPLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setScanFormDPLoadingEnd');
        return e;
      }
    },

    async createScanFormDP({commit}, data) {
      commit('changeScanFormDPBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successScanFormDPBtn');
          return response;
        });
      } catch (e) {
        commit('successScanFormDPBtn');
        return e;
      }
    },

    async updateScanFormDP({commit}, {id, data}) {
      commit('changeScanFormDPBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successScanFormDPBtn');
          return response;
        });
      } catch (e) {
        commit('successScanFormDPBtn');
        return e;
      }
    },

    async deleteScanFormDP({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successScanFormDPBtn');
        return e;
      }
    },

  }
}
