import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const FastOrdersTable = () => import(/* webpackChunkName: "group-fast-orders-table" */ './components/FastOrdersTable/FastOrdersTable.vue')
const FastOrdersCreate = () => import(/* webpackChunkName: "group-fast-orders-create" */ './components/FastOrdersCreate/FastOrdersCreate.vue')
const FastOrdersEdit = () => import(/* webpackChunkName: "group-fast-orders-edit" */ './components/FastOrdersEdit/FastOrdersEdit.vue')
const moduleKey = 'fast-orders'

export const FastOrdersRoutes = [
  {
    path: routerPaths.reportsFastOrders,
    component: FastOrdersTable,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'fastOrders'
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.reportsFastOrders,
          },
          'fastOrders': {
            name: 'menu_fastOrders',
            link: routerPaths.reportsFastOrders,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.FAST_ORDER_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_fastOrders'
    },
  },

  {
    path: routerPaths.reportsFastOrdersCreate,
    component: FastOrdersCreate,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'fastOrders',
          'fastOrdersCreate'
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.reportsFastOrders,
          },
          'fastOrders': {
            name: 'menu_fastOrders',
            link: routerPaths.reportsFastOrders,
          },
          'fastOrdersCreate': {
            name: 'breadcrumbs_fastOrdersCreate',
            link: routerPaths.reportsFastOrdersCreate,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.FAST_ORDER_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_fastOrdersCreate'
    },
  },

  {
    path: routerPaths.reportsFastOrdersEditItem,
    component: FastOrdersEdit,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'fastOrders',
          'fastOrdersEdit'
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.reportsFastOrders,
          },
          'fastOrders': {
            name: 'menu_fastOrders',
            link: routerPaths.reportsFastOrders,
          },
          'fastOrdersEdit': {
            name: 'breadcrumbs_fastOrdersEdit',
            link: routerPaths.reportsFastOrdersEditItem,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.FAST_ORDER_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_fastOrdersEdit'
    },
  },
]
