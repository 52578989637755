<template>
  <div class="history-table__row">
    <div class="history-table__col">
      {{ item.created_at | moment("DD MMM, YYYY") }} {{$t('common_at.localization_value.value')}}
      {{ item.created_at | moment("HH:mm") }}
    </div>
    <div class="history-table__col">
      {{ item.user.user_full_name }}
    </div>
    <div class="history-table__col">
      <div
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_historyCreated','common_historyUpdated', 'common_historyDeleted'])"></div>
        <template v-if="item.event === 'created'">
          {{$t('common_historyCreated.localization_value.value')}}
        </template>
        <template v-if="item.event === 'updated'">
          {{$t('common_historyUpdated.localization_value.value')}}
        </template>
        <template v-if="item.event === 'deleted'">
          {{$t('common_historyDeleted.localization_value.value')}}
        </template>
      </div>
    </div>
    <div class="history-table__col">
      <!--                v-if="item.show !== undefined"-->
      <span
          class="history-table__arrow"
          @click="toggleShow"
          :class="{'show' : show}"
      ></span>
    </div>

    <div class="history-table__col history-table__col--100 p-0"
         v-if="item.history"
    >
      <div class="history-table__sub"
           v-if="show"
      >
        <div class="history-table__sub-head">
          <div class="history-table__sub-row">
            <div class="history-table__sub-col"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_historyFields',])"></div>
              {{$t('common_historyFields.localization_value.value')}}
            </div>
            <div class="history-table__sub-col"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_historyBeforeChanges',])"></div>
              {{$t('common_historyBeforeChanges.localization_value.value')}}
            </div>
            <div class="history-table__sub-col"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_historyChanges',])"></div>
              {{$t('common_historyChanges.localization_value.value')}}
            </div>
          </div>
        </div>

        <div class="history-table__sub-body">
          <ProformType
              v-if="type === 'proform'"
              :pevItem="pevItem"
              :item="item"
          />
          <ExpressType
              v-if="type === 'express'"
              :pevItem="pevItem"
              :item="item"
          />
          <ProductType
              v-if="type === 'product'"
              :pevItem="pevItem"
              :item="item"
          />
          <ConsolidationType
              v-if="type === 'consolidation'"
              :pevItem="pevItem"
              :item="item"
          />
          <FBAType
              v-if="type === 'fba'"
              :pevItem="pevItem"
              :item="item"
          />
          <InboundType
              v-if="type === 'inbound'"
              :pevItem="pevItem"
              :item="item"
          />
          <NewPaymentsType
              v-if="type === 'newPayments'"
              :pevItem="pevItem"
              :item="item"
          />
          <FillClaimType
              v-if="type === 'fillClaim'"
              :pevItem="pevItem"
              :item="item"
          />
          <OrderPriceType
              v-if="type === 'orderPrice'"
              :pevItem="pevItem"
              :item="item"
          />
          <OrderPriceTypeOther
              v-if="type === 'orderPriceOther'"
              :pevItem="pevItem"
              :item="item"
          />
          <ConsolidationUnionsType
              v-if="type === 'consolidationUnions'"
              :pevItem="pevItem"
              :item="item"
          />
          <RequestFundsType
              v-if="type === 'requestFunds'"
              :pevItem="pevItem"
              :item="item"
          />
          <PayPalCasesType
              v-if="type === 'payPalCases'"
              :pevItem="pevItem"
              :item="item"
          />
          <ShopType
              v-if="type === 'shops'"
              :pevItem="pevItem"
              :item="item"
          />
          <MainSettingsType
              v-if="type === 'mainSettings'"
              :pevItem="pevItem"
              :item="item"
          />
          <FBMType
              v-if="type === 'fbm'"
              :pevItem="pevItem"
              :item="item"
          />
          <SubUsersType
              v-if="type === 'subUsers'"
              :pevItem="pevItem"
              :item="item"
          />
          <UserSettingsType
              v-if="type === 'userSettings'"
              :pevItem="pevItem"
              :item="item"
          />
          <InvoicesType
              v-if="type === 'invoices'"
              :pevItem="pevItem"
              :item="item"
          />
          <OrderNotificationType
              v-if="type === 'orderNotification'"
              :pevItem="pevItem"
              :item="item"
          />
          <AccountsType
              v-if="type === 'accounts'"
              :pevItem="pevItem"
              :item="item"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProformType
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/components/HistoryOfChangesTable/HistoryOfChangesTableItem/ProformType/ProformType";
import ExpressType
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/components/HistoryOfChangesTable/HistoryOfChangesTableItem/ExpressType/ExpressType";
import ProductType
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/components/HistoryOfChangesTable/HistoryOfChangesTableItem/ProductType/ProductType";
import ConsolidationType
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/components/HistoryOfChangesTable/HistoryOfChangesTableItem/ConsolidationType/ConsolidationType";
import FBAType
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/components/HistoryOfChangesTable/HistoryOfChangesTableItem/FBAType/FBAType";
import InboundType
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/components/HistoryOfChangesTable/HistoryOfChangesTableItem/InboundType/InboundType";
import NewPaymentsType
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/components/HistoryOfChangesTable/HistoryOfChangesTableItem/NewPaymentsType/NewPaymentsType";
import FillClaimType
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/components/HistoryOfChangesTable/HistoryOfChangesTableItem/FillClaimType/FillClaimType";
import OrderPriceType
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/components/HistoryOfChangesTable/HistoryOfChangesTableItem/OrderPriceType/OrderPriceType";
import ConsolidationUnionsType
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/components/HistoryOfChangesTable/HistoryOfChangesTableItem/ConsolidationUnionsType/ConsolidationUnionsType";
import OrderPriceTypeOther
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/components/HistoryOfChangesTable/HistoryOfChangesTableItem/OrderPriceTypeOther/OrderPriceTypeOther";
import RequestFundsType
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/components/HistoryOfChangesTable/HistoryOfChangesTableItem/RequestFundsType/RequestFundsType";
import PayPalCasesType
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/components/HistoryOfChangesTable/HistoryOfChangesTableItem/PayPalCasesType/PayPalCasesType";
import ShopType
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/components/HistoryOfChangesTable/HistoryOfChangesTableItem/ShopType/ShopType";
import MainSettingsType
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/components/HistoryOfChangesTable/HistoryOfChangesTableItem/MainSettingsType/MainSettingsType";
import FBMType
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/components/HistoryOfChangesTable/HistoryOfChangesTableItem/FBMType/FBMType";
import SubUsersType
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/components/HistoryOfChangesTable/HistoryOfChangesTableItem/SubUsersType/SubUsersType";
import UserSettingsType
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/components/HistoryOfChangesTable/HistoryOfChangesTableItem/UserSettingsType/UserSettingsType";
import InvoicesType
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/components/HistoryOfChangesTable/HistoryOfChangesTableItem/InvoicesType/InvoicesType";
import OrderNotificationType
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/components/HistoryOfChangesTable/HistoryOfChangesTableItem/OrderNotificationType/OrderNotificationType";
import AccountsType
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/components/HistoryOfChangesTable/HistoryOfChangesTableItem/AccountsType/AccountsType";
export default {
  name: "HistoryOfChangesTableItem",

  components: {
    OrderNotificationType,
    UserSettingsType,
    SubUsersType,
    FBMType,
    MainSettingsType,
    ShopType,
    PayPalCasesType,
    RequestFundsType,
    OrderPriceTypeOther,
    ConsolidationUnionsType,
    OrderPriceType,
    FillClaimType,
    NewPaymentsType,
    InboundType,
    FBAType,
    ConsolidationType,
    ProductType,
    ExpressType,
    ProformType,
    InvoicesType,
    AccountsType,
  },

  props: {
    pevItem: Object,
    item: Object,
    type: String,
    index: Number,
    defaultShow: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      show: false,
    }
  },

  mounted() {
    this.show = this.defaultShow
  },

  methods: {
    toggleShow() {
      if((this.item.history.length === 0 || this.pevItem.history.length === 0) && !this.show) {
        let data = {
          currentId: this.item.id
        }

        if(this.pevItem) {
          data['aboveId'] = this.pevItem.id
        }

        this.$store.dispatch('getHistoryChangesHistory', data).then(response => {
          let respDataArray = this.getRespData(response)?.activities
          let currentItem = this._.find(respDataArray, {id: this.item.id})
          if(currentItem) {
            this.item.history = currentItem.history
          }
          if(this.pevItem) {
            let prevItem = this._.find(respDataArray, {id: this.pevItem.id})
            if(prevItem) {
              this.pevItem.history = prevItem.history
            }
          }
          this.show = !this.show
        })
      } else {
        this.show = !this.show
      }

    }
  }

}
</script>

<style lang="scss">
@import "../../../../../../../scss/mixins/mixins";
@import "../../../../../../../scss/colors";

.history-table__sub{
  width: 100%;
}

.history-table__sub-col {
  word-break: break-all;
  flex-wrap: wrap;

  &:nth-child(1) {
    width: 20%;
    max-width: 20%;

    @include for-720 {
      width: 30%;
      max-width: 30%;
    }
  }

  &:nth-child(2) {
    width: 40%;
    max-width: 40%;

    @include for-720 {
      width: 40%;
      max-width: 40%;
    }
  }

  &:nth-child(3) {
    width: 40%;
    max-width: 40%;

    @include for-720 {
      width: 40%;
      max-width: 40%;
    }
  }

}
</style>