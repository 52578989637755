import {API} from "../../axios/axiosMainUrl";

const prefix = '/error-logs/frontend'

export const errorLogs = {
  state: {
    errorLogs: [],
    errorLogsItem: {},
    errorLogsCommonList: [],
    errorLogsBtn: false,
    errorLogsLoading: true,
    nextErrorLogsPage: false,
    errorLogsForPage: 25,
    errorLogsFilter: '',
  },

  mutations: {
    setErrorLogsFilter: (state, payload) => {
      state.errorLogsFilter = payload
    },

    setErrorLogs: (state, payload) => {
      if(state.nextErrorLogsPage) {
        state.errorLogs = state.errorLogs.concat(payload.errorLogs)
      } else {
        state.errorLogs = payload.errorLogs
      }
    },

    setErrorLogsCommonList: (state, payload) => {
      state.errorLogsCommonList = payload.errorLogsCommonList
    },

    setNextErrorLogsPage: (state, payload) => {
      state.nextErrorLogsPage = payload
    },

    setErrorLogsItem: (state, payload) => {
      state.errorLogsItem = payload.errorLogsItem
    },

    setErrorLogsLoadingStart: (state) => {
      state.errorLogsLoading = true
    },

    setErrorLogsLoadingEnd: (state) => {
      state.errorLogsLoading = false
    },

    changeErrorLogsBtn: (state) => {
      state.errorLogsBtn = true
    },
    successErrorLogsBtn: (state) => {
      state.errorLogsBtn = false
    },
  },

  getters: {
    getErrorLogsFilter: state => {
      return state.errorLogsFilter
    },

    getErrorLogsForPage: state => {
      return state.errorLogsForPage
    },

    getErrorLogs: state => {
      return state.errorLogs
    },

    getErrorLogsLoading: state => {
      return state.errorLogsLoading
    },


    getNextErrorLogsPage: state => {
      return state.nextErrorLogsPage
    },

    getErrorLogsItem: state => {
      return state.errorLogsItem
    },

    getErrorLogsCommonList: state => {
      return state.errorLogsCommonList
    },

    getErrorLogsBtn: state => {
      return state.errorLogsBtn
    },
  },

  actions: {

    async fetchErrorLogs({commit, getters}, filter = '') {
      if(!getters.getNextErrorLogsPage)
        commit('setErrorLogsLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setErrorLogs',{errorLogs: response.data.data.data})
          commit('setErrorLogsCommonList',{errorLogsCommonList: response.data.data})
          commit('setErrorLogsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setErrorLogsLoadingEnd');
        return e;
      }
    },

    async getErrorLogs({commit}, id) {
      commit('setErrorLogsLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setErrorLogsItem', {errorLogsItem: response.data.data})
          commit('setErrorLogsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setErrorLogsLoadingEnd');
        return e;
      }
    },

    async createErrorLogs({commit}, data) {
      commit('changeErrorLogsBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successErrorLogsBtn');
          return response;
        });
      } catch (e) {
        commit('successErrorLogsBtn');
        return e;
      }
    },

    async updateErrorLogs({commit}, {id, data}) {
      commit('changeErrorLogsBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successErrorLogsBtn');
          return response;
        });
      } catch (e) {
        commit('successErrorLogsBtn');
        return e;
      }
    },

    async deleteErrorLogs({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successErrorLogsBtn');
        return e;
      }
    },

    async deleteCheckedErrorLogs({commit}, data) {
      try {
        return await API.post(`${prefix}/delete-selectively`, data).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successErrorLogsBtn');
        return e;
      }
    },

  }
}
