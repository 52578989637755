import {API} from "../../axios/axiosMainUrl";

const prefix = '/report/profit'

export const profitReport = {
  state: {
    profitReport: [],
    profitReportItem: {},
    profitReportCommonList: [],
    profitReportBtn: false,
    profitReportLoading: true,
    nextProfitReportPage: false,
    profitReportForPage: 25,
    profitReportFilter: '',
  },

  mutations: {
    setProfitReportFilter: (state, payload) => {
      state.profitReportFilter = payload
    },

    setProfitReport: (state, payload) => {
      state.profitReport = payload.profitReport
    },

    setProfitReportCommonList: (state, payload) => {
      state.profitReportCommonList = payload.profitReportCommonList
    },

    setNextProfitReportPage: (state, payload) => {
      state.nextProfitReportPage = payload
    },

    setProfitReportItem: (state, payload) => {
      state.profitReportItem = payload.profitReportItem
    },

    setProfitReportLoadingStart: (state) => {
      state.profitReportLoading = true
    },

    setProfitReportLoadingEnd: (state) => {
      state.profitReportLoading = false
    },

    changeProfitReportBtn: (state) => {
      state.profitReportBtn = true
    },
    successProfitReportBtn: (state) => {
      state.profitReportBtn = false
    },
  },

  getters: {
    getProfitReportFilter: state => {
      return state.profitReportFilter
    },

    getProfitReportForPage: state => {
      return state.profitReportForPage
    },

    getProfitReport: state => {
      return state.profitReport
    },

    getProfitReportLoading: state => {
      return state.profitReportLoading
    },


    getNextProfitReportPage: state => {
      return state.nextProfitReportPage
    },

    getProfitReportItem: state => {
      return state.profitReportItem
    },

    getProfitReportCommonList: state => {
      return state.profitReportCommonList
    },

    getProfitReportBtn: state => {
      return state.profitReportBtn
    },
  },

  actions: {

    async fetchProfitReport({commit, getters}, filter = '') {
      if(!getters.getNextProfitReportPage)
        commit('setProfitReportLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setProfitReport',{profitReport: response.data.data})
          // commit('setProfitReportCommonList',{profitReportCommonList: response.data.data})
          commit('setProfitReportLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setProfitReportLoadingEnd');
        return e;
      }
    },

    async getProfitReport({commit}, id) {
      commit('setProfitReportLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setProfitReportItem', {profitReportItem: response.data.data})
          commit('setProfitReportLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setProfitReportLoadingEnd');
        return e;
      }
    },

    async createProfitReport({commit}, data) {
      commit('changeProfitReportBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successProfitReportBtn');
          return response;
        });
      } catch (e) {
        commit('successProfitReportBtn');
        return e;
      }
    },

    async updateProfitReport({commit}, {id, data}) {
      commit('changeProfitReportBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successProfitReportBtn');
          return response;
        });
      } catch (e) {
        commit('successProfitReportBtn');
        return e;
      }
    },

    async deleteProfitReport({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successProfitReportBtn');
        return e;
      }
    },

  }
}
