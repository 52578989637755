import {API} from "../../axios/axiosMainUrl";

const prefix = '/report/admin-processed-order-fbm'

export const processFBMReport = {
  state: {
    processFBMReport: [],
    processFBMReportItem: {},
    processFBMReportCommonList: [],
    processFBMReportBtn: false,
    processFBMReportLoading: true,
    nextProcessFBMReportPage: false,
    processFBMReportForPage: 25,
    processFBMReportFilter: '',
  },

  mutations: {
    setProcessFBMReportFilter: (state, payload) => {
      state.processFBMReportFilter = payload
    },

    setProcessFBMReport: (state, payload) => {
      if(state.nextProcessFBMReportPage) {
        state.processFBMReport = state.processFBMReport.concat(payload.processFBMReport)
      } else {
        state.processFBMReport = payload.processFBMReport
      }
    },

    setProcessFBMReportCommonList: (state, payload) => {
      state.processFBMReportCommonList = payload.processFBMReportCommonList
    },

    setNextProcessFBMReportPage: (state, payload) => {
      state.nextProcessFBMReportPage = payload
    },

    setProcessFBMReportItem: (state, payload) => {
      state.processFBMReportItem = payload.processFBMReportItem
    },

    setProcessFBMReportLoadingStart: (state) => {
      state.processFBMReportLoading = true
    },

    setProcessFBMReportLoadingEnd: (state) => {
      state.processFBMReportLoading = false
    },

    changeProcessFBMReportBtn: (state) => {
      state.processFBMReportBtn = true
    },
    successProcessFBMReportBtn: (state) => {
      state.processFBMReportBtn = false
    },
  },

  getters: {
    getProcessFBMReportFilter: state => {
      return state.processFBMReportFilter
    },

    getProcessFBMReportForPage: state => {
      return state.processFBMReportForPage
    },

    getProcessFBMReport: state => {
      return state.processFBMReport
    },

    getProcessFBMReportLoading: state => {
      return state.processFBMReportLoading
    },


    getNextProcessFBMReportPage: state => {
      return state.nextProcessFBMReportPage
    },

    getProcessFBMReportItem: state => {
      return state.processFBMReportItem
    },

    getProcessFBMReportCommonList: state => {
      return state.processFBMReportCommonList
    },

    getProcessFBMReportBtn: state => {
      return state.processFBMReportBtn
    },
  },

  actions: {

    async fetchProcessFBMReport({commit, getters}, filter = '') {
      if(!getters.getNextProcessFBMReportPage)
        commit('setProcessFBMReportLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setProcessFBMReport',{processFBMReport: response.data.data.data})
          commit('setProcessFBMReportCommonList',{processFBMReportCommonList: response.data.data})
          commit('setProcessFBMReportLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setProcessFBMReportLoadingEnd');
        return e;
      }
    },

  }
}
