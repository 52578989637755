import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const InsuranceSkladUsaTable = () => import(/* webpackChunkName: "insurance-skladusa" */ './components/InsuranceSkladUsaTable/InsuranceSkladUsaTable.vue')
const InsuranceSkladUsaCreation = () => import(/* webpackChunkName: "insurance-skladusa-create" */ './components/InsuranceSkladUsaCreation/InsuranceSkladUsaCreation.vue')
const InsuranceSkladUsaEditing = () => import(/* webpackChunkName: "insurance-skladusa-edit" */ './components/InsuranceSkladUsaEditing/InsuranceSkladUsaEditing.vue')
const InsuranceSkladUsaShow = () => import(/* webpackChunkName: "insurance-skladusa-show" */ './components/InsuranceSkladUsaShow/InsuranceSkladUsaShow.vue')
const moduleKey = 'insurance-skladusa'


export const InsuranceSkladUsaRoutes = [
  {
    path: routerPaths.mainSettingsInsuranceSkladUsa,
    component: InsuranceSkladUsaTable,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'mainSettingsInsuranceSkladUsa'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsInsuranceSkladUsa,
          },
          'mainSettingsInsuranceSkladUsa': {
            name: 'menu_InsuranceSkladUsa',
            link: routerPaths.mainSettingsInsuranceSkladUsa,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.INSURANCE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_InsuranceSkladUsa'
    },
  },

  {
    path: routerPaths.mainSettingsInsuranceSkladUsaCreate,
    component: InsuranceSkladUsaCreation,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettingsInsuranceSkladUsa',
          'mainSettingsInsuranceSkladUsaCreation'
        ],
        items: {
          'mainSettingsInsuranceSkladUsa': {
            name: 'menu_InsuranceSkladUsa',
            link: routerPaths.mainSettingsInsuranceSkladUsa,
          },
          'mainSettingsInsuranceSkladUsaCreation': {
            name: 'menu_createInsuranceSkladUsa',
            link: routerPaths.mainSettingsInsuranceSkladUsaCreate,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.INSURANCE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_InsuranceSkladUsa'
    },
  },

  {
    path: routerPaths.mainSettingsInsuranceSkladUsaEdit,
    component: InsuranceSkladUsaEditing,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettingsInsuranceSkladUsa',
          'mainSettingsInsuranceSkladUsaEditing'
        ],
        items: {
          'mainSettingsInsuranceSkladUsa': {
            name: 'menu_InsuranceSkladUsa',
            link: routerPaths.mainSettingsInsuranceSkladUsa,
          },
          'mainSettingsInsuranceSkladUsaEditing': {
            name: 'menu_editInsuranceSkladUsa',
            link: routerPaths.mainSettingsInsuranceSkladUsaEdit,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.INSURANCE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_InsuranceSkladUsa'
    },
  },

  {
    path: routerPaths.mainSettingsInsuranceSkladUsaShow,
    component: InsuranceSkladUsaShow,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettingsInsuranceSkladUsa',
          'mainSettingsInsuranceSkladUsaShow'
        ],
        items: {
          'mainSettingsInsuranceSkladUsa': {
            name: 'menu_InsuranceSkladUsa',
            link: routerPaths.mainSettingsInsuranceSkladUsa,
          },
          'mainSettingsInsuranceSkladUsaShow': {
            name: 'menu_showInsuranceSkladUsa',
            link: routerPaths.mainSettingsInsuranceSkladUsa,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.INSURANCE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_InsuranceSkladUsa'
    },
  },
]
