import {API} from "../../axios/axiosMainUrl";

const prefix = '/express-orders/fast-order/problem'

export const expressProblem = {
  state: {
    expressProblem: [],
    expressProblemItem: {},
    expressProblemCommonList: [],
    expressProblemBtn: false,
    expressProblemLoading: true,
    nextExpressProblemPage: false,
    expressProblemForPage: 25,
    expressProblemFilter: '',
  },

  mutations: {
    setExpressProblemFilter: (state, payload) => {
      state.expressProblemFilter = payload
    },

    setExpressProblem: (state, payload) => {
      if(state.nextExpressProblemPage) {
        state.expressProblem = state.expressProblem.concat(payload.expressProblem)
      } else {
        state.expressProblem = payload.expressProblem
      }
    },

    setExpressProblemCommonList: (state, payload) => {
      state.expressProblemCommonList = payload.expressProblemCommonList
    },

    setNextExpressProblemPage: (state, payload) => {
      state.nextExpressProblemPage = payload
    },

    setExpressProblemItem: (state, payload) => {
      state.expressProblemItem = payload.expressProblemItem
    },

    setExpressProblemLoadingStart: (state) => {
      state.expressProblemLoading = true
    },

    setExpressProblemLoadingEnd: (state) => {
      state.expressProblemLoading = false
    },

    changeExpressProblemBtn: (state) => {
      state.expressProblemBtn = true
    },
    successExpressProblemBtn: (state) => {
      state.expressProblemBtn = false
    },
  },

  getters: {
    getExpressProblemFilter: state => {
      return state.expressProblemFilter
    },

    getExpressProblemForPage: state => {
      return state.expressProblemForPage
    },

    getExpressProblem: state => {
      return state.expressProblem
    },

    getExpressProblemLoading: state => {
      return state.expressProblemLoading
    },


    getNextExpressProblemPage: state => {
      return state.nextExpressProblemPage
    },

    getExpressProblemItem: state => {
      return state.expressProblemItem
    },

    getExpressProblemCommonList: state => {
      return state.expressProblemCommonList
    },

    getExpressProblemBtn: state => {
      return state.expressProblemBtn
    },
  },

  actions: {

    async fetchExpressProblem({commit, getters}, filter = '') {
      if(!getters.getNextExpressProblemPage)
        commit('setExpressProblemLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setExpressProblem',{expressProblem: response.data.data.data})
          // commit('setExpressProblemCommonList',{expressProblemCommonList: response.data.data})
          commit('setExpressProblemCommonList',{expressProblemCommonList: {...response.data.data.meta, ...{
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev
              }}})
          commit('setExpressProblemLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setExpressProblemLoadingEnd');
        return e;
      }
    },

    async getExpressProblem({commit}, id) {
      commit('setExpressProblemLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setExpressProblemItem', {expressProblemItem: response.data.data})
          commit('setExpressProblemLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setExpressProblemLoadingEnd');
        return e;
      }
    },

    async createExpressProblem({commit}, data) {
      commit('changeExpressProblemBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successExpressProblemBtn');
          return response;
        });
      } catch (e) {
        commit('successExpressProblemBtn');
        return e;
      }
    },

    async updateExpressProblem({commit}, {id, data}) {
      commit('changeExpressProblemBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successExpressProblemBtn');
          return response;
        });
      } catch (e) {
        commit('successExpressProblemBtn');
        return e;
      }
    },

    async deleteExpressProblem({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successExpressProblemBtn');
        return e;
      }
    },

    async competeByAdminExpressProblem({commit}, id) {
      commit('changeExpressProblemBtn');
      try {
        return await API.patch(`${prefix}/${id}/complete-order`).then((response) =>{
          commit('successExpressProblemBtn');
          return response;
        });
      } catch (e) {
        commit('successExpressProblemBtn');
        return e;
      }
    },

  }
}
