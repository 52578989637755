
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";


const UnidentifiedPaymentsTable = () => import(/* webpackChunkName: "group-unidentified-payments" */ './components/UnidentifiedPaymentsTable/UnidentifiedPaymentsTable.vue')
const moduleKey = 'unidentified-payments'

export const UnidentifiedPaymentsRoutes = [
  {
    path: routerPaths.financeUnidentifiedPayments,
    component: UnidentifiedPaymentsTable,
    meta: {
      breadcrumbs: {
        key: [
          'finance',
          'financeUnidentifiedPayments'
        ],
        items: {
          'finance': {
            name: 'menu_finance',
            link: routerPaths.dashboard,
          },
          'financeUnidentifiedPayments': {
            name: 'menu_unidentifiedPayments',
            link: routerPaths.financeUnidentifiedPayments,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.UNDEFINED_PAYMENT_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_unidentifiedPayments'
    },
  },
]
