<template>
<!--  <div class="default-select"-->
<!--       :class="{'default-select&#45;&#45;active' : selected && selected.length > 0}"-->
<!--  >  -->

    <div class="default-select"
       :class="{'default-select--active' : vSelectModel }"
  >
        <!--append-to-body-->
    <v-select
        :calculate-position="withPopper"
        :options="options"
        @input="onChange"
        v-model="vSelectModel"
        :label="optionsLabel"
        :filter-by="userFilter"
        @click.native="clickSelect"
        :selectable="option => !option.hasOwnProperty('disabled')"
    >
      <template #header>
        <div class="v-select__label">{{ label }}</div>
      </template>

      <template slot="option" slot-scope="option" v-if="otherValue !== ''">
        <div class="d-flex flex-column" v-if="otherValue === 'usersSelect'">
          {{ option.email }}
          <br>{{option.full_name}}
        </div>
      </template>

      <template #selected-option="option" v-if="otherValue !== ''">
        <template v-if="otherValue === 'usersSelect'">
          {{option.email}}
          <br>{{option.full_name}}
        </template>
      </template>

      <template slot="no-options" @click="$refs.select.open = false">
        <div v-if="defaultNoOptions">
          {{$t(`${defaultNoOptions}.localization_value.value`)}}
        </div>
        <div v-else>
          {{$t('common_SorryMatchingOptions.localization_value.value')}}
        </div>
      </template>
    </v-select>

    <span class="default-select__error error-field" v-if="error && errorTxt">{{errorTxt}}</span>
    <span class="default-select__caption" v-if="caption">{{caption}}</span>
  </div>
</template>

<script>
  import vSelect from 'vue-select'
  import { createPopper } from '@popperjs/core';


  export default {
    name: "DefaultSelectInside",
    components: {
      vSelect
    },

    props: [
      'options',
      'selected',
      'error',
      'errorTxt',
      'type',
      'label',
      'caption',
      'optionsLabel',
      'defaultNoOptions',
      'otherValue',
    ],

    data(){
      return{
        vSelectModel: '',
        placement: 'top',
        click: false,

        userFilter: (option, label, search) => {
          if(search.length < 3) return
          if(this.otherValue === 'usersSelect'){
            let temp = search.toLowerCase();
            return option.email.toLowerCase().indexOf(temp) > -1 || option.full_name.toLowerCase().indexOf(temp) > -1
          }
          return (label || '').toLowerCase().indexOf(search.toLowerCase()) > -1
        }
      }
    },

    mounted() {
      this.vSelectModel = this.selected;

      document.addEventListener('click', () => {
        if( this.click && event.target.closest('.default-select') === null){
          this.click = false
          this.$emit('onBlur', false);
        }
      })

    },

    watch: {
      selected: function(newVal) {
        this.vSelectModel = newVal;
      }
    },

    methods: {
      clickSelect() {
        this.click = true
        this.$emit('onBlur', true);
      },
      onChange(){
        let value = this.vSelectModel !== null ? this.vSelectModel : ''
        this.$emit('change', value);
      },

      withPopper (dropdownList, component, {width}) {
        /**
         * We need to explicitly define the dropdown width since
         * it is usually inherited from the parent with CSS.
         */
        dropdownList.style.width = width;

        /**
         * Here we position the dropdownList relative to the $refs.toggle Element.
         *
         * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
         * the dropdownList overlap by 1 pixel.
         *
         * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
         * wrapper so that we can set some styles for when the dropdown is placed
         * above.
         */
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.placement,
          modifiers: [
            {
              name: 'offset', options: {
                offset: [0, -1]
              }
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn ({state}) {
                component.$el.classList.toggle('drop-up', state.placement === 'top')
              },
            }]
        });

        /**
         * To prevent memory leaks Popper needs to be destroyed.
         * If you return function, it will be called just before dropdown is removed from DOM.
         */
        return () => popper.destroy();
      }
    }
  }
</script>

<style lang="scss">
  @import "../../../../scss/colors";
  @import "../../../../scss/mixins/mixins";
  @import "vue-select/src/scss/vue-select.scss";

  .default-select{
    position: relative;

    &.disabled{
      .vs__selected{
        color: #00000052;
      }
    }

    &.white .vs__dropdown-toggle{
      background: white;
    }

    &--active {
      .v-select__label {
        top: -5px;
        background: white;
        background: linear-gradient(0deg, #F8F4EE 0%, #FFFFFF 105.56%);
        padding: 0 2px;
        font-size: 10px;
        line-height: 12px;
      }

      .v-select .vs__dropdown-toggle{
        border-color: #CD9E64;
      }
    }


    &.ui-no-valid .v-select .vs__dropdown-toggle{
      border: 2px solid $orange;
    }


    &__error{
      position: absolute;
      bottom: -18px;
      right: 0;
      font-size: 11px;
      line-height: 13px;
      color: $orange;
    }

    &__caption{
      width: 100%;
      text-align: right;
      font-size: 11px;
      line-height: 13px;
      color: $borderBrown;
      display: flex;
      justify-content: flex-end;
      margin-top: 15px;
      margin-bottom: -10px;
    }


    &.black {
      .vs--open .v-select__label {
        background: #5f5e5c;
      }

      .vs__search,
      .vs__selected{
        color: white;
      }

      .vs__dropdown-toggle{
        background: #393838;
        border: 1px solid #5f5e5c;
      }

      .vs__dropdown-menu {
        border-radius: 0;
        border: 0;
        box-shadow: 0px 4px 16px #000000;
        padding-top: 0;
        padding-bottom: 0;
        top: auto;
        bottom: 45px;
      }

      .vs__dropdown-menu .vs__dropdown-option{
        background: #232323;
        color: #f8f4ee;
        border-bottom: 1px solid #393838;
        border-radius: 0;
      }
    }
  }





    .v-select__label{
      position: absolute;
      left: 16px;
      top: 13px;
      font-size: 14px;
      line-height: 16px;
      color: #BBAD9C;
      transition: 0.15s all;
    }

    .vs--open .v-select__label{
      top: -5px;
      background: white;
      background: linear-gradient(0deg, #F8F4EE 0%, #FFFFFF 105.56%);
      padding: 0 2px;
      font-size: 10px;
      line-height: 12px;
    }

    .vs__dropdown-toggle{
      padding-left: 16px;
      min-height: 42px;
      background: #F8F4EE;
      border: 1px solid #BBAD9C;
      box-sizing: border-box;
      border-radius: 5px;
    }

    .vs--open .vs__dropdown-toggle{
      border-color: #CD9E64;
    }

    .vs__selected{
      padding: 0;
      margin: 0;
      top: 8px;


      font-size: 14px;

      @include for-1120{
        font-size: 16px;
      }
    }

    .vs__search, .vs__search:focus{
      padding: 0;
    }

    .vs__selected-options{
      padding: 0;
      top: 2px;
      /*display: block;*/
      /*overflow: hidden;*/
    }



    .vs__dropdown-menu{
      /*top: 0;*/
      border-radius: 5px 5px 0 0;
      border: 1px solid $mainBg;
      box-shadow: 0px 4px 7px $mainBg;
      padding-top: 0;
      padding-bottom: 0;

      /* width */
      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
        border: 1px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
        margin-right: 8px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $borderBrown;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $borderBrown;
        opacity: .5;
      }

      .vs__no-options{
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include for-550 {
          font-size: 13px;
        }
      }



      .vs__actions svg path{
        fill: #8F7A61;
      }

      .vs__dropdown-option{
        min-height: 42px;
        display: flex;
        align-items: center;
        color: $black;
        white-space: normal;
        border-bottom: 1px solid $mainBg;

        &:last-child{
          border-bottom: 0;
        }

        &--highlight{
          background: $borderBrownOpacity02;
          color: $black;
        }
      }

    }

    .v-select.drop-up.vs--open .vs__dropdown-toggle {
      border-radius: 0 0 4px 4px;
      border-top-color: transparent;
      border-bottom-color: rgba(60, 60, 60, 0.26);
    }

    [data-popper-placement='top'] {
      border-radius: 4px 4px 0 0;
      border-top-style: solid;
      border-bottom-style: none;
      box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15)
    }



  .dark-select-scoped{
    .vs--open .v-select__label {
      background: #5f5e5c;
    }

    .vs__search,
    .vs__selected{
      color: white;
    }

    .vs__dropdown-toggle{
      background: #393838;
      border: 1px solid #5f5e5c;
    }

    .vs__dropdown-menu {
      border-radius: 0;
      border: 0;
      box-shadow: 0px 4px 16px #000000;
      padding-top: 0;
      padding-bottom: 0;
      top: auto;
      bottom: 45px;
    }

    .vs__dropdown-menu .vs__dropdown-option{
      background: #232323;
      color: #f8f4ee;
      border-bottom: 1px solid #393838;
      border-radius: 0;
    }
  }



  /*.dark-select-scoped{*/
    /*.vs--open .v-select__label {*/
      /*background: #5f5e5c!important;*/
    /*}*/

    /*.vs__search,*/
    /*.vs__selected{*/
      /*color: white!important;*/
    /*}*/

    /*.vs__dropdown-toggle{*/
      /*background: #393838!important;*/
      /*border: 1px solid #5f5e5c!important;*/
    /*}*/
  /*}*/


  body.black-select > ul[role="listbox"]{

    border-radius: 0!important;
    border: 0!important;
    box-shadow: 0px 4px 16px #000000!important;
    padding-top: 0!important;
    padding-bottom: 0!important;


    .vs__dropdown-option{
      background: #232323!important;
      color: #f8f4ee!important;
      border-bottom: 1px solid #393838!important;
      border-radius: 0!important;
      left: 16px;
      top: 13px;
      /*font-size: 14px;*/
      /*line-height: 16px;*/
      max-width: 100%;
      overflow: hidden;
      /*white-space: normal;*/
    }
  }
</style>
