
import {routerPaths} from '../../../router/routerPaths'

const SearchTable = () => import(/* webpackChunkName: "group-request-return" */ './components/SearchTable/SearchTable.vue')
const moduleKey = 'search'


export const SearchRoutes = [
  {
    path: routerPaths.searchTrack,
    component: SearchTable,
    meta: {
      breadcrumbs: {
        key: [
          'search'
        ],
        items: {
          'search': {
            name: 'search_title',
            link: routerPaths.searchTrack,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'search_title'
    },
  },


]
