
const onlyLetters = {
  bind(el, binding) {

    if(!binding.value) return true

    el.onkeydown = function(e) {
      let keyCode = e.which
      let key = e.key

      if ( ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105)) && !hasSpecialSymbol(key) ) {
        e.preventDefault()
        return false
      }
    }

    function hasSpecialSymbol(value) {
      return value.search(/(?=.*[!@#$%^&*])/g,"") === 0
    }
  }
}

export default onlyLetters