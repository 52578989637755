
import {routerPaths} from '../../../router/routerPaths'
import {REQUEST_FUNDS_TYPES} from "../../../staticData/staticVariables";
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const RequestFundsTable = () => import(/* webpackChunkName: "group-request-funds" */ './components/RequestFundsTable/RequestFundsTable.vue')
// const RequestFundsIndividual = () => import(/* webpackChunkName: "group-request-funds" */ './components/RequestFundsIndividual/RequestFundsIndividual.vue')
const RequestFundsCreation = () => import(/* webpackChunkName: "group-request-funds" */ './components/RequestFundsCreation/RequestFundsCreation.vue')
const RequestFundsEditing = () => import(/* webpackChunkName: "group-request-funds" */ './components/RequestFundsEditing/RequestFundsEditing.vue')
const RequestFundsBusiness = () => import(/* webpackChunkName: "group-request-funds" */ './components/RequestFundsBusiness/RequestFundsBusiness.vue')
const moduleKey = 'request-funds'


export const RequestFundsRoutes = [
  {
    path: routerPaths.financeRequestFunds,
    component: RequestFundsTable,
    meta: {
      breadcrumbs: {
        key: [
          'finance',
          'financeRequestFunds'
        ],
        items: {
          'finance': {
            name: 'menu_finance',
            link: routerPaths.financeRequestFunds,
          },
          'financeRequestFunds': {
            name: 'menu_requestFunds',
            link: routerPaths.financeRequestFunds,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.BALANCE_OUTPUT_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_requestFunds'
    },
  },


  {
    path: routerPaths.financeRequestFundsIndividual,
    component: RequestFundsCreation,
    meta: {
      breadcrumbs: {
        key: [
          'finance',
          'financeRequestFunds',
          'financeRequestFundsIndividual',
        ],
        items: {
          'finance': {
            name: 'menu_finance',
            link: routerPaths.financeRequestFunds,
          },
          'financeRequestFunds': {
            name: 'menu_requestFunds',
            link: routerPaths.financeRequestFunds,
          },
          'financeRequestFundsIndividual': {
            name: 'breadcrumbs_NewFundRequestIndividual',
            link: routerPaths.financeRequestFundsIndividual,
          },
        },

        type: REQUEST_FUNDS_TYPES.INDIVIDUAL,
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.BALANCE_OUTPUT_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewFundRequestIndividual'
    },
  },

  {
    path: routerPaths.financeRequestFundsBusiness,
    component: RequestFundsCreation,
    meta: {
      breadcrumbs: {
        key: [
          'finance',
          'financeRequestFunds',
          'financeRequestFundsIndividual',
        ],
        items: {
          'finance': {
            name: 'menu_finance',
            link: routerPaths.financeRequestFunds,
          },
          'financeRequestFunds': {
            name: 'menu_requestFunds',
            link: routerPaths.financeRequestFunds,
          },
          'financeRequestFundsIndividual': {
            name: 'breadcrumbs_NewFundRequestIndividual',
            link: routerPaths.financeRequestFundsIndividual,
          },
        },

        type: REQUEST_FUNDS_TYPES.BUSINESS,
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.BALANCE_OUTPUT_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewFundRequestBusiness'
    },
  },


  {
    path: routerPaths.financeRequestFundsIndividualEdit,
    component: RequestFundsEditing,
    meta: {
      breadcrumbs: {
        key: [
          'finance',
          'financeRequestFunds',
          'financeRequestFundsIndividual',
        ],
        items: {
          'finance': {
            name: 'menu_finance',
            link: routerPaths.financeRequestFunds,
          },
          'financeRequestFunds': {
            name: 'menu_requestFunds',
            link: routerPaths.financeRequestFunds,
          },
          'financeRequestFundsIndividual': {
            name: 'breadcrumbs_EditFundRequest',
            link: routerPaths.financeRequestFundsIndividual,
          },
        },

        type: REQUEST_FUNDS_TYPES.INDIVIDUAL,
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.BALANCE_OUTPUT_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_EditFundRequest'
    },
  },

  {
    path: routerPaths.financeRequestFundsBusinessEdit,
    component: RequestFundsEditing,
    meta: {
      breadcrumbs: {
        key: [
          'finance',
          'financeRequestFunds',
          'financeRequestFundsIndividual',
        ],
        items: {
          'finance': {
            name: 'menu_finance',
            link: routerPaths.financeRequestFunds,
          },
          'financeRequestFunds': {
            name: 'menu_requestFunds',
            link: routerPaths.financeRequestFunds,
          },
          'financeRequestFundsIndividual': {
            name: 'breadcrumbs_EditFundRequest',
            link: routerPaths.financeRequestFundsIndividual,
          },
        },

        type: REQUEST_FUNDS_TYPES.BUSINESS,
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.BALANCE_OUTPUT_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_EditFundRequest'
    },
  },







  {
    path: routerPaths.financeRequestFundsBusiness,
    component: RequestFundsBusiness,
    meta: {
      breadcrumbs: {
        key: [
          'finance',
          'financeRequestFunds',
          'financeRequestFundsBusiness',
        ],
        items: {
          'finance': {
            name: 'menu_finance',
            link: routerPaths.financeRequestFunds,
          },
          'financeRequestFunds': {
            name: 'menu_requestFunds',
            link: routerPaths.financeRequestFunds,
          },
          'financeRequestFundsBusiness': {
            name: 'breadcrumbs_NewFundRequestBusiness',
            link: routerPaths.financeRequestFundsBusiness,
          },
        },

        type: REQUEST_FUNDS_TYPES.BUSINESS,
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.BALANCE_OUTPUT_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewFundRequestBusiness'
    },
  },
]
