<template>
  <div class="fragment">
    <ProformTable
        :pevItem="pevItem"
        :item="item"
    />
  </div>
</template>

<script>

import ProformTable from "@/components/coreComponents/Popups/HistoryOfChangesPopup/chunks/ProformTable/ProformTable";

export default {
  name: "ProformType",

  components: {ProformTable},

  props: {
    pevItem: Object,
    item: Object,
  },

}
</script>

<style scoped>

</style>