import {API} from "../../axios/axiosMainUrl";

const prefix = '/report/send-tracking-number'

export const trackTest = {
  state: {
    trackTest: [],
    trackTestItem: {},
    trackTestByUserId: [],
    trackTestCommonList: [],
    trackTestBtn: false,
    trackTestLoading: true,
    nextTrackTestPage: false,
    trackTestForPage: 25,
    trackTestFilter: '',
  },

  mutations: {
    setTrackTestFilter: (state, payload) => {
      state.trackTestFilter = payload
    },

    setTrackTest: (state, payload) => {
      if(state.nextTrackTestPage) {
        state.trackTest = state.trackTest.concat(payload.trackTest)
      } else {
        state.trackTest = payload.trackTest
      }
    },

    setTrackTestCommonList: (state, payload) => {
      state.trackTestCommonList = payload.trackTestCommonList
    },

    setNextTrackTestPage: (state, payload) => {
      state.nextTrackTestPage = payload
    },

    setTrackTestItem: (state, payload) => {
      state.trackTestItem = payload.trackTestItem
    },

    setTrackTestByUserId: (state, payload) => {
      state.trackTestByUserId = payload.trackTestByUserId
    },

    setTrackTestLoadingStart: (state) => {
      state.trackTestLoading = true
    },

    setTrackTestLoadingEnd: (state) => {
      state.trackTestLoading = false
    },

    changeTrackTestBtn: (state) => {
      state.trackTestBtn = true
    },
    successTrackTestBtn: (state) => {
      state.trackTestBtn = false
    },
  },

  getters: {
    getTrackTestFilter: state => {
      return state.trackTestFilter
    },

    getTrackTestForPage: state => {
      return state.trackTestForPage
    },

    getTrackTest: state => {
      return state.trackTest
    },

    getTrackTestLoading: state => {
      return state.trackTestLoading
    },


    getNextTrackTestPage: state => {
      return state.nextTrackTestPage
    },

    getTrackTestItem: state => {
      return state.trackTestItem
    },

    getTrackTestByUserId: state => {
      return state.trackTestByUserId
    },

    getTrackTestCommonList: state => {
      return state.trackTestCommonList
    },

    getTrackTestBtn: state => {
      return state.trackTestBtn
    },
  },

  actions: {

    async getTrackTestCreate({commit}) {
      try {
        return await API.get(`${prefix}`).then((response) => {
          return response;
        });
      } catch (e) {
        commit('setTrackTestLoadingEnd');
        return e;
      }
    },

    async getTrackTestResult({commit}, data) {
      commit('changeTrackTestBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successTrackTestBtn');
          return response;
        });
      } catch (e) {
        commit('successTrackTestBtn');
        return e;
      }
    },

  }
}
