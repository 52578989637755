import {checkValid} from "./functions/ModelsValidate";


export function Payment() {

  this.checkValid = checkValid

  this.data = {
    card: '',
    month: '',
    year: '',
    cardCode: '',
    firstName: '',
    lastName: '',
    phone: '',
    selectedCountry: null,
    selectedCountryObject: null,
    city: '',
    street: '',
    state: '',
    zipCode: '',
  }

  this.validation = {
    card: false,
    month: false,
    year: false,
    cardCode: false,
    firstName: false,
    lastName: false,
    phone: false,
    selectedCountry: false,
    selectedCountryObject: false,
    city: false,
    street: false,
    state: false,
    zipCode: false,
  }

  this.validationTranslate = {
    card: '',
    month: '',
    year: '',
    cardCode: '',
    firstName: '',
    lastName: '',
    phone: '',
    selectedCountry: '',
    selectedCountryObject: '',
    city: '',
    street: '',
    state: '',
    zipCode: '',
  }

  this.validationTxt = {
    card: false,
    month: false,
    year: false,
    cardCode: false,
    firstName: false,
    lastName: false,
    phone: false,
    selectedCountry: false,
    selectedCountryObject: false,
    city: false,
    street: false,
    state: false,
    zipCode: false,
  }

  /**
   * Getters
   */
  this.getCard = () => {
    return this.data.card
  }
  this.getMonth = () => {
    return this.data.month
  }
  this.getYear = () => {
    return this.data.year
  }
  this.getCardCode = () => {
    return this.data.cardCode
  }
  this.getFirstName = () => {
    return this.data.firstName
  }
  this.getLastName = () => {
    return this.data.lastName
  }
  this.getPhone = () => {
    return this.data.phone
  }
  this.getSelectedCountry = () => {
    return this.data.selectedCountry
  }
  this.getSelectedCountryObject = () => {
    return this.data.selectedCountryObject
  }
  this.getCity = () => {
    return this.data.city
  }
  this.getStreet = () => {
    return this.data.street
  }
  this.getState = () => {
    return this.data.state
  }
  this.getZipCode = () => {
    return this.data.zipCode
  }

  /**
   * Setters
   */

  this.setCard = (val) => {
    this.data.card = val
  }
  this.setMonth = (val) => {
    this.data.month = val
  }
  this.setYear = (val) => {
    this.data.year = val
  }
  this.setCardCode = (val) => {
    this.data.cardCode = val
  }
  this.setFirstName = (val) => {
    this.data.firstName = val
  }
  this.setLastName = (val) => {
    this.data.lastName = val
  }
  this.setPhone = (val) => {
    this.data.phone = val
  }
  this.setSelectedCountry = (val) => {
    this.data.selectedCountry = val
  }
  this.setSelectedCountryObject = (val) => {
    this.data.selectedCountryObject = val
  }
  this.setCity = (val) => {
    this.data.city = val
  }
  this.setStreet = (val) => {
    this.data.street = val
  }
  this.setState = (val) => {
    this.data.state = val
  }
  this.setZipCode = (val) => {
    this.data.zipCode = val
  }

}



/**
 * Global Setters
 */

Payment.prototype.setItem = function() {

}

/**
 * Prepare Data
 */

Payment.prototype.prepareSave = function() {

}


/**
 * Validations
 */

Payment.prototype.firstValidation = function (
  {
    card = false,
    month = false,
    year = false,
    cardCode = false,
  }
  ) {

  let validationItems = {
      firstName: this.data.firstName,
      lastName: this.data.lastName,
      phone: this.data.phone,
      selectedCountry: this.data.selectedCountry?.id,
      // selectedCountryObject: this.data.selectedCountryObject?.id,
      city: this.data.city,
      street: this.data.street,
      state: this.data.state,
      zipCode: this.data.zipCode,
    },
    validationOptions = {
      firstName: {type: ['empty']},
      lastName: {type: ['empty']},
      phone: {type: ['phone', 'empty']},
      selectedCountry: {type: ['empty']},
      // selectedCountryObject: {type: ['empty']},
      city: {type: ['empty']},
      street: {type: ['empty']},
      state: {type: ['empty']},
      zipCode: {type: ['empty']},
    }

    if(card) {
      validationItems['card'] = this.data.card
      validationOptions['card'] = {type: ['empty']}
    }
    if(month) {
      validationItems['month'] = this.data.month
      validationOptions['month'] = {type: ['empty']}
    }
    if(year) {
      validationItems['year'] = this.data.year
      validationOptions['year'] = {type: ['empty']}
    }
    if(cardCode) {
      validationItems['cardCode'] = this.data.cardCode
      validationOptions['cardCode'] = {type: ['empty']}
    }


  return this.checkValid(validationItems, validationOptions)

}
