
import {routerPaths} from '../../../router/routerPaths'

const HeartysanChatPage = () => import(/* webpackChunkName: "group-chat" */ './components/HeartysanChatPage/HeartysanChatPage.vue')


export const HeartysanChatRoutes = [
  {
    path: routerPaths.heartysanChat,
    component: HeartysanChatPage,
    meta: {
      breadcrumbs: {
        key: [
          'heartysanChat'
        ],
        items: {
          'heartysanChat': {
            name: 'menu_heartysanChat',
            link: routerPaths.heartysanChat,
          },
        },
      },
      pageTitle: 'menu_heartysanChat'
    },
  },

]
