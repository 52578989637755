import {routerPaths} from '../../../router/routerPaths'

const ConnectionsHistoryTable = () => import(/* webpackChunkName: "group-connections-history" */ './components/ConnectionsHistoryTable/ConnectionsHistoryTable.vue')
const moduleKey = 'connections-history'

export const ConnectionsHistoryRoutes = [
  {
    path: routerPaths.reportsConnectionsHistory,
    component: ConnectionsHistoryTable,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'reportsConnectionsHistory'
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.reportsConnectionsHistory,
          },
          'reportsConnectionsHistory': {
            name: 'menu_connectionsHistory',
            link: routerPaths.reportsConnectionsHistory,
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'menu_connectionsHistory'
    },
  },
]
