import {routerPaths} from '../../../router/routerPaths'

const IE559ReportTable = () => import(/* webpackChunkName: "group-connections-history" */ './components/IE559ReportTable/IE559ReportTable.vue')
const moduleKey = 'connections-history'

export const IE559ReportRoutes = [
  {
    path: routerPaths.IE559Report,
    component: IE559ReportTable,
    meta: {
      breadcrumbs: {
        key: [
          'finance',
          'IE559Report'
        ],
        items: {
          'finance': {
            name: 'menu_finance',
            link: routerPaths.IE559Report,
          },
          'IE559Report': {
            name: 'menu_IE559Report',
            link: routerPaths.IE559Report,
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'menu_IE559Report'
    },
  },
]
