<template>
  <div class="fragment">

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('orderPrice_Price.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.price'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.price'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('orderPrice_Weight.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.max_weight'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.max_weight'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('orderPrice_Country.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.country.name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.country.name'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('orderPrice_FromCountry.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.from_country.name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.from_country.name'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('orderPrice_Zip.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.zip_mask'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.zip_mask'"/></div>
    </div>

  </div>
</template>

<script>
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";

export default {
  name: "OrderPriceTypeOther",

  components: {ValueHelper},

  props: {
    pevItem: Object,
    item: Object,
  },

}
</script>

<style scoped>

</style>