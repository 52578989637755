
import {routerPaths} from '../../../router/routerPaths'

const ErrorLogsTable = () => import(/* webpackChunkName: "error-logs" */ './components/ErrorLogsTable/ErrorLogsTable.vue')
const ErrorLogsShow = () => import(/* webpackChunkName: "error-logs-show" */ './components/ErrorLogsShow/ErrorLogsShow.vue')
const moduleKey = 'error-logs'


export const ErrorLogsRoutes = [
  {
    path: routerPaths.errorLogs,
    component: ErrorLogsTable,
    meta: {
      breadcrumbs: {
        key: [
          'menu_settings',
          'mainSettingsErrorLogs'
        ],
        items: {
          'menu_settings': {
            name: 'menu_settings',
            link: routerPaths.errorLogs,
          },
          'mainSettingsErrorLogs': {
            name: 'menu_ErrorLogs',
            link: routerPaths.errorLogs,
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'menu_ErrorLogs'
    },
  },
  {
    path: routerPaths.errorLogsShow,
    component: ErrorLogsShow,
    meta: {
      breadcrumbs: {
        key: [
          'menu_settings',
          'mainSettingsErrorLogs'
        ],
        items: {
          'menu_settings': {
            name: 'menu_settings',
            link: routerPaths.errorLogs,
          },
          'mainSettingsErrorLogs': {
            name: 'menu_ErrorLogs',
            link: routerPaths.errorLogs,
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'menu_ErrorLogs'
    },
  },
]
