
import {routerPaths} from '../../../router/routerPaths'
import permissions from "@/router/middleware/permissions";
import {PERMISSIONS} from "@/staticData/permissionsStatic";

const DeliveryStatisticsTable = () => import(/* webpackChunkName: "group-delivery-statistic" */ './components/DeliveryStatisticsTable/DeliveryStatisticsTable.vue')
const moduleKey = 'delivery-statistics'

export const DeliveryStatisticsRoutes = [
  {
    path: routerPaths.statisticsDeliveryStatistics,
    component: DeliveryStatisticsTable,
    meta: {
      breadcrumbs: {
        key: [
          'statistics',
          'statisticsDeliveryStatistics'
        ],
        items: {
          'statistics': {
            name: 'menu_statistics',
            link: routerPaths.statisticsDeliveryStatistics,
          },
          'statisticsDeliveryStatistics': {
            name: 'menu_deliveryStatistics',
            link: routerPaths.statisticsDeliveryStatistics,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.DELIVERY_STATISTIC_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_deliveryStatistics'
    },
  },
]
