
import {routerPaths} from '../../../router/routerPaths'
import {RETURN_GOODS_TYPES} from "@/staticData/staticVariables";
import permissions from "@/router/middleware/permissions";
import {PERMISSIONS} from "@/staticData/permissionsStatic";

// const MyReturnsList = () => import(/* webpackChunkName: "group-my-returns" */ './components/MyReturnsList/MyReturnsList.vue')
const UnidentifiedReturnsList = () => import(/* webpackChunkName: "group-unidentified-returns" */ './components/UnidentifiedReturnsList/UnidentifiedReturnsList.vue')
const ReturnedGoodsOfUserList = () => import(/* webpackChunkName: "group-processed-package-returns" */ './components/ReturnedGoodsOfUserList/ReturnedGoodsOfUserList.vue')
const RequestReturnGoodsList = () => import(/* webpackChunkName: "group-processed-package-returns" */ './components/RequestReturnGoodsList/RequestReturnGoodsList.vue')
const ReturnGoodsCreation = () => import(/* webpackChunkName: "group-return-goods-creation" */ './components/ReturnGoodsCreation/ReturnGoodsCreation.vue')
const ReturnGoodsEditing = () => import(/* webpackChunkName: "group-return-goods-editing" */ './components/ReturnGoodsEditing/ReturnGoodsEditing.vue')
const ReturnGoodsShow = () => import(/* webpackChunkName: "group-return-goods-show" */ './components/ReturnGoodsShow/ReturnGoodsShow.vue')

// const MeestReturnsList = () => import(/* webpackChunkName: "group-meest-return-goods" */ './components/MeestReturnsList/MeestReturnsList.vue')
// const MeestReturnsEditing = () => import(/* webpackChunkName: "group-meest-return-goods-editing" */ './components/MeestReturnsEditing/MeestReturnsEditing.vue')
// const MeestReturnsShow = () => import(/* webpackChunkName: "group-meest-return-goods-show" */ './components/MeestReturnsShow/MeestReturnsShow.vue')
const moduleKey = 'return-goods'


export const ReturnGoodsRoutes = [
  // {
  //   path: routerPaths.problemsMyReturns,
  //   component: MyReturnsList,
  //   meta: {
  //     breadcrumbs: {
  //       key: [
  //         'problems',
  //         'problemsMyPackages'
  //       ],
  //       items: {
  //         'problems': {
  //           name: 'menu_problems',
  //           link: routerPaths.problemsMyReturns,
  //         },
  //         'problemsMyPackages': {
  //           name: 'menu_myReturns',
  //           link: routerPaths.problemsMyReturns,
  //         },
  //       },
  //     },
  //
  //     middleware: [permissions],
  //     permissions: [PERMISSIONS.RETURN_PARCEL_GENERAL],
  //
  //     pageTitle: 'menu_myReturns'
  //   },
  // },

  {
    path: routerPaths.problemsUnidentifiedReturns,
    component: UnidentifiedReturnsList,
    meta: {
      breadcrumbs: {
        key: [
          'problems',
          'problemsUnidentifiedReturns'
        ],
        items: {
          'problems': {
            name: 'menu_problems',
            link: routerPaths.problemsUnidentifiedReturns,
          },
          'problemsUnidentifiedReturns': {
            name: 'menu_unidentifiedReturns',
            link: routerPaths.problemsUnidentifiedReturns,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.RETURN_PARCEL_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_unidentifiedReturns'
    },
  },

  {
    path: routerPaths.problemsProcessedReturnsGoodsMyReturns,
    component: ReturnedGoodsOfUserList,
    meta: {
      type: RETURN_GOODS_TYPES.MY_RETURNS,
      breadcrumbs: {
        key: [
          'problems',
          'problemsUnidentifiedReturns'
        ],
        items: {
          'problems': {
            name: 'menu_problems',
            link: routerPaths.problemsUnidentifiedReturns,
          },
          'problemsUnidentifiedReturns': {
            name: 'menu_processedPackageReturns',
            link: routerPaths.problemsUnidentifiedReturns,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.RETURN_PARCEL_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_processedPackageReturns'
    },
  },
  {
    path: routerPaths.problemsProcessedReturnsGoodsUnprocessed,
    component: ReturnedGoodsOfUserList,
    meta: {
      type: RETURN_GOODS_TYPES.UNPROCESSED_RETURNS,
      breadcrumbs: {
        key: [
          'problems',
          'problemsUnprocessedReturns'
        ],
        items: {
          'problems': {
            name: 'menu_problems',
            link: routerPaths.problemsProcessedReturnsGoodsUnprocessed,
          },
          'problemsUnprocessedReturns': {
            name: 'menu_unprocessedPackageReturns',
            link: routerPaths.problemsProcessedReturnsGoodsUnprocessed,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.RETURN_PARCEL_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_unprocessedPackageReturns'
    },
  },
  {
    path: routerPaths.problemsProcessedReturnsGoodsProcessed,
    component: ReturnedGoodsOfUserList,
    meta: {
      type: RETURN_GOODS_TYPES.PROCESSED_RETURNS,
      breadcrumbs: {
        key: [
          'problems',
          'problemsUnidentifiedReturns'
        ],
        items: {
          'problems': {
            name: 'menu_problems',
            link: routerPaths.problemsUnidentifiedReturns,
          },
          'problemsUnidentifiedReturns': {
            name: 'menu_processedPackageReturns',
            link: routerPaths.problemsUnidentifiedReturns,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.RETURN_PARCEL_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_processedPackageReturns'
    },
  },
  {
    path: routerPaths.problemsProcessedReturnsGoodsTrash,
    component: ReturnedGoodsOfUserList,
    meta: {
      type: RETURN_GOODS_TYPES.TRASH,
      breadcrumbs: {
        key: [
          'problems',
          'problemsUnidentifiedReturns'
        ],
        items: {
          'problems': {
            name: 'menu_problems',
            link: routerPaths.problemsProcessedReturnsGoodsTrash,
          },
          'problemsUnidentifiedReturns': {
            name: 'menu_trashPackageReturns',
            link: routerPaths.problemsProcessedReturnsGoodsTrash,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.RETURN_PARCEL_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_trashPackageReturns'
    },
  },

  {
    path: routerPaths.problemsRequestReturnsGoods,
    component: RequestReturnGoodsList,
    meta: {
      breadcrumbs: {
        key: [
          'problems',
          'problemsUnidentifiedReturns'
        ],
        items: {
          'problems': {
            name: 'menu_problems',
            link: routerPaths.problemsRequestReturnsGoods,
          },
          'problemsUnidentifiedReturns': {
            name: 'menu_requestPackageReturns',
            link: routerPaths.problemsRequestReturnsGoods,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.RETURN_PARCEL_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_requestPackageReturns'
    },
  },

  {
    path: routerPaths.problemsReturnGoodsCreation,
    component: ReturnGoodsCreation,
    meta: {
      breadcrumbs: {
        key: [
          'problems',
          'problemsUnidentifiedReturns'
        ],
        items: {
          'problems': {
            name: 'menu_problems',
            link: routerPaths.problemsUnidentifiedReturns,
          },
          'problemsUnidentifiedReturns': {
            name: 'menu_processedPackageReturns',
            link: routerPaths.problemsReturnGoodsCreation,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.RETURN_PARCEL_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_processedPackageReturns'
    },
  },

  {
    path: routerPaths.problemsReturnGoodsEditing,
    component: ReturnGoodsEditing,
    meta: {
      breadcrumbs: {
        key: [
          'problems',
          'problemsUnidentifiedReturns'
        ],
        items: {
          'problems': {
            name: 'menu_problems',
            link: routerPaths.problemsUnidentifiedReturns,
          },
          'problemsUnidentifiedReturns': {
            name: 'menu_processedPackageReturns',
            link: routerPaths.problemsReturnGoodsCreation,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.RETURN_PARCEL_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_processedPackageReturns'
    },
  },

  {
    path: routerPaths.problemsReturnGoodsShow,
    component: ReturnGoodsShow,
    meta: {
      breadcrumbs: {
        key: [
          'problems',
          'problemsUnidentifiedReturns'
        ],
        items: {
          'problems': {
            name: 'menu_problems',
            link: routerPaths.problemsUnidentifiedReturns,
          },
          'problemsUnidentifiedReturns': {
            name: 'menu_processedPackageReturns',
            link: routerPaths.problemsReturnGoodsCreation,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.RETURN_PARCEL_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_processedPackageReturns'
    },
  },

  // {
  //   path: routerPaths.problemsMeestReturns,
  //   component: MeestReturnsList,
  //   meta: {
  //     breadcrumbs: {
  //       key: [
  //         'problems',
  //         'problemsMeestReturns'
  //       ],
  //       items: {
  //         'problems': {
  //           name: 'menu_problems',
  //           link: routerPaths.problemsMeestReturns,
  //         },
  //         'problemsMeestReturns': {
  //           name: 'menu_problemsMeestReturns',
  //           link: routerPaths.problemsMeestReturns,
  //         },
  //       },
  //     },
  //
  //     middleware: [permissions],
  //     permissions: [PERMISSIONS.RETURN_PARCEL_GENERAL],
  //
  //     moduleKey: moduleKey,
  //     pageTitle: 'menu_problemsMeestReturns'
  //   },
  // },
  //
  // {
  //   path: routerPaths.problemsMeestReturnsEdit,
  //   component: MeestReturnsEditing,
  //   meta: {
  //     breadcrumbs: {
  //       key: [
  //         'problems',
  //         'problemsMeestReturns'
  //       ],
  //       items: {
  //         'problems': {
  //           name: 'menu_problems',
  //           link: routerPaths.problemsMeestReturns,
  //         },
  //         'problemsMeestReturns': {
  //           name: 'menu_problemsMeestReturns',
  //           link: routerPaths.problemsMeestReturnsEdit,
  //         },
  //       },
  //     },
  //
  //     middleware: [permissions],
  //     permissions: [PERMISSIONS.RETURN_PARCEL_GENERAL],
  //
  //     moduleKey: moduleKey,
  //     pageTitle: 'menu_problemsMeestReturns'
  //   },
  // },
  //
  // {
  //   path: routerPaths.problemsMeestReturnsShow,
  //   component: MeestReturnsShow,
  //   meta: {
  //     breadcrumbs: {
  //       key: [
  //         'problems',
  //         'problemsMeestReturns'
  //       ],
  //       items: {
  //         'problems': {
  //           name: 'menu_problems',
  //           link: routerPaths.problemsMeestReturns,
  //         },
  //         'problemsMeestReturns': {
  //           name: 'menu_problemsMeestReturns',
  //           link: routerPaths.problemsMeestReturnsShow,
  //         },
  //       },
  //     },
  //
  //     middleware: [permissions],
  //     permissions: [PERMISSIONS.RETURN_PARCEL_GENERAL],
  //
  //     moduleKey: moduleKey,
  //     pageTitle: 'menu_problemsMeestReturns'
  //   },
  // },
]
