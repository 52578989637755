<template>
  <div class="fragment">

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('payPalCases_transactionId.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.payment_transaction.transaction_id'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.payment_transaction.transaction_id'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('payPalCases_transactionFreeAmount.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.payment_transaction.freeAmount'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.payment_transaction.freeAmount'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('payPalCases_paypalNumberOfComplaint.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.external_complain_id'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.external_complain_id'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('payPalCases_caseType.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.complaint_type.name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.complaint_type.name'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('payPalCases_caseReason.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.complaint_reason.name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.complaint_reason.name'"/></div>
    </div>

<!--    <div class="history-table__sub-row">-->
<!--      <div class="history-table__sub-col">{{$t('payPalCases_caseStatus.localization_value.value')}}</div>-->
<!--      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.status'"/></div>-->
<!--      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.status'"/></div>-->
<!--    </div>-->

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('payPalCases_caseStatus.localization_value.value')}}</div>
      <div class="history-table__sub-col"><span v-if="pevItem && pevItem.history.status">{{$t(getStatusPrev + '.localization_value.value')}}</span><template v-else>—</template></div>
      <div class="history-table__sub-col"><span v-if="item">{{$t(getStatus + '.localization_value.value')}}</span></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('payPalCases_amount.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.amount'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.amount'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('payPalCases_openingDate.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.opened_date'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.opened_date'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('payPalCases_closingDate.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.closed_date'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.closed_date'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
    </div>

<!--    <div class="history-table__sub-row" v-if="(pevItem && _.find(pevItem.history.files, {group: 'payment_transaction_complaint_file_group',})) || _.find(item.history.files, {group: 'payment_transaction_complaint_file_group',})">-->
<!--      <div class="history-table__sub-col">{{$t('payPalCases_documentsUp.localization_value.value')}}</div>-->
<!--      <div class="history-table__sub-col">-->
<!--        <template v-if="pevItem && _.find(pevItem.history.files, {group: 'payment_transaction_complaint_file_group', type: 'big_image'})"><FileBlock :files="pevItem.history.files" :file="_.find(pevItem.history.files, {group: 'payment_transaction_complaint_file_group', type: 'big_image'})"/></template>-->
<!--        <template v-else>—</template>-->
<!--      </div>-->
<!--      <div class="history-table__sub-col">-->
<!--        <template v-if="_.find(item.history.files, {group: 'payment_transaction_complaint_file_group', type: 'big_image'})"><FileBlock :files="item.history.files" :file="_.find(item.history.files, {group: 'payment_transaction_complaint_file_group', type: 'big_image'})"/></template>-->
<!--        <template v-else>—</template>-->
<!--      </div>-->
<!--    </div>-->


    <template v-for="(file, index) in item.history.files">
      <div class="history-table__sub-row" :key="index" v-if="file.type === 'big_image' || file.extension === 'pdf'">
        <div class="history-table__sub-col">File</div>
        <div class="history-table__sub-col">
          <template v-if="pevItem && _.has(pevItem.history.files, index)"><FileBlock :files="pevItem.history.files" :file="pevItem.history.files[index]"/></template>
          <template v-else>—</template>
        </div>
        <div class="history-table__sub-col">
          <template v-if="file.type === 'big_image' || file.extension === 'pdf'"><FileBlock :files="item.history.files" :file="file"/></template>
          <template v-else>—</template>
        </div>
      </div>
    </template>

    <template v-if="pevItem && pevItem.history && pevItem.history.files.length > item.history.files.length">
      <template v-for="(file, index) in pevItem.history.files">
        <div class="history-table__sub-row" :key="index"
             v-if="(file.type === 'big_image' || file.extension === 'pdf') && index > item.history.files.length - 1">
          <div class="history-table__sub-col">File</div>
          <div class="history-table__sub-col">
            <template v-if="file.type === 'big_image' || file.extension === 'pdf'"><FileBlock :files="pevItem.history.files" :file="file"/></template>
            <template v-else>—</template>
          </div>
          <div class="history-table__sub-col">
            —
          </div>
        </div>
      </template>
    </template>

  </div>
</template>

<script>
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import FileBlock from "@/components/coreComponents/Popups/HistoryOfChangesPopup/chunks/FileBlock/FileBlock";
import {PAYPAL_CASES_ARRAY_STATUSES} from "@/staticData/staticVariables";

export default {
  name: "PayPalCasesType",

  components: {FileBlock, ValueHelper},

  props: {
    pevItem: Object,
    item: Object,
  },

  computed: {
    getStatusPrev() {
      return this._.find(PAYPAL_CASES_ARRAY_STATUSES, {value: this.pevItem.history.status})?.translation
    },

    getStatus() {
      return this._.find(PAYPAL_CASES_ARRAY_STATUSES, {value: this.item.history.status})?.translation
    },
  }

}
</script>

<style scoped>

</style>