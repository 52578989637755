import {API} from "../../axios/axiosMainUrl";

const prefix = '/report/transaction-tracking'

export const trackingForPayPal = {
  state: {
    trackingForPayPal: [],
    trackingForPayPalItem: {},
    trackingForPayPalCommonList: [],
    trackingForPayPalBtn: false,
    trackingForPayPalLoading: true,
    nextTrackingForPayPalPage: false,
    trackingForPayPalForPage: 25,
    trackingForPayPalFilter: '',
  },

  mutations: {
    setTrackingForPayPalFilter: (state, payload) => {
      state.trackingForPayPalFilter = payload
    },

    setTrackingForPayPal: (state, payload) => {
      if(state.nextTrackingForPayPalPage) {
        state.trackingForPayPal = state.trackingForPayPal.concat(payload.trackingForPayPal)
      } else {
        state.trackingForPayPal = payload.trackingForPayPal
      }
    },

    setTrackingForPayPalCommonList: (state, payload) => {
      state.trackingForPayPalCommonList = payload.trackingForPayPalCommonList
    },

    setNextTrackingForPayPalPage: (state, payload) => {
      state.nextTrackingForPayPalPage = payload
    },

    setTrackingForPayPalItem: (state, payload) => {
      state.trackingForPayPalItem = payload.trackingForPayPalItem
    },

    setTrackingForPayPalLoadingStart: (state) => {
      state.trackingForPayPalLoading = true
    },

    setTrackingForPayPalLoadingEnd: (state) => {
      state.trackingForPayPalLoading = false
    },

    changeTrackingForPayPalBtn: (state) => {
      state.trackingForPayPalBtn = true
    },
    successTrackingForPayPalBtn: (state) => {
      state.trackingForPayPalBtn = false
    },
  },

  getters: {
    getTrackingForPayPalFilter: state => {
      return state.trackingForPayPalFilter
    },

    getTrackingForPayPalForPage: state => {
      return state.trackingForPayPalForPage
    },

    getTrackingForPayPal: state => {
      return state.trackingForPayPal
    },

    getTrackingForPayPalLoading: state => {
      return state.trackingForPayPalLoading
    },


    getNextTrackingForPayPalPage: state => {
      return state.nextTrackingForPayPalPage
    },

    getTrackingForPayPalItem: state => {
      return state.trackingForPayPalItem
    },

    getTrackingForPayPalCommonList: state => {
      return state.trackingForPayPalCommonList
    },

    getTrackingForPayPalBtn: state => {
      return state.trackingForPayPalBtn
    },
  },

  actions: {

    async fetchTrackingForPayPal({commit, getters}, filter = '') {
      if(!getters.getNextTrackingForPayPalPage)
        commit('setTrackingForPayPalLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setTrackingForPayPal',{trackingForPayPal: response.data.data.data})
          // commit('setTrackingForPayPalCommonList',{trackingForPayPalCommonList: response.data.data})
          // not a standard meta data output, fix
          commit('setTrackingForPayPalCommonList',{trackingForPayPalCommonList: {...response.data.data.meta, ...{
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev
              }}})
          commit('setTrackingForPayPalLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setTrackingForPayPalLoadingEnd');
        return e;
      }
    },

    async getExportTrackingForPayPal({commit}, {filter, exportType}) {
      commit('setExportBtnLoadingStart')
      commit('setLoadingEnd')
      try {
        return await API.get(`${prefix}/export/${exportType}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

  }
}
