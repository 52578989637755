import {API} from "../../axios/axiosMainUrl";

const prefix = '/access-controls/presets'

export const manageUsersGroups = {
  state: {
    manageUsersGroups: [],
    manageUsersGroupsItem: {},
    manageUsersGroupsCommonList: [],
    manageUsersGroupsBtn: false,
    manageUsersGroupsLoading: true,
    nextManageUsersGroupsPage: false,
    manageUsersGroupsForPage: 25,
    manageUsersGroupsFilter: '',
  },

  mutations: {
    setManageUsersGroupsFilter: (state, payload) => {
      state.manageUsersGroupsFilter = payload
    },

    setManageUsersGroups: (state, payload) => {
      if(state.nextManageUsersGroupsPage) {
        state.manageUsersGroups = state.manageUsersGroups.concat(payload.manageUsersGroups)
      } else {
        state.manageUsersGroups = payload.manageUsersGroups
      }
    },

    setManageUsersGroupsCommonList: (state, payload) => {
      state.manageUsersGroupsCommonList = payload.manageUsersGroupsCommonList
    },

    setNextManageUsersGroupsPage: (state, payload) => {
      state.nextManageUsersGroupsPage = payload
    },

    setManageUsersGroupsItem: (state, payload) => {
      state.manageUsersGroupsItem = payload.manageUsersGroupsItem
    },

    setManageUsersGroupsLoadingStart: (state) => {
      state.manageUsersGroupsLoading = true
    },

    setManageUsersGroupsLoadingEnd: (state) => {
      state.manageUsersGroupsLoading = false
    },

    changeManageUsersGroupsBtn: (state) => {
      state.manageUsersGroupsBtn = true
    },
    successManageUsersGroupsBtn: (state) => {
      state.manageUsersGroupsBtn = false
    },
  },

  getters: {
    getManageUsersGroupsFilter: state => {
      return state.manageUsersGroupsFilter
    },

    getManageUsersGroupsForPage: state => {
      return state.manageUsersGroupsForPage
    },

    getManageUsersGroups: state => {
      return state.manageUsersGroups
    },

    getManageUsersGroupsLoading: state => {
      return state.manageUsersGroupsLoading
    },


    getNextManageUsersGroupsPage: state => {
      return state.nextManageUsersGroupsPage
    },

    getManageUsersGroupsItem: state => {
      return state.manageUsersGroupsItem
    },

    getManageUsersGroupsCommonList: state => {
      return state.manageUsersGroupsCommonList
    },

    getManageUsersGroupsBtn: state => {
      return state.manageUsersGroupsBtn
    },
  },

  actions: {

    async fetchManageUsersGroups({commit, getters}, filter = '') {
      if(!getters.getNextManageUsersGroupsPage)
        commit('setManageUsersGroupsLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setManageUsersGroups',{manageUsersGroups: response.data.data.data})
          commit('setManageUsersGroupsCommonList',{manageUsersGroupsCommonList: response.data.data})
          commit('setManageUsersGroupsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setManageUsersGroupsLoadingEnd');
        return e;
      }
    },

    async getManageUsersGroups({commit}, id) {
      commit('setManageUsersGroupsLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setManageUsersGroupsItem', {manageUsersGroupsItem: response.data.data})
          commit('setManageUsersGroupsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setManageUsersGroupsLoadingEnd');
        return e;
      }
    },

    async createManageUsersGroups({commit}, data) {
      commit('changeManageUsersGroupsBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successManageUsersGroupsBtn');
          return response;
        });
      } catch (e) {
        commit('successManageUsersGroupsBtn');
        return e;
      }
    },

    async updateManageUsersGroups({commit}, {id, data}) {
      commit('changeManageUsersGroupsBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successManageUsersGroupsBtn');
          return response;
        });
      } catch (e) {
        commit('successManageUsersGroupsBtn');
        return e;
      }
    },

    async deleteManageUsersGroups({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successManageUsersGroupsBtn');
        return e;
      }
    },

  }
}
