<template>
  <div class="fragment">
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        Category
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="pevItem"
            :deep="'history.proform.0.category.currentTranslate.name'"
        />
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="item"
            :deep="'history.proform.0.category.currentTranslate.name'"
        />
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        <ValueHelper
            :value="pevItem || item"
            :deep="'history.proform.0.type.currentTranslate.name'"
        />
      </div>
      <div class="history-table__sub-col">
        <div>
          <ValueHelper :value="pevItem" :deep="'history.proform.0.value.translationStorage.en.name'"/>
          <br>
          <ValueHelper class="d-block mt-2" :value="pevItem" :deep="'history.proform.0.value.translationStorage.ua.name'"/>
        </div>
      </div>
      <div class="history-table__sub-col">
        <div>
          <ValueHelper :value="item" :deep="'history.proform.0.value.translationStorage.en.name'"/>
          <br>
          <ValueHelper class="d-block mt-2" :value="item" :deep="'history.proform.0.value.translationStorage.ua.name'"/>
        </div>
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        <ValueHelper
            :value="pevItem || item"
            :deep="'history.proform.1.type.currentTranslate.name'"
        />
      </div>
      <div class="history-table__sub-col">
        <div>
          <ValueHelper :value="pevItem" :deep="'history.proform.1.value.translationStorage.en.name'"/>
          <br>
          <ValueHelper class="d-block mt-2" :value="pevItem" :deep="'history.proform.1.value.translationStorage.ua.name'"/>
        </div>
      </div>
      <div class="history-table__sub-col">
        <div>
          <ValueHelper :value="item" :deep="'history.proform.1.value.translationStorage.en.name'"/>
          <br>
          <ValueHelper class="d-block mt-2" :value="item" :deep="'history.proform.1.value.translationStorage.ua.name'"/>
        </div>
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        <ValueHelper
            :value="pevItem || item"
            :deep="'history.proform.2.type.currentTranslate.name'"
        />
      </div>
      <div class="history-table__sub-col">
        <div>
          <ValueHelper :value="pevItem" :deep="'history.proform.2.value.translationStorage.en.name'"/>
          <br>
          <ValueHelper class="d-block mt-2" :value="pevItem" :deep="'history.proform.2.value.translationStorage.ua.name'"/>
        </div>
      </div>
      <div class="history-table__sub-col">
        <div>
          <ValueHelper :value="item" :deep="'history.proform.2.value.translationStorage.en.name'"/>
          <br>
          <ValueHelper class="d-block mt-2" :value="item" :deep="'history.proform.2.value.translationStorage.ua.name'"/>
        </div>
      </div>
    </div>
    <div class="history-table__sub-row" v-if="item && _.has(item.history.proform, '3') || pevItem && _.has(pevItem.history.proform, '3')">
      <div class="history-table__sub-col">
        <ValueHelper
            :value="pevItem || item"
            :deep="'history.proform.3.type.currentTranslate.name'"
        />
      </div>
      <div class="history-table__sub-col">
        <div>
          <ValueHelper :value="pevItem" :deep="'history.proform.3.value.translationStorage.en.name'"/>
          <br>
          <ValueHelper class="d-block mt-2" :value="pevItem" :deep="'history.proform.3.value.translationStorage.ua.name'"/>
        </div>
      </div>
      <div class="history-table__sub-col">
        <div>
          <ValueHelper :value="item" :deep="'history.proform.3.value.translationStorage.en.name'"/>
          <br>
          <ValueHelper class="d-block mt-2" :value="item" :deep="'history.proform.3.value.translationStorage.ua.name'"/>
        </div>
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('common_HSCode.localization_value.value')}}
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="pevItem"
            :deep="'history.hs_code'"
        />
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="item"
            :deep="'history.hs_code'"
        />
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('common_ItemsQty.localization_value.value')}}
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="pevItem"
            :deep="'history.item_quantity'"
        />
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="item"
            :deep="'history.item_quantity'"
        />
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('common_PricePerItem.localization_value.value')}}
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="pevItem"
            :deep="'history.item_price'"
        />
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="item"
            :deep="'history.item_price'"
        />
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('product_Handmade.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox
            v-if="pevItem"
            :value="parseInt(pevItem.history.handmade) === 1"
            :disabled="true"
        />
        <template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox
            v-if="item"
            :value="parseInt(item.history.handmade) === 1"
            :disabled="true"
        />
        <template v-else>—</template>
      </div>
    </div>
  </div>
</template>

<script>
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
export default {
  name: "ProformTable",
  components: {
    DefaultCheckbox,
    ValueHelper
  },

  props: {
    pevItem: Object,
    item: Object,
  },

}
</script>

<style scoped lang="scss">
  .history-table__sub-col{
    word-break: initial;
  }
</style>