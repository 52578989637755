import {API} from "../../axios/axiosMainUrl";

export const simpleProductProfile = {
  state: {
    simpleProductProfile: [],
    simpleProductProfileItem: {},
    simpleProductProfileCommonList: [],
    simpleProductProfileBtn: false,
    simpleProductProfileLoading: true,
    nextSimpleProductProfilePage: false,
    simpleProductProfileForPage: 25,
    simpleProductProfileFilter: '',
  },

  mutations: {
    setSimpleProductProfileFilter: (state, payload) => {
      state.simpleProductProfileFilter = payload
    },

    setSimpleProductProfile: (state, payload) => {
      if(state.nextSimpleProductProfilePage) {
        state.simpleProductProfile = state.simpleProductProfile.concat(payload.simpleProductProfile)
      } else {
        state.simpleProductProfile = payload.simpleProductProfile
      }
    },

    setSimpleProductProfileCommonList: (state, payload) => {
      state.simpleProductProfileCommonList = payload.simpleProductProfileCommonList
    },

    setNextSimpleProductProfilePage: (state, payload) => {
      state.nextSimpleProductProfilePage = payload
    },

    setSimpleProductProfileItem: (state, payload) => {
      state.simpleProductProfileItem = payload.simpleProductProfileItem
    },

    setSimpleProductProfileLoadingStart: (state) => {
      state.simpleProductProfileLoading = true
    },

    setSimpleProductProfileLoadingEnd: (state) => {
      state.simpleProductProfileLoading = false
    },

    changeSimpleProductProfileBtn: (state) => {
      state.simpleProductProfileBtn = true
    },
    successSimpleProductProfileBtn: (state) => {
      state.simpleProductProfileBtn = false
    },
  },

  getters: {
    getSimpleProductProfileFilter: state => {
      return state.simpleProductProfileFilter
    },

    getSimpleProductProfileForPage: state => {
      return state.simpleProductProfileForPage
    },

    getSimpleProductProfile: state => {
      return state.simpleProductProfile
    },

    getSimpleProductProfileLoading: state => {
      return state.simpleProductProfileLoading
    },


    getNextSimpleProductProfilePage: state => {
      return state.nextSimpleProductProfilePage
    },

    getSimpleProductProfileItem: state => {
      return state.simpleProductProfileItem
    },

    getSimpleProductProfileCommonList: state => {
      return state.simpleProductProfileCommonList
    },

    getSimpleProductProfileBtn: state => {
      return state.simpleProductProfileBtn
    },
  },

  actions: {

    async fetchSimpleProductProfile({commit, getters}, filter = '') {
      if(!getters.getNextSimpleProductProfilePage)
        commit('setSimpleProductProfileLoadingStart')
      try {
        return await API.get(`/products/simple-product-profile/public-list${filter}`).then((response) =>{
          commit('setSimpleProductProfile',{simpleProductProfile: response.data.data.data})
          commit('setSimpleProductProfileCommonList',{simpleProductProfileCommonList: response.data.data})
          commit('setSimpleProductProfileLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setSimpleProductProfileLoadingEnd');
        return e;
      }
    },

    async fetchSimpleProductProfileWithPermissions({commit, getters}, filter = '') {
      if(!getters.getNextSimpleProductProfilePage)
        commit('setSimpleProductProfileLoadingStart')
      try {
        return await API.get(`/products/simple-product-profile${filter}`).then((response) =>{
          commit('setSimpleProductProfile',{simpleProductProfile: response.data.data.data})
          commit('setSimpleProductProfileCommonList',{simpleProductProfileCommonList: response.data.data})
          commit('setSimpleProductProfileLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setSimpleProductProfileLoadingEnd');
        return e;
      }
    },

    async getSimpleProductProfile({commit}, id) {
      commit('setSimpleProductProfileLoadingStart');
      try {
        return await API.get(`/products/simple-product-profile/${id}`).then((response) =>{
          console.log(response)
          commit('setSimpleProductProfileItem', {simpleProductProfileItem: response.data.data})
          commit('setSimpleProductProfileLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setSimpleProductProfileLoadingEnd');
        return e;
      }
    },

    async createSimpleProductProfile({commit}, data) {
      commit('changeSimpleProductProfileBtn');
      try {
        return await API.post(`/products/simple-product-profile`, data).then((response) =>{
          commit('successSimpleProductProfileBtn');
          return response;
        });
      } catch (e) {
        commit('successSimpleProductProfileBtn');
        return e;
      }
    },

    async updateSimpleProductProfile({commit}, {id, data}) {
      commit('changeSimpleProductProfileBtn');
      try {
        return await API.put(`/products/simple-product-profile/${id}`, data).then((response) =>{
          commit('successSimpleProductProfileBtn');
          return response;
        });
      } catch (e) {
        commit('successSimpleProductProfileBtn');
        return e;
      }
    },

    async deleteSimpleProductProfile({commit}, id) {
      try {
        return await API.delete(`/products/simple-product-profile/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successSimpleProductProfileBtn');
        return e;
      }
    },

  }
}
