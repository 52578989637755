var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"custom-popup guide-edit-popup",on:{"close":function($event){return _vm.$emit('close')}}},[_c('template',{slot:"header"},[_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'guide_guideEdit',
          ])}}}),_vm._v(" "+_vm._s(_vm.$t('guide_guideEdit.localization_value.value'))+" ")])]),_c('template',{slot:"body"},[_c('div',{staticClass:"custom-popup__content"},[_c('div',{staticClass:"custom-row"},[(!_vm.hideVideo)?_c('div',{staticClass:"custom-col custom-col-md--100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'guide_videoLink',
          ])}}}),_c('DefaultInput',{attrs:{"label":_vm.$t('guide_videoLink.localization_value.value')},model:{value:(_vm.guideItem.data.linkVideo),callback:function ($$v) {_vm.$set(_vm.guideItem.data, "linkVideo", $$v)},expression:"guideItem.data.linkVideo"}})],1):_vm._e(),_c('div',{staticClass:"custom-col custom-col-md--100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'guide_linkEng',
          ])}}}),_c('DefaultInput',{attrs:{"label":_vm.$t('guide_linkEng.localization_value.value')},model:{value:(_vm.guideItem.data.linkEn),callback:function ($$v) {_vm.$set(_vm.guideItem.data, "linkEn", $$v)},expression:"guideItem.data.linkEn"}})],1),_c('div',{staticClass:"custom-col custom-col-md--100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'guide_linkUa',
          ])}}}),_c('DefaultInput',{attrs:{"label":_vm.$t('guide_linkUa.localization_value.value')},model:{value:(_vm.guideItem.data.linkUa),callback:function ($$v) {_vm.$set(_vm.guideItem.data, "linkUa", $$v)},expression:"guideItem.data.linkUa"}})],1),_c('div',{staticClass:"custom-col custom-col-md--100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'guide_linkRu',
          ])}}}),_c('DefaultInput',{attrs:{"label":_vm.$t('guide_linkRu.localization_value.value')},model:{value:(_vm.guideItem.data.linkRu),callback:function ($$v) {_vm.$set(_vm.guideItem.data, "linkRu", $$v)},expression:"guideItem.data.linkRu"}})],1),_c('div',{staticClass:"custom-col custom-col-md--100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'guide_linkLv',
          ])}}}),_c('DefaultInput',{attrs:{"label":_vm.$t('guide_linkLv.localization_value.value')},model:{value:(_vm.guideItem.data.linkLv),callback:function ($$v) {_vm.$set(_vm.guideItem.data, "linkLv", $$v)},expression:"guideItem.data.linkLv"}})],1),_c('div',{staticClass:"custom-col custom-col-md--100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'guide_linkPl',
          ])}}}),_c('DefaultInput',{attrs:{"label":_vm.$t('guide_linkPl.localization_value.value')},model:{value:(_vm.guideItem.data.linkPl),callback:function ($$v) {_vm.$set(_vm.guideItem.data, "linkPl", $$v)},expression:"guideItem.data.linkPl"}})],1),_c('div',{staticClass:"custom-col custom-col-md--100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'guide_textEng',
          ])}}}),_c('div',{staticClass:"mb-2 fsz14",staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.$t('guide_textEng.localization_value.value')))]),_c('TextEditor',{attrs:{"value":_vm.guideItem.data.textEn},on:{"input":_vm.inputTextEditorEn}})],1),_c('div',{staticClass:"custom-col custom-col-md--100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'guide_textUa',
          ])}}}),_c('div',{staticClass:"mb-2 fsz14",staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.$t('guide_textUa.localization_value.value')))]),_c('TextEditor',{attrs:{"value":_vm.guideItem.data.textUa},on:{"input":_vm.inputTextEditorUa}})],1),_c('div',{staticClass:"custom-col custom-col-md--100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'guide_textRu',
          ])}}}),_c('div',{staticClass:"mb-2 fsz14",staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.$t('guide_textRu.localization_value.value')))]),_c('TextEditor',{attrs:{"value":_vm.guideItem.data.textRu},on:{"input":_vm.inputTextEditorRu}})],1),_c('div',{staticClass:"custom-col custom-col-md--100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'guide_textLv',
          ])}}}),_c('div',{staticClass:"mb-2 fsz14",staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.$t('guide_textLv.localization_value.value')))]),_c('TextEditor',{attrs:{"value":_vm.guideItem.data.textLv},on:{"input":_vm.inputTextEditorLv}})],1),_c('div',{staticClass:"custom-col custom-col-md--100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'guide_textPl',
          ])}}}),_c('div',{staticClass:"mb-2 fsz14",staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.$t('guide_textPl.localization_value.value')))]),_c('TextEditor',{attrs:{"value":_vm.guideItem.data.textPl},on:{"input":_vm.inputTextEditorPl}})],1)])])]),_c('template',{slot:"footer"},[_c('div',{staticClass:"custom-popup__btn align-items-center d-flex flex-wrap mt-5"},[_c('span',{staticClass:"site-link site-link--alt custom-popup__btn-i mr-4 mt-3 mb-3",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('common_cancel.localization_value.value'))+" ")]),_c('MainButton',{staticClass:"custom-popup__btn-i",attrs:{"value":_vm.edit ? _vm.$t('common_save.localization_value.value') : _vm.$t('common_create.localization_value.value')},nativeOn:{"click":function($event){return _vm.$emit('save', _vm.edit)}}})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }