import {ErrorLog} from "@/components/globalModels/ErrorLog";

export const globalErrorLogsMixin = {

  data() {
    return {
      ErrorLog: new ErrorLog(),
    }
  },

  methods: {
    createErrorLog(error) {
      /**
       * Set error in log
       */
      console.error(error)

      /**
       * Save error in API
       */

      this.ErrorLog.setItem({
        baseURI: this?.$el.baseURI,
        vueComponent: this?.$vnode.tag,
        user: `Id: ${this.getCurrentUser?.id}, email: ${this.getCurrentUser?.email}`,
        userAgent: navigator.userAgent,
        error: error.stack,
        typeError: 'Global mixin error;',
        routeHistory: this.$store.getters.GET_ROUTER_HISTORY,
      })

      let data = this.ErrorLog.prepareSave()

      this.$store.dispatch('createErrorLogs', data)

    },
  }

}
