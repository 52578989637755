
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const ProfilesTable = () => import(/* webpackChunkName: "group-ukr-treasures" */ './components/ProfilesTable/ProfilesTable.vue')
const moduleKey = 'profiles'

export const ProfilesRoutes = [
  {
    path: routerPaths.mainSettingsProfilesLink,
    component: ProfilesTable,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'mainSettingsProfilesLink'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.dashboard,
          },
          'mainSettingsProfilesLink': {
            name: 'menu_profiles',
            link: routerPaths.mainSettingsProfilesLink,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.PROFILE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_profiles'
    },
  },
]
