<template>
  <div class="fragment">
    <div class="history-table__sub-row"
         v-if="name">
      <div class="history-table__sub-col">
        {{$t('fbm_ProductName.localization_value.value')}}
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="pevItem"
            :deep="'product.name_for_fba'"
        />
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="item"
            :deep="'product.name_for_fba'"
        />
      </div>
    </div>
    <div class="history-table__sub-row"
         v-if="quantity">
      <div class="history-table__sub-col">
        {{$t('fbm_ItemsQty.localization_value.value')}}
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="pevItem"
            :deep="'item_quantity'"
        />
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="item"
            :deep="'item_quantity'"
        />
      </div>
    </div>
    <div class="history-table__sub-row"
         v-if="price">
      <div class="history-table__sub-col">
        {{$t('fbm_ProductPrice.localization_value.value')}}
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="pevItem"
            :deep="'item_price'"
        />
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="item"
            :deep="'item_price'"
        />
      </div>
    </div>
    <div class="history-table__sub-row"
         v-if="hsCode">
      <div class="history-table__sub-col">
        {{$t('common_HSCode.localization_value.value')}}
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="pevItem"
            :deep="'hs_code'"
        />
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="item"
            :deep="'hs_code'"
        />
      </div>
    </div>
    <div class="history-table__sub-row"
         v-if="description">
      <div class="history-table__sub-col">
        {{$t('fbm_ProductDescription.localization_value.value')}}
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="pevItem"
            :deep="'item_description'"
        />
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="item"
            :deep="'item_description'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";

export default {
  name: "ProductTable",

  components: {
    ValueHelper
  },

  props: {
    pevItem: Object,
    item: Object,
    name: {
      type: Boolean,
      default: true,
    },
    quantity: {
      type: Boolean,
      default: false,
    },
    price: {
      type: Boolean,
      default: false,
    },
    hsCode: {
      type: Boolean,
      default: false,
    },
    description: {
      type: Boolean,
      default: false,
    },
  },

}
</script>

<style scoped>

</style>