<template>
  <div class="fragment">
    <AuthorizePaymentPopup
        v-if="authorizePaymentPopup && transactionsChecked"
        :countMoney="authorizePaySum"
        :authorizePayData="authorizePayData"
        :authorizeError="authorizeError"
        :serverError="serverError"
        :canBeClosedByUser="canBeClosedByUser"
        :titleText="'common_paymentTitle'"
        @submitForm="payAuthorize"
        @close="changeAuthorizePaymentPopup(false)"
    />

    <BraintreePaymentPopup
        v-if="braintreePaymentPopup && transactionsChecked"
        :amount="authorizePaySum"
        :payData="authorizePayData"
        :canBeClosedByUser="canBeClosedByUser"
        :titleText="'common_paymentTitle'"
        @submitForm="submitBrainTreePay"
        @close="braintreePaymentPopup = false"
    />

    <ThankYouPopup
        v-if="thankYouPopupPopup && transactionsChecked"
        :title="'common_paySuccessTitle'"
        :text="'common_paidNegativeBalanceText'"
        :withLink="false"
        :canBeClosedByUser="canBeClosedByUser"
        :linkLastText="'common_paySuccessTextLink'"
        :linkLastTextHref="$store.getters.GET_PATHS.financeMyPayments"
        @close="changeThankYouPopupPopup(false)"
    />
  </div>
</template>

<script>
  import AuthorizePaymentPopup from "../Popups/AuthorizePaymentPopup/AuthorizePaymentPopup";
  import ThankYouPopup from "../Popups/ThankYouPopup/ThankYouPopup";
  import {PERMISSIONS} from "@/staticData/permissionsStatic";
  import BraintreePaymentPopup from "@/components/coreComponents/Popups/BraintreePaymentPopup/BraintreePaymentPopup";
  // import RadioDefault from "../../UI/radiobuttons/RadioDefault/RadioDefault";
  // import DefaultInput from "../../UI/inputs/DefaultInput/DefaultInput";
  // import MainButton from "../../UI/buttons/MainButton/MainButton";
  // import AuthorizePaymentPopup from "../AuthorizePaymentPopup/AuthorizePaymentPopup";
  // import ThankYouPopup from "../ThankYouPopup/ThankYouPopup";

  export default {
    name: "NegativeBalance",
    components: {
      BraintreePaymentPopup,
      ThankYouPopup,
      AuthorizePaymentPopup,
    },

    props: [
    ],

    created() {


      if(!Object.hasOwnProperty.call(this.$store.getters.getCurrentUserRights.additionalPermissions, PERMISSIONS.MY_PAYMENT_GENERAL)) {
        console.log('negative balance no permissions');
        return
      }

      console.log(1111111111, this.getCurrentImpersonateUser);
      if (this.getCurrentImpersonateUser !== null) {
        this.canBeClosedByUser = true
      }
      //check once if user has transactions with status "on_hold" i.e. still in progress in the last few days
      const query = new this.Query();
      let url = '?';
      let myQuery = query
          .for('posts')

      myQuery.where('PaymentTransactionStatus', 'on_hold')
      let yesterdayDate = new Date()
      yesterdayDate = yesterdayDate.setDate(yesterdayDate.getDate() - 1)

      myQuery.whereIn(
          'between_created_at',
          [this.$moment(yesterdayDate).format("YYYY-MM-DD"),
          this.$moment(new Date()).format("YYYY-MM-DD")]
      )

      url = url + myQuery.limit(100000).page(1).url().split('?')[1]
      // type: 'authorize'
      let type = 'authorize'
      if(this._.has(this.currentPermissions, this.PERMISSIONS.MY_PAYMENT_BRAINTREE)) {
        type = 'braintree'
      }
      this.$store.dispatch('fetchMyPayments', {type: type, filter: url}).then(response => {
        let respData = this.getRespPaginateData(response)

        if (respData.length) {
          this.authorizePaymentPopup = false
          this.thankYouPopupPopup = true
        }

        this.transactionsChecked = true
      })

      let authorize = this.$store.getters.getUserSettings.payments.authorize
      this.authorizePaySum = Math.abs(this.$store.getters.GET_COMMON_AUTHORIZED.user.balance).toFixed(2)
      console.log(this.authorizePaySum);

      this.recountBankFeeds(authorize.default_authorize_commission.value, authorize.authorize_per_transaction.value)

    },

    data() {
      return {
        authorizePaymentPopup: false,
        braintreePaymentPopup: false,
        authorizePayData: {},
        authorizePaySum: 0,
        thankYouPopupPopup: false,

        serverError: false,
        authorizeError: false,
        transactionsChecked: false,
        canBeClosedByUser: false,
      }
    },

    mounted() {
      if(this._.has(this.currentPermissions, this.PERMISSIONS.CUSTOMER_PAYMENT_BRAINTREE)) {
        this.braintreePaymentPopup = true
      } else {
        this.authorizePaymentPopup = true
      }
    },

    methods: {

      submitBrainTreePay() {
        this.braintreePaymentPopup = false
        setTimeout(() => {
          this.changeThankYouPopupPopup(true)
        }, 200)

        let setAccessCheckFunc = setInterval(() => {
          this.$store.dispatch('fetchCommonAllowAccessWithNegativeBalance').then(responseAccess => {
            let respData = this.getRespData(responseAccess)

            if (respData.allow_access_with_negative_balance) {
              clearInterval(setAccessCheckFunc)
              this.thankYouPopupPopup = false
            }
          })
        }, 8000)
      },

      payAuthorize(data) {

        let postData = {
          card: {
            "number": data.card,
            "expiryMonth": data.month,
            "expiryYear": data.year,
            "code": data.cardCode,
          },
          "contact": {
            "firstName": data.firstName,
            "lastName": data.lastName,
            "phone": data.phone,
            "city": data.city,
            "zip": data.zipCode,
            "email": "ssdsd@sadsad.sad2",
            "address": data.street,
            "state": data.state,
            "country": data.selectedCountry
          },
          "from_dashboard": 1,
          "amount": this.authorizePaySum
        }

        this.serverError = false

        this.$store.dispatch('newPaymentAuthorize', postData).then(response => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            if (response.data.data.status === true) {

              this.authorizePaymentPopup = false
              setTimeout(() => {
                this.changeThankYouPopupPopup(true)
              }, 200)

              let setAccessCheckFunc = setInterval(() => {
                this.$store.dispatch('fetchCommonAllowAccessWithNegativeBalance').then(responseAccess => {
                  let respData = this.getRespData(responseAccess)

                  if (respData.allow_access_with_negative_balance) {
                    clearInterval(setAccessCheckFunc)
                    this.thankYouPopupPopup = false
                  }

                  console.log(responseAccess);
                })
              }, 8000)

            } else {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors

            this.serverError = true
            this.authorizeError = errors

          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        })

      },

      recountBankFeeds(paymentSystemFee, additionalPercent) {
        paymentSystemFee = paymentSystemFee.replace(',', '.')

          // this.authorizePaySum = (
          //     (
          //         ((parseFloat(this.authorizePaySum) + parseFloat(additionalPercent)) * parseFloat(value)) / 100) +
          //     (parseFloat(this.authorizePaySum) + parseFloat(additionalPercent))
          // ).toFixed(2)

        let sum = Number(this.authorizePaySum) // user amount
        let paymentFee = Number(paymentSystemFee) // 4,20
        let systemPercent = Number(additionalPercent) // 0.3

        this.authorizePaySum = (
            (sum + systemPercent) * (100 / (100 - paymentFee))
        ).toFixed(2)

      },

      changeThankYouPopupPopup(val) {
        if (this.getCurrentImpersonateUser !== null) {
          this.thankYouPopupPopup = val
        }
      },

      changeAuthorizePaymentPopup(val) {
        if (this.getCurrentImpersonateUser !== null) {
          this.authorizePaymentPopup = val
        }
      },
    }

  }
</script>

<style lang="scss">
  @import "../../../scss/colors";
  @import "../../../scss/mixins/mixins";


  .negative-balance-popup {
    /*.modal-component__header{*/
      /*margin-bottom: 0;*/
    /*}*/

    /*&__ico{*/
      /*float: left;*/
      /*width: 100px;*/
      /*margin-right: 25px;*/

      /*img{*/
        /*width: 100%;*/
      /*}*/
    /*}*/

    /*&__title{*/
      /*font-weight: bold;*/
      /*font-size: 48px;*/
      /*line-height: 56px;*/
      /*color: #8F7A61;*/
      /*margin-bottom: 16px;*/
    /*}*/

    /*&__text{*/
      /*font-size: 14px;*/
      /*line-height: 24px;*/
      /*color: #232323;*/
    /*}*/

    .modal-component__inner{
      max-width: 415px;
    }

    .modal-component__content{
      padding: 50px;

      @include for-550{
        padding: 25px;
      }
    }

    .payment-block__inner{
      padding: 0;
    }

    .payment-block__currently:first-child,
    .payment-block__description,
    .payment-block__name{
      display: none;
    }

    &__subtitle{
      margin-top: -5px;
      margin-bottom: 24px;
    }

    .payment-block__input{
      max-width: 100%;
    }

    .modal-component__body{
      margin-bottom: 0;
    }

    .main-button{
      width: 100%;
      max-width: 100%;
    }

    .btn-close.modal-component__close{
      display: none;
    }

    .payment-block {
      background: transparent;
      box-shadow: none;
    }

  }
</style>
