
import {routerPaths} from '../../../router/routerPaths'

const ExportRequestsTable = () => import(/* webpackChunkName: "group-export-requests" */ './components/ExportRequestsTable/ExportRequestsTable.vue')
const moduleKey = 'export-requests'


export const ExportRequestsRoutes = [
  {
    path: routerPaths.exportRequests,
    component: ExportRequestsTable,
    meta: {
      breadcrumbs: {
        key: [
          'menu_settings',
          'mainSettingsExportRequests'
        ],
        items: {
          'menu_settings': {
            name: 'menu_settings',
            link: routerPaths.exportRequests,
          },
          'mainSettingsExportRequests': {
            name: 'menu_exportRequests',
            link: routerPaths.exportRequests,
          },
        },
      },


      moduleKey: moduleKey,
      pageTitle: 'menu_exportRequests'
    },
  }
]
