<template>
  <div class="notification-fixed">
    <notifications group="common"
                   position="bottom right"
                   class="notification-fixed__list"
    >
      <template slot="body" slot-scope="props">
        <div class="notification-fixed__item success"
             v-if="props.item.type === 'success'"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit"
                  @click="editionalTranslate(props.item.text)"></span>
          <div>
            <div class="notification-fixed__close"
                 @click="props.close" aria-label="close modal"
            ></div>
            <div class="notification-fixed__item-ico">
              <SuccessNotification/>
            </div>
            <div class="notification-fixed__item-title">
              {{$t('common_success.localization_value.value')}}
            </div>
            <div class="notification-fixed__item-description">
              <template v-if="Object.hasOwnProperty.call(props.item.text, 'txtServer')">
                <span v-html="props.item.text.txtServer"></span>
              </template>
              <template v-else>
                {{$t(props.item.text + '.localization_value.value')}}
              </template>
            </div>
          </div>
        </div>

        <div class="notification-fixed__item success"
             v-if="props.item.type === 'chat'"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit"
                  @click="editionalTranslate(props.item.text)"></span>
          <div>
            <div class="notification-fixed__close"
                 @click="props.close" aria-label="close modal"
            ></div>
            <div class="notification-fixed__item-ico">
              <SuccessNotification/>
            </div>
            <div class="notification-fixed__item-title">
              {{$t('common_chat.localization_value.value')}}
            </div>
            <div class="notification-fixed__item-description">
              New message in  <router-link :to="props.item.text.chatLink" class="fsz18 site-link btn-style">chat</router-link>
            </div>
          </div>
        </div>

        <div class="notification-fixed__item success"
             v-if="props.item.type === 'exportFile'"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit"
                  @click="editionalTranslate(props.item.text)"></span>
          <div>
            <div class="notification-fixed__close"
                 @click="props.close" aria-label="close modal"
            ></div>
            <div class="notification-fixed__item-ico">
              <SuccessNotification/>
            </div>
            <div class="notification-fixed__item-title">
              {{$t('common_exportFile.localization_value.value')}}
            </div>
            <div class="notification-fixed__item-description">
              <span @click="props.item.text.downloadFile" class="fsz18 site-link btn-style">Download file</span>
            </div>
          </div>
        </div>

        <div class="notification-fixed__item error"
             v-if="props.item.type === 'error'"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit"
                  @click="editionalTranslate(props.item.text)"></span>
          <div class="notification-fixed__close"
               @click="props.close" aria-label="close modal"
          ></div>
          <div class="notification-fixed__item-title">
            {{$t('common_notificationError.localization_value.value')}}
          </div>
          <div class="notification-fixed__item-description">
            <LinkButton
                style="margin-bottom: -10px;margin-left: -20px;"
                :type="'copy'"
                @click.native="copyError(props)"
            />
            <template v-if="Object.hasOwnProperty.call(props.item.text, 'txtServer')">
             <span v-html="props.item.text.txtServer"></span>
            </template>
            <template v-else>
              {{$t(props.item.text + '.localization_value.value')}}
            </template>
          </div>
        </div>

        <div class="notification-fixed__item warn"
             v-if="props.item.type === 'warn'"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit"
                  @click="editionalTranslate(props.item.text)"></span>
          <div class="notification-fixed__close"
               @click="props.close" aria-label="close modal"
          ></div>
          <div class="notification-fixed__item-title">
            {{$t('common_notificationWarning.localization_value.value')}}
          </div>
          <div class="notification-fixed__item-description">
            <template v-if="Object.hasOwnProperty.call(props.item.text, 'txtServer')">
              <span v-html="props.item.text.txtServer"></span>
            </template>
            <template v-else>
              {{$t(props.item.text + '.localization_value.value')}}
            </template>
          </div>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script>
  import SuccessNotification from '../../../../public/img/common/success-notification.svg?inline'
  import LinkButton from "@/components/UI/buttons/LinkButton/LinkButton";

  export default {
    name: "NotificationFixed",
    components: {
      LinkButton,
      SuccessNotification,
    },

    data () {
      return {
        show: false,
      }
    },

    methods: {
      editionalTranslate(txt) {
        let arrayToTranslate = ['common_success', 'common_notificationError']
        if(txt.length > 0) {
          arrayToTranslate.push(txt)
        }
        this.editTranslate(arrayToTranslate)
      },

      copyError(props) {
        if(Object.hasOwnProperty.call(props.item.text, 'txtServer')) {
          this.copyToClipboard(props.item.text.txtServer)
        } else {
          this.copyToClipboard(this.$t(props.item.text + '.localization_value.value'))
        }

      },
    },

    mounted() {
    }

  }
</script>

<style lang="scss">
  @import "../../../scss/colors";
  @import "../../../scss/mixins/mixins";

  .notification-fixed{
    /*position: fixed;*/
    /*right: 0;*/
    /*bottom: 0;*/
    /*padding: 0 10px;*/
    /*max-width: 436px;*/
    /*width: 100%;*/
    .admin-edit {
      top: 0 !important;
    }

    &__list{
      /*display: flex;*/
      /*flex-direction: column;*/
      /*width: 100%;*/
      width: 100% !important;
      max-width: 436px;
    }

    &__item{
      background: white;
      position: relative;
      margin-bottom: 5px;
      display: flex;
      flex-direction: column;
      padding: 35px 30px 35px 0;
      width: 100%;

      @include for-768{
        padding: 24px 15px 24px 0;
        width: 100%;
      }


      &.success{
        padding-left: 35px;
      }

      &.error{
        background: #fbe8e7;
      }

      &.warn {
        background: #fcf1ca;
      }
    }

    &__close{
      width: 30px;
      height: 30px;
      right: 5px;
      top: 5px;
      background: red;
      position: absolute;
      cursor: pointer;
      transition: .3s;
      background: url("../../../assets/img/UI-group/close-ico.svg") center/15px no-repeat;
      opacity: 1;

      &:hover{
        opacity: .5;
      }
    }

    &__item-ico{
      position: absolute;
      left: 30px;
      top: 35px;

      @include for-768{
        left: 15px;
        top: 24px;
      }
    }

    &__item-title{
      font-weight: bold;
      font-size: 32px;
      line-height: 37px;
      color: $accent;
      margin-bottom: 12px;
      padding-left: 45px;

      @include for-768{
        padding-left: 25px;
      }
    }

    &__item-description{
      font-size: 18px;
      line-height: 26px;
      color: $black;
      padding-left: 45px;

      @include for-768{
        padding-left: 25px;
      }
    }

  }


</style>
