
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const OrdersFBATable = () => import(/* webpackChunkName: "group-fba" */ './components/OrdersFBATable/OrdersFBATable.vue')
const OrdersFBACreation = () => import(/* webpackChunkName: "group-fba" */ './components/OrdersFBACreation/OrdersFBACreation.vue')
const OrdersFBAEdit = () => import(/* webpackChunkName: "group-fba" */ './components/OrdersFBAEdit/OrdersFBAEdit.vue')
const OrdersFBAShow = () => import(/* webpackChunkName: "group-fba" */ './components/OrdersFBAShow/OrdersFBAShow.vue')
const moduleKey = 'orders-fba'


export const OrdersFBARoutes = [
  {
    path: routerPaths.ordersFBA,
    component: OrdersFBATable,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'ordersFBA'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersFBA,
          },
          'ordersFBA': {
            name: 'menu_ordersFBA',
            link: routerPaths.ordersFBA,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_FBA_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_ordersFBA'
    },
  },
  {
    path: routerPaths.ordersFBACreation,
    component: OrdersFBACreation,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'ordersFBA',
          'ordersFBACreation',
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersFBA,
          },
          'ordersFBA': {
            name: 'menu_ordersFBA',
            link: routerPaths.ordersFBA,
          },
          'ordersFBACreation': {
            name: 'breadcrumbs_NewOrderFBA',
            link: routerPaths.ordersFBA,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_FBA_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewOrderFBA'
    },
  },
  {
    path: routerPaths.ordersFBAEditing,
    component: OrdersFBAEdit,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'ordersFBA',
          'ordersFBAEdit',
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.dashboard,
          },
          'ordersFBA': {
            name: 'menu_ordersFBA',
            link: routerPaths.ordersFBA,
          },
          'ordersFBAEdit': {
            name: 'breadcrumbs_EditOrderFBA',
            link: routerPaths.ordersFBA,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_EditOrderFBA'
    },
  },
  {
    path: routerPaths.ordersFBAShow,
    component: OrdersFBAShow,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'ordersFBA',
          'ordersFBAEdit',
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.dashboard,
          },
          'ordersFBA': {
            name: 'menu_ordersFBA',
            link: routerPaths.ordersFBA,
          },
          'ordersFBAEdit': {
            name: 'breadcrumbs_OrderFBA',
            link: routerPaths.ordersFBA,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_FBA_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_OrderFBA'
    },
  },
]
