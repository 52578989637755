<template>
  <modal
      @close="$emit('close')"
      class="custom-popup guide-edit-popup"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'guide_guideEdit',
          ])"></div>
        {{ $t('guide_guideEdit.localization_value.value') }}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">

        <div class="custom-row">

<!--          <div class="custom-col custom-col-md&#45;&#45;100"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate([-->
<!--          'guide_key',-->
<!--          ])"></div>-->
<!--            <DefaultInput-->
<!--                :label="$t('guide_key.localization_value.value')"-->
<!--                v-model="guideItem.data.chapterKey"-->
<!--                :disabled="true"-->
<!--            />-->
<!--&lt;!&ndash;            {{$store.getters.GET_LANG}}&ndash;&gt;-->
<!--          </div>-->
          <div class="custom-col custom-col-md--100"
               v-if="!hideVideo"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
          'guide_videoLink',
          ])"></div>
            <DefaultInput
                :label="$t('guide_videoLink.localization_value.value')"
                v-model="guideItem.data.linkVideo"
            />
          </div>
          <div class="custom-col custom-col-md--100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
          'guide_linkEng',
          ])"></div>
            <DefaultInput
                :label="$t('guide_linkEng.localization_value.value')"
                v-model="guideItem.data.linkEn"
            />
          </div>
          <div class="custom-col custom-col-md--100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
          'guide_linkUa',
          ])"></div>
            <DefaultInput
                :label="$t('guide_linkUa.localization_value.value')"
                v-model="guideItem.data.linkUa"
            />
          </div>
          <div class="custom-col custom-col-md--100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
          'guide_linkRu',
          ])"></div>
            <DefaultInput
                :label="$t('guide_linkRu.localization_value.value')"
                v-model="guideItem.data.linkRu"
            />
          </div>
          <div class="custom-col custom-col-md--100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
          'guide_linkLv',
          ])"></div>
            <DefaultInput
                :label="$t('guide_linkLv.localization_value.value')"
                v-model="guideItem.data.linkLv"
            />
          </div>
          <div class="custom-col custom-col-md--100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
          'guide_linkPl',
          ])"></div>
            <DefaultInput
                :label="$t('guide_linkPl.localization_value.value')"
                v-model="guideItem.data.linkPl"
            />
          </div>
          <div class="custom-col custom-col-md--100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
          'guide_textEng',
          ])"></div>
            <div style="font-weight: bold;" class="mb-2 fsz14">{{$t('guide_textEng.localization_value.value')}}</div>
            <TextEditor
                :value="guideItem.data.textEn"
                @input="inputTextEditorEn"
            />
          </div>
          <div class="custom-col custom-col-md--100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
          'guide_textUa',
          ])"></div>
            <div style="font-weight: bold;" class="mb-2 fsz14">{{$t('guide_textUa.localization_value.value')}}</div>
            <TextEditor
                :value="guideItem.data.textUa"
                @input="inputTextEditorUa"
            />
          </div>
          <div class="custom-col custom-col-md--100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
          'guide_textRu',
          ])"></div>
            <div style="font-weight: bold;" class="mb-2 fsz14">{{$t('guide_textRu.localization_value.value')}}</div>
            <TextEditor
                :value="guideItem.data.textRu"
                @input="inputTextEditorRu"
            />
          </div>
          <div class="custom-col custom-col-md--100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
          'guide_textLv',
          ])"></div>
            <div style="font-weight: bold;" class="mb-2 fsz14">{{$t('guide_textLv.localization_value.value')}}</div>
            <TextEditor
                :value="guideItem.data.textLv"
                @input="inputTextEditorLv"
            />
          </div>
          <div class="custom-col custom-col-md--100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
          'guide_textPl',
          ])"></div>
            <div style="font-weight: bold;" class="mb-2 fsz14">{{$t('guide_textPl.localization_value.value')}}</div>
            <TextEditor
                :value="guideItem.data.textPl"
                @input="inputTextEditorPl"
            />
          </div>

        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex flex-wrap mt-5">
        <span
        class="site-link site-link--alt custom-popup__btn-i mr-4 mt-3 mb-3"
        @click="$emit('close')"
        >
        {{$t('common_cancel.localization_value.value')}}
        </span>
        <MainButton
            class="custom-popup__btn-i "
            :value="edit ? $t('common_save.localization_value.value') : $t('common_create.localization_value.value')"
            @click.native="$emit('save', edit)"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "../../../commonModals/Modal";
import MainButton from "../../../UI/buttons/MainButton/MainButton";
import DefaultInput from "../../../UI/inputs/DefaultInput/DefaultInput";
import TextEditor from "../../TextEditor/TextEditor";
export default {
  name: "GuideEditPopup",
  components: {
    TextEditor,
    DefaultInput,
    MainButton,
    Modal,
  },

  props: {
    guideItem: Object,
    edit: Boolean,
    hideVideo: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    inputTextEditorEn(val) {
      this.guideItem.setTextEn(val)
    },
    inputTextEditorRu(val) {
      this.guideItem.setTextRu(val)
    },
    inputTextEditorUa(val) {
      this.guideItem.setTextUa(val)
    },
    inputTextEditorLv(val) {
      this.guideItem.setTextLv(val)
    },
    inputTextEditorPl(val) {
      this.guideItem.setTextPl(val)
    },
  }
}
</script>

<style>

</style>