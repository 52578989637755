<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup history-of-changes-popup"
  >
    <template slot="header">
      <div
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_HistoryOfChanges',])"></div>
        {{$t('common_HistoryOfChanges.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <HistoryOfChangesTable
            v-if="historyChanges.length > 0"
            :type="type"
            :historyChanges="historyChanges"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "../../../commonModals/Modal";
import HistoryOfChangesTable
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/components/HistoryOfChangesTable/HistoryOfChangesTable";

export default {
  name: "HistoryOfChanges",
  components: {
    HistoryOfChangesTable,
    Modal,
  },

  props: {
    type: {
      type: String,
      default: null
    },
    uuid: String,
  },

  data() {
    return {
      historyChanges: []
    }
  },

  mounted() {
    // console.log(this.type);
    // console.log(this.uuid);
    this.$store.dispatch('getHistoryChanges', this.uuid).then(response => {
      this.historyChanges = this.getRespData(response)?.activities
    })
  },

  methods: {}
}
</script>

<style lang="scss">

.history-of-changes-popup {

  .modal-component__inner {
    max-width: 649px;
  }
}


</style>
