import {API} from "../../axios/axiosMainUrl";

const prefix = '/warehouse/storage/document'

export const storageDocuments = {
  state: {
    storageDocuments: [],
    storageDocumentsItem: {},
    storageDocumentsCommonList: [],
    storageDocumentsBtn: false,
    storageDocumentsLoading: true,
    nextStorageDocumentsPage: false,
    storageDocumentsForPage: 25,
    storageDocumentsFilter: '',
  },

  mutations: {
    setStorageDocumentsFilter: (state, payload) => {
      state.storageDocumentsFilter = payload
    },

    setStorageDocuments: (state, payload) => {
      if(state.nextStorageDocumentsPage) {
        state.storageDocuments = state.storageDocuments.concat(payload.storageDocuments)
      } else {
        state.storageDocuments = payload.storageDocuments
      }
    },

    setStorageDocumentsCommonList: (state, payload) => {
      state.storageDocumentsCommonList = payload.storageDocumentsCommonList
    },

    setNextStorageDocumentsPage: (state, payload) => {
      state.nextStorageDocumentsPage = payload
    },

    setStorageDocumentsItem: (state, payload) => {
      state.storageDocumentsItem = payload.storageDocumentsItem
    },

    setStorageDocumentsLoadingStart: (state) => {
      state.storageDocumentsLoading = true
    },

    setStorageDocumentsLoadingEnd: (state) => {
      state.storageDocumentsLoading = false
    },

    changeStorageDocumentsBtn: (state) => {
      state.storageDocumentsBtn = true
    },
    successStorageDocumentsBtn: (state) => {
      state.storageDocumentsBtn = false
    },
  },

  getters: {
    getStorageDocumentsFilter: state => {
      return state.storageDocumentsFilter
    },

    getStorageDocumentsForPage: state => {
      return state.storageDocumentsForPage
    },

    getStorageDocuments: state => {
      return state.storageDocuments
    },

    getStorageDocumentsLoading: state => {
      return state.storageDocumentsLoading
    },


    getNextStorageDocumentsPage: state => {
      return state.nextStorageDocumentsPage
    },

    getStorageDocumentsItem: state => {
      return state.storageDocumentsItem
    },

    getStorageDocumentsCommonList: state => {
      return state.storageDocumentsCommonList
    },

    getStorageDocumentsBtn: state => {
      return state.storageDocumentsBtn
    },
  },

  actions: {

    async fetchStorageDocuments({commit, getters}, filter = '') {
      if(!getters.getNextStorageDocumentsPage)
        commit('setStorageDocumentsLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setStorageDocuments',{storageDocuments: response.data.data.data})
          commit('setStorageDocumentsCommonList',{storageDocumentsCommonList: response.data.data})
          commit('setStorageDocumentsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setStorageDocumentsLoadingEnd');
        return e;
      }
    },

    async fetchDisposalOrders({commit, getters}, filter = '') {
      if(!getters.getNextStorageDocumentsPage)
        commit('setStorageDocumentsLoadingStart')
      try {
        return await API.get(`${prefix}/disposal${filter}`).then((response) =>{
          commit('setStorageDocuments',{storageDocuments: response.data.data.data})
          commit('setStorageDocumentsCommonList',{storageDocumentsCommonList: response.data.data})
          commit('setStorageDocumentsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setStorageDocumentsLoadingEnd');
        return e;
      }
    },

    async getStorageDocuments({commit}, id) {
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setStorageDocumentsItem', {storageDocumentsItem: response.data.data})
          commit('setStorageDocumentsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setStorageDocumentsLoadingEnd');
        return e;
      }
    },

    async getStorageDocumentsDisposal({commit}, id) {
      try {
        return await API.get(`${prefix}/disposal/${id}`).then((response) =>{
          console.log(response)
          commit('setStorageDocumentsItem', {storageDocumentsItem: response.data.data})
          commit('setStorageDocumentsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setStorageDocumentsLoadingEnd');
        return e;
      }
    },

    async getStorageDocumentsEdit({commit}, id) {
      try {
        return await API.get(`${prefix}/${id}/edit`).then((response) =>{
          console.log(response)
          commit('setStorageDocumentsItem', {storageDocumentsItem: response.data.data})
          commit('setStorageDocumentsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setStorageDocumentsLoadingEnd');
        return e;
      }
    },

    async createStorageDocuments({commit}, data) {
      commit('changeStorageDocumentsBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successStorageDocumentsBtn');
          return response;
        });
      } catch (e) {
        commit('successStorageDocumentsBtn');
        return e;
      }
    },

    async createInventoryRequest({commit}, data) {
      commit('changeStorageDocumentsBtn');
      try {
        return await API.post(`${prefix}/inventory-request`, data).then((response) =>{
          commit('successStorageDocumentsBtn');
          return response;
        });
      } catch (e) {
        commit('successStorageDocumentsBtn');
        return e;
      }
    },

    async createDisposalRequest({commit}, data) {
      commit('changeStorageDocumentsBtn');
      try {
        return await API.post(`${prefix}/disposal-request`, data).then((response) =>{
          commit('successStorageDocumentsBtn');
          return response;
        });
      } catch (e) {
        commit('successStorageDocumentsBtn');
        return e;
      }
    },

    async updateStorageDocuments({commit}, {id, data}) {
      commit('changeStorageDocumentsBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successStorageDocumentsBtn');
          return response;
        });
      } catch (e) {
        commit('successStorageDocumentsBtn');
        return e;
      }
    },

    async updateDisposalRequest({commit}, {id, data}) {
      commit('changeStorageDocumentsBtn');
      try {
        return await API.put(`${prefix}/disposal-request/${id}`, data).then((response) =>{
          commit('successStorageDocumentsBtn');
          return response;
        });
      } catch (e) {
        commit('successStorageDocumentsBtn');
        return e;
      }
    },

    async deleteStorageDocuments({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successStorageDocumentsBtn');
        return e;
      }
    },

  }
}
