import {API} from "../../axios/axiosMainUrl";

const prefix = '/marketplace-shop-settings'

export const marketplaceSettings = {
  state: {
    marketplaceSettings: [],
    marketplaceSettingsItem: {},
    marketplaceSettingsCommonList: [],
    marketplaceSettingsBtn: false,
    marketplaceSettingsLoading: true,
    nextMarketplaceSettingsPage: false,
    marketplaceSettingsForPage: 25,
    marketplaceSettingsFilter: '',
  },

  mutations: {
    setMarketplaceSettingsFilter: (state, payload) => {
      state.marketplaceSettingsFilter = payload
    },

    setMarketplaceSettings: (state, payload) => {
      if(state.nextMarketplaceSettingsPage) {
        state.marketplaceSettings = state.marketplaceSettings.concat(payload.marketplaceSettings)
      } else {
        state.marketplaceSettings = payload.marketplaceSettings
      }
    },

    setMarketplaceSettingsCommonList: (state, payload) => {
      state.marketplaceSettingsCommonList = payload.marketplaceSettingsCommonList
    },

    setNextMarketplaceSettingsPage: (state, payload) => {
      state.nextMarketplaceSettingsPage = payload
    },

    setMarketplaceSettingsItem: (state, payload) => {
      state.marketplaceSettingsItem = payload.marketplaceSettingsItem
    },

    setMarketplaceSettingsLoadingStart: (state) => {
      state.marketplaceSettingsLoading = true
    },

    setMarketplaceSettingsLoadingEnd: (state) => {
      state.marketplaceSettingsLoading = false
    },

    changeMarketplaceSettingsBtn: (state) => {
      state.marketplaceSettingsBtn = true
    },
    successMarketplaceSettingsBtn: (state) => {
      state.marketplaceSettingsBtn = false
    },
  },

  getters: {
    getMarketplaceSettingsFilter: state => {
      return state.marketplaceSettingsFilter
    },

    getMarketplaceSettingsForPage: state => {
      return state.marketplaceSettingsForPage
    },

    getMarketplaceSettings: state => {
      return state.marketplaceSettings
    },

    getMarketplaceSettingsLoading: state => {
      return state.marketplaceSettingsLoading
    },


    getNextMarketplaceSettingsPage: state => {
      return state.nextMarketplaceSettingsPage
    },

    getMarketplaceSettingsItem: state => {
      return state.marketplaceSettingsItem
    },

    getMarketplaceSettingsCommonList: state => {
      return state.marketplaceSettingsCommonList
    },

    getMarketplaceSettingsBtn: state => {
      return state.marketplaceSettingsBtn
    },
  },

  actions: {

    async fetchMarketplaceSettings({commit, getters}, filter = '') {
      if(!getters.getNextMarketplaceSettingsPage)
        commit('setMarketplaceSettingsLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setMarketplaceSettings',{marketplaceSettings: response.data.data})
          commit('setMarketplaceSettingsCommonList',{marketplaceSettingsCommonList: response.data})
          commit('setMarketplaceSettingsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setMarketplaceSettingsLoadingEnd');
        return e;
      }
    },

    async getMarketplaceSettings({commit}, id) {
      commit('setMarketplaceSettingsLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setMarketplaceSettingsItem', {marketplaceSettingsItem: response.data.data})
          commit('setMarketplaceSettingsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setMarketplaceSettingsLoadingEnd');
        return e;
      }
    },

    async updateMarketplaceSettings({commit}, {id, data}) {
      commit('changeMarketplaceSettingsBtn');
      try {
        return await API.post(`${prefix}/${id}`, data).then((response) =>{
          commit('successMarketplaceSettingsBtn');
          return response;
        });
      } catch (e) {
        commit('successMarketplaceSettingsBtn');
        return e;
      }
    },

    async deleteMarketplaceSettings({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successMarketplaceSettingsBtn');
        return e;
      }
    },

    async syncMarketplaceSettings({commit}, id) {
      commit('changeMarketplaceSettingsBtn');
      try {
        return await API.get(`${prefix}/${id}/sync-reviews`).then((response) =>{
          commit('successMarketplaceSettingsBtn');
          return response;
        });
      } catch (e) {
        commit('successMarketplaceSettingsBtn');
        return e;
      }
    },

    async addPhotoToMarketplaceSettings({commit}, {id, data}) {
      commit('changeMarketplaceSettingsBtn');
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${prefix}/${id}/upload-images`, data).then((response) =>{
          commit('successMarketplaceSettingsBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successMarketplaceSettingsBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

  }
}
