import {routerPaths} from '../../../router/routerPaths'
import permissions from "@/router/middleware/permissions";
import {PERMISSIONS} from "@/staticData/permissionsStatic";

const BalancePayoneerHistoryTable = () => import(/* webpackChunkName: "group-balance-history-payoneer" */ './components/BalancePayoneerHistoryTable/BalancePayoneerHistoryTable.vue')
const moduleKey = 'balance-history'

export const BalancePayoneerHistoryRoutes = [
  {
    path: routerPaths.balancePayoneerHistory,
    component: BalancePayoneerHistoryTable,
    meta: {
      breadcrumbs: {
        key: [
          'balancePayoneerHistory',
        ],
        items: {
          'balancePayoneerHistory': {
            name: 'breadcrumbs_balancePayoneerHistory',
            link: routerPaths.balancePayoneerHistory,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.CUSTOMER_PAYMENT_PAYONEER],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_balancePayoneerHistory'
    },
  },
]


