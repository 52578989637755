<template>

  <div class="fragment">

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_user.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user.user_personal_contact.email'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user.user_personal_contact.email'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_userAdmin.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.admin_user.user_personal_contact.email'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.admin_user.user_personal_contact.email'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('fillClaim_TrackingNumber.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.tracking_number'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.tracking_number'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('fillClaim_ReasonToClaim.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper v-if="pevItem && pevItem.history" :value="pevItem" :deep="'history.params_data.reasons.' + pevItem.history.reason"/></div>
      <div class="history-table__sub-col"><ValueHelper v-if="item" :value="item" :deep="'history.params_data.reasons.' + item.history.reason"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('fillClaim_ShipDate.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.shipping_date'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.shipping_date'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('fillClaim_Value.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.declared_value'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.declared_value'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('fillClaim_CloseDate.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.close_date'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.close_date'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('fillClaim_ResultStatuses.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper v-if="pevItem && pevItem.history" :value="pevItem" :deep="'history.params_data.status_results.' + pevItem.history.status_result"/></div>
      <div class="history-table__sub-col"><ValueHelper v-if="item" :value="item" :deep="'history.params_data.status_results.' + item.history.status_result"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('fillClaim_AdminCompensations.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.compensation_value'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.compensation_value'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('fillClaim_ClaimStatuses.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper v-if="pevItem && pevItem.history" :value="pevItem" :deep="'history.params_data.statuses.' + pevItem.history.status"/></div>
      <div class="history-table__sub-col"><ValueHelper v-if="item" :value="item" :deep="'history.params_data.statuses.' + item.history.status"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_comment.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.comment'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.comment'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('fillClaim_CommentAdmin.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.admin_comment'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.admin_comment'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('fillClaim_shippingCompany.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.delivery_service.name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.delivery_service.name'"/></div>
    </div>


    <div class="history-table__sub-row" v-if="(pevItem && pevItem.history && _.find(pevItem.history.files, {type: 'claimAdminFile'})) || _.find(item.history.files, {type: 'claimAdminFile'})">
      <div class="history-table__sub-col">File Admin</div>
      <div class="history-table__sub-col align-items-start flex-column">
        <template v-if="pevItem && pevItem.history">
          <template v-for="(file, indexFile) in pevItem.history.files" >
            <template v-if="!_.find(pevItem.history.files, {type: 'claimAdminFile'})">—</template>
            <template v-else-if="file.type === 'claimAdminFile'">
              <FileBlock class="mb-2" :files="pevItem.history.files" :key="indexFile" :file="file"/>
            </template>
          </template>
        </template>
        <template v-else>—</template>
      </div>
      <div class="history-table__sub-col align-items-start flex-column">
        <template v-for="(file, indexFile) in item.history.files" >
          <template v-if="file.type === 'claimAdminFile'">
            <FileBlock class="mb-2" :files="item.history.files" :key="indexFile + 'file'" :file="file"/>
            <!--            <template v-else>—</template>-->
          </template>
        </template>
      </div>
    </div>

    <div class="history-table__sub-row" v-if="(pevItem && pevItem.history && _.find(pevItem.history.files, {type: 'claimUserFile'})) || _.find(item.history.files, {type: 'claimUserFile'})">
      <div class="history-table__sub-col">File User</div>
      <div class="history-table__sub-col align-items-start flex-column">
        <template v-if="pevItem && pevItem.history && _.find(pevItem.history.files, {type: 'claimUserFile'})">
          <template v-for="(file, indexFile) in pevItem.history.files" >

            <template v-if="!_.find(pevItem.history.files, {type: 'claimUserFile'})">—</template>
            <template v-else-if="file.type === 'claimUserFile'">
              <FileBlock class="mb-2" :files="pevItem.history.files" :key="indexFile" :file="file"/>
            </template>

          </template>
        </template>
        <template v-else>—</template>
      </div>
      <div class="history-table__sub-col align-items-start flex-column">
        <template v-for="(file, indexFile) in item.history.files" >
          <template v-if="file.type === 'claimUserFile'">
            <FileBlock class="mb-2" :files="item.history.files"  :key="indexFile + 'file'" :file="file"/>
            <!--            <template v-else>—</template>-->
          </template>
        </template>
      </div>
    </div>


  </div>
</template>

<script>
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import FileBlock from "@/components/coreComponents/Popups/HistoryOfChangesPopup/chunks/FileBlock/FileBlock";
export default {
  name: "FillClaimType",

  components: {FileBlock, ValueHelper},

  props: {
    pevItem: Object,
    item: Object,
  },

}
</script>

<style scoped>

</style>