import {API} from "../../axios/axiosMainUrl";

export const products = {
  state: {
    products: [],
    productsItem: {},
    productsCommonList: [],
    productsBtn: false,
    productsLoading: true,
    nextProductsPage: false,
    productsForPage: 25,
    productsFilter: '',
  },

  mutations: {
    setProductsFilter: (state, payload) => {
      state.productsFilter = payload
    },

    setProducts: (state, payload) => {
      if(state.nextProductsPage) {
        state.products = state.products.concat(payload.products)
      } else {
        state.products = payload.products
      }
    },

    setProductsCommonList: (state, payload) => {
      state.productsCommonList = payload.productsCommonList
    },

    setNextProductsPage: (state, payload) => {
      state.nextProductsPage = payload
    },

    setProductsItem: (state, payload) => {
      state.productsItem = payload.productsItem
    },

    setProductsLoadingStart: (state) => {
      state.productsLoading = true
    },

    setProductsLoadingEnd: (state) => {
      state.productsLoading = false
    },

    changeProductsBtn: (state) => {
      state.productsBtn = true
    },
    successProductsBtn: (state) => {
      state.productsBtn = false
    },
  },

  getters: {
    getProductsFilter: state => {
      return state.productsFilter
    },

    getProductsForPage: state => {
      return state.productsForPage
    },

    getProducts: state => {
      return state.products
    },

    getProductsLoading: state => {
      return state.productsLoading
    },


    getNextProductsPage: state => {
      return state.nextProductsPage
    },

    getProductsItem: state => {
      return state.productsItem
    },

    getProductsCommonList: state => {
      return state.productsCommonList
    },

    getProductsBtn: state => {
      return state.productsBtn
    },
  },

  actions: {

    async fetchProducts({commit}, filter = '') {
      // if(!getters.getNextProductsPage)
      //   commit('setProductsLoadingStart')
      try {
        return await API.get(`/products/public-list${filter}`).then((response) =>{
          // commit('setProducts',{products: response.data.data.data})
          // commit('setProductsCommonList',{productsCommonList: response.data.data})
          // commit('setProductsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setProductsLoadingEnd');
        return e;
      }
    },

    async fetchProductsFBMReturns({commit}, id = '') {
      // if(!getters.getNextProductsPage)
      //   commit('setProductsLoadingStart')
      try {
        return await API.get(`/products/return-public-list/${id}`).then((response) =>{
          // commit('setProducts',{products: response.data.data.data})
          // commit('setProductsCommonList',{productsCommonList: response.data.data})
          // commit('setProductsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setProductsLoadingEnd');
        return e;
      }
    },

    async fetchProductsWithPermissions({commit, getters}, filter = '') {
      if(!getters.getNextProductsPage)
        commit('setProductsLoadingStart')
      try {
        return await API.get(`/products${filter}`).then((response) =>{
          commit('setProducts',{products: response.data.data.data})
          commit('setProductsCommonList',{productsCommonList: response.data.data})
          commit('setProductsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setProductsLoadingEnd');
        return e;
      }
    },

    async fetchProductsWithCells({commit}, filter = '') {
      // if(!getters.getNextProductsPage)
      //   commit('setProductsLoadingStart')
      try {
        return await API.get(`/products/user-products${filter}`).then((response) =>{
          // commit('setProducts',{products: response.data.data.data})
          // commit('setProductsCommonList',{productsCommonList: response.data.data})
          // commit('setProductsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setProductsLoadingEnd');
        return e;
      }
    },

    async getProducts({commit}, id) {
      try {
        return await API.get(`/products/${id}`).then((response) =>{
          commit('setProductsItem', {productsItem: response.data.data})
          commit('setProductsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setProductsLoadingEnd');
        return e;
      }
    },

    async createProducts({commit}, data) {
      commit('changeProductsBtn');
      try {
        return await API.post(`/products`, data).then((response) =>{
          commit('successProductsBtn');
          return response;
        });
      } catch (e) {
        commit('successProductsBtn');
        return e;
      }
    },

    async addPhotoToProduct({commit}, data) {
      commit('changeProductsBtn');
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`/products/upload-files`, data).then((response) =>{
          commit('successProductsBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successProductsBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async updateProducts({commit}, {id, data}) {
      commit('changeProductsBtn');
      try {
        return await API.put(`/products/${id}`, data).then((response) =>{
          commit('successProductsBtn');
          return response;
        });
      } catch (e) {
        commit('successProductsBtn');
        return e;
      }
    },

    async archiveProduct({commit}, id) {
      commit('changeProductsBtn');
      try {
        return await API.get(`/products/${id}/archived-toggle`).then((response) =>{
          commit('successProductsBtn');
          return response;
        });
      } catch (e) {
        commit('successProductsBtn');
        return e;
      }
    },

    async getProductMedia({commit}, id) {
      try {
        return await API.get(`/products/${id}/product-media`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successProductsBtn');
        return e;
      }
    },

    async deleteProducts({commit}, id) {
      try {
        return await API.delete(`/products/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successProductsBtn');
        return e;
      }
    },


    async getProductsCondition({commit}) {
      try {
        return await API.get(`/products/condition`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successProductsBtn');
        return e;
      }
    },

    async massStoreProducts({commit}, data) {
      commit('changeProductsBtn');
      try {
        return await API.post(`/products/mass-store`, data).then((response) =>{
          commit('successProductsBtn');
          return response;
        });
      } catch (e) {
        commit('successProductsBtn');
        return e;
      }
    },

    async massUpdateProducts({commit}, data) {
      commit('changeProductsBtn');
      try {
        return await API.put(`/products/mass-update`, data).then((response) =>{
          commit('successProductsBtn');
          return response;
        });
      } catch (e) {
        commit('successProductsBtn');
        return e;
      }
    },

    async thermalPrint({commit}, data) {
      commit('changeProductsBtn');
      try {
        return await API.post(`/products/custom-thermal/print`, data).then((response) =>{
          commit('successProductsBtn');
          return response;
        });
      } catch (e) {
        commit('successProductsBtn');
        return e;
      }
    },

    async massThermalPrint({commit}, data) {
      commit('changeProductsBtn');
      try {
        return await API.post(`/products/mass-thermal-print`, data).then((response) =>{
          commit('successProductsBtn');
          return response;
        });
      } catch (e) {
        commit('successProductsBtn');
        return e;
      }
    },

    async getExportProducts({commit}, {filter, exportType}) {
      commit('setExportBtnLoadingStart')
      commit('setLoadingEnd')
      try {
        return await API.get(`/products/export/${exportType}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

    async getImportProducts({commit}, data) {
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`/products/import`, data).then((response) =>{
          commit('successProductsBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successProductsBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async getImportExampleXLSXProducts({commit}) {
      commit('setLoadingEnd')
      try {
        return await API.get(`/products/import-example-xlsx`, { responseType: 'blob' }).then((response) =>{
          return response
        })
      } catch (e) {
        return e;
      }
    },

    async fetchProductsForProformSearch({commit}, filter = '') {
      commit('setProductsLoadingStart')
      try {
        return await API.get(`/products/proform-search/name${filter}`).then((response) =>{
          commit('setProductsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setProductsLoadingEnd');
        return e;
      }
    },


    async fetchSpecialProducts({commit}, filter = '') {
      try {
        return await API.get(`/products/special-public-list${filter}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setProductsLoadingEnd');
        return e;
      }
    },

  }
}
