
import {routerPaths} from '../../../router/routerPaths'
// import permissions from "../../../router/middleware/permissions";
// import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const HeartysanCategoryTable = () => import(/* webpackChunkName: "group-heartysan" */ './components/HeartysanCategoryTable/HeartysanCategoryTable.vue')
const HeartysanCategoryCreation = () => import(/* webpackChunkName: "group-heartysan-create" */ './components/HeartysanCategoryCreation/HeartysanCategoryCreation.vue')
const HeartysanCategoryEditing = () => import(/* webpackChunkName: "group-heartysan-edit" */ './components/HeartysanCategoryEditing/HeartysanCategoryEditing.vue')
const moduleKey = 'heartysan'

export const HeartysanCategoryRoutes = [
  {
    path: routerPaths.heartysanCategory,
    component: HeartysanCategoryTable,
    meta: {
      breadcrumbs: {
        key: [
          'heartysan',
          'heartysanCategory'
        ],
        items: {
          'heartysan': {
            name: 'menu_heartysan',
            link: routerPaths.heartysanCategory,
          },
          'heartysanCategory': {
            name: 'menu_HeartysanCategory',
            link: routerPaths.heartysanCategory,
          },
        },
      },

      // middleware: [permissions],
      // permissions: [PERMISSIONS.EXPENSE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_HeartysanCategory'
    },
  },

  {
    path: routerPaths.heartysanCategoryCreate,
    component: HeartysanCategoryCreation,
    meta: {
      breadcrumbs: {
        key: [
          'heartysan',
          'heartysanCategory',
          'heartysanCategoryCreation'
        ],
        items: {
          'heartysan': {
            name: 'menu_heartysan',
            link: routerPaths.heartysanCategory,
          },
          'heartysanCategory': {
            name: 'menu_HeartysanCategory',
            link: routerPaths.heartysanCategory,
          },
          'heartysanCategoryCreation': {
            name: 'breadcrumbs_heartysanCategoryCreation',
            link: routerPaths.heartysanCategoryCreate,
          },
        },
      },

      // middleware: [permissions],
      // permissions: [PERMISSIONS.EXPENSE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_heartysanCategoryCreation'
    },
  },

  {
    path: routerPaths.heartysanCategoryEdit,
    component: HeartysanCategoryEditing,
    meta: {
      breadcrumbs: {
        key: [
          'heartysan',
          'heartysanCategory',
          'heartysanCategoryEdit'
        ],
        items: {
          'heartysan': {
            name: 'menu_heartysan',
            link: routerPaths.heartysan,
          },
          'heartysanCategory': {
            name: 'menu_HeartysanCategory',
            link: routerPaths.heartysan,
          },
          'heartysanCategoryEdit': {
            name: 'breadcrumbs_heartysanCategoryEdit',
            link: routerPaths.heartysanCategoryEdit,
          },
        },
      },

      // middleware: [permissions],
      // permissions: [PERMISSIONS.EXPENSE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_heartysanCategoryEdit'
    },
  },

  {
    path: routerPaths.heartysanCategoryShow,
    component: HeartysanCategoryEditing,
    meta: {
      breadcrumbs: {
        key: [
          'incomeAndHeartysanCategory',
          'heartysan',
          'incomeHeartysanCategoryEdit'
        ],
        items: {
          'incomeAndHeartysanCategory': {
            name: 'menu_incomeHeartysanCategory',
            link: routerPaths.heartysan,
          },
          'heartysan': {
            name: 'menu_heartysan',
            link: routerPaths.heartysan,
          },
          'incomeHeartysanCategoryEdit': {
            name: 'breadcrumbs_editHeartysanCategory',
            link: routerPaths.heartysanCategoryShow,
          },
        },
      },

      // middleware: [permissions],
      // permissions: [PERMISSIONS.EXPENSE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_editHeartysanCategory'
    },
  },
]
