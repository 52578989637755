import {API} from "../../axios/axiosMainUrl";

const prefix = '/return-parcels/document-unions'

export const consolidationMeest = {
  state: {
    consolidationMeest: [],
    consolidationMeestItem: {},
    consolidationMeestCommonList: [],
    consolidationMeestBtn: false,
    consolidationMeestLoading: true,
    nextConsolidationMeestPage: false,
    consolidationMeestForPage: 25,
    consolidationMeestFilter: '',
  },

  mutations: {
    setConsolidationMeestFilter: (state, payload) => {
      state.consolidationMeestFilter = payload
    },

    setConsolidationMeest: (state, payload) => {
      if(state.nextConsolidationMeestPage) {
        state.consolidationMeest = state.consolidationMeest.concat(payload.consolidationMeest)
      } else {
        state.consolidationMeest = payload.consolidationMeest
      }
    },

    setConsolidationMeestCommonList: (state, payload) => {
      state.consolidationMeestCommonList = payload.consolidationMeestCommonList
    },

    setNextConsolidationMeestPage: (state, payload) => {
      state.nextConsolidationMeestPage = payload
    },

    setConsolidationMeestItem: (state, payload) => {
      state.consolidationMeestItem = payload.consolidationMeestItem
    },

    setConsolidationMeestLoadingStart: (state) => {
      state.consolidationMeestLoading = true
    },

    setConsolidationMeestLoadingEnd: (state) => {
      state.consolidationMeestLoading = false
    },

    changeConsolidationMeestBtn: (state) => {
      state.consolidationMeestBtn = true
    },
    successConsolidationMeestBtn: (state) => {
      state.consolidationMeestBtn = false
    },
  },

  getters: {
    getConsolidationMeestFilter: state => {
      return state.consolidationMeestFilter
    },

    getConsolidationMeestForPage: state => {
      return state.consolidationMeestForPage
    },

    getConsolidationMeest: state => {
      return state.consolidationMeest
    },

    getConsolidationMeestLoading: state => {
      return state.consolidationMeestLoading
    },


    getNextConsolidationMeestPage: state => {
      return state.nextConsolidationMeestPage
    },

    getConsolidationMeestItem: state => {
      return state.consolidationMeestItem
    },

    getConsolidationMeestCommonList: state => {
      return state.consolidationMeestCommonList
    },

    getConsolidationMeestBtn: state => {
      return state.consolidationMeestBtn
    },
  },

  actions: {

    async fetchConsolidationMeest({commit, getters}, filter = '') {
      if(!getters.getNextConsolidationMeestPage)
        commit('setConsolidationMeestLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setConsolidationMeest',{consolidationMeest: response.data.data.data})
          commit('setConsolidationMeestCommonList',{consolidationMeestCommonList: response.data.data})
          commit('setConsolidationMeestLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setConsolidationMeestLoadingEnd');
        return e;
      }
    },

    async getConsolidationMeest({commit}, id) {
      commit('setConsolidationMeestLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setConsolidationMeestItem', {consolidationMeestItem: response.data.data})
          commit('setConsolidationMeestLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setConsolidationMeestLoadingEnd');
        return e;
      }
    },

    async createConsolidationMeest({commit}, data) {
      commit('changeConsolidationMeestBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successConsolidationMeestBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationMeestBtn');
        return e;
      }
    },

    async sendConsolidationMeest({commit}, data) {
      commit('changeConsolidationMeestBtn');
      try {
        return await API.post(`${prefix}/send`, data).then((response) =>{
          commit('successConsolidationMeestBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationMeestBtn');
        return e;
      }
    },

    async updateConsolidationMeest({commit}, {id, data}) {
      commit('changeConsolidationMeestBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successConsolidationMeestBtn');
          return response;
        });
      } catch (e) {
        commit('successConsolidationMeestBtn');
        return e;
      }
    },

    async deleteConsolidationMeest({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successConsolidationMeestBtn');
        return e;
      }
    },

  }
}
