
import {API} from '../../../axios/axiosMainUrl'

export const translationsGroups = {
  state: {
    translationsGroups: [],
    translationsGroupItem: {},
    loadingTranslationsGroups: false,
    loadingTranslationsGroupItem: false,
    translationsGroupBtn: false,
  },

  mutations: {
    setTranslationsGroups: (state, payload) => {
      state.translationsGroups = payload.translationsGroups
    },
    setTranslationsGroupItem: (state, payload) => {
      state.translationsGroupItem = payload.translationsGroupItem
    },

    setLoadingStart: (state) => {
      state.loadingTranslationsGroups = true
    },
    setLoadingTranslationsGroupItemStart: (state) => {
      state.loadingTranslationsGroups = true
    },

    setLoadingEnd: (state) => {
      state.loadingTranslationsGroups = false
    },
    setLoadingTranslationsGroupItemEnd: (state) => {
      state.loadingTranslationsGroups = false
    },


    changeTranslationsGroup: (state) => {
      state.translationsGroupBtn = true
    },
    successTranslationsGroup: (state) => {
      state.translationsGroupBtn = false
    },
  },

  getters: {
    getTranslationsGroups: state => {
      return state.translationsGroups
    },

    getTranslationsGroupItem: state => {
      return state.translationsGroupItem
    },

    getTranslationsGroupBtn: state => {
      return state.translationsGroupBtn
    }
  },

  actions: {
    async fetchTranslationsGroupItem({commit}, id) {
      commit('setLoadingTranslationsGroupItemStart');
      try {
        return await API.get(`localizations/groups/${id}`).then((response) =>{
          commit('setTranslationsGroupItem', {translationsGroupItem: response.data.data});
          commit('setLoadingTranslationsGroupItemEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingTranslationsGroupItemEnd');
        return e;
      }
    },

    async fetchTranslationsGroups({commit}, filter='') {
      commit('setLoadingStart');
      try {
        return await API.get(`localizations/groups${filter}`).then((response) =>{
          commit('setTranslationsGroups', {translationsGroups: response.data.data.data});
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async updateTranslationsGroups({commit}, {id, data}) {
      commit('setLoadingStart');
      try {
        return await API.put(`localizations/groups/${id}`, data).then((response) =>{
          commit('setTranslationsGroups', {translationsGroups: response.data.data});
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async createTranslationsGroups({commit}, data) {
      commit('setLoadingStart');
      try {
        return await API.post('localizations/groups', data).then((response) =>{
          // commit('setTranslationsGroups', {translationsGroups: response.data.translationsGroups});
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },


    async deleteTranslationsGroups({commit}, id) {
      commit('setLoadingStart');
      try {
        return await API.delete(`localizations/groups/${id}`).then((response) =>{
          // commit('setTranslationsGroups', {translationsGroups: response.data.translationsGroups});
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

  }
}
