<template>
  <div class="side-bar__content"
      :class="{'iphone' : iphoneDetect()}"
  >
    <!--<p style="color: white">-->
      <!--windowWidthFlag {{windowWidthMobile}}</p>-->
    <vue-custom-scrollbar
        class="side-bar__content-scrollbar"
        v-show="!windowWidthMobile"
        :settings="settings"
        :swicher="swicher"
    >
      <slot name="content">

      </slot>
    </vue-custom-scrollbar>

    <div class="side-bar__content-scrollbar"
         v-show="windowWidthMobile"
    >
      <slot name="content">

      </slot>
    </div>

  </div>

</template>

<script>
  import vueCustomScrollbar from 'vue-custom-scrollbar';
  import "vue-custom-scrollbar/dist/vueScrollbar.css"
  import {munuFunc} from "../../../mixins/menuMixins/menuFunc";
  import {mixinDetictingMobile} from "../../../mixins/mobileFunctions";

  export default {
    name: "MenuScrollContainer",

    components: {
      vueCustomScrollbar,
    },

    mixins: [munuFunc, mixinDetictingMobile],

    data(){
      return{
        settings: {
          suppressScrollY: false,
          suppressScrollX: true,
          wheelPropagation: false,
          wheelSpeed: 0.5,
        },

        swicher: false,

        windowWidthMobile: true,
      }
    },

    mounted() {
      window.innerWidth < 680 ? this.windowWidthMobile = true : this.windowWidthMobile = false
      window.innerWidth > 992 ? this.swicher = true : this.swicher = false
    },

    methods: {
      resizeWindowEvent(){
        window.innerWidth < 680 ? this.windowWidthMobile = true : this.windowWidthMobile = false
        window.innerWidth > 992 ? this.swicher = true : this.swicher = false
      },
    },


    created() {
      window.addEventListener("resize", this.resizeWindowEvent);
    },

    destroyed() {
      window.removeEventListener("resize", this.resizeWindowEvent);
    },
  }
</script>

<style scoped>

</style>