<template>
  <modal
      :canBeClosedByUser="canBeClosedByUser"
      @close="$emit('close')"
      class="custom-popup-modal custom-popup thank-you-popup"
  >
    <template slot="body">
      <div class="custom-popup__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate([title, text, linkLastText])"></span>
        <div class="thank-you-popup__ico">
          <img src="/img/registration-group/susses-ico.svg" alt="ico">
        </div>
        <div class="thank-you-popup__title">
          {{$t(title+'.localization_value.value')}}
        </div>
        <div class="thank-you-popup__text">
          {{$t(text+'.localization_value.value')}}
          <template v-if="withLink">
            <router-link :to="linkLastTextHref" class="site-link text-decoration-underline">
              {{$t(linkLastText+'.localization_value.value')}}
            </router-link>
          </template>
        </div>
      </div>
    </template>

  </modal>
</template>

<script>
  import Modal from "../../../commonModals/Modal";

  export default {
    name: "ThankYouPopup",
    components: {
      Modal,
    },

    props: {
      title: {},
      text: {},
      linkLastText: {},
      linkLastTextHref: {},

      withLink: {
        type: Boolean,
        default: true,
      },
      canBeClosedByUser: {
        type: Boolean,
        default: true,
      }
    },

    // props: [
    //   'title',
    //   'text',
    //   'linkLastText',
    //   'linkLastTextHref'
    // ],

    watch: {
      serverError: (newVal) => {
        this.serverError = newVal
      }
    },

    data() {
      return {


      }
    },

  }
</script>

<style lang="scss">
  @import "../../../../scss/colors";




  .thank-you-popup {
    .modal-component__header{
      margin-bottom: 0;
    }

    &__ico{
      float: left;
      width: 100px;
      margin-right: 25px;
      margin-bottom: 40px;

      img{
        width: 100%;
      }
    }

    &__title{
      font-weight: bold;
      font-size: 48px;
      line-height: 56px;
      color: #8F7A61;
      margin-bottom: 16px;
    }

    &__text{
      font-size: 14px;
      line-height: 24px;
      color: #232323;
    }

    .modal-component__inner{
      max-width: 600px;
    }

  }
</style>
