import {API} from "../../axios/axiosMainUrl";

const prefix = 'exports'

export const exportRequests = {
  state: {
    exportRequests: [],
    exportRequestsItem: {},
    exportRequestsCommonList: [],
    exportRequestsBtn: false,
    exportRequestsLoading: true,
    nextExportRequestsPage: false,
    exportRequestsForPage: 25,
    exportRequestsFilter: '',


    exportRequestsSocket: false,
    exportRequestsSocketChannel: 'export-data-transfer-channel',
    exportRequestsSocketEvent: 'export-data.transfer',
  },

  mutations: {
    setExportRequestsFilter: (state, payload) => {
      state.exportRequestsFilter = payload
    },

    setExportRequests: (state, payload) => {
      if(state.nextExportRequestsPage) {
        state.exportRequests = state.exportRequests.concat(payload.exportRequests)
      } else {
        state.exportRequests = payload.exportRequests
      }
    },

    setExportRequestsCommonList: (state, payload) => {
      state.exportRequestsCommonList = payload.exportRequestsCommonList
    },

    setNextExportRequestsPage: (state, payload) => {
      state.nextExportRequestsPage = payload
    },

    setExportRequestsItem: (state, payload) => {
      state.exportRequestsItem = payload.exportRequestsItem
    },

    setExportRequestsLoadingStart: (state) => {
      state.exportRequestsLoading = true
    },

    setExportRequestsLoadingEnd: (state) => {
      state.exportRequestsLoading = false
    },

    changeExportRequestsBtn: (state) => {
      state.exportRequestsBtn = true
    },
    successExportRequestsBtn: (state) => {
      state.exportRequestsBtn = false
    },

    setExportRequestsSocket: (state, payload) => {
      state.exportRequestsSocket = payload
    },
  },

  getters: {
    getExportRequestsFilter: state => {
      return state.exportRequestsFilter
    },

    getExportRequestsForPage: state => {
      return state.exportRequestsForPage
    },

    getExportRequests: state => {
      return state.exportRequests
    },

    getExportRequestsLoading: state => {
      return state.exportRequestsLoading
    },


    getNextExportRequestsPage: state => {
      return state.nextExportRequestsPage
    },

    getExportRequestsItem: state => {
      return state.exportRequestsItem
    },

    getExportRequestsCommonList: state => {
      return state.exportRequestsCommonList
    },

    getExportRequestsBtn: state => {
      return state.exportRequestsBtn
    },

    getExportRequestsSocket: state => {
      return state.exportRequestsSocket
    },

    getExportRequestsSocketChannel: state => {
      return state.exportRequestsSocketChannel
    },

    getExportRequestsSocketEvent: state => {
      return state.exportRequestsSocketEvent
    },
  },

  actions: {

    async fetchExportRequests({commit, getters}, filter = '') {
      if(!getters.getNextExportRequestsPage)
        commit('setExportRequestsLoadingStart')
      try {
        return await API.get(`/${prefix}${filter}`).then((response) =>{
          commit('setExportRequests',{exportRequests: response.data.data.data})
          commit('setExportRequestsCommonList',{exportRequestsCommonList: response.data.data})
          commit('setExportRequestsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setExportRequestsLoadingEnd');
        return e;
      }
    },

    async getExportRequests({commit}, id) {
      commit('setExportRequestsLoadingStart');
      try {
        return await API.get(`/${prefix}/${id}`).then((response) =>{
          commit('setExportRequestsItem', {exportRequestsItem: response.data.data})
          commit('setExportRequestsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setExportRequestsLoadingEnd');
        return e;
      }
    },

    async createExportRequests({commit}, data) {
      commit('changeExportRequestsBtn');
      try {
        return await API.post(`/${prefix}`, data).then((response) =>{
          commit('successExportRequestsBtn');
          return response;
        });
      } catch (e) {
        commit('successExportRequestsBtn');
        return e;
      }
    },

    async updateExportRequests({commit}, {id, data}) {
      commit('changeExportRequestsBtn');
      try {
        return await API.put(`/${prefix}/${id}`, data).then((response) =>{
          commit('successExportRequestsBtn');
          return response;
        });
      } catch (e) {
        commit('successExportRequestsBtn');
        return e;
      }
    },

    async deleteExportRequests({commit}, id) {
      try {
        return await API.delete(`/${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successExportRequestsBtn');
        return e;
      }
    },

  }
}
