import {API} from "../../axios/axiosMainUrl";

export const unidentifiedPayments = {
  state: {
    unidentifiedPayments: [],
    unidentifiedPaymentsItem: {},
    unidentifiedPaymentsCommonList: [],
    unidentifiedPaymentsBtn: false,
    unidentifiedPaymentsLoading: true,
    nextUnidentifiedPaymentsPage: false,
    unidentifiedPaymentsForPage: 25,
    unidentifiedPaymentsFilter: '',
  },

  mutations: {
    setUnidentifiedPaymentsFilter: (state, payload) => {
      state.unidentifiedPaymentsFilter = payload
    },

    setUnidentifiedPayments: (state, payload) => {
      if(state.nextUnidentifiedPaymentsPage) {
        state.unidentifiedPayments = state.unidentifiedPayments.concat(payload.unidentifiedPayments)
      } else {
        state.unidentifiedPayments = payload.unidentifiedPayments
      }
    },

    setUnidentifiedPaymentsCommonList: (state, payload) => {
      state.unidentifiedPaymentsCommonList = payload.unidentifiedPaymentsCommonList
    },

    setNextUnidentifiedPaymentsPage: (state, payload) => {
      state.nextUnidentifiedPaymentsPage = payload
    },

    setUnidentifiedPaymentsItem: (state, payload) => {
      state.unidentifiedPaymentsItem = payload.unidentifiedPaymentsItem
    },

    setUnidentifiedPaymentsLoadingStart: (state) => {
      state.unidentifiedPaymentsLoading = true
    },

    setUnidentifiedPaymentsLoadingEnd: (state) => {
      state.unidentifiedPaymentsLoading = false
    },

    changeUnidentifiedPaymentsBtn: (state) => {
      state.unidentifiedPaymentsBtn = true
    },
    successUnidentifiedPaymentsBtn: (state) => {
      state.unidentifiedPaymentsBtn = false
    },
  },

  getters: {
    getUnidentifiedPaymentsFilter: state => {
      return state.unidentifiedPaymentsFilter
    },

    getUnidentifiedPaymentsForPage: state => {
      return state.unidentifiedPaymentsForPage
    },

    getUnidentifiedPayments: state => {
      return state.unidentifiedPayments
    },

    getUnidentifiedPaymentsLoading: state => {
      return state.unidentifiedPaymentsLoading
    },


    getNextUnidentifiedPaymentsPage: state => {
      return state.nextUnidentifiedPaymentsPage
    },

    getUnidentifiedPaymentsItem: state => {
      return state.unidentifiedPaymentsItem
    },

    getUnidentifiedPaymentsCommonList: state => {
      return state.unidentifiedPaymentsCommonList
    },

    getUnidentifiedPaymentsBtn: state => {
      return state.unidentifiedPaymentsBtn
    },
  },

  actions: {

    async fetchUnidentifiedPayments({commit, getters}, {type, filter = ''}) {
      if(!getters.getNextUnidentifiedPaymentsPage)
        commit('setUnidentifiedPaymentsLoadingStart')
      try {
        return await API.get(`/payments/undefined${type}${filter}`).then((response) =>{
          commit('setUnidentifiedPayments',{unidentifiedPayments: response.data.data.data})
          commit('setUnidentifiedPaymentsCommonList',{unidentifiedPaymentsCommonList: {...response.data.data.meta, ...{
                addition_export: response.data.data.addition_export,
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev
              }}})
          if (type === '/archive') {
            commit('setUnidentifiedPaymentsCommonList',{unidentifiedPaymentsCommonList: {...response.data.data}})
          }
          commit('setUnidentifiedPaymentsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setUnidentifiedPaymentsLoadingEnd');
        return e;
      }
    },

    async getUnidentifiedPayments({commit}, id) {
      // commit('setUnidentifiedPaymentsLoadingStart');
      try {
        return await API.get(`/payments/${id}`).then((response) =>{
          console.log(response)
          commit('setUnidentifiedPaymentsItem', {unidentifiedPaymentsItem: response.data.data})
          commit('setUnidentifiedPaymentsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setUnidentifiedPaymentsLoadingEnd');
        return e;
      }
    },

    async createUnidentifiedPayments({commit}, data) {
      commit('changeUnidentifiedPaymentsBtn');
      try {
        return await API.post(`/unidentifiedPayments`, data).then((response) =>{
          commit('successUnidentifiedPaymentsBtn');
          return response;
        });
      } catch (e) {
        commit('successUnidentifiedPaymentsBtn');
        return e;
      }
    },

    async updateUnidentifiedPayments({commit}, {id, data}) {
      commit('changeUnidentifiedPaymentsBtn');
      try {
        return await API.put(`/unidentifiedPayments/${id}`, data).then((response) =>{
          commit('successUnidentifiedPaymentsBtn');
          return response;
        });
      } catch (e) {
        commit('successUnidentifiedPaymentsBtn');
        return e;
      }
    },

    async deleteUnidentifiedPayments({commit}, id) {
      try {
        return await API.delete(`/payments/undefined/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successUnidentifiedPaymentsBtn');
        return e;
      }
    },

    async archiveUnidentifiedPayments({commit}, {id, data}) {
      try {
        return await API.post(`/payments/undefined/archive/${id}`, data).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successUnidentifiedPaymentsBtn');
        return e;
      }
    },

    async requestPayment({commit}, data) {
      commit('changeUnidentifiedPaymentsBtn');
      API.defaults.headers.post['Accept'] = 'multipart/form-data'
      API.defaults.headers.post['Content-Type'] ='multipart/form-data'
      try {
        return await API.post(`/payments/undefined/send-email`, data).then((response) =>{
          commit('successUnidentifiedPaymentsBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successUnidentifiedPaymentsBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async addUserPayment({commit}, data) {
      commit('changeUnidentifiedPaymentsBtn');
      try {
        return await API.post(`/payments/undefined/attach-transaction`, data).then((response) =>{
          commit('successUnidentifiedPaymentsBtn');
          return response;
        });
      } catch (e) {
        commit('successUnidentifiedPaymentsBtn');
        return e;
      }
    },


    async getExportUnidentifiedPayments({commit}, {systemType, filter , type}) {
      commit('setExportBtnLoadingStart')
      commit('setLoadingEnd')
      try {
        return await API.get(`/payments/undefined/export/${type}${systemType}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },
  }
}
