
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const WarehouseManagementTablePage = () => import(/* webpackChunkName: "group-warehouse-management" */ './components/WarehouseManagementTablePage/WarehouseManagementTablePage.vue')
const WarehouseManagementCreationPage = () => import(/* webpackChunkName: "group-warehouse-management" */ './components/WarehouseManagementCreationPage/WarehouseManagementCreationPage.vue')
const WarehouseManagementEditingPage = () => import(/* webpackChunkName: "group-warehouse-management" */ './components/WarehouseManagementEditingPage/WarehouseManagementEditingPage.vue')
const moduleKey = 'warehouse-management'

export const WarehouseManagementRoutes = [
  {
    path: routerPaths.mainSettingsWarehouseManagement,
    component: WarehouseManagementTablePage,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'mainSettingsWarehouseManagement'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsWarehouseManagement,
          },
          'mainSettingsWarehouseManagement': {
            name: 'menu_warehouseManagementConsolidation',
            link: routerPaths.mainSettingsWarehouseManagement,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.WAREHOUSE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_warehouseManagementConsolidation'
    },
  },
  {
    path: routerPaths.mainSettingsWarehouseManagementCreate,
    component: WarehouseManagementCreationPage,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'mainSettingsWarehouseManagement',
          'mainSettingsWarehouseManagementCreate'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsWarehouseManagement,
          },
          'mainSettingsWarehouseManagement': {
            name: 'menu_warehouseManagementConsolidation',
            link: routerPaths.mainSettingsWarehouseManagement,
          },
          'mainSettingsWarehouseManagementCreate': {
            name: 'breadcrumbs_NewWarehouse',
            link: routerPaths.mainSettingsWarehouseManagementCreate,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.WAREHOUSE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewWarehouse'
    },
  },
  {
    path: routerPaths.mainSettingsWarehouseManagementEdit,
    component: WarehouseManagementEditingPage,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'mainSettingsWarehouseManagement',
          'mainSettingsWarehouseManagementEdit'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsWarehouseManagement,
          },
          'mainSettingsWarehouseManagement': {
            name: 'menu_warehouseManagementConsolidation',
            link: routerPaths.mainSettingsWarehouseManagement,
          },
          'mainSettingsWarehouseManagementEdit': {
            name: 'breadcrumbs_EditWarehouse',
            link: routerPaths.mainSettingsWarehouseManagementEdit,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.WAREHOUSE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_EditWarehouse'
    },
  },
]
