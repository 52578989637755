<template>
  <div class="fragment">

    <div class="custom-row">
      <div class="custom-col custom-col--50 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'payPalCases_transactionId',
          ])"></div>
        <SearchSelect
            :options="options"
            :label="$t('payPalCases_transactionId.localization_value.value')"
            v-bind:class="{'ui-no-valid': PC.validation.paymentTransactionId}"
            :errorTxt="$t(`${PC.validationTranslate.paymentTransactionId}.localization_value.value`)"
            :error="PC.validation.paymentTransactionId"
            :functionSearch="(value, loading) => functionSearch(value, loading, 'transactions')"
            :typeSelect="'transactions'"
            :selected="PC.data.transactionObject"
            :disabled="!createPageFlag"
            @change="(val) => {changeTransactionId(val)}"
        />
<!--        <DefaultInput-->
<!--            :disabled="true"-->
<!--            :label="$t('payPalCases_transactionId.localization_value.value')"-->
<!--            v-else-->
<!--            v-model="PC.data.paymentTransactionPaypalId"-->
<!--        />-->
      </div>
    </div>

    <div v-if="showTransactionInfo" class="custom-row"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'common_user',
          'payPalCases_transactionFreeAmount',
          ])"></div>
      <div class="custom-col">
        {{$t('common_user.localization_value.value')}}: {{PC.data.transactionUserEmail}}<br/>
<!--        {{$t('payPalCases_transactionFreeAmount.localization_value.value')}}: {{PC.data.transactionFreeAmount}}<br/>-->
        {{$t('payPalCases_sum.localization_value.value')}}: {{PC.data.transactionTotal}}<br>
        {{$t('cuspayments_netAmount.localization_value.value')}}, $: {{getTRNSNetAmount}}
      </div>
    </div>

    <div class="custom-row">
      <div class="custom-col custom-col--50 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'payPalCases_trackingNumber',
          ])"></div>
        <DefaultInput
            :disabled="true"
            :label="$t('payPalCases_trackingNumber.localization_value.value')"
            v-model="PC.data.trackingNumber"
        />
      </div>

      <div class="custom-col custom-col--50 custom-col--md-100">
        <DefaultInput
            :label="$t('payPalCases_paypalNumberOfComplaint.localization_value.value')"
            v-bind:class="{'ui-no-valid': PC.validation.externalComplaintId}"
            :errorTxt="$t(`${PC.validationTranslate.externalComplaintId}.localization_value.value`)"
            :error="PC.validation.externalComplaintId"
            v-model="PC.data.externalComplaintId"
            :disabled="!createPageFlag && !isAdmin"
        />
      </div>
    </div>

    <div class="custom-row">


      <div class="custom-col custom-col--50 custom-col--md-100"
           v-if="_.has(createData, 'types')"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'payPalCases_caseType',
          ])"></div>
        <DefaultSelect
            class="w-100"
            :options="createData.types"
            :label="$t('payPalCases_caseType.localization_value.value')"
            :optionsLabel="'name'"
            v-bind:class="{'ui-no-valid': PC.validation.complaintType}"
            :errorTxt="$t(`${PC.validationTranslate.complaintType}.localization_value.value`)"
            :error="PC.validation.complaintType"
            :otherValue="'currentTranslation'"
            @change="(val) => {PC.setcomplaintType(val)}"
            :selected="PC.data.complaintType"
            :disabled="!createPageFlag && !isAdmin"
        />
      </div>

      <div class="custom-col custom-col--50 custom-col--md-100"
           v-if="_.has(createData, 'reasons')"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'payPalCases_caseReason',
          ])"></div>
        <DefaultSelect
            class="w-100"
            :options="createData.reasons"
            :label="$t('payPalCases_caseReason.localization_value.value')"
            :optionsLabel="'name'"
            v-bind:class="{'ui-no-valid': PC.validation.complaintReason}"
            :errorTxt="$t(`${PC.validationTranslate.complaintReason}.localization_value.value`)"
            :error="PC.validation.complaintReason"
            :otherValue="'currentTranslation'"
            @change="(val) => {PC.setcomplaintReason(val)}"
            :selected="PC.data.complaintReason"
            :disabled="!createPageFlag && !isAdmin"
        />
      </div>

    </div>

    <div class="custom-row">
      <div class="custom-col custom-col--50 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'payPalCases_caseStatus',
          ])"></div>
        <DefaultSelect
            class="w-100"
            :options="PAYPAL_CASES_ARRAY_STATUSES"
            :label="$t('payPalCases_caseStatus.localization_value.value')"
            :otherValue="'translation'"
            v-bind:class="{'ui-no-valid': PC.validation.status}"
            :errorTxt="$t(`${PC.validationTranslate.status}.localization_value.value`)"
            :error="PC.validation.status"
            @change="(val) => {PC.setStatus(val)}"
            :selected="PC.data.status"
            :disabled="!createPageFlag && !isAdmin"
        />
      </div>

      <div class="custom-col custom-col--50 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'payPalCases_amount',
          ])"></div>
        <DefaultInput
            :label="$t('payPalCases_amount.localization_value.value')"
            v-bind:class="{'ui-no-valid': PC.validation.freeAmount}"
            :errorTxt="$t(`${PC.validationTranslate.freeAmount}.localization_value.value`)"
            :error="PC.validation.freeAmount"
            v-model="PC.data.freeAmount"
            :disabled="!createPageFlag && !isAdmin"
        />
      </div>
    </div>

    <div class="custom-row">

      <div class="custom-col custom-col--50 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'payPalCases_openingDate',
          ])"></div>
        <DatePickerDefault
            :label="$t('payPalCases_openingDate.localization_value.value')"
            :placeholder="$t('common_dateYYYY_MM_DD.localization_value.value')"
            v-bind:class="{'ui-no-valid': PC.validation.openDate}"
            :errorTxt="$t(`${PC.validationTranslate.openDate}.localization_value.value`)"
            :error="PC.validation.openDate"
            v-model="PC.data.openDate"
            :disabled="!createPageFlag && !isAdmin"
        >
          <template slot="body">
            <date-picker
                v-model="PC.data.openDate"
                ref="datePicker"
                valueType="format"
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'YYYY-MM-DD'"
                :placeholder="$t('common_dateYYYY_MM_DD.localization_value.value')"
                :disabled="!createPageFlag && !isAdmin"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="custom-col custom-col--50 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'payPalCases_closingDate',
          ])"></div>
        <DatePickerDefault
            :label="$t('payPalCases_closingDate.localization_value.value')"
            :placeholder="$t('common_dateYYYY_MM_DD.localization_value.value')"
            v-bind:class="{'ui-no-valid': PC.validation.closeDate}"
            :errorTxt="$t(`${PC.validationTranslate.closeDate}.localization_value.value`)"
            :error="PC.validation.closeDate"
            v-model="PC.data.closeDate"
            :disabled="!createPageFlag && !isAdmin"
        >
          <template slot="body">
            <date-picker
                v-model="PC.data.closeDate"
                ref="datePicker"
                valueType="format"
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'YYYY-MM-DD'"
                :placeholder="$t('common_dateYYYY_MM_DD.localization_value.value')"
                :disabled="!createPageFlag && !isAdmin"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

    </div>

    <div class="fragment">
      <div class="section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['payPalCases_messages'])"></div>
        {{ $t('payPalCases_messages.localization_value.value') }}
      </div>

      <PayPalCasesMessagesItems
          :PC="PC"
      />

      <template v-if="isAdmin">
        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col custom-col--66 custom-col--md-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['payPalCases_newMessage'])"></div>
            <TextareaDefault
                :label="$t('payPalCases_newMessage.localization_value.value')"
                v-bind:class="{'ui-no-valid': PC.validation.message}"
                :error="PC.validation.message"
                :errorTxt="$t(`${PC.validationTranslate.message}.localization_value.value`)"
                v-model="PC.data.message"
            />
          </div>
        </div>

        <div v-if="!createPageFlag" class="order-create__row custom-row">
          <div class="order-create__col custom-col">
            <div class="order-create__footer-btn"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['payPalCases_Send'])"></div>
              <MainButton
                  class="order-create__footer-btn-i"
                  :value="$t('payPalCases_Send.localization_value.value')"
                  @click.native="sendMessage"
              />
            </div>
          </div>
        </div>
      </template>

      <template v-else>

        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col custom-col--66 custom-col--md-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['payPalCases_newMessage'])"></div>
            <TextareaDefault
                :label="$t('payPalCases_newMessage.localization_value.value')"
                v-bind:class="{'ui-no-valid': PCPopup.validation.message}"
                :error="PCPopup.validation.message"
                :errorTxt="$t(`${PCPopup.validationTranslate.message}.localization_value.value`)"
                v-model="PCPopup.data.message"
            />
          </div>

          <div class=" custom-col">
<!--            :maxCount="PCPopup.data.Files.data.maxCountFiles - PCPopup.data.Files.data.downloadFiles.length"-->
            <DropZone class="drop-zone-bg"
                      :parentFiles="PCPopup.data.Files.data.files"
                      :multiple="true"
                      :maxCount="5"
                      :maxSize="PCPopup.data.Files.data.maxSizeFiles"
                      :accept="'image/x-png,image/gif,image/jpeg,application/pdf'"
                      @changeImg="changeUserMessageImg"
            />
          </div>
        </div>

        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col">
            <div class="order-create__footer-btn"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['payPalCases_Send'])"></div>
              <MainButton
                  :value="$t('common_send.localization_value.value')"
                  class="import-transaction__btn-i"
                  @click.native="sendUserMessage(true)"
              />
            </div>
          </div>
        </div>
      </template>



    </div>

<!--    <div class="custom-row">-->
<!--      <div class="custom-col">-->

<!--        <template>-->
<!--          <div class="section-label"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['payPalCases_commentAdmin'])"></div>-->
<!--            {{ $t('payPalCases_commentAdmin.localization_value.value') }}-->
<!--          </div>-->
<!--          <div class="order-create__row custom-row">-->
<!--            <div class="order-create__col custom-col custom-col&#45;&#45;66 custom-col&#45;&#45;md-100"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['common_widthCm'])"></div>-->
<!--              <TextareaDefault-->
<!--                  :label="$t('common_comment.localization_value.value')"-->
<!--                  v-bind:class="{'ui-no-valid': PC.validation.commentAdmin}"-->
<!--                  :error="PC.validation.commentAdmin"-->
<!--                  :errorTxt="$t(`${PC.validationTranslate.commentAdmin}.localization_value.value`)"-->
<!--                  v-model="PC.data.commentAdmin"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
<!--        </template>-->

<!--&lt;!&ndash;        <template>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="section-label"&ndash;&gt;-->
<!--&lt;!&ndash;               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="admin-edit" @click="editTranslate(['payPalCases_comment'])"></div>&ndash;&gt;-->
<!--&lt;!&ndash;            {{ $t('payPalCases_comment.localization_value.value') }}&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="order-create__row custom-row">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="order-create__col custom-col custom-col&#45;&#45;66 custom-col&#45;&#45;md-100"&ndash;&gt;-->
<!--&lt;!&ndash;                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="admin-edit" @click="editTranslate(['common_widthCm'])"></div>&ndash;&gt;-->
<!--&lt;!&ndash;              <TextareaDefault&ndash;&gt;-->
<!--&lt;!&ndash;                  :label="$t('common_comment.localization_value.value')"&ndash;&gt;-->
<!--&lt;!&ndash;                  v-bind:class="{'ui-no-valid': PC.validation.comment}"&ndash;&gt;-->
<!--&lt;!&ndash;                  :error="PC.validation.comment"&ndash;&gt;-->
<!--&lt;!&ndash;                  :errorTxt="$t(`${PC.validationTranslate.comment}.localization_value.value`)"&ndash;&gt;-->
<!--&lt;!&ndash;                  v-model="PC.data.comment"&ndash;&gt;-->
<!--&lt;!&ndash;              />&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </template>&ndash;&gt;-->

<!--      </div>-->
<!--    </div>-->

    <div class="section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['payPalCases_documentsUp',])"></div>
      {{ $t('payPalCases_documentsUp.localization_value.value') }}
    </div>
    
    <div class="custom-row" :key="images - 100">
      <div class="custom-col custom-col--16 custom-col--md-50 custom-col--sm-100"
           v-for="(item, index) in PC.data.Files.data.downloadFiles"
           :key="index">
        <div class="site-document d-flex justify-content-center">
              <span class="site-document__remove" @click="removeDocument(item)" v-if="isAdmin">
                <CloseIcon/>
              </span>
          <div class="site-document__img">
            <img v-if="item.mime_type !== 'application/pdf'"
                 :src="`data:${item.type};base64,` + item.base64"
                 @click="showSingle(item.id)"
                 alt="img"
            >
            <img v-else
                 @click="openPdf(item['base64'])"
                 style="width: 30px; height: 40px;"
                 src="/img/common/pdf_file_icon.svg"
                 alt="ico"
            >
          </div>
        </div>
      </div>

      <template v-if="isAdmin">
        <div class="custom-col" v-if="PC.data.Files.data.maxCountFiles > PC.data.Files.data.downloadFiles.length">
          <DropZone class="drop-zone-bg"
                    :parentFiles="PC.data.Files.data.files"
                    :multiple="true"
                    :maxCount="PC.data.Files.data.maxCountFiles - PC.data.Files.data.downloadFiles.length"
                    :maxSize="PC.data.Files.data.maxSizeFiles"
                    :accept="'image/x-png,image/gif,image/jpeg,application/pdf'"
                    @changeImg="changeImg"
          />
        </div>
      </template>

    </div>

    <Lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
    ></Lightbox>
  </div>
</template>

<script>
import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import DatePickerDefault from "../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
import DatePicker from 'vue2-datepicker';
import {filterFunc} from "../../../../../mixins/filterFuncMixin/filterFunc";
import {payPalCasesMixin} from "../../../../../mixins/payPalCasesMixins/payPalCasesMixin";
import TextareaDefault from "../../../../UI/textarea/TextareaDefault/TextareaDefault";
import DropZone from "../../../../coreComponents/DropZone/DropZone";
import SearchSelect from "../../../../UI/selectiones/SearchSelect/SearchSelect";
import {PAYPAL_CASES_ARRAY_STATUSES} from "../../../../../staticData/staticVariables";
import CloseIcon from '../../../../../../public/img/modal-group/close-icon.svg?inline'
import PayPalCasesMessagesItems from "./PayPalCasesMessagesItems";
import MainButton from "../../../../UI/buttons/MainButton/MainButton";
import Lightbox from "vue-easy-lightbox";
import {PayPalCases} from "@/components/modules/PayPalCasesModule/models/PayPalCases";

export default {
  name: "FieldsBlock",

  components: {
    MainButton,
    PayPalCasesMessagesItems,
    SearchSelect,
    DropZone,
    TextareaDefault,
    DefaultInput,
    DefaultSelect,
    DatePickerDefault,
    DatePicker,
    CloseIcon,
    Lightbox,
  },

  mixins: [filterFunc, payPalCasesMixin],

  props: {
    PC: Object,
    createData: Object,
    createPageFlag: {
      type: Boolean,
      default: false
    },
    transactionUser: Object,
  },

  computed: {
    getTRNSNetAmount() {
      return this.PC.data.transactionObject?.warehouse_amount
    },
  },

  data() {
    return {
      options: [],
      showTransactionInfo: false,
      images: -1,
      imgs: [], // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0

      PAYPAL_CASES_ARRAY_STATUSES: PAYPAL_CASES_ARRAY_STATUSES,

      PCPopup: new PayPalCases({files: 1}),
    }
  },

  mounted() {

    this.PCPopup.setId(this.$route.params?.id)

    if (this.createPageFlag) {
      this.PC.setOpenDate(this.$moment().format('YYYY-MM-DD'))
    }
    else {
      this.showTransactionInfo = true
      this.transactionUserEmail = this.transactionUser?.email
    }


    if(this.$route.query.transaction){
      const query = new this.Query();
      let url = '?';
      let myQuery = query
          .for('posts')
      myQuery.where('PaymentTransactionId', this.$route.query.transaction)
      myQuery.where('PaymentTransactionStatus', 'success')
      myQuery.where('excludeOpenedComplaint', '1')
      myQuery.appends('freeAmount')

      url = url + myQuery.limit(100000).page(1).url().split('?')[1]
      // fetchCusPayments
      this.$store.dispatch('fetchPayPalCasesTrnsList', url).then(response => {
        this.options = response.data.data.data
        this.changeTransactionId(this.options[0])
      })
    }
  },


  methods: {
    changeImg(files, maxSizeError) {
      this.PC.data.Files.setFiles(files)

      this.PC.data.Files.setFilesMaxSizeError(maxSizeError)
      console.log(this.PC.data.Files);
    },

    removeDocument(item) {
      let targetFile = item.id

      //delete big image
      if (item.type === 'small_image') {
        targetFile = item.id + 1
      }

      this.$store.dispatch('removeUserDocument', targetFile).then(() => {

        let files = this.PC.data.Files
        let updatedFiles = this._.remove(files.data.downloadFiles, function(n) {
          return n.id === item.id
        })
        files.getDownloadFiles(updatedFiles)
        this.images = item.id

      })
    },

    changeTransactionId(val) {

      this.PC.setTransactionObject(val)
      this.PC.setPaymentTransactionId(val?.id)
      this.PC.setTrackingNumber(val?.tracking_number)
      this.PC.setTransactionFreeAmount(val?.freeAmount)
      this.PC.setTransactionUserEmail(val?.user?.email)
      this.PC.setTransactionTotal(val?.user_amount)

      this.PC.setTransactionObject(val)

      this.showTransactionInfo = true
    },

    show() {
      this.visible = true
    },

    handleHide() {
      this.visible = false
    },

    showSingle(id) {
      let index = this._.findIndex(this.PC.data.Files.data.orderFiles, {id: Number(id)})
      let bigId = this.PC.data.Files.data.orderFiles[index + 1]?.id

      this.$store.dispatch('getFileFromServer', bigId).then((response) => {
        this.imgs = []
        console.log(response);
        this.imgs.push({
          title: 'img',
          src: `data:image/png;base64,` + response[bigId]
        })
        this.show()
      })
    },

    openPdf(base64) {
      this.globalOpenPdfBase64(base64)
    },
  },
}
</script>