import {routerPaths} from '../../../router/routerPaths'
import permissions from "@/router/middleware/permissions";
import {PERMISSIONS} from "@/staticData/permissionsStatic";

const ExpressPoshtaReportTable = () => import(/* webpackChunkName: "group-express-poshta-report" */ './components/ExpressPoshtaReportTable/ExpressPoshtaReportTable.vue')
const moduleKey = 'consolidation-orders'

export const ExpressPoshtaReportRoutes = [
  {
    path: routerPaths.expressPoshtaReport,
    component: ExpressPoshtaReportTable,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'expressPoshtaReport'
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.expressPoshtaReport,
          },
          'expressPoshtaReport': {
            name: 'menu_expressPoshtaReport',
            link: routerPaths.expressPoshtaReport,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_EXTERNAL_EXPRESS_POSHTA],

      moduleKey: moduleKey,
      pageTitle: 'menu_expressPoshtaReport'
    },
  }
]
