import {API} from "../../axios/axiosMainUrl";

const prefix = '/search'

export const search = {
  state: {
    search: [],
    searchItem: {},
    searchCommonList: [],
    searchBtn: false,
    searchLoading: true,
    nextSearchPage: false,
    searchForPage: 25,
    searchFilter: '',
  },

  mutations: {
    setSearchFilter: (state, payload) => {
      state.searchFilter = payload
    },

    setSearch: (state, payload) => {
      if(state.nextSearchPage) {
        state.search = state.search.concat(payload.search)
      } else {
        state.search = payload.search
      }
    },

    setSearchCommonList: (state, payload) => {
      state.searchCommonList = payload.searchCommonList
    },

    setNextSearchPage: (state, payload) => {
      state.nextSearchPage = payload
    },

    setSearchItem: (state, payload) => {
      state.searchItem = payload.searchItem
    },

    setSearchLoadingStart: (state) => {
      state.searchLoading = true
    },

    setSearchLoadingEnd: (state) => {
      state.searchLoading = false
    },

    changeSearchBtn: (state) => {
      state.searchBtn = true
    },
    successSearchBtn: (state) => {
      state.searchBtn = false
    },
  },

  getters: {
    getSearchFilter: state => {
      return state.searchFilter
    },

    getSearchForPage: state => {
      return state.searchForPage
    },

    getSearch: state => {
      return state.search
    },

    getSearchLoading: state => {
      return state.searchLoading
    },


    getNextSearchPage: state => {
      return state.nextSearchPage
    },

    getSearchItem: state => {
      return state.searchItem
    },

    getSearchCommonList: state => {
      return state.searchCommonList
    },

    getSearchBtn: state => {
      return state.searchBtn
    },
  },

  actions: {

    async fetchSearchTrack({commit, getters}, filter = '') {
      if(!getters.getNextSearchPage)
        commit('setSearchLoadingStart')
      try {
        return await API.get(`${prefix}/tracking-number${filter}`).then((response) =>{
          commit('setSearch',{search: response.data.data})
          commit('setSearchCommonList',{searchCommonList: response.data.data})
          commit('setSearchLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setSearchLoadingEnd');
        return e;
      }
    },

    async fetchSearchBarcode({commit, getters}, filter = '') {
      if(!getters.getNextSearchPage)
        commit('setSearchLoadingStart')
      try {
        return await API.get(`${prefix}/scan-number${filter}`).then((response) =>{
          commit('setSearchLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setSearchLoadingEnd');
        return e;
      }
    },

    //search orders for apc delcon conso
    async fetchSearchOrderUnion({commit, getters}, filter = '') {
      if(!getters.getNextSearchPage)
        commit('setSearchLoadingStart')
      try {
        return await API.get(`${prefix}/scan-order-union${filter}`).then((response) =>{
          commit('setSearchLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setSearchLoadingEnd');
        return e;
      }
    },

    //search orders for consolidation unions
    async fetchSearchUnionOrder({commit, getters}, filter = '') {
      if(!getters.getNextSearchPage)
        commit('setSearchLoadingStart')
      try {
        return await API.get(`${prefix}/scan-union-order${filter}`).then((response) =>{
          commit('setSearchLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setSearchLoadingEnd');
        return e;
      }
    },

  }
}
