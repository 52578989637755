import {API} from "../../axios/axiosMainUrl";

export const brainTreeAPI = {
  state: {
    brainTreeAPI: [],
    brainTreeAPIItem: {},
    brainTreeAPICommonList: [],
    brainTreeAPIBtn: false,
    brainTreeAPILoading: true,
    nextBrainTreeAPIPage: false,
    brainTreeAPIForPage: 25,
    brainTreeAPIFilter: '',
  },

  mutations: {
    setBrainTreeAPIFilter: (state, payload) => {
      state.brainTreeAPIFilter = payload
    },

    setBrainTreeAPI: (state, payload) => {
      if(state.nextBrainTreeAPIPage) {
        state.brainTreeAPI = state.brainTreeAPI.concat(payload.brainTreeAPI)
      } else {
        state.brainTreeAPI = payload.brainTreeAPI
      }
    },

    setBrainTreeAPICommonList: (state, payload) => {
      state.brainTreeAPICommonList = payload.brainTreeAPICommonList
    },

    setNextBrainTreeAPIPage: (state, payload) => {
      state.nextBrainTreeAPIPage = payload
    },

    setBrainTreeAPIItem: (state, payload) => {
      state.brainTreeAPIItem = payload.brainTreeAPIItem
    },

    setBrainTreeAPILoadingStart: (state) => {
      state.brainTreeAPILoading = true
    },

    setBrainTreeAPILoadingEnd: (state) => {
      state.brainTreeAPILoading = false
    },

    changeBrainTreeAPIBtn: (state) => {
      state.brainTreeAPIBtn = true
    },
    successBrainTreeAPIBtn: (state) => {
      state.brainTreeAPIBtn = false
    },
  },

  getters: {
    getBrainTreeAPIFilter: state => {
      return state.brainTreeAPIFilter
    },

    getBrainTreeAPIForPage: state => {
      return state.brainTreeAPIForPage
    },

    getBrainTreeAPI: state => {
      return state.brainTreeAPI
    },

    getBrainTreeAPILoading: state => {
      return state.brainTreeAPILoading
    },


    getNextBrainTreeAPIPage: state => {
      return state.nextBrainTreeAPIPage
    },

    getBrainTreeAPIItem: state => {
      return state.brainTreeAPIItem
    },

    getBrainTreeAPICommonList: state => {
      return state.brainTreeAPICommonList
    },

    getBrainTreeAPIBtn: state => {
      return state.brainTreeAPIBtn
    },
  },

  actions: {


    async getBrainTreeToken({commit}) {
      try {
        return await API.get(`/api-payments/braintree/token`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setBrainTreeAPILoadingEnd');
        return e;
      }
    },

    async getBrainTreeGoogleMerchantId({commit}) {
      try {
        return await API.get(`/api-payments/braintree/google-merchant-id`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setBrainTreeAPILoadingEnd');
        return e;
      }
    },

    async newBrainTree({commit}, data) {
      commit('changeBrainTreeAPIBtn');
      try {
        return await API.post(`/api-payments/braintree/create-pay`, data).then((response) =>{
          commit('successBrainTreeAPIBtn');
          return response;
        });
      } catch (e) {
        commit('successBrainTreeAPIBtn');
        return e;
      }
    },

    async newPaymentBrainTreeInvoice({commit}, data) {
      commit('changeAuthorizeBtn');
      try {
        return await API.post(`/api-payments/braintree/create-invoice-pay`, data).then((response) =>{
          commit('successBrainTreeAPIBtn');
          return response;
        });
      } catch (e) {
        commit('successBrainTreeAPIBtn');
        return e;
      }
    },

    async fetchBrainTreeAPI({commit, getters}, filter = '') {
      if(!getters.getNextBrainTreeAPIPage)
        commit('setBrainTreeAPILoadingStart')
      try {
        return await API.get(`/brainTreeAPI${filter}`).then((response) =>{
          commit('setBrainTreeAPI',{brainTreeAPI: response.data.data.data})
          commit('setBrainTreeAPICommonList',{brainTreeAPICommonList: response.data.data})
          commit('setBrainTreeAPILoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setBrainTreeAPILoadingEnd');
        return e;
      }
    },

    async getBrainTreeAPI({commit}, id) {
      commit('setBrainTreeAPILoadingStart');
      try {
        return await API.get(`/brainTreeAPI/${id}`).then((response) =>{
          commit('setBrainTreeAPIItem', {brainTreeAPIItem: response.data.data})
          commit('setBrainTreeAPILoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setBrainTreeAPILoadingEnd');
        return e;
      }
    },

    async createBrainTreeAPI({commit}, data) {
      commit('changeBrainTreeAPIBtn');
      try {
        return await API.post(`/brainTreeAPI`, data).then((response) =>{
          commit('successBrainTreeAPIBtn');
          return response;
        });
      } catch (e) {
        commit('successBrainTreeAPIBtn');
        return e;
      }
    },

    async updateBrainTreeAPI({commit}, {id, data}) {
      commit('changeBrainTreeAPIBtn');
      try {
        return await API.put(`/brainTreeAPI/${id}`, data).then((response) =>{
          commit('successBrainTreeAPIBtn');
          return response;
        });
      } catch (e) {
        commit('successBrainTreeAPIBtn');
        return e;
      }
    },

    async deleteBrainTreeAPI({commit}, id) {
      try {
        return await API.delete(`/brainTreeAPI/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successBrainTreeAPIBtn');
        return e;
      }
    },

  }
}
