
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const ConsolidationUsersTable = () => import(/* webpackChunkName: "group-other-shippers" */ './components/ConsolidationUsersTable/ConsolidationUsersTable.vue')
const moduleKey = 'consolidation-users'

export const ConsolidationUsersRoutes = [
  {
    path: routerPaths.consolidationUsers,
    component: ConsolidationUsersTable,
    meta: {
      breadcrumbs: {
        key: [
          'menu_settings',
          'mainSettingsConsolidationUsers'
        ],
        items: {
          'menu_settings': {
            name: 'menu_settings',
            link: routerPaths.consolidationUsers,
          },
          'mainSettingsConsolidationUsers': {
            name: 'menu_consolidationUsers',
            link: routerPaths.consolidationUsers,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.CONSOLIDATION_PERSONS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_consolidationUsers'
    },
  }
]
