
export const globalAPIFunctionsMixin = {

  methods: {

    downloadFileFromServer(file) {
      return this.$store.dispatch('getFileFromServer', file.id)
        .then((response) => {
          let dataFile = response
          let fileBase64 = dataFile
          Object.keys(dataFile).map(item => {
            dataFile[item] = {
              id: item,
              type: file.mime_type,
              uuid: file.uuid,
              base64: fileBase64[item],
              typeSize: file?.type ? file.type : null
            }
          })
          return dataFile
        }).catch(error => this.createErrorLog(error))
    },


  }
}
