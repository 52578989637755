
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const AccountsTable = () => import(/* webpackChunkName: "group-accounts" */ './components/AccountsTable/AccountsTable.vue')
const AccountsCreate = () => import(/* webpackChunkName: "group-accounts" */ './components/AccountsCreate/AccountsCreate.vue')
const AccountsEdit = () => import(/* webpackChunkName: "group-accounts" */ './components/AccountsEdit/AccountsEdit.vue')
const moduleKey = 'accounts'

export const AccountsRoutes = [
  {
    path: routerPaths.accounts,
    component: AccountsTable,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'settingsAccounts'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.dashboard,
          },
          'settingsAccounts': {
            name: 'menu_personalAccount',
            link: routerPaths.accounts,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ACCOUNT_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_personalAccount'
    },
  },
  {
    path: routerPaths.accountsCreation,
    component: AccountsCreate,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'settingsAccountsLink',
          'settingsAccountsLinkCreate',
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.dashboard,
          },
          'settingsAccountsLink': {
            name: 'menu_personalAccount',
            link: routerPaths.accounts,
          },
          'settingsAccountsLinkCreate': {
            name: 'breadcrumbs_NewAccount',
            link: '/',
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ACCOUNT_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewAccount'
    },
  },
  {
    path: routerPaths.accountsEditing,
    component: AccountsEdit,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'settingsAccountsLink',
          'settingsAccountsLinkEdit',
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.dashboard,
          },
          'settingsAccountsLink': {
            name: 'menu_personalAccount',
            link: routerPaths.accounts,
          },
          'settingsAccountsLinkEdit': {
            name: 'breadcrumbs_EditAccount',
            link: '/',
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ACCOUNT_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_EditAccount'
    },
  },
]
