<template>
  <div class="default-layout" v-bind:class="{'hide-header-menu': $store.getters.getHideMenuAndHeader}">

    <SideBarBlock class="menu-side noPrintable"/>
    <div class="default-layout__page-wrap"
         v-bind:class="{'dashboard-page': $route.meta && $route.meta.moduleKey === 'dashboard'}"
    >
      <Header/>
      <div class="default-layout__page-content"
           v-if="_.has(getCurrentUser, 'id')"
           :key="$store.getters.GET_LANG">
        <router-view></router-view>
      </div>
    </div>


    <NegativeBalance v-if="negativeBalance && _.has($store.getters.getCurrentUserRights, 'additionalPermissions')"/>

  </div>
</template>

<script>
  import Header from '../../templates/Header/Header.vue'
  import SideBarBlock from '../../templates/SideBarBlock/SideBarBlock.vue'
  import {GET_SMALL_AVATAR} from "../../../services/commonFunctions"
  import NegativeBalance from "../../coreComponents/NegativeBalance/NegativeBalance";
  import {INIT_SOCKET_ECHO, SOCKET_MODE} from "../../../socket/socket-config";
  import {socketMixin} from "../../../mixins/socketMixins/socketMixin";

  export default {
    name: "DefaultLayout",

    components: {
      Header,
      SideBarBlock,
      NegativeBalance,
    },

    mixins: [socketMixin],

    data(){
      return{
        negativeBalance: false,
        viewedNotification: false,
      }
    },

    created() {


      this.$store.dispatch('fetchCommonAuthorizedData').then(() => {
        GET_SMALL_AVATAR(this.$store)

        //check if the user can be blocked for negative balance (the value in user settings)
        if (this.$store.getters.GET_COMMON_AUTHORIZED.user.user_setting.allow_access_with_negative_balance !== 1) {
          //false = blocked, true = not blocked
          this.negativeBalance = !this.$store.getters.GET_COMMON_AUTHORIZED.allow_access_with_negative_balance
        }

      })

      if(SOCKET_MODE === 'ON'){
        this.CREATE_SOCKET_CONNECTION()
      }

    },

    mounted() {
      this.checkNewNotification()
    },

    methods: {
      CREATE_SOCKET_CONNECTION(){
        INIT_SOCKET_ECHO()
      },

      checkNewNotification() {
        this.$store.dispatch('fetchNotificationsNoRead').then((response => {
          if(this.getRespPaginateData(response).length > 0 && !this.viewedNotification && !this.isAdmin) {
            this.notificationPopup(this.getRespPaginateData(response)[0].id)
            this.viewedNotification = true
          }
        }))

      },
    },

    // watch: {
    //   $route: () =>{
    //     console.log(this.$route);
    //   }
    // }
  }
</script>

<style lang="scss">
  @import "../../../scss/colors";
  @import "../../../scss/mixins/mixins";

  .default-layout {
    display: flex;
    /*width: 100vw;*/
    overflow: hidden;

    &__page-wrap {
      width: 100%;
      margin-left: 300px;
      min-height: 100vh;
      background: $mainBg;
      max-width: calc(100% - 300px);

      @include from-680-for-1550{
        margin-left: 0;
        padding-left: 73px;
        max-width: 100%;
      }

      @include for-680{
        margin-left: 0;
        padding-left: 0;
        max-width: 100%;
      }
    }

    &__page-content {
      padding: 0 30px;

      @include for-1120{
        padding: 0 15px;
      }
    }

  }
</style>
