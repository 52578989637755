
import {routerPaths} from '../../../router/routerPaths'

const OtherShippersTable = () => import(/* webpackChunkName: "group-other-shippers" */ './components/OtherShippersTable/OtherShippersTable.vue')
const moduleKey = 'other-shippers'


export const OtherShippersRoutes = [
  {
    path: routerPaths.mainSettingsOtherShippers,
    component: OtherShippersTable,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettings',
          'mainSettingsOtherShippers'
        ],
        items: {
          'mainSettings': {
            name: 'menu_ordersDispatches',
            link: routerPaths.mainSettingsOtherShippers,
          },
          'mainSettingsOtherShippers': {
            name: 'menu_otherShippers',
            link: routerPaths.mainSettingsOtherShippers,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'menu_otherShippers'
    },
  }
]