import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const OrderSizeTable = () => import(/* webpackChunkName: "group-order-size" */ './components/OrderSizeTable/OrderSizeTable.vue')
const moduleKey = 'order-size'

export const OrderSizeRoutes = [
  {
    path: routerPaths.mainSettingsOrderSize,
    component: OrderSizeTable,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'reportsConsolidation'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsOrderSize,
          },
          'reportsConsolidation': {
            name: 'breadcrumbs_mainSettingsOrderSize',
            link: routerPaths.mainSettingsOrderSize,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_SIZES_ACCESS],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_mainSettingsOrderSize'
    },
  },
]
