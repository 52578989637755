<template>
<div class="fragment">

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{$t('consolidationUnion_trackingNumber.localization_value.value')}}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.tracking_number'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.tracking_number'"/></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{$t('consolidationUnion_shippingCompany.localization_value.value')}}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.delivery_service.name'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.delivery_service.name'"/></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{$t('consolidationUnion_warehouse.localization_value.value')}}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.warehouse.currentTranslate.name'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.warehouse.currentTranslate.name'"/></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{$t('consolidationUnion_comment.localization_value.value')}}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.comment'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.comment'"/></div>
  </div>

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{$t('consolidationUnion_sendDate.localization_value.value')}}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.send_at'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.send_at'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
  </div>

<!--  <div class="history-table__sub-row">-->
<!--    <div class="history-table__sub-col">{{$t('consolidationUnion_sendDate.localization_value.value')}}</div>-->
<!--    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.received_at'" :date="true" :dateType="'DD MMM, YYYY'"/></div>-->
<!--    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.received_at'" :date="true" :dateType="'DD MMM, YYYY'"/></div>-->
<!--  </div>-->

<!--  <div class="history-table__sub-row">-->
<!--    <div class="history-table__sub-col">{{$t('consolidationUnion_consoUser.localization_value.value')}}</div>-->
<!--    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.consolidation_responsible_person.name'"/></div>-->
<!--    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.consolidation_responsible_person.name'"/></div>-->
<!--  </div>-->

  <div class="history-table__sub-row">
    <div class="history-table__sub-col">{{$t('consolidations_status.localization_value.value')}}</div>
    <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.status'"/></div>
    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.status'"/></div>
  </div>

  <template
      v-for="(order, index) in item.history.checkOrders">
    <div class="history-table__sub-row"
         :key="index"
    >
      <div class="history-table__sub-col">{{$t('consolidationUnion_order.localization_value.value')}}</div>
      <div class="history-table__sub-col">
        <span v-if="pevItem && _.has(pevItem.history.checkOrders, index)">#{{pevItem.history.checkOrders[index].id}}: {{pevItem.history.checkOrders[index].recipient_contact_name}}</span>
        <template v-else>—</template>
      </div>
      <div class="history-table__sub-col">#{{order.id}}: {{order.recipient_contact_name}}</div>
    </div>
  </template>

  <template v-if="pevItem && pevItem.history.checkOrders.length > item.history.checkOrders.length">
    <template v-for="(order, index) in pevItem.history.checkOrders">
      <div class="history-table__sub-row"
           v-if="index > item.history.checkOrders.length - 1"
           :key="index"
      >
        <div class="history-table__sub-col">{{$t('consolidationUnion_order.localization_value.value')}}</div>
        <div class="history-table__sub-col">#{{order.id}}: {{order.recipient_contact_name}}</div>
        <div class="history-table__sub-col">—</div>
      </div>
    </template>
  </template>



<!--  <div class="history-table__sub-row">-->
<!--    <div class="history-table__sub-col">{{$t('consolidationUnion_consoUser.localization_value.value')}}</div>-->
<!--    <div class="history-table__sub-col">-->
<!--      <div v-for="(order, index) in pevItem.checkOrders" :key="index">-->
<!--        <LinkButton-->
<!--            :value="'#' + order.id"-->
<!--            :type="'edit'"-->
<!--            :target="'_blank'"-->
<!--            :link="$store.getters.GET_PATHS.ordersConsolidationEditing + '/' + getConsolidationTypeById(pevItem.history.delivery_service.id).name + '/' + order.id"-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.consolidation_responsible_person.name'"/></div>-->
<!--  </div>-->

</div>
</template>

<script>
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
// import LinkButton from "@/components/UI/buttons/LinkButton/LinkButton";
export default {
name: "ConsolidationUnionsType",
  components: {
    //LinkButton,
    ValueHelper
  },

  props: {
    pevItem: Object,
    item: Object,
  },

}
</script>

<style scoped>

</style>