<template>
  <div class="fragment">
    <div class="history-table__sub-row"
         v-if="name"
    >
      <div class="history-table__sub-col">
        {{$t('packaging_NamePackage.localization_value.value')}}
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="pevItem"
            :deep="'packaging.package_name'"
        />
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="item"
            :deep="'packaging.package_name'"
        />
      </div>
    </div>
    <div class="history-table__sub-row"
         v-if="weight"
    >
      <div class="history-table__sub-col">
        {{$t('packaging_Weight.localization_value.value')}}
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="pevItem"
            :deep="'packaging.weight'"
        />
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="item"
            :deep="'packaging.weight'"
        />
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('packaging_Height.localization_value.value')}}
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="pevItem"
            :deep="'packaging.height'"
        />
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="item"
            :deep="'packaging.height'"
        />
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('packaging_Width.localization_value.value')}}
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="pevItem"
            :deep="'packaging.width'"
        />
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="item"
            :deep="'packaging.width'"
        />
      </div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('packaging_Length.localization_value.value')}}
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="pevItem"
            :deep="'packaging.length'"
        />
      </div>
      <div class="history-table__sub-col">
        <ValueHelper
            :value="item"
            :deep="'packaging.length'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";

export default {
  name: "PackagingTable",

  components: {
    ValueHelper
  },

  props: {
    pevItem: Object,
    item: Object,
    name: {
      type: Boolean,
      default: true,
    },
    weight: {
      type: Boolean,
      default: true,
    }
  },

}
</script>

<style scoped>

</style>