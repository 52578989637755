import {API} from "../../axios/axiosMainUrl";

export const downloadPlugins = {
  state: {
    downloadPlugins: [],
    downloadPluginsItem: {},
    downloadPluginsCommonList: [],
    downloadPluginsBtn: false,
    downloadPluginsLoading: false,

    headerDefaultDownloadPlugins: {
      opencart: {
        type: 'opencart',
        name: 'Opencart',
        ico: '/img/company-icons-group/tab-m-opencart.png',
      },
      // t: {
      //   type: 't',
      //   name: 't',
      //   ico: '/img/company-icons-group/tab-t.png',
      // },
      woocommerce: {
        type: 'woocommerce',
        name: 'Woocommerce',
        ico: '/img/company-icons-group/tab-m-woo-commerce.png',
      },
    }
  },

  mutations: {
    setDownloadPlugins: (state, payload) => {
      state.downloadPlugins = payload.downloadPlugins
    },
    setDownloadPluginsItem: (state, payload) => {
      state.downloadPluginsItem = payload.downloadPluginsItem
    },

    setDownloadPluginsLoadingStart: (state) => {
      state.downloadPluginsLoading = true
    },

    setDownloadPluginsLoadingEnd: (state) => {
      state.downloadPluginsLoading = false
    },

    changeDownloadPluginsBtn: (state) => {
      state.downloadPluginsBtn = true
    },
    successDownloadPluginsBtn: (state) => {
      state.downloadPluginsBtn = false
    },
  },

  getters: {
    getDownloadPlugins: state => {
      return state.downloadPlugins
    },

    getDownloadPluginsItem: state => {
      return state.downloadPluginsItem
    },

    getDownloadPluginsCommonList: state => {
      return state.downloadPluginsCommonList
    },

    getDownloadPluginsBtn: state => {
      return state.downloadPluginsBtn
    },

    getHeaderDefaultDownloadPlugins: state => {
      return state.headerDefaultDownloadPlugins
    },
  },

  actions: {

    async fetchDownloadPlugins({commit}, filter = '') {
      commit('setDownloadPluginsLoadingStart');
      try {
        return await API.get(`/plugins${filter}`).then((response) =>{
          commit('setDownloadPlugins',{downloadPlugins: response.data.data})
          commit('setDownloadPluginsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setDownloadPluginsLoadingEnd');
        return e;
      }
    },

    async getDownloadPlugins({commit}, id) {
      commit('setDownloadPluginsLoadingStart');
      try {
        return await API.get(`/${id}`, { responseType: 'blob' }).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setDownloadPluginsLoadingEnd');
        return e;
      }
    },

    // async createDownloadPlugins({commit}, data) {
    //   commit('changeDownloadPluginsBtn');
    //   try {
    //     return await API.post(`/downloadPlugins`, data).then((response) =>{
    //       commit('successDownloadPluginsBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successDownloadPluginsBtn');
    //     return e;
    //   }
    // },
    //
    // async updateDownloadPlugins({commit}, {id, data}) {
    //   commit('changeDownloadPluginsBtn');
    //   try {
    //     return await API.put(`/downloadPlugins/${id}`, data).then((response) =>{
    //       commit('successDownloadPluginsBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successDownloadPluginsBtn');
    //     return e;
    //   }
    // },
    //
    // async deleteDownloadPlugins({commit}, id) {
    //   try {
    //     return await API.delete(`/downloadPlugins/${id}`).then((response) =>{
    //       console.log(response);
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successDownloadPluginsBtn');
    //     return e;
    //   }
    // },

  }
}
