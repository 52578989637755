import {API} from "../../axios/axiosMainUrl";

const prefix = '/report/order-outbound-storage-products'

export const generateReport = {
  state: {
    generateReport: [],
    generateReportAM: [],
    generateReportMD: [],
    generateReportCO: [],
    generateReportInUnits: [],
    generateReportItem: {},
    generateReportCommonList: [],
    generateReportCommonListAM: [],
    generateReportCommonListMD: [],
    generateReportCommonListCO: [],
    generateReportCommonListInUnits: [],
    generateReportBtn: false,
    generateReportLoading: true,
    generateReportAMLoading: true,
    generateReportMDLoading: true,
    generateReportCOLoading: true,
    generateReportInUnitsLoading: true,
    nextGenerateReportPage: false,
    generateReportForPage: 3000,
    generateReportFilter: '',
  },

  mutations: {
    setGenerateReportFilter: (state, payload) => {
      state.generateReportFilter = payload
    },

    setGenerateReport: (state, payload) => {
      if(state.nextGenerateReportPage) {
        state.generateReport = state.generateReport.concat(payload.generateReport)
      } else {
        state.generateReport = payload.generateReport
      }
    },

    setGenerateReportAM: (state, payload) => {
      if(state.nextGenerateReportPage) {
        state.generateReportAM = state.generateReportAM.concat(payload.generateReportAM)
      } else {
        state.generateReportAM = payload.generateReportAM
      }
    },

    setGenerateReportMD: (state, payload) => {
      if(state.nextGenerateReportPage) {
        state.generateReportMD = state.generateReportMD.concat(payload.generateReportMD)
      } else {
        state.generateReportMD = payload.generateReportMD
      }
    },

    setGenerateReportCO: (state, payload) => {
      if(state.nextGenerateReportPage) {
        state.generateReportCO = state.generateReportCO.concat(payload.generateReportCO)
      } else {
        state.generateReportCO = payload.generateReportCO
      }
    },

    setGenerateReportInUnits: (state, payload) => {
      if(state.nextGenerateReportPage) {
        state.generateReportInUnits = state.generateReportInUnits.concat(payload.generateReportInUnits)
      } else {
        state.generateReportInUnits = payload.generateReportInUnits
      }
    },

    setGenerateReportCommonList: (state, payload) => {
      state.generateReportCommonList = payload.generateReportCommonList
    },

    setGenerateReportCommonListAM: (state, payload) => {
      state.generateReportCommonListAM = payload.generateReportCommonListAM
    },

    setGenerateReportCommonListMD: (state, payload) => {
      state.generateReportCommonListMD = payload.generateReportCommonListMD
    },

    setGenerateReportCommonListCO: (state, payload) => {
      state.generateReportCommonListCO = payload.generateReportCommonListCO
    },

    setGenerateReportCommonListInUnits: (state, payload) => {
      state.generateReportCommonListInUnits = payload.generateReportCommonListInUnits
    },

    setNextGenerateReportPage: (state, payload) => {
      state.nextGenerateReportPage = payload
    },

    setGenerateReportItem: (state, payload) => {
      state.generateReportItem = payload.generateReportItem
    },

    setGenerateReportLoadingStart: (state) => {
      state.generateReportLoading = true
    },

    setGenerateReportLoadingEnd: (state) => {
      state.generateReportLoading = false
    },

    setGenerateReportAMLoadingStart: (state) => {
      state.generateReportAMLoading = true
    },

    setGenerateReportAMLoadingEnd: (state) => {
      state.generateReportAMLoading = false
    },

    setGenerateReportMDLoadingStart: (state) => {
      state.generateReportMDLoading = true
    },

    setGenerateReportMDLoadingEnd: (state) => {
      state.generateReportMDLoading = false
    },

    setGenerateReportCOLoadingStart: (state) => {
      state.generateReportCOLoading = true
    },

    setGenerateReportCOLoadingEnd: (state) => {
      state.generateReportCOLoading = false
    },

    setGenerateReportInUnitsLoadingStart: (state) => {
      state.generateReportInUnitsLoading = true
    },

    setGenerateReportInUnitsLoadingEnd: (state) => {
      state.generateReportInUnitsLoading = false
    },

    changeGenerateReportBtn: (state) => {
      state.generateReportBtn = true
    },
    successGenerateReportBtn: (state) => {
      state.generateReportBtn = false
    },
  },

  getters: {
    getGenerateReportFilter: state => {
      return state.generateReportFilter
    },

    getGenerateReportForPage: state => {
      return state.generateReportForPage
    },

    getGenerateReport: state => {
      return state.generateReport
    },

    getGenerateReportAM: state => {
      return state.generateReportAM
    },

    getGenerateReportMD: state => {
      return state.generateReportMD
    },

    getGenerateReportCO: state => {
      return state.generateReportCO
    },

    getGenerateReportInUnits: state => {
      return state.generateReportInUnits
    },

    getGenerateReportLoading: state => {
      return state.generateReportLoading
    },

    getGenerateReportAMLoading: state => {
      return state.generateReportAMLoading
    },

    getGenerateReportMDLoading: state => {
      return state.generateReportMDLoading
    },

    getGenerateReportCOLoading: state => {
      return state.generateReportCOLoading
    },

    getGenerateReportInUnitsLoading: state => {
      return state.generateReportInUnitsLoading
    },

    getNextGenerateReportPage: state => {
      return state.nextGenerateReportPage
    },

    getGenerateReportItem: state => {
      return state.generateReportItem
    },

    getGenerateReportCommonList: state => {
      return state.generateReportCommonList
    },

    getGenerateReportCommonListAM: state => {
      return state.generateReportCommonListAM
    },

    getGenerateReportCommonListMD: state => {
      return state.generateReportCommonListMD
    },

    getGenerateReportCommonListCO: state => {
      return state.generateReportCommonListCO
    },

    getGenerateReportCommonListInUnits: state => {
      return state.generateReportCommonListInUnits
    },

    getGenerateReportBtn: state => {
      return state.generateReportBtn
    },
  },

  actions: {

    async incrementReportPrint({ commit }, data) {
      try {
        const response = await API.get(`/report/order-outbound-storage-products/fbm/print${data}`);
        commit('setLoadingEnd');

        return  response.data.data;
      } catch (err) {
        commit('setLoadingEnd');
        return err;
      }
    },

    async fetchGenerateReport({commit, getters}, filter = '') {
      commit('setGenerateReport',{generateReport: []})
      if(!getters.getNextGenerateReportPage)
        commit('setGenerateReportLoadingStart')
      try {
        return await API.get(`${prefix}/fbm${filter}`).then((response) =>{
          commit('setGenerateReportAM',{ generateReportAM: response.data.data.before12.data })
          commit('setGenerateReportMD',{ generateReportMD: response.data.data.between12and14.data })
          commit('setGenerateReport',{ generateReport: response.data.data.after14.data })

          commit('setGenerateReportCommonListAM',{ generateReportCommonListAM: response.data.data.before12 })
          commit('setGenerateReportCommonListMD',{ generateReportCommonListMD: response.data.data.between12and14 })
          commit('setGenerateReportCommonList',{ generateReportCommonList: response.data.data.after14 })
          commit('setGenerateReportLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setGenerateReportLoadingEnd');
        return e;
      }
    },

    async fetchGenerateReportBeforeHTML({commit, getters}, filter = '') {
      commit('setGenerateReportAM',{generateReportAM: []})
      if(!getters.getNextGenerateReportPage)
        commit('setGenerateReportAMLoadingStart')
      try {
        return await API.get(`${prefix}/fbm/html${filter}`).then((response) =>{
          commit('setGenerateReportAM',{generateReportAM: response.data.data.before12.data})
          commit('setGenerateReportCommonListAM',{generateReportCommonListAM: response.data.data.before12})
          commit('setGenerateReportAMLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setGenerateReportAMLoadingEnd');
        return e;
      }
    },

    async fetchGenerateReportBefore({commit, getters}, filter = '') {
      commit('setGenerateReportAM',{generateReportAM: []})
      if(!getters.getNextGenerateReportPage)
        commit('setGenerateReportAMLoadingStart')
      try {
        return await API.get(`${prefix}/fbm${filter}`).then((response) =>{
          commit('setGenerateReportAM',{generateReportAM: response.data.data.before12.data})
          commit('setGenerateReportCommonListAM',{generateReportCommonListAM: response.data.data.before12})
          commit('setGenerateReportAMLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setGenerateReportAMLoadingEnd');
        return e;
      }
    },

    async fetchGenerateReportBetween({commit, getters}, filter = '') {
      commit('setGenerateReportMD',{generateReportMD: []})
      if(!getters.getNextGenerateReportPage)
        commit('setGenerateReportMDLoadingStart')
      try {
        return await API.get(`${prefix}/fbm${filter}`).then((response) =>{
          commit('setGenerateReportMD',{generateReportMD: response.data.data.between12and14.data})
          commit('setGenerateReportCommonListMD',{generateReportCommonListMD: response.data.data.between12and14})
          commit('setGenerateReportMDLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setGenerateReportMDLoadingEnd');
        return e;
      }
    },


    async fetchGenerateReportAfter({commit, getters}, filter = '') {
      commit('setGenerateReport',{generateReport: []})
      if(!getters.getNextGenerateReportPage)
        commit('setGenerateReportLoadingStart')
      try {
        return await API.get(`${prefix}/fbm${filter}`).then((response) =>{

          commit('setGenerateReport',{generateReport: response.data.data.after14.data})
          commit('setGenerateReportCommonList',{generateReportCommonList: response.data.data.after14})
          commit('setGenerateReportLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setGenerateReportLoadingEnd');
        return e;
      }
    },

    async fetchCurrentOrders({commit, getters}, filter = '') {
      commit('setGenerateReportCO',{generateReportCO: []})
      if(!getters.getNextGenerateReportPage)
        commit('setGenerateReportCOLoadingStart')
      try {
        return await API.get(`/orders/fbm-on-bin${filter}`).then((response) =>{
          console.log(response.data.data.data);
          console.log(response?.data?.data?.data || []);
          commit('setGenerateReportCO',{generateReportCO: response?.data?.data?.data || []})
          commit('setGenerateReportCommonListCO',{generateReportCommonListCO: response?.data?.data})
          commit('setGenerateReportCOLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setGenerateReportCOLoadingEnd');
        return e;
      }
    },

    async fetchOrdersInUnits({commit, getters}, filter = '') {
      commit('setGenerateReportInUnits',{generateReportInUnits: []})
      if(!getters.getNextGenerateReportPage)
        commit('setGenerateReportInUnitsLoadingStart')
      try {
        return await API.get(`/orders/fbm-on-bin/assigned-unit${filter}`).then((response) =>{
          commit('setGenerateReportInUnits',{generateReportInUnits: response?.data?.data?.data || []})
          commit('setGenerateReportCommonListInUnits',{generateReportCommonListInUnits: response?.data?.data})
          commit('setGenerateReportInUnitsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setGenerateReportInUnitsLoadingEnd');
        return e;
      }
    },

    async fetchGenerateReportFBA({commit, getters}, filter = '') {
      if(!getters.getNextGenerateReportPage)
        commit('setGenerateReportLoadingStart')
      try {
        return await API.get(`${prefix}/fba${filter}`).then((response) =>{
          commit('setGenerateReportAM',{ generateReportAM: response.data.data.before12.data })
          commit('setGenerateReportMD',{ generateReportMD: response.data.data.between12and14.data })
          commit('setGenerateReport',{ generateReport: response.data.data.after14.data })

          commit('setGenerateReportCommonListAM',{ generateReportCommonListAM: response.data.data.before12 })
          commit('setGenerateReportCommonListMD',{ generateReportCommonListMD: response.data.data.between12and14 })
          commit('setGenerateReportCommonList',{ generateReportCommonList: response.data.data.after14 })
          commit('setGenerateReportLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setGenerateReportLoadingEnd');
        return e;
      }
    },

    // async fetchGenerateReportFBAMD({commit, getters}, filter = '') {
    //   commit('setGenerateReportMD',{generateReportMD: []})
    //   if(!getters.getNextGenerateReportPage)
    //     commit('setGenerateReportMDLoadingStart')
    //   try {
    //     return await API.get(`${prefix}/fbm${filter}`).then((response) =>{
    //       commit('setGenerateReportMD',{generateReportMD: response.data.data.data})
    //       commit('setGenerateReportCommonListMD',{generateReportCommonListMD: response.data.data})
    //       commit('setGenerateReportMDLoadingEnd');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('setGenerateReportMDLoadingEnd');
    //     return e;
    //   }
    // },
    //
    // async fetchGenerateReportFBAAM({commit, getters}, filter = '') {
    //   if(!getters.getNextGenerateReportPage)
    //     commit('setGenerateReportAMLoadingStart')
    //   try {
    //     return await API.get(`${prefix}/fba${filter}`).then((response) =>{
    //       commit('setGenerateReportAM',{generateReportAM: response.data.data.data})
    //       commit('setGenerateReportCommonListAM',{generateReportCommonListAM: response.data.data})
    //       commit('setGenerateReportAMLoadingEnd');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('setGenerateReportAMLoadingEnd');
    //     return e;
    //   }
    // },

    async getGenerateReport({commit}, id) {
      commit('setGenerateReportLoadingStart');
      try {
        return await API.get(`/generateReport/${id}`).then((response) =>{
          commit('setGenerateReportItem', {generateReportItem: response.data.data})
          commit('setGenerateReportLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setGenerateReportLoadingEnd');
        return e;
      }
    },

    async createGenerateReport({commit}, data) {
      commit('changeGenerateReportBtn');
      try {
        return await API.post(`/generateReport`, data).then((response) =>{
          commit('successGenerateReportBtn');
          return response;
        });
      } catch (e) {
        commit('successGenerateReportBtn');
        return e;
      }
    },

    async updateGenerateReport({commit}, {id, data}) {
      commit('changeGenerateReportBtn');
      try {
        return await API.put(`/generateReport/${id}`, data).then((response) =>{
          commit('successGenerateReportBtn');
          return response;
        });
      } catch (e) {
        commit('successGenerateReportBtn');
        return e;
      }
    },

    async deleteGenerateReport({commit}, id) {
      try {
        return await API.delete(`/generateReport/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successGenerateReportBtn');
        return e;
      }
    },

  }
}
