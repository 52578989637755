
import {routerPaths} from '../../../router/routerPaths'


const DownloadPluginsTablePage = () => import(/* webpackChunkName: "group-import-orders" */ './components/DownloadPluginsTablePage/DownloadPluginsTablePage.vue')
const moduleKey = 'download-plugins'

export const DownloadPluginsRoutes = [
  {
    path: routerPaths.downloadPlugins,
    component: DownloadPluginsTablePage,
    meta: {
      breadcrumbs: {
        key: [
          'settingsModules'
        ],
        items: {
          'settingsModules': {
            name: 'menu_modules',
            link: routerPaths.downloadPlugins,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'menu_modules'
    },
  }
]
