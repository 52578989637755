
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const BelposhtaTable = () => import(/* webpackChunkName: "group-belposhta" */ './components/BelposhtaTable/BelposhtaTable.vue')
const BelposhtaCreation = () => import(/* webpackChunkName: "group-belposhta" */ './components/BelposhtaCreation/BelposhtaCreation.vue')
const BelposhtaEditing = () => import(/* webpackChunkName: "group-belposhta" */ './components/BelposhtaEditing/BelposhtaEditing.vue')
const BelposhtaShow = () => import(/* webpackChunkName: "group-belposhta" */ './components/BelposhtaShow/BelposhtaShow.vue')
const moduleKey = 'belposhta'


export const BelposhtaRoutes = [
  {
    path: routerPaths.belposhta,
    component: BelposhtaTable,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'belposhta'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'belposhta': {
            name: 'menu_belposhta',
            link: routerPaths.belposhta,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_INTERNAL_BELPOSHTA],

      moduleKey: moduleKey,
      pageTitle: 'menu_belposhta'
    },
  },

  {
    path: routerPaths.belposhtaCreationPayments,
    component: BelposhtaCreation,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'belposhta',
          'belposhtaCreation',
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'belposhta': {
            name: 'menu_belposhta',
            link: routerPaths.belposhta,
          },
          'belposhtaCreation': {
            name: 'breadcrumbs_NewOrderBelposhta',
            link: routerPaths.belposhtaCreation,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_INTERNAL_BELPOSHTA],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewOrderBelposhta'
    },
  },

  {
    path: routerPaths.belposhtaCreation,
    component: BelposhtaCreation,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'belposhta',
          'belposhtaCreation',
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'belposhta': {
            name: 'menu_belposhta',
            link: routerPaths.belposhta,
          },
          'belposhtaCreation': {
            name: 'breadcrumbs_NewOrderBelposhta',
            link: routerPaths.belposhtaCreation,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_INTERNAL_BELPOSHTA],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewOrderBelposhta'
    },
  },

  {
    path: routerPaths.belposhtaEditing,
    component: BelposhtaEditing,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'belposhta',
          'belposhtaCreation',
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'belposhta': {
            name: 'menu_belposhta',
            link: routerPaths.belposhta,
          },
          'belposhtaCreation': {
            name: 'breadcrumbs_OrderBelposhta',
            link: routerPaths.belposhtaEditing,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_INTERNAL_BELPOSHTA],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_OrderBelposhta'
    },
  },

  {
    path: routerPaths.belposhtaShow,
    component: BelposhtaShow,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'belposhta',
          'belposhtaShow',
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'belposhta': {
            name: 'menu_belposhta',
            link: routerPaths.belposhta,
          },
          'belposhtaShow': {
            name: 'breadcrumbs_OrderBelposhta',
            link: routerPaths.belposhtaShow,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_INTERNAL_BELPOSHTA],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_OrderBelposhta'
    },
  },
]
