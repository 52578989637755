import {API} from "../../axios/axiosMainUrl";

const apiOrderInternal = 'api-order-internal'

export const belPoshta = {
  state: {
    belPoshta: [],
    belPoshtaItem: {},
    belPoshtaCommonList: [],
    belPoshtaBtn: false,
    belPoshtaLoading: true,
    nextBelPoshtaPage: false,
    belPoshtaForPage: 25,
    belPoshtaFilter: '',
  },

  mutations: {
    setBelPoshtaFilter: (state, payload) => {
      state.belPoshtaFilter = payload
    },

    setBelPoshta: (state, payload) => {
      if(state.nextBelPoshtaPage) {
        state.belPoshta = state.belPoshta.concat(payload.belPoshta)
      } else {
        state.belPoshta = payload.belPoshta
      }
    },

    setBelPoshtaCommonList: (state, payload) => {
      state.belPoshtaCommonList = payload.belPoshtaCommonList
    },

    setNextBelPoshtaPage: (state, payload) => {
      state.nextBelPoshtaPage = payload
    },

    setBelPoshtaItem: (state, payload) => {
      state.belPoshtaItem = payload.belPoshtaItem
    },

    setBelPoshtaLoadingStart: (state) => {
      state.belPoshtaLoading = true
    },

    setBelPoshtaLoadingEnd: (state) => {
      state.belPoshtaLoading = false
    },

    changeBelPoshtaBtn: (state) => {
      state.belPoshtaBtn = true
    },
    successBelPoshtaBtn: (state) => {
      state.belPoshtaBtn = false
    },
  },

  getters: {
    getBelPoshtaFilter: state => {
      return state.belPoshtaFilter
    },

    getBelPoshtaForPage: state => {
      return state.belPoshtaForPage
    },

    getBelPoshta: state => {
      return state.belPoshta
    },

    getBelPoshtaLoading: state => {
      return state.belPoshtaLoading
    },


    getNextBelPoshtaPage: state => {
      return state.nextBelPoshtaPage
    },

    getBelPoshtaItem: state => {
      return state.belPoshtaItem
    },

    getBelPoshtaCommonList: state => {
      return state.belPoshtaCommonList
    },

    getBelPoshtaBtn: state => {
      return state.belPoshtaBtn
    },
  },

  actions: {

    async fetchBelPoshta({commit, getters}, filter = '') {
      if(!getters.getNextBelPoshtaPage)
        commit('setBelPoshtaLoadingStart')
      try {
        return await API.get(`/belPoshta${filter}`).then((response) =>{
          commit('setBelPoshta',{belPoshta: response.data.data.data})
          commit('setBelPoshtaCommonList',{belPoshtaCommonList: response.data.data})
          commit('setBelPoshtaLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setBelPoshtaLoadingEnd');
        return e;
      }
    },

    async getBelPoshta({commit}, id) {
      commit('setBelPoshtaLoadingStart');
      try {
        return await API.get(`/belPoshta/${id}`).then((response) =>{
          console.log(response)
          commit('setBelPoshtaItem', {belPoshtaItem: response.data.data})
          commit('setBelPoshtaLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setBelPoshtaLoadingEnd');
        return e;
      }
    },


    // async createBelPoshta({commit}, data) {
    //   commit('changeBelPoshtaBtn');
    //   try {
    //     return await API.post(`${orderInternal}`, data).then((response) =>{
    //       commit('successBelPoshtaBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successBelPoshtaBtn');
    //     return e;
    //   }
    // },


    async updateBelPoshta({commit}, {id, data}) {
      commit('changeBelPoshtaBtn');
      try {
        return await API.put(`/belPoshta/${id}`, data).then((response) =>{
          commit('successBelPoshtaBtn');
          return response;
        });
      } catch (e) {
        commit('successBelPoshtaBtn');
        return e;
      }
    },

    async deleteBelPoshta({commit}, id) {
      try {
        return await API.delete(`/belPoshta/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successBelPoshtaBtn');
        return e;
      }
    },


    async createBelPoshtaLabel({commit}, id) {
      commit('changeBelPoshtaBtn');
      try {
        return await API.post(`${apiOrderInternal}/belposhta/${id}/create-label`).then((response) =>{
          commit('successBelPoshtaBtn');
          return response;
        });
      } catch (e) {
        commit('successBelPoshtaBtn');
        return e;
      }
    },

  }
}
