import {API} from "../../axios/axiosMainUrl";

const prefix = 'payments/forbidden-items-keywords'

export const shippingInsurances = {
  state: {
    shippingInsurances: [],
    shippingInsurancesItem: {},
    shippingInsurancesCommonList: [],
    shippingInsurancesBtn: false,
    shippingInsurancesLoading: true,
    nextShippingInsurancesPage: false,
    shippingInsurancesForPage: 25,
    shippingInsurancesFilter: '',
  },

  mutations: {
    setShippingInsurancesFilter: (state, payload) => {
      state.shippingInsurancesFilter = payload
    },

    setShippingInsurances: (state, payload) => {
      if(state.nextShippingInsurancesPage) {
        state.shippingInsurances = state.shippingInsurances.concat(payload.shippingInsurances)
      } else {
        state.shippingInsurances = payload.shippingInsurances
      }
    },

    setShippingInsurancesCommonList: (state, payload) => {
      state.shippingInsurancesCommonList = payload.shippingInsurancesCommonList
    },

    setNextShippingInsurancesPage: (state, payload) => {
      state.nextShippingInsurancesPage = payload
    },

    setShippingInsurancesItem: (state, payload) => {
      state.shippingInsurancesItem = payload.shippingInsurancesItem
    },

    setShippingInsurancesLoadingStart: (state) => {
      state.shippingInsurancesLoading = true
    },

    setShippingInsurancesLoadingEnd: (state) => {
      state.shippingInsurancesLoading = false
    },

    changeShippingInsurancesBtn: (state) => {
      state.shippingInsurancesBtn = true
    },
    successShippingInsurancesBtn: (state) => {
      state.shippingInsurancesBtn = false
    },
  },

  getters: {
    getShippingInsurancesFilter: state => {
      return state.shippingInsurancesFilter
    },

    getShippingInsurancesForPage: state => {
      return state.shippingInsurancesForPage
    },

    getShippingInsurances: state => {
      return state.shippingInsurances
    },

    getShippingInsurancesLoading: state => {
      return state.shippingInsurancesLoading
    },


    getNextShippingInsurancesPage: state => {
      return state.nextShippingInsurancesPage
    },

    getShippingInsurancesItem: state => {
      return state.shippingInsurancesItem
    },

    getShippingInsurancesCommonList: state => {
      return state.shippingInsurancesCommonList
    },

    getShippingInsurancesBtn: state => {
      return state.shippingInsurancesBtn
    },
  },

  actions: {

    async fetchShippingInsurances({commit, getters}, filter = '') {
      if(!getters.getNextShippingInsurancesPage)
        commit('setShippingInsurancesLoadingStart')
      try {
        return await API.get(`/${prefix}${filter}`).then((response) =>{
          commit('setShippingInsurances',{shippingInsurances: response.data.data.data})
          commit('setShippingInsurancesCommonList',{shippingInsurancesCommonList: response.data.data})
          commit('setShippingInsurancesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setShippingInsurancesLoadingEnd');
        return e;
      }
    },

    async getShippingInsurances({commit}, id) {
      try {
        return await API.get(`/${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setShippingInsurancesItem', {shippingInsurancesItem: response.data.data})
          commit('setShippingInsurancesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setShippingInsurancesLoadingEnd');
        return e;
      }
    },

    async createShippingInsurances({commit}, data) {
      commit('changeShippingInsurancesBtn');
      try {
        return await API.post(`/${prefix}`, data).then((response) =>{
          commit('successShippingInsurancesBtn');
          return response;
        });
      } catch (e) {
        commit('successShippingInsurancesBtn');
        return e;
      }
    },

    async updateShippingInsurances({commit}, {id, data}) {
      commit('changeShippingInsurancesBtn');
      try {
        return await API.put(`/${prefix}/${id}`, data).then((response) =>{
          commit('successShippingInsurancesBtn');
          return response;
        });
      } catch (e) {
        commit('successShippingInsurancesBtn');
        return e;
      }
    },

    async deleteShippingInsurances({commit}, id) {
      try {
        return await API.delete(`/${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successShippingInsurancesBtn');
        return e;
      }
    },

  }
}
