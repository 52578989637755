
import {routerPaths} from '../../../router/routerPaths'

const ShippingInsurancesTable = () => import(/* webpackChunkName: "group-other-shippers" */ './components/ShippingInsurancesTable/ShippingInsurancesTable.vue')
const moduleKey = 'shipping-insurances'


export const ShippingInsurancesRoutes = [
  {
    path: routerPaths.shippingInsurances,
    component: ShippingInsurancesTable,
    meta: {
      breadcrumbs: {
        key: [
          'menu_settings',
          'mainSettingsShippingInsurances'
        ],
        items: {
          'menu_settings': {
            name: 'menu_settings',
            link: routerPaths.shippingInsurances,
          },
          'mainSettingsShippingInsurances': {
            name: 'menu_shippingInsurances',
            link: routerPaths.shippingInsurances,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'menu_shippingInsurances'
    },
  }
]
