import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const AuthTokensTable = () => import(/* webpackChunkName: "group-auth-tokens" */ './components/AuthTokensTable/AuthTokensTable.vue')
const moduleKey = 'auth-tokens'

export const AuthTokensRoutes = [
  {
    path: routerPaths.authTokens,
    component: AuthTokensTable,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettings',
          'authTokens'
        ],
        items: {
          'mainSettings': {
            name: 'menu_settings',
            link: routerPaths.authTokens,
          },
          'authTokens': {
            name: 'menu_authTokens',
            link: routerPaths.authTokens,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.AUTH_TOKENS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_authTokens'
    },
  },
]
