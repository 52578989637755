import {API} from "../../axios/axiosMainUrl";

const accessControlsPrefix = '/access-controls'
const usersManagePrefix = '/users/manage-access'

export const manageUsers = {
  state: {
    manageUsers: [],
    manageUsersItem: {},
    manageUsersCommonList: [],
    manageUsersBtn: false,
    manageUsersLoading: true,
    nextManageUsersPage: false,
    manageUsersForPage: 25,
    manageUsersFilter: '',
  },

  mutations: {
    setManageUsersFilter: (state, payload) => {
      state.manageUsersFilter = payload
    },

    setManageUsers: (state, payload) => {
      if(state.nextManageUsersPage) {
        state.manageUsers = state.manageUsers.concat(payload.manageUsers)
      } else {
        state.manageUsers = payload.manageUsers
      }
    },

    setManageUsersCommonList: (state, payload) => {
      state.manageUsersCommonList = payload.manageUsersCommonList
    },

    setNextManageUsersPage: (state, payload) => {
      state.nextManageUsersPage = payload
    },

    setManageUsersItem: (state, payload) => {
      state.manageUsersItem = payload.manageUsersItem
    },

    setManageUsersLoadingStart: (state) => {
      state.manageUsersLoading = true
    },

    setManageUsersLoadingEnd: (state) => {
      state.manageUsersLoading = false
    },

    changeManageUsersBtn: (state) => {
      state.manageUsersBtn = true
    },
    successManageUsersBtn: (state) => {
      state.manageUsersBtn = false
    },
  },

  getters: {
    getManageUsersFilter: state => {
      return state.manageUsersFilter
    },

    getManageUsersForPage: state => {
      return state.manageUsersForPage
    },

    getManageUsers: state => {
      return state.manageUsers
    },

    getManageUsersLoading: state => {
      return state.manageUsersLoading
    },


    getNextManageUsersPage: state => {
      return state.nextManageUsersPage
    },

    getManageUsersItem: state => {
      return state.manageUsersItem
    },

    getManageUsersCommonList: state => {
      return state.manageUsersCommonList
    },

    getManageUsersBtn: state => {
      return state.manageUsersBtn
    },
  },

  actions: {

    async getSystemRoles({commit}) {
      try {
        return await API.get(`${accessControlsPrefix}/list/roles`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setManageUsersLoadingEnd');
        return e;
      }
    },

    async getSystemPermissions({commit}) {
      try {
        return await API.get(`${accessControlsPrefix}/list/permission-groups `).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setManageUsersLoadingEnd');
        return e;
      }
    },

    async fetchManageUsers({commit, getters}, filter = '') {
      if(!getters.getNextManageUsersPage)
        commit('setManageUsersLoadingStart')
      try {
        return await API.get(`${usersManagePrefix}${filter}`).then((response) =>{
          commit('setManageUsers',{manageUsers: response.data.data.data})
          commit('setManageUsersCommonList',{manageUsersCommonList: response.data.data})
          commit('setManageUsersLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setManageUsersLoadingEnd');
        return e;
      }
    },

    async getManageUsers({commit}, id) {
      commit('setManageUsersLoadingStart');
      try {
        return await API.get(`${usersManagePrefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setManageUsersItem', {manageUsersItem: response.data.data})
          commit('setManageUsersLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setManageUsersLoadingEnd');
        return e;
      }
    },

    async getManageUsersCreate({commit}) {
      commit('setManageUsersLoadingStart');
      try {
        return await API.get(`${usersManagePrefix}/create`).then((response) =>{
          console.log(response)
          // commit('setManageUsersLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setManageUsersLoadingEnd');
        return e;
      }
    },

    async createManageUsers({commit}, data) {
      commit('changeManageUsersBtn');
      try {
        return await API.post(`${usersManagePrefix}`, data).then((response) =>{
          commit('successManageUsersBtn');
          return response;
        });
      } catch (e) {
        commit('successManageUsersBtn');
        return e;
      }
    },

    async updateManageUsers({commit}, {id, data}) {
      commit('changeManageUsersBtn');
      try {
        return await API.put(`${usersManagePrefix}/${id}`, data).then((response) =>{
          commit('successManageUsersBtn');
          return response;
        });
      } catch (e) {
        commit('successManageUsersBtn');
        return e;
      }
    },

    async deleteManageUsers({commit}, id) {
      try {
        return await API.delete(`${usersManagePrefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successManageUsersBtn');
        return e;
      }
    },

    async getExportManageUsers({commit}, {filter , type}) {
      commit('changeManageUsersBtn');
      try {
        return await API.get(`${usersManagePrefix}/export-all-permissions/${type}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('successManageUsersBtn')
          return response
        })
      } catch (e) {
        commit('successManageUsersBtn')
        return e;
      }
    },

  }
}
