import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const TrackTest = () => import(/* webpackChunkName: "group-track-test" */ './components/TrackTest/TrackTest.vue')
const moduleKey = 'track-test'

export const TrackTestRoutes = [
  {
    path: routerPaths.trackTest,
    component: TrackTest,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettings',
          'trackTest'
        ],
        items: {
          'mainSettings': {
            name: 'menu_settings',
            link: routerPaths.trackTest,
          },
          'trackTest': {
            name: 'menu_trackTest',
            link: routerPaths.trackTest,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.REPORT_SEND_TRACKING_NUMBER_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_trackTest'
    },
  },
]
