<template>
  <div class="fragment">
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('siteSetting_InsuranceForOrders.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.percent_order_outbound_insurance_cost'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.percent_order_outbound_insurance_cost'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('siteSetting_DHLBased.localization_value.value')}}</b></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('siteSetting_Ukraine.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.percent_dhl_ua'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.percent_dhl_ua'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('siteSetting_America.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.percent_dhl_us'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.percent_dhl_us'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('siteSetting_Belarus.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.percent_dhl_by'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.percent_dhl_by'"/></div>
    </div>


    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('siteSetting_TNTBased.localization_value.value')}}</b></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('siteSetting_TNTPercentShippingPrice.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.percent_tnt_default'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.percent_tnt_default'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('siteSetting_FedExBased.localization_value.value')}}</b></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('siteSetting_FedexPercentShippingPriceUa.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.percent_fedex_default'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.percent_fedex_default'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('siteSetting_FedexPercentShippingPriceRu.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.percent_fedex_russia'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.percent_fedex_russia'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('siteSetting_FedexPercentShippingPriceBaltic.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.percent_fedex_baltic_plus'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.percent_fedex_baltic_plus'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('siteSetting_CDEKBased.localization_value.value')}}</b></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('siteSetting_CDEKPercentShippingPrice.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.percent_cdek_default'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.percent_cdek_default'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('siteSetting_RestrictionsSum.localization_value.value')}}</b></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('siteSetting_RestrictionsSumMinimumLimit.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.output_kit_group_minimum_limit'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.output_kit_group_minimum_limit'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('siteSetting_RestrictionsSumPerDay.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.output_ua_card_day_limit'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.output_ua_card_day_limit'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('siteSetting_RestrictionsSumPerWeek.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.output_ua_card_week_limit'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.output_ua_card_week_limit'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('siteSetting_RestrictionsSumPerMonth.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.output_ua_card_month_limit'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.output_ua_card_month_limit'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('siteSetting_OutputCommission.localization_value.value')}}</b></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('siteSetting_OutputCommission500.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.output_ua_commission_charge_500'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.output_ua_commission_charge_500'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('siteSetting_OutputCommission1000.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.output_ua_commission_charge_1000'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.output_ua_commission_charge_1000'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('siteSetting_OutputCommission3000.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.output_ua_commission_charge_3000'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.output_ua_commission_charge_3000'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('siteSetting_balanceUnderZero.localization_value.value')}}</b></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('siteSetting_turnOnNotificationsNBOne.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_negative_balance_min_limit'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_negative_balance_min_limit'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('siteSetting_turnOnNotificationsNBTwo.localization_value.value') }} {{$t('siteSetting_days.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_negative_balance_max_days'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_negative_balance_max_days'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('siteSetting_turnOffFuncNBOne.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.negative_balance_limit_for_restriction'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.negative_balance_limit_for_restriction'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('siteSetting_turnOffFuncNBTwo.localization_value.value') }} {{$t('siteSetting_days.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.max_days_with_negative_balance'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.max_days_with_negative_balance'"/></div>
    </div>


  </div>
</template>

<script>
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
export default {
name: "MainSettingsType",
  components: {ValueHelper},
  props: {
    pevItem: Object,
    item: Object,
  },

}
</script>

<style scoped>

</style>