import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const PackagingTypesTable = () => import(/* webpackChunkName: "group-packaging-types" */ './components/PackagingTypesTable/PackagingTypesTable.vue')
const PackagingTypesCreate = () => import(/* webpackChunkName: "group-packaging-types" */ './components/PackagingTypesCreate/PackagingTypesCreate.vue')
const PackagingTypesEdit = () => import(/* webpackChunkName: "group-packaging-types" */ './components/PackagingTypesEdit/PackagingTypesEdit.vue')
const CustomizationOfferCreate = () => import(/* webpackChunkName: "group-customization-offer" */ './components/CustomizationOfferCreate/CustomizationOfferCreate.vue')
const CustomizationOfferEdit = () => import(/* webpackChunkName: "group-customization-offer" */ './components/CustomizationOfferEdit/CustomizationOfferEdit.vue')
const moduleKey = 'packaging-types'

export const PackagingTypesRoutes = [
  {
    path: routerPaths.mainSettingsPackagingTypes,
    component: PackagingTypesTable,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettings',
          'mainSettingsPackagingTypes',
        ],
        items: {
          'mainSettings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsPackagingTypes,
          },
          'mainSettingsPackagingTypes': {
            name: 'breadcrumbs_packagingTypes',
            link: routerPaths.mainSettingsPackagingTypes,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.PACKING_PRICE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_packagingTypes'
    },
  },
  {
    path: routerPaths.mainSettingsPackagingTypesCreate,
    component: PackagingTypesCreate,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettings',
          'mainSettingsPackagingTypes',
          'mainSettingsPackagingTypesCreate',
        ],
        items: {
          'mainSettings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsPackagingTypes,
          },
          'mainSettingsPackagingTypes': {
            name: 'breadcrumbs_packagingTypes',
            link: routerPaths.mainSettingsPackagingTypes,
          },
          'mainSettingsPackagingTypesCreate': {
            name: 'breadcrumbs_packagingTypesCreate',
            link: routerPaths.mainSettingsPackagingTypesCreate,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.PACKING_PRICE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_packagingTypesCreate'
    },
  },
  {
    path: routerPaths.mainSettingsPackagingTypesEdit,
    component: PackagingTypesEdit,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettings',
          'mainSettingsPackagingTypes',
          'mainSettingsPackagingTypesEdit',
        ],
        items: {
          'mainSettings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsPackagingTypes,
          },
          'mainSettingsPackagingTypes': {
            name: 'breadcrumbs_packagingTypes',
            link: routerPaths.mainSettingsPackagingTypes,
          },
          'mainSettingsPackagingTypesEdit': {
            name: 'breadcrumbs_packagingTypesEdit',
            link: routerPaths.mainSettingsPackagingTypes,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.PACKING_PRICE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_packagingTypesEdit'
    },
  },


  {
    path: routerPaths.mainSettingsCustomizationOfferCreateType,
    component: CustomizationOfferCreate,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettings',
          'mainSettingsCustomizationOffer',
          'mainSettingsCustomizationOfferCreate',
        ],
        items: {
          'mainSettings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsPackagingTypes,
          },
          'mainSettingsCustomizationOffer': {
            name: 'breadcrumbs_packagingTypes',
            link: routerPaths.mainSettingsPackagingTypes,
          },
          'mainSettingsCustomizationOfferCreate': {
            name: 'breadcrumbs_customizationOfferCreate',
            link: routerPaths.mainSettingsPackagingTypes,
          },
        },
      },

      // middleware: [permissions],
      // permissions: [PERMISSIONS.PACKING_PRICE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_customizationOfferCreate'
    },
  },
  {
    path: routerPaths.mainSettingsCustomizationOfferEditType,
    component: CustomizationOfferEdit,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettings',
          'mainSettingsCustomizationOffer',
          'mainSettingsCustomizationOfferEdit',
        ],
        items: {
          'mainSettings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsPackagingTypes,
          },
          'mainSettingsCustomizationOffer': {
            name: 'breadcrumbs_packagingTypes',
            link: routerPaths.mainSettingsPackagingTypes,
          },
          'mainSettingsCustomizationOfferEdit': {
            name: 'breadcrumbs_customizationOfferEdit',
            link: routerPaths.mainSettingsPackagingTypes,
          },
        },
      },
      //
      // middleware: [permissions],
      // permissions: [PERMISSIONS.PACKING_PRICE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_customizationOfferEdit'
    },
  },
]
