
import {routerPaths} from '../../../router/routerPaths'
// import permissions from "../../../router/middleware/permissions";
// import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const CustomInfo = () => import(/* webpackChunkName: "group-customs-info" */ './components/CustomInfo/CustomInfo.vue')
const moduleKey = 'customs-info'

export const CustomsInfoRoutes = [
  {
    path: routerPaths.mainSettingsCustomsInformation,
    component: CustomInfo,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettings',
          'mainSettingsCustomsInformation'
        ],
        items: {
          'mainSettings': {
            name: 'menu_settings',
            link: routerPaths.mainSettings,
          },
          'mainSettingsCustomsInformation': {
            name: 'menu_customsInformation',
            link: routerPaths.mainSettingsCustomsInformation,
          },
        },
      },

      // middleware: [permissions],
      // permissions: [PERMISSIONS.DELIVERY_COUNTRY_CUSTOMS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_customsInformation'
    },
  },
]
