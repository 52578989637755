<template>
  <div class="fragment">
    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('manageUsers_GeneralInformation.localization_value.value')}}</b></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('common_name.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.name'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('common_surName.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.last_name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.last_name'"/></div>
    </div>
<!--    <div class="history-table__sub-row">-->
<!--      <div class="history-table__sub-col">{{ $t('login_createPassword.localization_value.value') }}</div>-->
<!--      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.recipient_contact_name'"/></div>-->
<!--      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.recipient_contact_name'"/></div>-->
<!--    </div>-->
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('common_phone.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.phone'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.phone'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('login_email.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.email'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.email'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('manageUsers_Location.localization_value.value')}}</b></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('common_address.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.address'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.address'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('common_city.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.city'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.city'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('common_country.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.country.name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.country.name'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('common_region.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.country_region.name'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.country_region.name'"/></div>
    </div>
    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{ $t('common_postNumber.localization_value.value') }}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.zip'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.zip'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('manageUsers_Permissions.localization_value.value')}}</b></div>
    </div>
<!--    <template v-if="pevItem"></template>-->
    <div class="history-table__sub-row"
         v-for="(permission, index) in allPermissions" :key="index"
    >
      <div class="history-table__sub-col">{{permission.display_name}}</div>
      <div class="history-table__sub-col">
        <DefaultCheckbox v-if="pevItem" :value="_.find(pevItem.history.permissions, {name: permission.name}) !== undefined" :disabled="true"/>
      </div>
      <div class="history-table__sub-col">
        <DefaultCheckbox v-if="item" :value="_.find(item.history.permissions, {name: permission.name}) !== undefined" :disabled="true"/>
      </div>
    </div>

  </div>
</template>

<script>
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

export default {

  name: "SubUsersType",

  components: {DefaultCheckbox, ValueHelper},

  props: {
    pevItem: Object,
    item: Object,
  },

  watch: {
    item() {
      this.generateAllPermissions()
    }
  },

  data() {
    return {
      allPermissions: [],
    }
  },

  mounted() {
    this.generateAllPermissions()
  },

  methods: {
    generateAllPermissions() {
      console.log(this.item?.history.permission_list);
      this.allPermissions = this.item?.history?.permission_list || []

      // this._.remove(this.allPermissions, function(item) {
      //   if(item.name === PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX)
      //     return true
      //
      //   if(item.name === PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX)
      //     return true
      //   // return n % 2 == 0;
      // })

      // if (!this.item?.history) return
      //
      // this.allPermissions = this._.clone(this.item?.history?.permissions)
      //
      // if (this.pevItem && this.pevItem?.history?.permissions) {
      //   this.pevItem.history.permissions.map(permission => {
      //     if(!this._.find(this.allPermissions, {name: permission.name})){
      //       this.allPermissions.push(permission)
      //     }
      //   })
      // }
    },
  }

}
</script>

<style scoped>

</style>