
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const ProformDataTable = () => import(/* webpackChunkName: "group-request-return" */ './components/ProformDataTable/ProformDataTable.vue')
const moduleKey = 'proform-data'


export const ProformDataRoutes = [
  {
    path: routerPaths.mainSettingsProformData,
    component: ProformDataTable,
    meta: {
      breadcrumbs: {
        key: [
          'dashboard',
          'ProformData'
        ],
        items: {
          'dashboard': {
            name: 'menu_proform',
            link: routerPaths.mainSettingsProformCategory,
          },
          'ProformData': {
            name: 'menu_proformData',
            link: routerPaths.mainSettingsProformData,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.PROFORM_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_proformData'
    },
  },

]
