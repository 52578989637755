import {routerPaths} from '../../../router/routerPaths'
import permissions from "@/router/middleware/permissions";
import {PERMISSIONS} from "@/staticData/permissionsStatic";

const GenerateReportFBAPageTable = () => import(/* webpackChunkName: "group-generate-report-fba-page" */ './components/GenerateReportFBAPageTable/GenerateReportFBAPageTable.vue')
const moduleKey = 'generate-fba-report'

export const GenerateReportFBAPageRoutes = [
  {
    path: routerPaths.reportsGenerateReportFBAPage,
    component: GenerateReportFBAPageTable,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'reportsGenerateReportFBAPage'
        ],
        items: {
          'reports': {
            name: 'menu_finance',
            link: routerPaths.reportsGenerateReportFBAPage,
          },
          'reportsGenerateReportFBAPage': {
            name: 'menu_financeGenerateReportFBAPage',
            link: routerPaths.reportsGenerateReportFBAPage,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.REPORT_ORDER_FBA_PRODUCTS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_financeGenerateReportFBAPage'
    },
  }
]
