<template>
  <div class="fragment">



    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_user.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user.user_personal_contact.email'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user.user_personal_contact.email'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_Amount.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.payment_total'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.payment_total'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('payments_comment.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.payment_transaction_detail.description'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.payment_transaction_detail.description'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('payments_Status.localization_value.value')}}</div>
      <div class="history-table__sub-col"> <span v-if="pevItem">{{$t(getStatusPrev + '.localization_value.value')}}</span><span v-else>—</span> </div>
      <div class="history-table__sub-col"> {{$t(getStatus + '.localization_value.value')}} </div>
    </div>

  </div>
</template>

<script>
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import {CUSTOMERS_PAYMENTS_STATUSES as PAYMENTS_STATUSES} from "@/staticData/staticVariables";

export default {
  name: "NewPaymentsType",

  components: {ValueHelper},

  props: {
    pevItem: Object,
    item: Object,
  },

  computed: {
    getStatus() {
      return this.checkStatus(this.item?.history.status)
    },
    getStatusPrev() {
      return this.checkStatus(this.pevItem?.history.status)
    },
  },

  methods: {
    checkStatus(status) {
      switch (status){
        case PAYMENTS_STATUSES.IN_PROGRESS_TRANSACTION_STATUS:
          return 'common_InProgress'
        case PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS:
          return 'common_Paid'
        case PAYMENTS_STATUSES.ON_HOLD_STATUS:
          return 'common_statusNew'
      }
    }
  }

}
</script>

<style scoped>

</style>