import {API} from "../../axios/axiosMainUrl";

const heartysan = 'heartysan/product-category'

export const heartysanCategory = {
  state: {
    heartysanCategory: [],
    heartysanCategoryItem: {},
    heartysanCategoryCommonList: [],
    heartysanCategoryBtn: false,
    heartysanCategoryLoading: true,
    nextHeartysanCategoryPage: false,
    heartysanCategoryForPage: 25,
    heartysanCategoryFilter: '',
  },

  mutations: {
    setHeartysanCategoryFilter: (state, payload) => {
      state.heartysanCategoryFilter = payload
    },

    setHeartysanCategory: (state, payload) => {
      if(state.nextHeartysanCategoryPage) {
        state.heartysanCategory = state.heartysanCategory.concat(payload.heartysanCategory)
      } else {
        state.heartysanCategory = payload.heartysanCategory
      }
    },

    setHeartysanCategoryCommonList: (state, payload) => {
      state.heartysanCategoryCommonList = payload.heartysanCategoryCommonList
    },

    setNextHeartysanCategoryPage: (state, payload) => {
      state.nextHeartysanCategoryPage = payload
    },

    setHeartysanCategoryItem: (state, payload) => {
      state.heartysanCategoryItem = payload.heartysanCategoryItem
    },

    setHeartysanCategoryLoadingStart: (state) => {
      state.heartysanCategoryLoading = true
    },

    setHeartysanCategoryLoadingEnd: (state) => {
      state.heartysanCategoryLoading = false
    },

    changeHeartysanCategoryBtn: (state) => {
      state.heartysanCategoryBtn = true
    },
    successHeartysanCategoryBtn: (state) => {
      state.heartysanCategoryBtn = false
    },
  },

  getters: {
    getHeartysanCategoryFilter: state => {
      return state.heartysanCategoryFilter
    },

    getHeartysanCategoryForPage: state => {
      return state.heartysanCategoryForPage
    },

    getHeartysanCategory: state => {
      return state.heartysanCategory
    },

    getHeartysanCategoryLoading: state => {
      return state.heartysanCategoryLoading
    },


    getNextHeartysanCategoryPage: state => {
      return state.nextHeartysanCategoryPage
    },

    getHeartysanCategoryItem: state => {
      return state.heartysanCategoryItem
    },

    getHeartysanCategoryCommonList: state => {
      return state.heartysanCategoryCommonList
    },

    getHeartysanCategoryBtn: state => {
      return state.heartysanCategoryBtn
    },
  },

  actions: {

    async fetchHeartysanCategory({commit, getters}, filter = '') {
      if(!getters.getNextHeartysanCategoryPage)
        commit('setHeartysanCategoryLoadingStart')
      try {
        return await API.get(`${heartysan}${filter}`).then((response) =>{
          commit('setHeartysanCategory',{heartysanCategory: response.data.data.data})
          commit('setHeartysanCategoryCommonList',{heartysanCategoryCommonList: response.data.data})
          commit('setHeartysanCategoryLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setHeartysanCategoryLoadingEnd');
        return e;
      }
    },

    async fetchHeartysanCategoryResponse({commit}, filter = '') {
      try {
        return await API.get(`${heartysan}${filter}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setHeartysanCategoryLoadingEnd');
        return e;
      }
    },

    async getHeartysanCategory({commit}, id) {
      commit('setHeartysanCategoryLoadingStart');
      try {
        return await API.get(`${heartysan}/${id}`).then((response) =>{
          console.log(response)
          commit('setHeartysanCategoryItem', {heartysanCategoryItem: response.data.data})
          commit('setHeartysanCategoryLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setHeartysanCategoryLoadingEnd');
        return e;
      }
    },

    async createHeartysanCategory({commit}, data) {
      commit('changeHeartysanCategoryBtn');
      try {
        return await API.post(`${heartysan}`, data).then((response) =>{
          commit('successHeartysanCategoryBtn');
          return response;
        });
      } catch (e) {
        commit('successHeartysanCategoryBtn');
        return e;
      }
    },

    async updateHeartysanCategory({commit}, {id, data}) {
      commit('changeHeartysanCategoryBtn');
      try {
        return await API.put(`${heartysan}/${id}`, data).then((response) =>{
          commit('successHeartysanCategoryBtn');
          return response;
        });
      } catch (e) {
        commit('successHeartysanCategoryBtn');
        return e;
      }
    },

    async deleteHeartysanCategory({commit}, id) {
      try {
        return await API.delete(`${heartysan}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successHeartysanCategoryBtn');
        return e;
      }
    },
  }
}
