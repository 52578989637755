
import {routerPaths} from '../../../router/routerPaths'
import {PERMISSIONS} from "@/staticData/permissionsStatic";
import permissions from "@/router/middleware/permissions";

const ProfitReportTable = () => import(/* webpackChunkName: "group-profit-report" */ './components/ProfitReportTable/ProfitReportTable.vue')
const ShippingReportTable = () => import(/* webpackChunkName: "group-profit-report" */ './components/ShippingReportTable/ShippingReportTable.vue')
const ProfitReportTotal = () => import(/* webpackChunkName: "group-profit-report" */ './components/ProfitReportTotal/ProfitReportTotal.vue')
const ProfitReportTransactions = () => import(/* webpackChunkName: "group-profit-report" */ './components/ProfitReportTransactions/ProfitReportTransactions.vue')
const moduleKey = 'profit-report'

export const ProfitReportRoutes = [
  {
    path: routerPaths.incomeExpensesProfitReport,
    component: ProfitReportTable,
    meta: {
      breadcrumbs: {
        key: [
          'incomeAndExpenses',
          'incomeExpensesProfitReport'
        ],
        items: {
          'incomeAndExpenses': {
            name: 'menu_incomeExpenses',
            link: routerPaths.incomeExpensesProfitReport,
          },
          'incomeExpensesProfitReport': {
            name: 'menu_profitStatement',
            link: routerPaths.incomeExpensesProfitReport,
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'menu_profitStatement'
    },
  },
  {
    path: routerPaths.incomeExpensesShippingReport,
    component: ShippingReportTable,
    meta: {
      breadcrumbs: {
        key: [
          'incomeAndExpenses',
          'incomeExpensesShippingReport'
        ],
        items: {
          'incomeAndExpenses': {
            name: 'menu_incomeExpenses',
            link: routerPaths.incomeExpensesShippingReport,
          },
          'incomeExpensesShippingReport': {
            name: 'breadcrumbs_ShippingReport',
            link: routerPaths.incomeExpensesShippingReport,
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_ShippingReport'
    },
  },


  {
    path: routerPaths.reportsProfitReportTotal,
    component: ProfitReportTotal,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'profitReport',
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.reportsProfitReportTotal,
          },
          'profitReport': {
            name: 'menu_profitReport',
            link: routerPaths.reportsProfitReportTotal,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.REPORT_PROFIT],

      moduleKey: moduleKey,
      pageTitle: 'menu_profitReport'
    },
  },
  {
    path: routerPaths.reportsProfitReportTransactions,
    component: ProfitReportTransactions,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'profitReport',
          'reportsProfitReportTransactions',
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.reportsProfitReportTotal,
          },
          'profitReport': {
            name: 'menu_profitReport',
            link: routerPaths.reportsProfitReportTotal,
          },
          'reportsProfitReportTransactions': {
            name: 'breadcrumbs_profitReportTransactions',
            link: routerPaths.reportsProfitReportTransactions,
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_profitReportTransactions'
    },
  },
]
