
import {routerPaths} from '../../../router/routerPaths'
// import permissions from "../../../router/middleware/permissions";
// import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const HeartysanUsersTable = () => import(/* webpackChunkName: "group-heartysan" */ './components/HeartysanUsersTable/HeartysanUsersTable.vue')
const moduleKey = 'heartysan'

export const HeartysanUsersRoutes = [
  {
    path: routerPaths.heartysanUsers,
    component: HeartysanUsersTable,
    meta: {
      breadcrumbs: {
        key: [
          'heartysan',
          'heartysanUsers'
        ],
        items: {
          'heartysan': {
            name: 'menu_heartysan',
            link: routerPaths.heartysanUsers,
          },
          'heartysanUsers': {
            name: 'menu_heartysanUsers',
            link: routerPaths.heartysanUsers,
          },
        },
      },

      // middleware: [permissions],
      // permissions: [PERMISSIONS.EXPENSE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_heartysanUsers'
    },
  },
]
