import {API} from "../../axios/axiosMainUrl";

const prefix = '/orders/shipping-company'

export const shippingCompanyForOrder = {
  state: {
    shippingCompanyForOrder: [],
    shippingCompanyForOrderItem: {},
    shippingCompanyForOrderCommonList: [],
    shippingCompanyForOrderBtn: false,
    shippingCompanyForOrderLoading: true,
    nextShippingCompanyForOrderPage: false,
    shippingCompanyForOrderForPage: 25,
    shippingCompanyForOrderFilter: '',
  },

  mutations: {
    setShippingCompanyForOrderFilter: (state, payload) => {
      state.shippingCompanyForOrderFilter = payload
    },

    setShippingCompanyForOrder: (state, payload) => {
      if(state.nextShippingCompanyForOrderPage) {
        state.shippingCompanyForOrder = state.shippingCompanyForOrder.concat(payload.shippingCompanyForOrder)
      } else {
        state.shippingCompanyForOrder = payload.shippingCompanyForOrder
      }
    },

    setShippingCompanyForOrderCommonList: (state, payload) => {
      state.shippingCompanyForOrderCommonList = payload.shippingCompanyForOrderCommonList
    },

    setNextShippingCompanyForOrderPage: (state, payload) => {
      state.nextShippingCompanyForOrderPage = payload
    },

    setShippingCompanyForOrderItem: (state, payload) => {
      state.shippingCompanyForOrderItem = payload.shippingCompanyForOrderItem
    },

    setShippingCompanyForOrderLoadingStart: (state) => {
      state.shippingCompanyForOrderLoading = true
    },

    setShippingCompanyForOrderLoadingEnd: (state) => {
      state.shippingCompanyForOrderLoading = false
    },

    changeShippingCompanyForOrderBtn: (state) => {
      state.shippingCompanyForOrderBtn = true
    },
    successShippingCompanyForOrderBtn: (state) => {
      state.shippingCompanyForOrderBtn = false
    },
  },

  getters: {
    getShippingCompanyForOrderFilter: state => {
      return state.shippingCompanyForOrderFilter
    },

    getShippingCompanyForOrderForPage: state => {
      return state.shippingCompanyForOrderForPage
    },

    getShippingCompanyForOrder: state => {
      return state.shippingCompanyForOrder
    },

    getShippingCompanyForOrderLoading: state => {
      return state.shippingCompanyForOrderLoading
    },


    getNextShippingCompanyForOrderPage: state => {
      return state.nextShippingCompanyForOrderPage
    },

    getShippingCompanyForOrderItem: state => {
      return state.shippingCompanyForOrderItem
    },

    getShippingCompanyForOrderCommonList: state => {
      return state.shippingCompanyForOrderCommonList
    },

    getShippingCompanyForOrderBtn: state => {
      return state.shippingCompanyForOrderBtn
    },
  },

  actions: {

    async fetchShippingCompanyForOrder({commit, getters}, filter = '') {
      if(!getters.getNextShippingCompanyForOrderPage)
        commit('setShippingCompanyForOrderLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setShippingCompanyForOrder',{shippingCompanyForOrder: response.data.data.data})
          commit('setShippingCompanyForOrderCommonList',{shippingCompanyForOrderCommonList: response.data.data})
          commit('setShippingCompanyForOrderLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setShippingCompanyForOrderLoadingEnd');
        return e;
      }
    },

    async getShippingCompanyCreate({commit}) {
      // commit('setShippingCompanyForOrderLoadingStart');
      try {
        return await API.get(`${prefix}/create`).then((response) =>{
          console.log(response)
          commit('setShippingCompanyForOrderItem', {shippingCompanyForOrderItem: response.data.data})
          // commit('setShippingCompanyForOrderLoadingEnd');
          return response;
        });
      } catch (e) {
        // commit('setShippingCompanyForOrderLoadingEnd');
        return e;
      }
    },

    async getShippingCompanyForOrder({commit}, id) {
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setShippingCompanyForOrderItem', {shippingCompanyForOrderItem: response.data.data})
          return response;
        });
      } catch (e) {
        commit('setShippingCompanyForOrderLoadingEnd');
        return e;
      }
    },

    async createShippingCompanyForOrder({commit}, data) {
      commit('changeShippingCompanyForOrderBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successShippingCompanyForOrderBtn');
          return response;
        });
      } catch (e) {
        commit('successShippingCompanyForOrderBtn');
        return e;
      }
    },

    async updateShippingCompanyForOrder({commit}, {id, data}) {
      commit('changeShippingCompanyForOrderBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successShippingCompanyForOrderBtn');
          return response;
        });
      } catch (e) {
        commit('successShippingCompanyForOrderBtn');
        return e;
      }
    },

    async deleteShippingCompanyForOrder({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successShippingCompanyForOrderBtn');
        return e;
      }
    },

  }
}
