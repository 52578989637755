
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const ForbiddenGoodsTable = () => import(/* webpackChunkName: "group-other-shippers" */ './components/ForbiddenGoodsTable/ForbiddenGoodsTable.vue')
const moduleKey = 'forbidden-goods'


export const ForbiddenGoodsRoutes = [
  {
    path: routerPaths.forbiddenGoods,
    component: ForbiddenGoodsTable,
    meta: {
      breadcrumbs: {
        key: [
          'menu_finance',
          'mainSettingsForbiddenGoods'
        ],
        items: {
          'menu_finance': {
            name: 'menu_finance',
            link: routerPaths.forbiddenGoods,
          },
          'mainSettingsForbiddenGoods': {
            name: 'menu_forbiddenGoods',
            link: routerPaths.forbiddenGoods,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.FORBIDDEN_PAYMENT_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_forbiddenGoods'
    },
  }
]
