import {routerPaths} from '../../../router/routerPaths'

const ShippingReportTable = () => import(/* webpackChunkName: "group-shipping-report" */ './components/ShippingReportTable/ShippingReportTable.vue')
const moduleKey = 'consolidation-orders'

export const ShippingReportRoutes = [
  {
    path: routerPaths.shippingReport,
    component: ShippingReportTable,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'shippingReport'
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.shippingReport,
          },
          'shippingReport': {
            name: 'menu_shippingReportDHL',
            link: routerPaths.shippingReport,
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'menu_shippingReportDHL'
    },
  }
]
