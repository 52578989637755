import {API} from "../../axios/axiosMainUrl";

export const authorizeAPI = {
  state: {
    authorize: [],
    authorizeItem: {},
    authorizeCommonList: [],
    authorizeBtn: false,
    authorizeLoading: false,
  },

  mutations: {
    setAuthorize: (state, payload) => {
      state.authorize = payload.authorize
    },
    setAuthorizeItem: (state, payload) => {
      state.authorizeItem = payload.authorizeItem
    },

    setAuthorizeLoadingStart: (state) => {
      state.authorizeLoading = true
    },

    setAuthorizeLoadingEnd: (state) => {
      state.authorizeLoading = false
    },

    changeAuthorizeBtn: (state) => {
      state.authorizeBtn = true
    },
    successAuthorizeBtn: (state) => {
      state.authorizeBtn = false
    },
  },

  getters: {
    getAuthorize: state => {
      return state.authorize
    },

    getAuthorizeItem: state => {
      return state.authorizeItem
    },

    getAuthorizeCommonList: state => {
      return state.authorizeCommonList
    },

    getAuthorizeBtn: state => {
      return state.authorizeBtn
    },
  },

  actions: {

    async fetchAuthorize({commit}, filter = '') {
      commit('setAuthorizeLoadingStart');
      try {
        return await API.get(`/authorize${filter}`).then((response) =>{
          commit('setAuthorize',{authorize: response.data.data})
          commit('setAuthorizeLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setAuthorizeLoadingEnd');
        return e;
      }
    },

    async getAuthorize({commit}, id) {
      commit('setAuthorizeLoadingStart');
      try {
        return await API.get(`/authorize/${id}`).then((response) =>{
          console.log(response)
          commit('setAuthorizeItem', {authorizeItem: response.data.data})
          commit('setAuthorizeCommonList',{authorizeCommonList: response.data.data})
          commit('setAuthorizeLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setAuthorizeLoadingEnd');
        return e;
      }
    },

    async createAuthorize({commit}, data) {
      commit('changeAuthorizeBtn');
      try {
        return await API.post(`/authorize`, data).then((response) =>{
          commit('successAuthorizeBtn');
          return response;
        });
      } catch (e) {
        commit('successAuthorizeBtn');
        return e;
      }
    },

    async updateAuthorize({commit}, {id, data}) {
      commit('changeAuthorizeBtn');
      try {
        return await API.put(`/authorize/${id}`, data).then((response) =>{
          commit('successAuthorizeBtn');
          return response;
        });
      } catch (e) {
        commit('successAuthorizeBtn');
        return e;
      }
    },

    async deleteAuthorize({commit}, id) {
      try {
        return await API.delete(`/authorize/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successAuthorizeBtn');
        return e;
      }
    },

    async newPaymentAuthorize({commit}, data) {
      commit('changeAuthorizeBtn');
      try {
        return await API.post(`/api-payments/authorize/create-pay`, data).then((response) =>{
          commit('successAuthorizeBtn');
          return response;
        });
      } catch (e) {
        commit('successAuthorizeBtn');
        return e;
      }
    },

    async newPaymentAuthorizeInvoice({commit}, data) {
      commit('changeAuthorizeBtn');
      try {
        return await API.post(`/api-payments/authorize/create-invoice-pay`, data).then((response) =>{
          commit('successAuthorizeBtn');
          return response;
        });
      } catch (e) {
        commit('successAuthorizeBtn');
        return e;
      }
    },

    // async executePaymentAuthorize({commit}, data) {
    //   commit('changeAuthorizeBtn');
    //   try {
    //     return await API.post(`/api-payments/paypal/execute-pay`, data).then((response) =>{
    //       console.log(response);
    //       commit('successAuthorizeBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successAuthorizeBtn');
    //     return e;
    //   }
    // },



  }
}
