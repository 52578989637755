import {routerPaths} from '../../../router/routerPaths'
import permissions from "@/router/middleware/permissions";
import {PERMISSIONS} from "@/staticData/permissionsStatic";

const GenerateReportPageTable = () => import(/* webpackChunkName: "group-generate-report-page" */ './components/GenerateReportPageTable/GenerateReportPageTable.vue')
const GenerateReportCurrentOrdersTable = () => import(/* webpackChunkName: "group-generate-report-current-orders" */ './components/CurrentOrdersTable/CurrentOrdersTable.vue')
const moduleKey = 'generate-report'

export const GenerateReportPageRoutes = [
  {
    path: routerPaths.reportsGenerateReportPage,
    component: GenerateReportPageTable,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'reportsGenerateReportPage'
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.reportsGenerateReportPage,
          },
          'reportsGenerateReportPage': {
            name: 'menu_financeGenerateReportPage',
            link: routerPaths.reportsGenerateReportPage,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.REPORT_ORDER_FBM_PRODUCTS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_financeGenerateReportPage'
    },
  },
  {
    path: routerPaths.reportsGenerateReportCurOrdersPage,
    component: GenerateReportCurrentOrdersTable,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'reportsGenerateReportCurOrdersPage'
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.reportsGenerateReportCurOrdersPage,
          },
          'reportsGenerateReportCurOrdersPage': {
            name: 'menu_reportsCurrentOrders',
            link: routerPaths.reportsGenerateReportCurOrdersPage,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.REPORT_ORDER_FBM_PRODUCTS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_reportsCurrentOrders'
    },
  }
]