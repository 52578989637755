import {API} from "../../axios/axiosMainUrl";

const prefix = '/notifications/sms-contact'

export const chat = {
  state: {
    chat: [],
    chatItem: {},
    chatCommonList: [],
    chatBtn: false,
    chatLoading: true,
    nextChatPage: false,
    chatForPage: 25,
    chatFilter: '',
  },

  mutations: {
    setChatFilter: (state, payload) => {
      state.chatFilter = payload
    },

    setChat: (state, payload) => {
      if(state.nextChatPage) {
        state.chat = state.chat.concat(payload.chat)
      } else {
        state.chat = payload.chat
      }
    },

    setChatCommonList: (state, payload) => {
      state.chatCommonList = payload.chatCommonList
    },

    setNextChatPage: (state, payload) => {
      state.nextChatPage = payload
    },

    setChatItem: (state, payload) => {
      state.chatItem = payload.chatItem
    },

    setChatLoadingStart: (state) => {
      state.chatLoading = true
    },

    setChatLoadingEnd: (state) => {
      state.chatLoading = false
    },

    changeChatBtn: (state) => {
      state.chatBtn = true
    },
    successChatBtn: (state) => {
      state.chatBtn = false
    },
  },

  getters: {
    getChatFilter: state => {
      return state.chatFilter
    },

    getChatForPage: state => {
      return state.chatForPage
    },

    getChat: state => {
      return state.chat
    },

    getChatLoading: state => {
      return state.chatLoading
    },


    getNextChatPage: state => {
      return state.nextChatPage
    },

    getChatItem: state => {
      return state.chatItem
    },

    getChatCommonList: state => {
      return state.chatCommonList
    },

    getChatBtn: state => {
      return state.chatBtn
    },
  },

  actions: {

    async fetchChat({commit, getters}, filter = '') {
      if(!getters.getNextChatPage)
        commit('setChatLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setChat',{chat: response.data.data.data})
          commit('setChatCommonList',{chatCommonList: response.data.data})
          commit('setChatLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setChatLoadingEnd');
        return e;
      }
    },

    async getChat({commit}, id) {
      commit('setChatLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setChatItem', {chatItem: response.data.data})
          commit('setChatLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setChatLoadingEnd');
        return e;
      }
    },

    async createChat({commit}, data) {
      commit('changeChatBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successChatBtn');
          return response;
        });
      } catch (e) {
        commit('successChatBtn');
        return e;
      }
    },

    async updateChat({commit}, {id, data}) {
      commit('changeChatBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successChatBtn');
          return response;
        });
      } catch (e) {
        commit('successChatBtn');
        return e;
      }
    },

    async deleteChat({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successChatBtn');
        return e;
      }
    },

  }
}
