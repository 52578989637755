
import {routerPaths} from '../../../router/routerPaths'

const ConsolidationTable = () => import(/* webpackChunkName: "group-consolidation" */ './components/ConsolidationTable/ConsolidationTable.vue')
const ConsolidationCreate = () => import(/* webpackChunkName: "group-consolidation-create" */ './components/ConsolidationCreate/ConsolidationCreate.vue')
const ConsolidationDetail = () => import(/* webpackChunkName: "group-consolidation-detail" */ './components/ConsolidationDetail/ConsolidationDetail.vue')
const ConsolidationEditing = () => import(/* webpackChunkName: "group-consolidation-edit" */ './components/ConsolidationEditing/ConsolidationEditing.vue')
const moduleKey = 'consolidation'

export const ConsolidationRoutes = [
  {
    path: routerPaths.ordersConsolidation,
    component: ConsolidationTable,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'ordersConsolidation'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'ordersConsolidation': {
            name: 'menu_consolidation',
            link: routerPaths.ordersConsolidation,
          },
        },
      },

      moduleKey: moduleKey,
      moduleKeyChapters: true,
      pageTitle: 'menu_consolidation'
    },
  },
  {
    path: routerPaths.ordersConsolidationCreation,
    component: ConsolidationCreate,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'ordersConsolidation',
          'ordersConsolidationCreate'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'ordersConsolidation': {
            name: 'menu_consolidation',
            link: routerPaths.ordersConsolidation,
          },
          'ordersConsolidationCreate': {
            name: 'breadcrumbs_NewConsolidation',
            link: '/',
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewConsolidation'
    },
  },
  {
    path: routerPaths.ordersConsolidationDetailInfo,
    component: ConsolidationDetail,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'ordersConsolidation',
          'ordersConsolidationDetail'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'ordersConsolidation': {
            name: 'menu_consolidation',
            link: routerPaths.ordersConsolidation,
          },
          'ordersConsolidationDetail': {
            name: 'breadcrumbs_ViewConsolidation',
            link: '/',
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_ViewConsolidation'
    },
  },
  {
    path: routerPaths.ordersConsolidationEditingItem,
    component: ConsolidationEditing,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'ordersConsolidation',
          'ordersConsolidationEdit'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'ordersConsolidation': {
            name: 'menu_consolidation',
            link: routerPaths.ordersConsolidation,
          },
          'ordersConsolidationEdit': {
            name: 'breadcrumbs_EditConsolidation',
            link: '/',
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_EditConsolidation'
    },
  },
]
