import {routerPaths} from '../../../router/routerPaths'
import permissions from "@/router/middleware/permissions";
import {PERMISSIONS} from "@/staticData/permissionsStatic";

const InventoryTable = () => import(/* webpackChunkName: "group-inventory" */ './components/InventoryTable/InventoryTable.vue')
const InventoryTableShow = () => import(/* webpackChunkName: "group-inventory" */ './components/InventoryTableShow/InventoryTableShow.vue')
const InventoryEdit = () => import(/* webpackChunkName: "group-inventory" */ './components/InventoryEdit/InventoryEdit.vue')
const moduleKey = 'inventory'

export const InventoryRoutes = [
  {
    path: routerPaths.mainSettingsInventory,
    component: InventoryTable,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettings',
          'mainSettingsInventory',
        ],
        items: {
          'mainSettings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsInventory,
          },
          'mainSettingsInventory': {
            name: 'breadcrumbs_inventory',
            link: routerPaths.mainSettingsInventory,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.INVENTORY_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_inventory'
    },
  },
  {
    path: routerPaths.mainSettingsInventoryShow,
    component: InventoryTableShow,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettings',
          'mainSettingsInventoryShow',
        ],
        items: {
          'mainSettings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsInventory,
          },
          'mainSettingsInventoryShow': {
            name: 'breadcrumbs_inventoryShow',
            link: routerPaths.mainSettingsInventoryShow,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.INVENTORY_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_inventoryShow'
    },
  },
  {
    path: routerPaths.mainSettingsInventoryEdit,
    component: InventoryEdit,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettings',
          'mainSettingsInventory',
          'mainSettingsInventoryEdit',
        ],
        items: {
          'mainSettings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsInventory,
          },
          'mainSettingsInventory': {
            name: 'breadcrumbs_inventory',
            link: routerPaths.mainSettingsInventory,
          },
          'mainSettingsInventoryEdit': {
            name: 'breadcrumbs_inventoryEdit',
            link: routerPaths.mainSettingsInventoryEdit,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.INVENTORY_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_inventoryEdit'
    },
  },
]
