import {API} from "../../axios/axiosMainUrl";

export const hSCodes = {
  state: {
    hSCodes: [],
    hSCodesItem: {},
    hSCodesCommonList: [],
    hSCodesBtn: false,
    hSCodesLoading: true,
    nextHSCodesPage: false,
    hSCodesForPage: 25,
    hSCodesFilter: '',
  },

  mutations: {
    setHSCodesFilter: (state, payload) => {
      state.hSCodesFilter = payload
    },

    setHSCodes: (state, payload) => {
      if(state.nextHSCodesPage) {
        state.hSCodes = state.hSCodes.concat(payload.hSCodes)
      } else {
        state.hSCodes = payload.hSCodes
      }
    },

    setHSCodesCommonList: (state, payload) => {
      state.hSCodesCommonList = payload.hSCodesCommonList
    },

    setNextHSCodesPage: (state, payload) => {
      state.nextHSCodesPage = payload
    },

    setHSCodesItem: (state, payload) => {
      state.hSCodesItem = payload.hSCodesItem
    },

    setHSCodesLoadingStart: (state) => {
      state.hSCodesLoading = true
    },

    setHSCodesLoadingEnd: (state) => {
      state.hSCodesLoading = false
    },

    changeHSCodesBtn: (state) => {
      state.hSCodesBtn = true
    },
    successHSCodesBtn: (state) => {
      state.hSCodesBtn = false
    },
  },

  getters: {
    getHSCodesFilter: state => {
      return state.hSCodesFilter
    },

    getHSCodesForPage: state => {
      return state.hSCodesForPage
    },

    getHSCodes: state => {
      return state.hSCodes
    },

    getHSCodesLoading: state => {
      return state.hSCodesLoading
    },


    getNextHSCodesPage: state => {
      return state.nextHSCodesPage
    },

    getHSCodesItem: state => {
      return state.hSCodesItem
    },

    getHSCodesCommonList: state => {
      return state.hSCodesCommonList
    },

    getHSCodesBtn: state => {
      return state.hSCodesBtn
    },
  },

  actions: {

    async fetchHSCodes({commit, getters}, filter = '') {
      if(!getters.getNextHSCodesPage)
        commit('setHSCodesLoadingStart')
      try {
        return await API.get(`/products/hs-codes${filter}`).then((response) =>{
          commit('setHSCodes',{hSCodes: response.data.data.data})
          commit('setHSCodesCommonList',{hSCodesCommonList: response.data.data})
          commit('setHSCodesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setHSCodesLoadingEnd');
        return e;
      }
    },

    async getHSCodesFromCategoryId({commit}, filter = '') {
      try {
        return await API.get(`/products/proform-attachment/hs-code-list${filter}`).then((response) =>{
          return response.data.data.data
        });
      } catch (e) {
        commit('setHSCodesLoadingEnd');
        return e;
      }
    },

    async getHSCodes({commit}, id) {
      // commit('setHSCodesLoadingStart');
      try {
        return await API.get(`/products/hs-codes/${id}`).then((response) =>{
          commit('setHSCodesItem', {hSCodesItem: response.data.data})
          // commit('setHSCodesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setHSCodesLoadingEnd');
        return e;
      }
    },

    async getHSCodeForProform({commit}, filter) {
      try {
        return await API.get(`/products/hs-codes${filter}`).then((response) =>{
          return response.data.data;
        });
      } catch (e) {
        commit('setHSCodesLoadingEnd');
        return e;
      }
    },

    async createHSCodes({commit}, data) {
      commit('changeHSCodesBtn');
      try {
        return await API.post(`/products/hs-codes`, data).then((response) =>{
          commit('successHSCodesBtn');
          return response;
        });
      } catch (e) {
        commit('successHSCodesBtn');
        return e;
      }
    },

    async updateHSCodes({commit}, {id, data}) {
      commit('changeHSCodesBtn');
      try {
        return await API.put(`/products/hs-codes/${id}`, data).then((response) =>{
          commit('successHSCodesBtn');
          return response;
        });
      } catch (e) {
        commit('successHSCodesBtn');
        return e;
      }
    },

    async deleteHSCodes({commit}, id) {
      try {
        return await API.delete(`/products/hs-codes/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successHSCodesBtn');
        return e;
      }
    },

  }
}
