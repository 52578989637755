
export function GetMenuTranslationsList() {

}

GetMenuTranslationsList.prototype = {

  DASHBOARD: () => {
    return [
      'menu_dashboard',
    ]
  },


  ORDER_AND_DISPATHES: () => {
    return [
      'menu_ordersDispatches',
      'menu_express',
      'menu_consolidation',
      'menu_createOrders',
      'menu_ordersFBA',
      'menu_shipmentToWarehouse',
      'menu_problemsMeestReturns',
      'menu_ukrposhta',
      // 'menu_belposhta',
      // 'menu_novaPoshta',
      'menu_importOrders',
      // 'menu_returns',
    ]
  },

  FINANCE: () => {
    return [
      'menu_finance',
      'menu_customersPayments',
      'menu_unidentifiedPayments',
      'menu_paymentListings',
      'menu_requestFunds',
      'menu_requestReturn',
      'menu_invoices',
      'menu_myPayments',
      'menu_payPalCases',
      'menu_forbiddenGoods',


      // 'menu_customersPayments',
      // 'menu_unidentifiedPayments',
      // 'menu_paymentListings',
      // 'menu_requestFunds',
      // 'menu_requestReturn',
      // 'menu_invoices',
      // 'menu_myPayments',
      // 'menu_financeNewPayments',
      // 'menu_financeReportCells',
      // 'menu_financeProcessFBMReport',
      // 'menu_financeGenerateReportPage',
    ]
  },

  WAREHOUSE: () => {
    return [
      'menu_warehouse',
      'menu_products',
      'menu_productsReport',
      'menu_writeOffProduct',

      // 'menu_products',
      // 'menu_warehouseWillCreate',
      // 'menu_IWillCreateLabel',
      // 'menu_ordersEasy',
      // 'menu_returnLabels',
      // 'menu_ordersFromEtsy',
      // 'menu_ordersShopify',
      // 'menu_ordersFromEbay',
      // 'menu_ordersFBA',
      // 'menu_ordersFBM',
      // 'menu_amazonHMOrders',
      // 'menu_DHL',
      // 'menu_DHLNoTransaction',
      // 'menu_TNT',
      // 'menu_Fedex',
      // 'menu_FedexNoTransaction',
      // 'menu_disposalorDer',
      // 'menu_productListingsOnSale',
      // 'menu_productListingsSold',
      // 'menu_ordersUkrposhta',
      // 'menu_ordersBelposhta',
      // 'menu_ordersWooCommerce',
      // 'menu_ordersOpenCart',
      // 'menu_ordersNovaPoshta',
      // 'menu_ordersCDEK',
      // 'menu_FBAInbound',
      // 'menu_FBAReceived',
      // 'menu_FBMInbound',
      // 'menu_consolidationInbound',
      // 'menu_parcelsInTransit',
      // 'menu_goodsRelocation',
      // 'menu_FillAClaim',
      // 'menu_processedPackage',
      // 'menu_unprocessedPackage',
      // 'menu_unidentifiedPackages',
      // 'menu_MeestToUkraine',
      // 'menu_UserRequests',
      // 'menu_trashPackages',
      // 'menu_importOrders',
      // 'menu_express'
    ]
  },

  PROBLEMS: () => {
    return [
      'menu_problems',
      'menu_parcelsDays',
      'menu_fileAClaim',
      'menu_returnPackages',
      'menu_myReturns',
      'menu_unidentifiedReturns',
    ]
  },

  // EXPRESS: () => {
  //   return [
  //     'menu_express',
  //     'menu_customersPayments',
  //     'menu_unidentifiedPayments',
  //     'menu_paymentListings',
  //     'menu_etsyListings',
  //     'menu_requestFunds',
  //     'menu_requestReturn',
  //     'menu_invoices',
  //     'menu_topUpBalance',
  //     'menu_payPalCases',
  //   ]
  // },


  INCOME_EXPRESS: () => {
    return [
      'menu_incomeExpenses'
    ]
  },

  HEARTYSAN: () => {
    return [
      'menu_heartysanChoice',
      'menu_heartysanManufacturerListings',
      'menu_heartysanSellerListings',
      'menu_heartysanChat',
      'menu_heartysanEtsyListings',
    ]
  },

  MARKETPLACE: () => {
    return [
      'menu_marketplaceProducts',
      'menu_marketplaceReviews',
      'menu_marketplaceRates',
    ]
  },

  // REPORTS: () => {
  //   return [
  //     'menu_productsReport',
  //   ]
  // },

  SETTINGS: () => {
    return [
      'menu_settings',
      'menu_personalAccount',
      // 'menu_businessAccount',
      'menu_shops',
      'menu_importFromEtsyToWPOrOs',
      'menu_productImportFromEtsy',
      'menu_subuser',
      'menu_profiles',
      'menu_modules',
      'menu_customsInformation',
      'menu_notifications',
      'menu_proform',
      'menu_proformCategory',
      'menu_proformData',
      'menu_myProfile',
      'menu_warehouseManagementConsolidation',
      'menu_mainSettingsOtherShippers',
      // 'menu_financeAPCDelconTable',
    ]
  },

  STATISTICS: () => {
    return [
      'menu_statistics',
      'menu_deliveryStatistics',
      'menu_graphicsStatistic',
    ]
  },

  SYSTEM: () => {
    return [
      'menu_system ',
      'menu_systemAdminAuth ',
      'menu_systemUsers',
      'menu_roles',
      'menu_permissions',
      'menu_permissionsGroup',
      'menu_vueComponents',
      'menu_translations',
      'menu_translationsGroups',
    ]
  },

}
