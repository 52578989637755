import {API} from "../../axios/axiosMainUrl";

const prefix = 'payments/balance-output'
const APIprefix = 'api-payments/balance-output'

export const requestFunds = {
  state: {
    requestFunds: [],
    requestFundsItem: {},
    requestFundsCommonList: [],
    requestFundsTotals: [],
    requestFundsBtn: false,
    requestFundsLoading: true,
    nextRequestFundsPage: false,
    requestFundsForPage: 25,
    requestFundsFilter: '',

    requestFundsSocket: false,
    requestFundsSocketChannel: 'balance-output-channel',
    requestFundsSocketEvent: 'balance-output-status.updated',
  },

  mutations: {
    setRequestFundsFilter: (state, payload) => {
      state.requestFundsFilter = payload
    },

    setRequestFunds: (state, payload) => {
      if(state.nextRequestFundsPage) {
        state.requestFunds = state.requestFunds.concat(payload.requestFunds)
      } else {
        state.requestFunds = payload.requestFunds
      }
    },

    setRequestFundsCommonList: (state, payload) => {
      state.requestFundsCommonList = payload.requestFundsCommonList
    },

    setRequestFundsTotals: (state, payload) => {
      state.requestFundsTotals = payload.requestFundsTotals
    },

    setNextRequestFundsPage: (state, payload) => {
      state.nextRequestFundsPage = payload
    },

    setRequestFundsItem: (state, payload) => {
      state.requestFundsItem = payload.requestFundsItem
    },

    setRequestFundsLoadingStart: (state) => {
      state.requestFundsLoading = true
    },

    setRequestFundsLoadingEnd: (state) => {
      state.requestFundsLoading = false
    },

    changeRequestFundsBtn: (state) => {
      state.requestFundsBtn = true
    },
    successRequestFundsBtn: (state) => {
      state.requestFundsBtn = false
    },


    setRequestFundsSocket: (state, payload) => {
      state.requestFundsSocket = payload
    },
    setRequestFundsSocketChannel: (state, payload) => {
      state.requestFundsSocketChannel = payload
    },
    setRequestFundsSocketEvent: (state, payload) => {
      state.requestFundsSocketEvent = payload
    },
  },

  getters: {
    getRequestFundsFilter: state => {
      return state.requestFundsFilter
    },

    getRequestFundsForPage: state => {
      return state.requestFundsForPage
    },

    getRequestFunds: state => {
      return state.requestFunds
    },

    getRequestFundsLoading: state => {
      return state.requestFundsLoading
    },


    getNextRequestFundsPage: state => {
      return state.nextRequestFundsPage
    },

    getRequestFundsItem: state => {
      return state.requestFundsItem
    },

    getRequestFundsCommonList: state => {
      return state.requestFundsCommonList
    },

    getRequestFundsTotals: state => {
      return state.requestFundsTotals
    },

    getRequestFundsBtn: state => {
      return state.requestFundsBtn
    },

    getRequestFundsSocket: state => {
      return state.requestFundsSocket
    },
    getRequestFundsSocketChannel: state => {
      return state.requestFundsSocketChannel
    },
    getRequestFundsSocketEvent: state => {
      return state.requestFundsSocketEvent
    },
  },


  actions: {

    async fetchRequestFunds({commit, getters}, filter = '') {
      if(!getters.getNextRequestFundsPage)
        commit('setRequestFundsLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setRequestFunds',{requestFunds: response.data.data.data})
          commit('setRequestFundsCommonList',{requestFundsCommonList: response.data.data})
          commit('setRequestFundsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setRequestFundsLoadingEnd');
        return e;
      }
    },

    async getRequestFunds({commit}, id) {
      commit('changeRequestFundsBtn');
      // commit('setRequestFundsLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setRequestFundsItem', {requestFundsItem: response.data.data})
          commit('successRequestFundsBtn');
          // commit('setRequestFundsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('successRequestFundsBtn');
        commit('setRequestFundsLoadingEnd');
        return e;
      }
    },

    async getEditRequestFunds({commit}, id) {
      commit('changeRequestFundsBtn');
      // commit('setRequestFundsLoadingStart');
      try {
        return await API.get(`${prefix}/${id}/edit`).then((response) =>{
          console.log(response)
          commit('setRequestFundsItem', {requestFundsItem: response.data.data})
          commit('successRequestFundsBtn');
          // commit('setRequestFundsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('successRequestFundsBtn');
        commit('setRequestFundsLoadingEnd');
        return e;
      }
    },

    async createRequestFundsRemainedTrns({commit}, data) {
      commit('changeRequestFundsBtn');
      try {
        return await API.post(`/payments/create-remained-transaction`, data).then((response) =>{
          commit('successRequestFundsBtn');
          return response;
        });
      } catch (e) {
        commit('successRequestFundsBtn');
        return e;
      }
    },

    async createRequestFunds({commit}, data) {
      commit('changeRequestFundsBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successRequestFundsBtn');
          return response;
        });
      } catch (e) {
        commit('successRequestFundsBtn');
        return e;
      }
    },

    async updateRequestFunds({commit}, {id, data}) {
      commit('changeRequestFundsBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successRequestFundsBtn');
          return response;
        });
      } catch (e) {
        commit('successRequestFundsBtn');
        return e;
      }
    },

    async updateAdjustRequestFunds({commit}, {id, data}) {
      commit('changeRequestFundsBtn');
      try {
        return await API.put(`${prefix}/${id}/adjust`, data).then((response) =>{
          commit('successRequestFundsBtn');
          return response;
        });
      } catch (e) {
        commit('successRequestFundsBtn');
        return e;
      }
    },

    async deleteRequestFunds({commit}, id) {
       commit('changeRequestFundsBtn');
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          commit('successRequestFundsBtn');
          return response;
        });
      } catch (e) {
        commit('successRequestFundsBtn');
        return e;
      }
    },

    async getOutputPaymentList({commit}, data) {
       commit('changeRequestFundsBtn');
      try {
        return await API.post(`/payments/output-payment-list`, data).then((response) =>{
          commit('successRequestFundsBtn');
          return response;
        });
      } catch (e) {
        commit('successRequestFundsBtn');
        commit('setRequestFundsLoadingEnd');
        return e;
      }
    },

    /**
     * Request to output money
     */
    async singleRequestFundsOutput({commit}, data) {
       commit('changeRequestFundsBtn');
      try {
        return await API.post(`${APIprefix}/single-output`, data).then((response) =>{
          commit('successRequestFundsBtn');
          return response;
        });
      } catch (e) {
        commit('successRequestFundsBtn');
        commit('setRequestFundsLoadingEnd');
        return e;
      }
    },

    async massRequestFundsOutput({commit}, data) {
       commit('changeRequestFundsBtn');
      try {
        return await API.post(`${APIprefix}/mass-output`, data).then((response) =>{
          commit('successRequestFundsBtn');
          return response;
        });
      } catch (e) {
        commit('successRequestFundsBtn');
        commit('setRequestFundsLoadingEnd');
        return e;
      }
    },

    async getBalanceOutputTotals({commit}) {
       commit('changeRequestFundsBtn');
      try {
        return await API.get(`${prefix}/totals`).then((response) =>{
          commit('setRequestFundsTotals', {requestFundsTotals: response.data.data})
          commit('successRequestFundsBtn');
          return response;
        });
      } catch (e) {
        commit('successRequestFundsBtn');
        commit('setRequestFundsLoadingEnd');
        return e;
      }
    },

    async getExportRequestFunds({commit}, {filter, exportType}) {
      commit('setExportBtnLoadingStart')
      commit('setLoadingEnd')
      try {
        return await API.get(`${prefix}/export/${exportType}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

    async getPayUserSystems({commit}) {
      try {
        return await API.get(`/user-balance/pay-system`).then((response) =>{
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

    async createRequestFundsInvoice({commit}, data) {
      commit('changeRequestFundsBtn');
      try {
        return await API.post(`${prefix}-invoice`, data).then((response) =>{
          commit('successRequestFundsBtn');
          return response;
        });
      } catch (e) {
        commit('successRequestFundsBtn');
        return e;
      }
    },

    async updateRequestFundsInvoice({commit}, {id, data}) {
      commit('changeRequestFundsBtn');
      try {
        return await API.put(`${prefix}-invoice/${id}`, data).then((response) =>{
          commit('successRequestFundsBtn');
          return response;
        });
      } catch (e) {
        commit('successRequestFundsBtn');
        return e;
      }
    },

    async printRequestFundsInvoice({commit}, id) {
      commit('changeRequestFundsBtn');
      try {
        return await API.get(`${prefix}-invoice/${id}/pdf`,).then((response) =>{
          commit('successRequestFundsBtn');
          return response;
        });
      } catch (e) {
        commit('successRequestFundsBtn');
        return e;
      }
    },

    async getRequestFundsInvoice({commit}, id) {
      try {
        return await API.get(`${prefix}-invoice/${id}`,).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successRequestFundsBtn');
        return e;
      }
    },

  }
}
