<template>
  <div>
    <FreshChatSchedulePopup
        v-if="schedulePopup"
        :schedulePopupText="schedulePopupText"
        @close="schedulePopup = false"
    />
  </div>
</template>

<script>
import FreshChatSchedulePopup from "@/components/coreComponents/FreshChat/components/FreshChatSchedulePopup";
export default {
  name: "FreshChat",
  components: {FreshChatSchedulePopup},
  watch: {
    loadUserAuthorizedData() {
      window.fcWidget.destroy()
      setTimeout(() => {
        this.initiateCall()

        setTimeout(() => {
          if(document.getElementById('fc_frame')) {
            this.setVisibleFrame(10)
          } else {
            this.setVisibleFrame(1000)
          }
        }, 500)

      }, 1000)
    },
  },


  data() {
    return {
      schedulePopup: false,
      schedulePopupText: null,
    }
  },

  mounted() {
    if(this.loadUserAuthorizedData){
      this.initiateCall()
    }
  },

  methods: {

    setVisibleFrame(delay) {
      setTimeout(() => {
        document.getElementById('fc_frame').classList.add('visible-class')
      }, delay)
    },

    initFreshChat() {
      let restoreId = this.loadUserAuthorizedData?.user?.fresh_chat_id
      let personalContact = this._.clone(this.getUserContactDataFromUser(this.loadUserAuthorizedData?.user))

      let phone = personalContact?.cleared_phone
      // let phone = "+" + personalContact?.phone.replace('/[^0-9]/', '')
      // phone = phone.replace('(','').replace(')', '').replace('-', '')
      //
      // if(phone.indexOf('++') > -1) {
      //   phone = phone.replace('++', '+')
      // }

      let userId = this.loadUserAuthorizedData?.user?.id

      window.fcWidget.init({
        token: "00ca8dd7-eb7a-4b96-8c82-5b5673dd3ee7",
        host: "https://wchat.freshchat.com",
        externalId:  `${personalContact?.email}__${this.loadUserAuthorizedData?.user?.id}`,
        restoreId: restoreId,
        phone: phone,
      })

      window.fcWidget.user.setFirstName(personalContact?.user_full_name)// To set user email
      window.fcWidget.user.setEmail(personalContact?.email)// To set user properties

      console.log(321);
      console.log(phone);
      console.log(window.fcWidget);
      window.fcWidget.user.setPhone(phone)
      console.log(33332211);
      console.log(window.fcWidget);

      window.fcWidget.user.setProperties({
        plan: "Estate",                 // meta property 1
        status: "Active"                // meta property 2
      })

      window.fcWidget.on('user:created', (resp) => {
        console.log(1111);
        console.log('user:created');
        console.log(123);
        console.log(resp);
        let status = resp && resp.status,
            data = resp && resp.data

        if (status === 200) {
          if (data.restoreId && data.restoreId !== restoreId) {
            this.$store.dispatch('updateFreshChat', {id: userId, data: {fresh_chat_id: data.restoreId}}).then(response => {
              console.log(response);
            })
          }
        }
      });

      window.fcWidget.on("widget:loaded", function() {
            window.fcWidget.on("unreadCount:notify", function(resp) {
              if(resp && resp.count > 0) {
                document.getElementById('fc_frame').classList.add('visible')
                document.getElementById('fc_frame').dataset.unread = resp.count
              } else {
                document.getElementById('fc_frame').classList.remove('visible')
                document.getElementById('fc_frame').dataset.unread = resp.count
              }
            })
      })

      window.fcWidget.on("widget:opened", () => {
        // let schedulerMessage = '';
        // this.$store.dispatch('getFreshChat').then((response) => {
        //   schedulerMessage = this.getRespData(response)
        //
        //   if(!schedulerMessage.is_work){
        //     let timeBefore = localStorage.getItem('lastSeePopupFreshChat')
        //     console.log(timeBefore);
        //     if(timeBefore && timeBefore.length > 0) {
        //       let momentBefore = this.$moment(timeBefore)
        //       let momentNow = this.$moment(new Date())
        //       console.log(123123);
        //       console.log(momentBefore);
        //       console.log(momentNow);
        //       console.log(momentBefore.startOf('day').diff(momentNow.startOf('day'), 'days') < 0);
        //       if(momentBefore.startOf('day').diff(momentNow.startOf('day'), 'days') < 0) {
        //         return
        //       }
        //     }
        //
        //     this.schedulePopup = true
        //     this.schedulePopupText = schedulerMessage.message
        //
        //     localStorage.setItem('lastSeePopupFreshChat', this.$moment(new Date()))
        //   }
        //
        //   console.log(schedulerMessage.is_work);
        // }).catch(error => this.createErrorLog(error))
      });
    },

    initialize(i, t) {
      let e;
      i.getElementById(t) ? this.initFreshChat() : (
          (e = i.createElement("script")).id = t,
              e.async = !0, e.src = "https://wchat.freshchat.com/js/widget.js",
              e.onload = this.initFreshChat(), i.head.appendChild(e))
    },

    initiateCall() {
      this.initialize(document, "freshchat-js-sdk")
    },
  }
}
</script>

<style>

#fc_frame:before {
  content: attr(data-unread);
  display: block;
  background: #de350a;
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.5);
  color: #fff;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr=#ff1a00, endColorstr=#ff1a00, GradientType=0);
  font-size: 10px;
  font-weight: bold;
  padding-top: 1px;
  position: absolute;
  right: auto;
  left: 5px;
  text-align: center;
  top: 3px;
  height: 18px;
  width: 18px;
  background: linear-gradient(to bottom, #de350a 0%, #de350a 100%);
  transition: 0.1s;
  opacity: 0;
}

#fc_frame.visible:before {
  opacity: 1;
}
#fc_frame:hover:before {
  opacity: 0;
}

.z-callme-widget.callme.callme--shown {
  position: fixed;
  bottom: 16px;
  right: -30px;
  transform: scale(0.9);
  z-index: 1000;
  transition: right 0.4s;
}

.z-callme-widget.callme.callme--shown:hover {
  right: 20px;
}

#fc_frame, #fc_frame.fc-widget-normal {
  bottom: 85px !important;
  transition: right 0.4s;
  right: -35px !important;
}

#fc_frame:hover, #fc_frame.fc-widget-normal:hover {
  right: 15px !important;
}

#fc_frame.h-open-container, #fc_frame.fc-widget-small.h-open-container {
  right: 15px !important;
}

/*#fc_frame {*/
/*  display: none !important;*/
/*}*/

#fc_frame.visible-class {
  display: block !important;
}
</style>