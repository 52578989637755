import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {API} from '../axios/axiosMainUrl'

Vue.use(VueI18n)

// import en from './en'

const messages = {
  en: {
    'default': ''
  },
}

let lang = 'en';

if(localStorage.getItem('translationLang')){
  lang = localStorage.getItem('translationLang');
} else {
  localStorage.setItem('translationLang', lang);
}

export const i18n = new VueI18n({
  locale: lang,
  messages,
  silentTranslationWarn : true
})

function setI18nLanguage (lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

function getTranslation(lang) {
  API.defaults.headers.post['Content-Language'] = lang;
  API.defaults.headers.common['Accept-Language'] = lang;

  return API.get(`localizations/local-storage-list`).then(response => {
    let msgs = response.data.data

    i18n.setLocaleMessage(lang, msgs)
    localStorage.setItem('translation', JSON.stringify(msgs));
    localStorage.setItem('translationLang', lang);
    localStorage.setItem('translationDate', msgs.refreshDate.updated_at);

    setI18nLanguage(lang)
  })
}



export function getLocalTranslations(lang) {
  let localLang = localStorage.getItem('translationLang')
  let localTranslation = localStorage.getItem('translation')
  let localTranslationDate = localStorage.getItem('translationDate');

  if(localTranslationDate === null) localTranslationDate = '2010-12-18 12:35:12'

  if(localLang && localTranslation){
    i18n.setLocaleMessage(localLang, JSON.parse(localTranslation))
    setI18nLanguage(localLang)
    API.defaults.headers.post['Content-Language'] = localLang;
    API.defaults.headers.common['Accept-Language'] = localLang;

    API.post(`localizations/was-changed`, {updated_at: localTranslationDate}).then((response) => {
      if(response.data.data.isUpdated) {
        // API.defaults.headers.common['Accept-Language'] = localTranslation
        getTranslation(localLang)
      }
    })

  } else {
    loadLanguageAsync(lang)
  }
}

export function loadLanguageAsync (lang) {
  return getTranslation(lang)
}

export function setLanguage (data, lang) {
  i18n.setLocaleMessage(lang, data)
}



