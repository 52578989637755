<template>
  <div>

    <template v-if="isDeletedFile">
      <template v-if="otherName">{{otherName}}</template>
      <template v-else>{{ file.original_filename }}</template>
      (Deleted)
    </template>

    <div v-else class="link-button" @click="showFile">
      <template v-if="otherName">{{otherName}}</template>
      <template v-else>{{ file.original_filename }}</template>
    </div>


    <!-- all props & events -->
    <Lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
    ></Lightbox>

  </div>
</template>

<script>

import Lightbox from 'vue-easy-lightbox'

export default {
  name: "FileBlock",

  components: {
    Lightbox,
  },

  props: {
    files: Array,
    file: Object,
    otherName: {
      type: String,
      default: null
    },
  },

  computed: {
    isDeletedFile() {
      if(this._.find(this.files, {id: this.file.id, event: 'deleted'})){
        return true
      }
      return false
    },
  },

  data() {
    return {
      imgs: [],
      visible: false,
      index: 0, // default: 0
    }
  },

  mounted() {
    console.log(this.file);
  },

  methods: {

    showFile() {
      if(this.file.extension === 'pdf') {
        this.$store.dispatch('getFileFromServer', this.file.id)
            .then((response) => {
              this.globalOpenPdfBase64(response[this.file.id])
            })
      }
      if(this.file.mime_type.indexOf('image') > -1) {
        this.showSingle(this.file.id)
      }
    },

    showSingle(id) {
      this.$store.dispatch('getFileFromServer', id).then((response) => {
        this.imgs = []
        this.imgs.push({
          title: 'img',
          src: `data:image/png;base64,` + response[id]
        })
        this.show()
      })
    },

    handleHide() {
      this.visible = false
    },

    show() {
      this.visible = true
    },
  },

}
</script>

<style scoped>

</style>