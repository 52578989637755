import {routerPaths} from '../../../router/routerPaths'

const BalanceHistoryTable = () => import(/* webpackChunkName: "group-balance-history" */ './components/BalanceHistoryTable/BalanceHistoryTable.vue')
const moduleKey = 'balance-history'

export const BalanceHistoryRoutes = [
  {
    path: routerPaths.balanceHistory,
    component: BalanceHistoryTable,
    meta: {
      breadcrumbs: {
        key: [
          'balanceHistory',
        ],
        items: {
          'balanceHistory': {
            name: 'breadcrumbs_balanceHistory',
            link: routerPaths.balanceHistory,
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_balanceHistory'
    },
  },
]


