import {API} from "../../axios/axiosMainUrl";

const orderInternal = 'internal-orders'

const orderInternalGroups = 'internal-order-groups'

export const ordersInternal = {
  state: {
    ordersInternal: [],
    ordersInternalItem: {},
    ordersInternalCommonList: [],
    ordersInternalBtn: false,
    ordersInternalLoading: true,
    nextOrdersInternalPage: false,
    ordersInternalForPage: 25,
    ordersInternalFilter: '',
  },

  mutations: {
    setOrdersInternalFilter: (state, payload) => {
      state.ordersInternalFilter = payload
    },

    setOrdersInternal: (state, payload) => {
      if(state.nextOrdersInternalPage) {
        state.ordersInternal = state.ordersInternal.concat(payload.ordersInternal)
      } else {
        state.ordersInternal = payload.ordersInternal
      }
    },

    setOrdersInternalCommonList: (state, payload) => {
      state.ordersInternalCommonList = payload.ordersInternalCommonList
    },

    setNextOrdersInternalPage: (state, payload) => {
      state.nextOrdersInternalPage = payload
    },

    setOrdersInternalItem: (state, payload) => {
      state.ordersInternalItem = payload.ordersInternalItem
    },

    setOrdersInternalLoadingStart: (state) => {
      state.ordersInternalLoading = true
    },

    setOrdersInternalLoadingEnd: (state) => {
      state.ordersInternalLoading = false
    },

    changeOrdersInternalBtn: (state) => {
      state.ordersInternalBtn = true
    },
    successOrdersInternalBtn: (state) => {
      state.ordersInternalBtn = false
    },
  },

  getters: {
    getOrdersInternalFilter: state => {
      return state.ordersInternalFilter
    },

    getOrdersInternalForPage: state => {
      return state.ordersInternalForPage
    },

    getOrdersInternal: state => {
      return state.ordersInternal
    },

    getOrdersInternalLoading: state => {
      return state.ordersInternalLoading
    },


    getNextOrdersInternalPage: state => {
      return state.nextOrdersInternalPage
    },

    getOrdersInternalItem: state => {
      return state.ordersInternalItem
    },

    getOrdersInternalCommonList: state => {
      return state.ordersInternalCommonList
    },

    getOrdersInternalBtn: state => {
      return state.ordersInternalBtn
    },
  },

  actions: {


    async fetchOrdersInternal({commit, getters}, filter = '') {
      console.log(getters.getGlobalUpdateTable);
      if(!getters.getNextOrdersInternalPage ) //&& !getters.getGlobalUpdateTable
        commit('setOrdersInternalLoadingStart')
      try {
        return await API.get(`${orderInternal}${filter}`).then((response) =>{
          commit('setOrdersInternal',{ordersInternal: response.data.data.data})
          commit('setOrdersInternalCommonList',{ordersInternalCommonList: response.data.data})

          commit('setNextOrdersInternalPage', false)

          commit('setOrdersInternalLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrdersInternalLoadingEnd');
        return e;
      }
    },

    async getOrdersInternal({commit}, id) {
      try {
        return await API.get(`${orderInternal}/${id}`).then((response) =>{
          commit('setOrdersInternalItem', {ordersInternalItem: response.data.data})
          return response;
        });
      } catch (e) {
        commit('setOrdersInternalLoadingEnd');
        return e;
      }
    },

    async getOrdersInternalBelposhtaCN22({commit}, id) {
      try {
        return await API.get(`${orderInternal}/${id}/get-cn22`).then((response) =>{
          console.log('getOrdersInternal: ', response)
          commit('setOrdersInternalItem', {ordersInternalItem: response.data.data})
          // commit('setOrdersInternalLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrdersInternalLoadingEnd');
        return e;
      }
    },

    async getOrdersInternalEdit({commit}, id) {
      try {
        return await API.get(`${orderInternal}/${id}/edit`).then((response) =>{
          console.log('getOrdersInternal: ', response)
          commit('setOrdersInternalItem', {ordersInternalItem: response.data.data})
          // commit('setOrdersInternalLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrdersInternalLoadingEnd');
        return e;
      }
    },

    async createOrdersInternal({commit}, data) {
      commit('changeOrdersInternalBtn');
      try {
        return await API.post(`${orderInternal}`, data).then((response) =>{
          commit('successOrdersInternalBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersInternalBtn');
        return e;
      }
    },

    async updateOrdersInternal({commit}, {id, data}) {
      commit('changeOrdersInternalBtn');
      try {
        return await API.put(`${orderInternal}/${id}`, data).then((response) =>{
          commit('successOrdersInternalBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersInternalBtn');
        return e;
      }
    },

    async deleteOrdersInternal({commit}, id) {
      try {
        return await API.delete(`${orderInternal}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successOrdersInternalBtn');
        return e;
      }
    },

    async copyOrdersInternal({commit}, id) {
      commit('changeOrdersInternalBtn');
      try {
        return await API.post(`${orderInternal}/${id}/create-copy`).then((response) =>{
          commit('successOrdersInternalBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersInternalBtn');
        return e;
      }
    },

    async updateOrdersInternalTrackingNumber({commit}, {id, data}) {
      commit('changeOrdersInternalBtn');
      try {
        return await API.put(`${orderInternal}/${id}/set-tracking-number`, data).then((response) =>{
          commit('successOrdersInternalBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersInternalBtn');
        return e;
      }
    },

    async getAddressData({commit}, data) {
      commit('changeOrdersInternalBtn');
      try {
        return await API.post(`${orderInternal}/get-address-data`, data).then((response) =>{
          commit('successOrdersInternalBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersInternalBtn');
        return e;
      }
    },

    async createInternalGroupF103A({commit}, data) {
      commit('changeOrdersInternalBtn');
      try {
        return await API.post(`${orderInternalGroups}/create-ukrposhta-group-f103a`, data).then((response) =>{
          commit('successOrdersInternalBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersInternalBtn');
        return e;
      }
    },





  }
}
