import {API} from "../../axios/axiosMainUrl";

let prefix = '/report/shipping'

export const shippingReport = {
  state: {
    shippingReport: [],
    shippingReportItem: {},
    shippingReportCommonList: [],
    shippingReportBtn: false,
    shippingReportLoading: true,
    nextShippingReportPage: false,
    shippingReportForPage: 25,
    shippingReportFilter: '',
  },

  mutations: {
    setShippingReportFilter: (state, payload) => {
      state.shippingReportFilter = payload
    },

    setShippingReport: (state, payload) => {
      if(state.nextShippingReportPage) {
        state.shippingReport = state.shippingReport.concat(payload.shippingReport)
      } else {
        state.shippingReport = payload.shippingReport
      }
    },

    setShippingReportCommonList: (state, payload) => {
      state.shippingReportCommonList = payload.shippingReportCommonList
    },

    setNextShippingReportPage: (state, payload) => {
      state.nextShippingReportPage = payload
    },

    setShippingReportItem: (state, payload) => {
      state.shippingReportItem = payload.shippingReportItem
    },

    setShippingReportLoadingStart: (state) => {
      state.shippingReportLoading = true
    },

    setShippingReportLoadingEnd: (state) => {
      state.shippingReportLoading = false
    },

    changeShippingReportBtn: (state) => {
      state.shippingReportBtn = true
    },
    successShippingReportBtn: (state) => {
      state.shippingReportBtn = false
    },
  },

  getters: {
    getShippingReportFilter: state => {
      return state.shippingReportFilter
    },

    getShippingReportForPage: state => {
      return state.shippingReportForPage
    },

    getShippingReport: state => {
      return state.shippingReport
    },

    getShippingReportLoading: state => {
      return state.shippingReportLoading
    },


    getNextShippingReportPage: state => {
      return state.nextShippingReportPage
    },

    getShippingReportItem: state => {
      return state.shippingReportItem
    },

    getShippingReportCommonList: state => {
      return state.shippingReportCommonList
    },

    getShippingReportBtn: state => {
      return state.shippingReportBtn
    },
  },

  actions: {

    async fetchShippingReport({commit, getters}, {filter = '', type}) {
      if(!getters.getNextShippingReportPage)
        commit('setShippingReportLoadingStart')
      try {
        return await API.get(`${prefix}/${type}${filter}`).then((response) =>{
          commit('setShippingReport',{shippingReport: response.data.data.data})
          // commit('setShippingReportCommonList',{shippingReportCommonList: response.data.data})
          commit('setShippingReportCommonList',{shippingReportCommonList: {...response.data.data.meta, ...{
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev
              }}})
          commit('setShippingReportLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setShippingReportLoadingEnd');
        return e;
      }
    },

    async getShippingReport({commit}, id) {
      commit('setShippingReportLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setShippingReportItem', {shippingReportItem: response.data.data})
          commit('setShippingReportLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setShippingReportLoadingEnd');
        return e;
      }
    },

    async createShippingReport({commit}, data) {
      commit('changeShippingReportBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successShippingReportBtn');
          return response;
        });
      } catch (e) {
        commit('successShippingReportBtn');
        return e;
      }
    },

    async updateShippingReport({commit}, {id, data}) {
      commit('changeShippingReportBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successShippingReportBtn');
          return response;
        });
      } catch (e) {
        commit('successShippingReportBtn');
        return e;
      }
    },

    async deleteShippingReport({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successShippingReportBtn');
        return e;
      }
    },

    async getExportShippingReportExpress({commit}, {filter, exportType}) {
      commit('setExportBtnLoadingStart')
      commit('setLoadingEnd')
      try {
        return await API.get(`${prefix}/express/export/${exportType}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

  }
}
