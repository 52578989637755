import {API} from "../../axios/axiosMainUrl";

const prefix = '/orders/order-process-fee'

export const orderPrice = {
  state: {
    orderPrice: [],
    orderPriceItem: {},
    orderPriceCommonList: [],
    orderPriceBtn: false,
    orderPriceLoading: true,
    nextOrderPricePage: false,
    orderPriceForPage: 25,
    orderPriceFilter: '',
  },

  mutations: {
    setOrderPriceFilter: (state, payload) => {
      state.orderPriceFilter = payload
    },

    setOrderPrice: (state, payload) => {
      if(state.nextOrderPricePage) {
        state.orderPrice = state.orderPrice.concat(payload.orderPrice)
      } else {
        state.orderPrice = payload.orderPrice
      }
    },

    setOrderPriceCommonList: (state, payload) => {
      state.orderPriceCommonList = payload.orderPriceCommonList
    },

    setNextOrderPricePage: (state, payload) => {
      state.nextOrderPricePage = payload
    },

    setOrderPriceItem: (state, payload) => {
      state.orderPriceItem = payload.orderPriceItem
    },

    setOrderPriceLoadingStart: (state) => {
      state.orderPriceLoading = true
    },

    setOrderPriceLoadingEnd: (state) => {
      state.orderPriceLoading = false
    },

    changeOrderPriceBtn: (state) => {
      state.orderPriceBtn = true
    },
    successOrderPriceBtn: (state) => {
      state.orderPriceBtn = false
    },
  },

  getters: {
    getOrderPriceFilter: state => {
      return state.orderPriceFilter
    },

    getOrderPriceForPage: state => {
      return state.orderPriceForPage
    },

    getOrderPrice: state => {
      return state.orderPrice
    },

    getOrderPriceLoading: state => {
      return state.orderPriceLoading
    },


    getNextOrderPricePage: state => {
      return state.nextOrderPricePage
    },

    getOrderPriceItem: state => {
      return state.orderPriceItem
    },

    getOrderPriceCommonList: state => {
      return state.orderPriceCommonList
    },

    getOrderPriceBtn: state => {
      return state.orderPriceBtn
    },
  },

  actions: {

    async fetchOrderPrice({commit, getters}, {type = '', filter = ''}) {
      if(!getters.getNextOrderPricePage)
        commit('setOrderPriceLoadingStart')
      try {
        return await API.get(`${prefix}/${type}/list${filter}`).then((response) =>{
          commit('setOrderPrice',{orderPrice: response.data.data.data})
          commit('setOrderPriceCommonList',{orderPriceCommonList: response.data.data})
          commit('setOrderPriceLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrderPriceLoadingEnd');
        return e;
      }
    },

    async getOrderPrice({commit}, id) {
      // commit('setOrderPriceLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setOrderPriceItem', {orderPriceItem: response.data.data})
          // commit('setOrderPriceLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrderPriceLoadingEnd');
        return e;
      }
    },

    async setOrderPrice({commit}, id) {
      // commit('setOrderPriceLoadingStart');
      try {
        return await API.get(`${prefix}/use-default/${id}`).then((response) =>{
          commit('setOrderPriceItem', {orderPriceItem: response.data.data})
          // commit('setOrderPriceLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrderPriceLoadingEnd');
        return e;
      }
    },

    async createOrderPrice({commit}, data) {
      commit('changeOrderPriceBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successOrderPriceBtn');
          return response;
        });
      } catch (e) {
        commit('successOrderPriceBtn');
        return e;
      }
    },

    async updateOrderPrice({commit}, {id, data}) {
      commit('changeOrderPriceBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successOrderPriceBtn');
          return response;
        });
      } catch (e) {
        commit('successOrderPriceBtn');
        return e;
      }
    },

    async deleteOrderPrice({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successOrderPriceBtn');
        return e;
      }
    },

    async getOrderPriceFile({commit}, data) {
      try {
        return await API.get(`${prefix}/price-document/${data.type}/${data.format}`).then((response) =>{
          return response
        })
      } catch (e) {
        commit('successOrderPriceBtn')
        return e;
      }
    },


  }
}
