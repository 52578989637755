import {API} from "../../axios/axiosMainUrl";

const prefix = '/payments/multi-search'

export const multiSearch = {
  state: {
    multiSearch: [],
    multiSearchItem: {},
    multiSearchCommonList: [],
    multiSearchBtn: false,
    multiSearchLoading: true,
    nextMultiSearchPage: false,
    multiSearchForPage: 25,
    multiSearchFilter: '',
  },

  mutations: {
    setMultiSearchFilter: (state, payload) => {
      state.multiSearchFilter = payload
    },

    setMultiSearch: (state, payload) => {
      if(state.nextMultiSearchPage) {
        state.multiSearch = state.multiSearch.concat(payload.multiSearch)
      } else {
        state.multiSearch = payload.multiSearch
      }
    },

    setMultiSearchCommonList: (state, payload) => {
      state.multiSearchCommonList = payload.multiSearchCommonList
    },

    setNextMultiSearchPage: (state, payload) => {
      state.nextMultiSearchPage = payload
    },

    setMultiSearchItem: (state, payload) => {
      state.multiSearchItem = payload.multiSearchItem
    },

    setMultiSearchLoadingStart: (state) => {
      state.multiSearchLoading = true
    },

    setMultiSearchLoadingEnd: (state) => {
      state.multiSearchLoading = false
    },

    changeMultiSearchBtn: (state) => {
      state.multiSearchBtn = true
    },
    successMultiSearchBtn: (state) => {
      state.multiSearchBtn = false
    },
  },

  getters: {
    getMultiSearchFilter: state => {
      return state.multiSearchFilter
    },

    getMultiSearchForPage: state => {
      return state.multiSearchForPage
    },

    getMultiSearch: state => {
      return state.multiSearch
    },

    getMultiSearchLoading: state => {
      return state.multiSearchLoading
    },


    getNextMultiSearchPage: state => {
      return state.nextMultiSearchPage
    },

    getMultiSearchItem: state => {
      return state.multiSearchItem
    },

    getMultiSearchCommonList: state => {
      return state.multiSearchCommonList
    },

    getMultiSearchBtn: state => {
      return state.multiSearchBtn
    },
  },

  actions: {

    async fetchMultiSearch({commit, getters}, {filter = '', query = ''}) {
      if(!getters.getNextMultiSearchPage)
        commit('setMultiSearchLoadingStart')
      try {
        return await API.get(`${prefix}/global/${query}${filter}`).then((response) =>{
          commit('setMultiSearch',{multiSearch: response.data.data})
          commit('setMultiSearchCommonList',{multiSearchCommonList: response.data.data})
          commit('setMultiSearchLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setMultiSearchLoadingEnd');
        return e;
      }
    },

    async fetchMultiSearchLocal({commit, getters}, {filter = '', query = ''}) {
      if(!getters.getNextMultiSearchPage)
        commit('setMultiSearchLoadingStart')
      try {
        return await API.get(`${prefix}/local/${query}${filter}`).then((response) =>{
          commit('setMultiSearch',{multiSearch: response.data.data.data})
          commit('setMultiSearchCommonList',{multiSearchCommonList: response.data.data})
          commit('setMultiSearchLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setMultiSearchLoadingEnd');
        return e;
      }
    },

    async getMultiSearch({commit}, id) {
      commit('setMultiSearchLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setMultiSearchItem', {multiSearchItem: response.data.data})
          commit('setMultiSearchLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setMultiSearchLoadingEnd');
        return e;
      }
    },

    async createMultiSearch({commit}, data) {
      commit('changeMultiSearchBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successMultiSearchBtn');
          return response;
        });
      } catch (e) {
        commit('successMultiSearchBtn');
        return e;
      }
    },

    async updateMultiSearch({commit}, {id, data}) {
      commit('changeMultiSearchBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successMultiSearchBtn');
          return response;
        });
      } catch (e) {
        commit('successMultiSearchBtn');
        return e;
      }
    },

    async deleteMultiSearch({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successMultiSearchBtn');
        return e;
      }
    },

  }
}
