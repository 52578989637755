import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";
// import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const IOSSNumbersTable = () => import(/* webpackChunkName: "ioss-numbers" */ './components/IOSSNumbersTable/IOSSNumbersTable.vue')
const IOSSNumbersCreation = () => import(/* webpackChunkName: "ioss-numbers-create" */ './components/IOSSNumbersCreation/IOSSNumbersCreation.vue')
const IOSSNumbersEditing = () => import(/* webpackChunkName: "ioss-numbers-edit" */ './components/IOSSNumbersEditing/IOSSNumbersEditing.vue')
const IOSSNumbersShow = () => import(/* webpackChunkName: "ioss-numbers-show" */ './components/IOSSNumbersShow/IOSSNumbersShow.vue')
const moduleKey = 'ioss-numbers'


export const IOSSNumbersRoutes = [
  {
    path: routerPaths.mainSettingsIOSSNumbers,
    component: IOSSNumbersTable,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'mainSettingsIOSSNumbers'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsIOSSNumbers,
          },
          'mainSettingsIOSSNumbers': {
            name: 'menu_IOSSNumbers',
            link: routerPaths.mainSettingsIOSSNumbers,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.IOSS_NUMBERS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_IOSSNumbers'
    },
  },

  {
    path: routerPaths.mainSettingsIOSSNumbersCreate,
    component: IOSSNumbersCreation,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettingsIOSSNumbers',
          'mainSettingsIOSSNumbersCreation'
        ],
        items: {
          'mainSettingsIOSSNumbers': {
            name: 'menu_IOSSNumbers',
            link: routerPaths.mainSettingsIOSSNumbers,
          },
          'mainSettingsIOSSNumbersCreation': {
            name: 'menu_createIOSSNumber',
            link: routerPaths.mainSettingsIOSSNumbersCreate,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.IOSS_NUMBERS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_IOSSNumbers'
    },
  },

  {
    path: routerPaths.mainSettingsIOSSNumbersEdit,
    component: IOSSNumbersEditing,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettingsIOSSNumbers',
          'mainSettingsIOSSNumbersEditing'
        ],
        items: {
          'mainSettingsIOSSNumbers': {
            name: 'menu_IOSSNumbers',
            link: routerPaths.mainSettingsIOSSNumbers,
          },
          'mainSettingsIOSSNumbersEditing': {
            name: 'iossNumbers_iossNumber',
            link: routerPaths.mainSettingsIOSSNumbersEdit,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.IOSS_NUMBERS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_IOSSNumbers'
    },
  },

  {
    path: routerPaths.mainSettingsIOSSNumbersShow,
    component: IOSSNumbersShow,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettingsIOSSNumbers',
          'mainSettingsIOSSNumbersShow'
        ],
        items: {
          'mainSettingsIOSSNumbers': {
            name: 'menu_IOSSNumbers',
            link: routerPaths.mainSettingsIOSSNumbers,
          },
          'mainSettingsIOSSNumbersShow': {
            name: 'iossNumbers_iossNumber',
            link: routerPaths.mainSettingsIOSSNumbers,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.IOSS_NUMBERS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_IOSSNumbers'
    },
  },
]
