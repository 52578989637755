import {API} from "../../axios/axiosMainUrl";

const prefix = '/orders/fast-order'

export const fastOrders = {
  state: {
    fastOrders: [],
    fastOrdersItem: {},
    fastOrdersByUserId: [],
    fastOrdersCommonList: [],
    fastOrdersBtn: false,
    fastOrdersLoading: true,
    nextFastOrdersPage: false,
    fastOrdersForPage: 25,
    fastOrdersFilter: '',

    fastOrdersInitSocket: false,
    fastOrdersSocketItemId: '',
    fastOrdersSocket: false,
    fastOrdersSocketChannel: 'fast-order-transfer-channel',
    fastOrdersSocketEvent: 'fast-order.transfer',
  },

  mutations: {
    setFastOrdersFilter: (state, payload) => {
      state.fastOrdersFilter = payload
    },

    setFastOrders: (state, payload) => {
      if(state.nextFastOrdersPage) {
        state.fastOrders = state.fastOrders.concat(payload.fastOrders)
      } else {
        state.fastOrders = payload.fastOrders
      }
    },

    setFastOrdersCommonList: (state, payload) => {
      state.fastOrdersCommonList = payload.fastOrdersCommonList
    },

    setNextFastOrdersPage: (state, payload) => {
      state.nextFastOrdersPage = payload
    },

    setFastOrdersItem: (state, payload) => {
      state.fastOrdersItem = payload.fastOrdersItem
    },

    setFastOrdersByUserId: (state, payload) => {
      state.fastOrdersByUserId = payload.fastOrdersByUserId
    },

    setFastOrdersLoadingStart: (state) => {
      state.fastOrdersLoading = true
    },

    setFastOrdersLoadingEnd: (state) => {
      state.fastOrdersLoading = false
    },

    changeFastOrdersBtn: (state) => {
      state.fastOrdersBtn = true
    },
    successFastOrdersBtn: (state) => {
      state.fastOrdersBtn = false
    },

    setFastOrdersSocketItemId: (state, payload) => {
      state.fastOrdersSocketItemId = payload
    },
    changeFastOrdersInitSocket: (state) => {
      state.fastOrdersInitSocket = true
    },
    successFastOrdersInitSocket: (state) => {
      state.fastOrdersInitSocket = false
    },
    setFastOrdersSocket: (state, payload) => {
      state.fastOrdersSocket = payload
    },
    setFastOrdersSocketChannel: (state, payload) => {
      state.fastOrdersSocketChannel = payload
    },
    setFastOrdersSocketEvent: (state, payload) => {
      state.fastOrdersSocketEvent = payload
    },
  },

  getters: {
    getFastOrdersFilter: state => {
      return state.fastOrdersFilter
    },

    getFastOrdersForPage: state => {
      return state.fastOrdersForPage
    },

    getFastOrders: state => {
      return state.fastOrders
    },

    getFastOrdersLoading: state => {
      return state.fastOrdersLoading
    },


    getNextFastOrdersPage: state => {
      return state.nextFastOrdersPage
    },

    getFastOrdersItem: state => {
      return state.fastOrdersItem
    },

    getFastOrdersByUserId: state => {
      return state.fastOrdersByUserId
    },

    getFastOrdersCommonList: state => {
      return state.fastOrdersCommonList
    },

    getFastOrdersBtn: state => {
      return state.fastOrdersBtn
    },

    getFastOrdersInitSocket: state => {
      return state.fastOrdersInitSocket
    },

    getFastOrdersSocketItemId: state => {
      return state.fastOrdersSocketItemId
    },
    getFastOrdersSocket: state => {
      return state.fastOrdersSocket
    },
    getFastOrdersSocketChannel: state => {
      return state.fastOrdersSocketChannel
    },
    getFastOrdersSocketEvent: state => {
      return state.fastOrdersSocketEvent
    },
  },

  actions: {

    async fetchFastOrders({commit, getters}, filter = '') {
      if(!getters.getNextFastOrdersPage)
        commit('setFastOrdersLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setFastOrders',{fastOrders: response.data.data.data})
          commit('setFastOrdersCommonList',{fastOrdersCommonList: response.data.data})
          commit('setFastOrdersLoadingEnd');
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('setFastOrdersLoadingEnd');
        return e;
      }
    },

    async getFastOrders({commit}, id) {
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setFastOrdersItem', {fastOrdersItem: response.data.data})
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async createFastOrders({commit}, data) {
      commit('changeFastOrdersBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successFastOrdersBtn');
          return response;
        });
      } catch (e) {
        commit('successFastOrdersBtn');
        return e;
      }
    },

    async updateFastOrders({commit}, {id, data}) {
      commit('changeFastOrdersBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successFastOrdersBtn');
          return response;
        });
      } catch (e) {
        commit('successFastOrdersBtn');
        return e;
      }
    },

    async deleteFastOrders({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successFastOrdersBtn');
        return e;
      }
    },

    async createFastOrdersLabel({commit}, id) {
      try {
        return await API.get(`${prefix}/create-label/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successFastOrdersBtn');
        return e;
      }
    },

    async getFastOrdersLabel({commit}, id) {
      try {
        return await API.get(`${prefix}/get-label/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successFastOrdersBtn');
        return e;
      }
    },

    async searchFastOrdersFBMItem({commit}, data) {
      commit('changeFastOrdersBtn');
      try {
        return await API.post(`/orders/fast-order-element/search`, data).then((response) =>{
          commit('successFastOrdersBtn');
          return response;
        });
      } catch (e) {
        commit('successFastOrdersBtn');
        return e;
      }
    },


    async getRatesList({commit}) {
      try {
        return await API.get(`${prefix}/get-rates-list`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successFastOrdersBtn');
        return e;
      }
    },
  }
}
