<template>
  <div>
    <template
            v-if="$store.getters.getCurrentUserRights.additionalPermissions &&
                $store.getters.getCurrentUserRights.additionalPermissions[PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]">
      <div class="fragment">
        <div class="side-bar"
             :class="{'showMobile' : $store.getters.GET_MOBILE_MENU == true}"
             id="side-bar"
        >
          <div class="side-bar__overlay"
               @click="$store.commit('SET_MOBILE_MENU', !$store.getters.GET_MOBILE_MENU)"
          ></div>
          <div class="side-bar__overlay-right"
               @click="$store.commit('SET_MOBILE_MENU', !$store.getters.GET_MOBILE_MENU)"
          ></div>
          <SideBarAdmin @openAddressPopup="showAddress = true" />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="fragment">
        <div class="side-bar"
             :class="{'showMobile' : $store.getters.GET_MOBILE_MENU == true}"
             id="side-bar"
        >
          <div class="side-bar__overlay"
               @click="$store.commit('SET_MOBILE_MENU', !$store.getters.GET_MOBILE_MENU)"
          ></div>
          <div class="side-bar__overlay-right"
               @click="$store.commit('SET_MOBILE_MENU', !$store.getters.GET_MOBILE_MENU)"
          ></div>
          <SideBar @openAddressPopup="showAddress = true" v-if="$store.getters.getCurrentUserRights.additionalPermissions"/>
        </div>
      </div>
    </template>

    <AddressPopup
            @close="closeAddressPopupHandler"
            v-if="showAddress"
    />

  </div>


</template>

<script>
import SideBar from "./SideBar/SideBar.vue";
import SideBarAdmin from "./SideBarAdmin/SideBarAdmin.vue";
import AddressPopup from "./popups/AddressPopup/AddressPopup";

export default {
  name: "SideBarBlock",

  components: {
    SideBar,
    SideBarAdmin,
    AddressPopup,
  },

  data() {
    return {
      showAddress: false,
    }
  },

  methods: {

    closeAddressPopupHandler(){
      this.showAddress = false;
    },
  }
}
</script>

<style scoped>

</style>
