
import {routerPaths} from '../../../router/routerPaths'
import permissions from "@/router/middleware/permissions";
import {PERMISSIONS} from "@/staticData/permissionsStatic";

const EasypostInvoicesTable = () => import(/* webpackChunkName: "group-easypost-invoices-table" */ './components/EasypostInvoicesTable/EasypostInvoicesTable.vue')
const moduleKey = 'easypost-invoices'


export const EasypostInvoicesRoutes = [
  {
    path: routerPaths.mainSettingsEasypostInvoices,
    component: EasypostInvoicesTable,
    meta: {
      breadcrumbs: {
        key: [
          'menu_settings',
          'mainSettingsEasypostInvoices'
        ],
        items: {
          'menu_settings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsEasypostInvoices,
          },
          'mainSettingsEasypostInvoices': {
            name: 'menu_easypostInvoices',
            link: routerPaths.mainSettingsEasypostInvoices,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.EASY_POST_INVOICES_LIST],

      moduleKey: moduleKey,
      pageTitle: 'menu_easypostInvoices'
    },
  }
]
