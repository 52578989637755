import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const OrderPriceTable = () => import(/* webpackChunkName: "group-order-price" */ './components/OrderPriceTable/OrderPriceTable.vue')
const moduleKey = 'order-price'

export const OrderPriceRoutes = [
  {
    path: routerPaths.mainSettingsOrderPrice,
    component: OrderPriceTable,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'reportsConsolidation'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsOrderPrice,
          },
          'reportsConsolidation': {
            name: 'breadcrumbs_mainSettingsOrderPrice',
            link: routerPaths.mainSettingsOrderPrice,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_PRICE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_mainSettingsOrderPrice'
    },
  },
]
