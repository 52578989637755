<template>
  <modal
      @close="$emit('close')"
      class="custom-popup"
  >
    <template slot="header">
      <template v-if="textConfirmDeletePopup.title">
        <span
                v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit"
              @click="editTranslate([textConfirmDeletePopup.title])"></span>

          {{$t(textConfirmDeletePopup.title + '.localization_value.value')}}
        </span>
      </template>
    </template>
    <template slot="body">
      <div class="custom-popup__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit"
                v-if="textConfirmDeletePopup.txt.length > 0"
                @click="editTranslate([textConfirmDeletePopup.txt])"></span>
        <template v-if="textConfirmDeletePopup.txt.length > 0" >
          <p class="fsz14" v-html="$t(textConfirmDeletePopup.txt + '.localization_value.value')">

          </p>
        </template>
        <template v-if="_.has(textConfirmDeletePopup, 'customTxt') && textConfirmDeletePopup.customTxt.length > 0" >
          <p class="fsz14" v-html="textConfirmDeletePopup.customTxt">

          </p>
        </template>

        <TextareaDefault
            v-if="confirmDeletePopupComment"
            class="mb-4 mh-225"
            :label="$t(textConfirmDeletePopup.commentTranslate + '.localization_value.value')"
            v-model="text"
        />

      </div>
    </template>
    <template slot="footer">
       <div class="buy-label__btn d-flex align-items-center mt-4"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit"
                @click="editTranslate([textConfirmDeletePopup.yes, textConfirmDeletePopup.no])"></span>

        <span
            class="site-link site-link--alt buy-label__btn-i mr-3"
            @click="confirm"
            v-if="textConfirmDeletePopup.yes"
        >
          {{$t(textConfirmDeletePopup.yes + '.localization_value.value')}}
        </span>

        <MainButton
            :value="$t(textConfirmDeletePopup.no + '.localization_value.value')"
            class="buy-label__btn-i wfc "
            @click.native="$emit('close')"
        />
      </div>
    </template>
  </modal>

</template>

<script>
  import Modal from '../../../commonModals/Modal.vue'
  import MainButton from '../../../UI/buttons/MainButton/MainButton.vue'
  import TextareaDefault from "@/components/UI/textarea/TextareaDefault/TextareaDefault";

  export default {
    name: "DeleteItemPopup",
    components: {
      TextareaDefault,
      Modal,
      MainButton,
    },

    props: {
      textConfirmDeletePopup: Object,
      confirmDeletePopupComment: Boolean,
    },

    watch: {
      textConfirmDeletePopup: (newVal) => {
        this.textConfirmDeletePopup = newVal
      }
    },

    data() {
      return {
        text: '',
      }
    },

    methods: {
      confirm() {
        if(this.confirmDeletePopupComment) {
          this.$emit('confirm', this.text)
        } else {
          this.$emit('confirm')
        }
      }
    }

  }

</script>

<style lang="scss">

</style>
