
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const CDEKTable = () => import(/* webpackChunkName: "group-cdek" */ './components/CDEKTable/CDEKTable.vue')
const CDEKCreation = () => import(/* webpackChunkName: "group-cdek" */ './components/CDEKCreation/CDEKCreation.vue')
const CDEKEditing = () => import(/* webpackChunkName: "group-cdek" */ './components/CDEKEditing/CDEKEditing.vue')
const CDEKShow = () => import(/* webpackChunkName: "group-cdek" */ './components/CDEKShow/CDEKShow.vue')
const moduleKey = 'cdek'


export const CDEKRoutes = [
  {
    path: routerPaths.cdek,
    component: CDEKTable,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'cdek'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'cdek': {
            name: 'breadcrumbs_CDEK',
            link: routerPaths.cdek,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_INTERNAL_CDEK],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_CDEK'
    },
  },
  {
    path: routerPaths.cdekCreationFromOrder,
    component: CDEKCreation,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'cdek',
          'cdekCreation',
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'cdek': {
            name: 'breadcrumbs_CDEK',
            link: routerPaths.cdek,
          },
          'cdekCreation': {
            name: 'breadcrumbs_NewOrderCDEK',
            link: routerPaths.cdekCreation,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_INTERNAL_CDEK],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewOrderCDEK'
    },
  },
  // {
  //   path: routerPaths.cdekCreation,
  //   component: CDEKCreation,
  //   meta: {
  //     breadcrumbs: {
  //       key: [
  //         'ordersAndDispatches',
  //         'cdek',
  //         'cdekCreation',
  //       ],
  //       items: {
  //         'ordersAndDispatches': {
  //           name: 'menu_ordersDispatches',
  //           link: routerPaths.ordersAndDispatches,
  //         },
  //         'cdek': {
  //           name: 'breadcrumbs_CDEK',
  //           link: routerPaths.cdek,
  //         },
  //         'cdekCreation': {
  //           name: 'breadcrumbs_NewOrderCDEK',
  //           link: routerPaths.cdekCreation,
  //         },
  //       },
  //     },
  //     pageTitle: 'breadcrumbs_NewOrderCDEK'
  //   },
  // },

  {
    path: routerPaths.cdekEditing,
    component: CDEKEditing,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'cdek',
          'cdekCreation',
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'cdek': {
            name: 'breadcrumbs_CDEK',
            link: routerPaths.cdek,
          },
          'cdekCreation': {
            name: 'breadcrumbs_OrderCDEK',
            link: routerPaths.cdekEditing,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_INTERNAL_CDEK],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_OrderCDEK'
    },
  },

  {
    path: routerPaths.cdekShow,
    component: CDEKShow,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'cdek',
          'cdekShow',
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'cdek': {
            name: 'menu_cdek',
            link: routerPaths.cdek,
          },
          'cdekShow': {
            name: 'breadcrumbs_OrderCDEK',
            link: routerPaths.cdekShow,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_INTERNAL_CDEK],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_OrderCDEK'
    },
  },
]
