
import {routerPaths} from '../../../router/routerPaths'

const ErrorLogsTable = () => import(/* webpackChunkName: "error-logs-backend" */ './components/ErrorLogsTable/ErrorLogsTable.vue')
const ErrorLogsShow = () => import(/* webpackChunkName: "error-logs-backend-show" */ './components/ErrorLogsShow/ErrorLogsShow.vue')
const moduleKey = 'error-logs'


export const ErrorLogsBackendRoutes = [
  {
    path: routerPaths.errorLogsBackend,
    component: ErrorLogsTable,
    meta: {
      breadcrumbs: {
        key: [
          'menu_settings',
          'mainSettingsErrorLogs'
        ],
        items: {
          'menu_settings': {
            name: 'menu_settings',
            link: routerPaths.errorLogsBackend,
          },
          'mainSettingsErrorLogs': {
            name: 'menu_ErrorLogs',
            link: routerPaths.errorLogsBackend,
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'menu_ErrorLogs'
    },
  },
  {
    path: routerPaths.errorLogsBackendShow,
    component: ErrorLogsShow,
    meta: {
      breadcrumbs: {
        key: [
          'menu_settings',
          'mainSettingsErrorLogs'
        ],
        items: {
          'menu_settings': {
            name: 'menu_settings',
            link: routerPaths.errorLogsBackend,
          },
          'mainSettingsErrorLogs': {
            name: 'menu_ErrorLogsBackend',
            link: routerPaths.errorLogsBackendShow,
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'menu_ErrorLogsBackend'
    },
  },
]
