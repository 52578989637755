import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const ScanBarcodeFBM = () => import(/* webpackChunkName: "group-scan-barcode-fbm" */ './components/ScanBarcodeFBM/ScanBarcodeFBM.vue')
const moduleKey = 'scan-barcode-fbm'

export const ScanBarcodeFBMRoutes = [
  {
    path: routerPaths.scanBarcodeFBM,
    component: ScanBarcodeFBM,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettings',
          'scanBarcodeFBM'
        ],
        items: {
          'mainSettings': {
            name: 'menu_settings',
            link: routerPaths.scanBarcodeFBM,
          },
          'scanBarcodeFBM': {
            name: 'menu_scanBarcodeFBM',
            link: routerPaths.scanBarcodeFBM,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.SCAN_FBM_LABEL_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_scanBarcodeFBM'
    },
  },
]
