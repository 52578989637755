<template>
  <div class="fragment">
    <template v-if="item">

      <div class="history-table__sub-row">
        <div class="history-table__sub-col w-100 mw-100"><b>{{$t('express_PikupInfo.localization_value.value')}}</b></div>
      </div>

      <div class="history-table__sub-row">
        <div class="history-table__sub-col">
          {{$t('ex_sendFrom.localization_value.value')}}
        </div>
        <div class="history-table__sub-col">
          <template v-if="pevItem && pevItem.history.order_sender_type === 'by_warehouse'">{{ $t('ex_warehouse.localization_value.value') }}</template>
          <template v-else-if="pevItem && pevItem.history.order_sender_type === 'by_himself'">{{ $t('ex_sender.localization_value.value') }}</template>
          <template v-else>—</template>

        </div>
        <div class="history-table__sub-col">
          <template v-if="item && item.history.order_sender_type === 'by_warehouse'">{{ $t('ex_warehouse.localization_value.value') }}</template>
          <template v-else-if="item && item.history.order_sender_type === 'by_himself'">{{ $t('ex_sender.localization_value.value') }}</template>
          <template v-else>—</template>
        </div>
      </div>

      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('ex_warehouse.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper v-if="pevItem && pevItem.history.order_sender_type === 'by_warehouse'" :value="pevItem" :deep="'history.warehouse.currentTranslate.name'"/><span v-else>—</span></div>
        <div class="history-table__sub-col"><ValueHelper v-if="item && item.history.order_sender_type === 'by_warehouse'" :value="item" :deep="'history.warehouse.currentTranslate.name'"/><span v-else>—</span></div>
      </div>

      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('express_TrackingNumber.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper v-if="pevItem && pevItem.history.order_sender_type === 'by_warehouse'" :value="pevItem" :deep="'history.new_post_tracking_number'"/><span v-else>—</span></div>
        <div class="history-table__sub-col"><ValueHelper v-if="item && item.history.order_sender_type === 'by_warehouse'" :value="item" :deep="'history.new_post_tracking_number'"/><span v-else>—</span></div>
      </div>

      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('express_ContactName.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper v-if="pevItem && pevItem.history.order_sender_type === 'by_himself'" :value="pevItem" :deep="'history.sender_contact_name'"/><span v-else>—</span></div>
        <div class="history-table__sub-col"><ValueHelper v-if="item && item.history.order_sender_type === 'by_himself'" :value="item" :deep="'history.sender_contact_name'"/><span v-else>—</span></div>
      </div>

      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('common_phone.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper v-if="pevItem && pevItem.history.order_sender_type === 'by_himself'" :value="pevItem" :deep="'history.sender_phone_number'"/><span v-else>—</span></div>
        <div class="history-table__sub-col"><ValueHelper v-if="item && item.history.order_sender_type === 'by_himself'" :value="item" :deep="'history.sender_phone_number'"/><span v-else>—</span></div>
      </div>


      <!--    /////////////-->
      <div class="history-table__sub-row">
        <div class="history-table__sub-col w-100 mw-100"><b>{{$t('express_DeliveryDetails.localization_value.value')}}</b></div>
      </div>

      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('express_ContactName.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.recipient_contact_name'"/></div>
        <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.recipient_contact_name'"/></div>
      </div>
      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('common_phone.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.recipient_phone_number'"/></div>
        <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.recipient_phone_number'"/></div>
      </div>
      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('express_Email.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.recipient_email'"/></div>
        <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.recipient_email'"/></div>
      </div>
      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('common_address.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.recipient_address'"/></div>
        <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.recipient_address'"/></div>
      </div>
      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('common_addressSecond.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.recipient_additional_address'"/></div>
        <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.recipient_additional_address'"/></div>
      </div>
      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('common_city.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.recipient_city'"/></div>
        <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.recipient_city'"/></div>
      </div>
      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('common_region.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.recipient_region'"/></div>
        <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.recipient_region'"/></div>
      </div>
      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('common_country.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.recipient_country.name'"/></div>
        <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.recipient_country.name'"/></div>
      </div>
      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('common_zipCode.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.recipient_zip_code'"/></div>
        <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.recipient_zip_code'"/></div>
      </div>


      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('express_FreightCost.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.freight_cost'"/></div>
        <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.freight_cost'"/></div>
      </div>
      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('express_orderPrice.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.total_order_amount'"/></div>
        <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.total_order_amount'"/></div>
      </div>
      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('express_LunchBreak.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.not_available_courier_time'"/></div>
        <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.not_available_courier_time'"/></div>
      </div>
      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('express_DeliveryDate.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.delivery_date'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
        <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.delivery_date'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
      </div>

      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('express_TimeGap.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.available_courier_time'"/></div>
        <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.available_courier_time'"/></div>
      </div>

      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('common_vatNumber.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.vat_number'"/></div>
        <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.vat_number'"/></div>
      </div>

      <div class="history-table__sub-row" v-if="(pevItem && _.find(pevItem.history.files, {type: 'deliveryVatConfirmationDocumentType'})) || _.find(item.history.files, {type: 'deliveryVatConfirmationDocumentType'})">
        <div class="history-table__sub-col">{{$t('common_vatNumber.localization_value.value')}} file</div>
        <div class="history-table__sub-col">
          <template v-if="pevItem && _.find(pevItem.history.files, {type: 'deliveryVatConfirmationDocumentType'})"><FileBlock :files="pevItem.history.files" :file="_.find(pevItem.history.files, {type: 'deliveryVatConfirmationDocumentType'})"/></template>
          <template v-else>—</template>
        </div>
        <div class="history-table__sub-col">
          <template v-if="_.find(item.history.files, {type: 'deliveryVatConfirmationDocumentType'})"><FileBlock :files="item.history.files" :file="_.find(item.history.files, {type: 'deliveryVatConfirmationDocumentType'})"/></template>
          <template v-else>—</template>
        </div>
      </div>

      <div class="history-table__sub-row" v-if="(pevItem && _.find(pevItem.history.files, {group: 'deliveryConfirmationDocument'})) || _.find(item.history.files, {group: 'deliveryConfirmationDocument'})">
        <div class="history-table__sub-col">{{$t('express_CostProofs.localization_value.value')}} file</div>
        <div class="history-table__sub-col">
          <template v-if="pevItem && _.find(pevItem.history.files, {group: 'deliveryConfirmationDocument'})"><FileBlock :files="pevItem.history.files" :file="_.find(pevItem.history.files, {group: 'deliveryConfirmationDocument', type: 'big_image'})"/></template>
          <template v-else>—</template>
        </div>
        <div class="history-table__sub-col">
          <template v-if="_.find(item.history.files, {group: 'deliveryConfirmationDocument'})"><FileBlock :files="item.history.files" :file="_.find(item.history.files, {group: 'deliveryConfirmationDocument', type: 'big_image'})"/></template>
          <template v-else>—</template>
        </div>
      </div>

      <div class="history-table__sub-row" v-if="(pevItem && _.find(pevItem.history.files, {type: 'labelFileType'})) || _.find(item.history.files, {type: 'labelFileType'})">
        <div class="history-table__sub-col">{{$t('poshta_Label.localization_value.value')}} file</div>
        <div class="history-table__sub-col">
          <template v-if="pevItem && _.find(pevItem.history.files, {type: 'labelFileType'})"><FileBlock :otherName="$t('poshta_Label.localization_value.value')" :files="pevItem.history.files" :file="_.find(pevItem.history.files, {type: 'labelFileType'})"/></template>
          <template v-else>—</template>
        </div>
        <div class="history-table__sub-col">
          <template v-if="_.find(item.history.files, {type: 'labelFileType'})"><FileBlock :otherName="$t('poshta_Label.localization_value.value')" :files="item.history.files" :file="_.find(item.history.files, {type: 'labelFileType',})"/></template>
          <template v-else>—</template>
        </div>
      </div>

      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('express_Currencies.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.recipient_currency.name'"/></div>
        <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.recipient_currency.name'"/></div>
      </div>

      <!--    /////////////-->
      <div class="history-table__sub-row">
        <div class="history-table__sub-col w-100 mw-100"><b>{{$t('express_ProductsDetails.localization_value.value')}}</b></div>
      </div>

<!--      <ProformTable-->
<!--          v-for="(proform, index) in item.history.proform_entities"-->
<!--          :key="index"-->
<!--          :pevItem="pevItem && _.has(pevItem.history.proform_entities, index) ? {history: pevItem.history.proform_entities[index]} : pevItem"-->
<!--          :item="{history: proform}"-->
<!--      />-->

<!--      <ProformTable-->
<!--          v-for="(proform, index) in item.history.proform_entities"-->
<!--          :key="index + 'proform'"-->
<!--          :pevItem="pevItem && _.has(pevItem.history.proform_entities, index) ? {history: pevItem.history.proform_entities[index]} : pevItem"-->
<!--          :item="{history: proform}"-->
<!--      />-->
<!--      <template v-if="pevItem && pevItem.history.proform_entities.length > item.history.proform_entities.length">-->
<!--        <template v-for="(proform, index) in pevItem.history.proform_entities">-->
<!--          <ProformTable-->
<!--              v-if="index > item.history.proform_entities.length - 1"-->
<!--              :key="index + 'proform0'"-->
<!--              :pevItem="{history: proform}"-->
<!--              :item="null"-->
<!--          />-->
<!--        </template>-->
<!--      </template>-->

      <ProformTable
          v-for="(proform, index) in item.history.proform_entities"
          :key="index + 'proform'"
          :pevItem="pevItem && _.has(getActualOrderProform, index) ? {history: getActualOrderProform[index]} : null"
          :item="{history: proform}"
      />
      <template v-if="pevItem && getDeletedOrderProform">
        <template v-for="(proform, index) in getDeletedOrderProform">
          <ProformTable
              :key="index + 'proform0'"
              :pevItem="{history: proform}"
              :item="null"
          />
        </template>
      </template>

      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('express_ToxicSubstance.localization_value.value')}}</div>
        <div class="history-table__sub-col" ><DefaultCheckbox v-if="pevItem" :value="pevItem.history.toxic_substance" :disabled="true"/></div>
        <div class="history-table__sub-col" ><DefaultCheckbox :value="item.history.toxic_substance" :disabled="true"/></div>
      </div>
      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('express_DropBallTest.localization_value.value')}}</div>
        <div class="history-table__sub-col" ><DefaultCheckbox v-if="pevItem" :value="pevItem.history.drop_certification" :disabled="true"/></div>
        <div class="history-table__sub-col" ><DefaultCheckbox :value="item.history.drop_certification" :disabled="true"/></div>
      </div>

      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('express_fedexPacking.localization_value.value')}}</div>
        <div class="history-table__sub-col">
          <template v-if="pevItem && pevItem.history.packaging_type"> {{$t(`${_.find(FEDEX_PACKAGING_TYPES, {value: pevItem.history.packaging_type}).translate}.localization_value.value`)}}</template>
          <template v-else>—</template>
        </div>
        <div class="history-table__sub-col">
          <template v-if="item.history.packaging_type"> {{$t(`${_.find(FEDEX_PACKAGING_TYPES, {value: item.history.packaging_type}).translate}.localization_value.value`)}}</template>
          <template v-else>—</template>
        </div>
      </div>

      <!--    /////////////-->
      <div class="history-table__sub-row">
        <div class="history-table__sub-col w-100 mw-100"><b>{{$t('express_Packaging.localization_value.value')}}</b></div>
      </div>

      <PackagingTable
          :pevItem="pevItem ? {packaging: pevItem.history.proform_packaging} : pevItem"
          :item="{packaging: item.history.proform_packaging}"
      />

      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('express_InsurancePrice.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.insurance_amount'"/></div>
        <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.insurance_amount'"/></div>
      </div>
      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('express_TrackingNumber.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.tracking_number'"/></div>
        <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.tracking_number'"/></div>
      </div>
      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('express_status.localization_value.value')}}</div>
        <div class="history-table__sub-col"><span v-if="pevItem && pevItem.history.status === 'completed'">Completed</span><span v-else>In Progress</span></div>
        <div class="history-table__sub-col"><span v-if="item.history.status === 'completed'">Completed</span><span v-else>In Progress</span></div>
      </div>

      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('express_status.localization_value.value')}} Invoice</div>
        <div class="history-table__sub-col" style="text-transform: capitalize"><span v-if="pevItem && pevItem.history.payed_invoice">{{pevItem.history.payed_invoice.status}}</span><span v-else-if="pevItem && pevItem.history.payed_invoice">{{pevItem.history.payed_invoice.status}}</span><span v-else>—</span></div>
        <div class="history-table__sub-col" style="text-transform: capitalize"><span v-if="item.history.payed_invoice">{{item.history.payed_invoice.status}}</span><span v-else-if="item && item.history.payed_invoice">{{item.history.payed_invoice.status}}</span><span v-else>—</span></div>
      </div>

      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('express_InvoiceFeeAmount.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.payed_invoice.fee_amount'"/></div>
        <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.payed_invoice.fee_amount'"/></div>
      </div>
      <div class="history-table__sub-row">
        <div class="history-table__sub-col">{{$t('express_InvoiceNetAmount.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.payed_invoice.net_cost_amount'"/></div>
        <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.payed_invoice.net_cost_amount'"/></div>
      </div>

    </template>

  </div>
</template>

<script>

import ProformTable from "@/components/coreComponents/Popups/HistoryOfChangesPopup/chunks/ProformTable/ProformTable";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import PackagingTable
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/chunks/PackagingTable/PackagingTable";
import FileBlock from "@/components/coreComponents/Popups/HistoryOfChangesPopup/chunks/FileBlock/FileBlock";
import {FEDEX_PACKAGING_TYPES} from "@/staticData/staticVariables";

export default {
  name: "ExpressType",

  components: {FileBlock, PackagingTable, DefaultCheckbox, ValueHelper, ProformTable},

  props: {
    pevItem: Object,
    item: Object,
  },

  computed: {

    getActualOrderProform() {
      if(!this.pevItem) return false
      if(!this.item.history?.deleted_proform_ids?.proform_data) return this.pevItem?.history?.proform_entities

      let actualProform = []
      let deletedIds = this.item.history?.deleted_proform_ids?.proform_data

      this.pevItem?.history?.proform_entities.map(prevProformItem => {
        let foundDeleted = false
        deletedIds.map(deletedId => {
          if(parseInt(prevProformItem.id) === parseInt(deletedId)) foundDeleted = true
        })
        if(!foundDeleted) actualProform.push(prevProformItem)
      })
      if(actualProform.length > 0) return actualProform

      return false
    },

    getDeletedOrderProform() {
      if(!this.pevItem || !this.item.history?.deleted_proform_ids?.proform_data) return false

      let deletedProform = []
      let deletedIds = this.item.history?.deleted_proform_ids?.proform_data

      deletedIds.map(deletedId => {
        let product = this._.find(this.pevItem?.history?.proform_entities, {id: parseInt(deletedId)})
        if(product) deletedProform.push(product)
      })
      if(deletedProform.length > 0) return deletedProform

      return false
    },

  },

  data() {
    return {
      FEDEX_PACKAGING_TYPES: FEDEX_PACKAGING_TYPES,
    }
  }
}
</script>

<style scoped>

</style>