
import {routerPaths} from '../../../router/routerPaths'
import permissions from "@/router/middleware/permissions";
import {PERMISSIONS} from "@/staticData/permissionsStatic";

const ScanFormDPTable = () => import(/* webpackChunkName: "group-other-scan-form-dp" */ './components/ScanFormDPTable/ScanFormDPTable.vue')
const moduleKey = 'scan-form-dp'


export const ScanFormDPRoutes = [
  {
    path: routerPaths.scanFormDP,
    component: ScanFormDPTable,
    meta: {
      breadcrumbs: {
        key: [
          'menu_settings',
          'mainSettingsScanFormDP'
        ],
        items: {
          'menu_settings': {
            name: 'menu_settings',
            link: routerPaths.scanFormDP,
          },
          'mainSettingsScanFormDP': {
            name: 'menu_scanFormDP',
            link: routerPaths.scanFormDP,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.FBM_SCAN_FORM],

      moduleKey: moduleKey,
      pageTitle: 'menu_scanFormDP'
    },
  }
]
