
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const EtsyTransferTable = () => import(/* webpackChunkName: "group-etsy-listings" */ './components/EtsyTransferTable/EtsyTransferTable.vue')
const EtsyTransferShop = () => import(/* webpackChunkName: "group-etsy-listings" */ './components/EtsyTransferShop/EtsyTransferShop.vue')
const moduleKey = 'etsy-transfer'

export const EtsyTransferRoutes = [
  {
    path: routerPaths.mainSettingsEtsyTransfer,
    component: EtsyTransferTable,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettings',
          'mainSettingsEtsyTransfer'
        ],
        items: {
          'mainSettings': {
            name: 'menu_settings',
            link: routerPaths.mainSettings,
          },
          'mainSettingsEtsyTransfer': {
            name: 'menu_transferEtsy',
            link: routerPaths.mainSettingsEtsyTransfer,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.LISTING_DOWNLOAD_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_transferEtsy'
    },
  },
  {
    path: routerPaths.mainSettingsEtsyTransferShop,
    component: EtsyTransferShop,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettings',
          'mainSettingsEtsyTransfer',
          'mainSettingsEtsyTransferShop',
        ],
        items: {
          'mainSettings': {
            name: 'menu_settings',
            link: routerPaths.mainSettings,
          },
          'mainSettingsEtsyTransfer': {
            name: 'menu_transferEtsy',
            link: routerPaths.mainSettingsEtsyTransfer,
          },
          'mainSettingsEtsyTransferShop': {
            name: 'breadcrumbs_ShopName',
            link: routerPaths.mainSettingsEtsyTransferShop,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.LISTING_DOWNLOAD_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_transferEtsy'
    },
  },
]
