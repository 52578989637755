import {API} from "../../axios/axiosMainUrl";

export const warehouses = {
  state: {
    warehouses: [],
    warehousesItem: {},
    warehousesCommonList: [],
    warehousesBtn: false,
    warehousesLoading: true,
    warehousesForPage: 25,
    nextWarehousesPage: false,

    warehousesType: [],
  },

  mutations: {
    setWarehouses: (state, payload) => {
      if(state.nextWarehousesPage) {
        state.warehouses = state.warehouses.concat(payload.warehouses)
      } else {
        state.warehouses = payload.warehouses
      }
    },

    setNextWarehousesPage: (state, payload) => {
      state.nextWarehousesPage = payload
    },

    setWarehousesItem: (state, payload) => {
      state.warehousesItem = payload.warehousesItem
    },

    setWarehousesType: (state, payload) => {
      state.warehousesType = payload.warehousesType
    },

    setWarehousesCommonList: (state, payload) => {
      state.warehousesCommonList = payload.warehousesCommonList
    },

    setWarehousesLoadingStart: (state) => {
      state.warehousesLoading = true
    },

    setWarehousesLoadingEnd: (state) => {
      state.warehousesLoading = false
    },

    changeWarehousesBtn: (state) => {
      state.warehousesBtn = true
    },

    successWarehousesBtn: (state) => {
      state.warehousesBtn = false
    },
  },

  getters: {
    getWarehouses: state => {
      return state.warehouses
    },

    getLoadingWarehouses: state => {
      return state.warehousesLoading
    },

    getWarehousesItem: state => {
      return state.warehousesItem
    },

    getWarehousesType: state => {
      return state.warehousesType
    },

    getWarehousesCommonList: state => {
      return state.warehousesCommonList
    },

    getWarehousesBtn: state => {
      return state.warehousesBtn
    },

    getWarehousesForPage: state => {
      return state.warehousesForPage
    },
  },

  actions: {

    async fetchWarehouses({commit}, filter = '') {
      commit('setWarehousesLoadingStart');
      try {
        return await API.get(`/warehouses${filter}`).then((response) =>{
          commit('setWarehouses',{warehouses: response.data.data.data})
          commit('setWarehousesCommonList',{warehousesCommonList: response.data.data})
          commit('setWarehousesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setWarehousesLoadingEnd');
        return e;
      }
    },

    async fetchWarehousesFromExpress({commit}, filter = '') {
      commit('setWarehousesLoadingStart');
      try {
        return await API.get(`/warehouses/express-list${filter}`).then((response) =>{
          commit('setWarehouses',{warehouses: response.data.data})
          // commit('setWarehousesCommonList',{warehousesCommonList: response.data.data})
          commit('setWarehousesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setWarehousesLoadingEnd');
        return e;
      }
    },

    async fetchWarehousesFromDeliveryStatistics({commit}, filter = '') {
      commit('setWarehousesLoadingStart');
      try {
        return await API.get(`/warehouses/by-delivery-service${filter}`).then((response) =>{
          commit('setWarehouses',{warehouses: response.data.data})
          // commit('setWarehousesCommonList',{warehousesCommonList: response.data.data})
          commit('setWarehousesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setWarehousesLoadingEnd');
        return e;
      }
    },

    async fetchWarehousesForAddressList({commit}, filter = '') {
      commit('setWarehousesLoadingStart');
      try {
        return await API.get(`/warehouses/warehouses-address-list${filter}`).then((response) =>{
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async fetchWarehousesFromConsolidation({commit}, filter = '') {
      try {
        return await API.get(`/warehouses/consolidation-list${filter}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setWarehousesLoadingEnd');
        return e;
      }
    },

    // async fetchWarehousesUserList({commit}, filter = '') {
    //   commit('setWarehousesLoadingStart');
    //   try {
    //     return await API.get(`/warehouses/user-list${filter}`).then((response) =>{
    //       commit('setWarehouses',{warehouses: response.data.data.data})
    //       commit('setWarehousesCommonList',{warehousesCommonList: response.data.data})
    //       commit('setWarehousesLoadingEnd');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('setWarehousesLoadingEnd');
    //     return e;
    //   }
    // },

    async getWarehouses({commit}, id) {
      commit('setWarehousesLoadingStart');
      try {
        return await API.get(`/warehouses/${id}`).then((response) =>{
          console.log(response)
          commit('setWarehousesItem', {warehousesItem: response.data.data})
          commit('setWarehousesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setWarehousesLoadingEnd');
        return e;
      }
    },

    async createWarehouses({commit}, data) {
      commit('changeWarehousesBtn');
      try {
        return await API.post(`/warehouses`, data).then((response) =>{
          commit('successWarehousesBtn');
          return response;
        });
      } catch (e) {
        commit('successWarehousesBtn');
        return e;
      }
    },

    async updateWarehouses({commit}, {id, data}) {
      commit('changeWarehousesBtn');
      try {
        return await API.put(`/warehouses/${id}`, data).then((response) =>{
          commit('successWarehousesBtn');
          return response;
        });
      } catch (e) {
        commit('successWarehousesBtn');
        return e;
      }
    },

    async deleteWarehouses({commit}, id) {
      try {
        return await API.delete(`/warehouses/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successWarehousesBtn');
        return e;
      }
    },


    async fetchWarehousesType({commit}) {
      try {
        return await API.get(`/warehouses/type`).then((response) =>{
          commit('setWarehousesType',{warehousesType: response.data.data.data})
          return response;
        });
      } catch (e) {
        commit('setWarehousesLoadingEnd');
        return e;
      }
    },


  }
}
