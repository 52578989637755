import {API} from "../../axios/axiosMainUrl";

const prefix = '/notifications/sms-message'
const prefixApiMessages = '/api-notifications/sms'

export const chatMassage = {
  state: {
    chatMassage: [],
    chatMassageItem: {},
    chatMassageCommonList: [],
    chatMassageBtn: false,
    chatMassageLoading: true,
    nextChatMassagePage: false,
    chatMassageForPage: 25,
    chatMassageFilter: '',
  },

  mutations: {
    setChatMassageFilter: (state, payload) => {
      state.chatMassageFilter = payload
    },

    setChatMassage: (state, payload) => {
      if(state.nextChatMassagePage) {
        state.chatMassage = state.chatMassage.concat(payload.chatMassage)
      } else {
        state.chatMassage = payload.chatMassage
      }
    },

    setChatMassageCommonList: (state, payload) => {
      state.chatMassageCommonList = payload.chatMassageCommonList
    },

    setNextChatMassagePage: (state, payload) => {
      state.nextChatMassagePage = payload
    },

    setChatMassageItem: (state, payload) => {
      state.chatMassageItem = payload.chatMassageItem
    },

    setChatMassageLoadingStart: (state) => {
      state.chatMassageLoading = true
    },

    setChatMassageLoadingEnd: (state) => {
      state.chatMassageLoading = false
    },

    changeChatMassageBtn: (state) => {
      state.chatMassageBtn = true
    },
    successChatMassageBtn: (state) => {
      state.chatMassageBtn = false
    },
  },

  getters: {
    getChatMassageFilter: state => {
      return state.chatMassageFilter
    },

    getChatMassageForPage: state => {
      return state.chatMassageForPage
    },

    getChatMassage: state => {
      return state.chatMassage
    },

    getChatMassageLoading: state => {
      return state.chatMassageLoading
    },


    getNextChatMassagePage: state => {
      return state.nextChatMassagePage
    },

    getChatMassageItem: state => {
      return state.chatMassageItem
    },

    getChatMassageCommonList: state => {
      return state.chatMassageCommonList
    },

    getChatMassageBtn: state => {
      return state.chatMassageBtn
    },
  },

  actions: {

    async fetchChatMassage({commit, getters}, filter = '') {
      if(!getters.getNextChatMassagePage)
        commit('setChatMassageLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setChatMassage',{chatMassage: response.data.data.data})
          commit('setChatMassageCommonList',{chatMassageCommonList: response.data.data})
          commit('setChatMassageLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setChatMassageLoadingEnd');
        return e;
      }
    },

    async getChatMassage({commit}, id) {
      commit('setChatMassageLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setChatMassageItem', {chatMassageItem: response.data.data})
          commit('setChatMassageLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setChatMassageLoadingEnd');
        return e;
      }
    },

    async createChatMassage({commit}, data) {
      commit('changeChatMassageBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successChatMassageBtn');
          return response;
        });
      } catch (e) {
        commit('successChatMassageBtn');
        return e;
      }
    },

    async sendApiMassage({commit}, {id}) {
      commit('changeChatMassageBtn');
      try {
        return await API.post(`${prefixApiMessages}/send/${id}`).then((response) =>{
          commit('successChatMassageBtn');
          return response;
        });
      } catch (e) {
        commit('successChatMassageBtn');
        return e;
      }
    },

    async refreshApiMassages({commit}) {
      commit('changeChatMassageBtn');
      try {
        return await API.get(`${prefixApiMessages}/refresh-messages`).then((response) =>{
          commit('successChatMassageBtn');
          return response;
        });
      } catch (e) {
        commit('successChatMassageBtn');
        return e;
      }
    },

    async refreshApiMassageByContact({commit}, id) {
      commit('changeChatMassageBtn');
      try {
        return await API.get(`${prefixApiMessages}/refresh-messages/${id}`).then((response) =>{
          commit('successChatMassageBtn');
          return response;
        });
      } catch (e) {
        commit('successChatMassageBtn');
        return e;
      }
    },

    async updateChatMassage({commit}, {id, data}) {
      commit('changeChatMassageBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successChatMassageBtn');
          return response;
        });
      } catch (e) {
        commit('successChatMassageBtn');
        return e;
      }
    },

    async deleteChatMassage({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successChatMassageBtn');
        return e;
      }
    },

  }
}
