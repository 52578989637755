const _ = require('lodash');

export const SET_AUTORIZE_DATA_AND_AVATAR = (store) => {

  // Set avatar loading start
  store.commit('setLoadingAvatarSmallStart')

  return store.dispatch('fetchUserProfileFiles', store.getters.getUserProfile.id)

}

export const GET_LARGE_AVATAR = (store) => {

  if(store.getters.getUserProfileFiles.avatar_files.length > 0){
    return store.dispatch('getFileFromServer', store.getters.getUserProfileFiles.avatar_files[1].id).then((response) => {
      return `data:image/${store.getters.getUserProfileFiles.avatar_files[1].extension};base64,`
        + response[store.getters.getUserProfileFiles.avatar_files[1].id]

      // return store.commit('setAvatarSmall', img)
    })
  }

  return Promise.resolve(false)


}

export const GET_SMALL_AVATAR = (store) => {


  if(store.getters.getUserProfile.small_avatar_file.length > 0) {
    let img = `data:image/${store.getters.getUserProfile.small_avatar_file[0].extension};base64,`
      + store.getters.getUserProfile.small_avatar_file[0].file

    localStorage.setItem('avatar', img)
    store.commit('setAvatarSmall', img)
  } else {
    localStorage.removeItem('avatar')
    store.commit('setAvatarSmall', [])
  }

  return Promise.resolve(false)
}

export const REFRESH_AVATAR = (store) => {

  // Set avatar loading start
  store.commit('setLoadingAvatarSmallStart')

  return store.dispatch('fetchUserProfileFiles', store.getters.getUserProfile.id).then(() => {
    return store.dispatch('getFileFromServer', store.getters.getUserProfileFiles.avatar_files[0].id).then((response) => {

      let img = `data:image/${store.getters.getUserProfileFiles.avatar_files[0].extension};base64,`
        + response[store.getters.getUserProfileFiles.avatar_files[0].id]

      store.commit('setAvatarSmall', img)

      // Set avatar loading end
      store.commit('setLoadingAvatarSmallEnd')

      return store.dispatch('getFileFromServer', store.getters.getUserProfileFiles.avatar_files[1].id).then((response) => {
        return `data:image/${store.getters.getUserProfileFiles.avatar_files[1].extension};base64,`
          + response[store.getters.getUserProfileFiles.avatar_files[1].id]
      })

    })
  })
}



export const GET_DOCUMENTS_IMAGE = (store) => {

  let imgArr = [];

  if(store.getters.getUserProfileFilesCarPassport !== undefined) {
    if(store.getters.getUserProfileFilesCarPassport.img) {
      store.getters.getUserProfileFilesCarPassport.img.map((item) => {
        store.dispatch('getFileFromServer', item.small.id).then((response) => {
          imgArr.push({
            id: item.small.id,
            img: `data:image/png;base64,` + response[item.small.id]
          })
        })
      })
    }

    if(store.getters.getUserProfileFilesCarPassport.pdf){
      store.getters.getUserProfileFilesCarPassport.pdf.map((item) => {
        store.dispatch('getFileFromServer', item.file.id).then((response) => {
          imgArr.push({
            id: item.file.id,
            img: `data:application/pdf;base64,` + response[item.file.id]
          })
        })
      })
    }
  }

  if(store.getters.getUserProfileFilesPassport !== undefined) {
    if(store.getters.getUserProfileFilesPassport.img){
      store.getters.getUserProfileFilesPassport.img.map((item) => {
        store.dispatch('getFileFromServer', item.small.id).then((response) => {
          imgArr.push({
            id: item.small.id,
            img: `data:image/png;base64,` + response[item.small.id]
          })
        })
      })
    }
    if(store.getters.getUserProfileFilesPassport.pdf){
      store.getters.getUserProfileFilesPassport.pdf.map((item) => {
        store.dispatch('getFileFromServer', item.file.id).then((response) => {
          imgArr.push({
            id: item.file.id,
            img: `data:application/pdf;base64,` + response[item.file.id]
          })
        })
      })
    }

  }

  return imgArr
}

export const REFRESH_DOCUMENTS = (store) => {

  return store.dispatch('fetchUserProfileFiles', store.getters.getUserProfile.id).then(() => {
    let imgArr = [];

    store.getters.getUserProfileFiles.passport_document_files.map((item) => {
      return store.dispatch('getFileFromServer', item.id).then((response) => {
        imgArr[item.id] = `data:image/png;base64,` + response[item.id]
      })
    })

    return imgArr
  })
}


export const prepareProfileFilesArray = (array) => {

  if(array.length === 0) return
  let img = []
  let pdf = []


  array.map((item) => {
    if(item.extension !== 'pdf') {
      if(item.type === 'small_image'){
        img.push({
          small: item,
          big: array[_.findKey(array, {'id': item.nested_file_entities[0].child_file_entry_id})]
        })
      }
    } else if (item.extension === 'pdf'){
      pdf.push({
        file: item
      })
    }
  })


  return {img: img, pdf: pdf}
}
