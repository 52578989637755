import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const NewPaymentsPayoneerTable = () => import(/* webpackChunkName: "group-new-payments-payoneer" */ './components/NewPaymentsPayoneerTable/NewPaymentsPayoneerTable.vue')
const NewPaymentsPayoneerCreate = () => import(/* webpackChunkName: "group-new-payments-payoneer" */ './components/NewPaymentsPayoneerCreate/NewPaymentsPayoneerCreate.vue')
const NewPaymentsPayoneerEdit = () => import(/* webpackChunkName: "group-new-payments-payoneer" */ './components/NewPaymentsPayoneerEdit/NewPaymentsPayoneerEdit.vue')
const moduleKey = 'new-payments'

export const NewPaymentsPayoneerRoutes = [
  {
    path: routerPaths.financeNewPaymentsPayoneer,
    component: NewPaymentsPayoneerTable,
    meta: {
      breadcrumbs: {
        key: [
          'finance',
          'financeNewPaymentsPayoneer'
        ],
        items: {
          'finance': {
            name: 'menu_finance',
            link: routerPaths.financeNewPaymentsPayoneer,
          },
          'financeNewPaymentsPayoneer': {
            name: 'menu_financeNewPaymentsPayoneer',
            link: routerPaths.financeNewPaymentsPayoneer,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.CUSTOMER_PAYMENT_PAYONEER],

      moduleKey: moduleKey,
      pageTitle: 'menu_financeNewPaymentsPayoneer'
    },
  },
  {
    path: routerPaths.financeNewPaymentsPayoneerCreate,
    component: NewPaymentsPayoneerCreate,
    meta: {
      breadcrumbs: {
        key: [
          'finance',
          'financeNewPaymentsPayoneer',
          'financeNewPaymentsPayoneerCreate'
        ],
        items: {
          'finance': {
            name: 'menu_finance',
            link: routerPaths.financeNewPaymentsPayoneer,
          },
          'financeNewPaymentsPayoneer': {
            name: 'menu_financeNewPaymentsPayoneer',
            link: routerPaths.financeNewPaymentsPayoneer,
          },
          'financeNewPaymentsPayoneerCreate': {
            name: 'menu_financeNewPaymentsPayoneerCreate',
            link: routerPaths.financeNewPaymentsPayoneerCreate,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.CUSTOMER_PAYMENT_PAYONEER],

      moduleKey: moduleKey,
      pageTitle: 'menu_financeNewPaymentsPayoneerCreate'
    },
  },


  {
    path: routerPaths.financeNewPaymentsPayoneerEdit,
    component: NewPaymentsPayoneerEdit,
    meta: {
      breadcrumbs: {
        key: [
          'finance',
          'financeNewPaymentsPayoneer',
          'financeNewPaymentsPayoneerEdit'
        ],
        items: {
          'finance': {
            name: 'menu_finance',
            link: routerPaths.financeNewPaymentsPayoneer,
          },
          'financeNewPaymentsPayoneer': {
            name: 'menu_financeNewPaymentsPayoneer',
            link: routerPaths.financeNewPaymentsPayoneer,
          },
          'financeNewPaymentsPayoneerEdit': {
            name: 'breadcrumbs_editNewPaymentsPayoneer',
            link: routerPaths.financeNewPaymentsPayoneerCreate,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.CUSTOMER_PAYMENT_PAYONEER],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_editNewPaymentsPayoneer'
    },
  },
]
