import {API} from "../../axios/axiosMainUrl";

export const profilesProform = {
  state: {
    profilesProform: {},
    profilesProformItem: {},
    profilesProformCommonList: [],
    profilesProformBtn: false,
    profilesProformLoading: true,
    nextProfilesProformPage: false,
    profilesProformForPage: 25,
    profilesProformFilter: '',
  },

  mutations: {
    setProfilesProformFilter: (state, payload) => {
      state.profilesProformFilter = payload
    },

    setProfilesProform: (state, payload) => {
      if(state.nextProfilesProformPage) {
        state.profilesProform = state.profilesProform.concat(payload.profilesProform)
      } else {
        state.profilesProform = payload.profilesProform
      }
    },

    setProfilesProformCommonList: (state, payload) => {
      state.profilesProformCommonList = payload.profilesProformCommonList
    },

    setNextProfilesProformPage: (state, payload) => {
      state.nextProfilesProformPage = payload
    },

    setProfilesProformItem: (state, payload) => {
      state.profilesProformItem = payload.profilesProformItem
    },

    setProfilesProformLoadingStart: (state) => {
      state.profilesProformLoading = true
    },

    setProfilesProformLoadingEnd: (state) => {
      state.profilesProformLoading = false
    },

    changeProfilesProformBtn: (state) => {
      state.profilesProformBtn = true
    },
    successProfilesProformBtn: (state) => {
      state.profilesProformBtn = false
    },
  },

  getters: {
    getProfilesProformFilter: state => {
      return state.profilesProformFilter
    },

    getProfilesProformForPage: state => {
      return state.profilesProformForPage
    },

    getProfilesProform: state => {
      return state.profilesProform
    },

    getProfilesProformLoading: state => {
      return state.profilesProformLoading
    },


    getNextProfilesProformPage: state => {
      return state.nextProfilesProformPage
    },

    getProfilesProformItem: state => {
      return state.profilesProformItem
    },

    getProfilesProformCommonList: state => {
      return state.profilesProformCommonList
    },

    getProfilesProformBtn: state => {
      return state.profilesProformBtn
    },
  },

  actions: {

    async fetchProfilesProform({commit}, filter = '') {
      try {
        return await API.get(`/products/proform-entity${filter}`).then((response) =>{
          commit('setProfilesProform',{profilesProform: response.data.data.data})
          commit('setProfilesProformCommonList',{profilesProformCommonList: response.data.data})
          commit('setProfilesProformLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setProfilesProformLoadingEnd');
        return e;
      }
    },

    async getProfilesProform({commit}, filter = '') {
      commit('setProfilesProformLoadingStart');
      try {
        return await API.get(`/products/proform-entity${filter}`).then((response) =>{
          commit('setProfilesProformItem', {profilesProformItem: response.data.data.data})
          commit('setProfilesProformLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setProfilesProformLoadingEnd');
        return e;
      }
    },

    async createProfilesProform({commit}, data) {
      commit('changeProfilesProformBtn');
      try {
        return await API.post(`/profilesProform`, data).then((response) =>{
          commit('successProfilesProformBtn');
          return response;
        });
      } catch (e) {
        commit('successProfilesProformBtn');
        return e;
      }
    },

    async updateProfilesProform({commit}, {id, data}) {
      commit('changeProfilesProformBtn');
      try {
        return await API.put(`/profilesProform/${id}`, data).then((response) =>{
          commit('successProfilesProformBtn');
          return response;
        });
      } catch (e) {
        commit('successProfilesProformBtn');
        return e;
      }
    },

    async deleteProfilesProform({commit}, id) {
      try {
        return await API.delete(`/products/proform-entity/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successProfilesProformBtn');
        return e;
      }
    },

  }
}
