import {routerPaths} from '../../../router/routerPaths'
import PayPalCasesEditing from "./components/PayPalCasesEditing/PayPalCasesEditing";
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const PayPalCasesTable = () => import(/* webpackChunkName: "paypal-cases" */ './components/PayPalCasesTable/PayPalCasesTable.vue')
const PayPalCasesCreation = () => import(/* webpackChunkName: "paypal-cases-create" */ './components/PayPalCasesCreation/PayPalCasesCreation.vue')
const moduleKey = 'paypal-cases'


export const PayPalCasesRoutes = [
  {
    path: routerPaths.financePayPalCases,
    component: PayPalCasesTable,
    meta: {
      breadcrumbs: {
        key: [
          'finance',
          'payPalCases'
        ],
        items: {
          'finance': {
            name: 'menu_finance',
            link: routerPaths.financePayPalCases,
          },
          'payPalCases': {
            name: 'menu_payPalCases',
            link: routerPaths.financePayPalCases,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.PAYPAL_CASE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_payPalCases'
    },
  },

  {
    path: routerPaths.financePayPalCasesCreate,
    component: PayPalCasesCreation,
    meta: {
      breadcrumbs: {
        key: [
          'payPalCases',
          'problemsPayPalCasesCreation'
        ],
        items: {
          'payPalCases': {
            name: 'menu_payPalCases',
            link: routerPaths.financePayPalCases,
          },
          'problemsPayPalCasesCreation': {
            name: 'breadcrumbs_newPaypalCase',
            link: routerPaths.financePayPalCasesCreate,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.PAYPAL_CASE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_newPaypalCase'
    },
  },

  {
    path: routerPaths.financePayPalCasesEdit,
    component: PayPalCasesEditing,
    meta: {
      breadcrumbs: {
        key: [
          'payPalCases',
          'problemsPayPalCasesEditing'
        ],
        items: {
          'payPalCases': {
            name: 'menu_payPalCases',
            link: routerPaths.financePayPalCases,
          },
          'problemsPayPalCasesEditing': {
            name: 'breadcrumbs_editPaypalCase',
            link: routerPaths.financePayPalCasesEdit,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.PAYPAL_CASE_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_editPaypalCase'
    },
  },
]
