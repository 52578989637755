import {API} from "../../axios/axiosMainUrl";

const prefix = 'payments/forbidden-items-keywords'

export const forbiddenKeywords = {
  state: {
    forbiddenKeywords: [],
    forbiddenKeywordsItem: {},
    forbiddenKeywordsCommonList: [],
    forbiddenKeywordsBtn: false,
    forbiddenKeywordsLoading: true,
    nextForbiddenKeywordsPage: false,
    forbiddenKeywordsForPage: 25,
    forbiddenKeywordsFilter: '',
  },

  mutations: {
    setForbiddenKeywordsFilter: (state, payload) => {
      state.forbiddenKeywordsFilter = payload
    },

    setForbiddenKeywords: (state, payload) => {
      if(state.nextForbiddenKeywordsPage) {
        state.forbiddenKeywords = state.forbiddenKeywords.concat(payload.forbiddenKeywords)
      } else {
        state.forbiddenKeywords = payload.forbiddenKeywords
      }
    },

    setForbiddenKeywordsCommonList: (state, payload) => {
      state.forbiddenKeywordsCommonList = payload.forbiddenKeywordsCommonList
    },

    setNextForbiddenKeywordsPage: (state, payload) => {
      state.nextForbiddenKeywordsPage = payload
    },

    setForbiddenKeywordsItem: (state, payload) => {
      state.forbiddenKeywordsItem = payload.forbiddenKeywordsItem
    },

    setForbiddenKeywordsLoadingStart: (state) => {
      state.forbiddenKeywordsLoading = true
    },

    setForbiddenKeywordsLoadingEnd: (state) => {
      state.forbiddenKeywordsLoading = false
    },

    changeForbiddenKeywordsBtn: (state) => {
      state.forbiddenKeywordsBtn = true
    },
    successForbiddenKeywordsBtn: (state) => {
      state.forbiddenKeywordsBtn = false
    },
  },

  getters: {
    getForbiddenKeywordsFilter: state => {
      return state.forbiddenKeywordsFilter
    },

    getForbiddenKeywordsForPage: state => {
      return state.forbiddenKeywordsForPage
    },

    getForbiddenKeywords: state => {
      return state.forbiddenKeywords
    },

    getForbiddenKeywordsLoading: state => {
      return state.forbiddenKeywordsLoading
    },


    getNextForbiddenKeywordsPage: state => {
      return state.nextForbiddenKeywordsPage
    },

    getForbiddenKeywordsItem: state => {
      return state.forbiddenKeywordsItem
    },

    getForbiddenKeywordsCommonList: state => {
      return state.forbiddenKeywordsCommonList
    },

    getForbiddenKeywordsBtn: state => {
      return state.forbiddenKeywordsBtn
    },
  },

  actions: {

    async fetchForbiddenKeywords({commit, getters}, filter = '') {
      if(!getters.getNextForbiddenKeywordsPage)
        commit('setForbiddenKeywordsLoadingStart')
      try {
        return await API.get(`/${prefix}${filter}`).then((response) =>{
          commit('setForbiddenKeywords',{forbiddenKeywords: response.data.data.data})
          commit('setForbiddenKeywordsCommonList',{forbiddenKeywordsCommonList: response.data.data})
          commit('setForbiddenKeywordsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setForbiddenKeywordsLoadingEnd');
        return e;
      }
    },

    async getForbiddenKeywords({commit}, id) {
      try {
        return await API.get(`/${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setForbiddenKeywordsItem', {forbiddenKeywordsItem: response.data.data})
          commit('setForbiddenKeywordsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setForbiddenKeywordsLoadingEnd');
        return e;
      }
    },

    async createForbiddenKeywords({commit}, data) {
      commit('changeForbiddenKeywordsBtn');
      try {
        return await API.post(`/${prefix}`, data).then((response) =>{
          commit('successForbiddenKeywordsBtn');
          return response;
        });
      } catch (e) {
        commit('successForbiddenKeywordsBtn');
        return e;
      }
    },

    async updateForbiddenKeywords({commit}, {id, data}) {
      commit('changeForbiddenKeywordsBtn');
      try {
        return await API.put(`/${prefix}/${id}`, data).then((response) =>{
          commit('successForbiddenKeywordsBtn');
          return response;
        });
      } catch (e) {
        commit('successForbiddenKeywordsBtn');
        return e;
      }
    },

    async deleteForbiddenKeywords({commit}, id) {
      try {
        return await API.delete(`/${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successForbiddenKeywordsBtn');
        return e;
      }
    },

  }
}
