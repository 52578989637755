// import {routerPaths} from "../../../router/routerPaths";

export const DashboardRoutes = [
  // {
  //   path: routerPaths.login,
  //   component: Login,
  //   meta: {
  //     layout: {
  //       name: 'auth',
  //     },
  //   },
  // },
]
