import {API} from "../../axios/axiosMainUrl";

const prefix = '/user-balance/payment-history'

export const balancePayoneerHistory = {
  state: {
    balancePayoneerHistory: [],
    balancePayoneerHistoryItem: {},
    balancePayoneerHistoryCommonList: [],
    balancePayoneerHistoryBtn: false,
    balancePayoneerHistoryLoading: true,
    nextBalancePayoneerHistoryPage: false,
    balancePayoneerHistoryForPage: 25,
    balancePayoneerHistoryFilter: '',
  },

  mutations: {
    setBalancePayoneerHistoryFilter: (state, payload) => {
      state.balancePayoneerHistoryFilter = payload
    },

    setBalancePayoneerHistory: (state, payload) => {
      if(state.nextBalancePayoneerHistoryPage) {
        state.balancePayoneerHistory = state.balancePayoneerHistory.concat(payload.balancePayoneerHistory)
      } else {
        state.balancePayoneerHistory = payload.balancePayoneerHistory
      }
    },

    setBalancePayoneerHistoryCommonList: (state, payload) => {
      state.balancePayoneerHistoryCommonList = payload.balancePayoneerHistoryCommonList
    },

    setNextBalancePayoneerHistoryPage: (state, payload) => {
      state.nextBalancePayoneerHistoryPage = payload
    },

    setBalancePayoneerHistoryItem: (state, payload) => {
      state.balancePayoneerHistoryItem = payload.balancePayoneerHistoryItem
    },

    setBalancePayoneerHistoryLoadingStart: (state) => {
      state.balancePayoneerHistoryLoading = true
    },

    setBalancePayoneerHistoryLoadingEnd: (state) => {
      state.balancePayoneerHistoryLoading = false
    },

    changeBalancePayoneerHistoryBtn: (state) => {
      state.balancePayoneerHistoryBtn = true
    },
    successBalancePayoneerHistoryBtn: (state) => {
      state.balancePayoneerHistoryBtn = false
    },
  },

  getters: {
    getBalancePayoneerHistoryFilter: state => {
      return state.balancePayoneerHistoryFilter
    },

    getBalancePayoneerHistoryForPage: state => {
      return state.balancePayoneerHistoryForPage
    },

    getBalancePayoneerHistory: state => {
      return state.balancePayoneerHistory
    },

    getBalancePayoneerHistoryLoading: state => {
      return state.balancePayoneerHistoryLoading
    },


    getNextBalancePayoneerHistoryPage: state => {
      return state.nextBalancePayoneerHistoryPage
    },

    getBalancePayoneerHistoryItem: state => {
      return state.balancePayoneerHistoryItem
    },

    getBalancePayoneerHistoryCommonList: state => {
      return state.balancePayoneerHistoryCommonList
    },

    getBalancePayoneerHistoryBtn: state => {
      return state.balancePayoneerHistoryBtn
    },
  },

  actions: {

    async fetchBalancePayoneerHistory({commit, getters}, filter = '') {
      if(!getters.getNextBalancePayoneerHistoryPage)
        commit('setBalancePayoneerHistoryLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setBalancePayoneerHistory',{balancePayoneerHistory: response.data.data.data})
          // commit('setBalancePayoneerHistoryCommonList',{balancePayoneerHistoryCommonList: response.data.data})
          commit('setBalancePayoneerHistoryCommonList',{balancePayoneerHistoryCommonList: {...response.data.data.meta, ...{
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev,
                balance: response.data.data.balance,
              }}})
          commit('setBalancePayoneerHistoryLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setBalancePayoneerHistoryLoadingEnd');
        return e;
      }
    },

    async getBalancePayoneerHistory({commit}, id) {
      commit('setBalancePayoneerHistoryLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setBalancePayoneerHistoryItem', {balancePayoneerHistoryItem: response.data.data})
          commit('setBalancePayoneerHistoryLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setBalancePayoneerHistoryLoadingEnd');
        return e;
      }
    },

    async createBalancePayoneerHistory({commit}, data) {
      commit('changeBalancePayoneerHistoryBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successBalancePayoneerHistoryBtn');
          return response;
        });
      } catch (e) {
        commit('successBalancePayoneerHistoryBtn');
        return e;
      }
    },

    async updateBalancePayoneerHistory({commit}, {id, data}) {
      commit('changeBalancePayoneerHistoryBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successBalancePayoneerHistoryBtn');
          return response;
        });
      } catch (e) {
        commit('successBalancePayoneerHistoryBtn');
        return e;
      }
    },

    async deleteBalancePayoneerHistory({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successBalancePayoneerHistoryBtn');
        return e;
      }
    },

    async getExportBalancePayoneerHistory({commit}, {filter , type}) {
      commit('setExportBtnLoadingStart')
      commit('setLoadingEnd')
      try {
        return await API.get(`/user-balance/payment-history/export/${type}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

  }
}
