
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";


const CusPaymentsTablePage = () => import(/* webpackChunkName: "group-cus-payments" */ './components/CusPaymentsTablePage/CusPaymentsTablePage.vue')
const CusPaymentsEdit = () => import(/* webpackChunkName: "group-cus-payments" */ './components/CusPaymentsEdit/CusPaymentsEdit.vue')
const moduleKey = 'customer-payments'

export const CusPaymentsRoutes = [
  {
    path: routerPaths.cusPayments,
    component: CusPaymentsTablePage,
    meta: {
      breadcrumbs: {
        key: [
          'finance',
          'cusPayments'
        ],
        items: {
          'finance': {
            name: 'menu_finance',
            link: routerPaths.dashboard,
          },
          'cusPayments': {
            name: 'menu_customersPayments',
            link: routerPaths.cusPayments,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.CUSTOMER_PAYMENT_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_customersPayments'
    },
  },
  {
    path: routerPaths.cusPaymentsEdit,
    component: CusPaymentsEdit,
    meta: {
      breadcrumbs: {
        key: [
          'finance',
          'cusPayments',
          'cusPaymentsEdit'
        ],
        items: {
          'finance': {
            name: 'Finance',
            link: routerPaths.dashboard,
          },
          'cusPayments': {
            name: 'Customers’ Payments',
            link: routerPaths.cusPayments,
          },
          'cusPaymentsEdit': {
            name: 'Edit Customers’ Payment',
            link: routerPaths.cusPaymentsEdit,
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'Edit Customers’ Payment'
    },
  }
]
