import {API} from "../../axios/axiosMainUrl";

const prefix = '/settings/holidays'

export const holidays = {
  state: {
    holidays: [],
    holidaysItem: {},
    holidaysCommonList: [],
    holidaysBtn: false,
    holidaysLoading: true,
    nextHolidaysPage: false,
    holidaysForPage: 25,
    holidaysFilter: '',
  },

  mutations: {
    setHolidaysFilter: (state, payload) => {
      state.holidaysFilter = payload
    },

    setHolidays: (state, payload) => {
      if(state.nextHolidaysPage) {
        state.holidays = state.holidays.concat(payload.holidays)
      } else {
        state.holidays = payload.holidays
      }
    },

    setHolidaysCommonList: (state, payload) => {
      state.holidaysCommonList = payload.holidaysCommonList
    },

    setNextHolidaysPage: (state, payload) => {
      state.nextHolidaysPage = payload
    },

    setHolidaysItem: (state, payload) => {
      state.holidaysItem = payload.holidaysItem
    },

    setHolidaysLoadingStart: (state) => {
      state.holidaysLoading = true
    },

    setHolidaysLoadingEnd: (state) => {
      state.holidaysLoading = false
    },

    changeHolidaysBtn: (state) => {
      state.holidaysBtn = true
    },
    successHolidaysBtn: (state) => {
      state.holidaysBtn = false
    },
  },

  getters: {
    getHolidaysFilter: state => {
      return state.holidaysFilter
    },

    getHolidaysForPage: state => {
      return state.holidaysForPage
    },

    getHolidays: state => {
      return state.holidays
    },

    getHolidaysLoading: state => {
      return state.holidaysLoading
    },


    getNextHolidaysPage: state => {
      return state.nextHolidaysPage
    },

    getHolidaysItem: state => {
      return state.holidaysItem
    },

    getHolidaysCommonList: state => {
      return state.holidaysCommonList
    },

    getHolidaysBtn: state => {
      return state.holidaysBtn
    },
  },

  actions: {

    async fetchHolidays({commit, getters}, filter = '') {
      if(!getters.getNextHolidaysPage)
        commit('setHolidaysLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setHolidays',{holidays: response.data.data.data})
          commit('setHolidaysCommonList',{holidaysCommonList: response.data.data})
          commit('setHolidaysLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setHolidaysLoadingEnd');
        return e;
      }
    },

    async getHolidays({commit}, id) {
      // commit('setHolidaysLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setHolidaysItem', {holidaysItem: response.data.data})
          // commit('setHolidaysLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setHolidaysLoadingEnd');
        return e;
      }
    },

    async createHolidays({commit}, data) {
      commit('changeHolidaysBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successHolidaysBtn');
          return response;
        });
      } catch (e) {
        commit('successHolidaysBtn');
        return e;
      }
    },

    async updateHolidays({commit}, {id, data}) {
      commit('changeHolidaysBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successHolidaysBtn');
          return response;
        });
      } catch (e) {
        commit('successHolidaysBtn');
        return e;
      }
    },

    async deleteHolidays({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successHolidaysBtn');
        return e;
      }
    },

  }
}
