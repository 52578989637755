import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const ThermalPrint = () => import(/* webpackChunkName: "group-thermal-print" */ './components/ThermalPrint/ThermalPrint.vue')
const moduleKey = 'thermal-print'

export const ThermalPrintRoutes = [
  {
    path: routerPaths.thermalPrint,
    component: ThermalPrint,
    meta: {
      breadcrumbs: {
        key: [
          'mainSettings',
          'thermalPrint'
        ],
        items: {
          'mainSettings': {
            name: 'menu_settings',
            link: routerPaths.thermalPrint,
          },
          'thermalPrint': {
            name: 'menu_thermalPrint',
            link: routerPaths.thermalPrint,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.CUSTOM_PRODUCT_THERMAL_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_thermalPrint'
    },
  },
]
