<template>
  <div class="text-editor">
    <vue-editor
        v-model="vEditorModel"
        @text-change="textChangeFunc(vEditorModel)"
        :editor-toolbar="customToolbar"
    />
    <span class="default-input-wrap__error error-field" v-if="error && errorTxt">{{errorTxt}}</span>

    <link href="https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">

  </div>
</template>

<script>
  import { VueEditor, Quill } from "vue2-editor";

  export default {
    name: "TextEditor",
    components: {
      VueEditor,
    },

    props: [
      'value',
      'error',
      'errorTxt',
    ],

    data(){
      return {
        vEditorModel: this.value,
        customToolbar: [
          [{header: [false, 1, 2, 3, 4, 5, 6]}],
          // [{ color: [] }, { background: [] }],
          [{ size: []}],
          [{ font: ["roboto", "arial", "tahoma", "raleway", "courier", "timesnewroman", "comicsansms"]}],
          [{align: ""}, {align: "center"}, {align: "right"}, {align: "justify"}],
          ["bold", "italic", "underline", "strike", { 'script': 'super' }, { 'script': 'sub' }],
          [{ list: "ordered" }, { list: "bullet" }],
        ],
      }
    },

    watch: {
      value(newVal) {
        this.vEditorModel = newVal
      }
    },

    methods: {
      textChangeFunc(value){
        this.$emit('input', value)
      }
    },

    mounted() {
      let Font = Quill.import("formats/font");
      Font.whitelist = ["roboto", "arial", 'tahoma', 'raleway', 'courier', 'timesnewroman', 'comicsansms'];
    }
  }
</script>

<style lang="scss">
  @import "~vue2-editor/dist/vue2-editor.css";
  @import "../../../scss/colors";

  .text-editor{
    position: relative;

    &.ui-no-valid .quillWrapper{
      border: 2px solid $orange;
    }

    .quillWrapper{
      border: 1px solid $brown;
      border-radius: 5px;
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow{
      border: none;
    }


    .ql-picker-options{
      background:  $mainBg !important;
    }

    .ql-font span[data-value="roboto"]::before {
      font-family: 'Roboto'!important;
      content: "Roboto" !important;
    }

    .ql-font-roboto {
      font-family: 'Roboto'!important;
    }

    .ql-font span[data-value="arial"]::before {
      font-family: "Arial";
      content: "Arial" !important;
    }

    .ql-font-arial {
      font-family: 'Arial'!important;
    }

    .ql-font span[data-value="tahoma"]::before {
      font-family: "Tahoma";
      content: "Tahoma" !important;
    }

    .ql-font-tahoma {
      font-family: 'Tahoma'!important;
    }

    .ql-font span[data-value="raleway"]::before {
      font-family: "Raleway";
      content: "Raleway" !important;
    }

    .ql-font-raleway {
      font-family: 'Raleway', sans-serif;
    }

    .ql-font span[data-value="courier"]::before {
      font-family: "Courier";
      content: "Courier" !important;
    }

    .ql-font-courier {
      font-family: 'Courier', sans-serif;
    }

    .ql-font span[data-value="timesnewroman"]::before {
      font-family: "Times New Roman";
      content: "Times Roman" !important;
    }

    .ql-font-timesnewroman {
      font-family: 'Times New Roman', sans-serif;
    }

    .ql-font span[data-value="comicsansms"]::before {
      font-family: "Comic Sans MS";
      content: "Comic Sans" !important;
    }

    .ql-font-comicsansms {
      font-family: 'Comic Sans MS', sans-serif;
    }


    .ql-editor{
      min-height: 120px;
      background:  $mainBg;
      border-radius: 0 0 5px 5px;
    }

    .ql-formats{
      border-radius: 4px;
      background:  $mainBg;
      margin-bottom: 5px!important;
      margin-right: 5px!important;
      height: 32px;
      display: inline-flex!important;
      width: fit-content!important;
      align-items: center!important;
    }

    .ql-picker-label:focus{
      outline: none!important;
    }

    .quillWrapper .ql-picker-label{
      font-size: 14px;
    }

    .ql-toolbar{
      display: flex;
      flex-wrap: wrap;
    }


    .ql-snow .ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar button.ql-active, .ql-snow .ql-toolbar button:focus, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar button.ql-active, .ql-snow.ql-toolbar button:focus, .ql-snow.ql-toolbar button:hover{
      color: $borderBrown
    }

    .quillWrapper .ql-snow .ql-stroke{
      stroke: $borderBrown;
    }

    .ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill{
      fill: $borderBrown;
    }

    .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:focus .ql-fill, .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover .ql-fill, .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:focus .ql-fill, .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:hover .ql-fill, .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill{
      fill: $brown;
    }

    .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter{
      stroke: $brown;
    }

    .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg{
      transform: translateY(-50%);
      margin: 0;
      top: calc(50% - 4px) !important;
    }

    .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg > .ql-stroke:last-child{
      display: none;
    }



    .ql-snow .ql-toolbar button svg, .quillWrapper .ql-snow.ql-toolbar button svg{
      width: 14px;
      height: 14px;
    }

    .ql-snow .ql-picker-label::before{
      line-height: 20px;
    }

    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label{
      border-color: transparent;
    }
  }
</style>
