import {API} from "../../axios/axiosMainUrl";

const prefix = '/return-parcels'

export const returnGoods = {
  state: {
    returnGoods: [],
    returnGoodsItem: {},
    returnGoodsCommonList: [],
    returnGoodsBtn: false,
    returnGoodsLoading: true,
    nextReturnGoodsPage: false,
    returnGoodsForPage: 25,
    returnGoodsFilter: '',
  },

  mutations: {
    setReturnGoodsFilter: (state, payload) => {
      state.returnGoodsFilter = payload
    },

    setReturnGoods: (state, payload) => {
      if(state.nextReturnGoodsPage) {
        state.returnGoods = state.returnGoods.concat(payload.returnGoods)
      } else {
        state.returnGoods = payload.returnGoods
      }
    },

    setReturnGoodsCommonList: (state, payload) => {
      state.returnGoodsCommonList = payload.returnGoodsCommonList
    },

    setNextReturnGoodsPage: (state, payload) => {
      state.nextReturnGoodsPage = payload
    },

    setReturnGoodsItem: (state, payload) => {
      state.returnGoodsItem = payload.returnGoodsItem
    },

    setReturnGoodsLoadingStart: (state) => {
      state.returnGoodsLoading = true
    },

    setReturnGoodsLoadingEnd: (state) => {
      state.returnGoodsLoading = false
    },

    changeReturnGoodsBtn: (state) => {
      state.returnGoodsBtn = true
    },
    successReturnGoodsBtn: (state) => {
      state.returnGoodsBtn = false
    },
  },

  getters: {
    getReturnGoodsFilter: state => {
      return state.returnGoodsFilter
    },

    getReturnGoodsForPage: state => {
      return state.returnGoodsForPage
    },

    getReturnGoods: state => {
      return state.returnGoods
    },

    getReturnGoodsLoading: state => {
      return state.returnGoodsLoading
    },


    getNextReturnGoodsPage: state => {
      return state.nextReturnGoodsPage
    },

    getReturnGoodsItem: state => {
      return state.returnGoodsItem
    },

    getReturnGoodsCommonList: state => {
      return state.returnGoodsCommonList
    },

    getReturnGoodsBtn: state => {
      return state.returnGoodsBtn
    },
  },

  actions: {

    async fetchReturnGoods({commit, getters}, filter = '') {
      if(!getters.getNextReturnGoodsPage)
        commit('setReturnGoodsLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setReturnGoods',{returnGoods: response.data.data.data})
          commit('setReturnGoodsCommonList',{returnGoodsCommonList: response.data.data})
          commit('setReturnGoodsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setReturnGoodsLoadingEnd');
        return e;
      }
    },

    async getReturnGoods({commit}, id) {
      commit('setReturnGoodsLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setReturnGoodsItem', {returnGoodsItem: response.data.data})
          commit('setReturnGoodsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setReturnGoodsLoadingEnd');
        return e;
      }
    },

    async createReturnGoods({commit}, data) {
      commit('changeReturnGoodsBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successReturnGoodsBtn');
          return response;
        });
      } catch (e) {
        commit('successReturnGoodsBtn');
        return e;
      }
    },


    async updateReturnGoods({commit}, {id, data}) {
      commit('changeReturnGoodsBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successReturnGoodsBtn');
          return response;
        });
      } catch (e) {
        commit('successReturnGoodsBtn');
        return e;
      }
    },

    async deleteReturnGoods({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successReturnGoodsBtn');
        return e;
      }
    },

    async addReturnGoodsFile({commit}, data) {
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${prefix}/upload-file`, data).then((response) =>{
          commit('successPayPalCasesBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successPayPalCasesBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },


    async attachUserReturnGoods({commit}, data) {
      commit('changeReturnGoodsBtn');
      try {
        return await API.patch(`${prefix}/attach-user`, data).then((response) =>{
          commit('successReturnGoodsBtn');
          return response;
        });
      } catch (e) {
        commit('successReturnGoodsBtn');
        return e;
      }
    },

    async toTrashReturnGoods({commit}, {id, data}) {
      commit('changeReturnGoodsBtn');
      try {
        return await API.patch(`${prefix}/trash/${id}`, data).then((response) =>{
          commit('successReturnGoodsBtn');
          return response;
        });
      } catch (e) {
        commit('successReturnGoodsBtn');
        return e;
      }
    },

  }
}
