import {API} from "../../axios/axiosMainUrl";

const prefix = '/order-notifications'

export const orderNotification = {
  state: {
    orderNotification: [],
    orderNotificationItem: {},
    orderNotificationCommonList: [],
    orderNotificationBtn: false,
    orderNotificationLoading: true,
    nextOrderNotificationPage: false,
    orderNotificationForPage: 25,
    orderNotificationFilter: '',
  },

  mutations: {
    setOrderNotificationFilter: (state, payload) => {
      state.orderNotificationFilter = payload
    },

    setOrderNotification: (state, payload) => {
      if(state.nextOrderNotificationPage) {
        state.orderNotification = state.orderNotification.concat(payload.orderNotification)
      } else {
        state.orderNotification = payload.orderNotification
      }
    },

    setOrderNotificationCommonList: (state, payload) => {
      state.orderNotificationCommonList = payload.orderNotificationCommonList
    },

    setNextOrderNotificationPage: (state, payload) => {
      state.nextOrderNotificationPage = payload
    },

    setOrderNotificationItem: (state, payload) => {
      state.orderNotificationItem = payload.orderNotificationItem
    },

    setOrderNotificationLoadingStart: (state) => {
      state.orderNotificationLoading = true
    },

    setOrderNotificationLoadingEnd: (state) => {
      state.orderNotificationLoading = false
    },

    changeOrderNotificationBtn: (state) => {
      state.orderNotificationBtn = true
    },
    successOrderNotificationBtn: (state) => {
      state.orderNotificationBtn = false
    },
  },

  getters: {
    getOrderNotificationFilter: state => {
      return state.orderNotificationFilter
    },

    getOrderNotificationForPage: state => {
      return state.orderNotificationForPage
    },

    getOrderNotification: state => {
      return state.orderNotification
    },

    getOrderNotificationLoading: state => {
      return state.orderNotificationLoading
    },


    getNextOrderNotificationPage: state => {
      return state.nextOrderNotificationPage
    },

    getOrderNotificationItem: state => {
      return state.orderNotificationItem
    },

    getOrderNotificationCommonList: state => {
      return state.orderNotificationCommonList
    },

    getOrderNotificationBtn: state => {
      return state.orderNotificationBtn
    },
  },

  actions: {

    async fetchOrderNotification({commit, getters}, filter = '') {
      if(!getters.getNextOrderNotificationPage)
        commit('setOrderNotificationLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setOrderNotification',{orderNotification: response.data.data.data})
          commit('setOrderNotificationCommonList',{orderNotificationCommonList: response.data.data})
          commit('setOrderNotificationLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrderNotificationLoadingEnd');
        return e;
      }
    },

    async getOrderNotification({commit}, id) {
      commit('setOrderNotificationLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setOrderNotificationItem', {orderNotificationItem: response.data.data})
          commit('setOrderNotificationLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrderNotificationLoadingEnd');
        return e;
      }
    },

    async createOrderNotification({commit}, data) {
      commit('changeOrderNotificationBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successOrderNotificationBtn');
          return response;
        });
      } catch (e) {
        commit('successOrderNotificationBtn');
        return e;
      }
    },

    async updateOrderNotification({commit}, {id, data}) {
      commit('changeOrderNotificationBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successOrderNotificationBtn');
          return response;
        });
      } catch (e) {
        commit('successOrderNotificationBtn');
        return e;
      }
    },

    async deleteOrderNotification({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successOrderNotificationBtn');
        return e;
      }
    },

  }
}
