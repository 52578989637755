
import {routerPaths} from '../../../router/routerPaths'
import permissions from "@/router/middleware/permissions";
import {PERMISSIONS} from "@/staticData/permissionsStatic";

const UsersTablePage = () => import(/* webpackChunkName: "group-access" */ './components/Users/UsersTablePage/UsersTablePage.vue')
const UsersCardPage = () => import(/* webpackChunkName: "group-access" */ './components/Users/UsersCardPage/UsersCardPage.vue')

const RolesTablePage = () => import(/* webpackChunkName: "group-access" */ './components/Roles/RolesTablePage/RolesTablePage.vue')
const RolesCardPage = () => import(/* webpackChunkName: "group-access" */ './components/Roles/RolesCardPage/RolesCardPage.vue')
const RolesCardCreate = () => import(/* webpackChunkName: "group-access" */ './components/Roles/RolesCardCreate/RolesCardCreate.vue')

const PermissionsTablePage = () => import(/* webpackChunkName: "group-access" */ './components/Permissions/PermissionsTablePage/PermissionsTablePage.vue')
const PermissionsCardPage = () => import(/* webpackChunkName: "group-access" */ './components/Permissions/PermissionsCardPage/PermissionsCardPage.vue')
const PermissionsCardCreate = () => import(/* webpackChunkName: "group-access" */ './components/Permissions/PermissionsCardCreate/PermissionsCardCreate.vue')

const PermissionsGroupTablePage = () => import(/* webpackChunkName: "group-access" */ './components/PermissionsGroup/PermissionsGroupTablePage/PermissionsGroupTablePage.vue')
const PermissionsGroupCardPage = () => import(/* webpackChunkName: "group-access" */ './components/PermissionsGroup/PermissionsGroupCardPage/PermissionsGroupCardPage.vue')
const PermissionsGroupCardCreate = () => import(/* webpackChunkName: "group-access" */ './components/PermissionsGroup/PermissionsGroupCardCreate/PermissionsGroupCardCreate.vue')

const TranslationsGroupTablePage = () => import(/* webpackChunkName: "group-access" */ './components/TranslationsGroup/TranslationsGroupTablePage/TranslationsGroupTablePage.vue')
const TranslationsGroupCardPage = () => import(/* webpackChunkName: "group-access" */ './components/TranslationsGroup/TranslationsGroupCardPage/TranslationsGroupCardPage.vue')
const TranslationsGroupCardCreate = () => import(/* webpackChunkName: "group-access" */ './components/TranslationsGroup/TranslationsGroupCardCreate/TranslationsGroupCardCreate.vue')

const TranslationsCardCreate = () => import(/* webpackChunkName: "group-access" */ './components/Translations/TranslationsCardCreate/TranslationsCardCreate.vue')
const TranslationsCardPage = () => import(/* webpackChunkName: "group-access" */ './components/Translations/TranslationsCardPage/TranslationsCardPage.vue')
const TranslationsTablePage = () => import(/* webpackChunkName: "group-access" */ './components/Translations/TranslationsTablePage/TranslationsTablePage.vue')

const VueComponentsTablePage = () => import(/* webpackChunkName: "group-access" */ './components/VueComponents/VueComponentsTablePage/VueComponentsTablePage.vue')
const VueComponentsCardPage = () => import(/* webpackChunkName: "group-access" */ './components/VueComponents/VueComponentsCardPage/VueComponentsCardPage.vue')
const VueComponentsCreate = () => import(/* webpackChunkName: "group-access" */ './components/VueComponents/VueComponentsCreate/VueComponentsCreate.vue')

const AdminAuth = () => import(/* webpackChunkName: "group-access" */ './components/AdminAuth/AdminAuth.vue')

const moduleKey = 'settings'

export const SettingsRoutes = [
  {
    path: routerPaths.settingsAdminAuth,
    component: AdminAuth,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'usersSettings'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.settings,
          },
          'usersSettings': {
            name: 'menu_systemAdminAuth',
            link: routerPaths.settingsAdminAuth,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.TRANSLATION_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_systemAdminAuth'
    },
  },


  {
    path: routerPaths.settingsUsers,
    component: UsersTablePage,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'usersSettings'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.settings,
          },
          'usersSettings': {
            name: 'menu_users',
            link: routerPaths.settingsUsers,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'menu_users'
    },
  },
  {
    path: routerPaths.settingsUsersItem,
    component: UsersCardPage,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'usersSettings',
          'usersItemSettings',
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.settings,
          },
          'usersSettings': {
            name: 'menu_users',
            link: routerPaths.settingsUsers,
          },
          'usersItemSettings': {
            name: 'User Detail',
            link: routerPaths.settingsUsersItem,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'User Detail'
    },
  },
  {
    path: routerPaths.settingsRoles,
    component: RolesTablePage,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'rolesSettings'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.settings,
          },
          'rolesSettings': {
            name: 'menu_roles',
            link: routerPaths.settingsRoles,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'menu_roles'
    },
  },
  {
    path: routerPaths.settingsRolesItem,
    component: RolesCardPage,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'rolesSettings'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.settings,
          },
          'rolesSettings': {
            name: 'menu_roles',
            link: routerPaths.settingsRoles,
          },
          'rolesItemSettings': {
            name: 'menu_roles',
            link: routerPaths.settingsRolesItem,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'menu_roles'
    },
  },
  {
    path: routerPaths.settingsRolesCreate,
    component: RolesCardCreate,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'permissionsGroup',
          'permissionsGroupCreate',
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.settings,
          },
          'permissionsGroup': {
            name: 'menu_roles',
            link: routerPaths.settingsRoles,
          },
          'permissionsGroupCreate': {
            name: 'breadcrumbs_CreateRoles',
            link: routerPaths.settingsRolesCreate,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_CreateRoles'
    },
  },



  {
    path: routerPaths.settingsPermissionsGroup,
    component: PermissionsGroupTablePage,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'permissionsGroup'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.settings,
          },
          'permissionsGroup': {
            name: 'menu_permissionsGroup',
            link: routerPaths.settingsPermissionsGroup,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'menu_permissionsGroup'
    },
  },

  {
    path: routerPaths.settingsPermissionsGroupItem,
    component: PermissionsGroupCardPage,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'permissionsGroup',
          'permissionsGroupItem',
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.settings,
          },
          'permissionsGroup': {
            name: 'menu_permissionsGroup',
            link: routerPaths.settingsPermissionsGroup,
          },
          'permissionsGroupItem': {
            name: 'menu_permissionsGroup',
            link: routerPaths.settingsPermissionsGroupItem,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'menu_permissionsGroup'
    },
  },

  {
    path: routerPaths.settingsPermissionsGroupCreate,
    component: PermissionsGroupCardCreate,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'permissionsGroup',
          'permissionsGroupCreate',
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.settings,
          },
          'permissionsGroup': {
            name: 'menu_permissionsGroup',
            link: routerPaths.settingsPermissionsGroup,
          },
          'permissionsGroupCreate': {
            name: 'breadcrumbs_CreatePermissionsGroup',
            link: routerPaths.settingsPermissionsGroupCreate,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_CreatePermissionsGroup'
    },
  },





  {
    path: routerPaths.settingsTranslationsGroup,
    component: TranslationsGroupTablePage,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'translationsGroup'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.settings,
          },
          'translationsGroup': {
            name: 'menu_translationsGroups',
            link: routerPaths.settingsTranslationsGroup,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'menu_translationsGroups'
    },
  },

  {
    path: routerPaths.settingsTranslationsGroupItem,
    component: TranslationsGroupCardPage,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'translationsGroup',
          'translationsGroupItem',
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.settings,
          },
          'translationsGroup': {
            name: 'menu_translationsGroups',
            link: routerPaths.settingsTranslationsGroup,
          },
          'translationsGroupItem': {
            name: 'menu_translationsGroups',
            link: routerPaths.settingsTranslationsGroupItem,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'menu_translationsGroups'
    },
  },

  {
    path: routerPaths.settingsTranslationsGroupCreate,
    component: TranslationsGroupCardCreate,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'translationsGroup',
          'translationsGroupCreate',
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.settings,
          },
          'translationsGroup': {
            name: 'menu_translationsGroups',
            link: routerPaths.settingsTranslationsGroup,
          },
          'translationsGroupCreate': {
            name: 'breadcrumbs_CreateTranslationsGroup',
            link: routerPaths.settingsTranslationsGroupCreate,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_CreateTranslationsGroup'
    },
  },





  {
    path: routerPaths.settingsTranslations,
    component: TranslationsTablePage,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'translations'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.settings,
          },
          'translations': {
            name: 'menu_translations',
            link: routerPaths.settingsTranslations,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.TRANSLATION_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_translations'
    },
  },

  {
    path: routerPaths.settingsTranslationsItem,
    component: TranslationsCardPage,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'translations',
          'translationsItem',
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.settings,
          },
          'translations': {
            name: 'menu_translations',
            link: routerPaths.settingsTranslations,
          },
          'translationsItem': {
            name: 'menu_translations',
            link: routerPaths.settingsTranslationsItem,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'menu_translations'
    },
  },

  {
    path: routerPaths.settingsTranslationsCreate,
    component: TranslationsCardCreate,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'translations',
          'translationsCreate',
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.settings,
          },
          'translations': {
            name: 'menu_translations',
            link: routerPaths.settingsTranslations,
          },
          'translationsCreate': {
            name: 'breadcrumbs_CreateTranslation',
            link: routerPaths.settingsTranslationsCreate,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_CreateTranslation'
    },
  },




  {
    path: routerPaths.settingsPermissions,
    component: PermissionsTablePage,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'permissionsSettings'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.settings,
          },
          'permissionsSettings': {
            name: 'menu_permissions',
            link: routerPaths.settingsPermissions,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'menu_permissions'
    },
  },

  {
    path: routerPaths.settingsPermissionsItem,
    component: PermissionsCardPage,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'permissionsSettings',
          'permissionsSettingsItem',
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.settings,
          },
          'permissionsSettings': {
            name: 'menu_permissions',
            link: routerPaths.settingsPermissions,
          },
          'permissionsSettingsItem': {
            name: 'menu_permissions',
            link: routerPaths.settingsPermissionsItem,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'menu_permissions'
    },
  },

  {
    path: routerPaths.settingsPermissionsCreate,
    component: PermissionsCardCreate,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'permissionsSettings',
          'permissionsSettingsCreate'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.settings,
          },
          'permissionsSettings': {
            name: 'menu_permissions',
            link: routerPaths.settingsPermissions,
          },
          'permissionsSettingsCreate': {
            name: 'breadcrumbs_CreatePermission',
            link: routerPaths.settingsPermissionsCreate,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_CreatePermission'
    },
  },







  {
    path: routerPaths.settingsVueComponents,
    component: VueComponentsTablePage,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'vueComponents'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.settings,
          },
          'vueComponents': {
            name: 'menu_vueComponents',
            link: routerPaths.settingsVueComponents,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'menu_vueComponents'
    },
  },

  {
    path: routerPaths.settingsVueComponentsItem,
    component: VueComponentsCardPage,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'vueComponents',
          'permissionsSettingsItem',
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.settings,
          },
          'vueComponents': {
            name: 'menu_vueComponents',
            link: routerPaths.settingsVueComponents,
          },
          'permissionsSettingsItem': {
            name: 'menu_vueComponents',
            link: routerPaths.settingsVueComponentsItem,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'menu_vueComponents'
    },
  },

  {
    path: routerPaths.settingsVueComponentsCreate,
    component: VueComponentsCreate,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'vueComponents',
          'permissionsSettingsCreate'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.settings,
          },
          'vueComponents': {
            name: 'menu_vueComponents',
            link: routerPaths.settingsVueComponents,
          },
          'permissionsSettingsCreate': {
            name: 'breadcrumbs_CreateVueComponents',
            link: routerPaths.settingsVueComponentsCreate,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_CreateVueComponents'
    },
  },

]
