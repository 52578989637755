<template>
  <modal
      @close="$emit('close')"
      class="custom-popup guide-edit-popup"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'header_readGuide',
          ])"></div>
        {{ $t('header_readGuide.localization_value.value') }}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">

        <div class="custom-row">

          <div class="custom-col custom-col-md--100">
            <template v-if="$store.getters.GET_LANG === EN_LANG">
              <div v-html="guideItem.data.textEn"></div>
            </template>
            <template v-if="$store.getters.GET_LANG === RU_LANG">
              <div v-html="guideItem.data.textRu"></div>
            </template>
            <template v-if="$store.getters.GET_LANG === UA_LANG">
              <div v-html="guideItem.data.textUa"></div>
            </template>
          </div>


        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex flex-wrap mt-5">
        <span
        class="site-link site-link--alt custom-popup__btn-i mr-4 mt-3 mb-3"
        @click="$emit('close')"
        >
        {{$t('common_cancel.localization_value.value')}}
        </span>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "../../../commonModals/Modal";
import {EN_LANG, RU_LANG, UA_LANG} from "../../../../staticData/staticVariables";

export default {
  name: "ReadGuideViewPopup",
  components: {
    Modal,
  },

  props: {
    guideItem: Object,
    edit: Boolean,
  },

  data() {
    return {
      EN_LANG: EN_LANG,
      RU_LANG: RU_LANG,
      UA_LANG: UA_LANG,
    }
  },

  methods: {

  }
}
</script>

<style>

</style>