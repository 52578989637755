import {API} from "../../axios/axiosMainUrl";

const prefixDelivery = '/delivery'

export const delivery = {
  state: {
    delivery: [],
    deliveryItem: {},
    deliveryCommonList: [],
    deliveryBtn: false,
    deliveryLoading: true,
    nextDeliveryPage: false,
    deliveryForPage: 25,
    deliveryFilter: '',


    packagingTypes: [],

    deliveryTypeByCountry: {},
  },

  mutations: {
    setDeliveryFilter: (state, payload) => {
      state.deliveryFilter = payload
    },

    setDelivery: (state, payload) => {
      if(state.nextDeliveryPage) {
        state.delivery = state.delivery.concat(payload.delivery)
      } else {
        state.delivery = payload.delivery
      }
    },

    setDeliveryCommonList: (state, payload) => {
      state.deliveryCommonList = payload.deliveryCommonList
    },

    setNextDeliveryPage: (state, payload) => {
      state.nextDeliveryPage = payload
    },

    setDeliveryItem: (state, payload) => {
      state.deliveryItem = payload.deliveryItem
    },

    setDeliveryLoadingStart: (state) => {
      state.deliveryLoading = true
    },

    setDeliveryLoadingEnd: (state) => {
      state.deliveryLoading = false
    },

    changeDeliveryBtn: (state) => {
      state.deliveryBtn = true
    },

    successDeliveryBtn: (state) => {
      state.deliveryBtn = false
    },

    setPackagingTypes: (state, payload) => {
      state.packagingTypes = payload.packagingTypes
    },

    setDeliveryTypeByCountry: (state, payload) => {
      state.deliveryTypeByCountry = payload.deliveryTypeByCountry
    },

  },

  getters: {
    getDeliveryFilter: state => {
      return state.deliveryFilter
    },

    getDeliveryForPage: state => {
      return state.deliveryForPage
    },

    getDelivery: state => {
      return state.delivery
    },

    getDeliveryLoading: state => {
      return state.deliveryLoading
    },


    getNextDeliveryPage: state => {
      return state.nextDeliveryPage
    },

    getDeliveryItem: state => {
      return state.deliveryItem
    },

    getDeliveryCommonList: state => {
      return state.deliveryCommonList
    },

    getDeliveryBtn: state => {
      return state.deliveryBtn
    },

    getPackagingTypes: (state) => {
      return state.packagingTypes
    },

    getDeliveryTypeByCountry: (state) => {
      return state.deliveryTypeByCountry
    },
  },

  actions: {


    async getDeliveryPackingType({commit}, filter = '') {
      try {
        return await API.get(`${prefixDelivery}/packing-type${filter}`).then((response) =>{
          commit('setPackagingTypes',{packagingTypes: response.data.data.data})
          // commit('setDeliveryLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setDeliveryLoadingEnd');
        return e;
      }
    },

    async getDeliveryPackingTypeForAdmin({commit}, filter = '') {
      try {
        return await API.get(`${prefixDelivery}/packing-type/for-admin${filter}`).then((response) =>{
          commit('setPackagingTypes',{packagingTypes: response.data.data.data})
          // commit('setDeliveryLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setDeliveryLoadingEnd');
        return e;
      }
    },

    async getDeliveryPackingTypeGiftForAdmin({commit}, filter = '') {
      try {
        return await API.get(`${prefixDelivery}/packing-type/gift/for-admin${filter}`).then((response) =>{
          commit('setPackagingTypes',{packagingTypes: response.data.data.data})
          // commit('setDeliveryLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setDeliveryLoadingEnd');
        return e;
      }
    },

    async getDeliveryPackingTypeForUser({commit}, filter = '') {
      try {
        return await API.get(`${prefixDelivery}/packing-type${filter}`).then((response) =>{
          commit('setPackagingTypes',{packagingTypes: response.data.data.data})
          // commit('setDeliveryLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setDeliveryLoadingEnd');
        return e;
      }
    },

    async getDeliveryPackingTypeGiftForUser({commit}, filter = '') {
      try {
        return await API.get(`${prefixDelivery}/packing-type/gift${filter}`).then((response) =>{
          commit('setPackagingTypes',{packagingTypes: response.data.data.data})
          // commit('setDeliveryLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setDeliveryLoadingEnd');
        return e;
      }
    },


    async getDeliveryTypeByCountry({commit}, id) {
      try {
        return await API.get(`${prefixDelivery}/type-by-country/${id}`).then((response) =>{
          commit('setDeliveryTypeByCountry',{deliveryTypeByCountry: response.data.data})
          return response;
        });
      } catch (e) {
        commit('setDeliveryLoadingEnd');
        return e;
      }
    },

    async getGifPapers({commit}) {
      try {
        return await API.get(`${prefixDelivery}/packing-type/gift-papers`).then((response) =>{
          // commit('setDeliveryTypeByCountry',{deliveryTypeByCountry: response.data.data})
          return response;
        });
      } catch (e) {
        commit('setDeliveryLoadingEnd');
        return e;
      }
    },


    async getSignaturePrice({commit}) {
      try {
        return await API.get(`${prefixDelivery}/required-signature`).then((response) =>{
          // commit('setDeliveryTypeByCountry',{deliveryTypeByCountry: response.data.data})
          return response;
        });
      } catch (e) {
        commit('setDeliveryLoadingEnd');
        return e;
      }
    },




    async fetchDelivery({commit, getters}, filter = '') {
      if(!getters.getNextDeliveryPage)
        commit('setDeliveryLoadingStart')
      try {
        return await API.get(`/delivery${filter}`).then((response) =>{
          commit('setDelivery',{delivery: response.data.data.data})
          commit('setDeliveryCommonList',{deliveryCommonList: response.data.data})
          commit('setDeliveryLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setDeliveryLoadingEnd');
        return e;
      }
    },

    async getDelivery({commit}, id) {
      commit('setDeliveryLoadingStart');
      try {
        return await API.get(`/delivery/${id}`).then((response) =>{
          console.log(response)
          commit('setDeliveryItem', {deliveryItem: response.data.data})
          commit('setDeliveryLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setDeliveryLoadingEnd');
        return e;
      }
    },

    async createDelivery({commit}, data) {
      commit('changeDeliveryBtn');
      try {
        return await API.post(`/delivery`, data).then((response) =>{
          commit('successDeliveryBtn');
          return response;
        });
      } catch (e) {
        commit('successDeliveryBtn');
        return e;
      }
    },

    async updateDelivery({commit}, {id, data}) {
      commit('changeDeliveryBtn');
      try {
        return await API.put(`/delivery/${id}`, data).then((response) =>{
          commit('successDeliveryBtn');
          return response;
        });
      } catch (e) {
        commit('successDeliveryBtn');
        return e;
      }
    },

    async deleteDelivery({commit}, id) {
      try {
        return await API.delete(`/delivery/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successDeliveryBtn');
        return e;
      }
    },



    async getPackagingTypeBarcode({commit}, filter = '') {
      try {
        return await API.get(`${prefixDelivery}/packing-price/print-list${filter}`).then((response) =>{
          return response
        })
      } catch (e) {
        commit('successOrderSizeBtn')
        return e;
      }
    },


    async getPackingTypePrint({commit}, id) {
      try {
        return await API.get(`${prefixDelivery}/packing-price/print/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successOrderSizeBtn');
        return e;
      }
    },

  }
}
