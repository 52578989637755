import {validation} from "../../../services/validation";

export function checkValid(validationItems, validationOptions) {
  let validate = validation(validationItems, validationOptions)

  Object.keys(validationItems).map(item => {
    this.validation[item] = validate.validation[item] ? validate.validation[item] : false
    this.validationTranslate[item] = validate.validationError[item] ? validate.validationError[item] : ''
  })

  return validate.isValidate
}

export function phoneReplace(val) {
  return val ? val.replaceAll('-', '') : ''
}
