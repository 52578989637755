
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const NovaPoshtaTable = () => import(/* webpackChunkName: "group-novaPoshta" */ './components/NovaPoshtaTable/NovaPoshtaTable.vue')
const NovaPoshtaCreation = () => import(/* webpackChunkName: "group-novaPoshta" */ './components/NovaPoshtaCreation/NovaPoshtaCreation.vue')
const NovaPoshtaEditing = () => import(/* webpackChunkName: "group-novaPoshta" */ './components/NovaPoshtaEditing/NovaPoshtaEditing.vue')
const NovaPoshtaShow = () => import(/* webpackChunkName: "group-novaPoshta" */ './components/NovaPoshtaShow/NovaPoshtaShow.vue')
const moduleKey = 'nova-poshta'


export const NovaPoshtaRoutes = [
  {
    path: routerPaths.novaPoshta,
    component: NovaPoshtaTable,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'novaPoshta'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'novaPoshta': {
            name: 'menu_novaPoshta',
            link: routerPaths.novaPoshta,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_INTERNAL_NOVAPOSHTA],

      moduleKey: moduleKey,
      pageTitle: 'menu_novaPoshta'
    },
  },

  {
    path: routerPaths.novaPoshtaCreationFromOrder,
    component: NovaPoshtaCreation,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'novaPoshta',
          'novaPoshtaCreation',
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'novaPoshta': {
            name: 'menu_novaPoshta',
            link: routerPaths.novaPoshta,
          },
          'novaPoshtaCreation': {
            name: 'breadcrumbs_NewOrderNovaPoshta',
            link: routerPaths.novaPoshtaCreation,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_INTERNAL_NOVAPOSHTA],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewOrderNovaPoshta'
    },
  },

  {
    path: routerPaths.novaPoshtaCreation,
    component: NovaPoshtaCreation,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'novaPoshta',
          'novaPoshtaCreation',
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'novaPoshta': {
            name: 'menu_novaPoshta',
            link: routerPaths.novaPoshta,
          },
          'novaPoshtaCreation': {
            name: 'breadcrumbs_NewOrderNovaPoshta',
            link: routerPaths.novaPoshtaCreation,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_INTERNAL_NOVAPOSHTA],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewOrderNovaPoshta'
    },
  },



  {
    path: routerPaths.novaPoshtaEditing,
    component: NovaPoshtaEditing,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'novaPoshta',
          'novaPoshtaCreation',
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'novaPoshta': {
            name: 'menu_novaPoshta',
            link: routerPaths.novaPoshta,
          },
          'novaPoshtaCreation': {
            name: 'breadcrumbs_OrderNovaPoshta',
            link: routerPaths.novaPoshtaEditing,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_INTERNAL_NOVAPOSHTA],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_OrderNovaPoshta'
    },
  },

  {
    path: routerPaths.novaPoshtaShow,
    component: NovaPoshtaShow,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'novaPoshta',
          'novaPoshtaCreation',
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'novaPoshta': {
            name: 'menu_novaPoshta',
            link: routerPaths.novaPoshta,
          },
          'novaPoshtaCreation': {
            name: 'breadcrumbs_OrderNovaPoshta',
            link: routerPaths.novaPoshtaEditing,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_INTERNAL_NOVAPOSHTA],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_OrderNovaPoshta'
    },
  },


]
