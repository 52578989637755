import {API} from "../../axios/axiosMainUrl";

const treasures = 'products/listing'
const apiEtsy = 'api-etsy-listing'

export const ukrTreasures = {
  state: {
    ukrTreasures: [],
    ukrTreasuresItem: {},
    ukrTreasuresCommonList: [],
    ukrTreasuresBtn: false,
    ukrTreasuresLoading: true,
    nextUkrTreasuresPage: false,
    ukrTreasuresForPage: 25,
    ukrTreasuresFilter: '',
  },

  mutations: {
    setUkrTreasuresFilter: (state, payload) => {
      state.ukrTreasuresFilter = payload
    },

    setUkrTreasures: (state, payload) => {
      if(state.nextUkrTreasuresPage) {
        state.ukrTreasures = state.ukrTreasures.concat(payload.ukrTreasures)
      } else {
        state.ukrTreasures = payload.ukrTreasures
      }
    },

    setUkrTreasuresCommonList: (state, payload) => {
      state.ukrTreasuresCommonList = payload.ukrTreasuresCommonList
    },

    setNextUkrTreasuresPage: (state, payload) => {
      state.nextUkrTreasuresPage = payload
    },

    setUkrTreasuresItem: (state, payload) => {
      state.ukrTreasuresItem = payload.ukrTreasuresItem
    },

    setUkrTreasuresLoadingStart: (state) => {
      state.ukrTreasuresLoading = true
    },

    setUkrTreasuresLoadingEnd: (state) => {
      state.ukrTreasuresLoading = false
    },

    changeUkrTreasuresBtn: (state) => {
      state.ukrTreasuresBtn = true
    },
    successUkrTreasuresBtn: (state) => {
      state.ukrTreasuresBtn = false
    },
  },

  getters: {
    getUkrTreasuresFilter: state => {
      return state.ukrTreasuresFilter
    },

    getUkrTreasuresForPage: state => {
      return state.ukrTreasuresForPage
    },

    getUkrTreasures: state => {
      return state.ukrTreasures
    },

    getUkrTreasuresLoading: state => {
      return state.ukrTreasuresLoading
    },


    getNextUkrTreasuresPage: state => {
      return state.nextUkrTreasuresPage
    },

    getUkrTreasuresItem: state => {
      return state.ukrTreasuresItem
    },

    getUkrTreasuresCommonList: state => {
      return state.ukrTreasuresCommonList
    },

    getUkrTreasuresBtn: state => {
      return state.ukrTreasuresBtn
    },
  },

  actions: {

    async fetchUkrTreasures({commit, getters}, filter = '') {
      if(!getters.getNextUkrTreasuresPage)
        commit('setUkrTreasuresLoadingStart')
      try {
        return await API.get(`${treasures}${filter}`).then((response) =>{
          commit('setUkrTreasures',{ukrTreasures: response.data.data.data})
          commit('setUkrTreasuresCommonList',{ukrTreasuresCommonList: response.data.data})
          commit('setUkrTreasuresLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setUkrTreasuresLoadingEnd');
        return e;
      }
    },

    async getUkrTreasuresEdit({commit}, id) {
      commit('setUkrTreasuresLoadingStart');
      try {
        return await API.get(`${treasures}/${id}/edit`).then((response) =>{
          console.log(response)
          commit('setUkrTreasuresItem', {ukrTreasuresItem: response.data.data})
          commit('setUkrTreasuresLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setUkrTreasuresLoadingEnd');
        return e;
      }
    },

    async getUkrTreasures({commit}, id) {
      commit('setUkrTreasuresLoadingStart');
      try {
        return await API.get(`${treasures}/${id}`).then((response) =>{
          console.log(response)
          commit('setUkrTreasuresItem', {ukrTreasuresItem: response.data.data})
          commit('setUkrTreasuresLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setUkrTreasuresLoadingEnd');
        return e;
      }
    },

    async createUkrTreasures({commit}, data) {
      commit('changeUkrTreasuresBtn');
      try {
        return await API.post(`${treasures}`, data).then((response) =>{
          commit('successUkrTreasuresBtn');
          return response;
        });
      } catch (e) {
        commit('successUkrTreasuresBtn');
        return e;
      }
    },

    async addPhotoToUkrTreasures({commit}, {data, id}) {
      commit('changeUkrTreasuresBtn');
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${treasures}/${id}/upload-files`, data).then((response) =>{
          commit('successUkrTreasuresBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successUkrTreasuresBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async updateUkrTreasures({commit}, {id, data}) {
      commit('changeUkrTreasuresBtn');
      try {
        return await API.put(`${treasures}/${id}`, data).then((response) =>{
          commit('successUkrTreasuresBtn');
          return response;
        });
      } catch (e) {
        commit('successUkrTreasuresBtn');
        return e;
      }
    },

    async deleteUkrTreasures({commit}, id) {
      try {
        return await API.delete(`${treasures}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successUkrTreasuresBtn');
        return e;
      }
    },

    async getEtsyListings({commit}, data) {
      commit('changeUkrTreasuresBtn');
      try {
        return await API.post(`${apiEtsy}/get-listings-by-receipt`, data).then((response) =>{
          commit('successUkrTreasuresBtn');
          return response;
        });
      } catch (e) {
        commit('successUkrTreasuresBtn');
        return e;
      }
    },

    async cloneUkrTreasuresListing({commit}, id) {
      commit('changeUkrTreasuresBtn');
      try {
        return await API.post(`${treasures}/${id}/clone-listing`).then((response) =>{
          commit('successUkrTreasuresBtn');
          return response;
        });
      } catch (e) {
        commit('successUkrTreasuresBtn');
        return e;
      }
    },

  }
}
