
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const UkrposhtaTable = () => import(/* webpackChunkName: "group-ukrposhta" */ './components/UkrposhtaTable/UkrposhtaTable.vue')
const UkrposhtaCreation = () => import(/* webpackChunkName: "group-ukrposhta" */ './components/UkrposhtaCreation/UkrposhtaCreation.vue')
const UkrposhtaEditing = () => import(/* webpackChunkName: "group-ukrposhta" */ './components/UkrposhtaEditing/UkrposhtaEditing.vue')
const UkrposhtaShow = () => import(/* webpackChunkName: "group-ukrposhta" */ './components/UkrposhtaShow/UkrposhtaShow.vue')
const moduleKey = 'ukrposhta'


export const UkrposhtaRoutes = [
  {
    path: routerPaths.ukrposhta,
    component: UkrposhtaTable,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'ukrposhta'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'ukrposhta': {
            name: 'menu_ukrposhta',
            link: routerPaths.ukrposhta,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_INTERNAL_UKRPOSHTA],

      moduleKey: moduleKey,
      pageTitle: 'menu_ukrposhta'
    },
  },
  {
    path: routerPaths.ukrposhtaCreation,
    component: UkrposhtaCreation,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'ukrposhta',
          'ukrposhtaCreation',
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'ukrposhta': {
            name: 'menu_ukrposhta',
            link: routerPaths.ukrposhta,
          },
          'ukrposhtaCreation': {
            name: 'breadcrumbs_NewOrderUkrposhta',
            link: routerPaths.ukrposhtaCreation,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_INTERNAL_UKRPOSHTA],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewOrderUkrposhta'
    },
  },
  {
    path: routerPaths.ukrposhtaCreationPayments,
    component: UkrposhtaCreation,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'ukrposhta',
          'ukrposhtaCreation',
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'ukrposhta': {
            name: 'menu_ukrposhta',
            link: routerPaths.ukrposhta,
          },
          'ukrposhtaCreation': {
            name: 'breadcrumbs_NewOrderUkrposhta',
            link: routerPaths.ukrposhtaCreation,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_INTERNAL_UKRPOSHTA],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewOrderUkrposhta'
    },
  },

  {
    path: routerPaths.ukrposhtaEditing,
    component: UkrposhtaEditing,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'ukrposhta',
          'ukrposhtaCreation',
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'ukrposhta': {
            name: 'menu_ukrposhta',
            link: routerPaths.ukrposhta,
          },
          'ukrposhtaCreation': {
            name: 'breadcrumbs_OrderUkrposhta',
            link: routerPaths.ukrposhtaEditing,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_INTERNAL_UKRPOSHTA],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_OrderUkrposhta'
    },
  },

  {
    path: routerPaths.ukrposhtaShow,
    component: UkrposhtaShow,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'ukrposhta',
          'ukrposhtaCreation',
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'ukrposhta': {
            name: 'menu_ukrposhta',
            link: routerPaths.ukrposhta,
          },
          'ukrposhtaCreation': {
            name: 'breadcrumbs_OrderUkrposhta',
            link: routerPaths.ukrposhtaEditing,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_INTERNAL_UKRPOSHTA],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_OrderUkrposhta'
    },
  },
]
