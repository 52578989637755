
import {routerPaths} from '../../../router/routerPaths'
// import permissions from "../../../router/middleware/permissions";
// import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const UkrTreasuresTable = () => import(/* webpackChunkName: "group-ukr-treasures" */ './components/UkrTreasuresTable/UkrTreasuresTable.vue')
const UkrTreasuresCreate = () => import(/* webpackChunkName: "group-ukr-treasures" */ './components/UkrTreasuresCreate/UkrTreasuresCreate.vue')
// const UkrTreasuresEditing = () => import(/* webpackChunkName: "group-ukr-treasures" */ './components/UkrTreasuresEditing/UkrTreasuresEditing.vue')

const UkrTreasuresPayPalButtonCreate = () => import(/* webpackChunkName: "group-ukr-treasures-paypal-button-create" */ './components/UkrTreasuresPayPalButtonsCreate/UkrTreasuresPayPalButtonsCreate')
const moduleKey = 'ukrtreasures'

export const UkrTreasuresModuleRoutes = [
  {
    path: routerPaths.financeUkrTreasuresLink,
    component: UkrTreasuresTable,
    meta: {
      breadcrumbs: {
        key: [
          'finance',
          'financeUkrTreasuresLink'
        ],
        items: {
          'finance': {
            name: 'menu_finance',
            link: routerPaths.dashboard,
          },
          'financeUkrTreasuresLink': {
            name: 'breadcrumbs_UkrainianTreasures',
            link: routerPaths.financeUkrTreasuresLink,
          },
        },
      },

      // middleware: [permissions],
      // permissions: [PERMISSIONS.PAYMENT_LISTING_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_UkrainianTreasures'
    },
  },
  {
    path: routerPaths.financeUkrTreasuresLinkCreate,
    component: UkrTreasuresCreate,
    meta: {
      breadcrumbs: {
        key: [
          'finance',
          'financeUkrTreasuresLink',
          'financeUkrTreasuresLinkCreate',
        ],
        items: {
          'finance': {
            name: 'menu_finance',
            link: routerPaths.dashboard,
          },
          'financeUkrTreasuresLink': {
            name: 'breadcrumbs_UkrainianTreasures',
            link: routerPaths.financeUkrTreasuresLink,
          },
          'financeUkrTreasuresLinkCreate': {
            name: 'breadcrumbs_NewPaymentListings',
            link: routerPaths.financeUkrTreasuresLinkCreate,
          },
        },
      },

      // middleware: [permissions],
      // permissions: [PERMISSIONS.PAYMENT_LISTING_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewPaymentListings'
    },
  },
  // {
  //   path: routerPaths.financeUkrTreasuresLinkEdit,
  //   component: UkrTreasuresEditing,
  //   meta: {
  //     breadcrumbs: {
  //       key: [
  //         'finance',
  //         'financeUkrTreasuresLink',
  //         'financeUkrTreasuresLinkEdit',
  //       ],
  //       items: {
  //         'finance': {
  //           name: 'menu_finance',
  //           link: routerPaths.dashboard,
  //         },
  //         'financeUkrTreasuresLink': {
  //           name: 'breadcrumbs_UkrainianTreasures',
  //           link: routerPaths.financeUkrTreasuresLink,
  //         },
  //         'financeUkrTreasuresLinkEdit': {
  //           name: 'breadcrumbs_PaymentListingsEdit',
  //           link: routerPaths.financeUkrTreasuresLinkEdit,
  //         },
  //       },
  //     },
  //
  //     // middleware: [permissions],
  //     // permissions: [PERMISSIONS.PAYMENT_LISTING_GENERAL],
  //
  //     moduleKey: moduleKey,
  //     pageTitle: 'breadcrumbs_PaymentListingsEdit'
  //   },
  // },

  {
    path: routerPaths.financeUkrTreasuresPayPalButtonCreate,
    component: UkrTreasuresPayPalButtonCreate,
    meta: {
      breadcrumbs: {
        key: [
          'finance',
          'financeUkrTreasuresLink',
          'financeUkrTreasuresPayPalButtonCreate',
        ],
        items: {
          'finance': {
            name: 'menu_finance',
            link: routerPaths.dashboard,
          },
          'financeUkrTreasuresLink': {
            name: 'breadcrumbs_UkrainianTreasures',
            link: routerPaths.financeUkrTreasuresLink,
          },
          'financeUkrTreasuresPayPalButtonCreate': {
            name: 'breadcrumbs_NewPayPalButton',
            link: routerPaths.financeUkrTreasuresPayPalButtonCreate,
          },
        },
      },

      // middleware: [permissions],
      // permissions: [PERMISSIONS.PAYMENT_LISTING_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewPayPalButton'
    },
  },
]
