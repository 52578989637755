
import {API} from '../../../axios/axiosMainUrl'

export const vueComponents = {
  state: {
    vueComponents: [],
    vueComponentItem: {},
    loadingVueComponents: false,
    loadingVueComponentItem: false,
    vueComponentBtn: false,
  },

  mutations: {
    setVueComponents: (state, payload) => {
      state.vueComponents = payload.vueComponents
    },
    setVueComponentItem: (state, payload) => {
      state.vueComponentItem = payload.vueComponentItem
    },

    setLoadingStart: (state) => {
      state.loadingVueComponents = true
    },
    setLoadingVueComponentItemStart: (state) => {
      state.loadingVueComponents = true
    },

    setLoadingEnd: (state) => {
      state.loadingVueComponents = false
    },
    setLoadingVueComponentItemEnd: (state) => {
      state.loadingVueComponents = false
    },

    changeVueComponent: (state) => {
      state.vueComponentBtn = true
    },
    successVueComponent: (state) => {
      state.vueComponentBtn = false
    },
  },

  getters: {
    getVueComponents: state => {
      return state.vueComponents
    },

    getVueComponentItem: state => {
      return state.vueComponentItem
    },

    getVueComponentBtn: state => {
      return state.vueComponentBtn
    }
  },

  actions: {
    async fetchVueComponentItem({commit}, vueComponentId) {
      commit('setLoadingVueComponentItemStart');
      try {
        return await API.get(`access-controls/vue-components/${vueComponentId}`).then((response) =>{
          commit('setVueComponentItem', {vueComponentItem: response.data.data});
          commit('setLoadingVueComponentItemEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingVueComponentItemEnd');
        return e;
      }
    },

    async fetchVueComponents({commit}, filter='') {
      commit('setLoadingStart');
      try {
        return await API.get(`access-controls/vue-components${filter}`).then((response) =>{
          commit('setVueComponents', {vueComponents: response.data.data.data});
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async updateVueComponents({commit}, {id, data}) {
      commit('setLoadingStart');
      try {
        return await API.put(`access-controls/vue-components/${id}`, data).then((response) =>{
          commit('setVueComponents', {vueComponents: response.data.data});
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async createVueComponents({commit}, data) {
      commit('setLoadingStart');
      try {
        return await API.post('access-controls/vue-components', data).then((response) =>{
          // commit('setVueComponents', {vueComponents: response.data.vueComponents});
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },


    async deleteVueComponents({commit}, vueComponentId) {
      commit('setLoadingStart');
      try {
        return await API.delete(`access-controls/vue-components/${vueComponentId}`).then((response) =>{
          // commit('setVueComponents', {vueComponents: response.data.vueComponents});
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

  }
}
