
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

// const OrdersFBMTablePage = () => import(/* webpackChunkName: "group-FBM" */ './components/OrdersFBMTablePage/OrdersFBMTablePage.vue')
// const OrderFBMCreationPage = () => import(/* webpackChunkName: "group-FBM" */ './components/OrderFBMCreationPage/OrderFBMCreationPage.vue')
const OrdersFBMTable = () => import(/* webpackChunkName: "group-FBM" */ './components/OrdersFBMTable/OrdersFBMTable.vue')
const ManifestsTable = () => import(/* webpackChunkName: "group-FBM" */ './components/ManifestsTable/ManifestsTable.vue')
const OrdersFBMCreation = () => import(/* webpackChunkName: "group-FBM" */ './components/OrdersFBMCreation/OrdersFBMCreation.vue')

// const OrdersCreatLabel = () => import(/* webpackChunkName: "group-FBM" */ './components/OrdersFBMCreation/OrdersCreatLabel/OrdersCreatLabel.vue')
// const OrdersCreatWarehouse = () => import(/* webpackChunkName: "group-FBM" */ './components/OrdersFBMCreation/OrdersCreatWarehouse/OrdersCreatWarehouse.vue')
// const OrdersCreatConsolidation = () => import(/* webpackChunkName: "group-FBM" */ './components/OrdersFBMCreation/OrdersCreatConsolidation/OrdersCreatConsolidation.vue')


// const OrdersCreatEasy = () => import(/* webpackChunkName: "group-FBM" */ './components/OrdersFBMCreation/OrdersCreatEasy/OrdersCreatEasy.vue')

const OrdersFBMEditing = () => import(/* webpackChunkName: "group-FBM" */ './components/OrdersFBMEditing/OrdersFBMEditing.vue')

const OrdersFBMShow = () => import(/* webpackChunkName: "group-FBM" */ './components/OrdersFBMShow/OrdersFBMShow.vue')


// const OrdersEditingWarehouse = () => import(/* webpackChunkName: "group-FBM" */ './components/OrdersFBMEditing/OrdersEditingWarehouse/OrdersEditingWarehouse.vue')
// const OrdersEditingConsolidation = () => import(/* webpackChunkName: "group-FBM" */ './components/OrdersFBMEditing/OrdersEditingConsolidation/OrdersEditingConsolidation.vue')

// ordersFBM
// ordersFBMCreation
// ordersFBMItem

const moduleKey = 'orders-fbm'

export const OrdersFBMRoutes = [
  {
    path: routerPaths.ordersFBM,
    component: OrdersFBMTable,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'ordersFBM'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'ordersFBM': {
            name: 'menu_createOrders',
            link: routerPaths.ordersFBM,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_FBM_GENERAL],

      moduleKey: moduleKey,
      moduleKeyChapters: true,
      pageTitle: 'menu_createOrders'
    },
  },

  {
    path: routerPaths.ordersFBMCreation,
    component: OrdersFBMCreation,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'ordersFBM',
          'ordersFBMCreationConsolidation'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'ordersFBM': {
            name: 'menu_createOrders',
            link: routerPaths.ordersFBM,
          },
          'ordersFBMCreationConsolidation': {
            name: 'breadcrumbs_NewOrderFBMConsolidation',
            link: routerPaths.ordersFBMCreation,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_FBM_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewOrderFBMConsolidation'
    },
  },

  {
    path: routerPaths.ordersFBMCreationTransactionId,
    component: OrdersFBMCreation,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'ordersFBM',
          'ordersFBMCreationConsolidation'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'ordersFBM': {
            name: 'menu_createOrders',
            link: routerPaths.ordersFBM,
          },
          'ordersFBMCreationConsolidation': {
            name: 'breadcrumbs_NewOrderFBMConsolidation',
            link: routerPaths.ordersFBMCreation,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_FBM_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewOrderFBMConsolidation'
    },
  },


  // {
  //   path: routerPaths.ordersFBMEditingConsolidation,
  //   component: OrdersEditingConsolidation,
  //   meta: {
  //     breadcrumbs: {
  //       key: [
  //         'ordersAndDispatches',
  //         'ordersFBM',
  //         'ordersFBMCreationLabel'
  //       ],
  //       items: {
  //         'ordersAndDispatches': {
  //           name: 'menu_ordersDispatches',
  //           link: routerPaths.ordersAndDispatches,
  //         },
  //         'ordersFBM': {
  //           name: 'menu_createOrders',
  //           link: routerPaths.ordersFBM,
  //         },
  //         'ordersFBMCreationLabel': {
  //           name: 'breadcrumbs_EditOrderFBMLabel',
  //           link: routerPaths.ordersFBMEditing,
  //         },
  //       },
  //     },
  //
  //     middleware: [permissions],
  //     permissions: [PERMISSIONS.ORDER_FBM_GENERAL],
  //
  //     moduleKey: moduleKey,
  //     pageTitle: 'breadcrumbs_EditOrderFBMLabel'
  //   },
  // },
  // {
  //   path: routerPaths.ordersFBMEditingWarehouse,
  //   component: OrdersEditingWarehouse,
  //   meta: {
  //     breadcrumbs: {
  //       key: [
  //         'ordersAndDispatches',
  //         'ordersFBM',
  //         'ordersFBMCreationLabel'
  //       ],
  //       items: {
  //         'ordersAndDispatches': {
  //           name: 'menu_ordersDispatches',
  //           link: routerPaths.ordersAndDispatches,
  //         },
  //         'ordersFBM': {
  //           name: 'menu_createOrders',
  //           link: routerPaths.ordersFBM,
  //         },
  //         'ordersFBMCreationLabel': {
  //           name: 'breadcrumbs_EditOrderFBMLabel',
  //           link: routerPaths.ordersFBMEditing,
  //         },
  //       },
  //     },
  //
  //     middleware: [permissions],
  //     permissions: [PERMISSIONS.ORDER_FBM_GENERAL],
  //
  //     moduleKey: moduleKey,
  //     pageTitle: 'breadcrumbs_EditOrderFBMLabel'
  //   },
  // },

  {
    path: routerPaths.ordersFBMEditing,
    component: OrdersFBMEditing,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'ordersFBM',
          'ordersFBMCreationLabel'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'ordersFBM': {
            name: 'menu_createOrders',
            link: routerPaths.ordersFBM,
          },
          'ordersFBMCreationLabel': {
            name: 'breadcrumbs_EditOrderFBMLabel',
            link: routerPaths.ordersFBMEditing,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_FBM_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_EditOrderFBMLabel'
    },
  },

  {
      path: routerPaths.ordersFBMShow,
      component: OrdersFBMShow,
      meta: {
        breadcrumbs: {
          key: [
            'ordersAndDispatches',
            'ordersFBM',
            'ordersFBMCreationLabel'
          ],
          items: {
            'ordersAndDispatches': {
              name: 'menu_ordersDispatches',
              link: routerPaths.ordersAndDispatches,
            },
            'ordersFBM': {
              name: 'menu_createOrders',
              link: routerPaths.ordersFBM,
            },
            'ordersFBMCreationLabel': {
              name: 'breadcrumbs_ViewOrder',
              link: routerPaths.ordersFBMShow,
            },
          },
        },

        middleware: [permissions],
        permissions: [PERMISSIONS.ORDER_FBM_GENERAL],

        moduleKey: moduleKey,
        pageTitle: 'breadcrumbs_ViewOrder'
      },
    },


  {
    path: routerPaths.ordersFBMManifests,
    component: ManifestsTable,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'ordersFBM'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_ordersDispatches',
            link: routerPaths.ordersAndDispatches,
          },
          'ordersFBM': {
            name: 'menu_manifests',
            link: routerPaths.ordersFBMManifests,
          },
        },
      },

      moduleKey: moduleKey,
      moduleKeyChapters: true,
      pageTitle: 'menu_manifests'
    },
  },
]
