
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const RequestReturnTable = () => import(/* webpackChunkName: "group-request-return" */ './components/RequestReturnTable/RequestReturnTable.vue')
const RequestReturnCreation = () => import(/* webpackChunkName: "group-request-return" */ './components/RequestReturnCreation/RequestReturnCreation.vue')
const RequestReturnEditing = () => import(/* webpackChunkName: "group-request-return" */ './components/RequestReturnEditing/RequestReturnEditing.vue')
const moduleKey = 'request-return'


export const RequestReturnRoutes = [
  {
    path: routerPaths.financeRequestReturn,
    component: RequestReturnTable,
    meta: {
      breadcrumbs: {
        key: [
          'dashboard',
          'requestReturn'
        ],
        items: {
          'dashboard': {
            name: 'menu_finance',
            link: routerPaths.cusPayments,
          },
          'requestReturn': {
            name: 'menu_requestReturn',
            link: routerPaths.financeRequestReturn,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.REFUNDS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_requestReturn'
    },
  },

  {
    path: routerPaths.financeRequestReturnCreate,
    component: RequestReturnCreation,
    meta: {
      breadcrumbs: {
        key: [
          'requestReturn',
          'requestReturnCreation'
        ],
        items: {
          'requestReturn': {
            name: 'menu_finance',
            link: routerPaths.financeRequestReturn,
          },
          'requestReturnCreation': {
            name: 'menu_requestReturn',
            link: routerPaths.financeRequestReturnCreate,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.REFUNDS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewReturn'
    },
  },

  {
    path: routerPaths.financeRequestReturnCreateId,
    component: RequestReturnCreation,
    meta: {
      breadcrumbs: {
        key: [
          'requestReturn',
          'requestReturnCreation'
        ],
        items: {
          'requestReturn': {
            name: 'menu_finance',
            link: routerPaths.financeRequestReturn,
          },
          'requestReturnCreation': {
            name: 'menu_requestReturn',
            link: routerPaths.financeRequestReturnCreate,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.REFUNDS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewReturn'
    },
  },

  {
    path: routerPaths.financeRequestReturnEditId,
    component: RequestReturnEditing,
    meta: {
      breadcrumbs: {
        key: [
          'requestReturn',
          'requestReturnCreation'
        ],
        items: {
          'requestReturn': {
            name: 'menu_finance',
            link: routerPaths.financeRequestReturn,
          },
          'requestReturnCreation': {
            name: 'menu_requestReturn',
            link: routerPaths.financeRequestReturnEdit,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.REFUNDS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_EditReturn'
    },
  },

]
