<template>
  <div class="fragment">

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('orderPrice_Price.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.cost'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.cost'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('orderPrice_UseDefault.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox
            v-if="pevItem"
            :value="pevItem.history.use_default"
            :disabled="true"
        />
        <template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox
            v-if="item"
            :value="item.history.use_default"
            :disabled="true"
        />
        <template v-else>—</template>
      </div>
    </div>

  </div>
</template>

<script>
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

export default {
  name: "OrderPriceType",

  components: {DefaultCheckbox, ValueHelper},

  props: {
    pevItem: Object,
    item: Object,
  },

}
</script>

<style scoped>

</style>