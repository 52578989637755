import {API} from "../../axios/axiosMainUrl";

export const proformData = {
  state: {
    proformData: [],
    proformDataItem: {},
    proformDataCommonList: [],
    proformDataBtn: false,
    proformDataLoading: true,
    nextProformDataPage: false,
    proformDataForPage: 100,
    proformDataFilter: '',

    savedProform: [],
  },

  mutations: {
    setProformDataFilter: (state, payload) => {
      state.proformDataFilter = payload
    },

    setSavedProform: (state, payload) => {
      state.savedProform = payload.savedProform
    },

    setProformData: (state, payload) => {
      if(state.nextProformDataPage) {
        state.proformData = payload.proformData
      } else {
        state.proformData = payload.proformData
      }
    },

    setProformDataCommonList: (state, payload) => {
      state.proformDataCommonList = payload.proformDataCommonList
    },

    setNextProformDataPage: (state, payload) => {
      state.nextProformDataPage = payload
    },

    setProformDataItem: (state, payload) => {
      state.proformDataItem = payload.proformDataItem
    },

    setProformDataLoadingStart: (state) => {
      state.proformDataLoading = true
    },

    setProformDataLoadingEnd: (state) => {
      state.proformDataLoading = false
    },

    changeProformDataBtn: (state) => {
      state.proformDataBtn = true
    },
    successProformDataBtn: (state) => {
      state.proformDataBtn = false
    },
  },

  getters: {
    getProformDataFilter: state => {
      return state.proformDataFilter
    },

    getSavedProform: state => {
      return state.savedProform
    },

    getProformDataForPage: state => {
      return state.proformDataForPage
    },

    getProformData: state => {
      return state.proformData
    },

    getProformDataLoading: state => {
      return state.proformDataLoading
    },


    getNextProformDataPage: state => {
      return state.nextProformDataPage
    },

    getProformDataItem: state => {
      return state.proformDataItem
    },

    getProformDataCommonList: state => {
      return state.proformDataCommonList
    },

    getProformDataBtn: state => {
      return state.proformDataBtn
    },
  },

  actions: {

    async fetchProformData({commit, getters}, filter = '') {
      // if(!getters.getNextProformDataPage)
      //   commit('setProformDataLoadingStart')
      try {
        return await API.get(`/products/proform-attachment${filter}`).then((response) =>{
          if(getters.getNextProformDataPage){
            let data = Object.assign({}, getters.getProformData);
            response.data.data.proformTypes.map((item) => {
              if(response.data.data.attachmentData.data[item.id] !== undefined){
                if(data[item.id] === undefined) data[item.id] = []
                data[item.id] = data[item.id].concat(response.data.data.attachmentData.data[item.id])
              }
            })

            commit('setProformData',{proformData: data})
          } else {
            commit('setProformData',{proformData: response.data.data.attachmentData.data})
          }
          commit('setProformDataCommonList',{proformDataCommonList: response.data.data})
          commit('setProformDataLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setProformDataLoadingEnd');
        return e;
      }
    },

    async getProformData({commit}, id) {
      try {
        return await API.get(`/products/proform-attachment/${id}`).then((response) =>{
          commit('setProformDataItem', {proformDataItem: response.data.data})
          return response;
        });
      } catch (e) {
        commit('setProformDataLoadingEnd');
        return e;
      }
    },

    async createPartialProformData({commit}, data) {
      commit('changeProformDataBtn');
      try {
        return await API.post(`/products/proform-attachment/partial`, data).then((response) =>{
          commit('successProformDataBtn');
          return response;
        });
      } catch (e) {
        commit('successProformDataBtn');
        return e;
      }
    },

    async createProformData({commit}, data) {
      commit('changeProformDataBtn');
      try {
        return await API.post(`/products/proform-attachment/mass`, data).then((response) =>{
          commit('successProformDataBtn');
          return response;
        });
      } catch (e) {
        commit('successProformDataBtn');
        return e;
      }
    },

    async updateProformData({commit}, {id, data}) {
      commit('changeProformDataBtn');
      try {
        return await API.put(`/products/proform-attachment/${id}`, data).then((response) =>{
          commit('successProformDataBtn');
          return response;
        });
      } catch (e) {
        commit('successProformDataBtn');
        return e;
      }
    },

    async deleteProformData({commit}, id) {
      try {
        return await API.delete(`/products/proform-attachment/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successProformDataBtn');
        return e;
      }
    },

    async getProformExampleImportFile({commit}) {
      commit('setLoadingEnd')
      try {
        return await API.get(`/products/proform-attachment/import-example`, { responseType: 'blob' }).then((response) =>{
          return response
        })
      } catch (e) {
        return e;
      }
    },

    async importProform({commit}, data) {
      commit('setLoadingStart');
      try {
        return await API.post(`/products/proform-attachment/import`, data).then((response) =>{
          return response.data.data;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async getSavedProform({commit}, id) {
      try {
        return await API.get(`/products/proform-entity/template-list?filter[ProformEntityIsTemplate]=1&filter[ProformEntityUserId]=${id}`).then((response) =>{
          commit('setSavedProform', {savedProform: response.data.data})
          return response;
        });
      } catch (e) {
        commit('setProformDataLoadingEnd');
        return e;
      }
    },

    async createSavedProform({commit}, data) {
      commit('changeProformDataBtn');
      try {
        return await API.post(`/products/proform-entity/store-template`, data).then((response) =>{
          commit('successProformDataBtn');
          return response;
        });
      } catch (e) {
        commit('successProformDataBtn');
        return e;
      }
    },

    async updateProfileProformAndProduct({commit}, data) {
      commit('changeProformDataBtn');
      try {
        return await API.post(`/products/proform-update/update-profile-proform-and-product`, data).then((response) =>{
          commit('successProformDataBtn');
          return response;
        });
      } catch (e) {
        commit('successProformDataBtn');
        return e;
      }
    },

  }
}
