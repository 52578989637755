import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const MyPaymentsTable = () => import(/* webpackChunkName: "group-my-payments" */ './components/MyPaymentsTable')
const moduleKey = 'my-payments'

export const MyPaymentsRoutes = [
  {
    path: routerPaths.financeMyPayments,
    component: MyPaymentsTable,
    meta: {
      breadcrumbs: {
        key: [
          'finance',
          'financeMyPayments',
        ],
        items: {
          'finance': {
            name: 'menu_finance',
            link: routerPaths.dashboard,
          },
          'financeMyPayments': {
            name: 'menu_myPayments',
            link: routerPaths.financeMyPayments,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.MY_PAYMENT_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_myPayments'
    },
  },
]


