import {API} from "../../axios/axiosMainUrl";

const prefix = '/users/note'

export const userNotes = {
  state: {
    userNotes: [],
    userNotesItem: {},
    userNotesByUserId: [],
    userNotesCommonList: [],
    userNotesBtn: false,
    userNotesLoading: true,
    nextUserNotesPage: false,
    userNotesForPage: 25,
    userNotesFilter: '',
  },

  mutations: {
    setUserNotesFilter: (state, payload) => {
      state.userNotesFilter = payload
    },

    setUserNotes: (state, payload) => {
      if(state.nextUserNotesPage) {
        state.userNotes = state.userNotes.concat(payload.userNotes)
      } else {
        state.userNotes = payload.userNotes
      }
    },

    setUserNotesCommonList: (state, payload) => {
      state.userNotesCommonList = payload.userNotesCommonList
    },

    setNextUserNotesPage: (state, payload) => {
      state.nextUserNotesPage = payload
    },

    setUserNotesItem: (state, payload) => {
      state.userNotesItem = payload.userNotesItem
    },

    setUserNotesByUserId: (state, payload) => {
      state.userNotesByUserId = payload.userNotesByUserId
    },

    setUserNotesLoadingStart: (state) => {
      state.userNotesLoading = true
    },

    setUserNotesLoadingEnd: (state) => {
      state.userNotesLoading = false
    },

    changeUserNotesBtn: (state) => {
      state.userNotesBtn = true
    },
    successUserNotesBtn: (state) => {
      state.userNotesBtn = false
    },
  },

  getters: {
    getUserNotesFilter: state => {
      return state.userNotesFilter
    },

    getUserNotesForPage: state => {
      return state.userNotesForPage
    },

    getUserNotes: state => {
      return state.userNotes
    },

    getUserNotesLoading: state => {
      return state.userNotesLoading
    },


    getNextUserNotesPage: state => {
      return state.nextUserNotesPage
    },

    getUserNotesItem: state => {
      return state.userNotesItem
    },

    getUserNotesByUserId: state => {
      return state.userNotesByUserId
    },

    getUserNotesCommonList: state => {
      return state.userNotesCommonList
    },

    getUserNotesBtn: state => {
      return state.userNotesBtn
    },
  },

  actions: {

    async fetchUserNotes({commit, getters}, filter = '') {
      if(!getters.getNextUserNotesPage)
        commit('setUserNotesLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setUserNotes',{userNotes: response.data.data.data})
          commit('setUserNotesCommonList',{userNotesCommonList: response.data.data})
          commit('setUserNotesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setUserNotesLoadingEnd');
        return e;
      }
    },

    async getUserNotes({commit}, id) {
      commit('setUserNotesLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setUserNotesItem', {userNotesItem: response.data.data})
          commit('setUserNotesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setUserNotesLoadingEnd');
        return e;
      }
    },

    async getUserNotesById({commit}, id) {
      try {
        return await API.get(`${prefix}/get-user-notes?user_id=${id}`).then((response) =>{
          commit('setUserNotesByUserId', {userNotesByUserId: response.data.data})
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async createUserNotes({commit}, data) {
      commit('changeUserNotesBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successUserNotesBtn');
          return response;
        });
      } catch (e) {
        commit('successUserNotesBtn');
        return e;
      }
    },

    async updateUserNotes({commit}, {id, data}) {
      commit('changeUserNotesBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successUserNotesBtn');
          return response;
        });
      } catch (e) {
        commit('successUserNotesBtn');
        return e;
      }
    },

    async deleteUserNotes({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successUserNotesBtn');
        return e;
      }
    },

  }
}
