<template>
  <div class="fragment">

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('requestFunds_WhereSend.localization_value.value')}}</div>
      <div class="history-table__sub-col">
<!--        <ValueHelper :value="pevItem" :deep="'history.account.bank.name'" :replaceValue="' '"/>-->
<!--        <ValueHelper :value="pevItem" :deep="'history.account.card_number'" :replaceValue="' '"/>-->
<!--        <ValueHelper :value="pevItem" :deep="'history.account.phone'" :replaceValue="' '"/>-->

        <template v-if="pevItem && pevItem.history">
          <template v-if="pevItem.history.account.bank_id !== ACCOUNT_BANK_LIST.ACCOUNT_BANK_WISE.id">
            {{pevItem.history.account.bank.currentTranslate.name}}<template v-if="pevItem.history.account.card_number">
            , {{pevItem.history.account.card_number}}</template><template v-if="pevItem.history.account.email">
            , {{pevItem.history.account.email}}</template>

            <template v-if="pevItem.history.account.phone && pevItem.history.account.bank_id === ACCOUNT_BANK_LIST.ACCOUNT_BANK_KIT_GROUP.id">
              , {{pevItem.history.account.phone}}</template>
            <template v-if="pevItem.history.account.iban && pevItem.history.account.bank_id === ACCOUNT_BANK_LIST.ACCOUNT_BANK_SWIFT.id">
              , {{pevItem.history.account.iban}}</template>
          </template>
          <template v-else>
            {{pevItem.history.account.bank.currentTranslate.name}}, <template v-if="pevItem.history.account.full_name">
            , {{pevItem.history.account.full_name}}</template>
          </template>
        </template>


      </div>
      <div class="history-table__sub-col">
<!--        <ValueHelper class="mr-1" :value="item" :deep="'history.account.bank.name'" :replaceValue="''"/>-->
<!--        <ValueHelper class="mr-1" :value="item" :deep="'history.account.card_number'" :replaceValue="''"/>-->
<!--        <ValueHelper class="mr-1" :value="item" :deep="'history.account.phone'" :replaceValue="''"/>-->
        <template v-if="item && item.history">
          <template v-if="item.history.account.bank_id !== ACCOUNT_BANK_LIST.ACCOUNT_BANK_WISE.id">
            {{item.history.account.bank.currentTranslate.name}}<template v-if="item.history.account.card_number">
            , {{item.history.account.card_number}}</template><template v-if="item.history.account.email">
            , {{item.history.account.email}}</template>

            <template v-if="item.history.account.phone && item.history.account.bank_id === ACCOUNT_BANK_LIST.ACCOUNT_BANK_KIT_GROUP.id">
              , {{item.history.account.phone}}</template>
            <template v-if="item.history.account.iban && item.history.account.bank_id === ACCOUNT_BANK_LIST.ACCOUNT_BANK_SWIFT.id">
              , {{item.history.account.iban}}</template>
          </template>
          <template v-else>
            {{item.history.account.bank.currentTranslate.name}}, <template v-if="item.history.account.full_name">
            , {{item.history.account.full_name}}</template>
          </template>
        </template>
      </div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('requestFunds_SelectSum.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.payment_transaction_total_amount'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.payment_transaction_total_amount'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('requestFunds_Transactions.localization_value.value')}}</div>
      <div class="history-table__sub-col">
        <template v-if="pevItem">
          <span class="mr-1" v-for="(transaction, index) in pevItem.history.payment_transactions" :key="index">#{{transaction.id}} ${{transaction.payment_total}};</span>
        </template>
      </div>
      <div class="history-table__sub-col">
        <template v-if="item">
          <span class="mr-1" v-for="(transaction, index) in item.history.payment_transactions" :key="index">#{{transaction.id}} ${{transaction.payment_total}};</span>
        </template>
      </div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('requestFunds_Commission.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.commission_amount'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.commission_amount'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('requestFunds_Comment.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.user_comment'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.user_comment'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('fillClaim_CommentAdmin.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.admin_comment'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.admin_comment'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('requestFunds_Status.localization_value.value')}}</div>
      <div class="history-table__sub-col"><span v-if="pevItem && pevItem.history.status">{{$t(getStatusPrev + '.localization_value.value')}}</span><template v-else>—</template></div>
      <div class="history-table__sub-col"><span v-if="item">{{$t(getStatus + '.localization_value.value')}}</span></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">
        {{$t('requestFunds_ignoreAmountLimit.localization_value.value')}}
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox
            v-if="pevItem"
            :value="pevItem.history.ignore_amount_limit === 1"
            :disabled="true"
        />
        <template v-else>—</template>
      </div>
      <div class="history-table__sub-col" >
        <DefaultCheckbox
            v-if="item"
            :value="item.history.ignore_amount_limit === 1"
            :disabled="true"
        />
        <template v-else>—</template>
      </div>
    </div>

  </div>
</template>

<script>
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import {ACCOUNT_BANK_LIST, REQUEST_FUNDS_STATUSES} from "@/staticData/staticVariables";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

export default {
  name: "RequestFundsType",

  components: {DefaultCheckbox, ValueHelper},

  props: {
    pevItem: Object,
    item: Object,
  },

  data() {
    return {
      ACCOUNT_BANK_LIST: ACCOUNT_BANK_LIST,
    }
  },

  computed: {
    getStatusPrev() {
      return this._.find(REQUEST_FUNDS_STATUSES, {value: this.pevItem.history.status})?.translation
    },

    getStatus() {
      return this._.find(REQUEST_FUNDS_STATUSES, {value: this.item.history.status})?.translation
    },
  }

}
</script>

<style scoped>

</style>