import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const ConsolidationOrdersTable = () => import(/* webpackChunkName: "group-consolidation-orders" */ './components/ConsolidationOrdersTable/ConsolidationOrdersTable.vue')
const moduleKey = 'consolidation-orders'

export const ConsolidationOrdersRoutes = [
  {
    path: routerPaths.reportsConsolidationOrders,
    component: ConsolidationOrdersTable,
    meta: {
      breadcrumbs: {
        key: [
          'reports',
          'reportsConsolidationOrders'
        ],
        items: {
          'reports': {
            name: 'menu_reports',
            link: routerPaths.reportsConsolidationOrders,
          },
          'reportsConsolidationOrders': {
            name: 'menu_reportsConsolidationOrders',
            link: routerPaths.reportsConsolidationOrders,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.REPORT_ORDERS_IN_CONSOLIDATION_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_reportsConsolidationOrders'
    },
  }
]
