import {API} from "../../axios/axiosMainUrl";

const apiOrderInternal = 'api-order-internal'

export const novaPoshta = {
  state: {
    novaPoshta: [],
    novaPoshtaItem: {},
    novaPoshtaCommonList: [],
    novaPoshtaBtn: false,
    novaPoshtaLoading: true,
    nextNovaPoshtaPage: false,
    novaPoshtaForPage: 25,
    novaPoshtaFilter: '',
  },

  mutations: {
    setNovaPoshtaFilter: (state, payload) => {
      state.novaPoshtaFilter = payload
    },

    setNovaPoshta: (state, payload) => {
      if(state.nextNovaPoshtaPage) {
        state.novaPoshta = state.novaPoshta.concat(payload.novaPoshta)
      } else {
        state.novaPoshta = payload.novaPoshta
      }
    },

    setNovaPoshtaCommonList: (state, payload) => {
      state.novaPoshtaCommonList = payload.novaPoshtaCommonList
    },

    setNextNovaPoshtaPage: (state, payload) => {
      state.nextNovaPoshtaPage = payload
    },

    setNovaPoshtaItem: (state, payload) => {
      state.novaPoshtaItem = payload.novaPoshtaItem
    },

    setNovaPoshtaLoadingStart: (state) => {
      state.novaPoshtaLoading = true
    },

    setNovaPoshtaLoadingEnd: (state) => {
      state.novaPoshtaLoading = false
    },

    changeNovaPoshtaBtn: (state) => {
      state.novaPoshtaBtn = true
    },
    successNovaPoshtaBtn: (state) => {
      state.novaPoshtaBtn = false
    },
  },

  getters: {
    getNovaPoshtaFilter: state => {
      return state.novaPoshtaFilter
    },

    getNovaPoshtaForPage: state => {
      return state.novaPoshtaForPage
    },

    getNovaPoshta: state => {
      return state.novaPoshta
    },

    getNovaPoshtaLoading: state => {
      return state.novaPoshtaLoading
    },


    getNextNovaPoshtaPage: state => {
      return state.nextNovaPoshtaPage
    },

    getNovaPoshtaItem: state => {
      return state.novaPoshtaItem
    },

    getNovaPoshtaCommonList: state => {
      return state.novaPoshtaCommonList
    },

    getNovaPoshtaBtn: state => {
      return state.novaPoshtaBtn
    },
  },

  actions: {

    async fetchNovaPoshta({commit, getters}, filter = '') {
      if(!getters.getNextNovaPoshtaPage)
        commit('setNovaPoshtaLoadingStart')
      try {
        return await API.get(`/novaPoshta${filter}`).then((response) =>{
          commit('setNovaPoshta',{novaPoshta: response.data.data.data})
          commit('setNovaPoshtaCommonList',{novaPoshtaCommonList: response.data.data})
          commit('setNovaPoshtaLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setNovaPoshtaLoadingEnd');
        return e;
      }
    },

    async getNovaPoshta({commit}, id) {
      commit('setNovaPoshtaLoadingStart');
      try {
        return await API.get(`/novaPoshta/${id}`).then((response) =>{
          console.log(response)
          commit('setNovaPoshtaItem', {novaPoshtaItem: response.data.data})
          commit('setNovaPoshtaLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setNovaPoshtaLoadingEnd');
        return e;
      }
    },


    // async createNovaPoshta({commit}, data) {
    //   commit('changeNovaPoshtaBtn');
    //   try {
    //     return await API.post(`${orderInternal}`, data).then((response) =>{
    //       commit('successNovaPoshtaBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successNovaPoshtaBtn');
    //     return e;
    //   }
    // },


    async updateNovaPoshta({commit}, {id, data}) {
      commit('changeNovaPoshtaBtn');
      try {
        return await API.put(`/novaPoshta/${id}`, data).then((response) =>{
          commit('successNovaPoshtaBtn');
          return response;
        });
      } catch (e) {
        commit('successNovaPoshtaBtn');
        return e;
      }
    },

    async deleteNovaPoshta({commit}, id) {
      try {
        return await API.delete(`/novaPoshta/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successNovaPoshtaBtn');
        return e;
      }
    },


    async getUserSenderWarehouses({commit}, data) {
      try {
        return await API.post(`${apiOrderInternal}/novaposhta/get-user-sender-warehouses`, data).then((response) =>{
          return response.data;
        });
      } catch (e) {
        commit('successNovaPoshtaBtn');
        return e;
      }
    },

    async getUserSenderAddresses({commit}, data) {
      try {
        return await API.post(`${apiOrderInternal}/novaposhta/get-user-sender-addresses`, data).then((response) =>{
          return response.data;
        });
      } catch (e) {
        commit('successNovaPoshtaBtn');
        return e;
      }
    },

    async getNovaPoshtaDeliveryPrice({commit}, data) {
      commit('changeNovaPoshtaBtn');
      try {
        return await API.post(`${apiOrderInternal}/novaposhta/get-delivery-price`, data).then((response) =>{
          commit('successNovaPoshtaBtn');
          return response;
        });
      } catch (e) {
        commit('successNovaPoshtaBtn');
        return e;
      }
    },

    async createNovaPoshtaAddress({commit}, data) {
      try {
        return await API.post(`${apiOrderInternal}/novaposhta/create-user-address`, data).then((response) =>{
          return response.data.data;
        });
      } catch (e) {
        commit('successNovaPoshtaBtn');
        return e;
      }
    },


    async getNovaPoshtaCities({commit}, data) {
      try {
        return await API.post(`${apiOrderInternal}/novaposhta/get-search-city`, data).then((response) =>{
          return response.data.data;
        });
      } catch (e) {
        commit('setNovaPoshtaLoadingEnd');
        return e;
      }
    },


    async getNovaPoshtaStreets({commit}, data) {
      try {
        return await API.post(`${apiOrderInternal}/novaposhta/get-search-street-by-city-ref`, data).then((response) =>{
          return response.data.data;
        });
      } catch (e) {
        commit('setNovaPoshtaLoadingEnd');
        return e;
      }
    },

    async getNovaPoshtaWarehouseFromCity({commit}, data) {
      try {
        return await API.post(`${apiOrderInternal}/novaposhta/get-search-warehouses-by-city-ref`, data).then((response) =>{
          return response.data.data;
        });
      } catch (e) {
        commit('setNovaPoshtaLoadingEnd');
        return e;
      }
    },

    async createUserNovaPoshtaAddress({commit}, data) {
      commit('changeNovaPoshtaBtn');
      try {
        return await API.post(`${apiOrderInternal}/novaposhta/create-user-address`, data).then((response) =>{
          commit('successNovaPoshtaBtn');
          return response;
        });
      } catch (e) {
        commit('successNovaPoshtaBtn');
        return e;
      }
    },

    async createNovaPoshtaLabel({commit}, id) {
      commit('changeNovaPoshtaBtn');
      try {
        return await API.post(`${apiOrderInternal}/novaposhta/${id}/create-label`).then((response) =>{
          commit('successNovaPoshtaBtn');
          return response;
        });
      } catch (e) {
        commit('successNovaPoshtaBtn');
        return e;
      }
    },

  }
}
