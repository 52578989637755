import {API} from "../../axios/axiosMainUrl";

const heartysan = '/heartysan/product'

export const heartysanProducts = {
  state: {
    heartysanProducts: [],
    heartysanProductsItem: {},
    heartysanProductsCommonList: [],
    heartysanProductsBtn: false,
    heartysanProductsLoading: true,
    nextHeartysanProductsPage: false,
    heartysanProductsForPage: 25,
    heartysanProductsFilter: '',

    heartysanProductsEvents: [],

    heartysanProductsSocket: false,
    heartysanProductsSocketChannel: 'private-hearty-product.1',
    heartysanProductsSocketEvent: 'hearty-product.message',
  },

  mutations: {
    setHeartysanProductsFilter: (state, payload) => {
      state.heartysanProductsFilter = payload
    },

    setHeartysanProducts: (state, payload) => {
      if(state.nextHeartysanProductsPage) {
        state.heartysanProducts = state.heartysanProducts.concat(payload.heartysanProducts)
      } else {
        state.heartysanProducts = payload.heartysanProducts
      }
    },

    setHeartysanProductsCommonList: (state, payload) => {
      state.heartysanProductsCommonList = payload.heartysanProductsCommonList
    },

    setNextHeartysanProductsPage: (state, payload) => {
      state.nextHeartysanProductsPage = payload
    },

    setHeartysanProductsItem: (state, payload) => {
      state.heartysanProductsItem = payload.heartysanProductsItem
    },

    setHeartysanProductsLoadingStart: (state) => {
      state.heartysanProductsLoading = true
    },

    setHeartysanProductsLoadingEnd: (state) => {
      state.heartysanProductsLoading = false
    },

    changeHeartysanProductsBtn: (state) => {
      state.heartysanProductsBtn = true
    },
    successHeartysanProductsBtn: (state) => {
      state.heartysanProductsBtn = false
    },

    setHeartysanProductsSocket: (state) => {
      state.heartysanProductsSocket = false
    },

    setHeartysanProductsEvents: (state, payload) => {
      state.heartysanProductsEvents = payload
    },
  },

  getters: {
    getHeartysanProductsFilter: state => {
      return state.heartysanProductsFilter
    },

    getHeartysanProductsForPage: state => {
      return state.heartysanProductsForPage
    },

    getHeartysanProducts: state => {
      return state.heartysanProducts
    },

    getHeartysanProductsLoading: state => {
      return state.heartysanProductsLoading
    },


    getNextHeartysanProductsPage: state => {
      return state.nextHeartysanProductsPage
    },

    getHeartysanProductsItem: state => {
      return state.heartysanProductsItem
    },

    getHeartysanProductsCommonList: state => {
      return state.heartysanProductsCommonList
    },

    getHeartysanProductsBtn: state => {
      return state.heartysanProductsBtn
    },

    getHeartysanProductsSocketEvent: state => {
      return state.heartysanProductsSocketEvent
    },

    getHeartysanProductsSocketChannel: state => {
      return state.heartysanProductsSocketChannel
    },

    getHeartysanProductsEvents: state => {
      return state.heartysanProductsEvents
    },
  },

  actions: {

    async fetchHeartysanProducts({commit, getters}, filter = '') {
      if(!getters.getNextHeartysanProductsPage)
        commit('setHeartysanProductsLoadingStart')
      try {
        return await API.get(`${heartysan}${filter}`).then((response) =>{
          commit('setHeartysanProducts',{heartysanProducts: response.data.data.data})
          // commit('setHeartysanProductsCommonList',{heartysanProductsCommonList: response.data.data})
          commit('setHeartysanProductsCommonList',{heartysanProductsCommonList: {...response.data.data.meta, ...{
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev
              }}})
          commit('setHeartysanProductsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setHeartysanProductsLoadingEnd');
        return e;
      }
    },

    async getHeartysanProducts({commit}, id) {
      commit('setHeartysanProductsLoadingStart');
      try {
        return await API.get(`${heartysan}/${id}`).then((response) =>{
          console.log(response)
          commit('setHeartysanProductsItem', {heartysanProductsItem: response.data.data})
          commit('setHeartysanProductsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setHeartysanProductsLoadingEnd');
        return e;
      }
    },

    async createHeartysanProducts({commit}, data) {
      commit('changeHeartysanProductsBtn');
      try {
        return await API.post(`${heartysan}`, data).then((response) =>{
          commit('successHeartysanProductsBtn');
          return response;
        });
      } catch (e) {
        commit('successHeartysanProductsBtn');
        return e;
      }
    },

    async updateHeartysanProducts({commit}, {id, data}) {
      commit('changeHeartysanProductsBtn');
      try {
        return await API.put(`${heartysan}/${id}`, data).then((response) =>{
          commit('successHeartysanProductsBtn');
          return response;
        });
      } catch (e) {
        commit('successHeartysanProductsBtn');
        return e;
      }
    },

    async deleteHeartysanProducts({commit}, id) {
      try {
        return await API.delete(`${heartysan}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successHeartysanProductsBtn');
        return e;
      }
    },

    async addFilesHeartysanProduct({commit}, {data}) {
      commit('changeHeartysanProductsBtn');
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${heartysan}/upload-files`, data).then((response) =>{
          commit('successHeartysanProductsBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successHeartysanProductsBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },


    async getHeartysanFileFromServer({commit}, id) {
      commit('setLoadingEnd')
      try {
        return await API.get(`${heartysan}/${id}/download`).then((response) => {
          return response.data.data
        })
      } catch (e) {
        return e;
      }
    },

    async heartysanProductsInArchive({commit}, id) {
      commit('changeHeartysanProductsBtn');
      try {
        return await API.patch(`${heartysan}/${id}/disable`).then((response) =>{
          commit('successHeartysanProductsBtn');
          return response;
        });
      } catch (e) {
        commit('successHeartysanProductsBtn');
        return e;
      }
    },

    async heartysanProductsInUnArchive({commit}, id) {
      commit('changeHeartysanProductsBtn');
      try {
        return await API.patch(`${heartysan}/${id}/enable`).then((response) =>{
          commit('successHeartysanProductsBtn');
          return response;
        });
      } catch (e) {
        commit('successHeartysanProductsBtn');
        return e;
      }
    },


  }
}
