<template>
  <modal
          @close="$emit('close')"
          class="save-drafts-modal save-drafts"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'common_SaveInDrafts',
          ])"></div>
        {{ $t('common_SaveInDrafts.localization_value.value') }}?
      </div>

    </template>
    <template slot="body">
      <div class="save-drafts__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'common_DraftTxt',
            'common_DraftTxtNext',
          ])"></div>
        <p>
          {{ $t('common_DraftTxt.localization_value.value') }}
        </p>
        <p>
          {{ $t('common_DraftTxtNext.localization_value.value') }}
        </p>
      </div>
    </template>
    <template slot="footer">
      <div class="save-drafts__btn"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'common_SaveInDrafts',
            'common_NoContinue',
          ])"></div>
        <MainButton
                :value="$t('common_SaveInDrafts.localization_value.value')"
                class="secondary save-drafts__btn-i wfc"
                @click.native="$emit('yes')"
                v-bind:class="{'disabled-btn' : disabledBtn}"
        />

        <MainButton
                :value="$t('common_NoContinue.localization_value.value')"
                class="save-drafts__btn-i wfc"
                @click.native="$emit('close')"
        />
      </div>
    </template>
    <template slot="background">
      <div class="save-drafts__bg">
        <SaveInDraftsBg/>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '../../../commonModals/Modal.vue'
import MainButton from '../../../UI/buttons/MainButton/MainButton.vue'
import SaveInDraftsBg from '../../../../../public/img/modal-group/saving-in-drafts-bg.svg?inline'



export default {
  name: "SaveInDraftsPopup",
  components: {
    Modal,
    MainButton,
    SaveInDraftsBg
  },

  props: {
    disabledBtn: {
      type: Boolean,
      default: false,
    }
  }
}

</script>

<style lang="scss">
  @import "SaveInDraftsPopup";
</style>
