
import {routerPaths} from '../../../router/routerPaths'

const GetPdfFile = () => import(/* webpackChunkName: "group-hs-codes" */ './components/GetPdfFile/GetPdfFile.vue')


export const GetFileRoutes = [
  {
    path: routerPaths.getPdfFileId,
    component: GetPdfFile,

    pageTitle: 'common_getFileTitle'
  },

]
