import {API} from "../../axios/axiosMainUrl";
const _ = require('lodash');

export const countries = {
  state: {
    countries: [],
    countryGroup: {},
    countriesItem: {},
    countriesCommonList: [],
    countriesBtn: false,
    countriesLoading: true,
    nextCountriesPage: false,
    countriesForPage: 25,
    countriesFilter: '',
  },

  mutations: {
    setCountriesFilter: (state, payload) => {
      state.countriesFilter = payload
    },

    setCountries: (state, payload) => {
      if(state.nextCountriesPage) {
        state.countries = state.countries.concat(payload.countries)
      } else {
        state.countries = payload.countries
      }
    },

    setCountriesCommonList: (state, payload) => {
      state.countriesCommonList = payload.countriesCommonList
    },

    setNextCountriesPage: (state, payload) => {
      state.nextCountriesPage = payload
    },

    setCountriesItem: (state, payload) => {
      state.countriesItem = payload.countriesItem
    },

    setCountryGroup: (state, payload) => {
      state.countryGroup = payload.countryGroup
    },

    setCountriesLoadingStart: (state) => {
      state.countriesLoading = true
    },

    setCountriesLoadingEnd: (state) => {
      state.countriesLoading = false
    },

    changeCountriesBtn: (state) => {
      state.countriesBtn = true
    },
    successCountriesBtn: (state) => {
      state.countriesBtn = false
    },
  },

  getters: {
    getCountriesFilter: state => {
      return state.countriesFilter
    },

    getCountriesForPage: state => {
      return state.countriesForPage
    },

    getCountries: state => {
      return state.countries
    },

    getCountryGroup: state => {
      return state.countryGroup
    },

    getCountriesLoading: state => {
      return state.countriesLoading
    },


    getNextCountriesPage: state => {
      return state.nextCountriesPage
    },

    getCountriesItem: state => {
      return state.countriesItem
    },

    getCountriesCommonList: state => {
      return state.countriesCommonList
    },

    getCountriesBtn: state => {
      return state.countriesBtn
    },
  },

  actions: {

    async fetchCountries({commit, getters}, filter = '') {
      if(!getters.getNextCountriesPage)
        commit('setCountriesLoadingStart')
      try {
        await API.get(`/contact/country/countries-list${filter}`).then((response) =>{
          commit('setCountries', {countries: response.data.data.countries})
          // commit('setCountriesCommonList',{countriesCommonList: response.data.data})
          commit('setCountriesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setCountriesLoadingEnd');
        return e;
      }
    },

    async getCountryDataById({commit}, id) {
      try {
        await API.get(`/contact/country?filter[CountryId]=${id}`).then((response) =>{
          commit('setCountryGroup', {countryGroup: _.first(response.data.data.countries.data)})
        });
      } catch (e) {
        commit('setCountriesLoadingEnd');
        return e;
      }
    },

    async getCountries({commit}, id) {
      commit('setCountriesLoadingStart');
      try {
        return await API.get(`/countries/${id}`).then((response) =>{
          console.log(response)
          commit('setCountriesItem', {countriesItem: response.data.data})
          commit('setCountriesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setCountriesLoadingEnd');
        return e;
      }
    },

    async createCountries({commit}, data) {
      commit('changeCountriesBtn');
      try {
        return await API.post(`/countries`, data).then((response) =>{
          commit('successCountriesBtn');
          return response;
        });
      } catch (e) {
        commit('successCountriesBtn');
        return e;
      }
    },

    async updateCountries({commit}, {id, data}) {
      commit('changeCountriesBtn');
      try {
        return await API.put(`/countries/${id}`, data).then((response) =>{
          commit('successCountriesBtn');
          return response;
        });
      } catch (e) {
        commit('successCountriesBtn');
        return e;
      }
    },

    async deleteCountries({commit}, id) {
      try {
        return await API.delete(`/countries/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successCountriesBtn');
        return e;
      }
    },

  }
}
