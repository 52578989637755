import {API} from "../../axios/axiosMainUrl";

const prefix = '/consolidations/order-unions'

export const APCDelcon = {
  state: {
    APCDelcon: [],
    APCDelconItem: {},
    APCDelconByUserId: [],
    APCDelconCommonList: [],
    APCDelconBtn: false,
    APCDelconLoading: true,
    nextAPCDelconPage: false,
    APCDelconForPage: 25,
    APCDelconFilter: '',
  },

  mutations: {
    setAPCDelconFilter: (state, payload) => {
      state.APCDelconFilter = payload
    },

    setAPCDelcon: (state, payload) => {
      if(state.nextAPCDelconPage) {
        state.APCDelcon = state.APCDelcon.concat(payload.APCDelcon)
      } else {
        state.APCDelcon = payload.APCDelcon
      }
    },

    setAPCDelconCommonList: (state, payload) => {
      state.APCDelconCommonList = payload.APCDelconCommonList
    },

    setNextAPCDelconPage: (state, payload) => {
      state.nextAPCDelconPage = payload
    },

    setAPCDelconItem: (state, payload) => {
      state.APCDelconItem = payload.APCDelconItem
    },

    setAPCDelconByUserId: (state, payload) => {
      state.APCDelconByUserId = payload.APCDelconByUserId
    },

    setAPCDelconLoadingStart: (state) => {
      state.APCDelconLoading = true
    },

    setAPCDelconLoadingEnd: (state) => {
      state.APCDelconLoading = false
    },

    changeAPCDelconBtn: (state) => {
      state.APCDelconBtn = true
    },
    successAPCDelconBtn: (state) => {
      state.APCDelconBtn = false
    },
  },

  getters: {
    getAPCDelconFilter: state => {
      return state.APCDelconFilter
    },

    getAPCDelconForPage: state => {
      return state.APCDelconForPage
    },

    getAPCDelcon: state => {
      return state.APCDelcon
    },

    getAPCDelconLoading: state => {
      return state.APCDelconLoading
    },


    getNextAPCDelconPage: state => {
      return state.nextAPCDelconPage
    },

    getAPCDelconItem: state => {
      return state.APCDelconItem
    },

    getAPCDelconByUserId: state => {
      return state.APCDelconByUserId
    },

    getAPCDelconCommonList: state => {
      return state.APCDelconCommonList
    },

    getAPCDelconBtn: state => {
      return state.APCDelconBtn
    },
  },

  actions: {

    async fetchAPCDelcon({commit, getters}, filter = '') {
      if(!getters.getNextAPCDelconPage)
        commit('setAPCDelconLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setAPCDelcon',{APCDelcon: response.data.data.data})
          commit('setAPCDelconCommonList',{APCDelconCommonList: response.data.data})
          commit('setAPCDelconLoadingEnd');
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('setAPCDelconLoadingEnd');
        return e;
      }
    },


    async getAPCDelcon({commit}, id) {
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setAPCDelconItem', {APCDelconItem: response.data.data})
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async getAPCDelconConsolidations({commit}, id) {
      try {
        return await API.get(`${prefix}/${id}/consolidations`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setAPCDelconLoadingEnd');
        return e;
      }
    },

    async getAPCDelconEdit({commit}, id) {
      try {
        return await API.get(`${prefix}/${id}/edit`).then((response) =>{
          commit('setAPCDelconItem', {APCDelconItem: response.data.data})
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async getAPCDelconCreate({commit}, data) {
      try {
        return await API.post(`${prefix}/create`, data).then((response) => {
          return response;
        });
      } catch (e) {
        commit('setAPCDelconLoadingEnd');
        return e;
      }
    },

    async getAPCDelconDeliveryPrices({commit},) {
      try {
        return await API.get(`/APCDelcon-delivery-price`).then((response) => {
          return response;
        });
      } catch (e) {
        commit('setAPCDelconLoadingEnd');
        return e;
      }
    },

    async createAPCDelcon({commit}, data) {
      commit('changeAPCDelconBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successAPCDelconBtn');
          return response;
        });
      } catch (e) {
        commit('successAPCDelconBtn');
        return e;
      }
    },

    async APCDelconDepartConsolidations({commit}, data) {
      commit('changeAPCDelconBtn');
      try {
        return await API.post(`${prefix}/depart`, data).then((response) =>{
          commit('successAPCDelconBtn');
          return response;
        });
      } catch (e) {
        commit('successAPCDelconBtn');
        return e;
      }
    },

    async APCDelconReceiveUnions({commit}, data) {
      commit('changeAPCDelconBtn');
      try {
        return await API.post(`${prefix}/receive`, data).then((response) =>{
          commit('successAPCDelconBtn');
          return response;
        });
      } catch (e) {
        commit('successAPCDelconBtn');
        return e;
      }
    },

    async getAPCDelconShippingCost({commit}, data) {
      commit('changeAPCDelconBtn');
      try {
        return await API.post(`${prefix}/calculate-packaging-amount`, data).then((response) =>{
          commit('successAPCDelconBtn');
          return response;
        });
      } catch (e) {
        commit('successAPCDelconBtn');
        return e;
      }
    },

    async addMessageAPCDelcon({commit}, {id: id, data: data}) {
      commit('changeAPCDelconBtn');
      try {
        return await API.post(`${prefix}/${id}/add-dialog-message`, data).then((response) =>{
          commit('successAPCDelconBtn');
          return response;
        });
      } catch (e) {
        commit('successAPCDelconBtn');
        return e;
      }
    },

    async updateAPCDelcon({commit}, {id, data}) {
      commit('changeAPCDelconBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successAPCDelconBtn');
          return response;
        });
      } catch (e) {
        commit('successAPCDelconBtn');
        return e;
      }
    },

    async updateCommentAPCDelcon({commit}, {id, data}) {
      commit('changeAPCDelconBtn');
      try {
        return await API.patch(`${prefix}/${id}/add-comment`, data).then((response) =>{
          commit('successAPCDelconBtn');
          return response;
        });
      } catch (e) {
        commit('successAPCDelconBtn');
        return e;
      }
    },

    async updateConsoUserAPCDelcon({commit}, {id, data}) {
      commit('changeAPCDelconBtn');
      try {
        return await API.patch(`${prefix}/${id}/add-consolidation-person`, data).then((response) =>{
          commit('successAPCDelconBtn');
          return response;
        });
      } catch (e) {
        commit('successAPCDelconBtn');
        return e;
      }
    },

    async deleteAPCDelcon({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successAPCDelconBtn');
        return e;
      }
    },

    async addAPCDelconFile({commit}, data) {
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${prefix}/upload-file`, data).then((response) =>{
          commit('successAPCDelconBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successAPCDelconBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async getAPCDelconFile({commit}, data) {
      commit('changeAPCDelconBtn')
      try {
        return await API.get(`${prefix}/${data.id}/marking/${data.type}`).then((response) =>{
          commit('successAPCDelconBtn')
          return response
        })
      } catch (e) {
        commit('successAPCDelconBtn')
        commit('setLoadingEnd')
        return e;
      }
    },

    async getAPCDelconFileBlob({commit}, data) {
      commit('changeAPCDelconBtn')
      let config = {
        headers: {'Access-Control-Expose-Headers' : 'Content-Disposition'},
        responseType: 'blob'
      }
      try {
        return await API.get(`${prefix}/${data.id}/marking/${data.type}`, config).then((response) =>{
          commit('successAPCDelconBtn')
          return response
        })
      } catch (e) {
        commit('successAPCDelconBtn')
        commit('setLoadingEnd')
        return e;
      }
    },

    async getAPCDelconProformFile({commit}, data) {
      commit('changeAPCDelconBtn');
      try {
        return await API.post(`${prefix}/export-product-invoice`, data).then((response) =>{
          commit('successAPCDelconBtn');
          return response;
        });
      } catch (e) {
        commit('successAPCDelconBtn');
        return e;
      }
    },
  }
}
