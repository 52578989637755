
import {routerPaths} from '../../../router/routerPaths'

const Calculator = () => import(/* webpackChunkName: "group-calculator" */ './components/Calculator/Calculator.vue')
const moduleKey = 'calculator'

export const CalculatorRoutes = [
  {
    path: routerPaths.calculator,
    component: Calculator,
    meta: {
      breadcrumbs: {
        key: [
          'calculator'
        ],
        items: {
          'calculator': {
            name: 'breadcrumbs_ShippingCalculator',
            link: routerPaths.calculator,
          },
        },
      },
      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_ShippingCalculator'
    },
  },
]
