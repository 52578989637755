import {API} from "../../axios/axiosMainUrl";

export const mainSiteSettings = {
  state: {
    mainSiteSettings: [],
    mainSiteSettingsItem: {},

    mainShippingSettings: {},

    mainSiteSettingsCommonList: [],
    mainSiteSettingsBtn: false,
    mainSiteSettingsLoading: true,
    nextMainSiteSettingsPage: false,
    mainSiteSettingsForPage: 25,
    mainSiteSettingsFilter: '',
  },

  mutations: {
    setMainSiteSettingsFilter: (state, payload) => {
      state.mainSiteSettingsFilter = payload
    },

    setMainSiteSettings: (state, payload) => {
      if(state.nextMainSiteSettingsPage) {
        state.mainSiteSettings = state.mainSiteSettings.concat(payload.mainSiteSettings)
      } else {
        state.mainSiteSettings = payload.mainSiteSettings
      }
    },

    setMainSiteSettingsCommonList: (state, payload) => {
      state.mainSiteSettingsCommonList = payload.mainSiteSettingsCommonList
    },

    setNextMainSiteSettingsPage: (state, payload) => {
      state.nextMainSiteSettingsPage = payload
    },

    setMainSiteSettingsItem: (state, payload) => {
      state.mainSiteSettingsItem = payload.mainSiteSettingsItem
    },

    setMainShippingSettings: (state, payload) => {
      state.mainShippingSettings = payload.mainShippingSettings
    },

    setMainSiteSettingsLoadingStart: (state) => {
      state.mainSiteSettingsLoading = true
    },

    setMainSiteSettingsLoadingEnd: (state) => {
      state.mainSiteSettingsLoading = false
    },

    changeMainSiteSettingsBtn: (state) => {
      state.mainSiteSettingsBtn = true
    },
    successMainSiteSettingsBtn: (state) => {
      state.mainSiteSettingsBtn = false
    },
  },

  getters: {
    getMainSiteSettingsFilter: state => {
      return state.mainSiteSettingsFilter
    },

    getMainSiteSettingsForPage: state => {
      return state.mainSiteSettingsForPage
    },

    getMainSiteSettings: state => {
      return state.mainSiteSettings
    },

    getMainSiteSettingsLoading: state => {
      return state.mainSiteSettingsLoading
    },


    getNextMainSiteSettingsPage: state => {
      return state.nextMainSiteSettingsPage
    },

    getMainSiteSettingsItem: state => {
      return state.mainSiteSettingsItem
    },

    getMainShippingSettings: state => {
      return state.mainShippingSettings
    },

    getMainSiteSettingsCommonList: state => {
      return state.mainSiteSettingsCommonList
    },

    getMainSiteSettingsBtn: state => {
      return state.mainSiteSettingsBtn
    },
  },

  actions: {

    async fetchMainSiteSettings({commit, getters}, filter = '') {
      if(!getters.getNextMainSiteSettingsPage)
        commit('setMainSiteSettingsLoadingStart')
      try {
        return await API.get(`/mainSiteSettings${filter}`).then((response) =>{
          commit('setMainSiteSettings',{mainSiteSettings: response.data.data.data})
          commit('setMainSiteSettingsCommonList',{mainSiteSettingsCommonList: response.data.data})
          commit('setMainSiteSettingsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setMainSiteSettingsLoadingEnd');
        return e;
      }
    },

    async getMainShippingSettings({commit}, filter = '') {
      commit('setMainSiteSettingsLoadingStart');
      try {
        return await API.get(`/settings/extra-charge${filter}`).then((response) =>{
          commit('setMainShippingSettings', {mainShippingSettings: response.data.data.data})
          commit('setMainSiteSettingsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setMainSiteSettingsLoadingEnd');
        return e;
      }
    },

    async setMainShippingSettings({commit}, data) {
      commit('setMainSiteSettingsLoadingStart');
      try {
        return await API.post(`/settings/extra-charge/update-settings`, data).then((response) =>{
          commit('setMainSiteSettingsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setMainSiteSettingsLoadingEnd');
        return e;
      }
    },

    async createMainSiteSettings({commit}, data) {
      commit('changeMainSiteSettingsBtn');
      try {
        return await API.post(`/mainSiteSettings`, data).then((response) =>{
          commit('successMainSiteSettingsBtn');
          return response;
        });
      } catch (e) {
        commit('successMainSiteSettingsBtn');
        return e;
      }
    },

    async updateMainSiteSettings({commit}, {id, data}) {
      commit('changeMainSiteSettingsBtn');
      try {
        return await API.put(`/mainSiteSettings/${id}`, data).then((response) =>{
          commit('successMainSiteSettingsBtn');
          return response;
        });
      } catch (e) {
        commit('successMainSiteSettingsBtn');
        return e;
      }
    },

    async deleteMainSiteSettings({commit}, id) {
      try {
        return await API.delete(`/mainSiteSettings/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successMainSiteSettingsBtn');
        return e;
      }
    },

    async getSetting({commit}, id) {
      commit('setMainSiteSettingsLoadingStart');
      try {
        return await API.get(`/settings/${id}`).then((response) =>{
          commit('setMainSiteSettingsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setMainSiteSettingsLoadingEnd');
        return e;
      }
    },

    async updateLockSystemForUsers({commit}, {id, data}) {
      commit('changeMainSiteSettingsBtn');
      try {
        return await API.put(`/settings/${id}`, data).then((response) =>{
          commit('successMainSiteSettingsBtn');
          return response;
        });
      } catch (e) {
        commit('successMainSiteSettingsBtn');
        return e;
      }
    },

    async unlockSystemForUsers({commit}) {
      commit('changeMainSiteSettingsBtn');
      try {
        return await API.get(`/settings/kagj90a35hga09hsdga8hgas03`).then((response) =>{
          commit('successMainSiteSettingsBtn');
          return response;
        });
      } catch (e) {
        commit('successMainSiteSettingsBtn');
        return e;
      }
    },

  }
}
