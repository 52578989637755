import {API} from "../../axios/axiosMainUrl";

const prefix = '/warehouse/storage/orders-bin'

export const orderBin = {
  state: {
    orderBin: [],
    orderBinItem: {},
    orderBinByUserId: [],
    orderBinCommonList: [],
    orderBinBtn: false,
    orderBinLoading: true,
    nextOrderBinPage: false,
    orderBinForPage: 500,
    orderBinFilter: '',
  },

  mutations: {
    setOrderBinFilter: (state, payload) => {
      state.orderBinFilter = payload
    },

    setOrderBin: (state, payload) => {
      if(state.nextOrderBinPage) {
        state.orderBin = state.orderBin.concat(payload.orderBin)
      } else {
        state.orderBin = payload.orderBin
      }
    },

    setOrderBinCommonList: (state, payload) => {
      state.orderBinCommonList = payload.orderBinCommonList
    },

    setNextOrderBinPage: (state, payload) => {
      state.nextOrderBinPage = payload
    },

    setOrderBinItem: (state, payload) => {
      state.orderBinItem = payload.orderBinItem
    },

    setOrderBinByUserId: (state, payload) => {
      state.orderBinByUserId = payload.orderBinByUserId
    },

    setOrderBinLoadingStart: (state) => {
      state.orderBinLoading = true
    },

    setOrderBinLoadingEnd: (state) => {
      state.orderBinLoading = false
    },

    changeOrderBinBtn: (state) => {
      state.orderBinBtn = true
    },
    successOrderBinBtn: (state) => {
      state.orderBinBtn = false
    },
  },

  getters: {
    getOrderBinFilter: state => {
      return state.orderBinFilter
    },

    getOrderBinForPage: state => {
      return state.orderBinForPage
    },

    getOrderBin: state => {
      return state.orderBin
    },

    getOrderBinLoading: state => {
      return state.orderBinLoading
    },


    getNextOrderBinPage: state => {
      return state.nextOrderBinPage
    },

    getOrderBinItem: state => {
      return state.orderBinItem
    },

    getOrderBinByUserId: state => {
      return state.orderBinByUserId
    },

    getOrderBinCommonList: state => {
      return state.orderBinCommonList
    },

    getOrderBinBtn: state => {
      return state.orderBinBtn
    },
  },

  actions: {

    async fetchOrderBin({commit, getters}, filter = '') {
      if(!getters.getNextOrderBinPage)
        commit('setOrderBinLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setOrderBin',{orderBin: response.data.data.data})
          commit('setOrderBinCommonList',{orderBinCommonList: response.data.data})
          commit('setOrderBinLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrderBinLoadingEnd');
        return e;
      }
    },

    async getOrderBin({commit}, id) {
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setOrderBinItem', {orderBinItem: response.data.data})
          return response;
        });
      } catch (e) {
        commit('setOrderBinLoadingEnd');
        return e;
      }
    },

    async createOrderBin({commit}, data) {
      commit('changeOrderBinBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successOrderBinBtn');
          return response;
        });
      } catch (e) {
        commit('successOrderBinBtn');
        return e;
      }
    },

    async updateOrderBin({commit}, {id, data}) {
      commit('changeOrderBinBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successOrderBinBtn');
          return response;
        });
      } catch (e) {
        commit('successOrderBinBtn');
        return e;
      }
    },

    async deleteOrderBin({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successOrderBinBtn');
        return e;
      }
    },

    /** PRINT **/
    async getOrderBinPrint({commit}, {data, type}) {
      commit('changeOrderBinBtn');
      try {
        return await API.get(`/warehouse/storage/orders-bin/marking/${type}`, {params: data}).then((response) =>{
          // console.log(response);
          commit('successOrderBinBtn');
          return response;
        });
      } catch (e) {
        commit('successOrderBinBtn');
        return e;
      }
    },

  }
}
