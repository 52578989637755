
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const DisposalOrdersTable = () => import(/* webpackChunkName: "group-disposal-orders-table" */ './components/DisposalOrdersTable/DisposalOrdersTable.vue')
const DisposalOrdersCreation = () => import(/* webpackChunkName: "group-disposal-orders-creating" */ './components/DisposalOrdersCreation/DisposalOrdersCreation.vue')
const DisposalOrdersEdit = () => import(/* webpackChunkName: "group-disposal-orders-edit" */ './components/DisposalOrdersEdit/DisposalOrdersEdit.vue')
const moduleKey = 'disposal-orders'

export const DisposalOrdersRoutes = [
  {
    path: routerPaths.warehouseDisposalOrders,
    component: DisposalOrdersTable,
    meta: {
      breadcrumbs: {
        key: [
          'warehouse',
          'warehouseDisposalOrders'
        ],
        items: {
          'warehouse': {
            name: 'menu_warehouse',
            link: routerPaths.warehouseDisposalOrders,
          },
          'warehouseDisposalOrders': {
            name: 'menu_disposalorDer',
            link: routerPaths.warehouseDisposalOrders,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_DISPOSAL_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_disposalorDer'
    },
  },
  {
    path: routerPaths.warehouseDisposalOrdersCreation,
    component: DisposalOrdersCreation,
    meta: {
      breadcrumbs: {
        key: [
          'warehouse',
          'warehouseDisposalOrders',
          'warehouseDisposalOrdersShop',
        ],
        items: {
          'warehouse': {
            name: 'menu_warehouse',
            link: routerPaths.warehouseDisposalOrders,
          },
          'warehouseDisposalOrders': {
            name: 'menu_writeOffProduct',
            link: routerPaths.warehouseDisposalOrders,
          },
          'warehouseDisposalOrdersShop': {
            name: 'breadcrumbs_NewDisposalOrder',
            link: routerPaths.warehouseDisposalOrdersCreation,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_DISPOSAL_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewDisposalOrder'
    },
  },
  {
    path: routerPaths.warehouseDisposalOrdersEdit,
    component: DisposalOrdersEdit,
    meta: {
      breadcrumbs: {
        key: [
          'warehouse',
          'warehouseDisposalOrders',
          'warehouseDisposalOrdersShop',
        ],
        items: {
          'warehouse': {
            name: 'menu_warehouse',
            link: routerPaths.warehouseDisposalOrders,
          },
          'warehouseDisposalOrders': {
            name: 'menu_writeOffProduct',
            link: routerPaths.warehouseDisposalOrders,
          },
          'warehouseDisposalOrdersShop': {
            name: 'breadcrumbs_NewDisposalOrder',
            link: routerPaths.warehouseDisposalOrdersEdit,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_DISPOSAL_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_EditDisposalOrder'
    },
  },
]
