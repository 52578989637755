<template>
  <div class="preloader-big-dots"></div>
</template>

<script>
  export default {
    name: "DotsShadowPreloader"
  }
</script>

<style scoped lang="scss">
  @import "../../../../scss/colors";

  .preloader-big-dots {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: relative;
    animation: loader 1s linear alternate infinite;
    top: 50%;
    margin: -38px auto 42px auto;
  }

  @keyframes loader {
    0% {
      box-shadow: -60px 40px 0 2px $accent, -30px 40px 0 0 rgba(205, 158, 100, 0.2), 0 40px 0 0 rgba(205, 158, 100, 0.2), 30px 40px 0 0 rgba(205, 158, 100, 0.2), 60px 40px 0 0 rgba(205, 158, 100, 0.2); }
    25% {
      box-shadow: -60px 40px 0 0 rgba(205, 158, 100, 0.2), -30px 40px 0 2px $accent, 0 40px 0 0 rgba(205, 158, 100, 0.2), 30px 40px 0 0 rgba(205, 158, 100, 0.2), 60px 40px 0 0 rgba(205, 158, 100, 0.2); }
    50% {
      box-shadow: -60px 40px 0 0 rgba(205, 158, 100, 0.2), -30px 40px 0 0 rgba(205, 158, 100, 0.2), 0 40px 0 2px $accent, 30px 40px 0 0 rgba(205, 158, 100, 0.2), 60px 40px 0 0 rgba(205, 158, 100, 0.2); }
    75% {
      box-shadow: -60px 40px 0 0 rgba(205, 158, 100, 0.2), -30px 40px 0 0 rgba(205, 158, 100, 0.2), 0 40px 0 0 rgba(205, 158, 100, 0.2), 30px 40px 0 2px $accent, 60px 40px 0 0 rgba(205, 158, 100, 0.2); }
    100% {
      box-shadow: -60px 40px 0 0 rgba(205, 158, 100, 0.2), -30px 40px 0 0 rgba(205, 158, 100, 0.2), 0 40px 0 0 rgba(205, 158, 100, 0.2), 30px 40px 0 0 rgba(205, 158, 100, 0.2), 60px 40px 0 2px $accent; } }
</style>
