import {API} from "../../axios/axiosMainUrl";

const prefix = '/heartysan/chat'
const prefixMessage = '/heartysan/chat-message'

export const heartysanChat = {
  state: {
    heartysanChat: [],
    heartysanChatItem: {},
    heartysanChatCommonList: [],
    heartysanChatBtn: false,
    heartysanChatLoading: true,
    nextHeartysanChatPage: false,
    heartysanChatForPage: 25,
    heartysanChatFilter: '',

    heartysanChatEvents: [],

    heartysanChatSocket: false,
    heartysanChatSocketChannel: 'hearty-chat',
    heartysanChatSocketEvent: 'hearty-chat.message',
  },

  mutations: {
    setHeartysanChatFilter: (state, payload) => {
      state.heartysanChatFilter = payload
    },

    setHeartysanChat: (state, payload) => {
      if(state.nextHeartysanChatPage) {
        state.heartysanChat = state.heartysanChat.concat(payload.heartysanChat)
      } else {
        state.heartysanChat = payload.heartysanChat
      }
    },

    setHeartysanChatCommonList: (state, payload) => {
      state.heartysanChatCommonList = payload.heartysanChatCommonList
    },

    setNextHeartysanChatPage: (state, payload) => {
      state.nextHeartysanChatPage = payload
    },

    setHeartysanChatItem: (state, payload) => {
      state.heartysanChatItem = payload.heartysanChatItem
    },

    setHeartysanChatLoadingStart: (state) => {
      state.heartysanChatLoading = true
    },

    setHeartysanChatLoadingEnd: (state) => {
      state.heartysanChatLoading = false
    },

    changeHeartysanChatBtn: (state) => {
      state.heartysanChatBtn = true
    },
    successHeartysanChatBtn: (state) => {
      state.heartysanChatBtn = false
    },

    setHeartysanChatSocket: (state) => {
      state.heartysanChatSocket = false
    },

    setHeartysanChatEvents: (state, payload) => {
      state.heartysanChatEvents = payload
    },
  },

  getters: {
    getHeartysanChatFilter: state => {
      return state.heartysanChatFilter
    },

    getHeartysanChatForPage: state => {
      return state.heartysanChatForPage
    },

    getHeartysanChat: state => {
      return state.heartysanChat
    },

    getHeartysanChatLoading: state => {
      return state.heartysanChatLoading
    },


    getNextHeartysanChatPage: state => {
      return state.nextHeartysanChatPage
    },

    getHeartysanChatItem: state => {
      return state.heartysanChatItem
    },

    getHeartysanChatCommonList: state => {
      return state.heartysanChatCommonList
    },

    getHeartysanChatBtn: state => {
      return state.heartysanChatBtn
    },

    getHeartysanChatSocketEvent: state => {
      return state.heartysanChatSocketEvent
    },

    getHeartysanChatSocketChannel: state => {
      return state.heartysanChatSocketChannel
    },

    getHeartysanChatEvents: state => {
      return state.heartysanChatEvents
    },
  },

  actions: {

    async fetchHeartysanChat({commit, getters}, filter = '') {
      if(!getters.getNextHeartysanChatPage)
        commit('setHeartysanChatLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setHeartysanChat',{heartysanChat: response.data.data.data})
          // commit('setHeartysanChatCommonList',{heartysanChatCommonList: response.data.data})
          commit('setHeartysanChatCommonList',{heartysanChatCommonList: {...response.data.data.meta, ...{
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev
              }}})
          commit('setHeartysanChatLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setHeartysanChatLoadingEnd');
        return e;
      }
    },

    async getHeartysanChat({commit}, id) {
      commit('setHeartysanChatLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setHeartysanChatItem', {heartysanChatItem: response.data.data})
          commit('setHeartysanChatLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setHeartysanChatLoadingEnd');
        return e;
      }
    },

    async createHeartysanChat({commit}, data) {
      commit('changeHeartysanChatBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successHeartysanChatBtn');
          return response;
        });
      } catch (e) {
        commit('successHeartysanChatBtn');
        return e;
      }
    },

    async createMessageHeartysanChat({commit}, {data, id}) {
      commit('changeHeartysanChatBtn');
      try {
        return await API.post(`${prefix}/${id}/add-message`, data).then((response) =>{
          commit('successHeartysanChatBtn');
          return response;
        });
      } catch (e) {
        commit('successHeartysanChatBtn');
        return e;
      }
    },

    async updateHeartysanChat({commit}, {id, data}) {
      commit('changeHeartysanChatBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successHeartysanChatBtn');
          return response;
        });
      } catch (e) {
        commit('successHeartysanChatBtn');
        return e;
      }
    },

    async deleteHeartysanChat({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successHeartysanChatBtn');
        return e;
      }
    },


    async readMessageHeartysanChat({commit}, data) {
      commit('changeHeartysanChatBtn');
      try {
        return await API.post(`${prefixMessage}/set-viewed`, data).then((response) =>{
          commit('successHeartysanChatBtn');
          return response;
        });
      } catch (e) {
        commit('successHeartysanChatBtn');
        return e;
      }
    },
  }
}
