import Vue from 'vue'
import App from './App.vue'
import {store} from './store/store'
import {i18n} from './language/language'
import {router} from './router/router'
import VTooltip from 'v-tooltip'
import Notifications from 'vue-notification'
import Vue2Filters from "vue2-filters";
import VueTheMask from 'vue-the-mask'
import money from 'v-money'
import {PERMISSIONS} from "./staticData/permissionsStatic";
import {ErrorLog} from "@/components/globalModels/ErrorLog";
import VueGtag from "vue-gtag";
import globalDirectives from './directives'

// import VueSocketIO from 'vue-socket.io'
//
// Vue.use(new VueSocketIO({
//   debug: true,
//   connection: 'http://sklad.back:6001',
//   vuex: {
//     store,
//   },
//   // options: { path: "/my-app/" } //Optional options
// }))

Vue.use(globalDirectives)

Vue.use(Notifications)
// import axios from 'axios'

Vue.prototype.PERMISSIONS = PERMISSIONS
Vue.prototype._ = require('lodash');

const { Query } = require("cogent-js/src/index");
Vue.prototype.Query = Query;

const VueUploadComponent = require('vue-upload-component')
Vue.component('file-upload', VueUploadComponent)

Vue.use(VTooltip)
Vue.config.productionTip = false

Vue.use(Vue2Filters)


Vue.use(VueTheMask)

Vue.use(money, {precision: 2})

Vue.use(VueGtag, {
  config: { id: "GTM-KPBF74S" }
});

const moment = require('moment')
Vue.use(require('vue-moment'), {
  moment
})

Vue.config.errorHandler = function (error, vm, info) {
  /**
   * Set error in log
   */
  console.error(error)

  /**
   * Save error in API
   */
  let ErrorLogModel = new ErrorLog()
  let url = vm?.$el ? vm?.$el.baseURI : location.href

  ErrorLogModel.setItem({
    baseURI: url,
    vueComponent: vm?.$vnode.tag,
    user: `Id: ${store.getters.GET_COMMON_AUTHORIZED?.user?.id}, email: ${store.getters.GET_COMMON_AUTHORIZED?.user?.email}`,
    userAgent: navigator.userAgent,
    error: error.stack,
    typeError: 'Vue config errorHandler;',
    routeHistory: vm.$store.getters.GET_ROUTER_HISTORY,
  })

  let data = ErrorLogModel.prepareSave()
  store.dispatch('createErrorLogs', data)

  console.log(info);
  // Обработка ошибки. В `info` подробности Vue-специфичной ошибки,
  // например, в каком хуке жизненного цикла произошла ошибка.
  // Доступно только в версиях 2.2.0+
}

// Vue.config.warnHandler = function (msg, vm, trace) {
//   // `trace` — это трассировка иерархии компонентов
//   console.log(111);
//   console.log(msg)
//   console.log(vm);
//   console.log(vm._data.data);
//   console.log(vm.$store);
//   console.log(vm._routerRoot._route.fullPath);
//   console.log(vm._routerRoot._route);
//   console.log(vm.$vnode.tag);
//   console.log(navigator.userAgent);
//   console.log(window.innerHeight);
//   console.log(window.innerWidth);
//   console.log(trace);
//   // screenshot
//   console.log(222);
// }

new Vue({
  render: h => h(App),
  store,
  i18n,
  router,
}).$mount('#app')
