export const datePickerTranslations = {
  state: {
    datePickerTranslations: {
      ru: {
        formatLocale: {
          firstDayOfWeek: 1,
          weekdaysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
          monthsShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
          months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
          monthBeforeYear: true
        },
      },
      ua: {
        formatLocale: {
          firstDayOfWeek: 1,
          weekdaysMin: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
          months: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
          monthsShort:  ['Січ', 'Лют', 'Бер', 'Квт', 'Трв', 'Чер', 'Лип', 'Сер', 'Вер', 'Жов', 'Лис', 'Грд'],
          monthBeforeYear: false,
        },
      }
    },
  },

  mutations: {
    setDatePickerTranslations: (state, payload) => {
      state.datePickerTranslations = payload.datePickerTranslations
    },
  },

  getters: {
    getDatePickerTranslations: state => {
      return state.datePickerTranslations
    }
  },

}
