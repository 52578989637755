import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const TrackingPayPalTable = () => import(/* webpackChunkName: "group-tracking-paypal" */ './components/TrackingPayPalTable/TrackingPayPalTable.vue')
const moduleKey = 'tracking-paypal'

export const TrackingPayPalRoutes = [
  {
    path: routerPaths.trackingForPayPal,
    component: TrackingPayPalTable,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'trackingForPayPal'
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.trackingForPayPal,
          },
          'trackingForPayPal': {
            name: 'breadcrumbs_trackingForPayPal',
            link: routerPaths.trackingForPayPal,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.TRACKING_FOR_PAYPAL_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_trackingForPayPal'
    },
  },
]
