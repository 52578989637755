<template>
  <PayPalCasesEditingSection
          :PC="PC"
          :createData="createData"
          @save="save"
          @reload="reload"
  />
</template>

<script>
  import {PayPalCases} from "../../models/PayPalCases";
  import {payPalCasesMixin} from "../../../../../mixins/payPalCasesMixins/payPalCasesMixin";
  import PayPalCasesEditingSection from "./PayPalCasesEditingSection/PayPalCasesEditingSection";

  export default {
    name: "PayPalCasesCreation",

    components:{
      PayPalCasesEditingSection,
    },

    mixins: [payPalCasesMixin],

    data() {
      return {
        PC: new PayPalCases({files: 5}),
        transactionUser: {},
        createData: null,
      }
    },

    mounted() {
      this.initPayPalCasesEdit()
    },

    methods: {

      reload() {
        this.PC = new PayPalCases({files: 5})
        this.initPayPalCasesEdit()
      },

      async downloadFileFromArray(array) {
        let downloadedFilesArray = []
        for (const item of array) {
          if (item?.type === 'small_image') {
            await this.getFileFromServer(item, 'image').then(document => {
              downloadedFilesArray.push(document)
            })
          }

          if (item?.extension === 'pdf') {
            await this.getFileFromServer(item, 'pdf').then(document => {
              downloadedFilesArray.push(document)
            })
          }
        }
        return downloadedFilesArray
      },

      initPayPalCasesEdit() {
        this.$store.dispatch('getPayPalCasesCreate').then(response => {
          this.createData = this.getRespData(response)

          this.PC.setId(this.$route.params.id)

          this.$store.dispatch('getPayPalCases', this.PC.getId()).then((response) => {
            let item = this.getRespData(response)
            this.PC.setItem(item, this)

            console.log(item);
            // this.getFileFromServer(item?.image_files).then(files => {
            //   this.PC.data.Files.setDownloadFiles(files, 'image')
            //   console.log(files);
            // })
            let files = [
              ...item?.image_files,
              ...item?.document_file
            ]

            console.log(files);

            this.PC.data.Files.setOrderFiles(files)

            this.downloadFileFromArray(files).then((documents) => {
              let filesObj = this.PC.data.Files
              filesObj.setDownloadFiles(documents)

            }).catch(error => this.createErrorLog(error))

          }).catch(error => this.createErrorLog(error))
        }).catch(error => this.createErrorLog(error))

        console.log(4444,this.PC.data.Files);
      },

    }

  }
</script>

<style scoped>

</style>
