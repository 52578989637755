<template>

  <div class="header__inner">
    <div
        class="header__support"
        v-if="$route.meta && $route.meta.moduleKey === 'dashboard'"
    >
      <div
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate}"
      >
        <span class="admin-edit" @click="editTranslate([
            'header_whatsappSupport',
            'header_whatsappSupportTel',
            'header_whatsappSupportHref',
            ])"></span>
        <a :href="$t('header_whatsappSupportHref.localization_value.value')" target="_blank" class="header__support-item">
          <span>{{$t('header_whatsappSupport.localization_value.value')}}</span> <b>{{$t('header_whatsappSupportTel.localization_value.value')}}</b>
        </a>
      </div>
      <div
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate}"
      >
        <span class="admin-edit" @click="editTranslate([
            'header_supportService',
            'header_supportServiceTel',
            'header_supportServiceHref',
            ])"></span>
        <a :href="$t('header_supportServiceHref.localization_value.value')" class="header__support-item">
          <span>{{$t('header_supportService.localization_value.value')}}</span> <b>{{$t('header_supportServiceTel.localization_value.value')}}</b>
        </a>
      </div>
    </div>

    <GuideBlock />

    <MultiSearch
        @multiSearch="(e, checkPressedKey, searchString) => { $emit('multiSearch', e, checkPressedKey, searchString) }"
    />

    <div class="header__right-btn">
<!--      <div class="header__notification">-->
<!--        <HeaderNotification/>-->
<!--      </div>-->
      <div class="header__settings">
        <HeaderSettings
            @logout="$emit('logout')"
        />
      </div>
      <div class="header__menu">
        <div class="header__menu-btn"
             @click="$store.commit('SET_MOBILE_MENU', !$store.getters.GET_MOBILE_MENU)"
        >
          <BurgerIco
              v-if="$store.getters.GET_MOBILE_MENU == false"
          />
          <BurgerCloseIco
              v-else
          />
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// import HeaderNotification from './components/HeaderAdminNotification/HeaderAdminNotification'
import HeaderSettings from './components/HeaderAdminSettings/HeaderAdminSettings'
import BurgerIco from '../../../../../public/img/header/burger-icon.svg?inline'
import BurgerCloseIco from '../../../../../public/img/header/burger-close-icon.svg?inline'
import MultiSearch from "./components/MultiSearch/MultiSearch";
import GuideBlock from "../../../coreComponents/GuideBlock/GuideBlock";

export default {
  name: "HeaderAdmin",

  components: {
    GuideBlock,
    MultiSearch,
    // HeaderNotification,
    HeaderSettings,
    BurgerIco,
    BurgerCloseIco
  },

  props: [
    'showMobileMenu'
  ],


  methods: {

    // editTranslate(key) {
    //   this.$store.dispatch('getTranslationsForKey', [key])
    // },
  },


}

</script>

<style lang="scss">

@import "../../../../scss/colors";
@import "../../../../scss/mixins/mixins";

.header {
  padding-top: 15px;
  z-index: 4;

  @include for-680 {
    padding-top: 0;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__gide-block {


    @include for-1120 {
      display: none;
    }
  }

  &__gide-link {
    font-size: 14px;
    line-height: 21px;
    color: $brown;
    transition: 0.3s;

    &:hover {
      color: $black;
    }

    &--separate {
      position: relative;
      margin-left: 8px;
      padding-left: 9px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 2px;
        display: block;
        width: 1px;
        height: 12px;
        background: $accent;
        opacity: 0.2;
      }
    }
  }

  &__gide-separator {

  }

  &__search {
    width: 100%;
    max-width: 502px;
    margin-left: 25px;

    @include for-1200 {
      max-width: 320px;
    }

    @include for-1120 {
      max-width: 235px;
    }

    @include for-680 {
      display: none;
    }
  }

  &__right-btn {
    display: flex;
    align-items: center;
  }

  &__menu {
    @include from-680 {
      display: none;
    }

    @include for-680 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      position: relative;
    }
  }

  &__menu-btn {
    width: 48px;
    height: 48px;
    background-color: $black;
    position: absolute;
    top: 0;
    left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 2;
  }

}


</style>
