import {API} from "../../axios/axiosMainUrl";

const prefix = 'expenses/types'

export const expensesTypes = {
  state: {
    expensesTypes: [],
    expensesTypesItem: {},
    expensesTypesCommonList: [],
    expensesTypesBtn: false,
    expensesTypesLoading: true,
    nextExpensesTypesPage: false,
    expensesTypesForPage: 25,
    expensesTypesFilter: '',
  },

  mutations: {
    setExpensesTypesFilter: (state, payload) => {
      state.expensesTypesFilter = payload
    },

    setExpensesTypes: (state, payload) => {
      if(state.nextExpensesTypesPage) {
        state.expensesTypes = state.expensesTypes.concat(payload.expensesTypes)
      } else {
        state.expensesTypes = payload.expensesTypes
      }
    },

    setExpensesTypesCommonList: (state, payload) => {
      state.expensesTypesCommonList = payload.expensesTypesCommonList
    },

    setNextExpensesTypesPage: (state, payload) => {
      state.nextExpensesTypesPage = payload
    },

    setExpensesTypesItem: (state, payload) => {
      state.expensesTypesItem = payload.expensesTypesItem
    },

    setExpensesTypesLoadingStart: (state) => {
      state.expensesTypesLoading = true
    },

    setExpensesTypesLoadingEnd: (state) => {
      state.expensesTypesLoading = false
    },

    changeExpensesTypesBtn: (state) => {
      state.expensesTypesBtn = true
    },
    successExpensesTypesBtn: (state) => {
      state.expensesTypesBtn = false
    },
  },

  getters: {
    getExpensesTypesFilter: state => {
      return state.expensesTypesFilter
    },

    getExpensesTypesForPage: state => {
      return state.expensesTypesForPage
    },

    getExpensesTypes: state => {
      return state.expensesTypes
    },

    getExpensesTypesLoading: state => {
      return state.expensesTypesLoading
    },


    getNextExpensesTypesPage: state => {
      return state.nextExpensesTypesPage
    },

    getExpensesTypesItem: state => {
      return state.expensesTypesItem
    },

    getExpensesTypesCommonList: state => {
      return state.expensesTypesCommonList
    },

    getExpensesTypesBtn: state => {
      return state.expensesTypesBtn
    },
  },

  actions: {

    async fetchExpensesTypes({commit, getters}, filter = '') {
      if(!getters.getNextExpensesTypesPage)
        commit('setExpensesTypesLoadingStart')
      try {
        return await API.get(`/${prefix}${filter}`).then((response) =>{
          commit('setExpensesTypes',{expensesTypes: response.data.data.data})
          commit('setExpensesTypesCommonList',{expensesTypesCommonList: response.data.data})
          commit('setExpensesTypesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setExpensesTypesLoadingEnd');
        return e;
      }
    },

    async fetchExpensesTypesNoCommit({commit}, filter = '') {
      try {
        return await API.get(`/${prefix}${filter}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setExpensesTypesLoadingEnd');
        return e;
      }
    },

    async getExpensesTypes({commit}, id) {
      try {
        return await API.get(`/${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setExpensesTypesItem', {expensesTypesItem: response.data.data})
          commit('setExpensesTypesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setExpensesTypesLoadingEnd');
        return e;
      }
    },

    async createExpensesTypes({commit}, data) {
      commit('changeExpensesTypesBtn');
      try {
        return await API.post(`/${prefix}`, data).then((response) =>{
          commit('successExpensesTypesBtn');
          return response;
        });
      } catch (e) {
        commit('successExpensesTypesBtn');
        return e;
      }
    },

    async updateExpensesTypes({commit}, {id, data}) {
      commit('changeExpensesTypesBtn');
      try {
        return await API.put(`/${prefix}/${id}`, data).then((response) =>{
          commit('successExpensesTypesBtn');
          return response;
        });
      } catch (e) {
        commit('successExpensesTypesBtn');
        return e;
      }
    },

    async deleteExpensesTypes({commit}, id) {
      try {
        return await API.delete(`/${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successExpensesTypesBtn');
        return e;
      }
    },

  }
}
