
import {routerPaths} from '../../../router/routerPaths'
import permissions from "@/router/middleware/permissions";
import {PERMISSIONS} from "@/staticData/permissionsStatic";

const FreshChatTable = () => import(/* webpackChunkName: "group-other-shippers" */ './components/FreshChatTable/FreshChatTable.vue')
const moduleKey = 'fresh-chat'


export const FreshChatRoutes = [
  {
    path: routerPaths.mainSettingsFreshChat,
    component: FreshChatTable,
    meta: {
      breadcrumbs: {
        key: [
          'menu_settings',
          'mainSettingsFreshChat'
        ],
        items: {
          'menu_settings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsFreshChat,
          },
          'mainSettingsFreshChat': {
            name: 'menu_freshChat',
            link: routerPaths.forbiddenKeywords,
          },
        },
      },
      moduleKey: moduleKey,

      middleware: [permissions],
      permissions: [PERMISSIONS.FRESH_CHAT_GENERAL],

      pageTitle: 'menu_freshChat'
    },
  }
]
