import {checkValid} from "./functions/ModelsValidate";

export function Guide() {

    this.checkValid = checkValid

    this.data = {
        id: '',
        chapterKey: '',
        linkVideo: '',
        linkEn: '',
        linkUa: '',
        linkRu: '',
        linkLv: '',
        linkPl: '',
        textEn: '',
        textUa: '',
        textRu: '',
        textLv: '',
        textPl: '',
    }

    this.validation = {
        id: false,
        chapterKey: false,
        linkVideo: false,
        linkEn: false,
        linkUa: false,
        linkRu: false,
        linkLv: false,
        linkPl: false,
        textEn: false,
        textUa: false,
        textRu: false,
        textLv: false,
        textPl: false,
    }

    this.validationTranslate = {
        id: '',
        chapterKey: '',
        linkVideo: '',
        linkEn: '',
        linkUa: '',
        linkRu: '',
        linkLv: '',
        linkPl: '',
        textEn: '',
        textUa: '',
        textRu: '',
        textLv: '',
        textPl: '',
    }

    this.validationTxt = {
        id: false,
        chapterKey: false,
        linkVideo: false,
        linkEn: false,
        linkUa: false,
        linkRu: false,
        linkLv: false,
        linkPl: false,
        textEn: false,
        textUa: false,
        textRu: false,
        textLv: false,
        textPl: false,
    }

    /**
     * Getters
     */
    this.getId = () => {
        return this.data.id
    }
    this.getChapterKey = () => {
        return this.data.chapterKey
    }
    this.getLinkVideo = () => {
        return this.data.linkVideo
    }
    this.getLinkEn = () => {
        return this.data.linkEn
    }
    this.getLinkRu = () => {
        return this.data.linkRu
    }
    this.getLinkUa = () => {
        return this.data.linkUa
    }
    this.getLinkLv = () => {
        return this.data.linkLv
    }
    this.getLinkPl = () => {
        return this.data.linkPl
    }
    this.getTextEn = () => {
        return this.data.textEn
    }
    this.getTextRu = () => {
        return this.data.textRu
    }
    this.getTextUa = () => {
        return this.data.textUa
    }
    this.getTextLv = () => {
        return this.data.textLv
    }
    this.getTextPl = () => {
        return this.data.textPl
    }

    /**
     * Setters
     */
    this.setId = (val) => {
        this.data.id = val
    }
    this.setChapterKey = (val) => {
        this.data.chapterKey = val
    }
    this.setLinkVideo = (val) => {
        this.data.linkVideo = val
    }
    this.setLinkEn = (val) => {
        this.data.linkEn = val
    }
    this.setLinkUa = (val) => {
        this.data.linkUa = val
    }
    this.setLinkRu = (val) => {
        this.data.linkRu = val
    }
    this.setLinkLv = (val) => {
        this.data.linkLv = val
    }
    this.setLinkPl = (val) => {
        this.data.linkPl = val
    }
    this.setTextEn = (val) => {
        this.data.textEn = val
    }
    this.setTextUa = (val) => {
        this.data.textUa = val
    }
    this.setTextRu = (val) => {
        this.data.textRu = val
    }
    this.setTextLv = (val) => {
        this.data.textLv = val
    }
    this.setTextPl = (val) => {
        this.data.textPl = val
    }
}

/**
 * Global Setters
 */

Guide.prototype.setGuideItem = function(item) {
    this.setId(item?.id)
    this.setChapterKey(item?.chapter_key)

    this.setLinkVideo(item?.link_video)
    this.setLinkEn(item?.link_en)
    this.setLinkRu(item?.link_ru)
    this.setLinkUa(item?.link_ua)
    this.setLinkLv(item?.link_lv)
    this.setLinkPl(item?.link_pl)

    this.setTextEn(item?.text_en)
    this.setTextRu(item?.text_ru)
    this.setTextUa(item?.text_ua)
    this.setTextLv(item?.text_lv)
    this.setTextPl(item?.text_pl)
}

/**
 * Functions
 */

Guide.prototype.prepareData = function () {
    return {
          chapter_key: this.getChapterKey(),
          link_video: this.getLinkVideo(),
          link_en: this.getLinkEn(),
          link_ua: this.getLinkUa(),
          link_ru: this.getLinkRu(),
          link_lv: this.getLinkLv(),
          link_pl: this.getLinkPl(),
          text_en: this.getTextEn(),
          text_ua: this.getTextUa(),
          text_ru: this.getTextRu(),
          text_lv: this.getTextLv(),
          text_pl: this.getTextPl(),
    }
}