import {API} from "../../axios/axiosMainUrl";

const prefix = '/oauth/static-tokens'

export const authTokens = {
  state: {
    authTokens: [],
    authTokensItem: {},
    authTokensByUserId: [],
    authTokensCommonList: [],
    authTokensBtn: false,
    authTokensLoading: true,
    nextAuthTokensPage: false,
    authTokensForPage: 25,
    authTokensFilter: '',
  },

  mutations: {
    setAuthTokensFilter: (state, payload) => {
      state.authTokensFilter = payload
    },

    setAuthTokens: (state, payload) => {
      if(state.nextAuthTokensPage) {
        state.authTokens = state.authTokens.concat(payload.authTokens)
      } else {
        state.authTokens = payload.authTokens
      }
    },

    setAuthTokensCommonList: (state, payload) => {
      state.authTokensCommonList = payload.authTokensCommonList
    },

    setNextAuthTokensPage: (state, payload) => {
      state.nextAuthTokensPage = payload
    },

    setAuthTokensItem: (state, payload) => {
      state.authTokensItem = payload.authTokensItem
    },

    setAuthTokensByUserId: (state, payload) => {
      state.authTokensByUserId = payload.authTokensByUserId
    },

    setAuthTokensLoadingStart: (state) => {
      state.authTokensLoading = true
    },

    setAuthTokensLoadingEnd: (state) => {
      state.authTokensLoading = false
    },

    changeAuthTokensBtn: (state) => {
      state.authTokensBtn = true
    },
    successAuthTokensBtn: (state) => {
      state.authTokensBtn = false
    },
  },

  getters: {
    getAuthTokensFilter: state => {
      return state.authTokensFilter
    },

    getAuthTokensForPage: state => {
      return state.authTokensForPage
    },

    getAuthTokens: state => {
      return state.authTokens
    },

    getAuthTokensLoading: state => {
      return state.authTokensLoading
    },


    getNextAuthTokensPage: state => {
      return state.nextAuthTokensPage
    },

    getAuthTokensItem: state => {
      return state.authTokensItem
    },

    getAuthTokensByUserId: state => {
      return state.authTokensByUserId
    },

    getAuthTokensCommonList: state => {
      return state.authTokensCommonList
    },

    getAuthTokensBtn: state => {
      return state.authTokensBtn
    },
  },

  actions: {

    async fetchAuthTokens({commit, getters}, filter = '') {
      if(!getters.getNextAuthTokensPage)
        commit('setAuthTokensLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setAuthTokens',{authTokens: response.data.data.data})
          commit('setAuthTokensCommonList',{authTokensCommonList: response.data.data})
          commit('setAuthTokensLoadingEnd');
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('setAuthTokensLoadingEnd');
        return e;
      }
    },

    async updateAuthTokens({commit}, {id, data}) {
      commit('changeAuthTokensBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successAuthTokensBtn');
          return response;
        });
      } catch (e) {
        commit('successAuthTokensBtn');
        return e;
      }
    },

    async generateAuthTokens({commit}, data) {
      commit('changeAuthTokensBtn');
      try {
        return await API.put(`${prefix}/generate`, data).then((response) =>{
          commit('successAuthTokensBtn');
          return response;
        });
      } catch (e) {
        commit('successAuthTokensBtn');
        return e;
      }
    },

  }
}
