import {API} from "../../axios/axiosMainUrl";

const prefix = 'payments/forbidden-payment-transaction';
const prefixPayments = 'payments';

export const forbiddenGoods = {
  state: {
    forbiddenGoods: [],
    forbiddenGoodsItem: {},
    forbiddenGoodsCommonList: [],
    forbiddenGoodsBtn: false,
    forbiddenGoodsLoading: true,
    nextForbiddenGoodsPage: false,
    forbiddenGoodsForPage: 25,
    forbiddenGoodsFilter: '',

    forbiddenGoodsSocket: false,
    forbiddenGoodsSocketChannel: 'forbidden-refund-channel',
    forbiddenGoodsSocketEvent: 'forbidden-status.updated',
  },

  mutations: {
    setForbiddenGoodsFilter: (state, payload) => {
      state.forbiddenGoodsFilter = payload
    },

    setForbiddenGoods: (state, payload) => {
      if(state.nextForbiddenGoodsPage) {
        state.forbiddenGoods = state.forbiddenGoods.concat(payload.forbiddenGoods)
      } else {
        state.forbiddenGoods = payload.forbiddenGoods
      }
    },

    setForbiddenGoodsCommonList: (state, payload) => {
      state.forbiddenGoodsCommonList = payload.forbiddenGoodsCommonList
    },

    setNextForbiddenGoodsPage: (state, payload) => {
      state.nextForbiddenGoodsPage = payload
    },

    setForbiddenGoodsItem: (state, payload) => {
      state.forbiddenGoodsItem = payload.forbiddenGoodsItem
    },

    setForbiddenGoodsLoadingStart: (state) => {
      state.forbiddenGoodsLoading = true
    },

    setForbiddenGoodsLoadingEnd: (state) => {
      state.forbiddenGoodsLoading = false
    },

    changeForbiddenGoodsBtn: (state) => {
      state.forbiddenGoodsBtn = true
    },
    successForbiddenGoodsBtn: (state) => {
      state.forbiddenGoodsBtn = false
    },

    setForbiddenGoodsSocket: (state, payload) => {
      state.forbiddenGoodsSocket = payload
    },
  },

  getters: {
    getForbiddenGoodsFilter: state => {
      return state.forbiddenGoodsFilter
    },

    getForbiddenGoodsForPage: state => {
      return state.forbiddenGoodsForPage
    },

    getForbiddenGoods: state => {
      return state.forbiddenGoods
    },

    getForbiddenGoodsLoading: state => {
      return state.forbiddenGoodsLoading
    },


    getNextForbiddenGoodsPage: state => {
      return state.nextForbiddenGoodsPage
    },

    getForbiddenGoodsItem: state => {
      return state.forbiddenGoodsItem
    },

    getForbiddenGoodsCommonList: state => {
      return state.forbiddenGoodsCommonList
    },

    getForbiddenGoodsBtn: state => {
      return state.forbiddenGoodsBtn
    },

    getForbiddenGoodsSocket: state => {
      return state.forbiddenGoodsSocket
    },

    getForbiddenGoodsSocketChannel: state => {
      return state.forbiddenGoodsSocketChannel
    },

    getForbiddenGoodsSocketEvent: state => {
      return state.forbiddenGoodsSocketEvent
    },
  },

  actions: {

    async fetchForbiddenGoods({commit, getters}, filter = '') {
      if(!getters.getNextForbiddenGoodsPage)
        commit('setForbiddenGoodsLoadingStart')
      try {
        return await API.get(`/${prefix}${filter}`).then((response) =>{
          commit('setForbiddenGoods',{forbiddenGoods: response.data.data.data})
          commit('setForbiddenGoodsCommonList',{forbiddenGoodsCommonList: response.data.data})
          commit('setForbiddenGoodsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setForbiddenGoodsLoadingEnd');
        return e;
      }
    },

    async getForbiddenGoods({commit}, id) {
      // commit('setForbiddenGoodsLoadingStart');
      try {
        return await API.get(`/${prefixPayments}/${id}`).then((response) =>{
          console.log(response)
          commit('setForbiddenGoodsItem', {forbiddenGoodsItem: response.data.data})
          commit('setForbiddenGoodsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setForbiddenGoodsLoadingEnd');
        return e;
      }
    },

    async createForbiddenGoods({commit}, data) {
      commit('changeForbiddenGoodsBtn');
      try {
        return await API.post(`/${prefix}`, data).then((response) =>{
          commit('successForbiddenGoodsBtn');
          return response;
        });
      } catch (e) {
        commit('successForbiddenGoodsBtn');
        return e;
      }
    },

    async approveForbiddenGoods({commit}, data) {
      commit('changeForbiddenGoodsBtn');
      try {
        return await API.post(`/${prefix}/approve`, data).then((response) =>{
          commit('successForbiddenGoodsBtn');
          return response;
        });
      } catch (e) {
        commit('successForbiddenGoodsBtn');
        return e;
      }
    },

    async makeRefundForbiddenGoods({commit}, data) {
      commit('changeForbiddenGoodsBtn');
      try {
        return await API.post(`/${prefix}/make-refund`, data).then((response) =>{
          commit('successForbiddenGoodsBtn');
          return response;
        });
      } catch (e) {
        commit('successForbiddenGoodsBtn');
        return e;
      }
    },

    async updateForbiddenGoods({commit}, {id, data}) {
      commit('changeForbiddenGoodsBtn');
      try {
        return await API.put(`/${prefix}/${id}`, data).then((response) =>{
          commit('successForbiddenGoodsBtn');
          return response;
        });
      } catch (e) {
        commit('successForbiddenGoodsBtn');
        return e;
      }
    },

    async deleteForbiddenGoods({commit}, id) {
      try {
        return await API.delete(`/${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successForbiddenGoodsBtn');
        return e;
      }
    },

    async getExportForbiddenGoods({commit}, {filter, exportType}) {
      commit('setExportBtnLoadingStart')
      commit('setLoadingEnd')
      try {
        return await API.get(`${prefix}/export/${exportType}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

  }
}
