
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const MarketplaceProductsShop = () => import(/* webpackChunkName: "group-marketplace-products" */ './components/MarketplaceProductsShop/MarketplaceProductsShop.vue')
const MarketplaceProductsCreation = () => import(/* webpackChunkName: "group-marketplace-products" */ './components/MarketplaceProductsCreation/MarketplaceProductsCreation.vue')
const MarketplaceProductsEdit = () => import(/* webpackChunkName: "group-marketplace-products" */ './components/MarketplaceProductsEdit/MarketplaceProductsEdit.vue')
const moduleKey = 'marketplace-products'


export const MarketplaceProductsRoutes = [
  {
    path: routerPaths.marketplaceProducts,
    component: MarketplaceProductsShop,
    meta: {
      breadcrumbs: {
        key: [
          'marketplace',
          'products'
        ],
        items: {
          'marketplace': {
            name: 'menu_marketplace',
            link: routerPaths.marketplaceProducts,
          },
          'products': {
            name: 'menu_marketplaceProducts',
            link: routerPaths.marketplaceProducts,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.UKRAINIAN_TREASURES_ACCESS],

      moduleKey: moduleKey,
      pageTitle: 'menu_marketplaceProducts'
    },
  },

  {
    path: routerPaths.marketplaceProductsCreate,
    component: MarketplaceProductsCreation,
    meta: {
      breadcrumbs: {
        key: [
          'marketplace',
          'products',
          'productsCreation'
        ],
        items: {
          'marketplace': {
            name: 'menu_marketplace',
            link: routerPaths.marketplaceProducts,
          },
          'products': {
            name: 'menu_marketplaceProducts',
            link: routerPaths.marketplaceProducts,
          },
          'productsCreation': {
            name: 'breadcrumbs_marketplaceProductsNew',
            link: routerPaths.marketplaceProductsCreate,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.UKRAINIAN_TREASURES_ACCESS],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_marketplaceProductsNew'
    },
  },
  {
    path: routerPaths.marketplaceProductsEdit,
    component: MarketplaceProductsEdit,
    meta: {
      breadcrumbs: {
        key: [
          'marketplace',
          'products',
          'productsEdit'
        ],
        items: {
          'marketplace': {
            name: 'menu_marketplace',
            link: routerPaths.marketplaceProducts,
          },
          'products': {
            name: 'menu_marketplaceProducts',
            link: routerPaths.marketplaceProducts,
          },
          'productsEdit': {
            name: 'breadcrumbs_marketplaceProductsEdit',
            link: routerPaths.marketplaceProductsItem,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.UKRAINIAN_TREASURES_ACCESS],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_marketplaceProductsEdit'
    },
  },
]
