
import {routerPaths} from '../../../router/routerPaths'

const InactiveFBMUsersTable = () => import(/* webpackChunkName: "inactive-fbm-users" */ './components/InactiveFBMUsersTable/InactiveFBMUsersTable.vue')
const moduleKey = 'inactive-fbm-users'


export const InactiveFBMUsersRoutes = [
  {
    path: routerPaths.inactiveFBMUsers,
    component: InactiveFBMUsersTable,
    meta: {
      breadcrumbs: {
        key: [
          'menu_reports',
          'mainSettingsInactiveFBMUsers'
        ],
        items: {
          'menu_reports': {
            name: 'menu_reports',
            link: routerPaths.inactiveFBMUsers,
          },
          'mainSettingsInactiveFBMUsers': {
            name: 'menu_inactiveFBMUsers',
            link: routerPaths.inactiveFBMUsers,
          },
        },
      },

      moduleKey: moduleKey,
      pageTitle: 'menu_inactiveFBMUsers'
    },
  }
]
