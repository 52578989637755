import {API} from "../../axios/axiosMainUrl";

const prefix = '/activity'

export const historyChanges = {
  state: {
    historyChanges: [],
    historyChangesItem: {},
    historyChangesCommonList: [],
    historyChangesBtn: false,
    historyChangesLoading: true,
    nextHistoryChangesPage: false,
    historyChangesForPage: 25,
    historyChangesFilter: '',
  },

  mutations: {
    setHistoryChangesFilter: (state, payload) => {
      state.historyChangesFilter = payload
    },

    setHistoryChanges: (state, payload) => {
      if(state.nextHistoryChangesPage) {
        state.historyChanges = state.historyChanges.concat(payload.historyChanges)
      } else {
        state.historyChanges = payload.historyChanges
      }
    },

    setHistoryChangesCommonList: (state, payload) => {
      state.historyChangesCommonList = payload.historyChangesCommonList
    },

    setNextHistoryChangesPage: (state, payload) => {
      state.nextHistoryChangesPage = payload
    },

    setHistoryChangesItem: (state, payload) => {
      state.historyChangesItem = payload.historyChangesItem
    },

    setHistoryChangesLoadingStart: (state) => {
      state.historyChangesLoading = true
    },

    setHistoryChangesLoadingEnd: (state) => {
      state.historyChangesLoading = false
    },

    changeHistoryChangesBtn: (state) => {
      state.historyChangesBtn = true
    },
    successHistoryChangesBtn: (state) => {
      state.historyChangesBtn = false
    },
  },

  getters: {
    getHistoryChangesFilter: state => {
      return state.historyChangesFilter
    },

    getHistoryChangesForPage: state => {
      return state.historyChangesForPage
    },

    getHistoryChanges: state => {
      return state.historyChanges
    },

    getHistoryChangesLoading: state => {
      return state.historyChangesLoading
    },


    getNextHistoryChangesPage: state => {
      return state.nextHistoryChangesPage
    },

    getHistoryChangesItem: state => {
      return state.historyChangesItem
    },

    getHistoryChangesCommonList: state => {
      return state.historyChangesCommonList
    },

    getHistoryChangesBtn: state => {
      return state.historyChangesBtn
    },
  },

  actions: {

    async fetchHistoryChanges({commit, getters}, filter = '') {
      if(!getters.getNextHistoryChangesPage)
        commit('setHistoryChangesLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setHistoryChanges',{historyChanges: response.data.data.data})
          commit('setHistoryChangesCommonList',{historyChangesCommonList: response.data.data})
          commit('setHistoryChangesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setHistoryChangesLoadingEnd');
        return e;
      }
    },

    async getHistoryChanges({commit}, id) {
      commit('setHistoryChangesLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setHistoryChangesItem', {historyChangesItem: response.data.data})
          commit('setHistoryChangesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setHistoryChangesLoadingEnd');
        return e;
      }
    },

    async getHistoryChangesHistory({commit}, {currentId, aboveId}) {
      commit('setHistoryChangesLoadingStart');
      try {
        return await API.get(`${prefix}/history/${currentId}${aboveId ? '/' + aboveId : ''}`).then((response) =>{
          console.log(response)
          commit('setHistoryChangesItem', {historyChangesItem: response.data.data})
          commit('setHistoryChangesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setHistoryChangesLoadingEnd');
        return e;
      }
    },

    async createHistoryChanges({commit}, data) {
      commit('changeHistoryChangesBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successHistoryChangesBtn');
          return response;
        });
      } catch (e) {
        commit('successHistoryChangesBtn');
        return e;
      }
    },

    async updateHistoryChanges({commit}, {id, data}) {
      commit('changeHistoryChangesBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successHistoryChangesBtn');
          return response;
        });
      } catch (e) {
        commit('successHistoryChangesBtn');
        return e;
      }
    },

    async deleteHistoryChanges({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successHistoryChangesBtn');
        return e;
      }
    },

  }
}
