import {
  ACCOUNT_BANK_CUSTOM_BANK_ACCOUNT,
  ACCOUNT_BANK_SKLADUSA_USER_ACCOUNT,
  ACCOUNT_BANK_TYPE,
  ACCOUNT_TYPE
} from "../../staticData/staticVariables";


export const accountsHelperMixin = {
  methods: {
    isIndividualBank(item){
      return ACCOUNT_TYPE.INDIVIDUAL === item.type_id || item.type_id === 3
    },

    isBusinessBank(item){
      return ACCOUNT_TYPE.BUSINESS === item.type_id
    },
  }
}

export const accountMixin = {

  methods: {

    checkPermission(){
      return this.checkOrderTypePermission({
        types: {
          [ACCOUNT_BANK_TYPE.CUSTOM_BANK.name]: this.PERMISSIONS.ACCOUNT_BUSINESS,
          [ACCOUNT_BANK_TYPE.UKRAINIAN_BANK.name]: this.PERMISSIONS.ACCOUNT_PERSONAL_UKRAINE,
          [ACCOUNT_BANK_TYPE.WORLD_BANK.name]: this.PERMISSIONS.ACCOUNT_PERSONAL_WORLD,
          [ACCOUNT_BANK_TYPE.CUSTOM_ACCOUNT.name]: this.PERMISSIONS.ACCOUNT_EMAIL,
        },
        currentType: this.$route.params.type
      })
    },

    save(edit = false){
      /**
       * Set Account bank type
       */

      let accountBankType
      for(let [, value] of Object.entries(ACCOUNT_BANK_TYPE)){
        value.name === this.$route.params.type ? accountBankType = value.id : null
      }

      /**
       * Set Account type
       */
      switch (accountBankType) {
        case ACCOUNT_BANK_TYPE.CUSTOM_BANK.id:
          this.account.setTypeId(ACCOUNT_TYPE.BUSINESS)
          break
        default:
          this.account.setTypeId(ACCOUNT_TYPE.INDIVIDUAL)
          break
      }

      /**
       * Set Account bank id
       */

      switch (accountBankType) {
        case ACCOUNT_BANK_TYPE.CUSTOM_BANK.id:
          this.account.setBankId(ACCOUNT_BANK_CUSTOM_BANK_ACCOUNT.id)
          break
        case ACCOUNT_BANK_TYPE.CUSTOM_ACCOUNT.id:
          this.account.setBankId(ACCOUNT_BANK_SKLADUSA_USER_ACCOUNT.id)
          break
      }

      // Choose fields for validation form
      let fieldsValid = this.account.getFieldsValid()

      console.log(fieldsValid);

      if(!this.account.firstValidation(fieldsValid)) return

      // if(this.account.firstValidation(fieldsValid)){
      //   console.log(666, this.account.prepareSave());
      //   return
      // }

      let data = this.account.prepareSave()
      let saveType = 'createAccount'

      if (edit) {
        data = {
          id: this.account.getId(),
          data: data
        }
        saveType = 'updateAccount'
      }

      this.$store.dispatch(saveType, data).then(response => {

        // response.status = response?.data?.status || response?.response?.status

        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            if (edit) {
              this.openNotify('success', 'common_notificationRecordChanged')
            } else {
              this.openNotify('success', 'common_notificationRecordCreated')
            }
            this.$router.push(this.$store.getters.GET_PATHS.accounts)
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },

    saveIban(id) {
      let fieldsValid = this.account.getFieldsValid()

      if(!this.account.firstValidation(fieldsValid)) return

      let data = {
        id: id,
        data: {
          iban: this.account.data.iban
        }
      }
      let saveType = 'updateAccountIban'

      this.$store.dispatch(saveType, data).then(response => {

        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            this.openNotify('success', 'common_notificationRecordChanged')
            this.$emit('close')
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },


    removeItem(id){
      let text = {
        title: 'account_DeleteAccount',
        txt: '',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        this.$store.dispatch('deleteAccount', id).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

            this.$emit('reload')
            this.openNotify('success', 'common_notificationRecordDeleted')

          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

    changeUser(user) {
      this.account.setAccountUser(user)
    },

  },

}
