import {API} from "../../axios/axiosMainUrl";

const prefix = '/report/order-express-poshta'

export const expressPoshtaReport = {
  state: {
    expressPoshtaReport: [],
    expressPoshtaReportItem: {},
    expressPoshtaReportCommonList: [],
    expressPoshtaReportBtn: false,
    expressPoshtaReportLoading: true,
    nextExpressPoshtaReportPage: false,
    expressPoshtaReportForPage: 25,
    expressPoshtaReportFilter: '',
  },

  mutations: {
    setExpressPoshtaReportFilter: (state, payload) => {
      state.expressPoshtaReportFilter = payload
    },

    setExpressPoshtaReport: (state, payload) => {
      if(state.nextExpressPoshtaReportPage) {
        state.expressPoshtaReport = state.expressPoshtaReport.concat(payload.expressPoshtaReport)
      } else {
        state.expressPoshtaReport = payload.expressPoshtaReport
      }
    },

    setExpressPoshtaReportCommonList: (state, payload) => {
      state.expressPoshtaReportCommonList = payload.expressPoshtaReportCommonList
    },

    setNextExpressPoshtaReportPage: (state, payload) => {
      state.nextExpressPoshtaReportPage = payload
    },

    setExpressPoshtaReportItem: (state, payload) => {
      state.expressPoshtaReportItem = payload.expressPoshtaReportItem
    },

    setExpressPoshtaReportLoadingStart: (state) => {
      state.expressPoshtaReportLoading = true
    },

    setExpressPoshtaReportLoadingEnd: (state) => {
      state.expressPoshtaReportLoading = false
    },

    changeExpressPoshtaReportBtn: (state) => {
      state.expressPoshtaReportBtn = true
    },
    successExpressPoshtaReportBtn: (state) => {
      state.expressPoshtaReportBtn = false
    },
  },

  getters: {
    getExpressPoshtaReportFilter: state => {
      return state.expressPoshtaReportFilter
    },

    getExpressPoshtaReportForPage: state => {
      return state.expressPoshtaReportForPage
    },

    getExpressPoshtaReport: state => {
      return state.expressPoshtaReport
    },

    getExpressPoshtaReportLoading: state => {
      return state.expressPoshtaReportLoading
    },


    getNextExpressPoshtaReportPage: state => {
      return state.nextExpressPoshtaReportPage
    },

    getExpressPoshtaReportItem: state => {
      return state.expressPoshtaReportItem
    },

    getExpressPoshtaReportCommonList: state => {
      return state.expressPoshtaReportCommonList
    },

    getExpressPoshtaReportBtn: state => {
      return state.expressPoshtaReportBtn
    },
  },

  actions: {

    async fetchExpressPoshtaReport({commit, getters}, {filter = '', type = false}) {
      if(!getters.getNextExpressPoshtaReportPage)
        commit('setExpressPoshtaReportLoadingStart')
      try {
        return await API.get(`${prefix}${type ? `/${type}` : ''}${filter}`).then((response) =>{
          commit('setExpressPoshtaReport',{expressPoshtaReport: response.data.data.data})
          commit('setExpressPoshtaReportCommonList',{expressPoshtaReportCommonList: response.data.data})
          commit('setExpressPoshtaReportLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setExpressPoshtaReportLoadingEnd');
        return e;
      }
    },

    async getExpressPoshtaReport({commit}, id) {
      commit('setExpressPoshtaReportLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setExpressPoshtaReportItem', {expressPoshtaReportItem: response.data.data})
          commit('setExpressPoshtaReportLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setExpressPoshtaReportLoadingEnd');
        return e;
      }
    },

    async createExpressPoshtaReport({commit}, data) {
      commit('changeExpressPoshtaReportBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successExpressPoshtaReportBtn');
          return response;
        });
      } catch (e) {
        commit('successExpressPoshtaReportBtn');
        return e;
      }
    },

    async updateExpressPoshtaReport({commit}, {id, data}) {
      commit('changeExpressPoshtaReportBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successExpressPoshtaReportBtn');
          return response;
        });
      } catch (e) {
        commit('successExpressPoshtaReportBtn');
        return e;
      }
    },

    async deleteExpressPoshtaReport({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successExpressPoshtaReportBtn');
        return e;
      }
    },

  }
}
