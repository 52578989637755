import {API} from "../../axios/axiosMainUrl";

const prefixExpress = '/express-orders'
const prefixApiOrderExpress = '/api-order-express'

export const express = {
  state: {
    express: [],
    expressItem: {},
    expressCommonList: [],
    expressBtn: false,
    expressLoading: true,
    expressLoadingPrice: false,
    nextExpressPage: false,
    expressForPage: 25,
    expressFilter: '',

    expressOrderPriceFromTrn: '',
  },

  mutations: {
    setExpressFilter: (state, payload) => {
      state.expressFilter = payload
    },

    setExpress: (state, payload) => {
      if(state.nextExpressPage) {
        state.express = state.express.concat(payload.express)
      } else {
        state.express = payload.express
      }
    },

    setExpressCommonList: (state, payload) => {
      state.expressCommonList = payload.expressCommonList
    },

    setNextExpressPage: (state, payload) => {
      state.nextExpressPage = payload
    },

    setExpressItem: (state, payload) => {
      state.expressItem = payload.expressItem
    },

    setExpressLoadingStart: (state) => {
      state.expressLoading = true
    },

    setExpressLoadingEnd: (state) => {
      state.expressLoading = false
    },

    setExpressLoadingPriceStart: (state) => {
      state.expressLoadingPrice = true
    },

    setExpressLoadingPriceEnd: (state) => {
      state.expressLoadingPrice = false
    },

    changeExpressBtn: (state) => {
      state.expressBtn = true
    },
    successExpressBtn: (state) => {
      state.expressBtn = false
    },

    setOrderPriceFromTrn: (state, payload) => {
      state.expressOrderPriceFromTrn = payload
    },
  },

  getters: {
    getExpressFilter: state => {
      return state.expressFilter
    },

    getExpressForPage: state => {
      return state.expressForPage
    },

    getExpress: state => {
      return state.express
    },

    getExpressLoading: state => {
      return state.expressLoading
    },

    getExpressLoadingPrice: state => {
      return state.expressLoadingPrice
    },


    getNextExpressPage: state => {
      return state.nextExpressPage
    },

    getExpressItem: state => {
      return state.expressItem
    },

    getExpressCommonList: state => {
      return state.expressCommonList
    },

    getExpressBtn: state => {
      return state.expressBtn
    },
  },

  actions: {

    async fetchExpress({commit, getters}, {filter = '', noCommit = false}) {
      if(!getters.getNextExpressPage && !noCommit)
        commit('setExpressLoadingStart')
      try {
        return await API.get(`${prefixExpress}${filter}`).then((response) =>{
          if (!noCommit) {
            commit('setExpress',{express: response.data.data.data})
            // commit('setExpressCommonList',{expressCommonList: response.data.data})
            commit('setExpressCommonList',{expressCommonList: {...response.data.data.meta, ...{
                  addition_export: response.data.data.addition_export,
                  next_page_url: response.data.data.links.next,
                  first_page_url: response.data.data.links.first,
                  last_page_url: response.data.data.links.last,
                  prev_page_url: response.data.data.links.prev
                }}})
            commit('setExpressLoadingEnd');
          }
          return response;
        });
      } catch (e) {
        commit('setExpressLoadingEnd');
        return e;
      }
    },

    async getExpress({commit}, id) {
      // commit('setExpressLoadingStart');
      try {
        return await API.get(`${prefixExpress}/${id}`).then((response) =>{
          console.log(response)
          commit('setExpressItem', {expressItem: response.data.data})
          // commit('setExpressLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setExpressLoadingEnd');
        return e;
      }
    },

    async getExpressCreate({commit}, url = '') {
      try {
        return await API.get(`${prefixExpress}/create${url}`).then((response) => {
          return response;
        });
      } catch (e) {
        commit('setExpressLoadingEnd');
        return e;
      }
    },

    async getEditExpress({commit}, id) {
      // commit('setExpressLoadingStart');
      try {
        return await API.get(`${prefixExpress}/${id}/edit`).then((response) =>{
          commit('setExpressItem', {expressItem: response.data.data})
          // commit('setExpressLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setExpressLoadingEnd');
        return e;
      }
    },

    async copyExpress({commit}, data) {
      commit('changeExpressBtn');
      try {
        return await API.post(`${prefixExpress}/create-copy`, data).then((response) =>{
          commit('successExpressBtn');
          return response;
        });
      } catch (e) {
        commit('successExpressBtn');
        return e;
      }
    },

    async createExpress({commit}, data) {
      commit('changeExpressBtn');
      try {
        return await API.post(`${prefixExpress}`, data).then((response) =>{
          commit('successExpressBtn');
          return response;
        });
      } catch (e) {
        commit('successExpressBtn');
        return e;
      }
    },

    async addPhotoToExpress({commit}, data) {
      commit('changeExpressBtn');
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${prefixExpress}/upload-confirmation-document`, data).then((response) =>{
          commit('successExpressBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successExpressBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async addVatNumberFileExpress({commit}, data) {
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${prefixExpress}/upload-vat-confirmation-document`, data).then((response) =>{
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successExpressBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async getVatNumberPdf({commit}, data) {
      console.log(data);
      try {
        return await API.post(`${prefixExpress}/download-vat-confirmation-document`, data).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successExpressBtn');
        return e;
      }
    },

    async updateExpress({commit}, {id, data}) {
      commit('changeExpressBtn');
      try {
        return await API.put(`${prefixExpress}/${id}`, data).then((response) =>{
          commit('successExpressBtn');
          return response;
        });
      } catch (e) {
        commit('successExpressBtn');
        return e;
      }
    },

    async upsertExpress({commit}, data) {
      commit('changeExpressBtn');
      try {
        return await API.put(`${prefixExpress}/upsert`, data).then((response) =>{
          commit('successExpressBtn');
          return response;
        });
      } catch (e) {
        commit('successExpressBtn');
        return e;
      }
    },

    async deleteExpress({commit}, id) {
      try {
        return await API.delete(`${prefixExpress}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successExpressBtn');
        return e;
      }
    },

    async addNewPoshtaTrackNumExpress({commit}, {data: data, id: id}) {
      commit('changeExpressBtn');
      try {
        return await API.patch(`${prefixExpress}/new-post-track-number/${id}`, data).then((response) =>{
          commit('successExpressBtn');
          return response;
        });
      } catch (e) {
        commit('successExpressBtn');
        return e;
      }
    },

    async addTrackNumExpress({commit}, {data: data, id: id}) {
      try {
        return await API.patch(`${prefixExpress}/track-number/${id}`, data).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successExpressBtn');
        return e;
      }
    },

    async callCourier({commit}, {data: data, type: type}) {
      commit('changeExpressBtn');
      try {
        return await API.post(`${prefixApiOrderExpress}/${type}/call-courier`, data).then((response) =>{
          console.log(response);
          commit('successExpressBtn');
          return response;
        });
      } catch (e) {
        commit('successExpressBtn');
        return e;
      }
    },

    async cancelCourier({commit}, {data: data, type: type}) {
      commit('changeExpressBtn');
      try {
        return await API.post(`${prefixApiOrderExpress}/${type}/cancel-courier`, data).then((response) =>{
          console.log(response);
          commit('successExpressBtn');
          return response;
        });
      } catch (e) {
        commit('successExpressBtn');
        return e;
      }
    },

    async callingCourierPopup({commit}, {data: data, type: type}) {
      commit('changeExpressBtn');
      try {
        return await API.post(`${prefixApiOrderExpress}/${type}/call-courier`, data).then((response) =>{
          console.log(response);
          commit('successExpressBtn');
          return response;
        });
      } catch (e) {
        commit('successExpressBtn');
        return e;
      }
    },

    async getShippingCost({commit}, {data: data, type: type}) {
      commit('changeExpressBtn');
      commit('setExpressLoadingPriceStart');
      try {
        return await API.post(`${prefixApiOrderExpress}/${type}/${type}-price`, data).then((response) =>{
          console.log(response);
          commit('successExpressBtn');
          commit('setExpressLoadingPriceEnd');
          return response;
        });
      } catch (e) {
        commit('successExpressBtn');
        commit('setExpressLoadingPriceEnd');
        return e;
      }
    },

    async getIdentifyExpressOrderFromTrn({commit}, {data: data}) {
      try {
        return await API.post(`${prefixExpress}/identify-related-instance`, data).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successExpressBtn');
        return e;
      }
    },

    async makeLabel({commit}, {type, dataPayload}) {
      commit('changeExpressBtn');
      try {
        return await API.post(`${prefixApiOrderExpress}/${type}/make-label`, dataPayload).then((response) =>{
          commit('successExpressBtn');
          return response;
        });
      } catch (e) {
        commit('successExpressBtn');
        return e;
      }
    },

    async adminConfirmMakeLabel({commit}, {type, dataPayload}) {
      commit('changeExpressBtn');
      try {
        return await API.post(`${prefixApiOrderExpress}/${type}/confirm-making-label`, dataPayload).then((response) =>{
          commit('successExpressBtn');
          return response;
        });
      } catch (e) {
        commit('successExpressBtn');
        return e;
      }
    },


    async getExpressProform({commit}, data) {
      try {
        return await API.post(`${prefixExpress}/download-document-package`, data).then((response) =>{
          return response
        })
      } catch (e) {
        commit('successExpressBtn')
        return e;
      }
    },

    async getExportCommercialInvoice({commit}, data) {
      try {
        return await API.post(`${prefixExpress}/download-commercial-invoice`, data).then((response) =>{
          return response
        })
      } catch (e) {
        commit('successExpressBtn')
        return e;
      }
    },

    async getExpressConfirmationDocument({commit}, data) {
      try {
        return await API.post(`${prefixExpress}/download-confirmation-document`, data).then((response) =>{
          return response
        })
      } catch (e) {
        commit('successExpressBtn')
        return e;
      }
    },

    async afterInvoicePayStatusUpdate({commit}, id) {
      try {
        return await API.patch(`${prefixExpress}/after-pay-processing/${id}`).then((response) =>{
          return response
        })
      } catch (e) {
        commit('successExpressBtn')
        return e;
      }
    },


    async getExportExpress({commit}, {filter , type}) {
      commit('changeExpressBtn')
      commit('setExportBtnLoadingStart')
      try {
        return await API.get(`${prefixExpress}/export/${type}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('successExpressBtn')
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('successExpressBtn')
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

    async identifyPickupCity({commit}, data) {
      try {
        return await API.post(`${prefixExpress}/identify-pickup-city`, data).then((response) =>{
          return response
        })
      } catch (e) {
        commit('successExpressBtn')
        return e;
      }
    },

    async getExpressOrderThermalPrintPdf({commit}, data) {
      commit('changeExpressBtn')
      try {
        return await API.get(`${prefixExpress}/${data.id}/marking/${data.type}`, data, { responseType: 'blob' }).then((response) =>{
          commit('successExpressBtn')
          return response
        })
      } catch (e) {
        commit('successExpressBtn')
        commit('setLoadingEnd')
        return e;
      }
    },

    async getExpressTrackingLog({commit}, data) {
      // commit('changeExpressBtn')
      try {
        return await API.get(`${prefixExpress}/${data.id}/tracking-log-history`, data).then((response) =>{
          // commit('successExpressBtn')
          return response
        })
      } catch (e) {
        commit('successExpressBtn')
        commit('setLoadingEnd')
        return e;
      }
    },

    async changeExpressAlarm({commit}, {id, data}) {
      try {
        return await API.patch(`${prefixExpress}/alarm/${id}`, data).then((response) =>{
          return response
        })
      } catch (e) {
        commit('successExpressBtn')
        return e;
      }
    },


    async fetchExpressLabels({commit, getters}, {filter = ''}) {
      if(!getters.getNextExpressPage)
        commit('setExpressLoadingStart')
      try {
        return await API.get(`${prefixExpress}/user-proform-documents-list${filter}`).then((response) =>{
            commit('setExpress',{express: response.data.data.data})
            // commit('setExpressCommonList',{expressCommonList: response.data.data})
            commit('setExpressCommonList',{expressCommonList: {...response.data.data.meta, ...{
                  addition_export: response.data.data.addition_export,
                  next_page_url: response.data.data.links.next,
                  first_page_url: response.data.data.links.first,
                  last_page_url: response.data.data.links.last,
                  prev_page_url: response.data.data.links.prev
                }}})
            commit('setExpressLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setExpressLoadingEnd');
        return e;
      }
    },

    async expressGenerateLabels({commit}, data) {
      commit('changeExpressBtn');
      try {
        return await API.post(`${prefixExpress}/mass-proform-documents`, data).then((response) =>{
          commit('successExpressBtn');
          return response;
        });
      } catch (e) {
        commit('successExpressBtn');
        return e;
      }
    },

    async disableExpressLabel({commit}, uuid = '') {
      // commit('changeExpressBtn');
      try {
        return await API.post(`${prefixExpress}/disable-user-proform-document/${uuid}`).then((response) =>{
          // commit('successExpressBtn');
          return response;
        });
      } catch (e) {
        commit('successExpressBtn');
        return e;
      }
    },
  }
}
