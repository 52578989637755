<template>
  <div class="header__search admin-edit-item">
    <SearchInput
        :type="'text'"
        :label="$t('transactions_Multisearch.localization_value.value')"
        v-model="search"
        @submit="$emit('multiSearch', $event, false, search)"
        @keyup.native="$emit('multiSearch', $event, true, search)"
    />
    <span class="admin-edit" @click="editTranslate(['transactions_Multisearch'])"></span>
  </div>
</template>

<script>
import SearchInput from "../../../../../UI/inputs/SearchInput/SearchInput";

export default {
  name: "MultiSearch",

  components: {
    SearchInput
  },

  data() {
    return {
      search: '',
    }
  },

  methods: {

  }

}
</script>

<style scoped>

</style>