import {API} from "../../axios/axiosMainUrl";

const prefix = 'file-storage'

export const getFileEntries = {
  state: {
    getFileEntries: [],
    getFileEntriesItem: {},
    getFileEntriesCommonList: [],
    getFileEntriesBtn: false,
    getFileEntriesLoading: true,
    nextGetFileEntriesPage: false,
    getFileEntriesForPage: 25,
    getFileEntriesFilter: '',
  },

  mutations: {
    setGetFileEntriesFilter: (state, payload) => {
      state.getFileEntriesFilter = payload
    },

    setGetFileEntries: (state, payload) => {
      if(state.nextGetFileEntriesPage) {
        state.getFileEntries = state.getFileEntries.concat(payload.getFileEntries)
      } else {
        state.getFileEntries = payload.getFileEntries
      }
    },

    setGetFileEntriesCommonList: (state, payload) => {
      state.getFileEntriesCommonList = payload.getFileEntriesCommonList
    },

    setNextGetFileEntriesPage: (state, payload) => {
      state.nextGetFileEntriesPage = payload
    },

    setGetFileEntriesItem: (state, payload) => {
      state.getFileEntriesItem = payload.getFileEntriesItem
    },

    setGetFileEntriesLoadingStart: (state) => {
      state.getFileEntriesLoading = true
    },

    setGetFileEntriesLoadingEnd: (state) => {
      state.getFileEntriesLoading = false
    },

    changeGetFileEntriesBtn: (state) => {
      state.getFileEntriesBtn = true
    },
    successGetFileEntriesBtn: (state) => {
      state.getFileEntriesBtn = false
    },
  },

  getters: {
    getGetFileEntriesFilter: state => {
      return state.getFileEntriesFilter
    },

    getGetFileEntriesForPage: state => {
      return state.getFileEntriesForPage
    },

    getGetFileEntries: state => {
      return state.getFileEntries
    },

    getGetFileEntriesLoading: state => {
      return state.getFileEntriesLoading
    },


    getNextGetFileEntriesPage: state => {
      return state.nextGetFileEntriesPage
    },

    getGetFileEntriesItem: state => {
      return state.getFileEntriesItem
    },

    getGetFileEntriesCommonList: state => {
      return state.getFileEntriesCommonList
    },

    getGetFileEntriesBtn: state => {
      return state.getFileEntriesBtn
    },
  },

  actions: {

    async fetchGetFileEntries({commit, getters}, filter = '') {
      if(!getters.getNextGetFileEntriesPage)
        commit('setGetFileEntriesLoadingStart')
      try {
        return await API.get(`/getFileEntries${filter}`).then((response) =>{
          commit('setGetFileEntries',{getFileEntries: response.data.data.data})
          commit('setGetFileEntriesCommonList',{getFileEntriesCommonList: response.data.data})
          commit('setGetFileEntriesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setGetFileEntriesLoadingEnd');
        return e;
      }
    },

    async getGetFileEntries({commit}, id) {
      commit('setGetFileEntriesLoadingStart');
      try {
        return await API.get(`/${prefix}/get-file/${id}`).then((response) =>{
          console.log(response)
          commit('setGetFileEntriesItem', {getFileEntriesItem: response.data.data})
          commit('setGetFileEntriesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setGetFileEntriesLoadingEnd');
        return e;
      }
    },

    async createGetFileEntries({commit}, data) {
      commit('changeGetFileEntriesBtn');
      try {
        return await API.post(`/getFileEntries`, data).then((response) =>{
          commit('successGetFileEntriesBtn');
          return response;
        });
      } catch (e) {
        commit('successGetFileEntriesBtn');
        return e;
      }
    },

    async updateGetFileEntries({commit}, {id, data}) {
      commit('changeGetFileEntriesBtn');
      try {
        return await API.put(`/getFileEntries/${id}`, data).then((response) =>{
          commit('successGetFileEntriesBtn');
          return response;
        });
      } catch (e) {
        commit('successGetFileEntriesBtn');
        return e;
      }
    },

    async deleteGetFileEntries({commit}, id) {
      try {
        return await API.delete(`/getFileEntries/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successGetFileEntriesBtn');
        return e;
      }
    },

  }
}
