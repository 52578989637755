
import {routerPaths} from '../../../router/routerPaths'

const SynchronizeShopifyPage = () => import(/* webpackChunkName: "synchronize-shopify-page" */ './components/SynchronizeShopifyPage/SynchronizeShopifyPage.vue')


export const SynchronizeShopifyRoutes = [
  {
    path: routerPaths.shopifyInfoPage,
    component: SynchronizeShopifyPage,

    pageTitle: 'common_synchronizeShopifyPage'
  },
]
