<template>
  <modal
      @close="$store.commit('closeTranslationPopup')"
      class="translation-popup"
  >
    <template slot="header">
      Translate

    </template>
    <template slot="body">
      <div class="order-create__section">

        <div class="order-create__row custom-row" v-for="(item, index) in data" :key="index">

          <div class="custom-col">
            <div class="order-create__section-label section-label">
              <!--Поле - "{{item.key}}"-->
              <template v-if="item.key.indexOf('common_') > -1">Даний переклад глобальний, його зміна може впливати на інші розділи</template>
            </div>
            <template v-for="(itemLang, index) in $store.getters.GET_LANGUAGES">
              <TextareaDefault
                      :key="index"
                      class="mb-4"
                      :label="itemLang.name"
                      :type="'text'"
                      v-model="item.translations[itemLang.id]"
              />
            </template>
          </div>
        </div>



      </div>
    </template>
    <template slot="footer">
      <div class="transaction-info__btn__btn">
        <MainButton
            :value="'Change translate'"
            class="btn-fit-content"
            @click.native="change"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/commonModals/Modal";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
// import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import TextareaDefault from "../../../UI/textarea/TextareaDefault/TextareaDefault";

export default {
  name: "TranslationPopup",

  components: {
    Modal,
    MainButton,
    TextareaDefault,
  },

  data() {
    return{
      data: {},
    }
  },

  mounted() {
    setTimeout(() => {
      this.data = this.$store.getters.getTranslationsPopupFields;
    }, 1)
  },

  methods: {
    change() {
      let data = {
        translations: {}
      }
      // let newData

      let newDataTranslate = {}

      this.data.map((item) => {
        newDataTranslate[item.key] = {}

        this.$store.getters.GET_LANGUAGES.map((lang) => {
          newDataTranslate[item.key][lang.id] = item.translations[lang.id]
        })
      })

      data.translations = newDataTranslate

      this.$store.dispatch('translationsForceUpdate', data)
    },
  },

  // computed: {
  //   message: {
  //     set (value) {
  //       this.$store.commit('updateMessage', value)
  //     }
  //   }
  // }
}
</script>

<style lang="scss">
@import "../../../../scss/mixins/mixins";
@import "../../../../scss/colors";

.translation-popup {


  .modal-component__inner {
    max-width: 617px;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-bottom: -20px;
  }

  &__name {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: $black;
    margin-bottom: 15px;
  }

  &__status {
    display: flex;
    margin-bottom: 40px;
  }

  &__status-ico {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    margin-top: -1px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__status-txt {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #000000;

    &--green {
      color: $lightgreen;
    }
  }

  &__section {
    margin-bottom: 20px;


    &:last-child {
      margin-bottom: 0;
    }
  }

  &__section-title {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    align-items: center;
    display: flex;
    margin-bottom: 16px;
  }

  &__section-title-ico {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    margin-top: -2px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  &__col {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 0 15px;
  }

  &__label {
    font-size: 14px;
    line-height: 17px;
    color: $borderBrown;
    margin-bottom: 8px;
    font-weight: 400;
  }

  &__txt {
    font-size: 14px;
    line-height: 21px;
    color: $black;
    font-weight: 400;
  }

  &__btn {

  }

  &__btn-i {
    max-width: 175px;
    padding: 0 5px;
  }

}

</style>
