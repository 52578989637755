<template>
  <div class="modal-component" v-bind:class="{open: show, 'sticky-footer': stickyFooter}">
    <div ref="modalWrap" @mousedown="closePopupWrap" class="modal-component__wrapper">
      <div class="modal-component__inner">
        <div class="modal-component__content">
        <span
            v-if="canBeClosedByUser !== false"
            class="btn-close modal-component__close"
            @click="close"
            aria-label="close modal"
        >
          <CloseIcon/>
        </span>
          <div class="modal-component__header" v-bind:class="{'modal-component__header--left': (headerType === 'left')}">
            <slot name="header">

            </slot>
          </div>
          <div class="modal-component__body" v-bind:class="{'z-index-100': (bodyZPosition)}">
            <slot name="body">

            </slot>
          </div>
          <div class="modal-component__footer">
            <slot name="footer">

            </slot>
          </div>
          <div class="modal-component__background">
            <slot name="background">

            </slot>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import CloseIcon from '../../../public/img/modal-group/close-icon.svg?inline'

  export default {
    name: "Modal",

    components: {
      CloseIcon
    },

    data: function () {
      return {
        show: false,
      }
    },

    methods: {
      close() {
        this.$emit('close');
      },

      closePopupWrap(){
        if( event.target == this.$refs.modalWrap){
          this.$emit('close');
        }
      }
    },

    props:[
      'headerType',
      'bodyZPosition',
      'canBeClosedByUser',
      'stickyFooter',
    ],

    mounted() {
      setTimeout(() => {
        this.show = true;
      }, 50);
    }
  }
</script>

<style lang="scss">
  @import "../../scss/colors";
  @import "../../scss/mixins/mixins";

  .modal-component{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(35, 35, 35, 0.8);
    transition: .15s all;
    overflow-y: auto;
    opacity: 0;
    z-index: -10;
    visibility: hidden;

    &.open{
      visibility: visible;
      opacity: 1;
      z-index: 200;
    }

    &__wrapper {
      /* fix for child margin bug */
      padding: 1px;
    }

    &__inner{
      margin: 1.75rem auto;
      max-width: 350px;
      top: 150px;
      background: white;
      border-radius: 5px;

      @include for-768{
        width: calc(100% - 30px);
        margin-top: 75px;
      }

      @include from-680-for-1600{
        width: calc(100% - 140px);
        transform: translateX(30px);
      }
    }

    &__content{
      min-height: calc(100% - (1.75rem * 2));
      position: relative;
      padding:  48px 40px 48px 48px;
      background: #FFFFFF;
      background: linear-gradient(180deg, rgba(255, 251, 245, 0) -23.7%, #FFFBF5 100%);
      border-radius: 5px;

      @include for-768{
        padding: 24px 15px;
      }
    }

    &__close{
      position: absolute;
      right: 10px;
      top: 10px;
      width: 28px;
      height: 28px;
      border: 0;
      outline: none;
      background-color: transparent;
      /*background-image: url("../../assets/img/modal-group/close-icon.svg");*/
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__header{
      margin-bottom: 24px;
      color: $brown;
      font-weight: bold;
      font-size: 32px;
      line-height: 37px;
      z-index: 2;
      position: relative;
      padding-right: 25px;

      @include for-768{
        font-size: 24px;
        line-height: 32px;
      }

      @include for-550{
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 15px;
      }
    }

    &__body{
      margin-bottom: 40px;
      z-index: 2;
      position: relative;

      &.z-index-100{
        z-index: 100;
      }

      p{
        /*font-size: 18px;*/
        font-size: 14px;
        line-height: 26px;
        color: $black;
      }
    }

    &__footer{
      z-index: 2;
      position: relative;
    }

    &__background{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 10%;
    }
  }

  .sticky-footer .modal-component__footer{
    position: sticky;
    bottom: 0;
    z-index: 10;
    width: 100%;
    background: #fffcf6;
  }

</style>
