import {API} from "../../axios/axiosMainUrl";

const prefix = '/claim'

export const fillClaim = {
  state: {
    fillClaim: [],
    fillClaimItem: {},
    fillClaimCommonList: [],
    fillClaimBtn: false,
    fillClaimLoading: true,
    nextFillClaimPage: false,
    fillClaimForPage: 25,
    fillClaimFilter: '',
  },

  mutations: {
    setFillClaimFilter: (state, payload) => {
      state.fillClaimFilter = payload
    },

    setFillClaim: (state, payload) => {
      if(state.nextFillClaimPage) {
        state.fillClaim = state.fillClaim.concat(payload.fillClaim)
      } else {
        state.fillClaim = payload.fillClaim
      }
    },

    setFillClaimCommonList: (state, payload) => {
      state.fillClaimCommonList = payload.fillClaimCommonList
    },

    setNextFillClaimPage: (state, payload) => {
      state.nextFillClaimPage = payload
    },

    setFillClaimItem: (state, payload) => {
      state.fillClaimItem = payload.fillClaimItem
    },

    setFillClaimLoadingStart: (state) => {
      state.fillClaimLoading = true
    },

    setFillClaimLoadingEnd: (state) => {
      state.fillClaimLoading = false
    },

    changeFillClaimBtn: (state) => {
      state.fillClaimBtn = true
    },
    successFillClaimBtn: (state) => {
      state.fillClaimBtn = false
    },
  },

  getters: {
    getFillClaimFilter: state => {
      return state.fillClaimFilter
    },

    getFillClaimForPage: state => {
      return state.fillClaimForPage
    },

    getFillClaim: state => {
      return state.fillClaim
    },

    getFillClaimLoading: state => {
      return state.fillClaimLoading
    },


    getNextFillClaimPage: state => {
      return state.nextFillClaimPage
    },

    getFillClaimItem: state => {
      return state.fillClaimItem
    },

    getFillClaimCommonList: state => {
      return state.fillClaimCommonList
    },

    getFillClaimBtn: state => {
      return state.fillClaimBtn
    },
  },

  actions: {

    async fetchFillClaim({commit, getters}, filter = '') {
      if(!getters.getNextFillClaimPage)
        commit('setFillClaimLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setFillClaim',{fillClaim: response.data.data.data})
          commit('setFillClaimCommonList',{fillClaimCommonList: response.data.data})
          commit('setFillClaimLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setFillClaimLoadingEnd');
        return e;
      }
    },

    async getFillClaimCreate({commit},) {
      try {
        return await API.get(`${prefix}/create`).then((response) => {
          return response;
        });
      } catch (e) {
        commit('setFillClaimLoadingEnd');
        return e;
      }
    },

    async getFillClaim({commit}, id) {
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setFillClaimItem', {fillClaimItem: response.data.data})
          return response;
        });
      } catch (e) {
        commit('setFillClaimLoadingEnd');
        return e;
      }
    },

    async getFillClaimEdit({commit}, id) {
      try {
        return await API.get(`${prefix}/${id}/edit`).then((response) =>{
          console.log(response)
          commit('setFillClaimItem', {fillClaimItem: response.data.data})
          return response;
        });
      } catch (e) {
        commit('setFillClaimLoadingEnd');
        return e;
      }
    },

    async getFillClaimShow({commit}, id) {
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setFillClaimItem', {fillClaimItem: response.data.data})
          return response;
        });
      } catch (e) {
        commit('setFillClaimLoadingEnd');
        return e;
      }
    },

    async createFillClaim({commit}, data) {
      commit('changeFillClaimBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successFillClaimBtn');
          return response;
        });
      } catch (e) {
        commit('successFillClaimBtn');
        return e;
      }
    },

    async addMessageFillClaim({commit}, {id: id, data: data}) {
      commit('changeFillClaimBtn');
      try {
        return await API.post(`${prefix}/${id}/add-message`, data).then((response) =>{
          commit('successFillClaimBtn');
          return response;
        });
      } catch (e) {
        commit('successFillClaimBtn');
        return e;
      }
    },

    async updateFillClaim({commit}, {id, data}) {
      commit('changeFillClaimBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successFillClaimBtn');
          return response;
        });
      } catch (e) {
        commit('successFillClaimBtn');
        return e;
      }
    },

    async deleteFillClaim({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successFillClaimBtn');
        return e;
      }
    },

    async addFillClaimAdminFile({commit}, {id, data}) {
      commit('changeFillClaimBtn');
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${prefix}/${id}/upload-admin-file`, data).then((response) =>{
          commit('successFillClaimBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successFillClaimBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async addFillClaimUserFile({commit}, {id, data}) {
      commit('changeFillClaimBtn');
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${prefix}/${id}/upload-user-file`, data).then((response) =>{
          commit('successFillClaimBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successFillClaimBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },


    async getExportClaim({commit}, {filter , type}) {
      commit('setExportBtnLoadingStart')
      commit('setLoadingEnd')
      try {
        return await API.get(`/claim/export/${type}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

    //
    //
  }
}
