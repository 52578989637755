import {API} from "../../axios/axiosMainUrl";

export const cusPayments = {
  state: {
    cusPayments: [],
    cusPaymentsItem: {},
    cusPaymentsCommonList: [],
    cusPaymentsBtn: false,
    cusPaymentsLoading: true,
    nextCusPaymentsPage: false,
    cusPaymentsForPage: 25,
    cusPaymentsFilter: '',
  },

  mutations: {
    setCusPaymentsFilter: (state, payload) => {
      state.cusPaymentsFilter = payload
    },

    setCusPaymentsForPage: (state, payload) => {
      state.cusPaymentsForPage = payload
    },

    setCusPayments: (state, payload) => {
      if(state.nextCusPaymentsPage) {
        state.cusPayments = state.cusPayments.concat(payload.cusPayments)
      } else {
        state.cusPayments = payload.cusPayments
      }
    },

    setCusPaymentsCommonList: (state, payload) => {
      state.cusPaymentsCommonList = payload.cusPaymentsCommonList
    },

    setNextCusPaymentsPage: (state, payload) => {
      state.nextCusPaymentsPage = payload
    },

    setCusPaymentsItem: (state, payload) => {
      state.cusPaymentsItem = payload.cusPaymentsItem
    },

    setCusPaymentsLoadingStart: (state) => {
      state.cusPaymentsLoading = true
    },

    setCusPaymentsLoadingEnd: (state) => {
      state.cusPaymentsLoading = false
    },

    changeCusPaymentsBtn: (state) => {
      state.cusPaymentsBtn = true
    },
    successCusPaymentsBtn: (state) => {
      state.cusPaymentsBtn = false
    },

    removeCusPaymentsItem: (state, id) => {
      let itemIndex = state.cusPayments.findIndex(item => item.id === id)
      state.cusPayments.splice(itemIndex, 1)
    }
  },

  getters: {
    getCusPaymentsFilter: state => {
      return state.cusPaymentsFilter
    },

    getCusPaymentsForPage: state => {
      return state.cusPaymentsForPage
    },

    getCusPayments: state => {
      return state.cusPayments
    },

    getCusPaymentsLoading: state => {
      return state.cusPaymentsLoading
    },


    getNextCusPaymentsPage: state => {
      return state.nextCusPaymentsPage
    },

    getCusPaymentsItem: state => {
      return state.cusPaymentsItem
    },

    getCusPaymentsCommonList: state => {
      return state.cusPaymentsCommonList
    },

    getCusPaymentsBtn: state => {
      return state.cusPaymentsBtn
    },
  },

  actions: {

    async fetchCusPayments({commit, getters}, {type, filter = ''}) {
      if(!getters.getNextCusPaymentsPage)
        commit('setCusPaymentsLoadingStart')
      try {
        return await API.get(`/payments/${type}${filter}`).then((response) =>{
          commit('setCusPayments',{cusPayments: response.data.data.data})
          commit('setCusPaymentsCommonList',{cusPaymentsCommonList: response.data.data})
          commit('setCusPaymentsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setCusPaymentsLoadingEnd');
        return e;
      }
    },

    async getCusPayments({commit}, id) {
      try {
        return await API.get(`/payments/${id}`).then((response) =>{
          console.log(response)
          commit('setCusPaymentsItem', {cusPaymentsItem: response.data.data})
          return response;
        });
      } catch (e) {
        commit('setCusPaymentsLoadingEnd');
        return e;
      }
    },

    async createCusPayments({commit}, data) {
      commit('changeCusPaymentsBtn');
      try {
        return await API.post(`/cusPayments`, data).then((response) =>{
          commit('successCusPaymentsBtn');
          return response;
        });
      } catch (e) {
        commit('successCusPaymentsBtn');
        return e;
      }
    },

    async updateCusPayments({commit}, {id, data}) {
      commit('changeCusPaymentsBtn');
      try {
        return await API.put(`/cusPayments/${id}`, data).then((response) =>{
          commit('successCusPaymentsBtn');
          return response;
        });
      } catch (e) {
        commit('successCusPaymentsBtn');
        return e;
      }
    },

    async deleteCusPayments({commit}, id) {
      try {
        return await API.delete(`/cusPayments/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successCusPaymentsBtn');
        return e;
      }
    },

    async getExportCusPayments({commit}, {filter , transactionType, type}) {
      commit('setExportBtnLoadingStart')
      commit('setLoadingEnd')
      try {
        return await API.get(`payments/export/${transactionType}/${type}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

    async getImportTransaction({commit}, data) {
      commit('setLoadingEnd')
      try {
        return await API.post(`payments/import-transaction-by-number`, data).then((response) =>{
          return response
        })
      } catch (e) {
        return e;
      }
    },

    async createCusPaymentsTrackingNumber({commit}, data) {
      // commit('changeImportOrdersBtn');
      try {
        return await API.post(`payments/paypal/add-tracker-number`, data).then((response) =>{
          // commit('successImportOrdersBtn');
          return response;
        });
      } catch (e) {
        commit('successImportOrdersBtn');
        return e;
      }
    },

    async updateCusPaymentsTrackingNumber({commit}, data) {
      // commit('changeImportOrdersBtn');
      try {
        return await API.put(`payments/paypal/update-tracker-number`, data).then((response) =>{
          // commit('successImportOrdersBtn');
          return response;
        });
      } catch (e) {
        commit('successImportOrdersBtn');
        return e;
      }
    },

    async deleteCusPaymentsTrackingNumber({commit}, data) {
      // commit('changeImportOrdersBtn');
      try {
        return await API.put(`payments/paypal/delete-tracker-number`, data).then((response) =>{
          // commit('successImportOrdersBtn');
          return response;
        });
      } catch (e) {
        commit('successImportOrdersBtn');
        return e;
      }
    },


    async updateAuthorizeTrackingNumber({commit}, {data, id}) {
      try {
        return await API.put(`payments/${id}`, data).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successImportOrdersBtn');
        return e;
      }
    },

    async changeWarehouseCommission({commit}, data) {
      // commit('changeImportOrdersBtn');
      try {
        return await API.patch(`/payments/change-warehouse-commission`, data).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successImportOrdersBtn');
        return e;
      }
    },

    async deleteResidualTransaction({commit}, id) {
      try {
        return await API.delete(`/payments/other/${id}`).then((response) =>{
          commit('removeCusPaymentsItem', id)
          return response;
        });
      } catch (e) {
        commit('successCusPaymentsBtn');
        return e;
      }
    },


    async getExportTransaction({commit}, {userId, filter}) {
      commit('changeExpressBtn')
      try {
        return await API.get(`/payments/export/transactions/${userId}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('successExpressBtn')
          return response
        })
      } catch (e) {
        commit('successExpressBtn')
        return e;
      }
    },

    async importTransaction({commit}, data) {
      commit('changeCusPaymentsBtn');
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`payments/import/transaction-statuses`, data).then((response) =>{
          commit('successCusPaymentsBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successCusPaymentsBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async importTransactionTrackById({commit}, data) {
      commit('changeCusPaymentsBtn');
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`payments/import-tracker-numbers`, data).then((response) =>{
          commit('successCusPaymentsBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successCusPaymentsBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async getImportExampleXLSXTransactionTrackById({commit}) {
      commit('setLoadingEnd')
      try {
        return await API.get(`/payments/import-tracker-numbers-example-xlsx`, { responseType: 'blob' }).then((response) =>{
          return response
        })
      } catch (e) {
        return e;
      }
    },

  }
}
