export const guideMixin = {

    methods: {

        checkPermission(){
            // return this.checkOrderTypePermission({
            // 	types: {
            //
            // 	},
            // 	currentType: this.$route.params.type
            // })
            return true
        },

        saveGuide(edit = false) {

            // if (!this.FO.firstValidationAdmin()) return

            let data = this.guideItem.prepareData()
            let saveType = 'createGuide'

            if (edit) {
                data = {
                    id: this.guideItem.getId(),
                    data: data
                }
                saveType = 'updateGuide'
            }

            console.log(data);
            console.log(saveType);

            return this.$store.dispatch(saveType, data).then(response => {
                if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

                switch (this.getResponseStatus(response)) {
                    /**
                     * Success
                     */
                    case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

                        if (edit) {
                            this.openNotify('success', 'common_notificationRecordChanged')
                        } else {
                            let newData = this.getRespData(response)
                            console.log('new data', newData);
                            this.guideItem.setGuideItem(newData)
                            this.edit = true
                            this.openNotify('success', 'common_notificationRecordCreated')
                        }

                        this.isModalGuidePopup = false

                        break
                    }
                    /**
                     * Validation Error
                     */
                    case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                        let errors = response.response.data.errors;
                        this.notifyErrorHelper(errors)
                        break
                    }
                    /**
                     * Undefined Error
                     */
                    default: {
                        this.openNotify('error', 'common_notificationUndefinedError')
                    }
                }
            })

        },
    }
}