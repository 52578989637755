import {API} from "../../axios/axiosMainUrl";

const prefix = '/payments/paypal-button'

export const payPalButtons = {
  state: {
    payPalButtons: [],
    payPalButtonsItem: {},
    payPalButtonsByUserId: [],
    payPalButtonsCommonList: [],
    payPalButtonsBtn: false,
    payPalButtonsLoading: true,
    nextPayPalButtonsPage: false,
    payPalButtonsForPage: 25,
    payPalButtonsFilter: '',
  },

  mutations: {
    setPayPalButtonsFilter: (state, payload) => {
      state.payPalButtonsFilter = payload
    },

    setPayPalButtons: (state, payload) => {
      if(state.nextPayPalButtonsPage) {
        state.payPalButtons = state.payPalButtons.concat(payload.payPalButtons)
      } else {
        state.payPalButtons = payload.payPalButtons
      }
    },

    setPayPalButtonsCommonList: (state, payload) => {
      state.payPalButtonsCommonList = payload.payPalButtonsCommonList
    },

    setNextPayPalButtonsPage: (state, payload) => {
      state.nextPayPalButtonsPage = payload
    },

    setPayPalButtonsItem: (state, payload) => {
      state.payPalButtonsItem = payload.payPalButtonsItem
    },

    setPayPalButtonsByUserId: (state, payload) => {
      state.payPalButtonsByUserId = payload.payPalButtonsByUserId
    },

    setPayPalButtonsLoadingStart: (state) => {
      state.payPalButtonsLoading = true
    },

    setPayPalButtonsLoadingEnd: (state) => {
      state.payPalButtonsLoading = false
    },

    changePayPalButtonsBtn: (state) => {
      state.payPalButtonsBtn = true
    },
    successPayPalButtonsBtn: (state) => {
      state.payPalButtonsBtn = false
    },
  },

  getters: {
    getPayPalButtonsFilter: state => {
      return state.payPalButtonsFilter
    },

    getPayPalButtonsForPage: state => {
      return state.payPalButtonsForPage
    },

    getPayPalButtons: state => {
      return state.payPalButtons
    },

    getPayPalButtonsLoading: state => {
      return state.payPalButtonsLoading
    },


    getNextPayPalButtonsPage: state => {
      return state.nextPayPalButtonsPage
    },

    getPayPalButtonsItem: state => {
      return state.payPalButtonsItem
    },

    getPayPalButtonsByUserId: state => {
      return state.payPalButtonsByUserId
    },

    getPayPalButtonsCommonList: state => {
      return state.payPalButtonsCommonList
    },

    getPayPalButtonsBtn: state => {
      return state.payPalButtonsBtn
    },
  },

  actions: {

    async fetchPayPalButtons({commit, getters}, filter = '') {
      if(!getters.getNextPayPalButtonsPage)
        commit('setPayPalButtonsLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setPayPalButtons',{payPalButtons: response.data.data.data})
          commit('setPayPalButtonsCommonList',{payPalButtonsCommonList: response.data.data})
          commit('setPayPalButtonsLoadingEnd');
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('setPayPalButtonsLoadingEnd');
        return e;
      }
    },


    async getPayPalButtons({commit}, id) {
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setPayPalButtonsItem', {payPalButtonsItem: response.data.data})
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async getPayPalButtonsById({commit}, id) {
      try {
        return await API.get(`${prefix}/get-user-notes?user_id=${id}`).then((response) =>{
          commit('setPayPalButtonsByUserId', {payPalButtonsByUserId: response.data.data})
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async getPayPalButtonsCreate({commit},) {
      try {
        return await API.get(`${prefix}/create`).then((response) => {
          return response;
        });
      } catch (e) {
        commit('setPayPalButtonsLoadingEnd');
        return e;
      }
    },

    async createPayPalButtons({commit}, data) {
      commit('changePayPalButtonsBtn');
      try {
        return await API.post(`/api-payments/paypal/create-paypal-button`, data).then((response) =>{
          commit('successPayPalButtonsBtn');
          return response;
        });
      } catch (e) {
        commit('successPayPalButtonsBtn');
        return e;
      }
    },

    async addMessagePayPalButtons({commit}, {id: id, data: data}) {
      commit('changePayPalButtonsBtn');
      try {
        return await API.post(`${prefix}/${id}/add-dialog-message`, data).then((response) =>{
          commit('successPayPalButtonsBtn');
          return response;
        });
      } catch (e) {
        commit('successPayPalButtonsBtn');
        return e;
      }
    },

    async updatePayPalButtons({commit}, {id, data}) {
      commit('changePayPalButtonsBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successPayPalButtonsBtn');
          return response;
        });
      } catch (e) {
        commit('successPayPalButtonsBtn');
        return e;
      }
    },

    async partialUpdatePayPalButtons({commit}, {id, data}) {
      commit('changePayPalButtonsBtn');
      try {
        return await API.patch(`${prefix}/${id}/partial`, data).then((response) =>{
          commit('successPayPalButtonsBtn');
          return response;
        });
      } catch (e) {
        commit('successPayPalButtonsBtn');
        return e;
      }
    },

    async deletePayPalButtons({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successPayPalButtonsBtn');
        return e;
      }
    },

    async addPayPalButtonsFile({commit}, data) {
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${prefix}/upload-file`, data).then((response) =>{
          commit('successPayPalButtonsBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successPayPalButtonsBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },
  }
}
