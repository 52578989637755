import {API} from "../../axios/axiosMainUrl";

const prefix = '/heartysan/deal'

export const heartysanDeal = {
  state: {
    heartysanDeal: [],
    heartysanDealItem: {},
    heartysanDealCommonList: [],
    heartysanDealBtn: false,
    heartysanDealLoading: true,
    nextHeartysanDealPage: false,
    heartysanDealForPage: 25,
    heartysanDealFilter: '',
  },

  mutations: {
    setHeartysanDealFilter: (state, payload) => {
      state.heartysanDealFilter = payload
    },

    setHeartysanDeal: (state, payload) => {
      if(state.nextHeartysanDealPage) {
        state.heartysanDeal = state.heartysanDeal.concat(payload.heartysanDeal)
      } else {
        state.heartysanDeal = payload.heartysanDeal
      }
    },

    setHeartysanDealCommonList: (state, payload) => {
      state.heartysanDealCommonList = payload.heartysanDealCommonList
    },

    setNextHeartysanDealPage: (state, payload) => {
      state.nextHeartysanDealPage = payload
    },

    setHeartysanDealItem: (state, payload) => {
      state.heartysanDealItem = payload.heartysanDealItem
    },

    setHeartysanDealLoadingStart: (state) => {
      state.heartysanDealLoading = true
    },

    setHeartysanDealLoadingEnd: (state) => {
      state.heartysanDealLoading = false
    },

    changeHeartysanDealBtn: (state) => {
      state.heartysanDealBtn = true
    },
    successHeartysanDealBtn: (state) => {
      state.heartysanDealBtn = false
    },
  },

  getters: {
    getHeartysanDealFilter: state => {
      return state.heartysanDealFilter
    },

    getHeartysanDealForPage: state => {
      return state.heartysanDealForPage
    },

    getHeartysanDeal: state => {
      return state.heartysanDeal
    },

    getHeartysanDealLoading: state => {
      return state.heartysanDealLoading
    },


    getNextHeartysanDealPage: state => {
      return state.nextHeartysanDealPage
    },

    getHeartysanDealItem: state => {
      return state.heartysanDealItem
    },

    getHeartysanDealCommonList: state => {
      return state.heartysanDealCommonList
    },

    getHeartysanDealBtn: state => {
      return state.heartysanDealBtn
    },
  },

  actions: {

    async fetchHeartysanDeal({commit, getters}, filter = '') {
      if(!getters.getNextHeartysanDealPage)
        commit('setHeartysanDealLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setHeartysanDeal',{heartysanDeal: response.data.data.data})
          // commit('setHeartysanDealCommonList',{heartysanDealCommonList: response.data.data})
          commit('setHeartysanDealCommonList',{heartysanDealCommonList: {...response.data.data.meta, ...{
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev
              }}})
          commit('setHeartysanDealLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setHeartysanDealLoadingEnd');
        return e;
      }
    },

    async getHeartysanDeal({commit}, id) {
      commit('setHeartysanDealLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          console.log(response)
          commit('setHeartysanDealItem', {heartysanDealItem: response.data.data})
          commit('setHeartysanDealLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setHeartysanDealLoadingEnd');
        return e;
      }
    },

    async createHeartysanDeal({commit}, data) {
      commit('changeHeartysanDealBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successHeartysanDealBtn');
          return response;
        });
      } catch (e) {
        commit('successHeartysanDealBtn');
        return e;
      }
    },

    async updateHeartysanDeal({commit}, {id, data}) {
      commit('changeHeartysanDealBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successHeartysanDealBtn');
          return response;
        });
      } catch (e) {
        commit('successHeartysanDealBtn');
        return e;
      }
    },

    async deleteHeartysanDeal({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successHeartysanDealBtn');
        return e;
      }
    },

    async heartisanManufacturerAccept({commit}, {id, data}) {
      commit('changeHeartysanDealBtn');
      try {
        return await API.patch(`${prefix}/${id}/manufacturer-accept`, data).then((response) =>{
          commit('successHeartysanDealBtn');
          return response;
        });
      } catch (e) {
        commit('successHeartysanDealBtn');
        return e;
      }
    },
    async heartisanManufacturerDeaccept({commit}, id) {
      commit('changeHeartysanDealBtn');
      try {
        return await API.patch(`${prefix}/${id}/manufacturer-deaccept`).then((response) =>{
          commit('successHeartysanDealBtn');
          return response;
        });
      } catch (e) {
        commit('successHeartysanDealBtn');
        return e;
      }
    },
    async heartisanSellerAccept({commit}, id) {
      commit('changeHeartysanDealBtn');
      try {
        return await API.patch(`${prefix}/${id}/seller-accept`).then((response) =>{
          commit('successHeartysanDealBtn');
          return response;
        });
      } catch (e) {
        commit('successHeartysanDealBtn');
        return e;
      }
    },
    async heartisanManufacturerCancel({commit}, id) {
      commit('changeHeartysanDealBtn');
      try {
        return await API.patch(`${prefix}/${id}/manufacturer-cancel`).then((response) =>{
          commit('successHeartysanDealBtn');
          return response;
        });
      } catch (e) {
        commit('successHeartysanDealBtn');
        return e;
      }
    },
    async heartisanSellerCancel({commit}, id) {
      commit('changeHeartysanDealBtn');
      try {
        return await API.patch(`${prefix}/${id}/seller-cancel`).then((response) =>{
          commit('successHeartysanDealBtn');
          return response;
        });
      } catch (e) {
        commit('successHeartysanDealBtn');
        return e;
      }
    },
    async heartisanDealReactivate({commit}, id) {
      commit('changeHeartysanDealBtn');
      try {
        return await API.patch(`${prefix}/${id}/reactivate`).then((response) =>{
          commit('successHeartysanDealBtn');
          return response;
        });
      } catch (e) {
        commit('successHeartysanDealBtn');
        return e;
      }
    },






  }
}
