
export const globalUserPermissionMixin = {

  computed: {
    downloadPermissions(){
      return Object.hasOwnProperty.call(this.$store.getters.getCurrentUserRights, 'additionalPermissions')
    },

    currentPermissions(){
      return this.$store.getters.getCurrentUserRights?.additionalPermissions
    },

    getUserRole() {
      return this.$store.getters.getCurrentUserRights?.role
    },
  },

  methods: {
    HAS_PERMISSION(permission){
      let userPermissions = this.$store.getters.getCurrentUserRights?.additionalPermissions
      if(!userPermissions)
        return false

      return Object.hasOwnProperty.call(userPermissions, permission)
    },

    checkOrderTypePermission({types, currentType}){
      if(!types || !currentType) return false

      if(!this._.has(types, currentType)) {
        this.$router.push(this.$store.getters.GET_PATHS.notFound)
        return false
      }

      let permissionName = types[currentType]
      if(!this._.has(this.currentPermissions, permissionName)){
        this.$router.push(this.$store.getters.GET_PATHS.notFound)
        return false
      }

      return true
    },
  },
}
