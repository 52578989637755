
import {API} from '../../../axios/axiosMainUrl'

export const permissions = {
  state: {
    permissions: [],
    permissionItem: {},
    loadingPermissions: false,
    loadingPermissionItem: false,
    changeBtn: false,
  },

  mutations: {
    setPermissions: (state, payload) => {
      state.permissions = payload.permissions
    },
    setPermissionItem: (state, payload) => {
      state.permissionItem = payload.permissionItem
    },

    setLoadingStart: (state) => {
      state.loadingPermissions = true
    },
    setLoadingPermissionItemStart: (state) => {
      state.loadingPermissions = true
    },

    setLoadingEnd: (state) => {
      state.loadingPermissions = false
    },
    setLoadingPermissionItemEnd: (state) => {
      state.loadingPermissions = false
    },

    changeBtn: (state) => {
      state.changeBtn = true
    },
    successBtn: (state) => {
      state.changeBtn = false
    },
  },

  getters: {
    getPermissions: state => {
      return state.permissions
    },

    getPermissionItem: state => {
      return state.permissionItem
    },

    getPermissionBtn: state => {
      return state.changeBtn
    }
  },

  actions: {
    async fetchPermissionItem({commit}, permissionId) {
      commit('setLoadingPermissionItemStart');
      try {
        return await API.get(`access-controls/permissions/${permissionId}`).then((response) =>{
          commit('setPermissionItem', {permissionItem: response.data.data});
          commit('setLoadingPermissionItemEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingPermissionItemEnd');
        return e;
      }
    },

    async fetchPermissions({commit}, filter = '') {
      commit('setLoadingStart');
      try {
        return await API.get(`access-controls/permissions${filter}`).then((response) =>{
          console.log(response.data.data.data);
          commit('setPermissions', {permissions: response.data.data.data});
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async getPermissions({commit}, filter = '') {
      try {
        return await API.get(`access-controls/permissions${filter}`).then((response) =>{
          return response.data.data.data;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async updatePermissions({commit}, {id, data}) {
      commit('setLoadingStart');
      try {
        return await API.put(`access-controls/permissions/${id}`, data).then((response) =>{
          commit('setPermissions', {permissions: response.data.permissions});
          commit('setLoadingEnd');

          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async createPermissions({commit}, data) {
      commit('setLoadingStart');
      try {
        return await API.post('access-controls/permissions', data).then((response) =>{
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },


    async deletePermissions({commit}, permissionId) {
      commit('setLoadingStart');
      try {
        return await API.delete(`access-controls/permissions/${permissionId}`).then((response) =>{
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

  }
}
