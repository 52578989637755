import {API} from "../../axios/axiosMainUrl";

const prefix = 'expenses'

export const otherIncome = {
  state: {
    otherIncome: [],
    otherIncomeItem: {},
    otherIncomeCommonList: [],
    otherIncomeBtn: false,
    otherIncomeLoading: true,
    nextOtherIncomePage: false,
    otherIncomeForPage: 25,
    otherIncomeFilter: '',
  },

  mutations: {
    setOtherIncomeFilter: (state, payload) => {
      state.otherIncomeFilter = payload
    },

    setOtherIncome: (state, payload) => {
      if(state.nextOtherIncomePage) {
        state.otherIncome = state.otherIncome.concat(payload.otherIncome)
      } else {
        state.otherIncome = payload.otherIncome
      }
    },

    setOtherIncomeCommonList: (state, payload) => {
      state.otherIncomeCommonList = payload.otherIncomeCommonList
    },

    setNextOtherIncomePage: (state, payload) => {
      state.nextOtherIncomePage = payload
    },

    setOtherIncomeItem: (state, payload) => {
      state.otherIncomeItem = payload.otherIncomeItem
    },

    setOtherIncomeLoadingStart: (state) => {
      state.otherIncomeLoading = true
    },

    setOtherIncomeLoadingEnd: (state) => {
      state.otherIncomeLoading = false
    },

    changeOtherIncomeBtn: (state) => {
      state.otherIncomeBtn = true
    },
    successOtherIncomeBtn: (state) => {
      state.otherIncomeBtn = false
    },
  },

  getters: {
    getOtherIncomeFilter: state => {
      return state.otherIncomeFilter
    },

    getOtherIncomeForPage: state => {
      return state.otherIncomeForPage
    },

    getOtherIncome: state => {
      return state.otherIncome
    },

    getOtherIncomeLoading: state => {
      return state.otherIncomeLoading
    },


    getNextOtherIncomePage: state => {
      return state.nextOtherIncomePage
    },

    getOtherIncomeItem: state => {
      return state.otherIncomeItem
    },

    getOtherIncomeCommonList: state => {
      return state.otherIncomeCommonList
    },

    getOtherIncomeBtn: state => {
      return state.otherIncomeBtn
    },
  },

  actions: {

    async fetchOtherIncome({commit, getters}, filter = '') {
      if(!getters.getNextOtherIncomePage)
        commit('setOtherIncomeLoadingStart')
      try {
        return await API.get(`/${prefix}${filter}`).then((response) =>{
          commit('setOtherIncome',{otherIncome: response.data.data.data})
          commit('setOtherIncomeCommonList',{otherIncomeCommonList: response.data.data})
          commit('setOtherIncomeLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOtherIncomeLoadingEnd');
        return e;
      }
    },

    async getOtherIncome({commit}, id) {
      try {
        return await API.get(`/${prefix}/${id}`).then((response) =>{
          commit('setOtherIncomeItem', {otherIncomeItem: response.data.data})
          commit('setOtherIncomeLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOtherIncomeLoadingEnd');
        return e;
      }
    },

    async getOtherIncomeCreate({commit}) {
      try {
        return await API.get(`/${prefix}/create`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setOtherIncomeLoadingEnd');
        return e;
      }
    },

    async createOtherIncome({commit}, data) {
      commit('changeOtherIncomeBtn');
      try {
        return await API.post(`/${prefix}`, data).then((response) =>{
          commit('successOtherIncomeBtn');
          return response;
        });
      } catch (e) {
        commit('successOtherIncomeBtn');
        return e;
      }
    },

    async updateOtherIncome({commit}, {id, data}) {
      commit('changeOtherIncomeBtn');
      try {
        return await API.put(`/${prefix}/${id}`, data).then((response) =>{
          commit('successOtherIncomeBtn');
          return response;
        });
      } catch (e) {
        commit('successOtherIncomeBtn');
        return e;
      }
    },

    async uploadFileOtherIncome({commit}, data) {
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`/${prefix}/upload-file`, data).then((response) =>{
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successOtherIncomeBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async deleteOtherIncome({commit}, id) {
      try {
        return await API.delete(`/${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successOtherIncomeBtn');
        return e;
      }
    },

  }
}
