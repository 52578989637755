import {API} from "../../axios/axiosMainUrl";

const prefix = '/promocodes'

export const promoCode = {
  state: {
    promoCode: [],
    promoCodeItem: {},
    promoCodeCommonList: [],
    promoCodeBtn: false,
    promoCodeLoading: true,
    nextPromoCodePage: false,
    promoCodeForPage: 25,
    promoCodeFilter: '',
  },

  mutations: {
    setPromoCodeFilter: (state, payload) => {
      state.promoCodeFilter = payload
    },

    setPromoCode: (state, payload) => {
      if(state.nextPromoCodePage) {
        state.promoCode = state.promoCode.concat(payload.promoCode)
      } else {
        state.promoCode = payload.promoCode
      }
    },

    setPromoCodeCommonList: (state, payload) => {
      state.promoCodeCommonList = payload.promoCodeCommonList
    },

    setNextPromoCodePage: (state, payload) => {
      state.nextPromoCodePage = payload
    },

    setPromoCodeItem: (state, payload) => {
      state.promoCodeItem = payload.promoCodeItem
    },

    setPromoCodeLoadingStart: (state) => {
      state.promoCodeLoading = true
    },

    setPromoCodeLoadingEnd: (state) => {
      state.promoCodeLoading = false
    },

    changePromoCodeBtn: (state) => {
      state.promoCodeBtn = true
    },
    successPromoCodeBtn: (state) => {
      state.promoCodeBtn = false
    },
  },

  getters: {
    getPromoCodeFilter: state => {
      return state.promoCodeFilter
    },

    getPromoCodeForPage: state => {
      return state.promoCodeForPage
    },

    getPromoCode: state => {
      return state.promoCode
    },

    getPromoCodeLoading: state => {
      return state.promoCodeLoading
    },


    getNextPromoCodePage: state => {
      return state.nextPromoCodePage
    },

    getPromoCodeItem: state => {
      return state.promoCodeItem
    },

    getPromoCodeCommonList: state => {
      return state.promoCodeCommonList
    },

    getPromoCodeBtn: state => {
      return state.promoCodeBtn
    },
  },

  actions: {

    async fetchPromoCode({commit, getters}, filter = '') {
      if(!getters.getNextPromoCodePage)
        commit('setPromoCodeLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          console.log(response.data);
          commit('setPromoCode',{promoCode: response.data.data})
          commit('setPromoCodeCommonList',{promoCodeCommonList: response.data})
          commit('setPromoCodeLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setPromoCodeLoadingEnd');
        return e;
      }
    },

    async getPromoCode({commit}, id) {
      commit('setPromoCodeLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setPromoCodeItem', {promoCodeItem: response.data.data})
          commit('setPromoCodeLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setPromoCodeLoadingEnd');
        return e;
      }
    },

    async createPromoCode({commit}, data) {
      commit('changePromoCodeBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successPromoCodeBtn');
          return response;
        });
      } catch (e) {
        commit('successPromoCodeBtn');
        return e;
      }
    },

    async updatePromoCode({commit}, {id, data}) {
      commit('changePromoCodeBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successPromoCodeBtn');
          return response;
        });
      } catch (e) {
        commit('successPromoCodeBtn');
        return e;
      }
    },

    async checkPromoCode({commit}, data) {
      commit('changePromoCodeBtn');
      try {
        return await API.post(`${prefix}/validating/available-for-consolidation`, data).then((response) =>{
          commit('successPromoCodeBtn');
          return response;
        });
      } catch (e) {
        commit('successPromoCodeBtn');
        return e;
      }
    },

    async checkPromoCodeByWeight({commit}, data) {
      commit('changePromoCodeBtn');
      try {
        return await API.post(`${prefix}/validating/for-consolidation-by-weight`, data).then((response) =>{
          commit('successPromoCodeBtn');
          return response;
        });
      } catch (e) {
        commit('successPromoCodeBtn');
        return e;
      }
    },

    async deletePromoCode({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successPromoCodeBtn');
        return e;
      }
    },

    async importPromoCode({commit}, data) {
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      commit('changePromoCodeBtn');
      try {
        return await API.post(`${prefix}/import-codes`, data).then((response) =>{
          commit('successPromoCodeBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successPromoCodeBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

  }
}
