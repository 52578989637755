
export const checkAccess = {
  methods: {

    goToProfile() {
      this.checkAuthAccess('profile')
    },

    goToPersonalAccount() {
      this.checkAuthAccess('personal-account')
    },

    accessToPersonalAccount() {
      this.checkAuthAccess('access-personal-account')
    },

    accessToImportOrdersAmazon() {
      return this.checkAuthAccess('import-orders-amazon', `type=2`).then((response) => {
        return response
      })
    },

    newPaymentAccess(id) {
      return this.checkAuthAccess('new-payment', `payId=${id}`).then((response) => {
        return response
      })
    },

    newPaymentPayoneerAccess(id) {
      return this.checkAuthAccess('new-payment-payoneer', `payId=${id}`).then((response) => {
        return response
      })
    },

    ATMAccess(params = false) {
      return this.checkAuthAccess('ATM', params).then(response => {
        return response
      })
    },

    checkAuthAccess(type, params = false) {
      let dataTo = {}
      switch (type){
        case 'profile':
          dataTo['linkIfAccess'] = this.$store.getters.GET_PATHS.mainSettingsMyProfile
          dataTo['linkName'] = 'mainSettingsMyProfile'
          dataTo['params'] = params
          dataTo['onlyGoogle'] = false
          break
        case 'personal-account':
          dataTo['linkIfAccess'] = this.$store.getters.GET_PATHS.accounts
          dataTo['linkName'] = 'accounts'
          dataTo['params'] = params
          dataTo['onlyGoogle'] = true
          break
        case 'access-personal-account':
          dataTo['linkName'] = 'accounts'
          dataTo['params'] = params
          dataTo['onlyGoogle'] = true
          break
        case 'ATM':
          dataTo['linkName'] = 'financeRequestFunds'
          dataTo['params'] = params
          dataTo['onlyGoogle'] = true
          break
        case 'new-payment':
          dataTo['linkName'] = 'financeNewPayment'
          dataTo['params'] = params
          dataTo['onlyGoogle'] = true
          break
        case 'new-payment-payoneer':
          dataTo['linkName'] = 'financeNewPaymentsPayoneer'
          dataTo['params'] = params
          dataTo['onlyGoogle'] = true
          break
        case 'import-orders-amazon':
          dataTo['linkName'] = 'importOrders'
          dataTo['params'] = params
          dataTo['onlyGoogle'] = true
          break
      }

      return this.$store.dispatch('checkExpired2faAt').then((response) => {

        if(dataTo.onlyGoogle && !this.getUserAccessWithoutGoogleAuth && this.$store.getters.getUserProfile.auth_type === 'email') {
          this.$router.push(this.$store.getters.GET_PATHS.authenticationEmail + `/add-google`)
          return false
        }

        if (response.status === false) {
          this.checkAccess2faAt(dataTo)
          return false
        }

        if(this._.has(dataTo, 'linkIfAccess')){
          this.$router.push(dataTo.linkIfAccess)
          return false
        }

        return true
      })
    },

    checkAccess2faAt(dataTo) {

      // if(dataTo.onlyGoogle && !this.getUserAccessWithoutGoogleAuth && this.$store.getters.getUserProfile.auth_type === 'email') {
      //   this.$router.push(this.$store.getters.GET_PATHS.authenticationEmail + `/add-google`)
      //   return
      // }

      let paramsString = dataTo.params ? `?${dataTo.params}` : ''
      if(this.$store.getters.getUserProfile.auth_type === 'email') {
        this.$router.push(this.$store.getters.GET_PATHS.authenticationEmail + `/${dataTo.linkName}` + paramsString)
      } else {
        this.$router.push(this.$store.getters.GET_PATHS.authenticationGoogle + `/${dataTo.linkName}` + paramsString)
      }
    },
  }
}
