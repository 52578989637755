
import {routerPaths} from '../../../router/routerPaths'
import permissions from "@/router/middleware/permissions";
import {PERMISSIONS} from "@/staticData/permissionsStatic";

const ForbiddenKeywordsTable = () => import(/* webpackChunkName: "group-other-shippers" */ './components/ForbiddenKeywordsTable/ForbiddenKeywordsTable.vue')
const moduleKey = 'forbidden-keywords'


export const ForbiddenKeywordsRoutes = [
  {
    path: routerPaths.forbiddenKeywords,
    component: ForbiddenKeywordsTable,
    meta: {
      breadcrumbs: {
        key: [
          'menu_settings',
          'mainSettingsForbiddenKeywords'
        ],
        items: {
          'menu_settings': {
            name: 'menu_settings',
            link: routerPaths.forbiddenKeywords,
          },
          'mainSettingsForbiddenKeywords': {
            name: 'menu_forbiddenKeywords',
            link: routerPaths.forbiddenKeywords,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.FORBIDDEN_WORDS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_forbiddenKeywords'
    },
  }
]
