import {API} from "../../axios/axiosMainUrl";

const prefix = '/delivery-services/country-price'

export const orderPriceDeliveryServices = {
  state: {
    orderPriceDeliveryServices: [],
    orderPriceDeliveryServicesItem: {},
    orderPriceDeliveryServicesCommonList: [],
    orderPriceDeliveryServicesBtn: false,
    orderPriceDeliveryServicesLoading: true,
    nextOrderPriceDeliveryServicesPage: false,
    orderPriceDeliveryServicesForPage: 25,
    orderPriceDeliveryServicesFilter: '',
  },

  mutations: {
    setOrderPriceDeliveryServicesFilter: (state, payload) => {
      state.orderPriceDeliveryServicesFilter = payload
    },

    setOrderPriceDeliveryServices: (state, payload) => {
      if(state.nextOrderPriceDeliveryServicesPage) {
        state.orderPriceDeliveryServices = state.orderPriceDeliveryServices.concat(payload.orderPriceDeliveryServices)
      } else {
        state.orderPriceDeliveryServices = payload.orderPriceDeliveryServices
      }
    },

    setOrderPriceDeliveryServicesCommonList: (state, payload) => {
      state.orderPriceDeliveryServicesCommonList = payload.orderPriceDeliveryServicesCommonList
    },

    setNextOrderPriceDeliveryServicesPage: (state, payload) => {
      state.nextOrderPriceDeliveryServicesPage = payload
    },

    setOrderPriceDeliveryServicesItem: (state, payload) => {
      state.orderPriceDeliveryServicesItem = payload.orderPriceDeliveryServicesItem
    },

    setOrderPriceDeliveryServicesLoadingStart: (state) => {
      state.orderPriceDeliveryServicesLoading = true
    },

    setOrderPriceDeliveryServicesLoadingEnd: (state) => {
      state.orderPriceDeliveryServicesLoading = false
    },

    changeOrderPriceDeliveryServicesBtn: (state) => {
      state.orderPriceDeliveryServicesBtn = true
    },
    successOrderPriceDeliveryServicesBtn: (state) => {
      state.orderPriceDeliveryServicesBtn = false
    },
  },

  getters: {
    getOrderPriceDeliveryServicesFilter: state => {
      return state.orderPriceDeliveryServicesFilter
    },

    getOrderPriceDeliveryServicesForPage: state => {
      return state.orderPriceDeliveryServicesForPage
    },

    getOrderPriceDeliveryServices: state => {
      return state.orderPriceDeliveryServices
    },

    getOrderPriceDeliveryServicesLoading: state => {
      return state.orderPriceDeliveryServicesLoading
    },


    getNextOrderPriceDeliveryServicesPage: state => {
      return state.nextOrderPriceDeliveryServicesPage
    },

    getOrderPriceDeliveryServicesItem: state => {
      return state.orderPriceDeliveryServicesItem
    },

    getOrderPriceDeliveryServicesCommonList: state => {
      return state.orderPriceDeliveryServicesCommonList
    },

    getOrderPriceDeliveryServicesBtn: state => {
      return state.orderPriceDeliveryServicesBtn
    },
  },

  actions: {

    async fetchOrderPriceDeliveryServices({commit, getters}, filter = false) {
      if(!getters.getNextOrderPriceDeliveryServicesPage)
        commit('setOrderPriceDeliveryServicesLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setOrderPriceDeliveryServices',{orderPriceDeliveryServices: response.data.data.data})
          commit('setOrderPriceDeliveryServicesCommonList',{orderPriceDeliveryServicesCommonList: response.data.data})
          commit('setOrderPriceDeliveryServicesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrderPriceDeliveryServicesLoadingEnd');
        return e;
      }
    },

    async getOrderPriceDeliveryServices({commit}, id) {
      // commit('setOrderPriceDeliveryServicesLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setOrderPriceDeliveryServicesItem', {orderPriceDeliveryServicesItem: response.data.data})
          // commit('setOrderPriceDeliveryServicesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrderPriceDeliveryServicesLoadingEnd');
        return e;
      }
    },

    async setOrderPriceDeliveryServices({commit}, id) {
      // commit('setOrderPriceDeliveryServicesLoadingStart');
      try {
        return await API.get(`${prefix}/use-default/${id}`).then((response) =>{
          commit('setOrderPriceDeliveryServicesItem', {orderPriceDeliveryServicesItem: response.data.data})
          // commit('setOrderPriceDeliveryServicesLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrderPriceDeliveryServicesLoadingEnd');
        return e;
      }
    },

    async createOrderPriceDeliveryServices({commit}, data) {
      commit('changeOrderPriceDeliveryServicesBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successOrderPriceDeliveryServicesBtn');
          return response;
        });
      } catch (e) {
        commit('successOrderPriceDeliveryServicesBtn');
        return e;
      }
    },

    async updateOrderPriceDeliveryServices({commit}, {id, data}) {
      commit('changeOrderPriceDeliveryServicesBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successOrderPriceDeliveryServicesBtn');
          return response;
        });
      } catch (e) {
        commit('successOrderPriceDeliveryServicesBtn');
        return e;
      }
    },

    async deleteOrderPriceDeliveryServices({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successOrderPriceDeliveryServicesBtn');
        return e;
      }
    },

    async ImportPrice({commit}, data) {
      commit('setOrderPriceDeliveryServicesLoadingStart');
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${prefix}/import`, data).then((response) =>{
          commit('successOrderPriceDeliveryServicesBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successOrderPriceDeliveryServicesBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async zoneSkipping({commit}, data) {
      commit('setOrderPriceDeliveryServicesLoadingStart');
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`/delivery/drivers/vip-parcel/import`, data).then((response) =>{
          commit('successOrderPriceDeliveryServicesBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successOrderPriceDeliveryServicesBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async ImportPriceZip({commit}, data) {
      commit('setOrderPriceDeliveryServicesLoadingStart');
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${prefix}/import-zip-masked`, data).then((response) =>{
          commit('successOrderPriceDeliveryServicesBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successOrderPriceDeliveryServicesBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },
  }
}
