<template>
  <modal
      @close="$emit('close')"
      class="address-popup-modal address-popup"
  >
    <template slot="header">
      <div
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_ShippingAddresses',])"></div>
        {{$t('common_ShippingAddresses.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="address-popup__content">
        <div class="shipping-address custom-row">
          <template v-for="(item, index) in warehouses">
            <div class="shipping-address__item custom-col custom-col--33 custom-col--sm-50 custom-col--xs-100"
                 v-if="item.enable === 1"
                 :key="index"
            >
              <div class="shipping-address__item-content">
                <div class="shipping-address__place" v-html="item.currentTranslate.warehouse_card_title">
                </div>
                <div class="shipping-address__name" v-html="item.currentTranslate.warehouse_card_representative">
                </div>
                <div class="shipping-address__info">
                  <div class="shipping-address__info-txt" v-html="item.currentTranslate.warehouse_card_address">
                  </div>
                  <div class="shipping-address__info-phone" v-html="item.currentTranslate.warehouse_card_phone">
                  </div>
                </div>
                <div class="shipping-address__schedule" v-html="item.currentTranslate.warehouse_card_working_hours">
                </div>
              </div>
            </div>
          </template>

        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";

  export default {
    name: "AddressPopup",

    components: {
      Modal,
    },

    watch: {
      loadUserAuthorizedData() {
        this.getWarehouses()
      },
    },

    data(){
      return {
        warehouses: []
      }
    },

    mounted() {
      this.getWarehouses()
    },

    methods: {

      getWarehouses(){
        if(this.loadUserAuthorizedData){
          if(this.getUserCountryGroup){
            // `?filter[countryGroupId]=${this.getUserCountryGroup?.ids.join(',')},2,11`
            this.$store.dispatch('fetchWarehousesForAddressList', ).then(response => {
              this.warehouses = this.getRespData(response)
            })
          } else {
            this.$store.dispatch('fetchWarehousesForAddressList').then(response => {
              this.warehouses = this.getRespData(response)
            })
          }
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "../../../../../scss/colors";
  @import "../../../../../scss/mixins/mixins";

  .address-popup{
    width: 100vw;

    &.open{
      z-index: 999;
    }

    .modal-component__content{
      padding-bottom: 25px;

      @include for-680{
        padding: 25px 15px;
      }
    }

    .modal-component__inner {
      max-width: 712px;

      @include for-680{
        max-width: calc(100vw - 30px);
      }
    }

    .modal-component__body{
      margin-bottom: 0;
    }

    &__content {

    }

  }

  .shipping-address{

    &__item{

      @include for-680{
        max-width: 200px;
        width: 100%;
      }
    }

    &__item-content{
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &__place{
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: $black;
    }

    &__name{
      font-size: 14px;
      line-height: 16px;
      color: $brown;
      margin-bottom: 12px;
    }

    &__info{
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      padding-bottom: 15px;
      margin-bottom: 10px;
      position: relative;

      p{
        margin: 0;
        font-size: 14px;
        line-height: 16px;
      }

      &:after{
        content: '';
        left: 0;
        bottom: 0;
        width: 75%;
        height: 1px;
        background: $brown;
        position: absolute;
      }
    }

    &__info-txt{
      font-size: 14px;
      line-height: 16px;
      color: $black;
      white-space: pre-line;
      margin-bottom: 15px;
    }

    &__info-phone{
      font-size: 14px;
      line-height: 16px;
      color: $black;
      white-space: pre-line;
    }

    &__schedule{
      font-size: 14px;
      line-height: 16px;
      color: $black;
      white-space: pre-line;
      p{
        margin: 0;
        font-size: 14px;
        line-height: 16px;
      }
    }


  }

</style>
