
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const FillClaimTable = () => import(/* webpackChunkName: "group-request-return" */ './components/FillClaimTable/FillClaimTable.vue')
const FillClaimCreation = () => import(/* webpackChunkName: "group-request-return" */ './components/FillClaimCreation/FillClaimCreation.vue')
const FillClaimEditing = () => import(/* webpackChunkName: "group-request-return" */ './components/FillClaimEditing/FillClaimEditing.vue')
const moduleKey = 'fill-claim'


export const FillClaimRoutes = [
  {
    path: routerPaths.problemsFillClaim,
    component: FillClaimTable,
    meta: {
      breadcrumbs: {
        key: [
          'dashboard',
          'problemsFillClaim'
        ],
        items: {
          'dashboard': {
            name: 'menu_problems',
            link: routerPaths.dashboard,
          },
          'problemsFillClaim': {
            name: 'menu_fileAClaim',
            link: routerPaths.problemsFillClaim,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.CLAIM_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_fileAClaim'
    },
  },

  {
    path: routerPaths.problemsFillClaimCreate,
    component: FillClaimCreation,
    meta: {
      breadcrumbs: {
        key: [
          'problemsFillClaim',
          'problemsFillClaimCreation'
        ],
        items: {
          'problemsFillClaim': {
            name: 'menu_problems',
            link: routerPaths.problemsFillClaim,
          },
          'problemsFillClaimCreation': {
            name: 'menu_fileAClaim',
            link: routerPaths.problemsFillClaimCreate,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.CLAIM_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_fileAClaim'
    },
  },

  {
    path: routerPaths.problemsFillClaimEdit,
    component: FillClaimEditing,
    meta: {
      breadcrumbs: {
        key: [
          'problemsFillClaim',
          'problemsFillClaimCreation'
        ],
        items: {
          'problemsFillClaim': {
            name: 'menu_problems',
            link: routerPaths.problemsFillClaim,
          },
          'problemsFillClaimCreation': {
            name: 'menu_fileAClaim',
            link: routerPaths.problemsFillClaimEdit,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.CLAIM_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'menu_fileAClaim'
    },
  },

]
