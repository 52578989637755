<template>
  <span class="link-back">
    <span class="link-back__ico">
      <BackArrowIco/>
    </span>
    {{ value }}
  </span>
</template>

<script>
  import BackArrowIco from '../../../../../public/img/UI-group/link-back-arrow-icon.svg?inline'

  export default {
    name: "LinkBack",
    components: {
      BackArrowIco
    },

    props:[
      'value'
    ]
  }
</script>

<style lang="scss">
  @import "../../../../scss/colors";

  .link-back{
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $borderBrown;
    cursor: pointer;

    &__ico{
      margin-right: 5px;
      display: flex;
      position: relative;
      top: 0;
      width: 10px;

      svg{
        width: 100%;
        height: 100%;
      }
    }
  }
</style>
