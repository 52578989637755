
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const MarketplaceSettingsEdit = () => import(/* webpackChunkName: "group-marketplace-settings-edit" */ './components/MarketplaceSettingsEdit/MarketplaceSettingsEdit.vue')
const moduleKey = 'marketplace-settings'


export const MarketplaceSettingsRoutes = [
  {
    path: routerPaths.marketplaceSettingsEditItem,
    component: MarketplaceSettingsEdit,
    meta: {
      breadcrumbs: {
        key: [
          'settings',
          'mainSettingsShops',
          'shopSettings',
        ],
        items: {
          'settings': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsShops,
          },
          'mainSettingsShops': {
            name: 'menu_shops',
            link: routerPaths.mainSettingsShops,
          },
          'shopSettings': {
            name: 'menu_marketplaceSettings',
            link: routerPaths.marketplaceSettingsEditItem,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.UKRAINIAN_TREASURES_SETTINGS],

      moduleKey: moduleKey,
      pageTitle: 'menu_marketplaceSettings'
    },
  },
]
