
export const filterFunc = {
  methods: {
    resetFilterParams(that, params) {
      let data = {}

      params.map((item) => {
        that[item] = ''
        data[item] = ''
      })

      return data
    },

    changeFilterParams(that, params) {
      let data = {}

      params.map((item) => {
        data[item] = that[item]
      })

      return data
    },

    changeDateParams(that, param) {
      if(
        (that[param][0] === null && that[param][1] === null) ||
        that[param] === ''
      ){
        return ''
      } else {
        return that[param][0] + ',' + that[param][1]
      }
    },

    changeRangeParams(that, param) {
      if(
          (that[param][0] === '') ||
          that[param] === ''
      ){
        return ''
      } else {
        return that[param][0] + ',' + that[param][1]
      }
    },

    generateFilterDate(date, type) {
      console.log(date);
      return [this.$moment(date.split(',')[0]).format(type),
        this.$moment(date.split(',')[1]).format(type)]
    },

    generateShopsTypeInFilter(typeShopsFilter) {
      let newArr = []

      if(typeShopsFilter.length > 0){
        typeShopsFilter.map((item) => {
          let data = item.shop
          data.fullName = item.shop.currentTranslate.label
          newArr.push(data)
        })
      }

      return newArr
    },


    checkShopSelectActiveMixin(shopTypesFilter, shop) {
      let shopActive = ''
      shopTypesFilter.map((item) => {
        if (item.id === parseInt(shop)) {
          shopActive = item.currentTranslate.label
        }
      })

      return shopActive
    },

    checkSelectActiveMixin(selectOptionsFilter, active, search) {
      let selectActive = ''
      selectOptionsFilter.map((item) => {
        if (item[search] === active) {
          selectActive = item
        }
      })

      return selectActive
    },

    functionSearch(value, loading, searchType = false) {
      loading(true)

      switch (searchType) {
        case 'user':
          this.getFilteredUsers(value, this, 'options', loading)
          break
        case 'admin':
          this.getFilteredAdminUsers(value, this, 'options', loading)
          break
        case 'accounts':
          this.getFilteredAccounts(value, this, 'optionsAccounts', loading)
          break
        case 'transactions':
          this.getFilteredTransactions(value, this, 'options', loading)
          break
        case 'trackingNumberOrIdFBM':
          this.getFilteredTrackingNumberOrIdFBM(value, this, 'options', loading)
          break
        default:
          this.getFilteredUsers(value, this, 'options', loading)
          break
      }
    },

    functionSearchUserFilter(value, loading, searchType = false) {
      loading(true)

      this.getFilteredUsers(value, this, 'options', loading, true, searchType)
    },

    changeUsersFilter(value) {
      this.userId = value.id
      if(value?.contacts && value.contacts.length > 0)
        this.userName = value.contacts[0].user_full_name
      else
        this.userName = ''
    },

    changeUserAdminFilter(value) {
      this.userAdminId = value.id
      if(value?.contacts && value.contacts.length > 0)
        this.userAdminName = value.contacts[0].user_full_name
      else
        this.userAdminName = ''
    },

    changeUsersMultiSearchFilter(value) {
      if (value.length > 0) {
        let idsArray = []

        idsArray = value.map(item => {
          return item.id
        })

        this.selectedUsers = value
        this.userIdsNotIncl = idsArray.join(',')
      }
      else {
        this.selectedUsers = []
        this.userIdsNotIncl = ''
      }
    },
  }
}
