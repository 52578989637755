
export const dataOperationsMixin = {

  methods: {
    /**
     * Returns object in response
     * @param response
     * @returns {*}
     */

    getRespData(response) {
      return response?.data?.data
    },

    /**
     * Returns object in response with paginate
     * @param response
     * @returns {*}
     */

    getRespPaginateData(response) {
      return response?.data?.data?.data
    },

    /**
     * Returns object in response with paginate
     * @param response
     * @returns {*}
     */

    getResponseStatus(response) {
      return response.status = response?.data?.status || response?.response?.status || response?.status
    },
  },

}
